import { createContext, useContext, useState } from "react";

const AppSideBarContext = createContext();
export const AppSideBarProvider = ({ children }) => {
  const [open, setOpen] = useState({ open: false, key: "" });
  return (
    <AppSideBarContext.Provider value={{ open, setOpen }}>
      {children}
    </AppSideBarContext.Provider>
  );
};

export const AppSideBar = () => useContext(AppSideBarContext);
