import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { useFormik } from "formik";
import FormControl from "../../../components/FormControl";
import FormFieldErrorMsg from "../../../components/FormFieldErrorMsg";
import { carouselValidation } from "./schemaValidation";
import APIHandler from "./apiHandler";
import { getToken } from "../../../utility/constants";
import { notifyError, notifySuccess } from "../../../utility/common";

export default function AddBikeCarousel({ totalItems, onSuccess }) {
  //eslint-disable-next-line
  const [fileName, setFileName] = useState("");
  const token = getToken();
  console.log(totalItems);

  const { handleSubmit, values, errors, setFieldValue, touched } = useFormik({
    initialValues: {
      title: "",
      slug: "",
      custom_slider_image: "",
      custom_slider_section: "new-bike",
    },
    validationSchema: carouselValidation,
    onSubmit: (values, action) => {
      //generate data for API
      const formData = new FormData();
      for (let e of Object.entries(values)) {
        formData.append(e[0], e[1]);
      }

      APIHandler.addBikeCarousel(formData, token)
        .then((result) => {
          notifySuccess("Added Successfully !!");
          action.resetForm();
          onSuccess();
        })
        .catch((error) => {
          notifyError(error.response.data.message);
        });
    },
  });
  console.log(totalItems);
  return (
    <Card className="pb-2">
      <Card.Body>
        <Card.Text>
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center">
                  <div className="table-heading">Add Carousel</div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </Card.Text>

        <Form onSubmit={handleSubmit}>
          <FormControl
            controlId={"titleInput"}
            label={"Title"}
            placeholder={"Title"}
            touched={touched}
            errors={errors}
            name={"title"}
            value={values.title}
            setFieldValue={setFieldValue}
            className="mb-4"
            // disabled={totalItems >= 3}
          />

          <FormControl
            controlId={"slugInput"}
            label={"Url"}
            placeholder={"Url"}
            touched={touched}
            errors={errors}
            name={"slug"}
            value={values.slug}
            setFieldValue={setFieldValue}
            className="mb-4"
            // disabled={totalItems >= 3}
          />

          <Form.Group className="mb-4 pt-3" controlId="exampleForm.ControlFile">
            <Form.Control
              type="file"
              placeholder=" "
              className="inputTypeFile"
              name="custom_slider_image"
              // disabled={totalItems >= 3}
              onChange={(e) => {
                setFieldValue("custom_slider_image", e.target.files[0]);
                setFileName(e.target.files[0].name);
              }}
            />

            <FormFieldErrorMsg
              touched={touched}
              errors={errors}
              name={"custom_slider_image"}
            />

            <small className="small-text">Image Size: 978px X 400px</small>
          </Form.Group>
          <Form.Group className="mt-2">
            <button
              type="submit"
              className="add-button d-flex align-items-center px-3 mb-4"
              // disabled={totalItems >= 3}
            >
              Add
            </button>
            <small className="small-text">Maximum 3 carousels allowed</small>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
}
