import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import EditViewDeleteReviewPassword from "../../../components/EditViewDeleteReviewPassword";
import { ReactSVG } from "react-svg";
import FilterIcon from "../../../assets/icons/filter2.svg";
import PlusLargeIcon from "../../../assets/icons/bootstrap-icons/plus-lg.svg";
import Search from "../../../components/SearchField";
import Breadcrumbs from "../../../Breadcrumbs";
import Card from "react-bootstrap/Card";
import UploadIcon from "../../../assets/icons/bootstrap-icons/upload.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { getToken } from "../../../utility/constants";
import Datatable from "../../../components/Table";
import Form from "react-bootstrap/Form";
import DeleteConfirmationModalComponent from "../../../components/DeleteConfirmationModalComponent";
import { ToastContainer } from "react-toastify";
import { notifySuccess, notifyError } from "../../../utility/common";
import apiHandler from "./apiHandler";
import BikeModelSearchBar from "../BikeModels/BikeModelListing/BikeModelSearchBar";
import ExportButton from "../../../components/Export";

export default function BikeServiceCentres({ children }) {
  const token = getToken();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [filter, setFilter] = useState({ state_id: 0, brand_id: 0 });
  const [states, setStates] = useState([]);
  const [brands, setBrands] = useState([]);
  const [reload, setReload] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const onEditInner = (id) => {
    navigate(`/bike-service-centre/edit-bike-service-centre/${id}`);
  };

  const onDeleteInner = async (id) => {
    apiHandler
      .deleteBikeServiceCentreById(token, deleteId)
      .then((result) => {
        console.log("del", result);
        setShow(false);
        setReload(true);
        notifySuccess("Bike Service Centre deleted successfully");
        setDeleteConfirm(false);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  };

  const columns = [
    {
      name: "Sr No.",
      sortable: true,
      selector: (_, index) =>
        index + 1 + currentPage * itemsPerPage - itemsPerPage,
      width: "100px",
    },
    {
      name: "State",
      selector: (row) => row.state_name,
      width: "150px",
      sortable: true,
    },
    {
      name: "Brand Name",
      selector: (row) => row.brand_name,
      width: "200px",
      sortable: true,
    },
    {
      name: "Service Center Name",
      selector: (row) => row.service_centre_name,
      width: "300px",
      sortable: true,
    },
    {
      name: "City Name",
      sortable: true,
      selector: (row) => row.city_name,
      width: "200px",
    },
    {
      name: "Actions",
      center: true,
      cell: (row) => (
        <>
          <EditViewDeleteReviewPassword
            id={row.id}
            onEdit={onEditInner}
            onDelete={(id) => {
              setDeleteId(id);
              setShow(true);
            }}
          />
        </>
      ),
      width: "200px",
    },
  ];

  useEffect(() => {
    getStatesInner();
    getBrandListInner();
  }, []);

  const getStatesInner = async () => {
    apiHandler.getAllStates(token).then((result) => {
      const states = result.data.result;
      // console.log("states", states)
      setStates(states);
    });
  };

  const getBrandListInner = async () => {
    apiHandler
      .getAllBrands(token)
      .then((result) => {
        // console.log("res", res)
        const brands = result.data.result;
        setBrands(brands);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  };

  const sortFieldNames = {
    "Sr. No.": "id",
    State: "state_name",
    "Brand Name": "brand_name",
    "Service Centre Name": "service_centre_name",
    "City Name": "city_name",
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      className="user-name"
      href="javascript:void(0)"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));
  const filterOptions = [
    {
      key: "brand",
      label: "By Brands",
      type: "brand",
      searchKey: "brand_name",
      values: brands,
    },
    {
      key: "state",
      label: "By States",
      type: "state",
      searchKey: "state_name",
      values: states,
    },
  ];

  const subHeaderComponentMemo = useMemo(() => {
    function handleSearchClick() {
      setReload(true);
    }

    const clearFilter = (e) => {
      e.preventDefault();
      setFilter({ state_id: 0, brand_id: 0 });
      setReload(true);
    };
    const selectedTypes = (type, selected) => {
      if (type === "brand") {
        setFilter({ ...filter, brand_id: selected });
      }
      if (type === "state") {
        setFilter({ ...filter, state_id: selected });
      }
    };
    return (
      <div className="col-md-12 p-0 m-0 d-flex justify-content-between">
        <BikeModelSearchBar
          onChange={(e) => {
            console.log(e);
            setSearch(e);
          }}
          value={search}
          options={filterOptions}
          selectedTypes={selectedTypes}
          apply={handleSearchClick}
          tableHeading={"Bike Service Centre"}
          searchPlaceholder={"Search by Service Centre Name"}
          selectedValues={{
            state_id: filter.state_id,
            brand_id: filter.brand_id,
          }}
          clearAll={(e) => clearFilter(e)}
        />

        <div className="col-md-3 text-right">
          <div className="btngroup d-flex justify-content-end flex-row ">
            <button
              className="add-service-centre-button d-flex align-items-center justify-content-between mb-2 px-3"
              onClick={() =>
                navigate("/bike-service-centre/add-bike-service-centre")
              }
            >
              <ReactSVG
                src={PlusLargeIcon}
                height="18px"
                width="18px"
                className="mr-2"
              />{" "}
              Add Bike Service Centre
            </button>
          </div>
        </div>
      </div>
    );
  }, [filter, search, states, brands, totalRecords, reload]);
  const Keys = [
    "id",
    "state_name",
    "city_name",
    "brand_id",
    "brand_name",
    "service_center_name",
    "service_centre_url",
    "service_centre_address",
    "phone_number",
    "created_date",
    "updated_date",
  ];

  return (
    <div>
      <ToastContainer />
      <DeleteConfirmationModalComponent
        show={show}
        type={"delete"}
        handleClose={() => setShow(false)}
        setDeleteConfirm={onDeleteInner}
      />
      <div className="d-flex justify-content-between">
        <Breadcrumbs />
        {/* <button className="d-flex align-items-center justify-content-center service-centre-export-btn ms-2"> */}
        <ExportButton
          api={apiHandler.getBikeServiceCentres}
          totalRecords={totalRecords}
          keys={Keys}
          showKeys={Keys}
          payload={{
            search: "",
            sortBy: "",
            sortOrder: "",
            page: 1,
            limit: totalRecords,
          }}
        />
        {/* </button> */}
      </div>

      <Card>
        <Card.Body>
          <Card.Text>
            <div className="row dashboard">
              <Datatable
                api={apiHandler.getBikeServiceCentres}
                columns={columns}
                header={subHeaderComponentMemo}
                showHeader={true}
                sortFieldNames={sortFieldNames}
                filter={filter}
                search={search}
                reload={reload}
                sortByDir={"desc"}
                defaultSortColumn={"id"}
                setReload={setReload}
                setTotalRecords={setTotalRecords}
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
              />
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
