import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../request";

export function getPosts(token, params) {
  return axios.post(
    `${BASE_URL_AUTOX}post-management/filtered/list`,
    params,
    getConfig(token)
  );
}

export function addNewPost(payload, token) {
  console.log(payload, "payload");
  return axios.post(
    `${BASE_URL_AUTOX}post-management/create`,
    payload,
    getConfig(token)
  );
}

export function checkPostLockStatus(postId, token) {
  return axios.get(
    `${BASE_URL_AUTOX}post-management/post-lock-status/${postId}`,
    getConfig(token)
  );
}

export function getCategoryList(token) {
  return axios.get(
    `${BASE_URL_AUTOX}post-management/category/list`,

    getConfig(token)
  );
}

export async function getAdminUserList(token) {
  return await axios.get(`${BASE_URL_AUTOX}admin/user-list`, getConfig(token));
}

export function ImageUpload(payload, token) {
  return axios.post(
    // `${BASE_URL_AUTOX}post-management/image-upload`,
    payload,
    getConfig(token)
  );
}
export function imagesListing(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}post-management/image-listing`,
    payload,
    getConfig(token)
  );
}

export function uploadImageListing(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}post-management/media-upload`,
    payload,
    getConfig(token, { "Content-Type": "multipart/form-data" })
  );
}

export function deleteImage(payload, token) {
  return axios.delete(
    `${BASE_URL_AUTOX}post-management/image-delete/${payload}`,
    getConfig(token)
  );
}

export function updateImageDetails(id, title, caption, token) {
  return axios.put(
    `${BASE_URL_AUTOX}post-management/image-update/${id}`,
    { title, caption },
    getConfig(token)
  );
}
export function getPostById(id, token) {
  return axios.get(
    `${BASE_URL_AUTOX}post-management/post-details/${id}`,
    getConfig(token)
  );
}

export function updatePostStatus(payload, token) {
  return axios.put(
    `${BASE_URL_AUTOX}post-management/post-status-update`,
    payload,
    getConfig(token)
  );
}
export function updatePostById(id, payload, token) {
  return axios.put(
    `${BASE_URL_AUTOX}post-management/update/${id}`,
    payload,
    getConfig(token)
  );
}

export function deletePostById(id, token) {
  return axios.delete(
    `${BASE_URL_AUTOX}post-management/post-delete/${id}`,
    getConfig(token)
  );
}

export function deleteCustomSliderById(id, token) {
  return axios.delete(
    `${BASE_URL_AUTOX}post-management/custom-slider-delete/${id}`,
    getConfig(token)
  );
}

export function postFilterOptions(token) {
  return axios.get(
    `${BASE_URL_AUTOX}post-management/post-filter-option`,
    getConfig(token)
  );
}

export function downloadPostContent(token) {
  return axios.get(
    `${BASE_URL_AUTOX}post-management/download-post-content`,
    getConfig(token)
  );
}
export async function downloadPostMetaData(token) {
  // const response = await axios.get(
  //   `${BASE_URL_AUTOX}post-management/download-post-meta`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "text/csv", // Ensure the response is treated as CSV
  //     },
  //     responseType: "blob",
  //   }
  // );
  // return response;
  return axios.get(
    `${BASE_URL_AUTOX}post-management/download-post-meta`,
    getConfig(token)
  );
}

export async function addCustomSlider(id, token) {
  return await axios.post(
    `${BASE_URL_AUTOX}post-management/add-custom-slider/`,
    { id },
    getConfig(token)
  );
}
const sortSlider = (token, carousel) => {
  console.log(carousel);
  return axios.put(
    `${BASE_URL_AUTOX}post-management/slider/sort`,
    { carousel: carousel },
    getConfig(token)
  );
};

export const addPostGallery = async (formData, token) => {
  try {
    const response = await axios.post(
      BASE_URL_AUTOX + "gallery/post-gallery-add",
      formData,
      getConfig(token, { "Content-Type": "multipart/form-data" })
    );
    return response.data;
  } catch (error) {
    console.error("Error uploading car gallery:", error);
    throw error;
  }
};
export const updatePostGallery = async (id, formData, token) => {
  try {
    const response = await axios.put(
      BASE_URL_AUTOX + `gallery/post-gallery-edit/${id}`,
      formData,
      getConfig(token, { "Content-Type": "multipart/form-data" })
    );
    return response.data;
  } catch (error) {
    console.error("Error uploading car gallery:", error);
    throw error;
  }
};

export const getPostGalleryList = async (token, payload) => {
  try {
    if (payload) {
      const response = await axios.post(
        BASE_URL_AUTOX + "gallery/get-post-gallery",
        payload,

        getConfig(token)
      );
      return response.data;
    }
  } catch (error) {
    console.error("Error fetching car gallery:", error);
    throw error;
  }
};

export function getPostGalleryById(id, token) {
  return axios.get(
    `${BASE_URL_AUTOX}gallery/post-gallery/${id}`,
    getConfig(token)
  );
}
export function deletePostGalleryById(id, token) {
  return axios.delete(
    `${BASE_URL_AUTOX}gallery/post-gallery/delete/${id}`,
    getConfig(token)
  );
}

export default {
  getPosts,
  deletePostById,
  addNewPost,
  getCategoryList,
  getAdminUserList,
  ImageUpload,
  imagesListing,
  deleteImage,
  uploadImageListing,
  deleteImage,
  getPostById,
  updatePostById,
  postFilterOptions,
  downloadPostContent,
  downloadPostMetaData,
  updatePostStatus,
  checkPostLockStatus,
  deleteCustomSliderById,
  addCustomSlider,
  sortSlider,
  addPostGallery,
  getPostGalleryList,
  deletePostGalleryById,
  getPostGalleryById,
};
