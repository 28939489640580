import * as Yup from "yup";

const BikeReviewSchema = Yup.object().shape({
  user_id: Yup.number().optional().integer().min(0).nullable(),
  name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Name must contain alphabets only")
    .max(255)
    .required(),
  email: Yup.string().email().max(255).required(),
  model_id: Yup.number().required(),
  title: Yup.string().max(255).required(),
  rating: Yup.number().required(),
  status: Yup.number().required(),
  version_id: Yup.number().required(),
  design_styling: Yup.number().optional().default(null),
  comfort_space: Yup.number().optional().default(null),
  performance: Yup.number().optional().default(null),
  fuel_economy: Yup.number().optional().default(null),
  value_for_money: Yup.number().optional().default(null),
  features: Yup.number().optional(),
  safety: Yup.number().optional(),
  maintenance_cost: Yup.string().optional(),
  kms_driven: Yup.string().optional(),
  mileage: Yup.string().optional(),
  pincode: Yup.number().required(),

  // create_date: Yup.string().required(),
});

export default BikeReviewSchema;
