import React from "react";
import AutoXLogo from "../../assets/icons/bootstrap-icons/autoX - Logo.svg";
import CarImage from "../../assets/icons/bootstrap-icons/auth-images/background.jpg";
import { ReactSVG } from "react-svg";

export default function AuthLayout({ children }) {
  return (
    <div className="row m-0 vh-100">
      <div
        className="col-xxl-12 col-lg-12 col-xl-12 col-md-12 col-12 d-flex justify-content-center align-items-center h-100 w-100 m-0 p-0"
        style={{
          backgroundImage: `url(${CarImage})`,
          backgroundPosition: "center",
      
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",

          width: "100%",
          height: "100vh",
        }}
      >
        <div className="auth-container h-100 d-flex align-items-center justify-content-center">
          <div className="card p-0 m-0 overflow-hidden">
            <div className="d-flex p-3 align-items-center justify-content-center auth-header">
              <h1 className="d-flex justify-content-center align-items-center my-3">
                <div className="d-flex justify-content-center align-items-center w-75 h-75">
                  <ReactSVG src={AutoXLogo} className="auth-header-image" />
                </div>
              </h1>
            </div>
            <div className="col-xxl-12 col-lg-12 col-xl-12 col-md-12 col-12">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
