import VersionManagementItem from "../../../components/VersionManagementItem";
import HeadingTwo from "../../../components/HeadingTwo";

export default function CarVersionInfo() {
    return (
        <div className="col-12 card-margin card p-0">
            <div className="w-100 d-flex p-3 ps-0 align-items-center justify-content-between">
                <div className="card-header-text">
                    {/* <HeadingTwo
                        title="Engine & Transmission"
                        type="dashboard"
                        showHeader={true}
                        pointerOnHover={true}
                    /> */}
                    Engine & Transmission
                </div>
                <div className="d-flex">
                    <button className="green-filled-button">
                        Save
                    </button>
                </div>
            </div>
            <div className="d-flex flex-wrap mt-3">
                <div className="col-6 px-4 pb-2">
                    <VersionManagementItem />
                </div>

                <div className="col-6 px-4 pb-2">
                    <VersionManagementItem />
                </div>

                <div className="col-6 px-4 pb-2">
                    <VersionManagementItem />
                </div>

                <div className="col-6 px-4 pb-2">
                    <VersionManagementItem />
                </div>
            </div>
        </div>
    )
}