import React from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { ReactSVG } from "react-svg";

import logo from "../assets/icons/bootstrap-icons/autoX - Logo.svg";
import { styled } from "styled-components";

import CarIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Cars.svg";
import BikeIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Bike.svg";
import CategoryIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Category management.svg";
import CommentsIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Comments.svg";
import DashboardIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/white home.svg";
import EditorIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Editor Management.svg";
import EnquiryFormIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Enquiry Form.svg";
import EnquiryIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Enquiry management.svg";
import FaqIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/faq.svg";
import FeedbackIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Feedback.svg";
import NewaIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Newa.svg";
import NewsletterIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Newsletter.svg";
import PageIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Page Management.svg";
import PostIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Post Management.svg";
import ReviewIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Review.svg";
import SidebarIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/sidebar.svg";
import StateIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/State.svg";
import SubscriptionIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Subscription.svg";
import TagsIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Tags Management.svg";
import TemplateIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Template.svg";
import ThemeIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Theme.svg";
import WhiteManagerIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/White Manage Employer.svg";

import { useLocation, useNavigate } from "react-router";
import { AppSideBar } from "./AppSideBarProvider";
import { getUserRole } from "../utility/constants";
const StyledSidebar = styled.div`
  background-color: #1d325e;
  color: #a3aed1;
`;

export default function SidebarComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const { open, setOpen } = AppSideBar();
  const userRoles = getUserRole();
  const handleToggle = (open, key) => {
    setOpen({ open: open, key: key });
  };

  const { collapsed } = useProSidebar();

  return (
    <StyledSidebar>
      <Sidebar
        toggled={collapsed}
        className="sidebar-custom d-flex flex-column"
      >
        <div className="d-flex flex-column">
          <div className="side-nav-header">
            {!collapsed && (
              <img src={logo} alt="AutoX logo" className="logo m-0 p-0" />
            )}
            <hr className="p-0 m-0" />
          </div>
        </div>

        <Menu className="scroller">
          {/* <StyledMenu> */}
          <MenuItem className="menuitem-category"> Main</MenuItem>
          <MenuItem
            className="menuitem-custom"
            active={
              location.pathname === "/dashboard" || location.pathname === "/"
            }
            onClick={() => navigate("/dashboard")}
            icon={<ReactSVG src={DashboardIcon} />}
          >
            {" "}
            Dashboard
          </MenuItem>
          <MenuItem className="menuitem-category"> Car</MenuItem>
          <SubMenu
            className="submenu-custom"
            icon={<ReactSVG src={CarIcon} />}
            label="New Car"
            open={open.open && open.key === "car"}
            active={
              location.pathname === "/car-brand" ||
              location.pathname === "/car-brand/add-car-brand" ||
              location.pathname === "/car-brand/edit-car-brand/:id" ||
              location.pathname === "/car-models" ||
              location.pathname === "/car-models/add-car-model" ||
              location.pathname === "/car-models/edit-car-model" ||
              location.pathname === "/car-version" ||
              // location.pathname === "/add-car-variant" ||
              // location.pathname === "/edit-car-variant" ||

              location.pathname === "/manage-car-version" ||
              location.pathname === "/add-car-version" ||
              location.pathname === "/car-version/manage-car-version" ||
              location.pathname === "/car-version/add-car-version" ||
              location.pathname === "/car-version/edit-car-version" ||
              location.pathname === "/popular-cars" ||
              location.pathname === "/trending-cars" ||
              location.pathname === "/car-price/multiple-city-price" ||
              location.pathname === "/multiple-city-price-details" ||
              location.pathname === "/car-city-price" ||
              location.pathname === "/add-car-city-price" ||
              location.pathname === "/car-comparison" ||
              location.pathname === "/car-driving-school" ||
              location.pathname ===
                "/car-driving-school/add-car-driving-school" ||
              location.pathname ===
                "/car-driving-school/edit-car-driving-school" ||
              location.pathname === "/maruti-car-driving-school" ||
              location.pathname ===
                "/maruti-car-driving-school/add-maruti-car-driving-school" ||
              location.pathname ===
                "/maruti-car-driving-school/edit-maruti-car-driving-school" ||
              location.pathname === "/car-dealer" ||
              location.pathname === "/dealer/add-car-dealer" ||
              location.pathname === "dealer/edit-car-dealer" ||
              location.pathname === "/car-service-centre" ||
              location.pathname ===
                "/car-service-centre/add-car-service-centre" ||
              location.pathname ===
                "/car-service-centre/edit-car-service-centre" ||
              location.pathname === "/car-vehicle-type" ||
              location.pathname === "/add-car-vehicle-type" ||
              location.pathname === "/vehicle-type/edit-car-vehicle-type" ||
              location.pathname === "/car-city" ||
              location.pathname === "/car-city/add-car-city" ||
              location.pathname === "/car-city/edit-car-city" ||
              location.pathname === "/car-carousel" ||
              location.pathname === "/car-faq" ||
              location.pathname === "/add-car-faq" ||
              location.pathname === "/widget"
            }
            onClick={() => handleToggle(!open.open, "car")}
          >
            <MenuItem
              active={
                location.pathname === "/car-brand" ||
                location.pathname === "/car-brand/add-car-brand" ||
                location.pathname === "/car-brand/edit-car-brand"
              }
              onClick={() => navigate("/car-brand")}
            >
              Car Brands
            </MenuItem>
            <MenuItem
              active={
                location.pathname === "/car-models" ||
                location.pathname === "/car-models/add-car-model" ||
                location.pathname === "/car-models/edit-car-model"
              }
              onClick={() => navigate("/car-models")}
            >
              Car Model
            </MenuItem>

            <MenuItem
              active={
                location.pathname === "/car-version" ||
                location.pathname === "/car-version/add-car-version" ||
                location.pathname === "/car-version/edit-car-variant" ||
                location.pathname === "/car-version/manage-car-version" ||
                location.pathname === "/car-version/create-car-version"
              }
              onClick={() => navigate("/car-version")}
            >
              Car Version
            </MenuItem>

            <MenuItem
              active={
                location.pathname === "/manage-car-version" ||
                location.pathname === "/add-car-version"
              }
              onClick={() => navigate("/manage-car-version")}
            >
              Manage Version
            </MenuItem>

            <MenuItem
              active={location.pathname === "/popular-cars"}
              onClick={() => navigate("/popular-cars")}
            >
              Popular Cars
            </MenuItem>
            <MenuItem
              active={location.pathname === "/trending-cars"}
              onClick={() => navigate("/trending-cars")}
            >
              Trending Cars
            </MenuItem>

            <MenuItem
              active={
                location.pathname === "/car-price/multiple-city-price" ||
                location.pathname === "/multiple-city-price-details"
              }
              onClick={() => navigate("/car-price/multiple-city-price")}
            >
              Multiple City Price
            </MenuItem>

            <MenuItem
              active={
                location.pathname === "/car-city-price" ||
                location.pathname === "/add-car-city-price" ||
                location.pathname === "/edit-car-city-price/:id/:url"
              }
              onClick={() => navigate("/car-city-price")}
            >
              City Price
            </MenuItem>

            <MenuItem
              active={location.pathname === "/car-comparison"}
              onClick={() => navigate("/car-comparison")}
            >
              Car Comparison
            </MenuItem>

            <MenuItem
              active={
                location.pathname === "/car-driving-school" ||
                location.pathname ===
                  "/car-driving-school/add-car-driving-school" ||
                location.pathname ===
                  "/car-driving-school/edit-car-driving-school"
              }
              onClick={() => navigate("/car-driving-school")}
            >
              Car Driving School
            </MenuItem>

            <MenuItem
              active={
                location.pathname === "/maruti-car-driving-school" ||
                location.pathname ===
                  "/maruti-car-driving-school/add-maruti-car-driving-school" ||
                location.pathname ===
                  "/maruti-car-driving-school/edit-maruti-car-driving-school"
              }
              onClick={() => navigate("/maruti-car-driving-school")}
            >
              Maruti Driving School
            </MenuItem>

            <MenuItem
              active={
                location.pathname === "/car-dealer" ||
                location.pathname === "/dealer/add-car-dealer" ||
                location.pathname === "/dealer/edit-car-dealer"
              }
              onClick={() => navigate("/car-dealer")}
            >
              Car Dealer
            </MenuItem>

            <MenuItem
              active={
                location.pathname === "/car-service-centre" ||
                location.pathname ===
                  "/car-service-centre/add-car-service-centre" ||
                location.pathname ===
                  "/car-service-centre/edit-car-service-centre"
              }
              onClick={() => navigate("/car-service-centre")}
            >
              Car Service Centre
            </MenuItem>

            <MenuItem
              active={
                location.pathname === "/car-vehicle-type" ||
                location.pathname === "/add-car-vehicle-type" ||
                location.pathname === "/vehicle-type/edit-car-vehicle-type"
              }
              onClick={() => navigate("/car-vehicle-type")}
            >
              Car Body Type
            </MenuItem>

            <MenuItem
              active={
                location.pathname === "/car-city" ||
                location.pathname === "/car-city/add-car-city" ||
                location.pathname === "/car-city/edit-car-city"
              }
              onClick={() => navigate("/car-city")}
            >
              Car City
            </MenuItem>

            <MenuItem
              active={location.pathname === "/car-carousel"}
              onClick={() => navigate("/car-carousel")}
            >
              Car Carousel
            </MenuItem>

            <MenuItem
              active={
                location.pathname === "/car-faq" ||
                location.pathname === "/add-car-faq" ||
                location.pathname === "/edit-car-faq"
              }
              onClick={() => navigate("/car-faq")}
            >
              Car FAQ
            </MenuItem>

            <MenuItem
              active={location.pathname === "/widget"}
              onClick={() => navigate("/widget")}
            >
              {" "}
              Widget
            </MenuItem>
          </SubMenu>

          <SubMenu
            className="submenu-custom"
            icon={<ReactSVG src={FaqIcon} />}
            label="Car FAQ"
            onClick={() => handleToggle(!open.open, "car-question")}
            open={open.open && open.key === "car-question"}
            active={
              location.pathname === "/car-questions" ||
              location.pathname === "/car-answers" ||
              location.pathname === "/add-car-question" ||
              location.pathname === "/edit-car-question" ||
              location.pathname === "add-car-answer" ||
              location.pathname === "/edit-car-answer"
            }

            //   onClick={() => navigate("/notifications")}
          >
            <MenuItem
              active={
                location.pathname === "/car-questions" ||
                location.pathname === "/add-car-question" ||
                location.pathname === "/edit-car-question"
              }
              onClick={() => navigate("/car-questions")}
            >
              Car Questions
            </MenuItem>

            <MenuItem
              onClick={() => navigate("/car-answers")}
              active={
                location.pathname === "/car-answers" ||
                location.pathname === "add-car-answer" ||
                location.pathname === "/edit-car-answer"
              }
            >
              {" "}
              Car Answers
            </MenuItem>
          </SubMenu>
          <MenuItem
            className="menuitem-custom"
            active={
              location.pathname === "/car-user-review" ||
              location.pathname === "/edit-car-user-review"
            }
            onClick={() => navigate("/car-user-review")}
            icon={<ReactSVG src={ReviewIcon} />}
          >
            {" "}
            Car User Review
          </MenuItem>
          <MenuItem className="menuitem-category"> Bike</MenuItem>
          <SubMenu
            className="submenu-custom"
            icon={<ReactSVG src={BikeIcon} />}
            label="New Bike"
            open={open.open && open.key === "bike"}
            active={
              location.pathname === "/bike-brand" ||
              location.pathname === "/bike-brand/add-bike-brand" ||
              location.pathname === "/bike-brand/edit-bike-brand/:id" ||
              location.pathname === "/bike-models" ||
              location.pathname === "/bike-models/add-bike-model" ||
              location.pathname === "/bike-models/edit-bike-model" ||
              location.pathname === "/bike-version" ||
              location.pathname === "/bike-version/create-bike-version" ||
              location.pathname === "/edit-bike-version" ||
              location.pathname === "/manage-bike-version" ||
              location.pathname === "/bike-carousel" ||
              location.pathname === "/scooter-carousel" ||
              location.pathname === "/bike-dealer" ||
              location.pathname === "/bike-dealer/add-bike-dealer" ||
              location.pathname === "/bike-dealer/edit-bike-dealer" ||
              location.pathname === "/bike-city-price" ||
              location.pathname === "/add-bike-city-price" ||
              location.pathname === "/edit-bike-city-price/:id/:url" ||
              location.pathname === "/popular-bikes" ||
              location.pathname === "/bike-city-price/multiple-city-price" ||
              location.pathname === "/multiple-city-price" ||
              location.pathname === "/bike-comparison" ||
              location.pathname === "/bike-vehicle-type" ||
              location.pathname === "/add-bike-vehicle-type" ||
              location.pathname === "/vehicle-type/edit-bike-vehicle-type" ||
              location.pathname === "/bike-vehicle-type" ||
              location.pathname === "/add-bike-vehicle-type" ||
              location.pathname === "/vehicle-type/edit-bike-vehicle-type" ||
              location.pathname === "/bike-service-centre" ||
              location.pathname ===
                "/bike-service-centre/add-bike-service-centre" ||
              location.pathname ===
                "/bike-service-centre/edit-bike-service-centre" ||
              location.pathname === "/bike-city" ||
              location.pathname === "/bike-city/add-bike-city" ||
              location.pathname === "/bike-city/edit-bike-city"
            }
            onClick={() => handleToggle(!open.open, "bike")}
          >
            <MenuItem
              active={
                location.pathname === "/bike-brand" ||
                location.pathname === "/bike-brand/add-bike-brand" ||
                location.pathname === "/bike-brand/edit-bike-brand/:id"
              }
              onClick={() => navigate("/bike-brand")}
            >
              {" "}
              Bike Brand
            </MenuItem>
            <MenuItem
              active={
                location.pathname === "/bike-models" ||
                location.pathname === "/bike-models/add-bike-model" ||
                location.pathname === "/bike-models/edit-bike-model"
              }
              onClick={() => navigate("/bike-models")}
            >
              Bike Model
            </MenuItem>
            <MenuItem
              active={
                location.pathname === "/bike-version" ||
                location.pathname === "/bike-version/create-bike-version" ||
                location.pathname === "/edit-bike-version"
              }
              onClick={() => navigate("/bike-version")}
            >
              Bike Version
            </MenuItem>
            <MenuItem
              active={location.pathname === "/manage-bike-version"}
              onClick={() => navigate("/manage-bike-version")}
            >
              Manage Bike Version
            </MenuItem>
            <MenuItem
              active={location.pathname === "/popular-bikes"}
              onClick={() => navigate("/popular-bikes")}
            >
              Popular Bikes
            </MenuItem>
            <MenuItem
              active={location.pathname === "/trending-bikes"}
              onClick={() => navigate("/trending-bikes")}
            >
              Trending Bikes
            </MenuItem>
            <MenuItem
              active={location.pathname === "/bike-carousel"}
              onClick={() => navigate("/bike-carousel")}
            >
              Bike Carousel
            </MenuItem>
            <MenuItem
              active={location.pathname === "/scooter-carousel"}
              onClick={() => navigate("/scooter-carousel")}
            >
              Scooter Carousel
            </MenuItem>
            <MenuItem
              active={
                location.pathname === "/bike-dealer" ||
                location.pathname === "/bike-dealer/add-bike-dealer" ||
                location.pathname === "/bike-dealer/edit-bike-dealer"
              }
              onClick={() => navigate("/bike-dealer")}
            >
              Bike Dealer
            </MenuItem>

            <MenuItem
              active={
                location.pathname === "/bike-city-price/multiple-city-price" ||
                location.pathname === "/multiple-city-price"
              }
              onClick={() => navigate("/bike-city-price/multiple-city-price")}
            >
              Multiple City Price
            </MenuItem>
            <MenuItem
              active={
                location.pathname === "/bike-city-price" ||
                location.pathname === "/add-bike-city-price" ||
                location.pathname === "/edit-bike-city-price/:id/:url"
              }
              onClick={() => navigate("/bike-city-price")}
            >
              City Price
            </MenuItem>
            <MenuItem
              active={location.pathname === "/bike-comparison"}
              onClick={() => navigate("/bike-comparison")}
            >
              Bike Comparison
            </MenuItem>
            <MenuItem
              active={
                location.pathname === "/bike-vehicle-type" ||
                location.pathname === "/add-bike-vehicle-type" ||
                location.pathname === "/vehicle-type/edit-bike-vehicle-type"
              }
              onClick={() => navigate("/bike-vehicle-type")}
            >
              Bike Body Type
            </MenuItem>
            <MenuItem
              active={
                location.pathname === "/bike-service-centre" ||
                location.pathname ===
                  "/bike-service-centre/add-bike-service-centre" ||
                location.pathname ===
                  "/bike-service-centre/edit-bike-service-centre"
              }
              onClick={() => navigate("/bike-service-centre")}
            >
              Bike Service Centre
            </MenuItem>
            <MenuItem
              active={
                location.pathname === "/bike-city" ||
                location.pathname === "/bike-city/add-bike-city" ||
                location.pathname === "/bike-city/edit-bike-city"
              }
              onClick={() => navigate("/bike-city")}
            >
              Bike City
            </MenuItem>
            <MenuItem
              active={
                location.pathname === "/bike-questions" ||
                location.pathname === "/bike-answers" ||
                location.pathname === "/add-bike-question" ||
                location.pathname === "/edit-bike-question" ||
                location.pathname === "/add-bike-answer" ||
                location.pathname === "/edit-bike-answer"
              }
              onClick={() => navigate("/bike-questions")}
            >
              Bike FAQ
            </MenuItem>
          </SubMenu>
          <SubMenu
            className="submenu-custom"
            icon={<ReactSVG src={FaqIcon} />}
            label="Bike FAQ"
            active={
              location.pathname === "/bike-questions" ||
              location.pathname === "/bike-answers" ||
              location.pathname === "/add-bike-question" ||
              location.pathname === "/edit-bike-question" ||
              location.pathname === "/add-bike-answer" ||
              location.pathname === "/edit-bike-answer"
            }
          >
            <MenuItem
              active={
                location.pathname === "/bike-questions" ||
                location.pathname === "/add-bike-question" ||
                location.pathname === "/edit-bike-question"
              }
              onClick={() => navigate("/bike-questions")}
            >
              {" "}
              Bike Question
            </MenuItem>
            <MenuItem
              active={
                location.pathname === "/bike-answers" ||
                location.pathname === "/add-bike-answer" ||
                location.pathname === "/edit-bike-answer"
              }
              onClick={() => navigate("/bike-answers")}
            >
              {" "}
              Bike Answer
            </MenuItem>
          </SubMenu>

          <MenuItem
            className="menuitem-custom"
            active={
              location.pathname === "/bike-user-review" ||
              location.pathname === "/add-bike-user-review" ||
              location.pathname === "/edit-bike-user-review"
            }
            onClick={() => navigate("/bike-user-review")}
            icon={<ReactSVG src={ReviewIcon} />}
          >
            {" "}
            Bike User Review
          </MenuItem>
          <MenuItem className="menuitem-category"> Pages</MenuItem>
          <MenuItem
            className="menuitem-custom"
            active={
              location.pathname === "/post-management" ||
              location.pathname === "/post-management/add-post" ||
              location.pathname === "/"
            }
            onClick={() => navigate("/post-management")}
            icon={<ReactSVG src={PostIcon} />}
          >
            {" "}
            Post Management
          </MenuItem>
          <MenuItem
            className="menuitem-custom"
            active={
              location.pathname === "/page" ||
              location.pathname === "/page-add" ||
              location.pathname.startsWith("/page-edit")
            }
            onClick={() => navigate("/page")}
            icon={<ReactSVG src={PageIcon} />}
          >
            {" "}
            Page Management
          </MenuItem>
          <MenuItem
            className="menuitem-custom"
            active={
              location.pathname === "/category" ||
              location.pathname === "/add-category" ||
              location.pathname === "/edit-category"
            }
            onClick={() => navigate("/category")}
            icon={<ReactSVG src={CategoryIcon} />}
          >
            {" "}
            Category Management
          </MenuItem>
          <MenuItem
            className="menuitem-custom"
            active={
              location.pathname === "/tag" ||
              location.pathname === "/add-tag" ||
              location.pathname === "/edit-tag"
            }
            onClick={() => navigate("/tag")}
            icon={<ReactSVG src={TagsIcon} />}
          >
            {" "}
            Tags Management
          </MenuItem>

          <SubMenu
            className="submenu-custom"
            icon={<ReactSVG src={SidebarIcon} />}
            label="Ad Page"
            active={
              location.pathname === "/ad-page" ||
              location.pathname === "/add-ad-page" ||
              location.pathname === "/edit-ad-page" ||
              location.pathname === "/ad-widget" ||
              location.pathname === "/ad-widget-add" ||
              location.pathname === "/ad-widget-edit"
            }
          >
            <MenuItem
              className="menuitem-custom"
              active={
                location.pathname === "/ad-page" ||
                location.pathname === "/add-ad-page" ||
                location.pathname === "/edit-ad-page"
              }
              onClick={() => navigate("/ad-page")}
              icon={<ReactSVG src={EnquiryIcon} />}
            >
              {" "}
              Ad Page
            </MenuItem>
            <MenuItem
              className="menuitem-custom"
              active={
                location.pathname === "/ad-widget" ||
                location.pathname === "/ad-widget-add" ||
                location.pathname === "/ad-widget-edit"
              }
              onClick={() => navigate("/ad-widget")}
              icon={<ReactSVG src={EnquiryIcon} />}
            >
              {" "}
              Ad Widget
            </MenuItem>
          </SubMenu>
          <MenuItem
            className="menuitem-custom"
            active={location.pathname === "/state-price"}
            onClick={() => navigate("/state-price")}
            icon={<ReactSVG src={StateIcon} />}
          >
            {" "}
            State Price
          </MenuItem>
          <MenuItem
            className="menuitem-custom"
            active={location.pathname === "/comments"}
            onClick={() => navigate("/comments")}
            icon={<ReactSVG src={CommentsIcon} />}
          >
            {" "}
            Comments
          </MenuItem>
          <MenuItem
            className="menuitem-custom"
            active={
              location.pathname === "/car-model-gallery" ||
              location.pathname === "/gallery-add" ||
              location.pathname === "/car-model-gallery/car-gallery-add" ||
              location.pathname === "/car-gallery-edit/:id"
            }
            onClick={() => navigate("/car-model-gallery")}
            icon={<ReactSVG src={CommentsIcon} />}
          >
            {" "}
            Gallery
          </MenuItem>
          <MenuItem
            className="menuitem-custom"
            active={
              location.pathname === "/safety-rating" ||
              location.pathname === "/add-safety-rating" ||
              location.pathname === "/edit-safety-rating/:id"
            }
            onClick={() => navigate("/safety-rating")}
            icon={<ReactSVG src={CommentsIcon} />}
          >
            {" "}
            Safety Ratings
          </MenuItem>
          <MenuItem className="menuitem-category"> Users</MenuItem>
          <MenuItem
            className="menuitem-custom"
            active={
              location.pathname === "/editor" ||
              location.pathname === "/add-editor" ||
              location.pathname === "/edit-editor"
            }
            onClick={() => navigate("/editor")}
            icon={<ReactSVG src={EditorIcon} />}
          >
            {" "}
            Editor Management
          </MenuItem>
          <MenuItem
            className="menuitem-custom"
            active={
              location.pathname === "/category" ||
              location.pathname === "/add-category" ||
              location.pathname === "/edit-category"
            }
            onClick={() => navigate("/category")}
            icon={<ReactSVG src={WhiteManagerIcon} />}
          >
            {" "}
            User Management
          </MenuItem>
          <MenuItem className="menuitem-category"> Enquiry</MenuItem>
          <MenuItem
            className="menuitem-custom"
            // active={
            //   location.pathname === "/" ||
            //   location.pathname === "/" ||
            //   location.pathname === "/"
            // }
            onClick={() => navigate("/")}
            icon={<ReactSVG src={EnquiryFormIcon} />}
          >
            {" "}
            Enquiry Form
          </MenuItem>
          <MenuItem
            className="menuitem-custom"
            active={location.pathname === "/enquiry-admin"}
            onClick={() => navigate("/enquiry-admin")}
            icon={<ReactSVG src={EnquiryIcon} />}
          >
            {" "}
            Enquiry Management
          </MenuItem>
          <MenuItem className="menuitem-category"> Settings</MenuItem>
          <MenuItem
            className="menuitem-custom"
            // active={
            //   location.pathname === "/" ||
            //   location.pathname === "/" ||
            //   location.pathname === "/"
            // }
            onClick={() => navigate("/")}
            icon={<ReactSVG src={StateIcon} />}
          >
            {" "}
            State
          </MenuItem>
          <SubMenu
            className="submenu-custom"
            icon={<ReactSVG src={SidebarIcon} />}
            label="Sidebar"
            active={
              location.pathname === "/sidebar" ||
              location.pathname === "/sidebar-add" ||
              location.pathname === "/sidebar-edit" ||
              location.pathname === "/widget" ||
              location.pathname === "/widget-add" ||
              location.pathname === "/widget-edit"
            }
          >
            <MenuItem
              className="menuitem-custom"
              active={
                location.pathname === "/sidebar" ||
                location.pathname === "/sidebar-add" ||
                location.pathname === "/sidebar-edit"
              }
              onClick={() => navigate("/sidebar")}
              icon={<ReactSVG src={EnquiryIcon} />}
            >
              {" "}
              Sidebar
            </MenuItem>
            <MenuItem
              className="menuitem-custom"
              active={
                location.pathname === "/widget" ||
                location.pathname === "/widget-add" ||
                location.pathname === "/widget-edit"
              }
              onClick={() => navigate("/widget")}
              icon={<ReactSVG src={EnquiryIcon} />}
            >
              {" "}
              Widget
            </MenuItem>
          </SubMenu>
          <MenuItem
            className="menuitem-custom"
            active={
              location.pathname === "/template" ||
              location.pathname === "/template/edit-template" ||
              location.pathname === "/template/add-template"
            }
            onClick={() => navigate("/template")}
            icon={<ReactSVG src={TemplateIcon} />}
          >
            {" "}
            Template
          </MenuItem>
          <MenuItem
            className="menuitem-custom"
            // active={
            //   location.pathname === "/" ||
            //   location.pathname === "/" ||
            //   location.pathname === "/"
            // }
            onClick={() => navigate("/")}
            icon={<ReactSVG src={ThemeIcon} />}
          >
            {" "}
            Themes
          </MenuItem>
          <MenuItem className="menuitem-category"> Newsletter</MenuItem>
          <SubMenu
            className="submenu-custom"
            icon={<ReactSVG src={NewsletterIcon} />}
            label="Newsletter"
            active={
              location.pathname === "/news-letter" ||
              location.pathname === "/news-letter-add" ||
              location.pathname === "/news-letter-edit" ||
              location.pathname === "/news-letter-campaign" ||
              location.pathname === "/news-letter-campaign-add" ||
              location.pathname === "/news-letter-campaign-view"
            }
          >
            <MenuItem
              className="menuitem-custom"
              active={
                location.pathname === "/news-letter" ||
                location.pathname === "/news-letter-add" ||
                location.pathname === "/news-letter-edit"
                // location.pathname === "/"
              }
              onClick={() => navigate("/news-letter")}
              icon={<ReactSVG src={EnquiryFormIcon} />}
            >
              {" "}
              Newsletter List
            </MenuItem>
            <MenuItem
              className="menuitem-custom"
              active={
                location.pathname === "/news-letter-campaign" ||
                location.pathname === "/news-letter-campaign-add" ||
                location.pathname === "/news-letter-campaign-view"
              }
              onClick={() => navigate("/news-letter-campaign")}
              icon={<ReactSVG src={EnquiryFormIcon} />}
            >
              {" "}
              Campaign List
            </MenuItem>
          </SubMenu>
          <MenuItem
            className="menuitem-custom"
            active={
              location.pathname === "/subscription" ||
              location.pathname === "/subscription/edit-subscription" ||
              location.pathname === "/subscription/add-subscription"
            }
            onClick={() => navigate("/subscription")}
            icon={<ReactSVG src={SubscriptionIcon} />}
          >
            {" "}
            Subscription
          </MenuItem>
          <MenuItem className="menuitem-category"> General</MenuItem>
          <MenuItem
            className="menuitem-custom"
            active={location.pathname === "/notification"}
            onClick={() => navigate("/notification")}
            icon={<ReactSVG src={EnquiryIcon} />}
          >
            {" "}
            Notification
          </MenuItem>
          <MenuItem
            className="menuitem-custom"
            active={location.pathname === "/feedback"}
            onClick={() => navigate("/feedback")}
            icon={<ReactSVG src={FeedbackIcon} />}
          >
            {" "}
            Feedback
          </MenuItem>
          <MenuItem
            className="menuitem-custom"
            active={
              location.pathname === "/magazine" ||
              location.pathname === "/magazine/add-magazine" ||
              location.pathname === "/magazine/edit-magazine"
            }
            onClick={() => navigate("/magazine")}
            icon={<ReactSVG src={NewaIcon} />}
          >
            {" "}
            Magazine
          </MenuItem>
          {/* <MenuItem
            className="menuitem-custom"
            // active={location.pathname === "/page"}
            // onClick={() => navigate("/page")}
            icon={<ReactSVG src={SubscriptionIcon} />}
          >
            {" "}
            Pages
          </MenuItem> */}
        </Menu>
      </Sidebar>
    </StyledSidebar>
  );
}
