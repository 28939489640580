import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../../utility/constants";
import { useEffect, useState } from "react";
import { getCategoryById, updateCategoryById } from "./apiHandler";
import { notifySuccess } from "../../utility/common";
import CategoryForm from "./CategoryForm";
import Breadcrumbs from "../../Breadcrumbs";
import { useFormik } from "formik";
import categoryValidationSchema from "./validation";

export default function EditCategory() {
  const navigate = useNavigate();
  const token = getToken();
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    parent: 0,
    category_name: "",
    slug: "",
    heading: "",
    breadcrumb: "",
    image: "",
    description: "",
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    meta_canonical: "",
    status: 1,
  });

  useEffect(() => {
    const fetchCategoryData = async () => {
      const response = await getCategoryById(id, token);
      setInitialValues(response.data.category);
    };

    fetchCategoryData();
  }, [id, token]);

  const { handleSubmit, values, setFieldValue, touched, errors } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: categoryValidationSchema,
    onSubmit: (values) => {
      delete values.id;
      delete values.created_date;
      delete values.updated_date;
      updateCategoryById(id, values, token)
        .then((res) => {
          if (res.status === 200) {
            notifySuccess("Category updated successfully");
            setTimeout(() => {
              navigate("/category");
            }, 1500);
          }
        })
        .catch((error) => {
          console.error("Error updating category:", error);
        });
    },
  });
  return (
    <>
      <Breadcrumbs />
      <div>
        <CategoryForm
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          handleSubmit={handleSubmit}
          type={"UPDATE"}
        />
      </div>
    </>
  );
}
