import { notifyError, notifySuccess } from "../../../utility/common";
import { getToken, renderDateTime } from "../../../utility/constants";
import { addCarGallery } from "../apiHandler";
import CarGalleryForm from "./CarGalleryForm";
import GalleryFormHandler from "./GalleryFormHandler";

export default function CarAddGallery() {
  const initialValues = {
    brand_id: "",
    model_id: "",
    publish_date: new Date(),
    status: 0,
    home_gallery: false,
    images: [],
  };

  const handleSubmit = async (values, action) => {
    const formData = new FormData();
    formData.append("brand_id", values.brand_id);
    formData.append("model_id", values.model_id);
    formData.append("publish_date", renderDateTime(values.publish_date));
    formData.append("status", values.status);
    formData.append("home_gallery", values.home_gallery);

    values.images.forEach((image, index) => {
      formData.append(`images[${index}][file]`, image.file);
      formData.append(`images[${index}][caption]`, image.caption);
      formData.append(`images[${index}][alt]`, image.alt);
      formData.append(`images[${index}][position]`, image.position);
      formData.append(`images[${index}][order]`, image.order);
    });

    try {
      const data = await addCarGallery(formData, getToken());
      if (data.status === 200) {
        action.resetForm();
        notifySuccess("Gallery added successfully");
      } else {
        const responseData = data.response ? data.response.data : data.data;
        const errorMessage = responseData.message || "An error occurred";
        notifyError(errorMessage);
      }
    } catch (error) {
      console.log("Error:", error);
      notifyError(error.message);
    }
  };

  return (
    <GalleryFormHandler
      initialValues={initialValues}
      onSubmit={handleSubmit}
      type={"add"}
    />
  );
}
