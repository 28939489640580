import DownloadIcon from "../../../../assets/icons/bootstrap-icons/download.svg";
import UploadIcon from "../../../../assets/icons/bootstrap-icons/upload.svg";
import CarModelBtn from "../../../../components/CarModelBtn";
import ExportMagazine from "../../../../components/ExportMagazine";
import { getMagazinData } from "../../../../request";
import { downloadEditorial, downloadUpcoming } from "./apiHandler";
const CarModelBtnGroup = ({ navigate }) => {
  const downloadEachEditorialReview = async () => {
    const response = await downloadEditorial();
    if (response.data.url) {
      window.open(response.data.url, "_blank");
    }
  };

  const downloadUpcomingPreview = async () => {
    const response = await downloadUpcoming();
    if (response.data.url) {
      window.open(response.data.url, "_blank");
    }
  };
  return (
    <div className="w-100">
      <div className="btngroup d-flex flex-wrap justify-content-end">
        <CarModelBtn
          label={"Download Each Upcoming Preview in Text Files"}
          icon={DownloadIcon}
          onClick={downloadUpcomingPreview}
          className={
            "preview-button d-flex align-items-center justify-content-between me-1 mb-2 text-white px-3"
          }
        />

        <CarModelBtn
          label={"Download Each Editorial Rreview in Text Files"}
          icon={DownloadIcon}
          onClick={downloadEachEditorialReview}
          className={
            "download-button d-flex align-items-center justify-content-between me-1 mb-2 text-white px-3 py-2"
          }
        />
        <ExportMagazine
          api={getMagazinData}
          payload={JSON.stringify([9, 3, 2])}
        />
      </div>
    </div>
  );
};

export default CarModelBtnGroup;
