import HeadingTwo from "./HeadingTwo";

export default function VersionManagementItem() {
  return (
    <div className="row dashboard card">
      <div className="card-headers w-100 border-bottom d-flex p-3 ps-0 align-items-center justify-content-between">
        <div className="d-flex align-items-center ps-3">
          <HeadingTwo
            title="Engine Type"
            type="dashboard"
            showHeader={true}
            pointerOnHover={true}
          />
        </div>
        <div className="">
          <button className="green-filled-button">Save</button>
        </div>
      </div>
      <div className="col-12 p-2">
        <div className="d-flex justify-content-end align-items-center p-2">
          <div>
            <label htmlFor="checkAll" className="form-label p-2">
              Check for All
            </label>
          </div>
          <div>
            <input type="checkbox" id="checkAll"></input>
          </div>
        </div>
        <div>
          <table className="w-100">
            <tr className="w-100">
              <td className="">Comfortline 1.0 TSI MT</td>
              <td className="">
                <input className="form-control border border-2 shadow-none" />
              </td>
            </tr>

            <tr className="w-100">
              <td className="">Highline 1.0 TSI MT</td>
              <td className="">
                <input className="form-control border border-2 shadow-none" />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}
