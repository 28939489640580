import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import {
  metaRobotsIndex,
  metaRobotsFollow,
  brandStatus,
  correctImagePath
} from "../../../utility/constants";
import FormSelect from "../../../components/FormSelect";
import FormControl from "../../../components/FormControl";
import FormFieldErrorMsg from "../../../components/FormFieldErrorMsg";
import { Image as ReactImage } from "react-bootstrap";
import EyeIcon from "../../../assets/icons/eye_icon.png";
import UpdateIcon from "../../../assets/icons/bootstrap-icons/update white 1.svg";
import JoditEditor from "jodit-react";
import { notifyError } from "../../../utility/common";
const image_base_url = process.env.REACT_APP_IMAGE_BASE_URL;

export default function CarBrandForm({
  touched,
  errors,
  values,
  setFieldValue,
  handleSubmit,
  type,
  onView
}) {
  const editor = useRef(null);

  console.log("values.brand_image", values);

  return (
    <Form onSubmit={handleSubmit}>
      <div className="col-12 d-flex">
        <div className="col-xl-9 col-sm-8 pe-2">
          <Accordion defaultActiveKey="0" className="mb-4">
            <Accordion.Item
              eventKey="0"
              className="card-height header_padding_top"
            >
              <Accordion.Header className="p-3 pb-1 ps-2 pt-4">
                Primary Details
              </Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-6">
                    <FormControl
                      controlId={"brandNameInput"}
                      label={"Brand Name"}
                      placeholder={"Brand Name"}
                      touched={touched}
                      errors={errors}
                      name={"brand_name"}
                      value={values["brand_name"]}
                      setFieldValue={setFieldValue}
                      className="mb-3"
                      isRequired={true}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormControl
                      controlId={"SlugControlInput"}
                      label={"Slug"}
                      placeholder={"Slug"}
                      touched={touched}
                      errors={errors}
                      name={"brand_url"}
                      value={values["brand_url"]}
                      setFieldValue={setFieldValue}
                      className="mb-3"
                      // isRequired={true}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormSelect
                      controlId={"StatusControlSelect"}
                      label={"Status"}
                      name={"status"}
                      defaultName={"Select Status"}
                      setFieldValue={setFieldValue}
                      items={brandStatus}
                      itemName={"value"}
                      touched={touched}
                      errors={errors}
                      value={values["status"]}
                      className="mb-3"
                      isRequired={true}
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion defaultActiveKey="0" className="mb-4">
            <Accordion.Item eventKey="0" className="header_padding_top">
              <Accordion.Header className="p-3 pb-2 ps-2 pt-4">
                Short Description
              </Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-12">
                    <FormControl
                      controlId={"ShortControlInput"}
                      label={"Short Description"}
                      placeholder={"Short Description"}
                      touched={touched}
                      errors={errors}
                      name={"short_description"}
                      value={values["short_description"]}
                      setFieldValue={setFieldValue}
                      as="textarea"
                      rows={8}
                      className="mb-3"
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion defaultActiveKey="0" className="mb-4">
            <Accordion.Item eventKey="0" className="header_padding_top">
              <Accordion.Header className="p-3 pb-2 ps-2 pt-4">
                Brand Description
              </Accordion.Header>

              <Accordion.Body>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <JoditEditor
                        placeholder="Enter Content"
                        ref={editor}
                        value={values["brand_content"]}
                        onBlur={(newContent) =>
                          setFieldValue("brand_content", newContent)
                        }
                      />
                      {/* <RichTextEditor
                        value={value}
                        name={"brand_content"}
                        onChange={(value) => {
                          setValue(value);
                          setFieldValue(
                            "brand_content",
                            value.toString("html")
                          );
                        }}
                      /> */}
                    </Form.Group>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion defaultActiveKey="0" className="mb-4">
            <Accordion.Item eventKey="0" className="header_padding_top">
              <Accordion.Header className="p-3 pb-2 ps-2 pt-4">
                Model Seo Tags
              </Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-12">
                    <FormControl
                      controlId={"seoTitleInput"}
                      label={"Seo Title"}
                      placeholder={"Meta Title"}
                      touched={touched}
                      errors={errors}
                      name={"meta_title"}
                      value={values["meta_title"]}
                      setFieldValue={setFieldValue}
                      className="mb-3"
                    />
                  </div>
                  <div className="col-md-12">
                    <FormControl
                      controlId={"metaDescriptionInput"}
                      label={"Meta Description"}
                      placeholder={"Meta Description"}
                      touched={touched}
                      errors={errors}
                      name={"meta_description"}
                      value={values["meta_description"]}
                      setFieldValue={setFieldValue}
                      className="mb-3"
                    />
                  </div>
                  <div className="col-md-12">
                    <FormControl
                      controlId={"StatusControlSelect"}
                      label={"Meta Keywords"}
                      placeholder={"Description"}
                      touched={touched}
                      errors={errors}
                      name={"meta_keyword"}
                      value={values["meta_keyword"]}
                      setFieldValue={setFieldValue}
                      as="textarea"
                      className={"mb-3"}
                      rows={3}
                    />
                  </div>
                  <div className="col-md-12">
                    <FormControl
                      controlId={"StatusControlSelect"}
                      label={"Canonical URL"}
                      placeholder={"Canonical URL"}
                      touched={touched}
                      errors={errors}
                      name={"canonical_url"}
                      value={values["canonical_url"]}
                      setFieldValue={setFieldValue}
                      className="mb-3"
                    />
                  </div>
                  <div className="col-md-6">
                    <FormSelect
                      controlId={"MetaRobotsIndexSelect"}
                      label={"Meta Robots Index"}
                      name={"robots_index"}
                      defaultName={"Select"}
                      setFieldValue={setFieldValue}
                      items={metaRobotsIndex}
                      itemName={"value"}
                      touched={touched}
                      errors={errors}
                      value={values["robots_index"]}
                      className="mb-3"
                    />
                  </div>
                  <div className="col-md-6">
                    <FormSelect
                      controlId={"MetaRobotsFollowSelect"}
                      label={"Meta Robots Follow"}
                      name={"robots_follow"}
                      defaultName={"Select"}
                      setFieldValue={setFieldValue}
                      items={metaRobotsFollow}
                      itemName={"value"}
                      touched={touched}
                      errors={errors}
                      value={values["robots_follow"]}
                      className="mb-3"
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="col-xl-3  col-sm-4 ps-2">
          <Accordion defaultActiveKey="0" className="mb-4">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="p-3 pb-1 ps-2 ">
                Save
              </Accordion.Header>
              <Accordion.Body className="pt-1 d-flex">
                {type === "Update" ? (
                  <div className="w-100 d-flex justify-content-between">
                    <button
                      className="d-flex download-button align-items-center py-2 px-3 mb-2"
                      type="button"
                      onClick={() => onView(values["id"])}
                    >
                      <ReactImage
                        src={EyeIcon}
                        width="10px"
                        height="10px"
                        className="me-1"
                      />{" "}
                      View Page
                    </button>

                    <button
                      type="submit"
                      className="d-flex update-button align-items-center rounded mb-2"
                    >
                      <ReactImage
                        src={UpdateIcon}
                        width="10px"
                        height="10px"
                        className="me-1"
                      />{" "}
                      {type}
                    </button>
                  </div>
                ) : (
                  <>
                    <button
                      type="submit"
                      className="d-flex save-button align-items-center rounded mb-2"
                    >
                      {" "}
                      {type}
                    </button>
                  </>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey="0" className="mb-4">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="p-3 pb-2 ps-2 pb-0 brand_logo_btn">
                Brand Logo <sup className="required-superscript">*</sup>
                <small className="small-text">(Format : PNG, JPG, JPEG)</small>
              </Accordion.Header>
              <Accordion.Body className="pt-0 ">
                <div className="mt-5 pt-3">
                  {(values.brand_image &&
                    typeof values.brand_image === "string") ||
                  (values.brand_image && values.brand_image instanceof File) ? (
                    <img
                      src={
                        values.brand_image instanceof File
                          ? URL.createObjectURL(values.brand_image)
                          : `${correctImagePath(values.brand_image, "car")}`
                      }
                      className="w-75 h-75"
                      alt="Brand Logo"
                    />
                  ) : null}
                  <Form.Group controlId="formFile" className="mb-1">
                    <div className="file-container">
                      <input
                        type="file"
                        className="form-control shadow-none file-input m-0 me-3"
                        accept="image/png, image/jpg, image/jpeg"
                        id="fileInput"
                        onChange={(e) => {
                          const selectedFile = e.target.files[0];
                          const maxSizeKB = 200;
                          if (selectedFile) {
                            if (selectedFile.size > maxSizeKB * 1024) {
                              notifyError(
                                "Image size exceeds 200Kb. Please choose a smaller image."
                              );
                              e.target.value = "";
                              return;
                            }
                            const img = new Image();
                            const objectUrl = URL.createObjectURL(selectedFile);

                            img.onload = () => {
                              if (img.width !== 100 && img.height !== 100) {
                                notifyError("Image dimensions must be 100x100");
                                e.target.value = "";
                                return;
                              }
                              setFieldValue("brand_image", selectedFile);
                              URL.revokeObjectURL(objectUrl);
                            };
                            img.onerror = () => {
                              notifyError("Invalid image file.");
                              e.target.value = "";
                              URL.revokeObjectURL(objectUrl);
                            };

                            img.src = objectUrl;
                          } else {
                            setFieldValue("brand_image", null);
                          }
                        }}
                        name="brand_image"
                      />
                      <FormFieldErrorMsg
                        touched={touched}
                        errors={errors}
                        name={"brand_image"}
                      />
                    </div>
                    <Form.Label className="logo-dimension">
                      Max 200Kb & Dimension: 100X100
                    </Form.Label>
                  </Form.Group>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion defaultActiveKey="0" className="mb-4">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="p-3 pb-1 ps-2">
                Brand Display Ordering
              </Accordion.Header>
              <Accordion.Body>
                <div className="mt-4 pb-2">
                  <FormControl
                    controlId={"exampleForm.ControlInput1"}
                    label={""}
                    placeholder={"Brand Display Ordering"}
                    touched={touched}
                    errors={errors}
                    name={"brand_ordering"}
                    value={values["brand_ordering"]}
                    setFieldValue={setFieldValue}
                    className="display-ordering mb-3"
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </Form>
  );
}
