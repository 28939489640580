import { useNavigate } from "react-router-dom";
import { getToken, getUserId } from "../../utility/constants";
import FAQapiHandler from "./FAQapiHandler";
import { notifyError, notifySuccess } from "../../utility/common";
import BikeAnswerForm from "./BikeAnswerForm";

export default function AddBikeAnswer() {
  const navigate = useNavigate();
  const token = getToken();
  const userId = getUserId();
  const AddBikeAnswer = async (data) => {
    try {
      const response = await FAQapiHandler.addBikeAnswer(token, data);
      console.log(response);
      if (response.status === 200) {
        notifySuccess(response.data.message);
        setTimeout(() => {
          navigate("/bike-answers");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      notifyError(error.response.data.message);
    }
  };

  const initialValues = {
    question_id: -1,
    answer_name: "",
    answer_status: -1,
    user_id: userId,
  };

  return (
    <div>
      <BikeAnswerForm initialValues={initialValues} onSubmit={AddBikeAnswer} />
    </div>
  );
}
