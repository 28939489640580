import { Button, Card, Form } from "react-bootstrap";
import { getToken } from "../../../utility/constants";
import FormControl from "../../../components/FormControl";
import FormSelect from "../../../components/FormSelect";
import FormControlNumber from "../../../components/FormControlNumber";

export default function CarFaqForm({
  touched,
  errors,
  values,
  setFieldValue,
  handleSubmit,
  type,
}) {
  return (
    <Card className="p-3 w-50 " style={{ height: "min-content" }}>
      <Card.Title className="mb-3">FAQ Details</Card.Title>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <FormControl
            controlId={"questionInput"}
            label={"Question"}
            placeholder={"Enter Question"}
            touched={touched}
            errors={errors}
            name="question"
            value={values["question"]}
            setFieldValue={setFieldValue}
            isRequired={true}
            as={"textarea"}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <FormControl
            controlId={"answerInput"}
            label={"Answer"}
            placeholder={"Enter Answer"}
            touched={touched}
            errors={errors}
            name="answer"
            value={values["answer"]}
            setFieldValue={setFieldValue}
            isRequired={true}
            as="textarea"
          />
        </Form.Group>
        <div className="col-12 d-flex gap-4 mb-3">
          <div style={{ width: "50%" }}>
            <FormSelect
              controlId={"isDefaultInput"}
              label={"Is Default"}
              touched={touched}
              errors={errors}
              name="is_default"
              value={values["is_default"]}
              setFieldValue={setFieldValue}
              items={[
                { id: 1, name: "Yes" },
                { id: 0, name: "No" },
              ]}
              itemName={"name"}
              isRequired={true}
            />
          </div>
          <div style={{ width: "50%" }}>
            <FormControlNumber
              controlId={"faqOrderingInput"}
              label={"Faq Ordering"}
              placeholder={"Enter Faq Ordering"}
              touched={touched}
              errors={errors}
              name="faq_ordering"
              value={values["faq_ordering"]}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>
        <Form.Group className="mb-3">
          <FormControl
            controlId={"faq_condition"}
            label={"Faq Condition"}
            placeholder={"Enter Faq Condition"}
            touched={touched}
            errors={errors}
            name="faq_condition"
            value={values["faq_condition"]}
            setFieldValue={setFieldValue}
            isRequired={true}
            as={"textarea"}
          />
        </Form.Group>
        <Button
          type="submit"
          variant="btnBlue"
          className="d-flex align-items-center justify-content-between my-3"
        >
          {type}
        </Button>
      </Form>
    </Card>
  );
}
