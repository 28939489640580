import React, { useState } from "react";
import { login } from "../request";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import InputGroup from "./InputGroup";

import SubmitButton from "./SubmitButton";
import {
  adminType,
  setRole,
  setToken,
  setUserId,
  setUserImage,
  setUserName
} from "../utility/constants";
import UserIcon from "../assets/icons/bootstrap-icons/auth-images/Male User.svg";
import LockIcon from "../assets/icons/bootstrap-icons/auth-images/Lock.svg";

export default function Login() {
  const navigate = useNavigate();

  const [error, setError] = useState("");

  const initialValues = {
    user_name: "",
    password: ""
  };

  const validationSchema = Yup.object({
    user_name: Yup.string()
      // .email("Invalid email address")
      .required("Username or Email is required"),
    password: Yup.string().required("Password is required")
  });
  return (
    <main className="w-100 h-100">
      <div className="auth-container-inner">
        <div className="row h-100">
          <div className="col-sm-10 col-md-10 col-lg-10 col-xl-10 col-lg-10 mx-auto h-100">
            <div className="align-middle">
              {/* <div className="text-center mt-4">
                <div className="auth-heading">Sign In</div>
                <p className="fw-400 p-3">
                  Please enter your credentials to sign in!
                </p>
              </div> */}

              <div className="">
                <div className="">
                  <div className="m-sm-3">
                    {error ? (
                      <div className="auth-error">
                        Something went wrong, please try again later
                      </div>
                    ) : null}
                    <div className="mb-3">
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                          login(values)
                            .then((res) => {
                              console.log(res);
                              setUserId(res.data.data.adminId);
                              setRole(res.data.data.role);
                              setToken(
                                res.data.data.token,
                                res.data.data.token_expiration_time
                              );
                              adminType(
                                res.data.data.admin_type,
                                res.data.data.role
                              );
                              setUserName(res.data.data.display_name);
                              setUserImage(res.data.data.display_image);

                              // navigate("/dashboard");
                              window.location.href = "/dashboard";
                            })
                            .catch((err) => {
                              console.log(err);
                              setError(err);
                              setTimeout(() => {
                                setError("");
                              }, 4000);
                            });
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,

                          handleSubmit,

                          setFieldValue
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                              <InputGroup
                                type="text"
                                position="left"
                                icon={UserIcon}
                                placeholder={"Username or Email"}
                                name={"user_name"}
                                touched={touched}
                                errors={errors}
                                value={values["user_name"]}
                                setFieldValue={setFieldValue}
                                auth={true}
                              />
                            </div>
                            <div className="mb-3">
                              <InputGroup
                                type="password"
                                position="left"
                                icon={LockIcon}
                                placeholder={"Password"}
                                touched={touched}
                                errors={errors}
                                name={"password"}
                                value={values["password"]}
                                setFieldValue={setFieldValue}
                                auth={true}
                              />
                            </div>
                            <div className="d-grid gap-2 mt-5">
                              <SubmitButton title="Login" />
                            </div>
                            {/* <div className="mt-3 mb-3 text-center">
                              <small>
                                <Link
                                  to="/forgot-password"
                                  className="forgot-password-link my-2"
                                >
                                  Forgot Password?
                                </Link>
                              </small>
                            </div> */}
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
