import React from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../Breadcrumbs";
import { ReactSVG } from "react-svg";
import BackIcon from "../../../assets/icons/bootstrap-icons/Back_Arrow.svg";
import { getToken } from "../../../utility/constants";
import { addBrand } from "./apiHandler";
import { ToastContainer } from "react-toastify";
import { carBrandValidation } from "./schemaValidation";
import { notifyError, notifySuccess } from "../../../utility/common";
import CarBrandForm from "./CarBrandForm";

export default function AddCarBrand() {
  const token = getToken();
  const navigate = useNavigate();

  const { handleSubmit, values, errors, setFieldValue, touched } = useFormik({
    initialValues: {
      brand_name: "",
      brand_url: "",
      brand_image: "",
      brand_content: "",
      brand_ordering: 1,
      canonical_url: "",
      meta_description: "",
      meta_keyword: "",
      meta_title: "",
      robots_follow: "nofollow",
      robots_index: "noindex",
      short_description: "",
      status: -1,
    },
    validationSchema: carBrandValidation,
    onSubmit: async (values, action) => {
      const payload = values;
      const formData = new FormData();
      try {
        for (let e of Object.entries(payload)) {
          formData.append(e[0], e[1]);
        }
        // const response = 5;
        const response = await addBrand(token, formData);
        console.log(response, "response");
        if (response.status === 200) {
          notifySuccess("Added Successfully !!");
          action.resetForm();

          navigate(`/car-brand/edit-car-brand/${response.data.result.id}`);
        } else {
          const responseData = response.response
            ? response.response.data
            : response.data;

          const errorMessage = responseData.message || "An error occurred";
          notifyError(errorMessage);
        }
      } catch (error) {
        console.log(error);
        const errorMessage = error.response
          ? error.response.data.message
          : "An error occurred";
        notifyError(errorMessage);
      }
    },
  });

  return (
    <div className="row dashboard">
      <ToastContainer />
      <div className="row">
        <div className="col-xl-11 col-sm-11">
          <Breadcrumbs />
        </div>
        <div className="col-xl-1 col-sm-1">
          <button
            className="d-flex back-button py-2 px-3 mb-4 btn btn-primary"
            onClick={() => navigate("/car-brand")}
          >
            {" "}
            <ReactSVG src={BackIcon} className="me-1" />
            Back
          </button>
        </div>
      </div>
      <CarBrandForm
        values={values}
        errors={errors}
        touched={touched}
        handleSubmit={handleSubmit}
        setFieldValue={setFieldValue}
        type={"Save"}
      />
    </div>
  );
}
