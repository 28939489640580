import axios from "axios";
import { BASE_URL_AUTOX, GET_CAR_CITY_LIST, getConfig } from "../../../request";

/**
 * Get List of Car City
 * getCarCityList is used to get List of Car cities
 * @param token
 * @param payload
 */
export function getCarCityList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_CAR_CITY_LIST}`,
    payload,
    getConfig(token)
  );
}
//eslint-disable-next-line
export default {
  getCarCityList,
};
