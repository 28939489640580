import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import DashboardWidgetOne from "../../components/DashboardWidgetOne";
import HeadingTwo from "../../components/HeadingTwo";

import NewCarImage from "../../assets/icons/bootstrap-icons/dashboard-icons/New Car.png";
import NewBikeImage from "../../assets/icons/bootstrap-icons/dashboard-icons/Bike.png";
import NewFeedbackImage from "../../assets/icons/bootstrap-icons/dashboard-icons/Feedback.png";
import NewNewaImage from "../../assets/icons/bootstrap-icons/dashboard-icons/Newa.png";

import FacebookImage from "../../assets/icons/bootstrap-icons/dashboard-icons/facebook.png";
import InstagramImage from "../../assets/icons/bootstrap-icons/dashboard-icons/instagram.png";
import NewsletterImge from "../../assets/icons/bootstrap-icons/dashboard-icons/newsletter.png";
import TwitterImage from "../../assets/icons/bootstrap-icons/dashboard-icons/twitter.png";
import YoutubeImage from "../../assets/icons/bootstrap-icons/dashboard-icons/youtube.png";

import LinkedInImage from "../../assets/icons/bootstrap-icons/dashboard-icons/linkedin.png";

import Breadcrumbs from "../../Breadcrumbs";
import { getToken } from "../../utility/constants";
import apiHandler from "./apiHandler.js";

export default function Dashboard() {
  const token = getToken();
  const [stats, setStats] = React.useState({});
  useEffect(() => {
    const fetchStats = async () => {
      const response = await apiHandler.getStats(token);
      setStats(response.data[0]);
    };
    fetchStats();
  }, []);
  console.log(stats);
  const makeSitemap = () => {
    try {
      apiHandler.makeSitemap(token);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Layout>
      <div className="row dashboard mt-0">
        <div className="mb-2">
          <Breadcrumbs removeId={true} />
        </div>
        <div className="d-flex flex-column">
          <div className="col-12 d-flex">
            <div className="mb-2 dashboard-widget">
              <DashboardWidgetOne
                count={stats?.total_cars_count}
                message={"Total New Cars"}
                picture={NewCarImage}
                redirectLink={"/comment"}
                imageBgColor={"#845ADF"}
                growth={stats?.cars_percentage_change}
              />
            </div>

            <div className="mb-2 dashboard-widget">
              <DashboardWidgetOne
                count={stats?.total_bikes_count}
                message={"Total Bikes"}
                picture={NewBikeImage}
                redirectLink={"/post"}
                imageBgColor={"#23B7E5"}
                growth={stats?.bike_percentage_change}
              />
            </div>

            <div className="mb-2 dashboard-widget">
              <DashboardWidgetOne
                count={stats?.total_posts_count}
                message={"Total Drafts"}
                picture={NewNewaImage}
                redirectLink={"/gallery"}
                imageBgColor={"#26BF94"}
                growth={stats?.posts_percentage_change}
              />
            </div>

            <div className="mb-2 dashboard-widget">
              <DashboardWidgetOne
                count={stats?.total_reviews_count}
                message={"Total Reviews"}
                picture={NewFeedbackImage}
                redirectLink={"/review"}
                imageBgColor={"#F5B849"}
                growth={stats?.reviews_percentage_change}
              />
            </div>
          </div>

          <div className="d-flex mt-3">
            <div className="dashboard-section d-flex mt-3">
              {/* <div className="col-3"> */}
              <div className="card card-rounded-dashboard">
                <div className="w-100 d-flex p-3 align-items-center">
                  <div className="">
                    <HeadingTwo
                      title="Social Stats"
                      type="dashboard"
                      showHeader={true}
                      pointerOnHover={true}
                    />
                  </div>
                </div>
                <div className="col-12 d-flex flex-wrap p-5">
                  <div className="col-6 d-flex mt-1">
                    <div className="social-text-field">
                      {"Instagram"}
                      <div className="mt-2">
                        <input
                          type="text"
                          className="form-control border dashboard-input shadow-none"
                          id="tagName"
                          value={"67K"}
                        />
                      </div>
                      <div className="d-flex">
                        <div
                          className="mt-2"
                          style={{
                            fontSize: "10px",
                            color: "#26BF94",
                            fontWeight: "500",
                          }}
                        >
                          {".25%"} &nbsp; {"^"}
                        </div>
                        <div
                          className="mt-2"
                          style={{
                            fontSize: "10px",
                            color: "#AAAAAA",
                            fontWeight: "500",
                          }}
                        >
                          &nbsp;{"This Month"}
                        </div>
                      </div>
                    </div>
                    <div className="social-logo">
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          height: "3rem",
                          width: "3rem",
                          backgroundColor: "#F3F6F8",
                          borderRadius: "8px",
                        }}
                      >
                        {/* <div> */}
                        <img src={InstagramImage} alt="instagram" />
                        {/* </div> */}
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-6 d-flex"> */}
                  <div className="col-6 d-flex mt-1">
                    <div className="social-text-field">
                      {"Facebook"}

                      <div className="mt-2">
                        <input
                          type="text"
                          className="form-control border dashboard-input shadow-none"
                          id="tagName"
                          value={"269.7K"}
                        />
                      </div>
                      <div className="d-flex">
                        <div
                          className="mt-2"
                          style={{
                            fontSize: "10px",
                            color: "#26BF94",
                            fontWeight: "500",
                          }}
                        >
                          {".25%"} &nbsp; {"^"}
                        </div>
                        <div
                          className="mt-2"
                          style={{
                            fontSize: "10px",
                            color: "#AAAAAA",
                            fontWeight: "500",
                          }}
                        >
                          &nbsp;{"This Month"}
                        </div>
                      </div>
                    </div>
                    <div className="social-logo">
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          height: "3rem",
                          width: "3rem",
                          backgroundColor: "#F3F6F8",
                          borderRadius: "8px",
                        }}
                      >
                        {/* <div> */}
                        <img src={FacebookImage} alt="facebook" />
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                  {/* </div> */}

                  {/* <div className="col-6"> */}
                  <div className="col-6 d-flex mt-5">
                    <div className="social-text-field">
                      {"Youtube"}

                      <div className="mt-2">
                        <input
                          type="text"
                          className="form-control border dashboard-input shadow-none"
                          id="tagName"
                          value={"97.4K"}
                        />
                      </div>

                      <div className="d-flex">
                        <div
                          className="mt-2"
                          style={{
                            fontSize: "10px",
                            color: "#26BF94",
                            fontWeight: "500",
                          }}
                        >
                          {".25%"} &nbsp; {"^"}
                        </div>
                        <div
                          className="mt-2"
                          style={{
                            fontSize: "10px",
                            color: "#AAAAAA",
                            fontWeight: "500",
                          }}
                        >
                          &nbsp;{"This Month"}
                        </div>
                      </div>
                    </div>
                    <div className="social-logo">
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          height: "3rem",
                          width: "3rem",
                          backgroundColor: "#F3F6F8",
                          borderRadius: "8px",
                        }}
                      >
                        {/* <div> */}
                        <img src={YoutubeImage} alt="youtube" />
                        {/* </div> */}
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-6"> */}
                  <div className="col-6 d-flex mt-5">
                    <div className="social-text-field">
                      {"Twitter"}

                      <div className="mt-2">
                        <input
                          type="text"
                          className="form-control border dashboard-input shadow-none"
                          id="tagName"
                          value="36.7K"
                        />
                      </div>

                      <div className="d-flex">
                        <div
                          className="mt-2"
                          style={{
                            fontSize: "10px",
                            color: "#26BF94",
                            fontWeight: "500",
                          }}
                        >
                          {".25%"} &nbsp; {"^"}
                        </div>
                        <div
                          className="mt-2"
                          style={{
                            fontSize: "10px",
                            color: "#AAAAAA",
                            fontWeight: "500",
                          }}
                        >
                          &nbsp;{"This Month"}
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                    <div className="social-logo">
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          height: "3rem",
                          width: "3rem",
                          backgroundColor: "#F3F6F8",
                          borderRadius: "8px",
                        }}
                      >
                        {/* <div> */}
                        <img src={TwitterImage} alt="twitter" />
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                  {/* </div> */}

                  {/* <div className="col-6"> */}
                  <div className="col-6 d-flex mt-5">
                    <div className="social-text-field">
                      {"Newsletter"}

                      <div className="mt-2">
                        <input
                          type="text"
                          className="form-control border dashboard-input shadow-none"
                          id="tagName"
                          value="14.5K"
                        />
                      </div>
                      <div className="d-flex">
                        <div
                          className="mt-2"
                          style={{
                            fontSize: "10px",
                            color: "#26BF94",
                            fontWeight: "500",
                          }}
                        >
                          {".25%"} &nbsp; {"^"}
                        </div>
                        <div
                          className="mt-2"
                          style={{
                            fontSize: "10px",
                            color: "#AAAAAA",
                            fontWeight: "500",
                          }}
                        >
                          &nbsp;{"This Month"}
                        </div>
                      </div>
                    </div>
                    <div className="social-logo">
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          height: "3rem",
                          width: "3rem",
                          backgroundColor: "#F3F6F8",
                          borderRadius: "8px",
                        }}
                      >
                        {/* <div> */}
                        <img src={NewsletterImge} alt="newsletter" />
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>

                <div className="d-flex justify-content-center mb-5">
                  <button
                    className=""
                    style={{
                      height: "45px",
                      width: "70%",
                      backgroundColor: "#46B47D",
                      border: "none",
                      borderRadius: "10px",
                      color: "#FFFFFF",
                    }}
                  >
                    {"Save"}
                  </button>
                </div>
                {/* </div> */}
              </div>
            </div>

            <div className="dashboard-section d-flex mt-3 align-self-start">
              <div className="card card-rounded-dashboard ">
                <div className="w-100 d-flex p-3 align-items-center">
                  <div className="d-flex">
                    <div className="d-flex align-items-center me-2">
                      <img src={LinkedInImage} alt="linkedin" />
                    </div>

                    <HeadingTwo
                      title="LinkedIn Token"
                      type="dashboard"
                      showHeader={true}
                      pointerOnHover={true}
                    />
                  </div>
                </div>

                <div className="col-12 mt-3 d-flex flex-wrap">
                  <div className="col-12">
                    <div className="p-3">
                      <b>{"Linkedin refresh token will expire in 549 days."}</b>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="p-3 col-12" style={{ color: "#9E9E9E" }}>
                      {
                        "Linkedin token is used to auto publish new post on autoX linkedin page. Please login using autoX official account only."
                      }
                    </div>
                  </div>

                  <div className="p-3 d-flex w-100 mb-3 gap-2">
                    <button
                      className=""
                      style={{
                        height: "45px",
                        width: "55%",
                        backgroundColor: "#5A7DDF",
                        border: "none",
                        borderRadius: "10px",
                        color: "#FFFFFF",
                      }}
                    >
                      {"Login with LinkedIn"}
                    </button>
                    <button
                      className=""
                      style={{
                        height: "45px",
                        width: "55%",
                        backgroundColor: "#5A7DDF",
                        border: "none",
                        borderRadius: "10px",
                        color: "#FFFFFF",
                      }}
                      onClick={() => makeSitemap()}
                    >
                      {"Make Site Map"}
                    </button>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
