import { Accordion, Card } from "react-bootstrap";

export default function FAQBikeShortCode() {
  return (
    <Card className="p-3 w-50">
      <Card.Title className="mb-3">Bike Short Codes</Card.Title>
      <div>
        <div className="p-3 border border-bottom-1 border-top-0 border-start-0 border-end-0">
          {"{BrandName}"} : Represent Brand Name
        </div>

        <div className="p-3 border border-bottom-1 border-top-0 border-start-0 border-end-0">
          {"{ModelName}"} : Model Name
        </div>

        <div className="p-3 border border-bottom-1 border-top-0 border-start-0 border-end-0">
          {"{DisplayName}"} : Brand And Model Name
        </div>

        <div className="p-3 border border-bottom-1 border-top-0 border-start-0 border-end-0">
          {"{LowestPriceVariantName}"} : Lowest Price Variant Name
        </div>

        <div className="p-3 border border-bottom-1 border-top-0 border-start-0 border-end-0">
          {"{HighestPriceVariantName}"} : Highest Price Variant Price
        </div>

        <div className="p-3 border border-bottom-1 border-top-0 border-start-0 border-end-0">
          {"{LowestVariantPrice}"} : Lowest Variant Price
        </div>

        <div className="p-3 border border-bottom-1 border-top-0 border-start-0 border-end-0">
          {"{HighestVariantPrice}"} : Highest Variant Price
        </div>

        <div className="p-3 border border-bottom-1 border-top-0 border-start-0 border-end-0">
          {"{CityVariantPrice}"} : City Variant Price
        </div>

        <div className="p-3 border border-bottom-1 border-top-0 border-start-0 border-end-0">
          {"{Mileage}"} : Model Variant Mileage
        </div>

        <div className="p-3 border border-bottom-1 border-top-0 border-start-0 border-end-0">
          {"{LaunchedDate}"} : Model Launched Date
        </div>

        <div className="p-3 border border-bottom-1 border-top-0 border-start-0 border-end-0">
          {"{LaunchedYear}"} : Model Launched Year
        </div>

        <div className="p-3 border border-bottom-1 border-top-0 border-start-0 border-end-0">
          {"{CityName}"} : Model City Name
        </div>

        <div className="p-3 border border-bottom-1 border-top-0 border-start-0 border-end-0">
          {"{Competitor}"} : Model Competitor
        </div>
      </div>
    </Card>
  );
}
