import * as Yup from "yup";

const addSafetyRatingSchema = Yup.object().shape({
  brand_id: Yup.number().required("Brand is required"),
  model_id: Yup.number().required("Model is required"),
  yearoftesting: Yup.number().required("Year of Testing is required"),
  crashtestweight: Yup.string().required("Crash Test Weight is required"),
  madein: Yup.string().required("Made in is required"),
  globalncapadultmaxscore: Yup.number()
    .min(0, "Global NCAP Adult Max Score must be greater than or equal to 0")
    .required("Global NCAP Adult Max Score is required"),
  globalncapadultrating: Yup.number()
    .min(0, "Global NCAP Adult Rating must be greater than or equal to 0")
    .required("Global NCAP Adult Rating is required"),
  globalncapadultachievedscore: Yup.number()
    .min(
      0,
      "Global NCAP Adult Achieved Score must be greater than or equal to 0"
    )
    .required("Global NCAP Adult Achieved Score is required"),
  globalncapchildmaxscore: Yup.number()
    .min(0, "Global NCAP Child Max Score must be greater than or equal to 0")
    .required("Global NCAP Child Max Score is required"),
  globalncapchildrating: Yup.number()
    .min(0, "Global NCAP Child Rating must be greater than or equal to 0")
    .required("Global NCAP Child Rating is required"),
  globalncapchildachievedscore: Yup.number()
    .min(
      0,
      "Global NCAP Child Achieved Score must be greater than or equal to 0"
    )
    .required("Global NCAP Child Achieved Score is required"),
  globalncapratingvideourl: Yup.string()
    .url("Global NCAP Rating Video URL must be a valid URL")
    .required("Global NCAP Rating Video URL is required"),
  globalncapbrochure: Yup.string()
    .url("Global NCAP Brochure must be a valid URL")
    .default(null),
  bharatncapadultmaxscore: Yup.number()
    .min(0, "Bharat NCAP Adult Max Score must be greater than or equal to 0")
    .nullable()
    .default(null),
  bharatncapadultrating: Yup.number()
    .min(0, "Bharat NCAP Adult Rating must be greater than or equal to 0")
    .nullable()
    .default(null),
  bharatncapadultachievedscore: Yup.number()
    .min(
      0,
      "Bharat NCAP Adult Achieved Score must be greater than or equal to 0"
    )
    .nullable()
    .default(null),
  bharatncapchildmaxscore: Yup.number()
    .min(0, "Bharat NCAP Child Max Score must be greater than or equal to 0")
    .nullable()
    .default(null),
  bharatncapchildrating: Yup.number()
    .min(0, "Bharat NCAP Child Rating must be greater than or equal to 0")
    .nullable()
    .default(null),
  bharatncapchildachievedscore: Yup.number()
    .min(
      0,
      "Bharat NCAP Child Achieved Score must be greater than or equal to 0"
    )
    .nullable()
    .default(null),
  bharatncapratingvideourl: Yup.string()
    .url("Bharat NCAP Rating Video URL must be a valid URL")
    .nullable()
    .default(""),
  bharatncapbrochure: Yup.string()
    .url("Bharat NCAP Brochure must be a valid URL")
    .nullable()
    .default(null),
});

export default addSafetyRatingSchema;
