import React, { useEffect, useState, memo } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { ReactSVG } from "react-svg";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL_AUTOX, getConfig } from "../request"; // Update based on your request module
import logo from "../assets/icons/bootstrap-icons/autoX - Logo.svg";
import styled from "styled-components";
import CarIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Cars.svg";
import BikeIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Bike.svg";
import CategoryIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Category management.svg";
import CommentsIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Comments.svg";
import DashboardIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/white home.svg";
import EditorIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Editor Management.svg";
import EnquiryFormIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Enquiry Form.svg";
import EnquiryIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Enquiry management.svg";
import FaqIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/faq.svg";
import FeedbackIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Feedback.svg";
import NewaIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Newa.svg";
import NewsletterIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Newsletter.svg";
import PageIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Page Management.svg";
import PostIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Post Management.svg";
import ReviewIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Review.svg";
import SidebarIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/sidebar.svg";
import StateIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/State.svg";
import SubscriptionIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Subscription.svg";
import TagsIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Tags Management.svg";
import TemplateIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Template.svg";
import ThemeIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/Theme.svg";
import WhiteManagerIcon from "../assets/icons/bootstrap-icons/sidebar-icons/new/White Manage Employer.svg";
import { AppSideBar } from "./AppSideBarProvider";
const iconMapping = {
  Dashboard: DashboardIcon,
  Cars: CarIcon,
  Bike: BikeIcon,
  "Category management": CategoryIcon,
  Comments: CommentsIcon,
  "Editor Management": EditorIcon,
  "Enquiry Form": EnquiryFormIcon,
  "Enquiry management": EnquiryIcon,
  faq: FaqIcon,
  Feedback: FeedbackIcon,
  Magazine: NewaIcon,
  Newsletter: NewsletterIcon,
  "Page Management": PageIcon,
  "Post Management": PostIcon,
  Review: ReviewIcon,
  "State management": StateIcon,
  "Subscription management": SubscriptionIcon,
  "Tags Management": TagsIcon,
  Template: TemplateIcon,
  Theme: ThemeIcon,
  "White Manage Employer": WhiteManagerIcon,
};

const StyledSidebar = styled.div`
  background-color: #1d325e;
  color: #a3aed1;
`;

const SidebarComponentTest = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [notificationCount, setNotificationCount] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { collapsed } = useProSidebar();
  const { open, setOpen } = AppSideBar();

  const getRole = () => {
    const role = localStorage.getItem("user_role");
    return role ? JSON.parse(role) : [];
  };

  const filterMenuItemsByRole = (menuItems, userRoles) => {
    return menuItems.filter(
      (item) => userRoles.includes(+item.id) || item.parent > 0
    );
  };

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL_AUTOX}editor/sidebar-modules`
        );
        const notificationCountResponse = await axios.get(
          `${BASE_URL_AUTOX}admin/sidebar-notification`
        );
        setNotificationCount(notificationCountResponse.data.data);
        const userRoles = getRole();
        const filteredItems = filterMenuItemsByRole(response.data, userRoles);
        sessionStorage.setItem("menuItems", JSON.stringify(filteredItems));
        sessionStorage.setItem(
          "notificationCount",
          JSON.stringify(notificationCountResponse.data.data)
        );
        console.log("filteredItems", filteredItems);
        setMenuItems(filteredItems);
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
    };

    // Check for stored items in sessionStorage
    const storedMenuItems = sessionStorage.getItem("menuItems");
    const storedNotificationCount = sessionStorage.getItem("notificationCount");
    if (storedMenuItems) {
      setMenuItems(JSON.parse(storedMenuItems));
      if (setNotificationCount) {
        setNotificationCount(JSON.parse(storedNotificationCount));
      } else {
        fetchMenuItems();
      }
    } else {
      fetchMenuItems();
    }
  }, []);

  // Structure menu items for rendering
  const structuredMenu = menuItems.reduce((acc, item) => {
    if (item.parent == 0) {
      acc.push({ ...item, subItems: [] });
    } else {
      const parent = acc.find((p) => p.id == item.parent);
      if (parent) {
        parent.subItems.push(item);
      }
    }
    return acc;
  }, []);

  const groupedMenu = structuredMenu.reduce((acc, item) => {
    const { menu_type } = item;
    if (!acc[menu_type]) {
      acc[menu_type] = [];
    }
    acc[menu_type].push(item);
    return acc;
  }, {});

  const handleToggle = (open, key) => {
    setOpen({ open, key });
  };

  const getNotificationCount = (type) => {
    const notification = notificationCount.find((n) => n.type == type);
    return notification ? notification.distinct_count : 0;
  };

  return (
    <StyledSidebar>
      <Sidebar
        toggled={collapsed}
        className="sidebar-custom d-flex flex-column"
      >
        <div className="d-flex flex-column">
          <div className="side-nav-header">
            {!collapsed && (
              <img src={logo} alt="AutoX logo" className="logo m-0 p-0" />
            )}
            <hr className="p-0 m-0" />
          </div>
        </div>

        <Menu className="scroller">
          {Object.keys(groupedMenu).map((menuType) => (
            <React.Fragment key={menuType}>
              <MenuItem className="menuitem-category">{menuType}</MenuItem>
              {groupedMenu[menuType].map((item) => (
                <React.Fragment key={item.id}>
                  {item.subItems.length > 0 ? (
                    <SubMenu
                      label={
                        <div className="d-flex justify-content-between">
                          <span>{item.module_name}</span>
                          {getNotificationCount(
                            item.module_name.toLowerCase()
                          ) > 0 && (
                            <span className="notification-circle">
                              {getNotificationCount(
                                item.module_name.toLowerCase()
                              )}
                            </span>
                          )}
                        </div>
                      }
                      icon={<ReactSVG src={iconMapping[item.icon]} />}
                      onClick={() => handleToggle(!open.open, item.module_name)}
                      open={open.open && open.key == item.module_name}
                    >
                      {item.subItems.map((subItem) => (
                        <MenuItem
                          key={subItem.id}
                          active={location.pathname == `/${subItem.url}`}
                          onClick={() => navigate(`/${subItem.url}`)}
                          icon={<ReactSVG src={iconMapping[subItem.icon]} />}
                          rootStyles={{
                            paddingRight: "0px",
                          }}
                        >
                          <div className="d-flex justify-content-between">
                            <span>{subItem.module_name}</span>
                            {getNotificationCount(
                              subItem.module_name.toLowerCase()
                            ) > 0 && (
                              <span className="notification-circle">
                                {getNotificationCount(
                                  subItem.module_name.toLowerCase()
                                )}
                              </span>
                            )}
                          </div>
                        </MenuItem>
                      ))}
                    </SubMenu>
                  ) : (
                    <MenuItem
                      active={location.pathname == `/${item.url}`}
                      onClick={() => navigate(`/${item.url}`)}
                      icon={<ReactSVG src={iconMapping[item.icon]} />}
                    >
                      <div className="d-flex justify-content-between">
                        <span>{item.module_name}</span>
                        {getNotificationCount(item.module_name.toLowerCase()) >
                          0 && (
                          <span className="notification-circle">
                            {getNotificationCount(
                              item.module_name.toLowerCase()
                            )}
                          </span>
                        )}
                      </div>
                    </MenuItem>
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </Menu>
      </Sidebar>
    </StyledSidebar>
  );
};

export default SidebarComponentTest;
