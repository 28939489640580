import * as Yup from "yup";

export const trendingValidation = Yup.object({
  brand_id: Yup.number()
    .min(0, "Please select brand")
    .required("Please select brand"),
  model_id: Yup.number()
    .min(0, "Please select model")
    .required("Please select model"),
});
