
import React, { useState } from "react";
import {
    BrowserRouter,
    Navigate,
    RouterProvider,
    createBrowserRouter,
    useLocation,
    useNavigate
} from "react-router-dom";
import Layout from "../../../components/Layout";
import Heading from "../../../components/Heading";
import HeadingTwo from "../../../components/HeadingTwo";
import PlusLargeIcon from "../../../assets/icons/bootstrap-icons/plus-lg.svg";
import DownloadIcon from "../../../assets/icons/bootstrap-icons/download.svg";

export default function ManageCarVersionMain({ children }) {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <Layout>
            <div className="row dashboard">
                <div className="d-flex">
                    <div className="margin-heading col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-4 col-6">
                        <Heading
                            title="New Car"
                            type="dashboard"
                            showHeader={true}
                            pointerOnHover={true}
                        />

                        <div className="d-flex w-100 align-items-center justify-content-between mb-2 sub-heading">
                            <HeadingTwo
                                title={<span className="">Car Version Management</span>}
                                type="dashboard"
                                showHeader={true}
                                pointerOnHover={true}>
                            </HeadingTwo>
                        </div>
                    </div>
                </div>
                <div className="my-4">
                    {children}
                </div>
            </div>
        </Layout>
    )
}