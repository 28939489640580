import clsx from "clsx";
import React from "react";
import Form from "react-bootstrap/Form";
import FormFieldErrorMsg from "./FormFieldErrorMsg";

export default function FormSelect({
  controlId,
  label,
  touched,
  errors,
  name,
  value,
  setFieldValue,
  items,
  itemName,
  defaultName,
  getValues,
  className,
  isRequired,
  showDefault,
  onChange,
}) {
  // console.log("value", value, name);
  return (
    <div>
      <Form.Group className="" controlId={controlId}>
        <Form.Label>
          {label}{" "}
          {isRequired ? <sup className="required-superscript">*</sup> : null}
        </Form.Label>
        <Form.Select
          aria-label="Default select example"
          onChange={(e) => {
            console.log(name, e.target.value);
            setFieldValue(name, e.target.value);
            if (getValues) {
              console.log("test");
              getValues(e.target.value);
            }
          }}
          name={name}
          className={clsx(
            {
              "is-invalid": touched[name] && errors[name],
            },
            {
              "is-valid": touched[name] && !errors[name],
            },
            className
          )}
          value={value}
        >
          {showDefault !== false ? (
            <option selected disabled value={-1}>
              {defaultName}
            </option>
          ) : null}

          {items.length > 0 && (
            <>
              {items.map((item, index) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item[itemName]}
                  </option>
                );
              })}
            </>
          )}
        </Form.Select>
        <FormFieldErrorMsg touched={touched} errors={errors} name={name} />
      </Form.Group>
    </div>
  );
}
