import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../../utility/constants";
import apiHandler from "./apiHandler";
import { notifyError, notifySuccess } from "../../utility/common";
import MagazineForm from "./MagazineForm";

export default function EditMagazine() {
  const navigate = useNavigate();
  const { id } = useParams();
  const token = getToken();
  const [initialValues, setInitialValues] = useState({
    title: "",
    url: "",
    post_id: "",
    display_date: "",
    display_image: "",
    content: "",
  });

  useEffect(() => {
    const getMagazineData = async () => {
      try {
        const response = await apiHandler.getMagazineDetails(id, token);
        console.log(response.data.data);
        if (response.status === 200) {
          setInitialValues(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getMagazineData();
  }, []);

  const EditMagazineData = async (data) => {
    try {
      delete data.created_date;
      delete data.id;
      delete data.updated_date;

      const formData = new FormData();
      for (let e of Object.entries(data)) {
        formData.append(e[0], e[1]);
      }
      const response = await apiHandler.editMagazine(id, formData, token);
      console.log(response);

      if (response.status === 200) {
        notifySuccess(response.data.message);
        setTimeout(() => {
          navigate("/magazine");
        }, 1500);
      }
    } catch (error) {
      console.log(error);
      notifyError(error.response.data.message);
    }
  };
  return (
    <>
      <MagazineForm
        initialValues={initialValues}
        onSubmit={EditMagazineData}
        title={"Edit Magazine"}
      />
    </>
  );
}
