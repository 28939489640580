import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../request";

export function getSubscriptionList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}subscription/filtered/list`,
    payload,
    getConfig(token)
  );
}
export function addSubscription(payload, token) {
  return axios.post(
    `${BASE_URL_AUTOX}subscription/add`,
    payload,
    getConfig(token)
  );
}

export function getCurrentId(token) {
  return axios.get(
    `${BASE_URL_AUTOX}subscription/current-id`,
    getConfig(token)
  );
}

export function editSubscription(id, data, token) {
  return axios.put(
    `${BASE_URL_AUTOX}subscription/edit/${id}`,
    data,
    getConfig(token)
  );
}

export function getSubscriptionDetails(id, token) {
  return axios.get(
    `${BASE_URL_AUTOX}subscription/subscription-details/${id}`,
    getConfig(token)
  );
}

export function deleteSubScription(id, token) {
  return axios.delete(
    `${BASE_URL_AUTOX}subscription/delete/${id}`,
    getConfig(token)
  );
}

export default {
  getSubscriptionList,
  addSubscription,
  getCurrentId,
  editSubscription,
  getSubscriptionDetails,
  deleteSubScription,
};
