import { useNavigate } from "react-router-dom";
import HeadingTwo from "../../../components/HeadingTwo";

import { BikeQuestionColumn } from "./BikeQuestionColumn";
import Datatable from "../../../components/Table";
import FAQapiHandler from "../FAQapiHandler";
import { useState } from "react";

import CarModelBtn from "../../../components/CarModelBtn";
import Search from "../../../components/SearchField";
import { FAQcustomStyles } from "../../NewBike/BikeFAQs/FAQtableStyle";
import { generateMonths } from "../../../utility/constants";

export default function BikeQuestion() {
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  //eslint-disable-next-line
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [reload, setReload] = useState(false);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({
    date_filter: "",
  });
  const navigate = useNavigate();
  const startYear = 2022;

  const reloadTable = () => {
    setReload(!reload);
  };
  const months = generateMonths(startYear);

  const ClearFilter = () => {
    setFilter({
      date_filter: "",
    });
    setSearch("");
    setReload(!reload);
  };

  return (
    <div className="row dashboard">
      <div className="card">
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-4 col-6 d-flex justify-content-between mb-2 sub-heading py-3">
          <HeadingTwo
            title={`Bike Question List (${totalRecords})`}
            type="dashboard"
            showHeader={true}
            pointerOnHover={true}
          />
          <CarModelBtn
            label={"Add Bike Question"}
            className="add-button d-flex align-items-center justify-content-between mb-2 px-3"
            onClick={() => navigate("/add-bike-question")}
          />
        </div>
        <div className="d-flex justify-content-start">
          <Search
            onChange={(event) => {
              console.log("ev", event);
              setSearch(event.target.value);
            }}
            value={search}
            placeholder={"Search by Question"}
          />

          <select
            className="month-select border border-2 shadow-none ms-2"
            id="searchBrand"
            placeholder="Search"
            value={filter.date_filter}
            onChange={(e) =>
              setFilter({ ...filter, date_filter: e.target.value })
            }
          >
            <option value="">Select Month</option>
            {months.map((month, index) => (
              <option key={index} value={month.id}>
                {month.value}
              </option>
            ))}
          </select>
          <button className="black-filled-button ms-2" onClick={reloadTable}>
            Apply
          </button>
          <button className="black-filled-button ms-2" onClick={ClearFilter}>
            Clear
          </button>
        </div>
        <Datatable
          api={FAQapiHandler.getBikeQuestionList}
          columns={BikeQuestionColumn(reloadTable, currentPage, itemsPerPage)}
          search={search}
          deafaultSortColumn={""}
          reload={reload}
          setReload={setReload}
          setTotalRecords={setTotalRecords}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
          isDraggable={true}
          column_ordering={""}
          styling={FAQcustomStyles}
          filter={filter}
        />
      </div>
    </div>
  );
}
