import * as Yup from "yup";
const internalLinkRegex = /href="https:\/\/[^"]+"/g; // Matches full URLs starting with https://

const DefaultPostValidationSchema = Yup.object().shape({
  category_id: Yup.number()
    .required("Category is required")
    .min(1, "Please Select Category"),
  author_id: Yup.number().nullable().required("Author is required"),
  post_title: Yup.string()
    .min(35)
    // .max(110)
    .nullable()
    .required("Title is required"),
  slug: Yup.string()
    // max(255)
    .nullable(),
  post_content: Yup.string().required("Content is required"), // Ensure content is not empty
  // .test(
  //   "has-internal-links",
  //   "The content must contain at least 2 external links",
  //   (value) => {
  //     if (!value) return false;
  //     const matches = value.match(internalLinkRegex);
  //     return matches && matches.length >= 2; // Ensure at least 2 internal links are found
  //   }
  // ),
  short_description: Yup.string()
    .min(120)
    // .max(166)
    .required("Please Enter Summary"),
  featured_image: Yup.string().required("Image is required"),
  thumb_type: Yup.string().required(),
  status: Yup.number().oneOf([0, 1, 2]).required("Status is required"),
  linkedin_post_status: Yup.number()
    .oneOf([0, 1, 2])
    .required("Status is required"),
  home_page_image: Yup.string().nullable(),
  verify_duplicate: Yup.number().oneOf([0, 1]).required("Status is required"),
  tags: Yup.array().of(Yup.string()).min(1, "Tags is required"),
  publish_date: Yup.string().nullable(),
  photographer: Yup.number().nullable(),
  post_rating: Yup.number().nullable(),
});

const WebstoriesValidationSchema = Yup.object().shape({
  post_title: Yup.string()
    .min(5)
    // .max(120)
    .nullable()
    .required("Title is required"),
  // post_content: Yup.string()
  //   .required("Content is required")
  //   .test(
  //     "has-internal-links",
  //     "The content must contain at least 2 external links",
  //     (value) => {
  //       if (!value) return false;
  //       const matches = value.match(internalLinkRegex);
  //       return matches && matches.length >= 2;
  //     }
  //   ),
  thumb_type: Yup.string().required(),
  category_id: Yup.number(),
  // featured_image: Yup.string().required("Image is required"),
  stories_images: Yup.array().min(4),
});

const VideosPostValidationSchema = Yup.object().shape({
  category_id: Yup.number()
    .required("Category is required")
    .min(1, "Please Select Category"),
  author_id: Yup.number().nullable().required("Author is required"),
  post_title: Yup.string()
    .min(0)
    // .max(20)
    .nullable()
    .required("Title is required"),
  slug: Yup.string().max(255).nullable(),
  post_content: Yup.string(),
  short_description: Yup.string()
    .min(20)
    // .max(120)
    .required("Please Enter Summary"),
  featured_image: Yup.string().required("Image is required"),
  thumb_type: Yup.string().required(),
  status: Yup.number().oneOf([0, 1, 2]).required("Status is required"),
  linkedin_post_status: Yup.number()
    .oneOf([0, 1, 2])
    .required("Status is required"),
  home_page_image: Yup.string().nullable(),
  verify_duplicate: Yup.number().oneOf([0, 1]).required("Status is required"),
  tags: Yup.array().of(Yup.string()).min(1, "Tags is required"),
  publish_date: Yup.string().nullable(),
  photographer: Yup.number().nullable(),
  post_rating: Yup.number().nullable(),
});

const DraftValidationSchema = Yup.object().shape({
  post_title: Yup.string().min(5).required("Title is required"),
  category_id: Yup.number()
    .required("Category is required")
    .min(1, "Please Select Category"),
  tags: Yup.array().of(Yup.string()).min(1, "Tags is required"),
});

const PostValidationSchema = Yup.lazy((values) => {
  // console.log(values,"values")
  if (values.status == 0) {
    return DraftValidationSchema;
  } else if (
    values.thumb_type == "stories" &&
    values.category_name == "Web Story"
  ) {
    return WebstoriesValidationSchema;
  } else if (values.category_name === "Videos") {
    return VideosPostValidationSchema;
  } else {
    return DefaultPostValidationSchema;
  }
});

export default PostValidationSchema;
