import { useState } from "react";
import Datatable from "../../components/Table";
import { getToken, renderDate, renderDateTime } from "../../utility/constants";
import apiHandler from "./apiHandler.js";
import Layout from "../../components/Layout.js";
import Breadcrumbs from "../../Breadcrumbs.js";
import { Card } from "react-bootstrap";
import HeadingTwo from "../../components/HeadingTwo.js";
import { useNavigate } from "react-router-dom";
export default function UserManage() {
  const [reloadTable, setReloadTable] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  const token = getToken();
  const columns = [
    {
      name: "User ID",
      selector: (row) => row.id,
      sortable: true,
      width: "100px",
    },
    {
      name: "Name",
      selector: (row) => (
        <a onClick={() => navigate(`/user-edit?id=${row.id}`)}>{row.name}</a>
      ),
      sortable: true,
      width: "300px",
    },
    {
      name: "Mobile Number",
      selector: (row) => row.mobile,
      sortable: true,
      width: "300px",
    },
    {
      name: "status",
      sortable: false,
      selector: (row) => {
        switch (row.approved) {
          case 1:
            return <div className="badge bg-success">Verified</div>;
          case 0:
            return <div className="badge bg-warning">Pending</div>;
          default:
            return "Unknown";
        }
      },
      width: "100px",
    },

    {
      name: "Date",
      selector: (row) => renderDateTime(row.created_date),
      sortable: true,
      width: "300px",
    },
  ];
  console.log(columns);
  return (
    <>
      <Layout>
        <div className="row dashboard" style={{ minHeight: "100%" }}>
          <div className="d-flex">
            <Breadcrumbs />
          </div>
          <div className="my-4" style={{ minHeight: "100%" }}>
            <Card className="p-3">
              <HeadingTwo title="User Manage" />
              <div>
                <Datatable
                  columns={columns}
                  api={apiHandler.getUserList}
                  reload={reloadTable}
                  setReload={setReloadTable}
                  setTotalRecords={setTotalPages}
                />
              </div>
            </Card>
          </div>
        </div>
      </Layout>
    </>
  );
}
