import React, { useState, useRef, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { getToken } from "../../../utility/constants";
import FormSelect from "../../../components/FormSelect";
import FormControl from "../../../components/FormControl";
import "react-toastify/dist/ReactToastify.css";
import apiHandler from "./apiHandler";
import {
  getAllBrands,
  getAllStates,
  getCityBystate,
} from "../CarServiceCenter/apiHandler";

export default function CarDrivingSchoolForm({
  touched,
  errors,
  values,
  setFieldValue,
  handleSubmit,
  type,
}) {
  const token = getToken();
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [brand, setBrand] = useState([]);

  useEffect(() => {
    getCityList();
    getBrandList();
  }, []);
  useEffect(() => {
    if (values["state_id"] > 0) {
      getStateList();
    }
  }, [values["state_id"]]);
  const getStateList = async () => {
    const response = await getCityBystate(token, values["state_id"]);
    setCities(response.data.data.result);
  };

  const getBrandList = async () => {
    const response = await getAllBrands(token);
    setBrand(response.data.result);
  };
  const getCityList = async () => {
    // apiHandler.getAllCarCities(token).then((res) => {
    //     // console.log("cities", res);
    //     setCities(res.data.result);
    // })
    const response = await getAllStates(token);
    console.log(response);
    setStates(response.data.result);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="row mt-1 p-3">
        <div className="col-md-6 my-3">
          <FormSelect
            controlId={"StateControlSelect"}
            label={"Select State"}
            name={"state_id"}
            defaultName={"Select State"}
            setFieldValue={setFieldValue}
            items={states}
            itemName={"state_name"}
            touched={touched}
            errors={errors}
            value={values["state_id"]}
            isRequired={true}
          />
        </div>
        <div className="col-md-6 my-3">
          <FormSelect
            controlId={"BrandControlSelect"}
            label={"Select Brand"}
            name={"brand_id"}
            defaultName={"Select Brand"}
            setFieldValue={setFieldValue}
            items={brand}
            itemName={"brand_name"}
            touched={touched}
            errors={errors}
            value={values["brand_id"]}
            isRequired={true}
          />
        </div>
        <div className="col-md-6 my-3">
          <FormSelect
            controlId={"CityControlSelect"}
            label={"Select City"}
            name={"city_id"}
            defaultName={"Select City"}
            setFieldValue={setFieldValue}
            items={cities}
            itemName={"city_name"}
            touched={touched}
            errors={errors}
            value={values["city_id"]}
            isRequired={true}
          />
        </div>
        <div className="col-md-6 my-3">
          <FormControl
            controlId={"schoolNameInput"}
            label={"School Name"}
            placeholder={"School Name"}
            touched={touched}
            errors={errors}
            name={"school_name"}
            value={values["school_name"]}
            setFieldValue={setFieldValue}
            isRequired={true}
          />
        </div>
        <div className="col-md-6 my-3">
          <FormControl
            controlId={"schoolUrlInput"}
            label={"Url"}
            placeholder={"Url"}
            touched={touched}
            errors={errors}
            name={"school_url"}
            value={values["school_url"]}
            setFieldValue={setFieldValue}
          />
        </div>
        <div className="col-md-6 my-3">
          <FormControl
            controlId={"schoolAddressInput"}
            label={"School Address"}
            placeholder={"School Address"}
            touched={touched}
            errors={errors}
            name={"driving_school_address"}
            value={values["driving_school_address"]}
            setFieldValue={setFieldValue}
            isRequired={true}
          />
        </div>
        <div className="col-md-6 my-3">
          <FormControl
            controlId={"schoolAreaInput"}
            label={"Area"}
            placeholder={"School Area"}
            touched={touched}
            errors={errors}
            name={"driving_school_area"}
            value={values["driving_school_area"]}
            setFieldValue={setFieldValue}
          />
        </div>
        <div className="col-md-6 my-3">
          <FormControl
            controlId={"schoolMapInput"}
            label={"Map (longitude and latitude)"}
            placeholder={"School Map"}
            touched={touched}
            errors={errors}
            name={"driving_school_map"}
            value={values["driving_school_map"]}
            setFieldValue={setFieldValue}
          />
        </div>
        <div className="col-md-6 my-3">
          <FormControl
            controlId={"schoolLatitudeInput"}
            label={"Latitude"}
            placeholder={"Latitude"}
            touched={touched}
            errors={errors}
            name={"latitude"}
            value={values["latitude"]}
            setFieldValue={setFieldValue}
          />
        </div>
        <div className="col-md-6 my-3">
          <FormControl
            controlId={"schoolLongitudeInput"}
            label={"Longitude"}
            placeholder={"Longitude"}
            touched={touched}
            errors={errors}
            name={"longitude"}
            value={values["longitude"]}
            setFieldValue={setFieldValue}
          />
        </div>
        <div className="col-md-6 my-3">
          <FormControl
            controlId={"schoolPhoneNumberInput"}
            label={"Phone No."}
            placeholder={"Phone No."}
            touched={touched}
            errors={errors}
            name={"phone_number"}
            value={values["phone_number"]}
            setFieldValue={setFieldValue}
          />
        </div>
        <div className="col-md-6 my-3">
          <FormControl
            controlId={"schoolEmailInput"}
            label={"Email Address"}
            placeholder={"Email Address"}
            touched={touched}
            errors={errors}
            name={"email_id"}
            value={values["email_id"]}
            setFieldValue={setFieldValue}
          />
        </div>
      </div>
      <Button
        type="submit"
        variant="btnBlue"
        className="d-flex align-items-center justify-content-between my-3"
      >
        {type} Driving School
      </Button>
    </Form>
  );
}
