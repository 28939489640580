import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../utility/constants";
import SidebarAPI from "../../Sidebar/sidebar/SidebarAPI";
import HeadingTwo from "../../../components/HeadingTwo";
import { Card, Form } from "react-bootstrap";

export default function SideBarSelect({
  selectedSidebar: initialSelectedSidebar,
}) {
  const [sidebarlist, setSidebarlist] = useState([]);
  const [selectedSidebar, setSelectedSidebar] = useState(
    initialSelectedSidebar || ""
  );
  const token = getToken();
  const navigate = useNavigate();

  useEffect(() => {
    const getSidebarList = async () => {
      try {
        const payload = {
          sortBy: "",
          sortOrder: "",
        };

        const response = await SidebarAPI.getSideBarlistName(token, payload);
        setSidebarlist(response.data.result);
      } catch (error) {
        console.log(error);
      }
    };

    getSidebarList();
  }, [token]);

  const handleSidebarChange = (e) => {
    setSelectedSidebar(e.target.value);
  };

  const getSidebarWidgets = () => {
    if (selectedSidebar) {
      navigate(`/widget-edit/${selectedSidebar}`);
    }
  };
  console.log(selectedSidebar);

  return (
    <Card className="p-3 mb-3">
      <HeadingTwo
        title="Sidebar"
        type="dashboard"
        showHeader={true}
        pointerOnHover={true}
      />

      <Form.Group className="mb-3" controlId="filterType">
        <div className="d-flex justify-content-between w-100 gap-4 align-items-end">
          <div className="sidebar-select">
            <Form.Label>Select Sidebar</Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={handleSidebarChange}
              value={selectedSidebar ? selectedSidebar : "Select"}
            >
              <option selected disabled={true}>
                Select
              </option>
              {sidebarlist.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.sidebar_name}
                </option>
              ))}
            </Form.Select>
          </div>
          <button
            className="version-go-button m-0 p-0"
            onClick={getSidebarWidgets}
          >
            Go
          </button>
        </div>
      </Form.Group>
    </Card>
  );
}
