import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeadingTwo from "../../../components/HeadingTwo";
import BackArrow from "../../../assets/icons/bootstrap-icons/arrow-left.svg";
import HondaLogo from "../../../assets/icons/bootstrap-icons/honda-logo-2000-full-640.webp";
import PlusLogo from "../../../assets/icons/bootstrap-icons/plus.svg";
import { ReactSVG } from "react-svg";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
  getBikeCityById,
  updateBikeCityById,
  getStates,
} from "../../../request";
import Card from "react-bootstrap/Card";
import Breadcrumbs from "../../../Breadcrumbs";
import Image from "react-bootstrap/Image";
import BackIcon from "../../../assets/icons/Back_Arrow.png";
import { cssTransition } from "react-toastify";
import { getToken } from "../../../utility/constants";

import FormSelect from "../../../components/FormSelect";
import { Formik, setIn } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import FormControl from "../../../components/FormControl";
import { notifySuccess } from "../../../utility/common";

export default function EditBikeCity() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { id } = useParams();
  const token = getToken();
  const [states, setStates] = useState([]);

  const [initialValues, setInitialValues] = useState({
    // id: "",
    state_id: 0,
    // state_name: "",
    city_name: "",
    city_url: "",
    latitude: "",
    longitude: "",
  });

  const getStateList = async () => {
    // const token = getToken();
    await getStates(token).then((res) => {
      setStates(res.data.result);
    });
  };

  const validationSchema = Yup.object({
    state_id: Yup.number()
      .min(1, t("State is required"))
      .required(t("State is required")),
    city_name: Yup.string().required(t("City Name is required")),
    city_url: Yup.string().required(t("Url is required")),
    latitude: Yup.number()
      .typeError(t("Lattitude must be a number"))
      .required(t("Latitude is required")),
    longitude: Yup.number()
      .typeError(t("Longitude must be a number"))
      .required(t("Longitude is required")),
  });

  const fetchData = async () => {
    try {
      const res = await getBikeCityById(id, token);
      const response = res.data.data;
      console.log(response);
      // setCityDetails(response);
      setInitialValues(response);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchData();
    getStateList();
  }, []);

  return (
    <div className="row dashboard">
      <div className="row">
        <div className="col-xl-11 col-sm-11">
          <Breadcrumbs removeId={true} />
        </div>
        <div className="col-xl-1 col-sm-1">
          <button
            className="d-flex back-button py-2 px-3 mb-2 btn btn-primary"
            onClick={() => navigate("/bike-city")}
          >
            <Image
              src={BackIcon}
              width="16px"
              height="16px"
              className="mt-1 me-2"
            />{" "}
            Back
          </button>
        </div>
      </div>

      <Card>
        <Card.Body>
          <Card.Text>
            <div className="row dashboard">
              <div className="table-heading fw-bold">Edit City</div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  // setLoading(true);

                  try {
                    console.log("test", values);
                    // const [longitude, latitude] = values.maruti_suzuki_driving_school_map.split(",");

                    // const payload = { ...values, longitude, latitude }
                    const payload = values;

                    // delete payload.city_name;
                    delete payload.id;
                    delete payload.created_date;
                    delete payload.updated_date;
                    delete payload.metro_city;
                    const res = await updateBikeCityById(id, payload, token);
                    notifySuccess("Bike City updated successfully");
                    setTimeout(() => {
                      navigate("/bike-city");
                    }, 1500);
                    console.log("myres", res);

                    // resetForm();
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="row col-12 mt-1 p-3">
                      <div className="col-6 px-0 pe-3 my-3">
                        <div className="">
                          <FormSelect
                            controlId={"StateSelect"}
                            label={"Select State"}
                            name={"state_id"}
                            defaultName={"Select State"}
                            setFieldValue={setFieldValue}
                            items={states}
                            itemName={"state_name"}
                            touched={touched}
                            errors={errors}
                            value={values["state_id"]}
                          />
                          {/* <Form.Group
                                                className=""
                                                controlId="StateSelect"
                                            >
                                                <Form.Label className="">Select State</Form.Label>
                                                <Form.Select
                                                    aria-label="Default select example"
                                                    onChange={handleOnChange}
                                                    name="state_id"
                                                >
                                                    <option selected disabled>
                                                        {" "}
                                                        Select State
                                                    </option>
                                                    {
                                                        states.map((state, index) => {
                                                            return (
                                                                <option value={state.id} key={state.id}>
                                                                    {state.state_name}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                    {/* <option value={0}>State 1</option>
                                                    <option value={1}>State 2</option> * /}
                                                </Form.Select>
                                            </Form.Group> */}
                        </div>
                      </div>

                      <div className="col-6 px-0 ps-3 my-3">
                        <div className="">
                          <FormControl
                            controlId={"CityNameInput"}
                            label={"City Name"}
                            placeholder={"City Name"}
                            touched={touched}
                            errors={errors}
                            name={"city_name"}
                            value={values["city_name"]}
                            setFieldValue={setFieldValue}
                          />
                          {/* <Form.Group className="" controlId="cityNameInput">
                                                <Form.Label className="">
                                                    City Name
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder=""
                                                    onChange={handleOnChange}
                                                    name="city_name"
                                                    value={cityDetails.city_name}
                                                />
                                            </Form.Group> */}
                        </div>
                      </div>

                      <div className="col-6 px-0 pe-3 my-3">
                        <div className="">
                          <FormControl
                            controlId={"CityUrlInput"}
                            label={"Slug"}
                            placeholder={"Slug"}
                            touched={touched}
                            errors={errors}
                            name={"city_url"}
                            value={values["city_url"]}
                            setFieldValue={setFieldValue}
                          />
                          {/* <Form.Group className="" controlId="cityUrlInput">
                                                <Form.Label className="">
                                                    Slug
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Slug"
                                                    onChange={handleOnChange}
                                                    name="city_url"
                                                    value={cityDetails.city_url}
                                                />
                                            </Form.Group> */}
                        </div>
                      </div>

                      <div className="col-6 px-0 ps-3 my-3">
                        <div className="">
                          <FormControl
                            controlId={"LatitudeInput"}
                            label={"Latitude"}
                            placeholder={"Latitude"}
                            touched={touched}
                            errors={errors}
                            name={"latitude"}
                            value={values["latitude"]}
                            setFieldValue={setFieldValue}
                          />
                          {/* <Form.Group className="" controlId="latitudeInput">
                                                <Form.Label className="">
                                                    Latitude
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Latitude"
                                                    onChange={handleOnChange}
                                                    name="latitude"
                                                    value={cityDetails.latitude}
                                                />
                                            </Form.Group> */}
                        </div>
                      </div>

                      <div className="col-6 px-0 pe-3 my-3">
                        <div className="">
                          <FormControl
                            controlId={"LongitudeInput"}
                            label={"Longitude"}
                            placeholder={"Longitude"}
                            touched={touched}
                            errors={errors}
                            name={"longitude"}
                            value={values["longitude"]}
                            setFieldValue={setFieldValue}
                          />
                          {/* <Form.Group className="" controlId="longitudeInput">
                                                <Form.Label className="">
                                                    Longitude
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Longitude"
                                                    onChange={handleOnChange}
                                                    name="longitude"
                                                    value={cityDetails.longitude}
                                                />
                                            </Form.Group> */}
                        </div>
                      </div>
                    </div>
                    <Button
                      type="submit"
                      variant="btnBlue"
                      className="d-flex align-items-center justify-content-between my-3"
                      // onClick={handleClick}
                    >
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
