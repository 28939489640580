import axios from "axios";
import { getConfig, BASE_URL_AUTOX } from "../../../request";

export const GET_ALL_BODY_TYPE_LIST = "car/body-type/all/list";
export const GET_ALL_BRAND_LIST = "car/brand/all/list";
export const GET_MODEL_BY_BODY_TYPE_ID_BRAND_ID =
  "car/model/body-type-brand-specific/";
export const ADD_CAR_COMPARISON = "car/comparison/add";
export const GET_CAR_COMPARISONS = "car/comparison/filtered/list";
export const GET_CAR_COMPARISON_URL = "car/comparison/view/";
export const DELETE_CAR_COMPARISON = "car/comparison/delete/";
export const GET_CAR_COMPARISON_BODY_TYPES = "car/comparison/body-type";
export const GET_CAR_COMAPRISONS_BY_BODY_TYPES =
  "car/comparison/body-type-specific/filtered/list/";
export const BULK_UPDATE_CAR_COMPARISON_ORDERING = "car/comparison/ordering";

/**
 * Get List of all body types
 * getBodyTypeList is used to get all car body types
 * @param token
 * @param payload
 */
export function getBodyTypeList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_ALL_BODY_TYPE_LIST}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get List of Brands
 * getAllBrands is used to get data of all brands
 * @param token
 * @param payload
 */
export function getAllBrands(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_ALL_BRAND_LIST}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get List of Models
 * getModelsByBodyTypeBrand is used to get data of models on behalf of body type and brand
 * @param token
 * @param payload
 */
export function getModelsByBodyTypeBrand(token, body_type_id, brand_id) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_MODEL_BY_BODY_TYPE_ID_BRAND_ID}${body_type_id}/${brand_id}`,
    getConfig(token)
  );
}

/**
 * Get List of car Comparisons
 * getCarComparison is used to get all car comparisons
 * @param token
 * @param payload
 */
export function getCarComparison(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_CAR_COMPARISONS}`,
    payload,
    getConfig(token)
  );
}

/**
 * Add car comparison
 * addCarComparison is used to add car comparison
 * @param token
 * @param payload
 */
export function addCarComparison(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${ADD_CAR_COMPARISON}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get View link of car comparison
 * getCarComparisonViewLink is used to get view link of car comparison
 * @param token
 * @param payload
 */
export function getCarComparisonViewLink(token, id) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_CAR_COMPARISON_URL}${id}`,
    getConfig(token)
  );
}

/**
 * Get delete car comparison
 * deleteCarComparisonById is used to delete car comparison
 * @param token
 * @param payload
 */
export function deleteCarComparisonById(token, id) {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_CAR_COMPARISON}${id}`,
    getConfig(token)
  );
}

/**
 * Get car comparison body types
 * getCarComparisonBodyTypes is used to get car comparison body types
 * @param token
 * @param payload
 */
export function getCarComparisonBodyTypes(token) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_CAR_COMPARISON_BODY_TYPES}`,
    getConfig(token)
  );
}

/**
 * Get car comparison by body types
 * getCarComparisonsByBodyTypes is used to get car comparison by body type
 * @param token
 * @param payload
 */
export function getCarComparisonsByBodyTypes(token, payload, id) {
  console.log("car body typeid", id);
  return axios.post(
    `${BASE_URL_AUTOX}${GET_CAR_COMAPRISONS_BY_BODY_TYPES}${id}`,
    payload,
    getConfig(token)
  );
}

/**
 * Update car comparison ordering
 * bulkUpdateCarComparisonOrdering is used to update car comparison ordering
 * @param token
 * @param payload
 */
export function bulkUpdateCarComparisonOrdering(token, payload, id) {
  console.log("test 4567");
  return axios.put(
    `${BASE_URL_AUTOX}${BULK_UPDATE_CAR_COMPARISON_ORDERING}/${id}`,
    payload,
    getConfig(token)
  );
}

export default {
  getBodyTypeList,
  getAllBrands,
  getModelsByBodyTypeBrand,
  addCarComparison,
  getCarComparison,
  getCarComparisonBodyTypes,
  getCarComparisonViewLink,
  deleteCarComparisonById,
  getCarComparisonsByBodyTypes,
  bulkUpdateCarComparisonOrdering,
};
