import * as Yup from "yup";
const phoneRegex = /^\+\d{1,3}\s?\d{10}$/;

export const marutiCarDrivingSchoolValidation = Yup.object({
  city_id: Yup.number().min(1, "City is invalid").required("City is required"),
  school_name: Yup.string().required("School Name is required"),
  school_url: Yup.string().required("School Url is required"),
  maruti_suzuki_driving_school_address: Yup.string().required(
    "School Address is required"
  ),
  maruti_suzuki_driving_school_area: Yup.string().nullable(),
  maruti_suzuki_driving_school_map: Yup.string().nullable(),
  latitude: Yup.number().nullable(),
  longitude: Yup.number().nullable(),
  phone_number: Yup.string()
    .matches(phoneRegex, "Phone number is not valid")
    .nullable(),
  email_id: Yup.string().email().nullable(),
});
