import { Card } from "react-bootstrap";
import Breadcrumbs from "../../Breadcrumbs";
import Layout from "../../components/Layout";
import { Accordion } from "react-bootstrap";
import { useFormik } from "formik";

export default function SettingsMain() {
  //   const initialObject = {
  //     [personalization.COLOR]: "",
  //     [personalization.FONT]: "",
  //     [personalization.FONT_SIZE]: "",
  //     [personalization.COMPANY_LOGO]: "",
  //   };
  const { handleSubmit, setFieldValue, values, errors, touched } = useFormik({
    // initialValues: { initialObject },
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  return (
    <></>
    // <Layout>
    //   <div className="row dashboard">
    //     <div>
    //       <Breadcrumbs />
    //     </div>
    //     <div>
    //       <form onSubmit={handleSubmit}>
    //         <Accordion className="mt-4">
    //           <Accordion.Item
    //             eventKey="0"
    //             className="mb-4 rounded shadow-lg p-3"
    //           >
    //             <Accordion.Header className="fw-bold">
    //               Change Color
    //             </Accordion.Header>
    //             <Accordion.Body
    //               className="d-flex flex-wrap gap-4 ml-4 align-content-center"
    //               style={{ height: "100px" }}
    //             >
    //               {/* {colorPalette.map((item, index) => (
    //               <div key={index}>
    //                 <Field
    //                   key={index}
    //                   className="form-check-input"
    //                   type="radio"
    //                   name={personalization.COLOR}
    //                   value={item}
    //                   style={{ backgroundColor: item }}
    //                   onChange={(e) => {
    //                     setFieldValue(personalization.COLOR, e.target.value);
    //                     handleSubmit();
    //                   }}
    //                 />
    //               </div>
    //             ))} */}
    //             </Accordion.Body>
    //           </Accordion.Item>
    //           <Accordion.Item
    //             eventKey="1"
    //             className="mb-4 rounded shadow-lg p-3"
    //           >
    //             <Accordion.Header className="fw-bold">
    //               Change Font
    //             </Accordion.Header>
    //             <Accordion.Body>
    //               {/* <CustomSelect
    //               name={personalization.FONT}
    //               setFieldValue={setFieldValue}
    //               options={fontOption}
    //               isSearchable={true}
    //               touched={touched}
    //               errors={errors}
    //               values={values}
    //               handleSubmit={handleSubmit}
    //             /> */}
    //             </Accordion.Body>
    //           </Accordion.Item>
    //           <Accordion.Item
    //             eventKey="2"
    //             className="mb-4 rounded shadow-lg p-3"
    //           >
    //             <Accordion.Header className="fw-bold">
    //               Change Font Size
    //             </Accordion.Header>
    //             <Accordion.Body>
    //               {/* <CustomSelect
    //               name={personalization.FONT_SIZE}
    //               setFieldValue={setFieldValue}
    //               options={fontSize}
    //               isSearchable={true}
    //               touched={touched}
    //               errors={errors}
    //               values={values}
    //               handleSubmit={handleSubmit}
    //             /> */}
    //               {/* <input
    //                       className="w-100"
    //                       type="range"
    //                       min={0}
    //                       max={52}
    //                       name={personalization.FONT_SIZE}
    //                       step={1}
    //                       value={values[personalization.FONT_SIZE]}
    //                       onChange={(e) => {
    //                         setFieldValue(
    //                           personalization.FONT_SIZE,
    //                           e.target.value
    //                         );
    //                         handleSubmit();
    //                       }}
    //                     /> */}
    //             </Accordion.Body>
    //           </Accordion.Item>
    //           <Accordion.Item
    //             eventKey="3"
    //             className="mb-4 rounded shadow-lg p-3"
    //           >
    //             <Accordion.Header className="fw-bold">
    //               Add Company Logo
    //             </Accordion.Header>
    //             <Accordion.Body>
    //               {/* <div className="d-flex w-50">
    //               <ImageInput
    //                 name={personalization.COMPANY_LOGO}
    //                 setFieldValue={setFieldValue}
    //                 error={errors[personalization.COMPANY_LOGO]}
    //                 isError={
    //                   touched[personalization.COMPANY_LOGO] &&
    //                   errors[personalization.COMPANY_LOGO]
    //                 }
    //                 url={values[personalization.COMPANY_LOGO]}
    //               />
    //               <div className="add-logo-container">
    //                 <button
    //                   className="add-logo-btn ms-4 py-2 px-4 rounded"
    //                   type="button"
    //                   onClick={(e) => {
    //                     e.preventDefault();
    //                     handleSubmit();
    //                   }}
    //                 >
    //                   Add
    //                 </button>
    //               </div>
    //             </div> */}
    //             </Accordion.Body>
    //           </Accordion.Item>
    //         </Accordion>
    //       </form>
    //     </div>
    //   </div>
    // </Layout>
  );
}
