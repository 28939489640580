import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../../../request";
import { getToken } from "../../../../utility/constants";
const token = getToken();
export const downloadEditorial = () => {
  return axios.post(
    `${BASE_URL_AUTOX}new-bike/model/downloadEditorialContent`,
    {},
    getConfig(token)
  );
};

export const downloadUpcoming = () => {
  return axios.post(
    `${BASE_URL_AUTOX}new-bike/model/downloadUpcomingPreview`,
    {},
    getConfig(token)
  );
};
