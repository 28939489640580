import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeadingTwo from "../../components/HeadingTwo";
import UserNewsletterApiHandler from "./UserNewsletterApiHandler";
import { FAQcustomStyles } from "../NewBike/BikeFAQs/FAQtableStyle";
import { generateMonths, getToken } from "../../utility/constants";
import { UserNewsletterColumn } from "./UseNewsletterColumns";
import CarModelBtn from "../../components/CarModelBtn";
import Search from "../../components/SearchField";
import Datatable from "../../components/Table";

export default function UserNewsletterSubscritions() {
  const [reload, setReload] = useState(false);
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [filter, setFilter] = useState({
    date_filter: "",
    question_id: "",
  });
  const navigate = useNavigate();
  const startYear = 2022;
  const token = getToken();

  // Debounce effect for search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500); // 500ms debounce time

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  const reloadTable = () => {
    setReload(!reload);
  };

  const ClearFilter = () => {
    setFilter({
      date_filter: "",
      question_id: "",
    });
    setSearch("");
    setReload(!reload);
  };

  return (
    <div className="row dashboard">
      <div className="card">
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-4 col-6 d-flex justify-content-between mb-2 sub-heading py-3">
          <HeadingTwo
            title={`Newsletter Subscribers (${totalRecords})`}
            type="dashboard"
            showHeader={true}
            pointerOnHover={true}
          />
          <CarModelBtn
            label={"Add Newsletter Subscribers"}
            className="add-button d-flex align-items-center justify-content-between mb-2 px-3"
            onClick={() => navigate("/add-user-newsletter-subscriptions")}
          />
        </div>
        <div className="d-flex justify-content-start">
          <Search
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            value={search}
            placeholder={"Search by Email"}
          />
        </div>
        <Datatable
          api={UserNewsletterApiHandler.listUserNewsletterSubscriptions}
          columns={UserNewsletterColumn(reloadTable, currentPage, itemsPerPage)}
          search={debouncedSearch} // Use debounced search value
          deafaultSortColumn={""}
          reload={reload}
          setReload={setReload}
          setTotalRecords={setTotalRecords}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
          isDraggable={true}
          column_ordering={""}
          sortByDir={"desc"}
          styling={FAQcustomStyles}
          filter={filter}
        />
      </div>
    </div>
  );
}
