import * as Yup from "yup";

export const filterValidationSchema = Yup.object({
  brand_id: Yup.number()
    .min(1, "Brand is required")
    .required("Brand is required"),
  model_id: Yup.number()
    .min(1, "Model is required")
    .required("Model is required"),
  version_id: Yup.number()
});
