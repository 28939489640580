import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import axios from "axios"; // Make sure to install axios if not already
import { getToken } from "../../../utility/constants";
import { uploadImageListing } from "../apiHandler";
import { notifyError, notifySuccess } from "../../../utility/common";

const getImageDimensions = async (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);

    img.onload = () => {
      const width = img.width;
      const height = img.height;
      URL.revokeObjectURL(objectUrl);
      resolve({ width, height });
    };

    img.onerror = (error) => {
      URL.revokeObjectURL(objectUrl);
      reject(error);
    };

    img.src = objectUrl;
  });
};

export default function ImageUpload({ onUploadComplete, refreshImages }) {
  const [file, setFile] = useState(null);
  // const [width, setWidth] = useState(0);
  // const [height, setHeight] = useState(0);
  const token = getToken();

  const handleFileChange = (e) => {
    if (e === null) return null;
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();

    if (file) {
      const fileSizeInKB = Math.round(file.size / 1024); // Convert bytes to KB

      // // Check if the file size is less than or greater than 200 KB
      // if (fileSizeInKB > 200) {
      //   notifyError("File size should be less than 200 KB");
      //   return;
      // }
      const { width, height } = await getImageDimensions(file);

      formData.append("width", width);
      formData.append("height", height);
      formData.append("size", fileSizeInKB);
      formData.append("image_listing", file);

      try {
        const response = await uploadImageListing(token, formData);
        console.log("Media uploaded successfully", response.data.media);
        onUploadComplete(response.data.media);
        setFile(null);
        refreshImages(); // Refresh the image listing after upload
        notifySuccess("Image uploaded successfully.");
        handleFileChange(null);
      } catch (error) {
        console.error("Error uploading image", error);
      }
    }
  };

  return (
    <div className="image-upload">
      <Form.Group>
        <Form.Control
          type="file"
          accept="image/*, video/*"
          onChange={handleFileChange}
        />
      </Form.Group>
      <Button onClick={handleUpload} disabled={!file}>
        Upload
      </Button>
    </div>
  );
}
