import React from "react";
import { Pagination } from "react-bootstrap";

export default function CustomPagination({
  onChangePage,
  currentPage,
  totalPages,
}) {
  return (
    <div className="d-flex justify-content-between align-items-center mt-5">
      <div
        className="ps-1"
        style={{ color: "#646464", fontSize: "12px", fontWeight: "500" }}
      >
        Page{" "}
        <span style={{ color: "#333335", fontWeight: "600" }}>
          {" "}
          {currentPage} of {totalPages > 0 ? totalPages : 1}
        </span>
      </div>

      <div>
        <Pagination>
          <Pagination.Item
            onClick={() => {
              if (currentPage === 1) return;
              onChangePage(currentPage - 1);
            }}
            disabled={currentPage === 1}
            className="m-1"
          >
            {"Previous"}
          </Pagination.Item>
          <Pagination.First
            onClick={() => onChangePage(1)}
            className="m-1"
            disabled={currentPage === 1}
          />
          <Pagination.Prev
            onClick={() => {
              if (currentPage === 1) return;
              onChangePage(currentPage - 1);
            }}
            className="m-1"
            disabled={currentPage === 1}
          />
          <Pagination.Item
            onClick={() => onChangePage(currentPage)}
            className="current m-1"
          >
            {currentPage}
          </Pagination.Item>
          <Pagination.Next
            onClick={() => {
              if (currentPage === totalPages) return;
              onChangePage(currentPage + 1);
            }}
            className="m-1"
            disabled={currentPage === totalPages}
          />
          <Pagination.Last
            onClick={() => onChangePage(totalPages)}
            className="m-1"
            disabled={currentPage === totalPages}
          />
          <Pagination.Item
            onClick={() => {
              if (currentPage === totalPages) return;
              onChangePage(currentPage + 1);
            }}
            className="m-1"
            disabled={currentPage === totalPages}
          >
            {"Next"}
          </Pagination.Item>
        </Pagination>
      </div>
    </div>
  );
}
