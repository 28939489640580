import { Card } from "react-bootstrap";
import Breadcrumbs from "../../Breadcrumbs";
import HeadingTwo from "../../components/HeadingTwo";
import { ReactSVG } from "react-svg";
import Table from "../../components/Table";
import PlusLargeIcon from "../../assets/icons/bootstrap-icons/plus-lg.svg";
import Search from "../../components/SearchField";
import PostListingComponent from "../../components/PostListingComponent";
import DeleteConfirmationModalComponent from "../../components/DeleteConfirmationModalComponent";
import { notifyError, notifySuccess } from "../../utility/common";
import { useNavigate } from "react-router-dom";
import { getToken, renderDateTime } from "../../utility/constants";
import { getGalleryList } from "./apiHandler";
import { useState } from "react";
import apiHandler from "./apiHandler";
import EditViewDelete from "../../components/EditViewDeleteReviewPassword";
export default function RegularGalleryListing() {
  const [search, setSearch] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [galleryIdToDelete, setGalleryIdToDelete] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [reloadTable, setReloadTable] = useState(false);
  const navigate = useNavigate();
  const token = getToken();
  const onDeleteGallery = async () => {
    apiHandler
      .deleteGalleryById(galleryIdToDelete, token)
      .then((result) => {
        setShowDeleteModal(false);
        setReloadTable(true);
        notifySuccess("Gallery deleted successfully");
      })
      .catch((error) => {
        setShowDeleteModal(false);
        notifyError(error.message);
      });
  };

  const onViewGallery = (values) => {
    const baseUrl = "http://64.227.166.183:3000/";
    const urlPath = `/gallery/${values.slug}`;

    window.open(`${baseUrl}${urlPath}`);
  };
  const onEditGallery = (id) => {
    navigate(`/regular-gallery/edit-regular-gallery?id=${id}`);
  };
  const columns = [
    {
      name: "ID",
      selector: (post) => `[gallery-shortcode=${post.id}]`,
      sortable: true,
      width: "260px",
    },
    {
      name: "Title",
      selector: (post) => (
        <div className="d-flex flex-column m-4 gap-2">
          <a
            onClick={() => onEditGallery(post.id)}
            style={{
              cursor: "pointer",
              color: "black", // Default color
              textDecoration: "none", // Remove underline if needed
              fontWeight: "bold", // You can make the title bold if needed
            }}
            onMouseEnter={(e) => (e.target.style.color = "blue")} // Change color on hover
            onMouseLeave={(e) => (e.target.style.color = "black")} // Revert back on mouse leave
          >
            {post.post_title}
          </a>
        </div>
      ),
      sortable: true,
      width: "350px",
      height: "100px",
    },

    // {
    //   name: "Post Type",
    //   sortable: true,
    //   selector: (row) =>
    //     row.status == 1 ? (
    //       <div className="green-box text-center p-1">Live</div>
    //     ) : row.status == 0 ? (
    //       <div className="yellow-box">Draft</div>
    //     ) : row.status == 2 ? (
    //       <div className="red-box">Scheduled</div>
    //     ) : (
    //       <div className="red-box">Custom Slider</div>
    //     ),

    //   width: "120px",
    // },

    // {
    //   name: "Status",
    //   selector: (post) => getPageStatusBadge(post.status),
    //   sortable: false,
    // },
    {
      name: "Date",
      selector: (post) => renderDateTime(post.publish_date),
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      width: "auto",
      cell: (post) => {
        return (
          <EditViewDelete
            id={post.id}
            onEdit={onEditGallery}
            onDelete={() => {
              setGalleryIdToDelete(post.id);
              setShowDeleteModal(true);
            }}
            onView={() => onViewGallery(post)}
          />
        );
      },
    },
  ];

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  return (
    <>
      <Breadcrumbs />
      <div>
        <Card className="p-3">
          <div className="d-flex justify-content-between">
            <HeadingTwo
              title={`Gallery List (0)`}
              type="dashboard"
              showHeader={true}
              pointerOnHover={true}
            />
            <button
              className="add-post-button d-flex align-items-center justify-content-between me-2 mb-2 px-3"
              onClick={() => navigate("/post-management/add-post")}
            >
              <ReactSVG
                src={PlusLargeIcon}
                height="18px"
                width="18px"
                className="mr-2"
              />{" "}
              Add Gallery
            </button>
          </div>
          <div>
            <div className="d-flex w-100">
              <Search
                onChange={(e) => handleSearchChange(e)}
                value={search}
                placeholder={"Search"}
              />
              <button className="black-filled-button ms-2">Apply</button>
            </div>
          </div>
          <div>
            <div className="row dashboard">
              <Table
                columns={columns}
                api={getGalleryList}
                reload={reloadTable}
                setReload={setReloadTable}
                totalRecords={totalRecords}
                setTotalRecords={setTotalRecords}
              />
              <DeleteConfirmationModalComponent
                show={showDeleteModal}
                tpe={"delete"}
                handleClose={() => setShowDeleteModal(false)}
                setDeleteConfirm={onDeleteGallery}
              />
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}
