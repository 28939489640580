import * as Yup from "yup";

const FAQQuestionSchema = Yup.object().shape({
  question_name: Yup.string().max(255).required(),
  question_status: Yup.number().required(),
  brand_id: Yup.number().required(),
  model_id: Yup.number().required(),
});

const FAQAnswerSchema = Yup.object().shape({
  question_id: Yup.number().min(0).required(),
  answer_name: Yup.string().max(255).required(),
  answer_status: Yup.number().min(0).required(),
});
//eslint-disable-next-line
export default { FAQQuestionSchema, FAQAnswerSchema };
