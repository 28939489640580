import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Layout from "../../../components/Layout";
import Breadcrumbs from "../../../Breadcrumbs";
import Image from "react-bootstrap/Image";
import BackIcon from "../../../assets/icons/Back_Arrow.png";
import { Card, Form } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { getAllBikeBrands, getAllBrands } from "../../../request";
import { notifyError, notifySuccess } from "../../../utility/common";

import {
  getToken,
  multiple_bike_city_price_cities,
} from "../../../utility/constants";
import { Formik } from "formik";
import { filterValidationSchema } from "./schemaValidation";
import apiHandler from "./apiHandler";
import {
  getBikeModelByBrandId,
  getBikeVersionByModelId,
} from "../BikeCityPrice/apiHandler";

export default function MultipleBikeCityPriceMain({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const token = getToken();
  const [brands, setBrands] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [model, setModels] = useState([]);
  const [versions, setVersions] = useState([]);
  const cities = multiple_bike_city_price_cities;
  const [data, setData] = useState({});
  const [initialPayload, setInitialPayload] = useState([]);
  useEffect(() => {
    getAllBikeBrands()
      .then((res) => {
        setBrands(res.data.result);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  }, []);

  const getModels = (brand_id) => {
    if (!brand_id) {
      setModels([]);
      return;
    }
    const payload = {
      brand_id,
    };
    getBikeModelByBrandId(token, payload)
      .then((response) => {
        setModels(response.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getVersions = (model_id) => {
    if (!model_id) {
      setModels([]);
      return;
    }
    const payload = {
      model_id,
    };
    getBikeVersionByModelId(token, payload)
      .then((response) => {
        setVersions(response.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFieldChange = (value, id, fieldName) => {
    let newPayload = [...initialPayload];
    newPayload = newPayload.map((car) => {
      if (car.id === id) {
        car[fieldName] = Number(value) || 0;
      }
      return car;
    });
    setInitialPayload(newPayload);
  };

  const handleAllFormSubmit = async () => {
    try {
      const response = await apiHandler.updateBikeCityMultiplePrice(token, {
        versionData: initialPayload,
      });
      notifySuccess("Records updated successfully.");
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <Layout>
      <div className="">
        <div className="d-flex justify-content-between align-items-center multipleCityPriceMain mb-3">
          <Breadcrumbs />

          {/* <button
            className="d-flex back-button mb-2 btn btn-primary px-3 py-2"
            onClick={() => navigate("/car-brand")}
          >
            <Image
              src={BackIcon}
              width="16px"
              height="16px"
              className="mt-1 me-2"
            />{" "}
            Back
          </button> */}
        </div>
        <div className="row mb-4">
          <div className="col-xl-12 col-sm-12">
            <Card className="pb-2">
              <Card.Body>
                <Card.Text>
                  <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <div className="d-flex align-items-center">
                          <div className="table-heading">Version Form</div>
                          <div className="py-0 ms-5"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Formik
                    initialValues={{
                      version_id: "",
                      brand_id: "",
                      model_id: "",
                    }}
                    validationSchema={filterValidationSchema}
                    enableReinitialize={true}
                    onSubmit={async (values) => {
                      const payload = {
                        model_id: values.model_id,
                        city_ids: cities,
                      };
                      if (values.version_id) {
                        payload.version_id = values.version_id;
                      }
                      const response =
                        await apiHandler.getBikeCityMultiplePrice(
                          token,
                          payload
                        );
                      // const promises = cities.map((city) =>
                      //   apiHandler.getCityMultiplePrice(token, {
                      //     ...payload,
                      //     city_id: city.city_id
                      //   })
                      // );

                      // const response = await Promise.all(promises);

                      const cityGroups = {};
                      const cityPayload = [];

                      response.data.data.forEach((item) => {
                        //group by city
                        if (!cityGroups[item.city_id]) {
                          cityGroups[item.city_id] = [];
                        }
                        cityGroups[item.city_id].push(item);

                        //populate initial payload for all cities
                        cityPayload.push({
                          id: item.id,
                          ex_showroom_price: item.ex_showroom_price,
                          rto: item.rto,
                          insurance: item.insurance,
                        });
                      });

                      setData(cityGroups);
                      setInitialPayload(cityPayload);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-3">
                            <Form.Group
                              className="mb-4"
                              controlId="brandNameInput"
                            >
                              <Form.Label className="mb-2">
                                Select Brand
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="custom-select"
                                touched={touched}
                                errors={errors["brand_id"]}
                                name="brand_id"
                                value={values.brand_id}
                                onChange={(e) => {
                                  const brand_id = e.target.value;
                                  setFieldValue("brand_id", brand_id);
                                  setFieldValue("model_id", "");
                                  setFieldValue("version_id", "");
                                  getModels(brand_id);
                                }}
                                onBlur={handleBlur}
                                isInvalid={touched.brand_id && errors.brand_id}
                              >
                                <option selected disabled value="">
                                  Select Brand
                                </option>
                                {brands.map((brand, i) => {
                                  return (
                                    <option value={brand.id} key={i}>
                                      {brand.brand_name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {touched["brand_id"] && errors["brand_id"]}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                          <div className="col-md-4">
                            <Form.Group
                              className="mb-3"
                              controlId="SlugControlInput"
                            >
                              <Form.Label className="mb-2">
                                Select Model
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="custom-select"
                                touched={touched}
                                errors={errors["model_id"]}
                                name="model_id"
                                value={values.model_id}
                                onChange={(e) => {
                                  const model_id = e.target.value;
                                  setFieldValue("model_id", model_id);
                                  getVersions(model_id);
                                }}
                                onBlur={handleBlur}
                                isInvalid={touched.model_id && errors.model_id}
                              >
                                <option selected disabled value={""}>
                                  Select Model
                                </option>
                                {model.map((mod, i) => {
                                  return (
                                    <option value={mod.id} key={i}>
                                      {mod.model_name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {touched["model_id"] && errors["model_id"]}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                          <div className="col-md-4">
                            <Form.Group
                              className="mb-3"
                              controlId="StatusControlSelect"
                            >
                              <Form.Label className="mb-2">Version</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="custom-select"
                                touched={touched}
                                errors={errors["version_id"]}
                                name="version_id"
                                value={values.version_id}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                  touched.version_id && errors.version_id
                                }
                              >
                                <option selected disabled value={""}>
                                  Select Version
                                </option>
                                {versions.map((version, i) => {
                                  return (
                                    <option value={version.id} key={i}>
                                      {version.version_name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {touched["version_id"] && errors["version_id"]}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                          <div className="col-md-1">
                            <Form.Label className="mb-2 d-block">
                              {" "}
                              &nbsp;{" "}
                            </Form.Label>
                            <button
                              className="black-filled-button px-3 py-2"
                              style={{ minHeight: 45 }}
                              type="submit"
                            >
                              Go
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>

        {Object.keys(data).length && (
          <div className="row">
            <div className="col-xxl-9 col-md-9  col-sm-8">
              {Object.keys(data).map((key) => {
                return (
                  <>
                    {data[key].length && (
                      <Card className="pb-2 mb-4">
                        <Card.Body>
                          <Card.Text>
                            <div className="row">
                              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 ps-58 mb-3">
                                <div className="d-flex justify-content-between align-items-center mb-4">
                                  <div className="d-flex align-items-center">
                                    <div className="table-heading">
                                      {data[key][0].city_name} (Top City)
                                    </div>
                                    <div className="py-0 ms-5"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {data[key].map((version) => {
                              return (
                                <Form>
                                  <div className="row">
                                    <div className="col-xxl-2 col-md-2">
                                      <Form.Group
                                        className="mb-4"
                                        controlId="brandNameInput"
                                      >
                                        <Form.Label className="mb-2 ">
                                          Version
                                        </Form.Label>
                                        <div className="Default_select mt-3">
                                          {version.version_name}
                                        </div>
                                      </Form.Group>
                                    </div>
                                    <div className="col-xxl-10 col-md-10">
                                      <div className="row">
                                        <div className="col-md-4">
                                          <Form.Group
                                            className="mb-4"
                                            controlId="brandNameInput"
                                          >
                                            <Form.Label className="mb-2">
                                              Ex Showroom Price
                                            </Form.Label>
                                            <Form.Control
                                              type="number"
                                              placeholder="32900000"
                                              className="nuber_input"
                                              defaultValue={
                                                version.ex_showroom_price
                                              }
                                              onChange={(e) =>
                                                handleFieldChange(
                                                  e.target.value,
                                                  version.id,
                                                  "ex_showroom_price"
                                                )
                                              }
                                            />
                                          </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                          <Form.Group
                                            className="mb-3"
                                            controlId="SlugControlInput"
                                          >
                                            <Form.Label className="mb-2">
                                              RTO Price
                                            </Form.Label>
                                            <Form.Control
                                              type="number"
                                              placeholder="32900000"
                                              className="nuber_input"
                                              defaultValue={version.rto}
                                              onChange={(e) =>
                                                handleFieldChange(
                                                  e.target.value,
                                                  version.id,
                                                  "rto"
                                                )
                                              }
                                            />
                                          </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                          <Form.Group
                                            className="mb-3"
                                            controlId="StatusControlSelect"
                                          >
                                            <Form.Label className="mb-2">
                                              Insurance Price
                                            </Form.Label>
                                            <Form.Control
                                              type="number"
                                              placeholder="32900000"
                                              className="nuber_input"
                                              defaultValue={version.insurance}
                                              onChange={(e) =>
                                                handleFieldChange(
                                                  e.target.value,
                                                  version.id,
                                                  "insurance"
                                                )
                                              }
                                            />
                                          </Form.Group>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Form>
                              );
                            })}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    )}
                  </>
                );
              })}
            </div>

            <div className="col-xxl-3 col-md-3  col-sm-4">
              <Accordion defaultActiveKey="0" className="mb-4">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="p-3 pb-1 ps-2">
                    Submit Version Price
                  </Accordion.Header>
                  <Accordion.Body className="pt-2">
                    <div className="d-flex justify-content-between flex-wrap mb-2">
                      <button
                        className="d-flex align-items-center px-3 py-2 btn btn-success"
                        onClick={handleAllFormSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion defaultActiveKey="0" className="mb-4">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="p-3 pb-1 ps-2">
                    Fixed Price
                  </Accordion.Header>
                  <Accordion.Body>
                    <Form>
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group
                            className="mb-4"
                            controlId="brandNameInput"
                          >
                            <Form.Label className="mb-2">
                              Ex-Showroom Price
                            </Form.Label>
                            <Form.Control type="number" placeholder="0" />
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          <Form.Group
                            className="mb-3"
                            controlId="SlugControlInput"
                          >
                            <Form.Label className="mb-2">RTO Price</Form.Label>
                            <Form.Control type="number" placeholder="0" />
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          <Form.Group
                            className="mb-3"
                            controlId="StatusControlSelect"
                          >
                            <Form.Label className="mb-2">
                              Insurance Price
                            </Form.Label>
                            <Form.Control type="number" placeholder="0" />
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          <div className="d-flex align-items-center">
                            <Form.Label
                              for="autoSizingCheck2"
                              className="mb-0 mt-1"
                            >
                              Luxury Car
                            </Form.Label>
                            <Form.Check
                              className="ms-2 check-lg"
                              type="checkbox"
                              id="autoSizingCheck2"
                            />
                          </div>
                        </div>
                      </div>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}
