import React from "react";

export default function FormFieldErrorMsg({ touched, errors, name }) {
  return (
    <>
      {" "}
      {touched[name] && errors[name] && 
      (
        <div className="invalid-feedback"> {errors[name]}</div>
      )}
    </>
  );
}
