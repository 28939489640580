import * as Yup from "yup";

const addMagazineSchema = Yup.object().shape({
  title: Yup.string().max(255).required("Title is required"),
  url: Yup.string().required("Url is required"),
  post_id: Yup.string(),
  display_date: Yup.string().required("Display date is required"),
  content: Yup.string(),
});

export default addMagazineSchema;
