import { BASE_URL_AUTOX, getConfig } from "../../../request";
import axios from "axios";

export const GET_BIKE_BRAND_LIST = "new-bike/brand/filtered/list";
export const GET_ALL_BRAND_LIST = "new-bike/brand/all/list";
export const GET_BIKE_BRAND_URL = "new-bike/brand/view/";
export const ADD_BIKE_BRAND = "new-bike/brand/add";
export const DELETE_BIKE_BRAND = "new-bike/brand/delete/";
export const GET_BIKE_BRAND_BY_ID = "new-bike/brand/";
export const UPDATE_BIKE_BRAND = "new-bike/brand/update/";
export const BULK_UPDATE_BIKE_BRAND_ORDERING = "new-bike/brand/ordering/update";

export function getAllBikeBrands(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_ALL_BRAND_LIST}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get List of Bike Brands
 * getBrandList is used to get all the data of Bike brands
 * @param token
 * @param payload
 */
export function getBikeBrandList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_BIKE_BRAND_LIST}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get Bike Brand By Id
 * getBrandById is used to get bike brand data by id
 * @param payload
 */
export function getBikeBrandById(token, brand_id) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_BIKE_BRAND_BY_ID}${brand_id}`,
    getConfig(token)
  );
}
/**
 * Get View link of Bike Brand
 * getBrandViewLink is used to view link of bike brand
 * @param token
 * @param payload
 */
export function getBikeBrandViewLink(token, id) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_BIKE_BRAND_URL}${id}`,
    getConfig(token)
  );
}

/**
 * Add New bike Brand Data
 * addBrand is used to create new bike brand data
 * @param token
 * @param payload
 */
export function addBikeBrand(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${ADD_BIKE_BRAND}`,
    payload,
    getConfig(token, { "Content-Type": "multipart/form-data" })
  );
}

/**
 * Update bike Brand By Id
 * updateBrand is used to update data of bike brand by id
 * @param token
 * @param id
 * @param payload
 */
export function updateBikeBrand(token, brand_id, payload) {
  return axios.put(
    `${BASE_URL_AUTOX}${UPDATE_BIKE_BRAND}${brand_id}`,
    payload,
    getConfig(token, { "Content-Type": "multipart/form-data" })
  );
}

/**
 * Update bike Brand By Id
 * bulkUpdateBrandOrdering is used to update ordering of bike brand
 * @param token
 * @param payload
 */
export function bulkUpdateBikeBrandOrdering(token, payload) {
  // console.log("test 4567")
  return axios.put(
    `${BASE_URL_AUTOX}${BULK_UPDATE_BIKE_BRAND_ORDERING}`,
    payload,
    getConfig(token)
  );
}

/**
 * Delete bike Brand By Id
 * deleteBrand is used to delete single record of bike brand by id
 * @param token
 * @param id
 */
export function deleteBikeBrand(token, brand_id) {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_BIKE_BRAND}${brand_id}`,
    getConfig(token)
  );
}

export default {
  getAllBikeBrands,
  getBikeBrandList,
  getBikeBrandById,
  getBikeBrandViewLink,
  addBikeBrand,
  updateBikeBrand,
  deleteBikeBrand,
  bulkUpdateBikeBrandOrdering,
};
