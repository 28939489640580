import { useFormik } from "formik";
import { notifyError, notifySuccess } from "../../../utility/common";
import { getToken } from "../../../utility/constants";
import { trendingValidation } from "./schemaValidations";
import { useEffect, useState } from "react";
import apiHandler from "./apiHandler";
import { getAllBrands, getModelByBrandId } from "../../../request";
import { Card, Form } from "react-bootstrap";
import eye from "../../../assets/icons/bootstrap-icons/eye.svg";
import { ReactSVG } from "react-svg";
import FormSelect from "../../../components/FormSelect";

export default function AddTrendingCar({ onSuccess }) {
  const token = getToken();
  const [brandList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);

  const { handleSubmit, values, errors, setFieldValue, touched } = useFormik({
    initialValues: {
      brand_id: -1,
      model_id: -1,
    },
    validationSchema: trendingValidation,
    onSubmit: (values, action) => {
      apiHandler
        .addTrendingCar(values, token)
        .then((result) => {
          notifySuccess("Added Successfully !!");
          action.resetForm();
          onSuccess();
        })
        .catch((error) => {
          console.log(error);
          notifyError(error?.response?.data?.message);
        })
        .finally(() => {
          action.resetForm();
          action.setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    loadBrand();
  }, []);

  const loadBrand = async () => {
    try {
      const res = await getAllBrands();
      const response = res.data.result;
      setBrandList(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleBrandChange = (brand_id) => {
    getModels(brand_id);
  };

  const getModels = async (brand_id) => {
    try {
      await getModelByBrandId({ brand_id }, token)
        .then((result) => {
          setModelList(result.data.result);
        })
        .catch((error) => {
          notifyError(error.message);
        });
    } catch (error) {
      return error;
    }
  };

  const redirectTrendingCars = () => {
    window.open("https://www.autox.com/top-selling-cars-in-india", "_blank");
  };

  return (
    <Card className="ps-2 pe-2">
      <Form onSubmit={handleSubmit}>
        <Card.Body>
          <Card.Text>
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="d-flex align-items-center mb-3">
                  <div className="d-flex align-items-center justify-content-between w-100 ">
                    <div className="table-heading">Add Trending Cars </div>
                    <Form.Group className="d-flex gap-3">
                      <div>
                        <ReactSVG
                          src={eye}
                          onClick={redirectTrendingCars}
                          className="eye-bg cursor-pointer"
                        />
                      </div>
                      <button
                        type="submit"
                        className="add-button d-flex align-items-center px-3 "
                      >
                        Add New
                      </button>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </Card.Text>
          <Card.Text>
            <FormSelect
              controlId={"brandName"}
              label={"Select Brand"}
              name={"brand_id"}
              defaultName={"Select Brand"}
              setFieldValue={setFieldValue}
              items={brandList}
              value={values.brand_id}
              itemName={"brand_name"}
              touched={touched}
              showDefault={true}
              errors={errors}
              getValues={handleBrandChange}
              isRequired={true}
            />
            <FormSelect
              controlId={"modelName"}
              label={"Select Model"}
              name={"model_id"}
              defaultName={"Select Model"}
              setFieldValue={setFieldValue}
              value={values.model_id}
              showDefault={true}
              items={modelList}
              itemName={"model_name"}
              touched={touched}
              errors={errors}
              isRequired={true}
            />
          </Card.Text>
        </Card.Body>
      </Form>
    </Card>
  );
}
