import { Card } from "react-bootstrap";
import HeadingTwo from "../../components/HeadingTwo";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import PlusLargeIcon from "../../assets/icons/bootstrap-icons/plus-lg.svg";
import { useState } from "react";
import { getToken } from "../../utility/constants";

import Datatable from "../../components/Table";
import { getPostGalleryList } from "./apiHandler";
import PostGalleryColumns from "./PostGalleryColumns";
export default function PostGalleryListing() {
  const [bikeGallery, setBikeGallery] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [reload, setReload] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const navigator = useNavigate();
  const token = getToken();
  const reloadTable = () => {
    setReload(!reload);
  };

  console.log(bikeGallery, "bikeGallery");

  return (
    <div>
      <Card>
        <Card.Body>
          <Card.Text>
            <div className="d-flex justify-content-between align-items-center">
              <HeadingTwo
                title="Post Gallery"
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />
              <button
                className="add-variant-button d-flex align-items-center justify-content-between me-2 mb-2 px-3"
                onClick={() => navigator("/add-post-gallery")}
              >
                <ReactSVG
                  src={PlusLargeIcon}
                  height="18px"
                  width="18px"
                  className="mr-2"
                />{" "}
                Add Gallery
              </button>
            </div>
          </Card.Text>
          <div className="row dashboard">
            <Datatable
              api={getPostGalleryList}
              columns={PostGalleryColumns(
                reloadTable,
                token,
                currentPage,
                itemsPerPage
              )}
              reload={reload}
              setReload={setReload}
              setTotalRecords={setTotalRecords}
              // setCurrentPage={setCurrentPage}
              // itemsPerPage={itemsPerPage}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
