import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs";
import { ReactSVG } from "react-svg";
import BackIcon from "../../assets/icons/bootstrap-icons/arrow-left.svg";
import { Button, Card } from "react-bootstrap";
import { useFormik } from "formik";
import HeadingTwo from "../../components/HeadingTwo";
import FormControl from "../../components/FormControl";
import UserNewsletterApiHandler from "./UserNewsletterApiHandler";
import { getToken } from "../../utility/constants";
import { notifyError, notifySuccess } from "../../utility/common";
import { getNewsLetterList } from "../NewsLetter/apiHandler";
import FormSelect from "../../components/FormSelect";

export default function EditUserNewsletterSubscription() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [contactList, setContactList] = useState([]);
  const token = getToken();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch contact lists
        const newsletterResponse = await getNewsLetterList(token);
        setContactList(
          newsletterResponse.data.result.map((item) => ({
            id: item.newsletter_id,
            name: item.name,
          }))
        );

        // Fetch subscription details
        const subscriptionResponse =
          await UserNewsletterApiHandler.getUserNewsletterSubscription(
            token,
            id
          );
        if (subscriptionResponse.data) {
          const subscription = subscriptionResponse.data.data;
          console.log("subscription", subscription);
          setFieldValue("email", subscription.email);
          setFieldValue("newsletter_id", subscription.newsletter_id);
        }
        setIsLoading(false);
      } catch (error) {
        notifyError("Error fetching data");
        console.error(error);
      }
    };
    fetchData();
  }, [token, id]);

  const { handleSubmit, errors, values, touched, setFieldValue, resetForm } =
    useFormik({
      initialValues: {
        email: "",
        newsletter_id: "",
      },
      enableReinitialize: true,
      onSubmit: async (values, action) => {
        try {
          const payload = values;
          const res = await UserNewsletterApiHandler.updateSubscriber(
            token,
            id,
            payload
          );
          if (res.status === 200) {
            notifySuccess("User subscription updated successfully");
            navigate("/user-newsletter-subscriptions");
          }
        } catch (error) {
          notifyError(error.response?.data?.message || "Update failed");
          console.error(error);
        }
      },
    });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <Breadcrumbs />
        <button
          className="d-flex back-button py-2 px-3 mb-4 btn btn-primary"
          onClick={() => navigate("/user-newsletter-subscriptions")}
        >
          <ReactSVG src={BackIcon} className="me-1" />
          Back
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <Card className="p-3 col-12 col-md-6">
            <HeadingTwo
              title={"Edit Newsletter Subscriber"}
              type="dashboard"
              showHeader={true}
              pointerOnHover={true}
            />
            <div className="row g-4">
              <div className="">
                <FormControl
                  controlId={"email"}
                  label={"Email"}
                  type={"email"}
                  placeholder={"Enter email"}
                  name={"email"}
                  value={values.email}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                />
                <FormSelect
                  controlId={"newsletter_id"}
                  label={"Contact List"}
                  items={contactList}
                  itemName={"name"}
                  name={"newsletter_id"}
                  value={values.newsletter_id}
                  touched={touched}
                  setFieldValue={(field, value) => {
                    setFieldValue(field, Number(value));
                  }}
                  errors={errors}
                />
              </div>
            </div>
            <div>
              <button type="submit" className="green-filled-button mt-4">
                Update
              </button>
            </div>
          </Card>
        </div>
      </form>
    </>
  );
}
