import { Button, Card, Form } from "react-bootstrap";
import Breadcrumbs from "../../../Breadcrumbs";
import HeadingTwo from "../../../components/HeadingTwo";
import FormSelect from "../../../components/FormSelect";
import FormControl from "../../../components/FormControl";
import { getBikeBrandNameList, getBrandNameList } from "../../../request";
import { getToken } from "../../../utility/constants";
import { useEffect, useState } from "react";
import apiHandler from "./apiHandler";
import { useFormik } from "formik";
import deleteCarCityValidation from "./schemaValidation";
import { notifyError, notifySuccess } from "../../../utility/common";

export default function DeleteBikeCityPrice() {
  const [brandList, setBrandList] = useState([]);
  const [models, setModels] = useState([]);
  const [versions, setVersions] = useState([]);

  const token = getToken();

  console.log(brandList);
  console.log(models);
  const brandListFunction = async () => {
    const response = await getBikeBrandNameList(token);
    setBrandList(response.data.result);
  };
  const getModels = (brand_id) => {
    setModels([]);
    if (!brand_id) {
      setModels([]);
      return;
    }
    const payload = {
      brand_id,
    };
    apiHandler
      .getBikeModelByBrandId(token, payload)
      .then((response) => {
        setModels(response.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    brandListFunction();
  }, []);

  const getVersions = (model_id) => {
    if (!model_id) {
      setModels([]);
      return;
    }
    const payload = {
      model_id,
    };
    apiHandler
      .getBikeVersionByModelId(token, payload)
      .then((response) => {
        setVersions(response.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      brand_id: -1,
      model_id: -1,
      version_id: -1,
      city_id: "",
    },
    validationSchema: deleteCarCityValidation,
    onSubmit: async (values) => {
      const response = await apiHandler.deleteBulkBikeCityPrice(token, values);
      if (response.status == 200) {
        notifySuccess(response?.data.message);
        resetForm();
      } else {
        notifyError(response.data.message);
      }
    },
  });
  console.log(values, errors);
  return (
    <>
      <Breadcrumbs />
      <Card className="p-3">
        <HeadingTwo
          title={"Delete Bike City Price"}
          type={"dashboard"}
          showHeader={true}
          pointerOnHover={true}
        />
        <div>
          <Form className="w-100" onSubmit={handleSubmit}>
            <FormSelect
              controlId={"Brand"}
              label={"Select Brand"}
              placeholder={"Select Brand"}
              touched={touched}
              name={"brand_id"}
              className={"mb-3"}
              errors={errors}
              value={values.brand_id}
              items={brandList}
              setFieldValue={setFieldValue}
              getValues={getModels}
              defaultName={"Select Brand"}
              itemName={"brand_name"}
              isRequired={true}
            />
            <FormSelect
              controlId={"Model"}
              label={"Select Model"}
              placeholder={"Select Model"}
              touched={touched}
              name={"model_id"}
              defaultName={"Select Model"}
              className={"mb-3"}
              errors={errors}
              value={values.model_id}
              items={models}
              setFieldValue={setFieldValue}
              getValues={getVersions}
              itemName={"model_name"}
              isRequired={true}
            />
            <FormSelect
              controlId={"version"}
              label={"Select Version"}
              placeholder={"Select Version"}
              touched={touched}
              defaultName={"Select Version"}
              className={"mb-3"}
              errors={errors}
              value={values.version_id}
              name={"version_id"}
              items={[
                { id: "all_version", version_name: "All Versions" },
                ...versions,
              ]}
              setFieldValue={setFieldValue}
              itemName={"version_name"}
              isRequired={true}
            />
            <FormControl
              controlId={"cityId"}
              label={"City IDs (Multiple Ids"}
              placeholder={
                "e.g:65,215,413,498 Or Leave it black to remove all city Prices"
              }
              touched={touched}
              name={"city_id"}
              errors={errors}
              value={values.city_id}
              setFieldValue={setFieldValue}
              as={"textarea"}
              rows={3}
            />
            <button className="delete-price">Delete Price</button>
          </Form>
        </div>
      </Card>
    </>
  );
}
