import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs";
import { ReactSVG } from "react-svg";
import BackIcon from "../../assets/icons/bootstrap-icons/arrow-left.svg";
import { Button, Card } from "react-bootstrap";
import { useFormik } from "formik";
import HeadingTwo from "../../components/HeadingTwo";
import FormControl from "../../components/FormControl";

export default function TemplateForm({ initialValues, onSubmit, title }) {
  const navigate = useNavigate();
  const [isPreview, setIsPreview] = useState(false);
  const { handleSubmit, errors, values, touched, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values, action) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const togglePreview = () => setIsPreview(!isPreview);

  return (
    <>
      <div className="d-flex justify-content-between">
        <Breadcrumbs />
        <button
          className="d-flex back-button py-2 px-3 mb-4 btn btn-primary"
          onClick={() => navigate("/template")}
        >
          <ReactSVG src={BackIcon} className="me-1" />
          Back
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <Card className="p-3">
            <HeadingTwo
              title={title}
              type="dashboard"
              showHeader={true}
              pointerOnHover={true}
            />
            <div className="row g-4">
              {/* Other Form Controls */}
              <div>
                <FormControl
                  controlId={"templateNameInput"}
                  label={"Template Name"}
                  type={"text"}
                  placeholder={"Enter Template Name"}
                  name={"template_name"}
                  value={values.template_name}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  isRequired={true}
                />
              </div>
              <div>
                <FormControl
                  controlId={"templateSubjectInput"}
                  label={"Template Subject"}
                  type={"text"}
                  placeholder={"Enter Template Subject"}
                  name={"subject"}
                  value={values.subject}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  isRequired={true}
                />
              </div>
              <div>
                <FormControl
                  controlId={"templateBodyInput"}
                  label={"Text Body"}
                  type={"text"}
                  placeholder={"Enter Template Body"}
                  name={"text_body"}
                  value={values.text_body}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  as={"textarea"}
                  rows={3}
                />
              </div>

              {/* HTML Editor and Preview Toggle */}
              <div className="mb-2">
                <Button
                  variant={isPreview ? "secondary" : "primary"}
                  onClick={() => setIsPreview(false)}
                  className="me-2"
                >
                  Edit HTML
                </Button>
                <Button
                  variant={isPreview ? "primary" : "secondary"}
                  onClick={togglePreview}
                >
                  Preview
                </Button>
              </div>

              <div>
                {isPreview ? (
                  <div
                    className="html-preview border p-3"
                    dangerouslySetInnerHTML={{ __html: values.html_body }}
                  />
                ) : (
                  <FormControl
                    controlId={"templatehtmlBodyInput"}
                    label={"HTML Body"}
                    type={"text"}
                    placeholder={"Enter Template Body"}
                    name={"html_body"}
                    value={values.html_body}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    as={"textarea"}
                    rows={20}
                  />
                )}
              </div>
            </div>
            <div>
              <button type="submit" className="green-filled-button mt-4">
                Save
              </button>
            </div>
          </Card>
        </div>
      </form>
    </>
  );
}
