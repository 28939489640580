import * as Yup from "yup";

export const BikeFAQValidation = Yup.object({
  question: Yup.string().required("Question is required"),
  answer: Yup.string().required("Answer is required"),
  faq_ordering: Yup.number().required("Faq Ordering is required"),
  is_default: Yup.number()
    .oneOf([0, 1], "Please enter valid status")
    .required("Status is required"),
  faq_condition: Yup.string().required("Faq Condition is required"),
});
