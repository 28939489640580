import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Breadcrumbs from "../../../Breadcrumbs";
import Image from "react-bootstrap/Image";
import BackIcon from "../../../assets/icons/Back_Arrow.png";
import { getToken } from "../../../utility/constants";
import APIHandler from "./apiHandler";
import { carDrivingSchoolValidation } from "./schemaValidation";
import { ToastContainer } from "react-toastify";
import { notifyError, notifySuccess } from "../../../utility/common";
import CarDrivingSchoolForm from "./CarDrivingSchoolForm";

export default function EditCarDrivingSchool() {
  const navigate = useNavigate();
  const { id } = useParams();
  const token = getToken();
  const [queryParams, setQueryParams] = useSearchParams();
  const [initialValues, setInitialValues] = useState({
    city_id: -1,
    state_id: -1,
    school_name: "",
    school_url: "",
    driving_school_address: "",
    driving_school_area: "",
    driving_school_map: "",
    latitude: "",
    longitude: "",
    phone_number: "",
    email_id: "",
  });

  useEffect(() => {
    if (queryParams.get("added") && queryParams.get("added") === "true") {
      // notifySuccess("Car Driving School added successfully")
      setQueryParams({ ["added"]: "false" });
    } else {
      return;
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    values,
    errors,
    setFieldValue,
    touched,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: carDrivingSchoolValidation,
    onSubmit: async (values, action) => {
      console.log("test");
      // const [longitude, latitude] = values.driving_school_map.split(",");
      const payload = { ...values };
      delete payload.id;
      delete payload.created_date;
      delete payload.updated_date;
      delete payload.state_id;
      APIHandler.updateDrivingSchoolById(token, id, payload)
        .then((result) => {
          fetchData();
          notifySuccess("Car Driving School updated successfully");
          setTimeout(() => {
            navigate("/car-driving-school");
          }, 1500);
        })
        .catch((error) => {
          notifyError(error.message);
        });
    },
  });

  const fetchData = async () => {
    APIHandler.getCarDrivingSchoolById(token, id)
      .then((result) => {
        const response = result.data.data;
        setInitialValues(response[0]);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  };

  return (
    <div className="row dashboard">
      <ToastContainer />
      <div className="row">
        <div className="col-xl-11 col-sm-11">
          <Breadcrumbs removeId={true} />
        </div>
        <div className="col-xl-1 col-sm-1">
          <button
            className="d-flex back-button py-2 px-3 mb-2 btn btn-primary"
            onClick={() => navigate("/car-driving-school")}
          >
            <Image
              src={BackIcon}
              width="16px"
              height="16px"
              className="mt-1 me-2"
            />{" "}
            Back
          </button>
        </div>
      </div>

      <Card>
        <Card.Body>
          <Card.Text>
            <div className="row dashboard">
              <div className="table-heading fw-bold">
                Car Driving School Form
              </div>
              <CarDrivingSchoolForm
                values={values}
                errors={errors}
                touched={touched}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                type={"Update"}
              />
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
