import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Accordion, Card, Form } from "react-bootstrap";
import FormSelect from "../../../components/FormSelect";
import SidebarAPI from "../../Sidebar/sidebar/SidebarAPI";
import { getToken } from "../../../utility/constants";
import CustomWidgetForm from "./CustomWidgetForm";
import SideBarSelect from "./SideBarSelect";
import { notifyError, notifySuccess } from "../../../utility/common";
import WidgetShortCodes from "./WidgetShortCodes.js";

export default function WidgetDescription() {
  const navigate = useNavigate();

  const [customWidgets, setCustomWidgets] = useState([]);
  const [removedWidgets, setRemovedWidgets] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const token = getToken();
  const { id } = useParams();

  const handleUpdateWidgets = (index, field, value) => {
    const updatedWidgets = [...customWidgets];
    updatedWidgets[index][field] = value;
    setCustomWidgets(updatedWidgets);
  };
  const handleAddWidgets = () => {
    setCustomWidgets([
      ...customWidgets,
      { widget_name: "", widget_type: -1, description: "", position: "1" },
    ]);
  };

  useEffect(() => {
    const getSidebarWidgets = async () => {
      try {
        const payload = {
          sidebar_id: id,
          sortBy: "",
          sortOrder: "",
        };
        const response = await SidebarAPI.getWidgetFromSidebar(token, payload);
        const preData = response.data.result;

        const extractedData = preData.map((item) => ({
          id: item.id,
          description: item.description,
          widget_name: item.widget_name,
          widget_type: item.widget_type,
          position: item.position,
        }));

        setCustomWidgets(extractedData);
      } catch (error) {
        console.log(error);
      }
    };

    getSidebarWidgets();
  }, [id]);

  const updatedWidgets = async () => {
    try {
      const formData = new FormData();
      formData.append("sidebar_id", id);
      formData.append("widget_removed", JSON.stringify(removedWidgets));

      customWidgets.forEach((widget, index) => {
        formData.append(`widgets[${index}][widget_name]`, widget.widget_name);
        formData.append(`widgets[${index}][widget_type]`, widget.widget_type);
        formData.append(`widgets[${index}][description]`, widget.description);
        formData.append(`widgets[${index}][position]`, widget.position);
        if (widget.id) {
          formData.append(`widgets[${index}][id]`, widget.id);
        }
      });

      const response = await SidebarAPI.updateWidgets(token, formData);

      if (response.status === 200) {
        notifySuccess("Updated successfully");
        setTimeout(() => {
          navigate(`/widget`);
        }, 1500);
      } else {
        console.log(response);
        notifyError("Something went wrong");
      }
    } catch (error) {
      console.log(error);
      notifyError("Something went wrong");
    }
  };
  // const updatedWidgets = async () => {
  //   try {
  //     const encodedWidgets = customWidgets.map((widget) => ({
  //       ...widget,
  //       description: encodeURIComponent(widget.description),
  //     }));
  //     const payload = {
  //       sidebar_id: id,
  //       widgets: JSON.stringify(encodedWidgets),
  //       widget_removed: JSON.stringify(removedWidgets),
  //     };
  //     const response = await SidebarAPI.updateWidgets(token, payload);
  //     if (response.status === 200) {
  //       notifySuccess("Updated successfully");
  //       setTimeout(() => {
  //         navigate(`/widget`);
  //       }, 1500);
  //     } else {
  //       console.log(response);
  //       notifyError("Something went wrong");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     notifyError("Something went wrong");
  //   }
  // };
  console.log(customWidgets, "customWidgets");
  return (
    <>
      <SideBarSelect selectedSidebar={id} />
      {id ? (
        <div className="widget-area">
          <div className="custom-widget-form">
            <CustomWidgetForm
              customWidgets={customWidgets}
              setCustomWidgets={setCustomWidgets}
              handleAddWidgets={handleAddWidgets}
              handleUpdateWidgets={handleUpdateWidgets}
              setRemovedWidgets={setRemovedWidgets}
              removedWidgets={removedWidgets}
            />
          </div>
          <div className="widget-action-area">
            <div className="h-100" />
            <div className="widget-action">
              <Accordion defaultActiveKey="0" className="mb-4">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="p-3 pb-1 ps-2">
                    Action
                  </Accordion.Header>
                  <Accordion.Body className="pt-1 d-flex flex-column gap-3">
                    <div className="d-flex gap-3">
                      <div className="">
                        <button
                          type="submit"
                          className="add-widget-button"
                          onClick={(e) => handleAddWidgets()}
                        >
                          Add More Widget
                        </button>
                      </div>

                      <button
                        type="submit"
                        className="green-submit-button"
                        onClick={updatedWidgets}
                      >
                        Submit
                      </button>
                    </div>

                    <div className="w-100 d-flex justify-content-between">
                      <button
                        type="submit"
                        className="shortcodes-btn"
                        onClick={handleShowModal}
                      >
                        Shortcodes
                      </button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      ) : null}
      <WidgetShortCodes show={showModal} handleClose={handleCloseModal} />
    </>
  );
}
