import * as Yup from "yup";

const adsCount = [1, 2, 3, 4, 5, 6];

const validationSchema = Yup.object()
  .shape(
    adsCount.reduce((schema, ad) => {
      return {
        ...schema,
        [`desktop_header_script_${ad}`]: Yup.string().nullable(),
        [`desktop_body_script_${ad}`]: Yup.string().nullable(),
        [`desktop_position_${ad}`]: Yup.number()
          .typeError("Desktop position must be a number")
          .nullable(),
        [`mobile_header_script_${ad}`]: Yup.string().nullable(),
        [`mobile_body_script_${ad}`]: Yup.string().nullable(),
        [`mobile_position_${ad}`]: Yup.number()
          .typeError("Mobile position must be a number")
          .nullable()
      };
    }, {})
  )
  .test(
    "desktop-group-validation",
    "All desktop fields must be filled if any one is provided",
    function (values) {
      return adsCount.every((ad) => {
        const header = values[`desktop_header_script_${ad}`]?.trim();
        const body = values[`desktop_body_script_${ad}`]?.trim();
        const position = values[`desktop_position_${ad}`];

        const isAnyFilled =
          header || body || (position !== undefined && position !== null);
        const isAllFilled =
          header && body && position !== undefined && position !== null;

        return !isAnyFilled || isAllFilled;
      });
    }
  )
  .test(
    "mobile-group-validation",
    "All mobile fields must be filled if any one is provided",
    function (values) {
      return adsCount.every((ad) => {
        const header = values[`mobile_header_script_${ad}`]?.trim();
        const body = values[`mobile_body_script_${ad}`]?.trim();
        const position = values[`mobile_position_${ad}`];

        const isAnyFilled =
          header || body || (position !== undefined && position !== null);
        const isAllFilled =
          header && body && position !== undefined && position !== null;

        return !isAnyFilled || isAllFilled;
      });
    }
  );

export default validationSchema;
