import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { ReactSVG } from "react-svg";
import menu from "../assets/icons/bootstrap-icons/header-icons/white menu.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Dropdown from "react-bootstrap/Dropdown";
import { useProSidebar } from "react-pro-sidebar";
import PersonIcon from "../assets/icons/bootstrap-icons/header-icons/Admin.svg";
import LogoutIcon from "../assets/icons/bootstrap-icons/box-arrow-right.svg";
import SearchIcon from "../assets/icons/bootstrap-icons/header-icons/search.svg";
import FullScreenIcon from "../assets/icons/bootstrap-icons/header-icons/Full Screen white.svg";
import SettingsIcon from "../assets/icons/bootstrap-icons/header-icons/Settings white.svg";
import ProfileIcon from "../assets/icons/bootstrap-icons/header-icons/profile.png";

export default function Header() {
  const { t } = useTranslation();
  const { collapseSidebar } = useProSidebar();
  const navigate = useNavigate();
  const userName = localStorage.getItem("userName");
  const userImage = localStorage.getItem("userImage");

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className="user-name"
      href="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  const logoutUser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_role");
    localStorage.removeItem("admin_type");
    sessionStorage.removeItem("menuItems");
    sessionStorage.removeItem("notificationCount");
    localStorage.removeItem("userName");
    localStorage.removeItem("userImage");
    localStorage.removeItem("user_id");
    localStorage.removeItem("token_expiration_time");
    navigate("/login");
  };

  const handleFullscreen = () => {
    const element = document.documentElement; // Use the entire document as the fullscreen element
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      // Firefox
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      // IE/Edge
      element.msRequestFullscreen();
    }
  };
  return (
    <>
      <Navbar className="header-container">
        <ReactSVG
          src={menu}
          className="sidebar-toggle cursor-pointer mx-3"
          onClick={() => collapseSidebar()}
        />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav className="justify-space-between">
            <div className="d-flex align-items-end">
              {/* <div className="">
                <ReactSVG src={SearchIcon} className="header-icon" />
              </div> */}
              <div className="">
                <ReactSVG
                  src={FullScreenIcon}
                  className="header-icon"
                  onClick={handleFullscreen}
                />
              </div>

              <div className="">
                <ReactSVG src={SettingsIcon} className="header-icon" />
              </div>
            </div>
            <Dropdown className="admin-button">
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-custom-components"
              >
                <div className="d-flex align-items-center mx-3">
                  {userImage ? (
                    <img
                      className="header-img"
                      src={userImage}
                      alt="user-image"
                    />
                  ) : (
                    <ReactSVG src={PersonIcon} />
                  )}
                  <div className="mx-2 me-0">
                    <div className="user-role">{userName}</div>
                  </div>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="mt-3">
                <Dropdown.Item href="" onClick={() => navigate("/profile")}>
                  <div className="d-flex align-items-center">
                    <img
                      className="dropdown-icons"
                      src={ProfileIcon}
                      alt="dropdown-icon"
                    />
                    <div className="mx-2 dropdown-icons-text">
                      {t("Profile")}
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item href="" onClick={() => logoutUser()}>
                  <div className="d-flex align-items-center">
                    <ReactSVG
                      className="dropdown-icons-logout"
                      src={LogoutIcon}
                    />
                    <div className="mx-2 dropdown-icons-text">
                      {t("Logout")}
                    </div>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
