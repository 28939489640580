import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs";
import PageForm from "./PageForm";
import { getToken } from "../../utility/constants";
import { addNewPage } from "./apiHandler";
import { notifySuccess } from "../../utility/common";
import { useFormik } from "formik";
import PageValidationSchema from "./validation";

export default function AddPage() {
  const navigate = useNavigate();
  const token = getToken();

  const { handleSubmit, values, setFieldValue, touched, errors } = useFormik({
    initialValues: {
      parent: 0,
      page_name: "",
      slug: "",
      heading: "",
      breadcrumb: "",
      image: "",
      description: "",
      content: "",
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
      meta_canonical: "",
      status: "",
    },
    enableReinitialize: true,
    validationSchema: PageValidationSchema,
    onSubmit: (values) => {
      addNewPage(values, token).then((res) => {
        console.log(res);
        if (res.status === 200) {
          notifySuccess("Page added successfully");
          setTimeout(() => {
            window.location.reload();
            // navigate("/page");
          }, 2000);
        }
      });
    },
  });

  return (
    <>
      <Breadcrumbs />{" "}
      <div>
        <PageForm
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          handleSubmit={handleSubmit}
          type={"Submit"}
        />
      </div>
    </>
  );
}
