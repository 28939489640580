import React, { useState, useEffect, useMemo, useCallback } from "react";
import DataTable from "react-data-table-component";
import Loader from "./Loader";
import { getToken, getUserId } from "../utility/constants";
import CustomPagination from "./CustomPagination";
import { notifyError } from "../utility/common";
// import io from "socket.io-client"; // Import the socket.io client
import { BASE_URL_AUTOX } from "../request";
import socket from "../socket";
function DatatableLoader() {
  return (
    <div style={{ padding: "100px" }}>
      <Loader size="100px" color="danger" />
    </div>
  );
}

export default function PostListingComponent({
  api,
  columns,
  filterText,
  itemsPerPage = 25,
  setReload,
  setTotalRecords,
  updateOrderingHandler,
  isDraggable,
  showHeader,
  header,
  reload,
  customStyles,
  search,
  filter,
  column_ordering,
  sortByDir = "desc",
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const [isInitialize, setIsInitialize] = useState(false);
  const token = getToken();
  const user_id=getUserId()

  // Socket connection setup


  // Function to request posts data from the server using socket
  const fetchApiData = useCallback(
    (page, size = perPage, sortBy = "id", sortOrder = sortByDir) => {
      if (token === null) return;
      setLoading(true);

      // Emit event to the server to get posts
      socket.emit("getPosts", {
        search: search,
        limit: size,
        sortBy,
        sortOrder,
        page,
        user_id:user_id,
        ...filter, // Ensure the filter is passed
      });

      // Listen for the 'postsUpdated' event from the server
      socket.on("postsUpdated", (response) => {
        setData(response.result);
        setTotalRows(response.totalRecord);
        setIsInitialize(true);
        setLoading(false); // Stop loading once data is fetched
      });

      // Listen for error event (optional)
      socket.on("errorOccurred", (error) => {
        notifyError(error.message);
        setLoading(false);
      });
    },
    [socket, search, filter, perPage, sortByDir, token]
  );

  // Set up the socket listener for real-time updates
  useEffect(() => {
    // Initially fetch the posts when the component is mounted
    fetchApiData(currentPage);

    // Listen for updates to the posts and trigger a data refresh
    socket.on("postsUpdated", (response) => {
      setData(response.result);
      setTotalRows(response.totalRecord);
    });

    // Cleanup socket listeners on unmount
    return () => {
      socket.off("postsUpdated");
      socket.off("errorOccurred");
    };
  }, [fetchApiData, currentPage, socket]);

  // Handle page change
  const handlePageChange = useCallback(
    (page) => {
      if (isInitialize) {
        fetchApiData(page);
        setCurrentPage(page);
      }
    },
    [fetchApiData, isInitialize]
  );

  // Handle rows per page change
  const handlePerRowsChange = useCallback(
    (newPerPage, page) => {
      if (isInitialize) {
        fetchApiData(page, newPerPage);
        setPerPage(newPerPage);
      }
    },
    [fetchApiData, isInitialize]
  );

  useEffect(() => {
    setTotalRecords(totalRows);
  }, [totalRows, setTotalRecords]);

  const memoizedColumns = useMemo(() => columns, [columns]);

  return (
    <div className="mt-4">
      <DataTable
        columns={memoizedColumns}
        data={data}
        progressPending={loading}
        progressComponent={<DatatableLoader />}
        pagination
        paginationServer
        paginationDefaultPage={currentPage}
        paginationComponent={(props) => (
          <CustomPagination
            totalPages={Math.ceil(totalRows / perPage)}
            currentPage={currentPage}
            onChangePage={(page) => setCurrentPage(page)}
            {...props}
          />
        )}
        subHeader={showHeader}
        subHeaderComponent={header}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        draggable={isDraggable}
        customStyles={customStyles}
      />
    </div>
  );
}
