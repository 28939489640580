import React from "react";
import EditorForm from "./EditorForm";
import apiHandler, { addEditor } from "./apiHandler";
import { notifyError, notifySuccess } from "../../utility/common";
import { useNavigate } from "react-router-dom";

export default function AddEditor() {
  const navigate = useNavigate();
  const initialValues = {
    first_name: "",
    last_name: "",
    display_name: "",
    twitter: "",
    email: "",
    alternate_email: "",
    user_name: "",
    password: "",
    description: "",
    profile_image: "",
    module_id: [],
  };

  const handleSubmit = async (values) => {
    try {
      // Create a new FormData instance
      const formData = new FormData();

      // Append the values to FormData
      formData.append("first_name", values.first_name);
      formData.append("last_name", values.last_name);
      formData.append("display_name", values.display_name);
      formData.append("twitter", values.twitter);
      formData.append("email", values.email);
      formData.append("alternate_email", values.alternate_email);
      formData.append("user_name", values.user_name);
      formData.append("password", values.password);
      formData.append("description", values.description);

      // For profile image, append the file (assuming values.profile_image is a file)
      if (values.profile_image) {
        formData.append("profile_image", values.profile_image); // Assuming this is a File object
      }

      // Append the module_id array (you can decide if you want to send this as an array or as a comma-separated string)
      // if (Array.isArray(values.module_id)) {
      //   values.module_id.forEach((moduleId, index) => {
      //     formData.append(`module_id[${index}]`, moduleId);
      //   });
      // }
      formData.append("module_id", values.module_id);

      // Call the API to add the editor
      const response = await addEditor(formData); // Ensure `addEditor` handles FormData properly

      if (response.status === 200) {
        notifySuccess("Editor added successfully");
        setTimeout(() => {
          navigate("/editor");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      notifyError(error.response.data.message);
    }
  };

  return (
    <EditorForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      formType="Add"
    />
  );
}
