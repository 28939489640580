import React, { useEffect, useState } from "react";
import Header from "./Header";
import SidebarComponent from "./Sidebar";
import Loader from "./Loader";
import axios from "axios";
import { notifyError } from "../utility/common";
import SidebarComponentTest from "./SideBarComponentTest";

export default function Layout({ children }) {
  const [loading, setLoading] = useState(false);

  const logoutUser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_role");
    localStorage.removeItem("admin_type");
    sessionStorage.removeItem("menuItems");
    sessionStorage.removeItem("notificationCount");
    localStorage.removeItem("userName");
    localStorage.removeItem("userImage");
    localStorage.removeItem("user_id");
    localStorage.removeItem("token_expiration_time");
    window.location.href = "/login";
  };

  useEffect(() => {
    //Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers["Authorization"] = "Bearer " + JSON.parse(token);
        }
        const expirationTime = localStorage.getItem("token_expiration_time");
        const currentTime = Math.floor(Date.now() / 1000); // Current time in Unix format
        const bufferTime = 4; // Buffer before actual expiration
        const remainingTime = expirationTime - currentTime - bufferTime; // Time left in seconds
        if (remainingTime <= 0) {
          logoutUser();
        }
        setLoading(true);
        return config;
      },
      (error) => {
        setLoading(false);
        // return Promise.reject(error);
      }
    );

    //Response Interceptor
    axios.interceptors.response.use(
      (config) => {
        const token = localStorage.getItem("token");
        if (token) {
          // config.headers["Authorization"] = "Bearer " + JSON.parse(token);
        }
        setLoading(false);
        return config;
      },
      (error) => {
        setLoading(false);
        console.log(error, "error");
        if (error?.response?.status !== 200) {
          notifyError(error.response.data.message);
          setLoading(false);
        } else if (error?.message == "Network Error") {
          notifyError("Network Error");
          setLoading(false);
        }

        if (error?.response?.data?.error?.name == "TokenExpiredError") {
          localStorage.removeItem("token");
        }
        setLoading(false);
        return error;
      }
    );
  }, []);

  return (
    <div>
      <div className="d-flex w-100">
        {/* <SidebarComponent /> */}
        <SidebarComponentTest />

        <div className="w-100 d-flex main">
          <Header />

          <div className="scroller-main">
            <div className="m-4 mb-0">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                {loading && (
                  <>
                    <div className="loader-div">
                      <div className="overlay">
                        <div className="overlay-content">
                          <Loader color="danger" size="100" />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
