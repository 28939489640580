import { Card, Form } from "react-bootstrap";
import Heading from "../../components/Heading";

import { useNavigate } from "react-router-dom";

export default function AddGallery() {
  const navigate = useNavigate();
  const redirectType = (type) => {
    switch (type) {
      case "1":
        return "regular";
      case "2":
        return navigate("/car-model-gallery/car-gallery-add");
      case "3":
        return navigate("/bike-model-gallery/bike-gallery-add");
      default:
        return "regular";
    }
  };
  return (
    <Card className="p-3">
      <Heading
        title="Add Gallery"
        type="dashboard"
        showHeader={true}
        pointerOnHover={true}
      />

      <div>
        <Form.Label>Choose Gallery Type</Form.Label>
        <Form.Select
          aria-label="Default select example"
          className="w-50"
          onChange={(e) => redirectType(e.target.value)}
        >
          <option disabled>Select Type</option>
          <option value="1">Regular Gallery</option>
          <option value="2">Car Gallery</option>
          <option value="3">Bike Gallery</option>
        </Form.Select>
      </div>
    </Card>
  );
}
