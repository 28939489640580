import * as Yup from "yup";
const URL = /^(ftp|http|https):\/\/[^\s/$.?#].[^\s]*$/;
const phoneRegExp = /^\+\d{1,3}\s?\d{10}$/;
export const serviceCentreValidation = Yup.object({
  city_id: Yup.number().min(1, "City is required").required("City is required"),
  state_id: Yup.number()
    .min(1, "State is required")
    .required("State is required"),
  brand_id: Yup.number()
    .min(1, "Brand is required")
    .required("Brand is required"),
  service_centre_name: Yup.string().required("Service Centre Name is required"),
  service_centre_url: Yup.string(),
  service_centre_address: Yup.string(),
  service_centre_content: Yup.string(),
  meta_title: Yup.string(),
  meta_description: Yup.string(),
  meta_keyword: Yup.string(),
  canonical_url: Yup.string(),
  breadcrumbs: Yup.string(),
  robots_follow: Yup.string().oneOf(["Follow", "nofollow"]).optional(),
  robots_index: Yup.string().oneOf(["index", "noindex"]).optional(),
  phone_number: Yup.string()
    .matches(phoneRegExp, "Invalid phone number format")
    .required("Phone number is required"),

  email_id: Yup.string().email(),
});
