import { useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from "../../utility/common";
import { editSafetyRatingAPI, getSingleSafetyRatingAPI } from "./apiHandler";
import SafetyRatingForm from "./SafetyRatingForm";
import { getToken } from "../../utility/constants";
import { useEffect, useState } from "react";

export default function SafetyRatingEdit() {
  const token = getToken();
  const navigation = useNavigate();
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    brand_id: 1,
    model_id: -1,
    yearoftesting: "",
    crashtestweight: "",
    madein: "",
    globalncapadultmaxscore: "",
    globalncapadultrating: "",
    globalncapadultachievedscore: "",
    globalncapchildmaxscore: "",
    globalncapchildrating: "",
    globalncapchildachievedscore: "",
    globalncapratingvideourl: "",
    globalncapbrochure: "",
    bharatncapadultmaxscore: "",
    bharatncapadultrating: "",
    bharatncapadultachievedscore: "",
    bharatncapchildmaxscore: "",
    bharatncapchildrating: "",
    bharatncapchildachievedscore: "",
    bharatncapratingvideourl: "",
    bharatncapbrochure: "",
  });
  const editSafetyRating = async (data) => {
    try {
      const id = data.id;
      delete data.brand_id;
      delete data.id;

      const response = await editSafetyRatingAPI(id, data, token);
      if (response.status === 200) {
        notifySuccess("Safety Rating updated successfully");
        setTimeout(() => {
          navigation("/safety-rating");
        }, 2000);
      }
    } catch (error) {
      notifyError(error.response.data.message);
      return;
    }
  };
  const getSafetyRating = async () => {
    try {
      const response = await getSingleSafetyRatingAPI(id, token);
      setInitialValues(response.data.data);
      console.log(response.data.data);
      return;
    } catch (error) {
      console.log(error);
      notifyError(error.response.data.message);
      return;
    }
  };

  useEffect(() => {
    getSafetyRating();
  }, [token]);
  return (
    <>
      <SafetyRatingForm
        initialValues={initialValues}
        onSubmit={editSafetyRating}
        title={"Edit Safety Rating"}
      />
    </>
  );
}
