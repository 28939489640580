import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../request";
const GET_SAFETY_RATING_LIST = "safety-rating/";
const ADD_SAFETY_RATING = "safety-rating/add";
const EDIT_SAFETY_RATING = "safety-rating/update";
const DELETE_SAFETY_RATING = "safety-rating/delete";
export const getSafetyRatingList = (token, payload) => {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_SAFETY_RATING_LIST}filtered-list`,
    payload,
    getConfig(token)
  );
};

export const addSafetyRatingAPI = async (data, token) => {
  return axios.post(
    `${BASE_URL_AUTOX}${ADD_SAFETY_RATING}`,
    data,
    getConfig(token)
  );
};

export const editSafetyRatingAPI = async (id, data, token) => {
  return axios.put(
    `${BASE_URL_AUTOX}${EDIT_SAFETY_RATING}/${id}`,
    data,
    getConfig(token)
  );
};

export const deleteSafetyRatingAPI = async (id, token) => {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_SAFETY_RATING}/${id}`,
    getConfig(token)
  );
};

export const getSingleSafetyRatingAPI = async (id, token) => {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_SAFETY_RATING_LIST}/${id}`,
    getConfig(token)
  );
};
