import { useEffect, useState } from "react";
import { Accordion, Card, Form, Button } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { useFormik, FieldArray } from "formik";
import DynamicSelect from "../../CarUserReview/EditCarUserReview/DynamicSelect";
import { getAllBrands, getModelByBrandId } from "../../../request";
import { getToken, renderDateTime } from "../../../utility/constants";
import RepeatIcon from "../../../assets/icons/bootstrap-icons/arrow-repeat.svg";
import { ReactSVG } from "react-svg";
import { addCarGallery } from "../apiHandler";
import { notifyError, notifySuccess } from "../../../utility/common";
import CarGalleryValidationSchema from "../GalleryValidation";

export default function CarGalleryForm({ type }) {
  const [isPublishChecked, setIsPublishChecked] = useState(false);
  const [isScheduleChecked, setIsScheduleChecked] = useState(false);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const token = getToken();

  useEffect(() => {
    const fetchBrand = async () => {
      try {
        const brandList = await getAllBrands(token);
        setBrands(brandList.data.result);
      } catch (error) {
        console.log(error);
      }
    };
    fetchBrand();
  }, [token]);

  const { handleSubmit, values, setFieldValue, touched, errors } = useFormik({
    initialValues: {
      brand_id: "",
      model_id: "",
      publish_date: new Date(),
      status: 0,
      home_gallery: false,
      images: [],
    },
    validationSchema: CarGalleryValidationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("brand_id", values.brand_id);
      formData.append("model_id", values.model_id);
      formData.append("publish_date", renderDateTime(values.publish_date));
      formData.append("status", values.status);
      formData.append("home_gallery", values.home_gallery);

      values.images.forEach((image, index) => {
        formData.append(`images[${index}][file]`, image.file);
        formData.append(`images[${index}][caption]`, image.caption);
        formData.append(`images[${index}][alt]`, image.alt);
        formData.append(`images[${index}][position]`, image.position);
        formData.append(`images[${index}][order]`, image.order);
      });

      try {
        const data = await addCarGallery(formData, token);
        if (data.status === 200) {
          notifySuccess("Gallery added successfully");
        } else {
          const responseData = data.response ? data.response.data : data.data;
          const errorMessage = responseData.message || "An error occurred";
          notifyError(errorMessage);
          console.log("Response from server:", data);
        }
      } catch (error) {
        console.log("Error:", error);
        notifyError(error.message);
      }
    },
  });

  const handleFileChange = (event, position) => {
    const files = event.currentTarget.files;
    const newImages = Array.from(files).map((file, index) => ({
      blob: URL.createObjectURL(file),
      file: file,
      caption: "",
      alt: file.name,
      position: position,
      order: values.images.length + index + 1,
    }));

    setFieldValue("images", [...values.images, ...newImages]);
  };

  const handleRemoveImage = (index) => {
    const updatedImages = values.images.filter((_, i) => i !== index);
    setFieldValue("images", updatedImages);
  };

  useEffect(() => {
    if (values.brand_id) {
      const fetchModels = async () => {
        try {
          const payload = { brand_id: values.brand_id };
          const ModelList = await getModelByBrandId(payload, token);
          setModels(ModelList.data.result);
        } catch (error) {
          console.error("Error fetching models:", error);
        }
      };
      fetchModels();
    }
  }, [values.brand_id, token]);

  const handleSubmitDraft = () => {
    setFieldValue("status", 0);
    handleSubmit();
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex gap-3" style={{ width: "100%" }}>
        <div className="d-flex flex-column" style={{ width: "70%" }}>
          <div className="mb-3">
            <Card className="w-100">
              <div className="d-flex flex-row justify-content-between align-items-center align-content-center">
                <DynamicSelect
                  label={"Brand"}
                  options={brands}
                  id="brand_id"
                  name="brand_name"
                  values={values}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  checkedValue={values.brand_id}
                />
                <DynamicSelect
                  label={"Model"}
                  name="model_name"
                  options={models}
                  id="model_id"
                  values={values}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  checkedValue={values.model_id}
                />
              </div>
            </Card>
          </div>
          <div>
            <Card className="w-100">
              <Accordion defaultActiveKey="0">
                <Accordion.Item
                  eventKey="0"
                  className="card-height header_padding_top"
                >
                  <Accordion.Header className="p-3 pb-1 ps-2 pt-4">
                    Upload Files
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="mb-3 d-flex gap-3">
                      <div className="mb-3">
                        <Form.Label>All</Form.Label>
                        <Form.Control
                          type="file"
                          multiple
                          onChange={(event) => handleFileChange(event, "All")}
                          className="form-control"
                        />
                      </div>
                      <div className="mb-3">
                        <Form.Label>Exterior</Form.Label>
                        <Form.Control
                          type="file"
                          multiple
                          onChange={(event) =>
                            handleFileChange(event, "exterior")
                          }
                        />
                      </div>
                      <div className="mb-3">
                        <Form.Label>Interior</Form.Label>
                        <Form.Control
                          type="file"
                          multiple
                          onChange={(event) =>
                            handleFileChange(event, "interior")
                          }
                        />
                      </div>
                      <div className="mb-3">
                        <Form.Label>Color</Form.Label>
                        <Form.Control
                          type="file"
                          multiple
                          onChange={(event) => handleFileChange(event, "color")}
                        />
                      </div>
                    </div>
                    {values.images.map((image, index) => (
                      <div key={index} className="mb-3 d-flex">
                        <img
                          src={image.blob}
                          alt={image.alt}
                          style={{ width: "50px", height: "50px" }}
                        />
                        <Form.Control
                          type="text"
                          placeholder="Caption"
                          value={image.caption}
                          onChange={(e) =>
                            setFieldValue(
                              `images.${index}.caption`,
                              e.target.value
                            )
                          }
                          className="ms-2"
                        />
                        <Form.Control
                          type="text"
                          placeholder="Alt Text"
                          value={image.alt}
                          onChange={(e) =>
                            setFieldValue(`images.${index}.alt`, e.target.value)
                          }
                          className="ms-2"
                        />
                        <Form.Select
                          value={image.position}
                          onChange={(e) =>
                            setFieldValue(
                              `images.${index}.position`,
                              e.target.value
                            )
                          }
                          className="ms-2"
                        >
                          <option value="All">All</option>
                          <option value="exterior">Exterior</option>
                          <option value="interior">Interior</option>
                          <option value="color">Color</option>
                        </Form.Select>
                        <Form.Control
                          type="number"
                          placeholder="Order"
                          value={image.order}
                          onChange={(e) =>
                            setFieldValue(
                              `images.${index}.order`,
                              e.target.value
                            )
                          }
                          className="ms-2"
                        />
                        <Button
                          variant="danger"
                          onClick={() => handleRemoveImage(index)}
                          className="ms-2"
                        >
                          Remove
                        </Button>
                      </div>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card>
          </div>
        </div>
        <div className="d-flex flex-column gap-3" style={{ width: "30%" }}>
          <div>
            <Card className="w-100">
              <Accordion defaultActiveKey="0">
                <Accordion.Item
                  eventKey="0"
                  className={`card-height header_padding_top ${
                    isScheduleChecked ? "expanded" : ""
                  }`}
                >
                  <Accordion.Header className="p-3 pb-1 ps-2 pt-4">
                    Publish
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row p-3">
                      <div className="row checkbox-container mb-2">
                        <Form.Check
                          type="checkbox"
                          id="publish"
                          label="Publish Immediately"
                          checked={values.status === 1}
                          onChange={() => setFieldValue("status", 1)}
                        />
                      </div>
                      <div className="row checkbox-container mb-2">
                        <Form.Check
                          type="checkbox"
                          id="schedule"
                          label="Schedule Publish"
                          checked={values.status === 2}
                          onChange={() => setFieldValue("status", 2)}
                        />
                      </div>
                      {values.status === 2 && (
                        <div className="w-100">
                          <ReactDatePicker
                            selected={values.publish_date}
                            onChange={(date) =>
                              setFieldValue("publish_date", date)
                            }
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            className="w-100"
                          />
                        </div>
                      )}
                    </div>
                    <div className="d-flex justify-content-between flex-wrap">
                      <Button
                        variant="btnBlue"
                        className="d-flex align-items-center justify-content-between"
                        type="submit"
                      >
                        Publish
                      </Button>
                      <Button
                        className="btn-secondary"
                        onClick={() => handleSubmitDraft()}
                      >
                        Save Draft
                      </Button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card>
          </div>
          <div>
            <Card className="w-100">
              <Accordion defaultActiveKey="0" className="">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="p-3 pb-2 ps-2 pb-0">
                    Home Gallery
                  </Accordion.Header>
                  <Accordion.Body className="pt-0 checkbox-body">
                    <div className="checkbox-container">
                      <Form.Check
                        type="checkbox"
                        id="home_gallery"
                        name="home_gallery"
                        label="Make Home Gallery"
                        checked={values.home_gallery}
                        onChange={(e) =>
                          setFieldValue("home_gallery", e.target.checked)
                        }
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card>
          </div>
        </div>
      </div>
    </form>
  );
}
