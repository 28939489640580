import { useEffect, useState } from "react";
import { getPostById, updatePostById } from "../PostManagement/apiHandler";
import { useFormik } from "formik";
import PostValidationSchema from "../PostManagement/validationSchema";
import { convertUSTtoIST, getISTDate } from "../../request";
import { notifySuccess } from "../../utility/common";
import Breadcrumbs from "../../Breadcrumbs";
import RegularGalleryForm from "./RegularGalleryForm";
import { useLocation, useNavigate } from "react-router-dom";
import { getAdminType, getToken, getUserId } from "../../utility/constants";
import useQuery from "../../components/useQuery";

export default function RegularGalleryEdit(){
    const navigate = useNavigate();
    const token = getToken();
    const query = useQuery();
    const location = useLocation();
  
    const user_id = getUserId();
    const id = query.get("id");
    const adminType = getAdminType();
    const [isDraft, setIsDraft] = useState(false);
  
    const [initialValues, setInitialValues] = useState({
      category_id: -1,
      author_id: "0",
      slug: "",
      post_title: "",
      post_content: "",
      short_description: "",
      featured_image: "",
      thumb_type: "featured_image",
      slider_images: [],
      status: "",
      linkedin_post_status: "0",
      home_page_image: "0",
      verify_duplicate: "0",
      display_category: "0",
      publish_date: "",
      photography_id: "0",
      brand: "-1",
      model: "-1",
      type: "",
      tags: [],
      slider: 0,
      seo_title: "",
      seo_description: "",
      meta_keywords: "",
      meta_description: "",
      cannonical: "",
      thumb_value: "",
      category_name: "",
      stories_images: [],
      delete_stories: [],
      post_rating: 0,
    });
    const [publishDate, setPublishDate] = useState("");


    const fetchPostData = async () => {
        try {
          const response = await getPostById(id, token);
          const processedResult = Object.fromEntries(
            Object.entries(response.data.result).map(([key, value]) => [
              key,
              value ?? "", // Replace null with an empty string
            ])
          );
    
          const mergedValues = {
            ...initialValues,
            ...processedResult,
            // status: processedResult.status == 0 ? 1 : processedResult.status,
            // display_category: processedResult.display_category.map((item) =>
            //   Number(item)
            // ),
          };
          setPublishDate(mergedValues.publish_date);
          setInitialValues(mergedValues);
        } catch (error) {
          console.error("Error fetching post data:", error);
        }

    }
    useEffect(()=>{
        fetchPostData()
    },[])


    const { handleSubmit, values, setFieldValue, touched, errors, isSubmitting } =
    useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      validationSchema: PostValidationSchema,
      onSubmit: (values) => {
        const payload = { ...values };
        if (isDraft) {
          if (payload.status == 1) {
            payload.publish_date = getISTDate();
          }
          payload.status = 0;
        }
        if (payload.status == 2) {
          payload.publish_date = convertUSTtoIST(
            new Date(payload.publish_date)
          );
        }
        // if (payload.status == 1) {
        //   payload.publish_date = publishDate;
        // }
        // Clean up unwanted values before sending to API
        delete payload.id;
        delete payload.created_date;
        delete payload.updated_date;
        delete payload?.category_slug;
        delete payload?.parent_category_slug;
        delete payload?.category_name;
        delete payload?.brand_name;
        delete payload?.model_name;
        console.log(payload);
        // Update the post by ID
        updatePostById(id, payload, token).then((res) => {
          if (res.status === 200) {
            notifySuccess("Post updated successfully");
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        });
      },
    });


    return (
        <div>
            <Breadcrumbs/>
            <div>
                <RegularGalleryForm
                values={values}
                setFieldValue={setFieldValue}
                touched={touched}
                errors={errors}
                handleSubmit={handleSubmit}
                type="edit"
                adminType={adminType}
                setIsDraft={setIsDraft}
                isSubmitting={isSubmitting}
                />
            </div>
        </div>
    )
}