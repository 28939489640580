import { toast } from 'react-toastify';

export const notifySuccess = (successMsg) => {
    toast.success(successMsg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastClassName: 'custom-toast', // Apply custom CSS class to toast messages
    });
  };

  export const notifyError = (errorMsg) => {
    toast.error(errorMsg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastClassName: 'custom-toast', // Apply custom CSS class to toast messages
    });
  };
