

import Layout from "../../../components/Layout";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import HeadingTwo from "../../../components/HeadingTwo";
import BackArrow from "../../../assets/icons/bootstrap-icons/arrow-left.svg";
import HondaLogo from "../../../assets/icons/bootstrap-icons/honda-logo-2000-full-640.webp";
import PlusLogo from "../../../assets/icons/bootstrap-icons/plus.svg";
import { ReactSVG } from "react-svg";


export default function MultipleCityPriceDetails({ children }) {
    return (
        <div className="row dashboard d-flex">

            <div className="p-0 col-9 align-self-start">
                <div className="card">
                    <div className="card-headers border-bottom d-flex p-3 ps-0 align-items-center justify-content-between">
                        <div className="ms-3">
                            <HeadingTwo
                                title="New Delhi (Top City)"
                                type="dashboard"
                                showHeader={true}
                                pointerOnHover={true}
                            />
                        </div>
                        <div className="">
                            <button className="green-filled-button">
                                Save
                            </button>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="p-3 col-12">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="pe-2 col-3">
                                            Version
                                        </th>
                                        <th className="px-2 col-3">
                                            Ex-showroom Price
                                        </th>
                                        <th className="px-2 col-3">
                                            RTO Price
                                        </th>
                                        <th className="px-2 col-3">
                                            Insurance Price
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="pe-2 col-3">
                                            Test Version Name
                                        </td>
                                        <td className="px-2 col-3">
                                            <input type="number" className="form-control border border-2 shadow-none" placeholder="" />
                                        </td>
                                        <td className="px-2 col-3">
                                            <input type="number" className="form-control border border-2 shadow-none" placeholder="" />
                                        </td>

                                        <td className="px-2 col-3">
                                            <input type="number" className="form-control border border-2 shadow-none" placeholder="" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                 <div className="card">
                    <div className="card-headers border-bottom d-flex p-3 ps-0 align-items-center justify-content-between">
                        <div className="ms-3">
                            <HeadingTwo
                                title="Mumbai (Top City)"
                                type="dashboard"
                                showHeader={true}
                                pointerOnHover={true}
                            />
                        </div>
                        <div className="">
                            <button className="green-filled-button">
                                Save
                            </button>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="p-3 col-12">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="pe-2 col-3">
                                            Version
                                        </th>
                                        <th className="px-2 col-3">
                                            Ex-showroom Price
                                        </th>
                                        <th className="px-2 col-3">
                                            RTO Price
                                        </th>
                                        <th className="px-2 col-3">
                                            Insurance Price
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="pe-2 col-3">
                                            Test Version Name
                                        </td>
                                        <td className="px-2 col-3">
                                            <input type="number" className="form-control border border-2 shadow-none" placeholder="" />
                                        </td>
                                        <td className="px-2 col-3">
                                            <input type="number" className="form-control border border-2 shadow-none" placeholder="" />
                                        </td>

                                        <td className="px-2 col-3">
                                            <input type="number" className="form-control border border-2 shadow-none" placeholder="" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-headers border-bottom d-flex p-3 ps-0 align-items-center justify-content-between">
                        <div className="ms-3">
                            <HeadingTwo
                                title="Jaipur (Top City)"
                                type="dashboard"
                                showHeader={true}
                                pointerOnHover={true}
                            />
                        </div>
                        <div className="">
                            <button className="green-filled-button">
                                Save
                            </button>
                        </div>
                    </div>

                    <div className="d-flex">
                        <div className="p-3 col-12">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="pe-2 col-3">
                                            Version
                                        </th>
                                        <th className="px-2 col-3">
                                            Ex-showroom Price
                                        </th>
                                        <th className="px-2 col-3">
                                            RTO Price
                                        </th>
                                        <th className="px-2 col-3">
                                            Insurance Price
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="pe-2 col-3">
                                            Test Version Name
                                        </td>
                                        <td className="px-2 col-3">
                                            <input type="number" className="form-control border border-2 shadow-none" placeholder="" />
                                        </td>
                                        <td className="px-2 col-3">
                                            <input type="number" className="form-control border border-2 shadow-none" placeholder="" />
                                        </td>

                                        <td className="px-2 col-3">
                                            <input type="number" className="form-control border border-2 shadow-none" placeholder="" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>




            <div className="col-3 align-self-start pe-0">
                <div className="card p-0">
                    <div className="card-headers border-bottom d-flex p-3 ps-0 align-items-center justify-content-between">
                        <div className="ms-3">
                            <HeadingTwo
                                title="Fixed Price"
                                type="dashboard"
                                showHeader={true}
                                pointerOnHover={true}
                            />
                        </div>
                        <div className="">
                            <button className="green-filled-button">
                                Save
                            </button>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 my-3 px-3">
                        <label for="exShowroomPrice" className="form-label">Ex-Showroom Price</label>
                        <input type="number" className="form-control border border-2 shadow-none" id="exShowroomPrice" />
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 my-3 px-3">
                        <label for="rtoPrice" className="form-label">RTO Price</label>
                        <input type="number" className="form-control border border-2 shadow-none" id="rtoPrice" />
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 my-3 px-3">
                        <label for="insurancePrice" className="form-label">Insurance Price</label>
                        <input type="number" className="form-control border border-2 shadow-none" id="insurancePrice" />
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 my-3 px-3">
                        <label for="luxuryCar" className="form-label">Luxury Car</label>
                        <input type="checkbox" className="border border-2 shadow-none ms-3" id="luxuryCar" />
                    </div>
                </div>
            </div>
        </div>
    )
}