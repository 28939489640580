import { useNavigate } from "react-router-dom";
import { getToken } from "../../../utility/constants";
import SidebarAPI from "./SidebarAPI";
import { notifyError, notifySuccess } from "../../../utility/common";
import SideBarForm from "./SideBarForm";

export default function AddSideBar() {
  const navigate = useNavigate();
  const token = getToken();

  const addSideBar = async (data) => {
    try {
      const response = await SidebarAPI.addSideBar(token, data);
      if (response.status === 200) {
        notifySuccess(response.data.message);
        setTimeout(() => {
          navigate("/sidebar");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      notifyError(error.response.data.message);
    }
  };

  const initialValues = {
    sidebar_name: "",
    router_location: "",
    remark: "",
  };

  return (
    <>
      <SideBarForm
        initialValues={initialValues}
        onSubmit={addSideBar}
        title={"Add Sidebar"}
      />
    </>
  );
}
