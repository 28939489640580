import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import apiHandler from "./apiHandler";
import { notifyError, notifySuccess } from "../../utility/common";
import TagForm from "./TagForm";

export default function EditTag() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    tag_name: "",
    slug: "",
    description: "",
    tag_type: "",
    verified: 0,
    status: 0,
    heading: "",
    breadcrumb: "",
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    robotsfollow: "Follow",
    robotsindex: "index",
  });

  useEffect(() => {
    const fetchTag = async () => {
      try {
        const response = await apiHandler.getTagById(id);
        if (response.status === 200) {
          setInitialValues(response.data);
        }
      } catch (error) {
        console.error(error);
        notifyError("Failed to load tag details.");
      }
    };

    fetchTag();
  }, [id]);

  const editTag = async (data) => {
    try {
      delete data.id;
      delete data.created_date;
      delete data.updated_date;
      const response = await apiHandler.updateTag(id, data);
      if (response.status === 200) {
        notifySuccess(response.data.message);
        setTimeout(() => {
          navigate("/tag");
        }, 1500);
      }
    } catch (error) {
      console.error(error);
      notifyError(error.response.data.message);
    }
  };

  // if (!initialValues) return <div>Loading...</div>;

  return (
    <TagForm
      initialValues={initialValues}
      onSubmit={editTag}
      title="Edit Tag"
    />
  );
}
