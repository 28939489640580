import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import HeadingTwo from "../../components/HeadingTwo";
import { ReactSVG } from "react-svg";
import BackIcon from "../../assets/icons/bootstrap-icons/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs";
import { Button, Card } from "react-bootstrap";
import apiHandler from "./apiHandler";
import { getToken } from "../../utility/constants";

const EditorSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  display_name: Yup.string().required("Display Name is required"),
  twitter: Yup.string(),
  email: Yup.string().email("Invalid email").required("Email is required"),
  alternate_email: Yup.string().email("Invalid email"),
  user_name: Yup.string().required("User Name is required"),
  password: Yup.string().required("Password is required"),
  description: Yup.string(),
  module_id: Yup.array()
    .of(Yup.number())
    .required("At least one module is required"),
});
const EditorSchemaEdit = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  display_name: Yup.string().required("Display Name is required"),
  twitter: Yup.string(),
  email: Yup.string().email("Invalid email").required("Email is required"),
  alternate_email: Yup.string().email("Invalid email"),

  description: Yup.string(),
  module_id: Yup.array()
    .of(Yup.number())
    .required("At least one module is required"),
});

const EditorForm = ({ initialValues, onSubmit, formType }) => {
  const navigate = useNavigate();
  const token = getToken();
  const [availableModules, setAvailableModules] = React.useState([]);

  const getModuleList = async () => {
    try {
      const response = await apiHandler.geteditorModules(token);
      setAvailableModules(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getModuleList();
  }, []);
  console.log(initialValues);
  const formFields = [
    { label: "First Name", name: "first_name" },
    { label: "Last Name", name: "last_name" },
    { label: "Display Name", name: "display_name" },
    { label: "Twitter Name", name: "twitter" },
    { label: "Email ID", name: "email" },
    {
      label: "Alternate Email ID",
      name: "alternate_email",
    },
    { label: "User Name", name: "user_name" },
    {
      label: "Password",
      name: "password",
      type: "password",
    },
  ];
  const filteredFields =
    formType === "Edit"
      ? formFields.filter(
          (field) => field.name !== "user_name" && field.name !== "password"
        )
      : formFields;
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={formType === "Edit" ? EditorSchemaEdit : EditorSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <Form>
          {console.log(values, errors)}
          <div className="row dashboard p-2">
            <div className="d-flex justify-content-between">
              <Breadcrumbs />
              <button
                className="d-flex back-button py-2 px-3 mb-4 btn btn-primary"
                onClick={() => navigate("/editors")}
                type="button"
              >
                <ReactSVG src={BackIcon} className="me-1" />
                Back
              </button>
            </div>
            <div className="d-flex gap-4">
              <div className="col-9">
                <div className="row">
                  <Card className="d-flex card p-3">
                    <div className="card-headers w-100 d-flex p-3 align-items-center justify-content-between">
                      <HeadingTwo
                        title="Primary Details"
                        type="dashboard"
                        showHeader={true}
                        pointerOnHover={true}
                      />
                    </div>
                    <div className="col-12 mt-3 d-flex flex-wrap">
                      {filteredFields.map(
                        ({ label, name, type = "text" }, index) => (
                          <div className="col-6" key={index}>
                            <div className="p-3">
                              <label htmlFor={name} className="form-label">
                                {label}
                              </label>
                              <Field
                                name={name}
                                type={type}
                                className={`form-control border border-2 shadow-none ${
                                  errors[name] && touched[name]
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {errors[name] && touched[name] && (
                                <div className="invalid-feedback">
                                  {errors[name]}
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      )}
                      <div className="col-12">
                        <div className="p-3">
                          <label htmlFor="description" className="form-label">
                            Description
                          </label>
                          <Field
                            as="textarea"
                            name="description"
                            className={`form-control border border-2 shadow-none ${
                              errors.description && touched.description
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {errors.description && touched.description && (
                            <div className="invalid-feedback">
                              {errors.description}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Card>
                  <Card className="d-flex card p-3 mt-3">
                    <div className="card-headers w-100  d-flex p-3 align-items-center justify-content-between">
                      <HeadingTwo
                        title="Set Editor Permission"
                        type="dashboard"
                        showHeader={true}
                        pointerOnHover={true}
                      />
                    </div>
                    <div className="col-12 mt-3 d-flex flex-wrap">
                      <div className="col-12">
                        {availableModules?.map(({ module_name, id }, index) => (
                          <div className="m-2" key={index}>
                            <label
                              htmlFor={module_name}
                              className="form-label me-2"
                            >
                              {module_name}
                            </label>
                            <Field
                              type="checkbox"
                              name="modules"
                              value={id}
                              checked={values.module_id?.includes(+id)}
                              className="p-1"
                              onChange={(e) => {
                                const checked = e.target.checked;
                                if (checked) {
                                  setFieldValue("module_id", [
                                    ...values.module_id,
                                    +id,
                                  ]);
                                } else {
                                  setFieldValue(
                                    "module_id",
                                    values.module_id.filter(
                                      (moduleId) => moduleId != +id
                                    )
                                  );
                                }
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
              <div className="col-3 d-flex gap-3 flex-column">
                <Card className="p-3">
                  <HeadingTwo
                    title="Display Image"
                    type="dashboard"
                    showHeader={true}
                    pointerOnHover={true}
                  />
                  <div className="d-flex mt-3 pt-3 gap-3 flex-column">
                    {values.profile_image && (
                      <img
                        src={
                          typeof values.profile_image === "string"
                            ? values.profile_image.startsWith("https://")
                              ? values.profile_image
                              : `https://static.autox.com/${values.profile_image}`
                            : values.profile_image instanceof File
                            ? URL.createObjectURL(values.profile_image)
                            : ""
                        }
                        className="w-75 h-75"
                        alt="Brand Logo"
                      />
                    )}
                    <div className="mb-1">
                      <input
                        type="file"
                        className="form-control shadow-none file-input m-0 me-3"
                        accept="image/png, image/jpg, image/jpeg"
                        id="fileInput"
                        onChange={(e) => {
                          const selectedFile = e.target.files[0];
                          const maxSizeKB = 200;
                          if (selectedFile) {
                            if (selectedFile.size > maxSizeKB * 1024) {
                              alert(
                                "Image size exceeds 200Kb. Please choose a smaller image."
                              );
                              e.target.value = "";
                              return;
                            }
                            setFieldValue("profile_image", selectedFile);
                          } else {
                            setFieldValue("profile_image", null);
                          }
                        }}
                        name="profile_image"
                      />
                      {errors.profile_image && touched.profile_image && (
                        <div className="invalid-feedback">
                          {errors.profile_image}
                        </div>
                      )}
                    </div>
                    <div className="logo-dimension">
                      Max 200Kb & Dimension: 100X100
                    </div>
                  </div>
                </Card>
                <Card className="p-3">
                  <HeadingTwo
                    title="Save"
                    type="dashboard"
                    showHeader={true}
                    pointerOnHover={true}
                  />
                  <Button variant="btnBlue" type="submit">
                    Save
                  </Button>
                </Card>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditorForm;
