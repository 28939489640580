import { useNavigate } from "react-router-dom";
import { getToken } from "../../utility/constants";
import { useFormik } from "formik";
import Breadcrumbs from "../../Breadcrumbs";
import CategoryForm from "./CategoryForm";
import apiHandler from "./apiHandler";
import { notifyError, notifySuccess } from "../../utility/common";
import categoryValidationSchema from "./validation";

export default function AddCategory() {
  const navigate = useNavigate();
  const token = getToken();
  const { handleSubmit, values, setFieldValue, touched, errors } = useFormik({
    initialValues: {
      parent: 0,
      category_name: "",
      slug: "",
      heading: "",
      breadcrumb: "",
      image: "",
      description: "",
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
      meta_canonical: "",
      status: 1,
    },
    enableReinitialize: true,
    validationSchema: categoryValidationSchema,
    onSubmit: (values) => {
      apiHandler
        .addNewCategory(values, token)
        .then((res) => {
          if (res && res.status === 200) {
            notifySuccess("Category added successfully");
            setTimeout(() => {
              navigate("/category");
            }, 1500);
          } else {
            notifyError("Unexpected response from the server");
          }
        })
        .catch((error) => {
          console.error("Error adding category:", error);
        });
    },
  });
  return (
    <>
      <Breadcrumbs />{" "}
      <div>
        <CategoryForm
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          handleSubmit={handleSubmit}
          type={"Submit"}
        />
      </div>
    </>
  );
}
