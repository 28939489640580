import WidgetTile from "../../../components/WidgetTile";

export default function CustomWidgetForm({
  customWidgets,
  setCustomWidgets,
  handleAddWidgets,
  handleUpdateWidgets,
  setRemovedWidgets,
  removedWidgets,
}) {
  console.log(customWidgets);
  const handleRemoveWidgets = (index) => {
    const updatedWidgets = [...customWidgets];
    const removed = updatedWidgets.splice(index, 1)[0];
    setCustomWidgets(updatedWidgets);
    setRemovedWidgets([...removedWidgets, removed.id]);
  };
  return (
    <div>
      {customWidgets?.map((widget, index) => (
        <WidgetTile
          key={index}
          index={index}
          widget={widget}
          handleUpdateWidgets={handleUpdateWidgets}
          handleRemoveWidgets={handleRemoveWidgets}
        />
      ))}
    </div>
  );
}
