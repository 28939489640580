import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HeadingTwo from "../../../components/HeadingTwo";
import BackArrow from "../../../assets/icons/bootstrap-icons/arrow-left.svg";
import HondaLogo from "../../../assets/icons/bootstrap-icons/honda-logo-2000-full-640.webp";
import PlusLogo from "../../../assets/icons/bootstrap-icons/plus.svg";
import { ReactSVG } from "react-svg";

import { createModel, getAllBrands } from "../../../request";
import Breadcrumbs from "../../../Breadcrumbs";
import Tooltip from "react-bootstrap/Tooltip";
import Overlay from "react-bootstrap/Overlay";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { notifyError, notifySuccess } from "../../../utility/common";
import { getCarModelByBrandId } from "../CarCityPrice/apiHandler";
import { getToken } from "../../../utility/constants";
import { Formik } from "formik";
import { filterValidationSchema } from "./schemaValidation";
import apiHandler from "./apiHandler";
import FormDate from "../../../components/FormDate";
import ReactDatePicker from "react-datepicker";
import BackIcon from "../../../assets/icons/Back_Arrow.png";
import { Image } from "react-bootstrap";
export default function AddCarVariant() {
  const navigate = useNavigate();
  const token = getToken();

  const [brands, setBrands] = useState([]);
  const [model, setModels] = useState([]);
  const [selectedFilterType, setSelectedFilterType] = useState("");
  const [data, setData] = useState([]);
  const [versionData, setVersionData] = useState({});
  const [versions, setVersions] = useState({});
  const [initialPayload, setInitialPayload] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const brandId = searchParams.get("brandId");
  const modelId = searchParams.get("modelId");
  const filterType = searchParams.get("type");
  // console.log("brandId, modelId, type", brandId, modelId, type);
  useEffect(() => {
    getAllBrands()
      .then((res) => {
        setBrands(res.data.result);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  }, []);

  const getModels = (brand_id, type) => {
    if (!brand_id) {
      setModels([]);
      return;
    }
    const payload = {
      brand_id,
    };
    if (type) {
      payload.type = type;
    }
    getCarModelByBrandId(token, payload)
      .then((response) => {
        setModels(response.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getModels(brandId, "all");
  }, [brandId]);

  const [modelDetails, setModelDetails] = useState({
    model_name: "",
    model_url: "",
    car_type: 0,
    keep_discontinued: 0,
    body_type: 0,
    popular_position: 0,
    model_date: "12",
    updated_launch_date: new Date(),
    model_image: "test",
    model_content: "",
    colors: "",
    model_preview: "",
    starting_price: 0,
    ending_price: 0,
    gallery_short_code: "",
    brand_id: 0,
    meta_title: "",
    meta_description: "",
    meta_keyword: "",
    canonical_url: "",
    breadcrumbs: "",
    robots_follow: "",
    robots_index: "",
    expert_review: "",
    electric_car: 0,
  });

  const target = useRef(null);

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    // Update the state with the new value for the specific field
    setModelDetails({
      ...modelDetails,
      [name]: value,
    });
  };

  const handleClick = async () => {
    try {
      const res = await createModel(modelDetails);
      console.log("myres", res);
      navigate("/car-models");
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckForAll = (e, field_name) => {
    const inputFields = Array.from(
      document.querySelectorAll(`input[name="${field_name}"]`)
    );
    const newValue = inputFields[0]?.value || "";

    if (e.target.checked) {
      inputFields.forEach((inputField) => (inputField.value = newValue));
      setInitialPayload((prevPayload) =>
        prevPayload.map((item) => ({ ...item, [field_name]: newValue }))
      );
    } else {
      inputFields.slice(1).forEach((inputField) => (inputField.value = ""));
      setInitialPayload((prevPayload) =>
        prevPayload.map((item) => ({ ...item, [field_name]: "" }))
      );
    }
  };

  const handleFieldChange = (value, versionId, fieldName) => {
    let newPayload = [...initialPayload];
    newPayload = newPayload.map((item) => {
      const selectedId = item.version_id ? item.version_id : item.id;
      if (selectedId === versionId) {
        item[fieldName] = value || "";
      }
      return item;
    });
    setInitialPayload(newPayload);
  };

  const handleSelectFieldChange = (value, versionId, fieldName) => {
    let newPayload = [...initialPayload];

    newPayload = newPayload.map((item) => {
      const selectedId = item.version_id ? item.version_id : item.id;

      if (selectedId === versionId) {
        item[fieldName] = parseInt(value);
      }
      return item;
    });
    setInitialPayload(newPayload);
  };
  const handleDateChange = (date, versionId, fieldName) => {
    let newPayload = [...initialPayload];

    newPayload = newPayload.map((item) => {
      const selectedId = item.version_id ? item.version_id : item.id;

      if (selectedId === versionId) {
        item[fieldName] = date.toISOString();
      }
      return item;
    });

    setInitialPayload(newPayload);
  };

  const handleAllFormSubmit = async () => {
    try {
      console.log("initialPayload", initialPayload);
      console.log("selectedFilterType", selectedFilterType);
      if (selectedFilterType == 3) {
        var payload = initialPayload.map((item) => {
          const { version_id, ...rest } = item;
          return {
            id: version_id,
            ...rest,
          };
        });
      } else {
        var payload = initialPayload;
      }
      const response = await apiHandler.updateAllVersion(token, {
        //yet to add this api
        id: selectedFilterType,
        versionData: payload,
      });
      console.log("response", response);
      notifySuccess("Records updated successfully.");
      // setTimeout(() => {
      //   navigate("/car-version");
      // }, 1500);
    } catch (error) {
      notifyError(error.message);
    }
  };
  const handleSubmitForm = async (values) => {
    setInitialPayload([]);
    setData([]);
    setVersionData({});
    setVersions({});
    const response = await apiHandler.getCarVersionFields(
      token,
      values.filter_type,
      values.model_id
    );

    setSelectedFilterType(values.filter_type);
    console.log("response", response.data);
    let results = response?.data?.result || [];
    let versionDataTemp = response.data.versionData;
    setData(results);
    setVersionData(response.data.versionData);
    setVersions(response.data.versions);

    let groupedPayload = [];
    response?.data?.versions?.forEach((version) => {
      let itemToPush = {};
      if (values.filter_type === 3) {
        itemToPush.id = version.id;
      } else {
        itemToPush.version_id = version.id;
      }
      results.forEach((row) => {
        if (row.child.length === 0) {
          itemToPush[row.field_name] =
            versionDataTemp?.[version.id]?.[row.field_name];
        } else {
          row.child.forEach((item) => {
            itemToPush[item.field_name] =
              versionDataTemp?.[version.id]?.[item.field_name] || null;
          });
        }
      });

      groupedPayload.push(itemToPush);
    });
    console.log("groupedPayload", groupedPayload);
    setInitialPayload(groupedPayload);
  };
  useEffect(() => {
    if (modelId && brandId && filterType) {
      const values = {};
      values.filter_type = filterType;
      values.brand_id = brandId;
      values.model_id = modelId;
      handleSubmitForm(values);
    }
  }, [modelId, brandId, filterType]);

  return (
    <div className="row dashboard">
      <div className="row">
        <div className="col-xl-11 col-sm-11">
          <Breadcrumbs />
        </div>
        <div className="col-xl-1 col-sm-1">
          <button
            className="d-flex back-button py-2 px-3  btn btn-primary"
            onClick={() => navigate("/car-version")}
          >
            <ReactSVG src={BackIcon} className="me-1" />
            Back
          </button>
        </div>
      </div>
      <div className="row py-3 m-0 p-0">
        <div className="col-lg-9 col-md-9 col-sm-12 p-0 mt-3">
          <div className="card card-rounded p-3 me-3">
            <Formik
              initialValues={{
                filter_type: filterType || "",
                brand_id: brandId || "",
                model_id: modelId || "",
              }}
              validationSchema={filterValidationSchema}
              enableReinitialize={true}
              onSubmit={handleSubmitForm}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="version-select pe-2 col-3">
                      <Form.Group className="mb-3" controlId="brandName">
                        <Form.Label>
                          Select Brand
                          {/* <sup className="text-danger">*</sup> */}
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          touched={touched}
                          errors={errors["brand_id"]}
                          name="brand_id"
                          value={values.brand_id}
                          onChange={(e) => {
                            const brand_id = e.target.value;
                            setFieldValue("brand_id", brand_id);
                            setFieldValue("model_id", "");
                            setFieldValue("filter_type", "");
                            getModels(brand_id, "all");
                          }}
                          onBlur={handleBlur}
                          isInvalid={touched.brand_id && errors.brand_id}
                        >
                          <option selected disabled value="">
                            Select Brand
                          </option>
                          {brands.map((brand, i) => {
                            return (
                              <option value={brand.id} key={i}>
                                {brand.brand_name}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {touched["brand_id"] && errors["brand_id"]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="version-select px-2 col-3">
                      <Form.Group className="mb-3" controlId="modelName">
                        <Form.Label>
                          Select Model
                          {/* <sup className="text-danger">*</sup> */}
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          touched={touched}
                          errors={errors["model_id"]}
                          name="model_id"
                          value={values.model_id}
                          onChange={(e) => {
                            const model_id = e.target.value;
                            setFieldValue("model_id", model_id);
                          }}
                          onBlur={handleBlur}
                          isInvalid={touched.model_id && errors.model_id}
                        >
                          <option selected disabled value={""}>
                            Select Model
                          </option>
                          {model.map((mod, i) => {
                            return (
                              <option value={mod.id} key={i}>
                                {mod.model_name}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {touched["model_id"] && errors["model_id"]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="version-select ps-2 col-4">
                      <Form.Group className="mb-3" controlId="filterType">
                        <Form.Label>
                          Select Filter Type
                          {/* <sup className="text-danger">*</sup> */}
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          touched={touched}
                          errors={errors["filter_type"]}
                          name="filter_type"
                          value={values.filter_type}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.filter_type && errors.filter_type}
                        >
                          <option selected disabled value={""}>
                            Select Filter Type
                          </option>
                          <option value={1}>Specification</option>
                          <option value={2}>Features</option>
                          <option value={3}>Others</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {touched["filter_type"] && errors["filter_type"]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="version-select ps-2 col-2">
                      <button
                        className="version-go-button m-0 p-0 mt-4"
                        type="submit"
                      >
                        Go
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {/* <div className=""> */}
        <Accordion
          defaultActiveKey="0"
          className="m-0 p-0 card card-rounded col-lg-3 col-md-3 col-sm-12 mt-3 bg-transparent"
        >
          <Accordion.Item eventKey="0">
            <Accordion.Header className="p-2 ps-2">
              Submit version
            </Accordion.Header>
            <Accordion.Body className="pt-1">
              <button className="btn btn-success" onClick={handleAllFormSubmit}>
                Save
              </button>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {/* </div> */}
      </div>

      {data.length && (
        <div className=" m-0 p-0 mt-3">
          <div className="row py-3">
            {data.map((row, index) => {
              return (
                <div
                  key={index}
                  className="col-xxl-12 col-lg-12 col-md-12 col-sm-12"
                >
                  <Accordion defaultActiveKey="0" className="mb-4">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="p-3  ps-1">
                        {row.display_name}
                      </Accordion.Header>
                      <Accordion.Body className="pt-2">
                        <div className="row">
                          {row.child.length !== 0 &&
                            row.child.map((item, i) => {
                              return (
                                <div
                                  key={i}
                                  className="col-xxl-3 col-lg-6 col-md-12 col-sm-12 "
                                >
                                  <Accordion
                                    defaultActiveKey="0"
                                    className="mb-4 card-version"
                                  >
                                    <Accordion.Item eventKey="0">
                                      <Accordion.Header className="p-3 pb-1 ps-2">
                                        {item.display_name}
                                      </Accordion.Header>
                                      <Accordion.Body className="pt-2">
                                        <Form className="">
                                          <div className="d-flex justify-content-end">
                                            Check for all
                                            <Form.Check
                                              className="ms-1 mb-3"
                                              onClick={(e) =>
                                                handleCheckForAll(
                                                  e,
                                                  item.field_name
                                                )
                                              }
                                            />
                                          </div>
                                          {versions.map((fields, ind) => {
                                            return (
                                              <div key={ind}>
                                                <div className=" mb-2">
                                                  <Form.Label>
                                                    {fields.version_name}
                                                    {/* <sup className="text-danger">*</sup> */}
                                                  </Form.Label>
                                                  <Form.Group
                                                    className="mb-4"
                                                    controlId="brandNameInput"
                                                  >
                                                    <Form.Control
                                                      name={item.field_name}
                                                      placeholder="-"
                                                      defaultValue={
                                                        versionData?.[
                                                          fields.id
                                                        ]?.[item.field_name] ==
                                                        "NULL"
                                                          ? ""
                                                          : versionData?.[
                                                              fields.id
                                                            ]?.[item.field_name]
                                                      }
                                                      onChange={(e) =>
                                                        handleFieldChange(
                                                          e.target.value,
                                                          fields.id,
                                                          item.field_name,
                                                          "text"
                                                        )
                                                      }
                                                    />
                                                  </Form.Group>
                                                </div>
                                              </div>
                                            );
                                          })}{" "}
                                        </Form>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                </div>
                              );
                            })}

                          {row.child.length === 0 && (
                            <div className="w-50">
                              <Form className="">
                                <div className="d-flex justify-content-end">
                                  Check for all {`${row.display_name}`}
                                  <Form.Check
                                    className="ms-1 mb-3"
                                    onClick={(e) =>
                                      handleCheckForAll(e, row.field_name)
                                    }
                                  />
                                </div>
                                {versions.map((fields) => {
                                  console.log(initialPayload, "initial");
                                  console.log(fields, "fields");
                                  console.log(row, "row");

                                  return (
                                    <div key={fields.version_id}>
                                      <div className=" mb-2">
                                        <Form.Label>
                                          {fields.version_name}

                                          {/* <sup className="text-danger">*</sup> */}
                                        </Form.Label>
                                        {console.log(
                                          initialPayload.find(
                                            (payload) =>
                                              payload.version_id == fields.id
                                          )?.[row.field_name],
                                          "salam"
                                        )}
                                        {row.display_name == "Car Status" ||
                                        row.display_name == "Show On Launch" ? (
                                          <Form.Select
                                            name={row.field_name}
                                            value={
                                              initialPayload.find(
                                                (payload) =>
                                                  payload.version_id ==
                                                  fields.id
                                              )?.[row.field_name]
                                            }
                                            onChange={(e) =>
                                              handleSelectFieldChange(
                                                e.target.value,
                                                fields.id,
                                                row.field_name
                                              )
                                            }
                                          >
                                            <option value={0}>
                                              {row.display_name == "Car Status"
                                                ? "Live"
                                                : "True"}
                                            </option>
                                            <option value={1}>
                                              {row.display_name == "Car Status"
                                                ? "Not Live"
                                                : "False"}
                                            </option>
                                            <option value={2}>
                                              {row.display_name == "Car Status"
                                                ? "Discontinued"
                                                : "None"}
                                            </option>
                                          </Form.Select>
                                        ) : row.display_name ==
                                          "Launch Date" ? (
                                          <>
                                            {console.log(row.field_name)}
                                            <ReactDatePicker
                                              name={row.field_name}
                                              selected={(() => {
                                                const value =
                                                  initialPayload.find(
                                                    (payload) =>
                                                      payload.version_id ==
                                                      fields.id
                                                  )?.[row.field_name];

                                                return value &&
                                                  !isNaN(Date.parse(value))
                                                  ? new Date(value)
                                                  : null;
                                              })()}
                                              onChange={(date) =>
                                                handleDateChange(
                                                  date,
                                                  fields.id,
                                                  row.field_name
                                                )
                                              }
                                            />
                                          </>
                                        ) : (
                                          <Form.Control
                                            name={row.field_name}
                                            placeholder="-"
                                            defaultValue={
                                              versionData?.[fields.id]?.[
                                                row.field_name
                                              ] == "NULL"
                                                ? ""
                                                : versionData?.[fields.id]?.[
                                                    row.field_name
                                                  ]
                                            }
                                            onChange={(e) =>
                                              handleFieldChange(
                                                e.target.value,
                                                fields.id,
                                                row.field_name
                                              )
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </Form>
                            </div>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
