// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eye-bg {
  background-color: #e4f2e0;
  width: 35px;
  height: 33px;
  border-radius: 7px;
  padding-top: 5px;
  padding-left: 8px;
}

.popular-title {
  width: 60%;
  border-radius: 7px;
}

.action-div {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.li-container {
  margin-bottom: 0px !important;
}

.li-container:nth-child(odd) {
  background: #f6f6f6;
}
.li-container:nth-child(even) {
  background: #fff;
}

.top-container {
  border: 1px solid #f6f6f6;
}
.cursor-move {
  cursor: move;
}
`, "",{"version":3,"sources":["webpack://./src/styles/PopularCars.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,YAAY;AACd","sourcesContent":[".eye-bg {\r\n  background-color: #e4f2e0;\r\n  width: 35px;\r\n  height: 33px;\r\n  border-radius: 7px;\r\n  padding-top: 5px;\r\n  padding-left: 8px;\r\n}\r\n\r\n.popular-title {\r\n  width: 60%;\r\n  border-radius: 7px;\r\n}\r\n\r\n.action-div {\r\n  display: flex;\r\n  align-items: center;\r\n  column-gap: 10px;\r\n}\r\n\r\n.li-container {\r\n  margin-bottom: 0px !important;\r\n}\r\n\r\n.li-container:nth-child(odd) {\r\n  background: #f6f6f6;\r\n}\r\n.li-container:nth-child(even) {\r\n  background: #fff;\r\n}\r\n\r\n.top-container {\r\n  border: 1px solid #f6f6f6;\r\n}\r\n.cursor-move {\r\n  cursor: move;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
