import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs";
import { ReactSVG } from "react-svg";
import BackIcon from "../../assets/icons/bootstrap-icons/Back_Arrow.svg";
import addMagazineSchema from "./magazineSchema";
import { Button, Card, Form } from "react-bootstrap";
import HeadingTwo from "../../components/HeadingTwo";
import { renderDateNumerical } from "../../utility/constants";
import FormControl from "../../components/FormControl";
import FormFieldErrorMsg from "../../components/FormFieldErrorMsg";
import { notifyError } from "../../utility/common";
import JoditEditor from "jodit-react";
import { useRef } from "react";
export default function MagazineForm({ initialValues, onSubmit, title }) {
  const navigate = useNavigate();
  const { handleSubmit, errors, values, touched, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: addMagazineSchema,
    onSubmit: async (values, action) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.error(error);
      }
    },
  });
  console.log(values, errors);
  const editor = useRef(null);

  return (
    <>
      <div className="d-flex justify-content-between">
        <Breadcrumbs />
        <button
          className="d-flex back-button py-2 px-3 mb-4 btn btn-primary"
          onClick={() => navigate("/subscription")}
        >
          <ReactSVG src={BackIcon} className="me-1" />
          Back
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between gap-3">
          <div className="w-100 d-flex flex-column gap-3">
            <Card className="p-3">
              <HeadingTwo
                title="Primary Details"
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />
              <div className="row">
                <div className="col-md-6">
                  <FormControl
                    controlId={"magazineName"}
                    label={"Magazine Name"}
                    placeholder={"Enter Magazine Name"}
                    touched={touched}
                    errors={errors}
                    name={"title"}
                    value={values.title}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                    isRequired={true}
                  />
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="StatusControlSelect">
                    <Form.Label>
                      Display Date <sup className="text-danger">*</sup> (Issue
                      Date)
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="start_date"
                      pattern="\d{4}-\d{2}-\d{2}"
                      placeholder="YYYY-MM-DD"
                      value={renderDateNumerical(values.display_date)}
                      onChange={(e) => {
                        const normalizedDate = e.target.value.replace(
                          /\//g,
                          "-"
                        );
                        setFieldValue("display_date", normalizedDate);
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <FormControl
                    controlId={"magazineUrl"}
                    label={"Magazine Url"}
                    placeholder={"Enter Magazine Url"}
                    touched={touched}
                    errors={errors}
                    name={"url"}
                    value={values.url}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                    isRequired={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <FormControl
                    controlId={"post_id"}
                    label={"Post IDs"}
                    placeholder={"Enter Post IDs"}
                    touched={touched}
                    errors={errors}
                    name={"post_id"}
                    value={values.post_id}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                    isRequired={true}
                    as="textarea"
                    rows={4}
                  />
                </div>
              </div>
            </Card>
            <Card className="p-3">
              <HeadingTwo
                title="Magazine Content"
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />
              <JoditEditor
                ref={editor}
                // config={{ height: 300 }}
                value={values.content}
                onBlur={(newContent) => setFieldValue("content", newContent)}
              />
            </Card>
          </div>
          <div className="w-50 d-flex gap-3 flex-column">
            <Card className="p-3">
              <HeadingTwo
                title="Save"
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />
              <Button
                variant="btnBlue"
                // className="d-flex align-items-center justify-content-between w-100"
                type="submit"
              >
                Save
              </Button>
            </Card>
            <Card className="p-3">
              <HeadingTwo
                title="Display Image"
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />
              <div className="d-flex mt-3 pt-3 gap-3 flex-column">
                {values.display_image && (
                  <img
                    src={
                      typeof values.display_image === "string"
                        ? values.display_image.startsWith(
                            "https://images.autoxmag"
                          )
                          ? values.display_image // Use the whole URL as is if it starts with the correct prefix
                          : `https://images.autoxmag.com/uploads/magazine-thumbs/${values.display_image}` // Prepend the URL if it doesn't start with the prefix
                        : values.display_image instanceof File
                        ? URL.createObjectURL(values.display_image) // Create a URL if it's a File object
                        : ""
                    }
                    className="w-75 h-75"
                    alt="Brand Logo"
                  />
                )}
                <Form.Group controlId="formFile" className="mb-1">
                  <div className="file-container">
                    <input
                      type="file"
                      className="form-control shadow-none file-input m-0 me-3"
                      accept="image/png, image/jpg, image/jpeg"
                      id="fileInput"
                      onChange={(e) => {
                        const selectedFile = e.target.files[0];
                        const maxSizeKB = 200;
                        if (selectedFile) {
                          if (selectedFile.size > maxSizeKB * 1024) {
                            notifyError(
                              "Image size exceeds 200Kb. Please choose a smaller image."
                            );
                            e.target.value = "";
                            return;
                          }
                          setFieldValue("display_image", selectedFile);
                        } else {
                          setFieldValue("display_image", null);
                        }
                      }}
                      name="display_image"
                    />
                    <FormFieldErrorMsg
                      touched={touched}
                      errors={errors}
                      name={"display_image"}
                    />
                  </div>
                  <Form.Label className="logo-dimension">
                    Max 200Kb & Dimension: 100X100
                  </Form.Label>
                </Form.Group>
              </div>
            </Card>
          </div>
        </div>
      </form>
    </>
  );
}
