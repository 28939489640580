import * as Yup from "yup";
const URL = /^(ftp|http|https):\/\/[^\s/$.?#].[^\s]*$/;

export const validationSchemaObject = Yup.object({
  model_name: Yup.string().required("Model Name is Required"),
  model_url: Yup.string().optional(),
  model_image: Yup.mixed().required("Model Image is Required"),
  bike_type: Yup.number()
    .min(0, "Status is Required")
    .required("Status is Required"),

  body_type: Yup.number()
    .min(0, "Vehicle Type is Required")
    .required("Vehicle Type is Required"),
  popular_position: Yup.number()
    .typeError("Popular Position must be a number")
    .required("Popular Position is Required"),
  colors: Yup.string().required("Colors is Required"),
  brand_id: Yup.number()
    .min(1, "Please Select Brand Id")
    .required("Brand ID is Required"),
  meta_title: Yup.string(),
  meta_description: Yup.string(),
  meta_keyword: Yup.string(),
  canonical_url: Yup.string(),
  robots_follow: Yup.string(),
  robots_index: Yup.string(),
  electric_bike: Yup.boolean(),
  faq: Yup.array().optional(),
  custom_faq: Yup.array().optional(),
});
