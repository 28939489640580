import axios from "axios";
import { getConfig, BASE_URL_AUTOX } from "../../../request";
export const GET_CAR_DRIVING_SCHOOL = "car/driving-school/filtered/list";
export const GET_CAR_DRIVING_SCHOOL_BY_ID = "car/driving-school/";
export const ADD_CAR_DRIVING_SCHOOL = "car/driving-school/add";
export const UPDATE_CAR_DRIVING_SCHOOL = "car/driving-school/update/";
export const DELETE_CAR_DRIVING_SCHOOL = "car/driving-school/delete/";
export const GET_ALL_CAR_CITIES = "car/city/all/list";


/**
 * Get List of Car Driving School
 * getCarDrivingSchools is used to get all the data of car driving schools
 * @param token
 * @param payload
 */
export function getCarDrivingSchools(token, payload) {
    return axios.post(`${BASE_URL_AUTOX}${GET_CAR_DRIVING_SCHOOL}`, payload, getConfig(token)
    )
  }


/**
 * Get Car Driving School By Id
 * getCarDrivingSchoolById is used to get data of car driving school by id
 * @param token
 * @param id
 */
export function getCarDrivingSchoolById(token, id) {
    return axios.get(`${BASE_URL_AUTOX}${GET_CAR_DRIVING_SCHOOL_BY_ID}${id}`, getConfig(token)
    )
  }


/**
 * Add New Car Driving School
 * addCarDrivingSchool is used to create new car driving school data
 * @param token
 * @param payload
 */
export function addCarDrivingSchool(token, payload) {
    return axios.post(`${BASE_URL_AUTOX}${ADD_CAR_DRIVING_SCHOOL}`, payload, getConfig(token))
  }


/**
 * Update Car Driving School By Id
 * updateDrivingSchoolById is used to update data of car driving school by id
 * @param token
 * @param id
 * @param payload
 */
export function updateDrivingSchoolById( token, id, payload) {
    console.log("test put", payload);
    return axios.put(`${BASE_URL_AUTOX}${UPDATE_CAR_DRIVING_SCHOOL}${id}`, payload, getConfig(token))
  }


/**
 * Delete Car Driving School By Id
 * deleteCarDrivingSchoolById is used to delete single record of car driving school by id
 * @param token
 * @param id
 */
 export function deleteCarDrivingSchoolById(token, id) {
    return axios.delete(`${BASE_URL_AUTOX}${DELETE_CAR_DRIVING_SCHOOL}${id}`, getConfig(token))
  }


/**
 * Get List of Cities
 * getAllCarCities is used to get subset data of all cities
 * @param token
 * @param payload
 */
export function getAllCarCities(token, payload) {
    return axios.post(`${BASE_URL_AUTOX}${GET_ALL_CAR_CITIES}`, payload,
        getConfig(token)
    )
}

export default {
    getCarDrivingSchools,
    getCarDrivingSchoolById,
    addCarDrivingSchool,
    updateDrivingSchoolById,
    deleteCarDrivingSchoolById,
    getAllCarCities
}