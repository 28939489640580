import clsx from 'clsx';
import React from 'react'

export default function SubmitButton({title,className, onClick}) {
  return (
    <button
      className={clsx(" submit-btn auth-button " + className)}
      type="submit"
      onClick={onClick}
    >
      {title}
    </button>
  );
}
