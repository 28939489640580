import { useNavigate } from "react-router-dom";
import { getToken } from "../../../utility/constants";
import { connect, useFormik } from "formik";
import { bikeBrandValidation } from "./schemaValidation";
import { notifyError, notifySuccess } from "../../../utility/common";
import { addBikeBrand } from "./apiHandler";
import { ToastContainer } from "react-toastify";
import { ReactSVG } from "react-svg";
import Breadcrumbs from "../../../Breadcrumbs";
import BackIcon from "../../../assets/icons/bootstrap-icons/Back_Arrow.svg";
import BikeBrandForm from "./BikeBrandForm";

export default function AddBikeBrand() {
  const token = getToken();
  const navigate = useNavigate();

  const { handleSubmit, values, errors, setFieldValue, touched } = useFormik({
    initialValues: {
      brand_name: "",
      brand_url: "",
      vehicle_supports: -1,
      brand_image: "",
      brand_content: "",
      brand_ordering: 1,
      canonical_url: "",
      meta_description: "",
      meta_keyword: "",
      meta_title: "",
      robots_follow: -1,
      robots_index: -1,
      short_description: "",
      status: -1,
    },
    validationSchema: bikeBrandValidation,
    onSubmit: async (values, action) => {
      const payload = values;
      const formData = new FormData();
      try {
        for (let e of Object.entries(payload)) {
          formData.append(e[0], e[1]);
        }

        const response = await addBikeBrand(token, formData);
        console.log(response, "response");
        if (response.status === 200) {
          notifySuccess("Added Successfully !!");
          action.resetForm();
          console.log(response.data, "response");
          navigate(`/bike-brand/edit-bike-brand/${response.data.data}`);
        } else {
          const responseData = response.response
            ? response.response.data
            : response.data;
          const errorMessage = responseData.message || "An error occurred";
          notifyError(errorMessage);
        }
      } catch (error) {
        console.log(error);
        const errorMessage = error.response
          ? error.response.data.message
          : "An error occurred";
        notifyError(errorMessage);
      }
    },
  });

  return (
    <div className="row dashboard">
      <ToastContainer />
      <div className="row">
        <div className="col-xl-11 col-sm-11">
          <Breadcrumbs />
        </div>
        <div className="col-xl-1 col-sm-1">
          <button
            className="d-flex back-button py-2 px-3 mb-4 btn btn-primary"
            onClick={() => navigate("/bike-brand")}
          >
            {" "}
            <ReactSVG src={BackIcon} className="me-1" />
            Back
          </button>
        </div>
      </div>
      <BikeBrandForm
        values={values}
        errors={errors}
        touched={touched}
        handleSubmit={handleSubmit}
        setFieldValue={setFieldValue}
        type={"Save"}
      />
    </div>
  );
}
