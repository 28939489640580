import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { getToken } from "../../../utility/constants";
import FormSelect from "../../../components/FormSelect";
import FormControl from "../../../components/FormControl";
import "react-toastify/dist/ReactToastify.css";
import { notifyError, notifySuccess } from "../../../utility/common";
import apiHandler from "./apiHandler";
import { getAllStates, getCityBystate } from "../CarServiceCenter/apiHandler";

export default function MarutiCarDrivingSchoolForm({
  type,
  touched,
  errors,
  values,
  setFieldValue,
  handleSubmit,
}) {
  const token = getToken();
  const navigate = useNavigate();
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  useEffect(() => {
    getStateList();
  }, []);

  useEffect(() => {
    if (values["state_id"] > 0) {
      getCityList();
    }
  }, [values["state_id"]]);

  const getStateList = async () => {
    const response = await getAllStates(token);
    setStates(response.data.result);
  };
  const getCityList = async () => {
    const response = await getCityBystate(token, values["state_id"]);
    console.log(response);
    setCities(response.data.data.result);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="row col-12 mt-1 p-3">
        <div className="col-6 px-0 pe-3 my-3">
          <div className="">
            <FormSelect
              controlId={"StateControlSelect"}
              label={"Select State"}
              name={"state_id"}
              defaultName={"Select State"}
              setFieldValue={setFieldValue}
              items={states}
              itemName={"state_name"}
              touched={touched}
              errors={errors}
              value={values["state_id"]}
              isRequired={true}
            />
          </div>
        </div>
        <div className="col-6 px-0 ps-3 my-3">
          <div className="">
            <FormSelect
              controlId={"CityControlSelect"}
              label={`Select City`}
              name={"city_id"}
              defaultName={"Select City"}
              setFieldValue={setFieldValue}
              items={cities}
              itemName={"city_name"}
              touched={touched}
              errors={errors}
              value={values["city_id"]}
              isRequired={true}
            />
          </div>
        </div>
        <div className="col-6 px-0 pe-3 my-3">
          <div className="">
            <FormControl
              controlId={"schoolNameInput"}
              label={"School Name"}
              placeholder={"School Name"}
              touched={touched}
              errors={errors}
              name={"school_name"}
              value={values["school_name"]}
              setFieldValue={setFieldValue}
              isRequired={true}
            />
          </div>
        </div>

        <div className="col-6 px-0 ps-3 my-3">
          <div className="">
            <FormControl
              controlId={"schoolUrlInput"}
              label={"Url"}
              placeholder={"Url"}
              touched={touched}
              errors={errors}
              name={"school_url"}
              value={values["school_url"]}
              setFieldValue={setFieldValue}
              isRequired={true}
            />
          </div>
        </div>
        <div className="col-6 px-0 pe-3 my-3">
          <div className="">
            <FormControl
              controlId={"schoolAddressInput"}
              label={"School Address"}
              placeholder={"School Address"}
              touched={touched}
              errors={errors}
              name={"maruti_suzuki_driving_school_address"}
              value={values["maruti_suzuki_driving_school_address"]}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>

        <div className="col-6 px-0 ps-3 my-3">
          <div className="">
            <FormControl
              controlId={"schoolAreaInput"}
              label={"Area"}
              placeholder={"School Area"}
              touched={touched}
              errors={errors}
              name={"maruti_suzuki_driving_school_area"}
              value={values["maruti_suzuki_driving_school_area"]}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>

        {/* <div className="col-6 px-0 ps-3 my-3">
          <div className="">
            <FormControl
              controlId={"schoolMapInput"}
              label={"Map (longitude and latitude)"}
              placeholder={"School Map"}
              touched={touched}
              errors={errors}
              name={"maruti_suzuki_driving_school_map"}
              value={values["maruti_suzuki_driving_school_map"]}
              setFieldValue={setFieldValue}
            />
          </div>
        </div> */}

        <div className="col-6 px-0 pe-3 my-3">
          <div className="">
            <FormControl
              controlId={"schoolLatitudeInput"}
              label={"Latitude"}
              placeholder={"Latitude"}
              touched={touched}
              errors={errors}
              name={"latitude"}
              value={values["latitude"]}
              setFieldValue={setFieldValue}
              isRequired={true}
            />
          </div>
        </div>
        <div className="col-6 px-0 ps-3 my-3">
          <div className="">
            <FormControl
              controlId={"schoolLongitudeInput"}
              label={"Longitude"}
              placeholder={"Longitude"}
              touched={touched}
              errors={errors}
              name={"longitude"}
              value={values["longitude"]}
              setFieldValue={setFieldValue}
              isRequired={true}
            />
          </div>
        </div>

        <div className="col-6 px-0 pe-3 my-3">
          <div className="">
            <FormControl
              controlId={"schoolPhoneNumberInput"}
              label={"Phone No."}
              placeholder={"Phone No."}
              touched={touched}
              errors={errors}
              name={"phone_number"}
              value={values["phone_number"]}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>
        <div className="col-6 px-0 ps-3 my-3">
          <div className="">
            <FormControl
              controlId={"schoolEmailInput"}
              label={"Email Address"}
              placeholder={"Email Address"}
              touched={touched}
              errors={errors}
              name={"email_id"}
              value={values["email_id"]}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>
      </div>
      <Button
        type="submit"
        variant="btnBlue"
        className="d-flex align-items-center justify-content-between my-3"
      >
        {`${type} Maruti Driving School`}
      </Button>
    </Form>
  );
}
