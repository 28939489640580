import clsx from "clsx";
import React from "react";

import Form from "react-bootstrap/Form";
import FormFieldErrorMsg from "./FormFieldErrorMsg";

export default function FormControlNumber({
  controlId,
  label,
  placeholder,
  touched,
  errors,
  name,
  value,
  setFieldValue,
  as,
  rows,
  className,
  isRequired,
  onChange,
  disabled = false,
}) {
  return (
    <div>
      <Form.Group className="" controlId={controlId}>
        <Form.Label className="">
          {label}{" "}
          {isRequired ? <sup className="required-superscript">*</sup> : null}
        </Form.Label>
        <Form.Control
          type="number"
          placeholder={placeholder}
          // onChange={handleOnChange}
          name={name}
          value={value}
          className={clsx(
            {
              "is-invalid": touched[name] && errors[name],
            },
            {
              "is-valid": touched[name] && !errors[name],
            },
            className
          )}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
            if (onChange) {
              onChange(e.target.value);
            }
          }}
          as={as}
          rows={rows}
          disabled={disabled}
        />
        <FormFieldErrorMsg touched={touched} errors={errors} name={name} />
      </Form.Group>
    </div>
  );
}
