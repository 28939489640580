import React from "react";
import { Field, getIn } from "formik";
export const ErrorMessage = ({ name }) => (
    <Field
        className="invalid-feedback"
        name={name}
        render={({ form }) => {
            const error = getIn(form.errors, name);
            const touch = getIn(form.touched, name);
            return touch && error ? <div className="invalid-feedback">
                {error}
            </div> : null;
        }}
    />
);
