import { useEffect, useState, useCallback } from "react";
import { getToken } from "../../../utility/constants";
import apiHandler from "./apiHandler";
import EditViewDeleteReviewPassword from "../../../components/EditViewDeleteReviewPassword";
import Datatable from "../../../components/CarComparisonTable";
import { ToastContainer } from "react-toastify";
import DeleteConfirmationModalComponent from "../../../components/DeleteConfirmationModalComponent";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import { notifyError, notifySuccess } from "../../../utility/common";

export default function CarComparisonList({ reloadData }) {
  const [bodyTypes, setBodyTypes] = useState([]);
  /* eslint-disable */
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [reload, setReload] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  /* eslint-enable */
  const token = getToken();

  const getCarBodyTypes = useCallback(() => {
    apiHandler
      .getCarComparisonBodyTypes(token)
      .then((result) => {
        console.log("car comparison body types", result);
        setBodyTypes(result.data.result);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  }, [token]);

  const onViewInner = async (id) => {
    apiHandler
      .getCarComparisonViewLink(token, id)
      .then((result) => {
        console.log("link", result);
        window.open(result.data.data, "_blank");
      })
      .catch((error) => {
        notifyError(error.message);
      });
  };

  const onDeleteInner = async (id) => {
    apiHandler
      .deleteCarComparisonById(token, deleteId)
      .then((result) => {
        setShow(false);
        setReload(true);
        notifySuccess("Car Comparison deleted successfully");
        setDeleteConfirm(false);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  };

  const columns = [
    {
      name: "Car 1",
      cell: (row) => row.brand_name1 + " " + row.model_name1,
      sortable: false,
      width: "30%",
    },
    {
      name: "Car 2",
      cell: (row) => row.brand_name2 + " " + row.model_name2,
      sortable: false,
      width: "30%",
    },
    {
      name: "Ordering",
      sortable: false,
      cell: (row) => row.comparison_ordering,
      width: "20%",
    },
    {
      title: "Action",
      sortable: false,
      width: "20%",
      cell: (row) => (
        <EditViewDeleteReviewPassword
          id={row.id}
          onView={onViewInner}
          onDelete={(id) => {
            setDeleteId(id);
            setShow(true);
          }}
        />
      ),
    },
  ];
  useEffect(() => {
    getCarBodyTypes();
    setReload(true);
  }, [reloadData, getCarBodyTypes]);

  const subHeaderComponent = useCallback((type_name, total_records) => {
    return (
      <>
        <div className="col-md-6 p-0 m-0">
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center p-0 m-0">
              <div className="table-heading fw-bold d-flex align-items-center m-0 p-0">
                {type_name} Comparison List ({total_records})
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }, []);

  return (
    <div className="row dashboard">
      <ToastContainer />
      <DeleteConfirmationModalComponent
        show={show}
        type={"delete"}
        handleClose={() => setShow(false)}
        setDeleteConfirm={onDeleteInner}
      />
      <div>
        <div className="d-flex flex-column p-0 border-0">
          {bodyTypes.map((item, index) => {
            console.log("body type", item);
            return (
              <div className="mb-4">
                <Accordion defaultActiveKey="0" className="mb-4">
                  <Accordion.Item
                    eventKey="0"
                    className="card-height header_padding_top pb-3"
                  >
                    <Card>
                      <Card.Body>
                        <Card.Text>
                          <div className="row dashboard">
                            <div className="" key={index}>
                              <Datatable
                                car_body_type_id={item.car_body_type_id}
                                type_name={item.type_name}
                                api={apiHandler.getCarComparisonsByBodyTypes}
                                columns={columns}
                                header={subHeaderComponent}
                                showHeader={true}
                                sortFieldNames={[]}
                                search={search}
                                key={index}
                                reload={reload}
                                defaultSortColumn={""}
                                setReload={setReload}
                                setTotalRecords={setTotalRecords}
                                isDraggable={true}
                                updateOrderingHandler={
                                  apiHandler.bulkUpdateCarComparisonOrdering
                                }
                                column_ordering={"comparison_ordering"}
                              />
                            </div>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Accordion.Item>
                </Accordion>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
