import { BASE_URL_AUTOX, getConfig } from "../../request";
import axios from "axios";

const ADD_CAR_GALLERY = "gallery/car-gallery-add";
const ADD_BIKE_GALLERY = "gallery/bike-gallery-add";

export const addCarGallery = async (formData, token) => {
  try {
    const response = await axios.post(
      BASE_URL_AUTOX + ADD_CAR_GALLERY,
      formData,
      getConfig(token, { "Content-Type": "multipart/form-data" })
    );
    return response.data;
  } catch (error) {
    console.error("Error uploading car gallery:", error);
    throw error;
  }
};
export const getGalleryPreviewLink = async (brandId, modelId, type) => {
  try {
    const response = await axios.get(
      `${BASE_URL_AUTOX}gallery/preview/${brandId}/${modelId}/${type}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching gallery preview link:", error);
    throw error;
  }
};

export const getCarGalleryById = async (id, token) => {
  try {
    const response = await axios.get(
      BASE_URL_AUTOX + "gallery/car-gallery/" + id,
      getConfig(token)
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching car gallery:", error);
    throw error;
  }
};

export const editCarGallery = async (id, formData, token) => {
  try {
    const response = await axios.put(
      BASE_URL_AUTOX + "gallery/car-gallery/edit/" + id,
      formData,
      getConfig(token, { "Content-Type": "multipart/form-data" })
    );
    return response.data;
  } catch (error) {
    console.error("Error editing car gallery:", error);
    throw error;
  }
};

export const getCarGalleryList = async (token, payload) => {
  return await axios.post(
    BASE_URL_AUTOX + "gallery/car-gallery",
    payload,
    getConfig(token)
  );
};

export const getAllreadyAddedCarGallery = async (id, token) => {
  try {
    const response = await axios.get(
      BASE_URL_AUTOX + "gallery/car-gallery/verify-entry/" + id,
      getConfig(token)
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching car gallery:", error);
    throw error;
  }
};

export const deleteCarGallery = async (id, token) => {
  try {
    const response = await axios.delete(
      BASE_URL_AUTOX + "gallery/car-gallery/delete/" + id,
      getConfig(token)
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting car gallery:", error);
    throw error;
  }
};

export const addBikeGallery = async (formData, token) => {
  try {
    const response = await axios.post(
      BASE_URL_AUTOX + ADD_BIKE_GALLERY,
      formData,
      getConfig(token, { "Content-Type": "multipart/form-data" })
    );
    return response.data;
  } catch (error) {
    console.error("Error uploading car gallery:", error);
    throw error;
  }
};

export const getBikeGalleryById = async (id, token) => {
  try {
    const response = await axios.get(
      BASE_URL_AUTOX + "gallery/bike-gallery/" + id,
      getConfig(token)
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching car gallery:", error);
    throw error;
  }
};

export const editBikeGallery = async (id, formData, token) => {
  try {
    const response = await axios.put(
      BASE_URL_AUTOX + "gallery/bike-gallery/edit/" + id,
      formData,
      getConfig(token, { "Content-Type": "multipart/form-data" })
    );
    return response.data;
  } catch (error) {
    console.error("Error editing car gallery:", error);
    throw error;
  }
};

export const getBikeGalleryList = async (token, payload) => {
  try {
    if (payload) {
      const response = await axios.post(
        BASE_URL_AUTOX + "gallery/bike-gallery",
        payload,

        getConfig(token)
      );
      return response.data;
    }
  } catch (error) {
    console.error("Error fetching car gallery:", error);
    throw error;
  }
};

export const getAllreadyAddedBikeGallery = async (id, token) => {
  try {
    const response = await axios.get(
      BASE_URL_AUTOX + "gallery/bike-gallery/verify-entry/" + id,
      getConfig(token)
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching car gallery:", error);
    throw error;
  }
};

export const deleteBikeGallery = async (id, token) => {
  try {
    const response = await axios.delete(
      BASE_URL_AUTOX + "gallery/bike-gallery/delete/" + id,
      getConfig(token)
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting car gallery:", error);
    throw error;
  }
};
