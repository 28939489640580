import { useNavigate } from "react-router-dom";
import { getToken } from "../../utility/constants";
import apiHandler from "./apiHandler";
import { notifyError, notifySuccess } from "../../utility/common";
import TemplateForm from "./TemplateForm";

export default function AddTemplate() {
  const navigate = useNavigate();
  const token = getToken();
  const initialValues = {
    template_name: "",
    subject: "",
    html_body: "",
    text_body: "",
    status: 1,
    created_by: 0,
  };
  const AddTemplate = async (data) => {
    try {
      const response = await apiHandler.addTemplate(data, token);
      console.log(response);
      if (response.status === 200) {
        notifySuccess(response.data.message);
        setTimeout(() => {
          navigate("/template");
        }, 1500);
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };
  return (
    <>
      <TemplateForm
        initialValues={initialValues}
        onSubmit={AddTemplate}
        title="Add Template"
      />
    </>
  );
}
