import { useState } from "react";
import { useNavigate } from "react-router-dom";
import EditViewDelete from "../../components/EditViewDeleteReviewPassword";
import Datatable from "../../components/Table";
import Breadcrumbs from "../../Breadcrumbs";
import { ReactSVG } from "react-svg";
import PlusLargeIcon from "../../assets/icons/bootstrap-icons/plus-lg.svg";
import DeleteConfirmationModalComponent from "../../components/DeleteConfirmationModalComponent";
import apiHandler from "./apiHandler";
import { getToken, renderDate } from "../../utility/constants";
import { Card } from "react-bootstrap";
import HeadingTwo from "../../components/HeadingTwo";
import { notifyError, notifySuccess } from "../../utility/common";
export default function MagazineList() {
  const [reloadTable, setReloadTable] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [magazineToDelete, setMagazineToDelete] = useState();
  const token = getToken();
  const navigate = useNavigate();
  const onEditInner = (id) => {
    navigate("/magazine/edit-magazine/" + id);
  };
  const onDeleteInner = async () => {
    try {
      await apiHandler.deleteMagazine(magazineToDelete, token);
      setShowDeleteModal(false);
      setReloadTable(true);
      notifySuccess("Magazine deleted successfully");
    } catch (error) {
      console.error("Error deleting magazine:", error);
      setShowDeleteModal(false);
      notifyError("Failed to delete magazine");
    }
  };
  const columns = [
    {
      name: "Sr. No",
      sortable: false,
      selector: (row) => row.id,
      width: "120px",
    },
    {
      name: "Display Image",
      sortable: true,
      selector: (row) => (
        <div className="m-2 bg-green">
          <img
            src={
              row.display_image.startsWith("https://")
                ? row.display_image
                : `https://images.autoxmag.com/uploads/magazine-thumbs/${row.display_image}`
            } //${row.display_image}
            alt="image"
            style={{
              width: "80px",
              height: "100px",
            }}
          />
        </div>
      ),
    },
    {
      name: "Magazine Name",
      sortable: true,
      selector: (row) => row.title,
      width: "250px",
    },
    {
      name: "URL",
      sortable: true,
      selector: (row) => row.url,
      width: "250px",
    },
    {
      name: "Created Date",
      sortable: true,
      selector: (row) => renderDate(row.display_date),
      width: "150px",
    },

    {
      name: "Action",
      sortable: false,
      width: "auto",
      cell: (row) => (
        <EditViewDelete
          id={row.id}
          onEdit={onEditInner}
          onDelete={() => {
            setMagazineToDelete(row.id);
            setShowDeleteModal(true);
          }}
          onView={() =>
            window.open("https://www.autox.com/magazine/" + row.url)
          }
        />
      ),
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <Breadcrumbs />
        <button
          className="add-service-centre-button d-flex align-items-center justify-content-between mb-2 px-3"
          onClick={() => navigate("/magazine/add-magazine")}
        >
          <ReactSVG
            src={PlusLargeIcon}
            height="18px"
            width="18px"
            className="mr-2"
          />{" "}
          Add Magazine
        </button>
      </div>
      <Card className="p-3">
        <div>
          <HeadingTwo
            title={"Magazine"}
            type={"dashboard"}
            showHeader
            pointerOnHover
          />
        </div>
        <div>
          <Datatable
            columns={columns}
            api={apiHandler.getMagazineList}
            reload={reloadTable}
            setReload={setReloadTable}
            setTotalRecords={setTotalPages}
          />
          <DeleteConfirmationModalComponent
            show={showDeleteModal}
            type={"delete"}
            handleClose={() => setShowDeleteModal(false)}
            setDeleteConfirm={onDeleteInner}
          />
        </div>
      </Card>
    </>
  );
}
