import React, { useState } from "react";
import {
  BrowserRouter,
  Navigate,
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ReactSVG } from "react-svg";
import Layout from "../../../components/Layout";
import Heading from "../../../components/Heading";
import HeadingTwo from "../../../components/HeadingTwo";

import { styled } from "styled-components";
import Breadcrumb from "react-bootstrap/Breadcrumb";

export default function CarModelsMain({ children }) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="row dashboard">
        {/* <Breadcrumb className="nav_Breadcrumb">
                    <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Data</Breadcrumb.Item>
                </Breadcrumb> */}

        {/* <div className="d-flex">
                                <div className="margin-heading col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-4 col-6">
                                    <Heading
                                        title="New Car"
                                        type="dashboard"
                                        showHeader={true}
                                        pointerOnHover={true}

                                    />
                                    <div className="d-flex w-100 align-items-center justify-content-between mb-2 sub-heading">
                                        <HeadingTwo
                                            title={<span className="">Car Brand Management</span>}
                                            type="dashboard"
                                            showHeader={true}
                                            pointerOnHover={true}>
                                        </HeadingTwo>
                                        <div className="d-flex justify-content-end">
                                            {location.pathname==="/car-brands" ?
                                                (<>

                                                   
                                                    <button className="purple-btn" onClick={{}}><div className="d-flex"><ReactSVG src={DownloadIcon} />&nbsp; Export Data</div></button>&nbsp;&nbsp;
                                                   
                                                    <button className="green-btn" onClick={{}}><div className="d-flex"><ReactSVG src={DownloadIcon} />&nbsp; Download Each Brand Content in Text Files</div></button>&nbsp;&nbsp;
                                                  
                                                    <button className="red-btn" onClick={() => navigate('/add-car-brand')}><div className="d-flex"><ReactSVG src={PlusLargeIcon} />&nbsp; Add Car Brand</div></button>
                                                </>
                                                ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div> */}
        <div>{children}</div>
      </div>
    </Layout>
  );
}
