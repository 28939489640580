import { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  getAllBikeBrands,
  getAllBrands,
  getModelByBrandId,
} from "../../../request";
import { getToken, renderDateTime } from "../../../utility/constants";

import BikeCommonForm from "./BikeCommonForm";
import {
  getAllreadyAddedBikeGallery,
  getAllreadyAddedCarGallery,
} from "../apiHandler";
import { useNavigate } from "react-router-dom";
import { getBikeModelByBrandId } from "../../NewBike/BikeCityPrice/apiHandler";

export default function BikeGalleryFormHandler({
  initialValues,
  onSubmit,
  validation,
  onRemoveImage,
  type,
}) {
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const token = getToken();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchBrand = async () => {
      try {
        const brandList = await getAllBikeBrands(token);
        setBrands(brandList.data.result);
      } catch (error) {
        console.log(error);
      }
    };
    fetchBrand();
  }, [initialValues, token]);

  const { handleSubmit, values, setFieldValue, touched, errors } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validation,
    onSubmit,
  });
  const handleRemoveImage = (index) => {
    const updatedImages = values.images.filter((_, i) => i !== index);
    setFieldValue("images", updatedImages);
    if (onRemoveImage) {
      onRemoveImage(index);
    }
  };

  const handleFileChange = (event, position) => {
    const files = event.currentTarget.files;
    const newImages = Array.from(files).map((file, index) => ({
      blob: URL.createObjectURL(file),
      file: file,
      caption: "",
      alt: file.name,
      position: position,
      order: values.images.length + index + 1,
    }));

    setFieldValue("images", [...values.images, ...newImages]);
  };

  useEffect(() => {
    if (values.brand_id) {
      const fetchModels = async () => {
        try {
          const payload = { brand_id: values.brand_id };
          const ModelList = await getBikeModelByBrandId(token, payload);
          console.log(ModelList.data.result, "sus");
          setModels(ModelList.data.result);
        } catch (error) {
          console.error("Error fetching models:", error);
        }
      };
      fetchModels();
    }
  }, [values.brand_id, token]);

  const handleSubmitDraft = () => {
    setFieldValue("status", 0);
    handleSubmit();
  };

  useEffect(() => {
    if (values.model_id) {
      const fetchExistingImages = async () => {
        try {
          const existingImages = await getAllreadyAddedBikeGallery(
            values.model_id,
            token
          );
          if (existingImages.data.result[0].id) {
            navigate(
              "/bike-model-gallery/bike-gallery-edit/" +
                existingImages.data.result[0].id
            );
          }
          console.log(existingImages.data.result[0].id, "existingImages");
        } catch (error) {
          console.error("Error fetching existing images:", error);
        }
      };
      fetchExistingImages();
    }
  }, [values.model_id]);

  return (
    <BikeCommonForm
      handleSubmit={handleSubmit}
      values={values}
      setFieldValue={setFieldValue}
      touched={touched}
      errors={errors}
      brands={brands}
      models={models}
      handleFileChange={handleFileChange}
      handleRemoveImage={handleRemoveImage}
      handleSubmitDraft={handleSubmitDraft}
      type={type}
    />
  );
}
