import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../../request";

/**
 * Add New Trending Car List
 * @param {*} data
 * @param {*} token
 */

const addTrendingBike = (payload, token) => {
  return axios.post(
    `${BASE_URL_AUTOX}new-bike/trending/add`,
    payload,
    getConfig(token)
  );
};

/**
 * Delete Trending Car
 * @param {*} id
 * @param {*} token
 */

const deleteTrendingBike = (token, id) => {
  return axios.delete(
    `${BASE_URL_AUTOX}new-bike/trending/delete/${id}`,
    getConfig(token)
  );
};

/**
 * Get Trending Car List
 * @param {*} token
 */

const listTrendingBike = (token) => {
  return axios.post(
    `${BASE_URL_AUTOX}new-bike/trending/list`,
    getConfig(token)
  );
};

/**
 * Sort Ordering of Car Trending Data
 * @param {*} token
 * @param {*} carItems
 */

const sortTrendingBike = (token, carItems) => {
  return axios.put(
    `${BASE_URL_AUTOX}new-bike/trending/sort`,
    carItems,
    getConfig(token)
  );
};

export default {
  addTrendingBike,
  deleteTrendingBike,
  listTrendingBike,
  sortTrendingBike,
};
