import Breadcrumbs from "../../Breadcrumbs";
import Layout from "../../components/Layout";

export default function NewsLetterMain({ children }) {
  return (
    <Layout>
      <div className="row dashboard" style={{ minHeight: "100%" }}>
       
        <div className="my-4" style={{ minHeight: "100%" }}>
          {children}
        </div>
      </div>
    </Layout>
  );
}
