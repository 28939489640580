import React from "react";

const DynamicSelect = ({
  label,
  options,
  id,
  values,
  touched,
  setFieldValue,
  errors,
  name,
  checkedValue,
  ...rest
}) => {
  return (
    <div className="col-lg-6 p-3">
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <select
        className={`form-select border border-2 shadow-none ${
          touched[id] && errors[id] ? "is-invalid" : ""
        }`}
        id={id}
        onChange={(e) => setFieldValue(id, e.target.value)}
        value={values[id] || checkedValue}
        {...rest}
      >
        <option>Select {label}</option>
        {options?.map((option) => (
          <option key={option.id} value={option.id}>
            {option[`${name}`]}
          </option>
        ))}
      </select>
      {touched[id] && errors[id] && (
        <div className="invalid-feedback">{errors[id]}</div>
      )}
    </div>
  );
};

export default DynamicSelect;
