import axios from "axios";
import {
  ADD_BIKE_DEALER,
  BASE_URL_AUTOX,
  DELETE_BIKE_DEALER_BY_ID,
  EDIT_BIKE_DEALER,
  GET_BIKE_DEALER_BY_ID,
  GET_BIKE_DEALER_LIST,
  getConfig,
} from "../../../request";
import { notifyError } from "../../../utility/common";

/**
 * Get List of bike Dealers
 * getbikedDealerList is used to get List of bike Dealers
 * @param token
 * @param payload
 */
export function getbikeDealerList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_BIKE_DEALER_LIST}`,
    payload,
    getConfig(token)
  );
}

export function addbikeDealer(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${ADD_BIKE_DEALER}`,
    payload,
    getConfig(token)
  );
}

export function getbikeDealerById(token, id) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_BIKE_DEALER_BY_ID}${id}`,
    getConfig(token)
  );
}

export function editbikeDealer(token, payload, id) {
  return axios.put(
    `${BASE_URL_AUTOX}${EDIT_BIKE_DEALER}${id}`,
    payload,
    getConfig(token)
  );
}

export function deletebikeDealerById(token, id) {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_BIKE_DEALER_BY_ID}${id}`,
    getConfig(token)
  );
}

export function addbikeDealerImport(data) {
  return axios.post(`${BASE_URL_AUTOX}new-bike/dealer/importDealer`, {
    data: data,
  });
}
export async function downloadDealerSample() {
  try {
    const res = await axios.post(
      `${BASE_URL_AUTOX}car/dealer/downloadSample`,
      {}
    );
    console.log(res.data.url);
    if (res.data.url) {
      window.open(res.data.url, "_blank");
    }
  } catch (error) {
    notifyError(error.message);
  }
}
export async function downloadBikeDealer(token, payload) {
  return await axios.post(
    `${BASE_URL_AUTOX}new-bike/dealer/export-dealer`,
    payload,
    getConfig(token)
  );
}

export default {
  getbikeDealerList,
  addbikeDealer,
  getbikeDealerById,
  editbikeDealer,
  deletebikeDealerById,
  addbikeDealerImport,
  downloadDealerSample,
};
