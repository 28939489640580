import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FieldArray, useFormik, Formik, Field, getIn } from "formik";
import FormSelect from "../../../components/FormSelect";
import Breadcrumbs from "../../../Breadcrumbs";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import BackIcon from "../../../assets/icons/Back_Arrow.png";
import apiHandler, { addbikeVersion } from "./apiHandler";
import CreateCarVersionSubForm from "./CreateCarVersionSubForm";
import { getToken } from "../../../utility/constants";
import { notifyError, notifySuccess } from "../../../utility/common";
import { carVersionValidation } from "./schemaValidation";

export default function AddBikeVariant() {
  const token = getToken();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [isSubmit, setIsSubmit] = useState("");
  const [versionDetails, setVersionDetails] = useState({
    brand_id: -1,
    model_id: -1,
    version_data: [
      {
        version_name: "",
        version_url: "",
        show_on_launch: 0,
        launch_date: "",
        version_status: 1,
      },
    ],
  });

  useEffect(() => {
    getBrands();
  }, []);

  const getBrands = async () => {
    apiHandler
      .getAllBikeBrands(token)
      .then((res) => {
        setBrands(res.data.result);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  };

  const handleBrandChange = async (brand_id) => {
    console.log(brand_id);
    apiHandler
      .getAllBikeModelsList(token, { brand_id: brand_id })
      .then((result) => {
        setModels(result.data.result);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  };

  return (
    <div className="row dashboard">
      <div className="row">
        <div className="col-xl-11 col-sm-11">
          <Breadcrumbs />
        </div>
        <div className="col-xl-1 col-sm-1">
          <button
            className="d-flex back-button py-2 px-3 mb-2 btn btn-primary"
            onClick={() => navigate("/bike-version")}
          >
            <Image
              src={BackIcon}
              width="16px"
              height="16px"
              className="mt-1 me-2"
            />{" "}
            Back
          </button>
        </div>
      </div>

      <div className="d-flex card card-rounded p-3">
        <div className="table-heading">Bike Version Form</div>

        <Formik
          initialValues={versionDetails}
          validationSchema={carVersionValidation}
          onSubmit={async (values, action) => {
            console.log("values", values);

            const payload = { ...values };
            // for (let item of payload.version_data) {
            //   if (!item.launch_date) {
            //     item.launch_date = new Date().toISOString();
            //     console.log(item.launch_date);
            //   }
            // }

            delete payload.brand_id;
            addbikeVersion(token, payload)
              .then((response) => {
                if (response.status === 200) {
                  if (isSubmit === "submit") {
                    action.resetForm();
                    notifySuccess("Bike version added successfully");
                  } else if (isSubmit === "submit-specs") {
                    notifySuccess("Bike version added successfully");
                    setTimeout(() => {
                      navigate(
                        `/edit-bike-version?brandId=${values.brand_id}&modelId=${values.model_id}&type=1`
                      );
                    }, 1500);
                  }
                } else {
                  const responseData = response.response
                    ? response.response.data
                    : response.data;
                  const errorMessage =
                    responseData.message || "An error occurred";
                  notifyError(errorMessage);
                }
              })
              .catch((error) => {
                notifyError(error.message);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <div>
              {console.log(values)}

              <Form onSubmit={handleSubmit}>
                <div className="row mt-4 mb-0">
                  <div className="col-6 mb-3">
                    <FormSelect
                      controlId={"brandName"}
                      label={"Select Brand"}
                      name={"brand_id"}
                      defaultName={"Select Brand"}
                      setFieldValue={setFieldValue}
                      items={brands}
                      itemName={"brand_name"}
                      touched={touched}
                      errors={errors}
                      value={values["brand_id"]}
                      getValues={handleBrandChange}
                      isRequired={true}
                    />
                  </div>

                  <div className="col-6 mb-3">
                    <FormSelect
                      controlId={"modelName"}
                      label={"Select Model"}
                      name={"model_id"}
                      defaultName={"Select Model"}
                      setFieldValue={setFieldValue}
                      items={models}
                      itemName={"model_name"}
                      touched={touched}
                      errors={errors}
                      value={values["model_id"]}
                      isRequired={true}
                    />
                  </div>

                  <FieldArray
                    name="version_data"
                    render={(arrayHelpers) => (
                      <div>
                        {values.version_data && values.version_data.length > 0
                          ? values.version_data.map((version, index) => (
                              <div key={index}>
                                <CreateCarVersionSubForm
                                  index={index}
                                  key={index}
                                  name={"version_data"}
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  setFieldValue={setFieldValue}
                                  arrayHelpers={arrayHelpers}
                                />
                              </div>
                            ))
                          : null}
                      </div>
                    )}
                  />
                </div>
                <div>
                  <div className="d-flex my-4">
                    <button
                      type="submit"
                      className="version-button submit me-1"
                      onClick={() => setIsSubmit("submit")}
                    >
                      Submit
                    </button>
                    &nbsp;&nbsp;
                    <button
                      type="submit"
                      className="version-button submit-add-specs ms-1"
                      onClick={() => setIsSubmit("submit-specs")}
                    >
                      Submit & Add Specs
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}
