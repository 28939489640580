import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../../assets/icons/bootstrap-icons/Back_Arrow.svg";
import { Card, Form, Button } from "react-bootstrap";

import { ReactSVG } from "react-svg";
import Breadcrumbs from "../../../Breadcrumbs";
import { getToken } from "../../../utility/constants";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {
  getBikeCityPriceById,
  getBikeBrandNameList,
  getAllBikeCities,
  updateBikeCityPriceById,
} from "../../../request";
import { useParams } from "react-router-dom";
import FormControl from "../../../components/FormControl";
import { ToastContainer } from "react-toastify";
import apiHandler from "./apiHandler";
import { notifyError, notifySuccess } from "../../../utility/common";
import FormControlNumber from "../../../components/FormControlNumber";
import { getAllBikeBrands } from "../BikeBrands/apiHandler";

export default function EditBikeCityPrice() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = getToken();
  const id = useParams();

  const [brands, setBrands] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [model, setModels] = useState([]);
  const [versions, setVersions] = useState([]);

  const [initialValues, setInitialValues] = useState({
    city_id: "",
    version_id: "",
    brand_id: "",
    model_id: "",
    ex_showroom_price: undefined,
    rto: undefined,
    version_name: "",
    insurance: undefined,
    rtoexpense: undefined,
    standardaccessories: undefined,
    depotcharges: undefined,
    rsa: undefined,
    handlingcharges: undefined,
  });

  const validationSchema = Yup.object({
    city_id: Yup.number()
      .min(1, t("City is required"))
      .required(t("City is required")),
    version_id: Yup.number()
      .min(1, t("Version is required"))
      .required(t("Version is required")),
    model_id: Yup.number()
      .min(1, t("Model is required"))
      .required(t("Model is required")),
    ex_showroom_price: Yup.number()
      .required(t("Showroom Price is required"))
      .integer(t("Showroom Price must be an integer")),
    rto: Yup.number().required(t("RTO is required")),
    insurance: Yup.number().required(t("Insurance is required")),
  });
  const fetchData = useCallback(async () => {
    try {
      const res = await getBikeCityPriceById(id.id, id.url, token);
      const response = res.data.data;
      getModels(response.brand_id);
      getVersions(response.model_id);
      setInitialValues(response);
    } catch (error) {
      console.log(error);
    } finally {
      console.log("Done");
    }
  }, [token, id]);

  const getBrandData = useCallback(() => {
    getBikeBrandNameList(token)
      .then((result) => {
        setBrands(result.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  const getModels = (brand_id) => {
    if (!brand_id) {
      setModels([]);
      return;
    }
    const payload = {
      brand_id,
    };
    apiHandler
      .getBikeModelByBrandId(token, payload)
      .then((response) => {
        setModels(response.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getVersions = (model_id) => {
    if (!model_id) {
      setModels([]);
      return;
    }
    const payload = {
      model_id,
    };
    apiHandler
      .getBikeVersionByModelId(token, payload)
      .then((response) => {
        setVersions(response.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAllBikeBrands()
      .then((res) => {
        setBrands(res.data.result);
      })
      .catch((error) => {
        notifyError(error.message);
      });

    getAllBikeCities(token)
      .then((res) => {
        setCityList(res.data.result);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  }, [token]);

  useEffect(() => {
    fetchData();
    getBrandData();
  }, [token, fetchData, getBrandData]);

  return (
    <div className="row dashboard">
      <div className="row">
        {console.log("initialValues", initialValues)}
        <div className="col-xl-11 col-sm-11">
          <Breadcrumbs removeId={true} />
        </div>

        <div className="col-xl-1 col-sm-1">
          <button
            className="d-flex back-button py-2 px-3 mb-4 btn btn-primary"
            onClick={() => navigate("/bike-brand")}
          >
            {" "}
            <ReactSVG src={BackIcon} className="me-1" />
            Back
          </button>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-xl-12 col-sm-12">
          <Card className="pb-2 px-4">
            <Card.Body>
              <Card.Text>
                <div className="row">
                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <div className="d-flex align-items-center">
                        <div className="table-heading">City Price Form</div>
                        <div className="py-0 ms-5"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    const payload = {
                      city_id: values.city_id,
                      version_id: values.version_id,
                      ex_showroom_price: values.ex_showroom_price,
                      rto: values.rto,
                      insurance: values.insurance,
                    };

                    if (values.rtoexpense) {
                      payload.rtoexpense = values.rtoexpense;
                    }
                    if (values.standardaccessories) {
                      payload.standardaccessories = values.standardaccessories;
                    }
                    if (values.depotcharges) {
                      payload.depotcharges = values.depotcharges;
                    }

                    if (values.rsa) {
                      payload.rsa = values.rsa;
                    }

                    if (values.handlingcharges) {
                      payload.handlingcharges = values.handlingcharges;
                    }

                    updateBikeCityPriceById(id.id, id.url, payload, token)
                      .then((resp) => {
                        console.log(resp);
                        notifySuccess("Bike city price updated successfully.");
                        setTimeout(() => {
                          navigate("/bike-city-price");
                        }, 0);
                      })
                      .catch((error) => {
                        console.log(error);
                        notifyError(error.message);
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-4"
                            controlId="brandNameInput"
                          >
                            <Form.Label className="mb-2">
                              Select City <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Form.Select
                              controlId={"CityControlSelect"}
                              label={"Select City"}
                              touched={touched}
                              errors={errors["city_id"]}
                              defaultChecked={""}
                              defaultValue={""}
                              name="city_id"
                              value={values.city_id}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.city_id && errors.city_id}
                            >
                              <option selected disabled value="">
                                Select City
                              </option>
                              {cityList.map((city, i) => {
                                return (
                                  <option value={city.id} key={i}>
                                    {city.city_name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {touched["city_id"] && errors["city_id"]}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-4"
                            controlId="brandNameInput"
                          >
                            <Form.Label className="mb-2">
                              Select Brand <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Form.Select
                              controlId={"CityControlSelect"}
                              touched={touched}
                              errors={errors["brand_id"]}
                              name="brand_id"
                              value={values.brand_id}
                              onChange={(e) => {
                                const brand_id = e.target.value;
                                setFieldValue("brand_id", brand_id);
                                setFieldValue("model_id", "");
                                setFieldValue("version_id", "");
                                getModels(brand_id);
                              }}
                              onBlur={handleBlur}
                              isInvalid={touched.brand_id && errors.brand_id}
                            >
                              <option selected disabled value="">
                                Select Brand
                              </option>
                              {brands.map((brand, i) => {
                                return (
                                  <option value={brand.id} key={i}>
                                    {brand.brand_name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {touched["brand_id"] && errors["brand_id"]}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-4"
                            controlId="brandNameInput"
                          >
                            <Form.Label className="mb-2">
                              Select Model <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Form.Select
                              controlId={"CityControlSelect"}
                              touched={touched}
                              errors={errors["model_id"]}
                              name="model_id"
                              value={values.model_id}
                              onChange={(e) => {
                                const model_id = e.target.value;
                                setFieldValue("model_id", model_id);
                                getVersions(model_id);
                              }}
                              onBlur={handleBlur}
                              isInvalid={touched.model_id && errors.model_id}
                            >
                              <option selected disabled value={""}>
                                Select Model
                              </option>
                              {model.map((mod, i) => {
                                return (
                                  <option value={mod.id} key={i}>
                                    {mod.model_name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {touched["model_id"] && errors["model_id"]}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-md-6">
                          <Form.Group
                            className="mb-4"
                            controlId="brandNameInput"
                          >
                            <Form.Label className="mb-2">
                              Select Version{" "}
                              <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Form.Select
                              controlId={"CityControlSelect"}
                              touched={touched}
                              errors={errors["version_id"]}
                              name="version_id"
                              value={values.version_id}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.version_id && errors.version_id
                              }
                            >
                              <option selected disabled value={""}>
                                Select Version
                              </option>
                              {versions.map((version, i) => {
                                return (
                                  <option value={version.id} key={i}>
                                    {version.version_name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {touched["version_id"] && errors["version_id"]}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group className="mb-4" controlId="priceInput">
                            <FormControlNumber
                              controlId={"showroomPriceInput"}
                              label={
                                <span>
                                  Ex Showroom Price
                                  <sup className="text-danger">*</sup>
                                </span>
                              }
                              touched={touched}
                              errors={errors}
                              name={"ex_showroom_price"}
                              value={values["ex_showroom_price"]}
                              setFieldValue={setFieldValue}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group className="mb-4" controlId="rtoInput">
                            <FormControl
                              controlId={"rtoInput"}
                              label={
                                <span>
                                  RTO
                                  <sup className="text-danger">*</sup>
                                </span>
                              }
                              touched={touched}
                              errors={errors}
                              name={"rto"}
                              value={values["rto"]}
                              setFieldValue={setFieldValue}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-4"
                            controlId="insurenceInput"
                          >
                            {console.log("er", errors)}
                            <FormControl
                              controlId={"insuranceInput"}
                              label={
                                <span>
                                  Insurance
                                  <sup className="text-danger">*</sup>
                                </span>
                              }
                              touched={touched}
                              errors={errors}
                              name={"insurance"}
                              value={values["insurance"]}
                              setFieldValue={setFieldValue}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-4"
                            controlId="rtoexpenseInput"
                          >
                            <FormControl
                              controlId={"rtoexpense"}
                              label={"RTO Expense"}
                              name={"rtoexpense"}
                              touched={touched}
                              errors={errors}
                              value={values["rtoexpense"]}
                              setFieldValue={setFieldValue}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-4"
                            controlId="standardaccessoriesInput"
                          >
                            <FormControl
                              controlId={"standardaccessoriesInput"}
                              label={"Standard Accessories"}
                              name={"standardaccessories"}
                              touched={touched}
                              errors={errors}
                              value={values["standardaccessories"]}
                              setFieldValue={setFieldValue}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-4"
                            controlId="depotchargesInput"
                          >
                            <FormControl
                              controlId={"depotchargesInput"}
                              label={"Depot Charges"}
                              name={"depotcharges"}
                              touched={touched}
                              errors={errors}
                              value={values["depotcharges"]}
                              setFieldValue={setFieldValue}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group className="mb-4" controlId="rsaInput">
                            <FormControl
                              controlId={"rsaInput"}
                              label={"RSA"}
                              name={"rsa"}
                              touched={touched}
                              errors={errors}
                              value={values["rsa"]}
                              setFieldValue={setFieldValue}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-4"
                            controlId="handlingchargesInput"
                          >
                            <FormControl
                              controlId={"handlingchargesInput"}
                              label={"Handling Charges"}
                              name={"handlingcharges"}
                              touched={touched}
                              errors={errors}
                              value={values["handlingcharges"]}
                              setFieldValue={setFieldValue}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      {/* <button className="export-button d-flex align-items-center justify-content-between me-2 mb-2 text-white px-3">
                        Update Price
                      </button> */}
                      <Button
                        type="submit"
                        variant="btnBlue"
                        className="d-flex align-items-center justify-content-between my-3"
                        // onClick={handleClick}
                      >
                        Update Price
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
