import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../request";
// import { API_URL } from "../../request";

const getBikeBrandList = async (payload, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL_AUTOX}new-bike/brand/all/list`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching bike brand list:", error);
    return null;
  }
};

const getBikeReviewList = async (payload, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL_AUTOX}new-bike/review/filtered/list`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching bike review list:", error);
    return null;
  }
};

const getBikeModelList = async (payload) => {
  try {
    const response = await axios.post(
      `${BASE_URL_AUTOX}new-bike/model/all/list`,
      payload
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching bike model list:", error);
    return null;
  }
};

const addBikeUserReview = async (payload, token) => {
  return await axios.post(`${BASE_URL_AUTOX}new-bike/review/add`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
const deleteBikeUserReviewById = async (id, token) => {
  return await axios.delete(`${BASE_URL_AUTOX}new-bike/review/delete/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getModelListingById = async (payload, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL_AUTOX}new-bike/model/all/list`,
      payload,
      getConfig(token)
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching bike model list:", error);
    return null;
  }
};

const getBikeUserReviews = async (token, params) => {
  try {
    return await axios.post(
      `${BASE_URL_AUTOX}new-bike/review/filtered/list`,
      params,
      getConfig(token)
    );
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getBikeUserReviewById = async (id, token) => {
  try {
    return await axios.get(
      `${BASE_URL_AUTOX}new-bike/review/${id}`,
      getConfig(token)
    );
  } catch (error) {
    console.log(error);
    return error;
  }
};

const updateBikeUserReview = async (id, payload, token) => {
  try {
    return await axios.put(
      `${BASE_URL_AUTOX}new-bike/review/update/${id}`,
      payload,
      getConfig(token)
    );
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getVersion = async (payload) => {
  try {
    const response = await axios.post(
      `${BASE_URL_AUTOX}new-bike/version/list`,
      payload
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export {
  getBikeReviewList,
  getBikeModelList,
  addBikeUserReview,
  deleteBikeUserReviewById,
  getBikeBrandList,
  getModelListingById,
  getBikeUserReviews,
  getBikeUserReviewById,
  updateBikeUserReview,
  getVersion,
};
