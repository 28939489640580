import React from "react";
import { useNavigate } from "react-router-dom";

import { getToken } from "../../utility/constants";
import { ToastContainer, toast } from "react-toastify";
import FAQapiHandler from "./FAQapiHandler";

import BikeQuestionForm from "./BikeQuestionForm";

export default function AddBikeQuestion() {
  const navigate = useNavigate();

  const token = getToken();

  const addBikeSubmit = async (data) => {
    try {
      console.log(data);
      const response = await FAQapiHandler.addBikeFAQquestion(token, data);
      console.log(response);
      if (response.status === 200) {
        toast.success(response.data.message);
        setTimeout(() => {
          navigate("/bike-questions");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };
  const initialValues = {
    question_name: "",
    question_status: -1,
    brand_id: -1,
    model_id: -1,
  };

  return (
    <>
      <BikeQuestionForm
        initialValues={initialValues}
        onSubmit={addBikeSubmit}
      />
      <ToastContainer />
    </>
  );
}
