import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import HeadingTwo from "../../components/HeadingTwo";
import { ReactSVG } from "react-svg";
import BackIcon from "../../assets/icons/bootstrap-icons/Back_Arrow.svg";
import TagsValidationSchema from "./TagValidation"; // Define your Yup validation schema here
import { useNavigate } from "react-router-dom";
import FormControl from "../../components/FormControl";
import { Card } from "react-bootstrap";
import Breadcrumbs from "../../Breadcrumbs";
import FormSelect from "../../components/FormSelect";
import { notifyError } from "../../utility/common";
import {
  getBikeBrandNameList,
  getBrandNameList,
  getModelByBrandId,
} from "../../request";
import { getBikeModelByBrandId } from "../NewBike/BikeCityPrice/apiHandler";

const TagForm = ({ initialValues, onSubmit, title }) => {
  const [brandList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const navigate = useNavigate();

  const { handleSubmit, errors, values, touched, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: TagsValidationSchema,
    onSubmit: async (values, action) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.error(error);
      }
    },
  });

  useEffect(() => {
    if (values.vehicle_type) {
      getBrands();
    }
  }, [values.vehicle_type]);

  useEffect(() => {
    if (values.brand_id) {
      getModels();
    }
  }, [values.brand_id]);

  const getBrands = async () => {
    try {
      const response =
        values.vehicle_type === "car"
          ? await getBrandNameList()
          : await getBikeBrandNameList();
      setBrandList(response.data.result);
    } catch (error) {
      console.log(error);
      notifyError(error.response.data.message);
    }
  };

  const getModels = async () => {
    try {
      const payload = { brand_id: values.brand_id };
      const response =
        values.vehicle_type === "car"
          ? await getModelByBrandId(payload)
          : await getBikeModelByBrandId(payload);
      setModelList(response.data.result);
    } catch (error) {
      console.log(error);
      notifyError(error.response.data.message);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <Breadcrumbs />
        <button
          className="d-flex back-button py-2 px-3 mb-4 btn btn-primary"
          onClick={() => navigate("/tag")}
        >
          <ReactSVG src={BackIcon} className="me-1" />
          Back
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <Card className="p-3" style={{ width: "80%" }}>
          <div className="d-flex align-items-center">
            <HeadingTwo
              title={title}
              type="dashboard"
              showHeader={true}
              pointerOnHover={true}
            />
          </div>
          <div className="row d-flex gap-4">
            {/* Existing FormControls */}
            <FormControl
              controlId={"tagName"}
              label={"Tag Name"}
              placeholder={"Enter Tag Name"}
              touched={touched}
              errors={errors}
              name="tag_name"
              value={values.tag_name}
              setFieldValue={setFieldValue}
              isRequired={true}
              as={"input"}
            />
            <FormControl
              controlId={"slug"}
              label={"Slug"}
              placeholder={"Enter Slug"}
              touched={touched}
              errors={errors}
              name="slug"
              value={values.slug}
              setFieldValue={setFieldValue}
              as={"input"}
            />
            <FormControl
              controlId={"description"}
              label={"Description"}
              placeholder={"Enter Description"}
              touched={touched}
              errors={errors}
              name="description"
              value={values.description}
              setFieldValue={setFieldValue}
              rows={3}
              as={"textarea"}
            />
            <FormControl
              controlId={"tagType"}
              label={"Tag Type"}
              placeholder={"Enter Tag Type"}
              touched={touched}
              errors={errors}
              name="tag_type"
              value={values.tag_type}
              setFieldValue={setFieldValue}
              as={"input"}
            />
            <FormSelect
              controlId={"verifiedStatus"}
              label={"Verified Status"}
              placeholder={"Select Status"}
              touched={touched}
              errors={errors}
              itemName={"value"}
              showDefault={true}
              defaultName={"Select Status"}
              name={"verified"}
              value={values.verified}
              items={[
                { id: 0, value: "Unverified" },
                { id: 1, value: "Verified" },
              ]}
              setFieldValue={setFieldValue}
            />
            <FormControl
              controlId={"heading"}
              label={"Heading"}
              placeholder={"Enter Heading"}
              touched={touched}
              errors={errors}
              name="heading"
              value={values.heading}
              setFieldValue={setFieldValue}
              as={"input"}
            />
            <FormControl
              controlId={"breadcrumbs"}
              label={"Breadcrumbs"}
              placeholder={"Enter Breadcrumbs"}
              touched={touched}
              errors={errors}
              name="breadcrumb"
              value={values.breadcrumb}
              setFieldValue={setFieldValue}
              as={"input"}
            />
            <FormControl
              controlId={"metaTitle"}
              label={"Meta Title"}
              placeholder={"Enter Meta Title"}
              touched={touched}
              errors={errors}
              name="meta_title"
              value={values.meta_title}
              setFieldValue={setFieldValue}
              as={"input"}
            />
            <FormControl
              controlId={"metaDescription"}
              label={"Meta Description"}
              placeholder={"Enter Meta Description"}
              touched={touched}
              errors={errors}
              name="meta_description"
              value={values.meta_description}
              setFieldValue={setFieldValue}
              as={"textarea"}
              rows={3}
            />
            <FormControl
              controlId={"metaKeywords"}
              label={"Meta Keywords"}
              placeholder={"Enter Meta Keywords"}
              touched={touched}
              errors={errors}
              name="meta_keywords"
              value={values.meta_keywords}
              setFieldValue={setFieldValue}
              as={"textarea"}
              rows={3}
            />
            <FormSelect
              controlId={"metaRobotsIndex"}
              label={"Meta Robots Index"}
              placeholder={"Select Index"}
              touched={touched}
              errors={errors}
              itemName={"value"}
              value={values.robotsindex}
              setFieldValue={setFieldValue}
              name={"robotsindex"}
              items={[
                { id: 0, value: "Select Index" },
                { id: "index", value: "Index" },
                { id: "noindex", value: "No Index" },
              ]}
            />
            <FormSelect
              controlId={"metaRobotsFollow"}
              label={"Meta Robots Follow"}
              placeholder={"Select Follow"}
              touched={touched}
              errors={errors}
              itemName={"value"}
              name={"robotsfollow"}
              value={values.robotsfollow}
              setFieldValue={setFieldValue}
              items={[
                { id: 0, value: "Select Follow" },
                { id: "Follow", value: "Follow" },
                { id: "nofollow", value: "No Follow" },
              ]}
            />

            {/* New Selects for Vehicle Type, Brand, and Model */}
            <FormSelect
              controlId={"vehicleType"}
              label={"Vehicle Type"}
              placeholder={"Select Vehicle Type"}
              touched={touched}
              errors={errors}
              itemName={"name"}
              name={"vehicle_type"}
              defaultName={"Select Vehicle Type"}
              value={values.vehicle_type}
              setFieldValue={setFieldValue}
              items={[
                { id: "car", name: "Car" },
                { id: "bike", name: "Bike" },
              ]}
              // onChange={(e) => {
              //   const selectedType = e.target.value;
              //   setVehicleType(selectedType);
              //   setFieldValue("vehicle_type", selectedType);
              //   setBrandList([]); // Clear previous brand and model selections
              //   setModelList([]);
              //   setFieldValue("bra);
              //   setFieldValue("mod);
              // }}
            />

            <FormSelect
              controlId={"brand"}
              label={"Brand"}
              placeholder={"Select Brand"}
              touched={touched}
              errors={errors}
              itemName={"brand_name"}
              name={"brand_id"}
              value={values.brand_id}
              setFieldValue={setFieldValue}
              items={brandList}
            />

            <FormSelect
              controlId={"model"}
              label={"Model"}
              placeholder={"Select Model"}
              touched={touched}
              errors={errors}
              itemName={"model_name"}
              name={"model_id"}
              value={values.model_id}
              setFieldValue={setFieldValue}
              items={modelList}
            />

            <div className="p-3 w-50">
              <button type="submit" className="green-filled-button">
                Save
              </button>
            </div>
          </div>
        </Card>
      </form>
    </>
  );
};

export default TagForm;
