import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { ToastContainer, toast } from "react-toastify";
import CarFaqForm from "./CarFaqForm";
import FAQCarShortCode from "./FAQCarShortCode";
import { useFormik } from "formik";
import BackIcon from "../../../assets/icons/bootstrap-icons/Back_Arrow.svg";
import { getToken } from "../../../utility/constants";
import apiHandler from "./apiHandler";
import { CarFAQValidation } from "./CarFAQValidation";

export default function EditCarFAQ() {
  const navigate = useNavigate();
  const { id } = useParams();
  const token = getToken();

  const [initialVal, setInitialVal] = useState({
    question: "",
    answer: "",
    faq_ordering: 1,
    is_default: 0,
    faq_condition: "",
  });
  useEffect(() => {
    const getFAQData = async () => {
      const response = await apiHandler.getCarFAQbyId(token, id);
      if (response.status === 200) {
        setInitialVal({
          question: response.data.data.question,
          answer: response.data.data.answer,
          faq_ordering: response.data.data.faq_ordering,
          is_default: response.data.data.is_default,
          faq_condition: response.data.data.faq_condition,
        });
      }
    };
    getFAQData();
  }, []);

  const { handleSubmit, values, touched, errors, setFieldValue } = useFormik({
    initialValues: initialVal,
    enableReinitialize: true,
    validationSchema: CarFAQValidation,
    onSubmit: async (values, action) => {
      try {
        const payload = {
          ...values,
        };
        console.log(values);
        const response = await apiHandler.updateCarFAQ(token, id, payload);
        if (response.status === 200) {
          toast.success("FAQ updated successfully");
          setTimeout(() => {
            navigate("/car-faq");
          }, 1500);
        }
      } catch (error) {
        console.error(error);
      }
    },
  });
  return (
    <div className="row dashboard">
      <ToastContainer />

      <div className="d-flex gap-3 justify-content-between">
        <CarFaqForm
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          handleSubmit={handleSubmit}
          type={"Update"}
        />
        <FAQCarShortCode />
      </div>
    </div>
  );
}
