import React, { useState, useEffect } from "react";
import { Card, Tab, Nav } from "react-bootstrap";
import DynamicSelect from "../CarUserReview/EditCarUserReview/DynamicSelect";
import {
  getAllBikeBrands,
  getAllBrands,
  getBikeModelByBrandId,
  getBikeModelList,
  getBrandList,
  getModelByBrandId,
  getModelsByBrandId,
} from "../../request";
import FormSelect from "../../components/FormSelect";
const CarBikeLink = (props) => {
  const { values, errors, touched, setFieldValue, token } = props;

  const [carBrands, setCarBrands] = useState([]);
  const [carModels, setCarModels] = useState([]);
  const [bikeBrands, setBikeBrands] = useState([]);
  const [bikeModels, setBikeModels] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const [carBrandRes, bikeBrandRes] = await Promise.all([
          getAllBrands(token),
          getAllBikeBrands(token),
        ]);
        setCarBrands(carBrandRes.data.result);
        setBikeBrands(bikeBrandRes.data.result);
      } catch (error) {
        console.error("Error fetching brand data:", error);
      }
    }
    fetchData();
  }, [token]);

  useEffect(() => {
    async function fetchModels() {
      try {
        const payload = { brand_id: values.brand, type: "all" };
        if (values.type === "car" || !values.type) {
          let carModelRes = await getModelByBrandId(payload, token);
          const carTypeMap = {
            0: "Live",
            1: "Upcoming",
            2: "Discontinued",
            3: "Discontinued with year",
          };

          carModelRes = carModelRes?.data?.result?.map((model) => ({
            ...model,
            model_name: `${model.model_name} - ${
              carTypeMap[model.car_type] || "Live"
            }`,
          }));
          console.log("carModelRes?.data?.result", carModelRes);
          setCarModels(carModelRes);
        } else if (values.type === "bike") {
          let bikeModelRes = await getBikeModelByBrandId(token, payload);
          const BikTypeMap = {
            0: "Live",
            1: "Upcoming",
            2: "Discontinued",
            3: "Discontinued with year",
          };

          bikeModelRes = bikeModelRes?.data?.result?.map((model) => ({
            ...model,
            model_name: `${model.model_name} - ${
              BikTypeMap[model.bike_type] || "Live"
            }`,
          }));
          setBikeModels(bikeModelRes);
        }
      } catch (error) {
        console.error("Error fetching model data:", error);
      }
    }
    if (values.brand) {
      fetchModels();
    }
  }, [values.brand, values.type, token]);

  const handleBrandSelect = (brand) => {
    setFieldValue("brand", brand);
    setFieldValue("model", "-1"); // Reset model when brand changes
  };

  const handleModelSelect = (model) => {
    setFieldValue("model", model);
  };

  return (
    <Card>
      <Tab.Container
        activeKey={values.type ? values.type : "car"}
        onSelect={(key) => {
          setFieldValue("type", key);
          setFieldValue("brand", "-1");
          setFieldValue("model", "-1");
        }}
      >
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link eventKey="car">Car</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="bike">Bike</Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content className="mt-3 p-3">
          <Tab.Pane eventKey="car">
            <FormSelect
              controlId={"Brand"}
              label={"Brand"}
              name={"brand_name"}
              defaultName={"Select Brand"}
              setFieldValue={setFieldValue}
              items={carBrands}
              itemName={"brand_name"}
              touched={touched}
              errors={errors}
              value={values.brand}
              className={"mb-3"}
              getValues={handleBrandSelect}
            />
            <FormSelect
              controlId={"model"}
              label={"Model"}
              name={"model_name"}
              defaultName={"Select Model"}
              setFieldValue={setFieldValue}
              items={carModels}
              itemName={"model_name"}
              touched={touched}
              errors={errors}
              value={values.model}
              className={"mb-3"}
              getValues={handleModelSelect}
            />
          </Tab.Pane>

          <Tab.Pane eventKey="bike">
            <FormSelect
              controlId={"brand"}
              label={"Brand"}
              name={"brand_name"}
              defaultName={"Select Brand"}
              setFieldValue={setFieldValue}
              items={bikeBrands}
              itemName={"brand_name"}
              touched={touched}
              errors={errors}
              value={values.brand}
              className={"mb-3"}
              getValues={handleBrandSelect}
            />
            <FormSelect
              controlId={"model"}
              label={"Model"}
              name={"model_name"}
              defaultName={"Select Model"}
              setFieldValue={setFieldValue}
              items={bikeModels}
              itemName={"model_name"}
              touched={touched}
              errors={errors}
              value={values.model}
              className={"mb-3"}
              getValues={handleModelSelect}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Card>
  );
};

export default CarBikeLink;
