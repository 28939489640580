import { Card, Form } from "react-bootstrap";
import Breadcrumbs from "../../Breadcrumbs";
import { ReactSVG } from "react-svg";
import PlusLargeIcon from "../../assets/icons/bootstrap-icons/plus-lg.svg";
import { useNavigate } from "react-router-dom";
import HeadingTwo from "../../components/HeadingTwo";
import Table from "../../components/Table";
import EditViewDelete from "../../components/EditViewDeleteReviewPassword";
import DeleteConfirmationModalComponent from "../../components/DeleteConfirmationModalComponent";
import apiHandler from "./apiHandler";
import { useState } from "react";
import { formatDate, getToken, renderDateTime } from "../../utility/constants";
import { notifyError, notifySuccess } from "../../utility/common";
import Search from "../../components/SearchField";

export default function PageListing() {
  const token = getToken();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);
  const [pageIdToDelete, setPageIdToDelete] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [tempSearch, setTempSearch] = useState("");

  const onEditPage = (id) => navigate(`/page-edit/${id}`);

  const onViewPage = (page) =>
    window.open(
      `${process.env.REACT_APP_FRONTEND_BASE_URL}/${page.slug}`,
      "_blank"
    );

  const onDeletePage = async () => {
    try {
      await apiHandler.deletePageById(pageIdToDelete, token);
      setShowDeleteModal(false);
      setReloadTable(true);
      notifySuccess("Page deleted successfully");
    } catch (error) {
      setShowDeleteModal(false);
      notifyError(error.message);
    }
  };

  function handleSearchClick() {
    setSearch(tempSearch);
    setReloadTable(true);
  }
  const columns = [
    {
      name: "Title",
      selector: (page) => page.page_name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (page) => getPageStatusBadge(page.status),
      sortable: false,
    },
    {
      name: "Date",
      selector: (page) => renderDateTime(page.created_date),
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      width: "auto",
      cell: (page) => (
        <EditViewDelete
          id={page.id}
          onEdit={onEditPage}
          onDelete={() => {
            setPageIdToDelete(page.id);

            setShowDeleteModal(true);
          }}
          onView={() => onViewPage(page)}
        />
      ),
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <Breadcrumbs />
        <button
          className="add-service-centre-button d-flex align-items-center justify-content-between mb-2 px-3"
          onClick={() => navigate("/page-add")}
        >
          <ReactSVG
            src={PlusLargeIcon}
            height="18px"
            width="18px"
            className="mr-2"
          />{" "}
          Add Page
        </button>
      </div>
      <Card className="p-3">
        <div className="d-flex align-items-center">
          <div className="table-heading">Page Listing ({totalPages})</div>
          <div className="py-0 ms-3">
            <Search
              onChange={(event) => {
                console.log("ev", event);
                setTempSearch(event.target.value);
              }}
              value={tempSearch}
              placeholder={"Search by brand name"}
            />
          </div>
          <button
            className="d-flex align-items-center justify-content-center search-btn ms-2 p-2"
            onClick={handleSearchClick}
          >
            Search
          </button>
          <button
            className="d-flex align-items-center justify-content-center search-btn ms-2 p-2"
            onClick={() => {
              setSearch("");
              setTempSearch("");
              setReloadTable(true);
            }}
          >
            Clear
          </button>
        </div>
        <div>
          <Table
            columns={columns}
            api={apiHandler.getPageList}
            reload={reloadTable}
            search={search}
            setReload={setReloadTable}
            onChangePage={(pageNumber) => setCurrentPage(pageNumber)}
            setTotalRecords={setTotalPages}
            setCurrentPage={setCurrentPage}
          />
          <DeleteConfirmationModalComponent
            show={showDeleteModal}
            type="delete"
            handleClose={() => setShowDeleteModal(false)}
            setDeleteConfirm={onDeletePage}
          />
        </div>
      </Card>
    </>
  );
}

function getPageStatusBadge(status) {
  switch (status) {
    case 1:
      return <div className="badge bg-success">Public</div>;
    case 0:
      return <div className="badge bg-warning">Un-Public</div>;
    default:
      return "Unknown";
  }
}
