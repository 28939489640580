import { useEffect, useState } from "react";
import { getToken } from "../../../utility/constants";
import apiHandler from "./apiHandler";
import { notifyError, notifySuccess } from "../../../utility/common";
import Layout from "../../../components/Layout";
import Breadcrumbs from "../../../Breadcrumbs";
import TrendingCars from "./TrendingCarList";
import AddTrendingCar from "./AddTrendingCar";

export default function TrendingCar({ children }) {
  const token = getToken();
  const [trendingCar, setTrendingCar] = useState([]);

  useEffect(() => {
    getTrendingCarList();
  }, []);

  const getTrendingCarList = () => {
    apiHandler
      .listTrendingCar(token)
      .then((res) => {
        console.log(res.data.result);
        setTrendingCar(res.data.result);
      })
      .catch((error) => {
        notifyError(error.response.data.message);
      });
  };

  const handleDelete = (id) => {
    apiHandler
      .deleteTrendingCar(token, id)
      .then((res) => {
        getTrendingCarList();
        notifySuccess("Removed Successfully !!");
      })
      .catch((error) => {
        notifyError(
          error.response
            ? error.response.data.message
            : "There is problem in error"
        );
      });
  };

  const updateSort = (trendingItems) => {
    apiHandler
      .sortTrendingCar(token, { trendingItems })
      .then((result) => {
        setTrendingCar(result.data.result);
      })
      .catch((error) => {
        notifyError(
          error.response
            ? error.response.data.message
            : "There is problem in error"
        );
      });
  };

  return (
    <Layout>
      <div className="row dashboard">
        <div className="d-flex justify-content-between align-items-center multipleCityPriceMain mb-3 flex-wrap">
          <Breadcrumbs />
        </div>
        <div className="row">
          <div className="col-xxl-8 col-md-7">
            <TrendingCars
              updateSort={updateSort}
              carData={trendingCar}
              handleDelete={handleDelete}
            />
          </div>
          <div className="col-xxl-4 col-md-5">
            <AddTrendingCar onSuccess={getTrendingCarList} />
          </div>
        </div>
      </div>
    </Layout>
  );
}
