import { Button, Card, Form } from "react-bootstrap";
import Breadcrumbs from "../../Breadcrumbs";
import Layout from "../../components/Layout";
import Heading from "../../components/Heading";
import Datatable from "../../components/Table";
import apiHandler from "./apiHandler";
import { useEffect, useState } from "react";
import {
  commentsType,
  getToken,
  getUserId,
  renderDate,
} from "../../utility/constants";
import Search from "../../components/SearchField";
import DownloadIcon from "../../assets/icons/bootstrap-icons/button-icons/download white.svg";
import { ReactSVG } from "react-svg";
export default function CommentListing() {
  const [reloadTable, setReloadTable] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [replyingToCommentId, setReplyingToCommentId] = useState(null);
  const [currentContent, setCurrentContent] = useState("");
  const [replyContent, setReplyContent] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [debouncedSearchVal, setDebouncedSearchVal] = useState("");

  const token = getToken();
  const user_id = getUserId();

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchVal(searchVal);
    }, 1000); // Adjust the delay (in milliseconds) as needed

    return () => {
      clearTimeout(handler);
    };
  }, [searchVal]);

  const getCommentType = (row) => {
    const type = commentsType.find((item) => item.id == row);
    return type ? type.value : "";
  };

  const handleEditClick = (comment) => {
    setEditingCommentId(comment.id);
    setCurrentContent(comment.content);
  };

  const handleReplyClick = (comment) => {
    setReplyingToCommentId(comment.id);
    setReplyContent("");
  };

  const handleCancelClick = () => {
    setEditingCommentId(null);
    setReplyingToCommentId(null);
    setCurrentContent("");
    setReplyContent("");
  };

  const handleUpdateSubmit = (comment) => {
    comment.content = currentContent;
    delete comment.comment_user;
    delete comment.created_date;
    delete comment.parent_comment_id;
    delete comment.parent_comment_user;
    delete comment.post_title;
    apiHandler
      .updateComments(comment, token)
      .then(() => {
        setTimeout(() => {
          setReloadTable(!reloadTable);
        }, 100);
        handleCancelClick();
      })
      .catch((error) => {
        console.error("Error updating comment:", error);
      });
  };

  const handleReplySubmit = async (comment) => {
    // Call the API to submit the reply
    const payload = {
      parent_id: comment.id,
      module_id: comment.module_id,
      module: comment.module,
      content: replyContent,
      approved_by: user_id,
      approved: 1,
      user_id: 1000,
      user_admin_id: user_id,
      user_ip: "",
      content: replyContent,
    };
    apiHandler
      .addComments(payload, token)
      .then(() => {
        setTimeout(() => {
          setReloadTable(!reloadTable);
        }, 100);
        handleCancelClick();
      })
      .catch((error) => {
        console.error("Error submitting reply:", error);
      });
  };

  const handleApproveClick = (id) => {
    apiHandler
      .approveComments(id, token)
      .then(() => {
        setTimeout(() => {
          setReloadTable(!reloadTable);
        }, 100);
      })
      .catch((error) => {
        console.error("Error approving comment:", error);
      });
  };
  const handleDeleteClick = (id) => {
    apiHandler
      .deleteComments(id, token)
      .then(() => {
        setTimeout(() => {
          setReloadTable(!reloadTable);
        }, 100);
      })
      .catch((error) => {
        console.error("Error deleting comment:", error);
      });
  };
  const columns = [
    {
      name: "ID",
      selector: (comment) => comment.id,
      sortable: true,
      width: "100px",
    },
    {
      name: "User",
      selector: (comment) => comment.comment_user,
      sortable: true,
      width: "200px",
    },
    {
      name: "Comment",
      selector: (comment) => (
        <>
          {editingCommentId === comment.id ? (
            <div className="mt-2 mb-2">
              <Form.Control
                as="textarea"
                rows={3}
                value={currentContent}
                onChange={(e) => setCurrentContent(e.target.value)}
              />
              <div className="mt-2 mb-2">
                <Button
                  variant="primary"
                  onClick={() => handleUpdateSubmit(comment)}
                  style={{ marginRight: "5px" }}
                >
                  Update
                </Button>
                <Button variant="secondary" onClick={handleCancelClick}>
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div className="mt-2 mb-2">
                {comment.parent_comment_id ? (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: "column",
                      marginBottom: "10px",
                    }}
                  >
                    <div>
                      In reply To{" "}
                      <span style={{ color: "#615da8" }}>
                        {comment.parent_comment_user}
                      </span>
                    </div>
                  </div>
                ) : null}
                {comment.content}
                <div className="mt-2 mb-2">
                  {comment.approved == 0 ? (
                    <button
                      style={{
                        backgroundColor: "#4cd964",
                        color: "white",
                        border: "none",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        marginRight: "5px",
                      }}
                      onClick={() => handleApproveClick(comment.id)}
                    >
                      Approve
                    </button>
                  ) : null}
                  <button
                    style={{
                      backgroundColor: "#615da8",
                      color: "white",
                      border: "none",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      marginRight: "5px",
                    }}
                    onClick={() => handleReplyClick(comment)}
                  >
                    Reply
                  </button>
                  <button
                    style={{
                      backgroundColor: "#f0ad4e",
                      color: "white",
                      border: "none",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      marginRight: "5px",
                    }}
                    onClick={() => handleEditClick(comment)}
                  >
                    Edit
                  </button>
                  <button
                    style={{
                      backgroundColor: "#d9534f",
                      color: "white",
                      border: "none",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDeleteClick(comment.id)}
                  >
                    Trash
                  </button>
                </div>
              </div>
            </>
          )}

          {replyingToCommentId === comment.id && (
            <div className="mt-2 mb-2">
              <Form.Control
                as="textarea"
                rows={3}
                value={replyContent}
                onChange={(e) => setReplyContent(e.target.value)}
              />
              <div className="mt-2 mb-2">
                <Button
                  variant="primary"
                  onClick={() => handleReplySubmit(comment)}
                  style={{ marginRight: "5px" }}
                >
                  Submit Reply
                </Button>
                <Button variant="secondary" onClick={handleCancelClick}>
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </>
      ),
      sortable: true,
      width: "300px",
      height: "100px",
    },

    {
      name: "In Response To",
      selector: (comment) => (
        <>
          <div
            style={{
              display: "flex",
              gap: "10px",
              flexDirection: "column",
              padding: "10px",
            }}
          >
            <div>{comment.post_title}</div>
            <div>
              <button
                style={{
                  border: "none",
                  background: "#615da8",
                  borderRadius: "5px",
                  color: "white",
                  cursor: "pointer",
                  fontSize: "14px",
                  padding: "5px 10px",
                }}
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_FRONTEND_BASE_URL}/${comment.category_slug}/${comment.slug}`,
                    "_blank"
                  )
                }
              >
                View Comment
              </button>
            </div>
          </div>
        </>
      ),
      sortable: true,
      width: "300px",
    },
    {
      name: "Status",
      selector: (comment) => {
        const commentTypeClass = commentsType
          .find((item) => item.id == comment.approved)
          ?.value.toLowerCase();
        return (
          <div
            className={`car-type-style px-2 pt-1 ${
              commentTypeClass ? `car-type-style-${commentTypeClass}` : ""
            }`}
          >
            {getCommentType(comment.approved)}
          </div>
        );
      },
      width: "200px",
    },
    {
      name: "Date",
      selector: (comment) => renderDate(comment.created_date),
      sortable: true,
    },
  ];

  const downloadCommentContentWrapper = async () => {
    const result = await apiHandler.downloadCommentContent(token);
    if (result.data.url) {
      window.open(result.data.url, "_blank");
    }
  };

  return (
    <Layout>
      <div>
        <div className="d-flex justify-content-between">
          <Breadcrumbs />
          <button
            className="download-button d-flex align-items-center justify-content-between me-2 mb-2 px-3"
            onClick={downloadCommentContentWrapper}
          >
            <ReactSVG
              src={DownloadIcon}
              className="mr-2"
              height="18px"
              width="18px"
            />{" "}
            Download
          </button>
        </div>
        <Card className="p-3">
          <div className="d-flex justify-content-between">
            <Heading title="Comments" />
            <Search
              onChange={(e) => setSearchVal(e.target.value)}
              value={searchVal}
            />
          </div>

          <div>
            <Datatable
              columns={columns}
              api={apiHandler.getCommentsList}
              search={debouncedSearchVal}
              reload={reloadTable}
              setReload={setReloadTable}
              setTotalRecords={setTotalRecords}
            />
          </div>
        </Card>
      </div>
    </Layout>
  );
}
