import clsx from "clsx";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Form from "react-bootstrap/Form";
import FormFieldErrorMsg from "./FormFieldErrorMsg";

export default function FormDate({
  controlId,
  label,
  touched,
  errors,
  name,
  value,
  setFieldValue,
  items,
  itemName,
  defaultName,
}) {
  return (
    <div>
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <DatePicker
          selected={value}
          onChange={(date) => {
            setFieldValue(name, date);
          }}
          dateFormat="yyyy-MM-dd"
          isClearable
          placeholderText="Select date"
          className={clsx(
            "form-control form-control-lg w-100",
            {
              "is-invalid": touched[name] && errors[name],
            },
            {
              "is-valid": touched[name] && !errors[name],
            }
          )}
        />
        <FormFieldErrorMsg touched={touched} errors={errors} name={name} />
      </Form.Group>
    </div>
  );
}
