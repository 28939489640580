import { Accordion, Button, Card, Form } from "react-bootstrap";
import FormControl from "../../components/FormControl";
import JoditEditor, { Jodit } from "jodit-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import FormFieldErrorMsg from "../../components/FormFieldErrorMsg";
import DynamicSelect from "../CarUserReview/EditCarUserReview/DynamicSelect";
import FormSelect from "../../components/FormSelect";
import HeadingTwo from "../../components/HeadingTwo";
import {
  getAdminUserList,
  getCategoryList,
  getPostGalleryList,
} from "./apiHandler";
import { getAdminType, getToken } from "../../utility/constants";
import ReactDatePicker from "react-datepicker";
import CarBikeLink from "./CarBikeLink";
import TagInput from "./TagInput";
import NestedCategoryList from "./CategoryList";
import MediaTab from "./Media/MediaTab";
import { convertTime } from "../../request";
import { ReactSVG } from "react-svg";
import UploadIcon from "../../assets/icons/bootstrap-icons/upload.svg";
import DisplayNestedCategoryList from "./DisplayCategoryList";
import { notifyError } from "../../utility/common";
import moment from "moment-timezone";
import StarRating from "./StarRating";
import PostProsCons from "../Template/PostProsCons";
// import CKEditorComponent from "../../components/CKeditor";
// import { CKEditor } from 'ckeditor4-react';

export default function PostForm({
  values,
  touched,
  setFieldValue,
  errors,
  handleSubmit,
  type,
  adminType,
  setIsDraft,
  isSubmitting,
}) {
  const [categories, setCategories] = React.useState([]);
  const [propSelectedImage, setPropSelectedImage] = React.useState(null);
  const [author, setAuthor] = React.useState([]);
  const [photographer, setPhotographer] = React.useState([]);
  const [types, setTypes] = React.useState(values.type);
  const [showMediaTabModal, setShowMediaTabModal] = useState(false);
  const [mediaTabType, setMediaTabType] = useState(null);
  const [onSelectImageFn, setOnSelectImageFn] = useState(null);
  const [editorContent, setEditorContent] = useState(null);
  const [galleries, setGalleries] = useState([]);
  const token = getToken();
  useEffect(() => {
    getPostGalleryList(token, { page: 1, limit: 100000 })
      .then((res) => {
        setGalleries(res.data.result);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  useEffect(() => {
    if (values.post_content) {
      setEditorContent(values.post_content); // Set content when it's available
    }
  }, [values.post_content]);

  const editor = useRef(null);
  const config = useMemo(
    () => ({
      readonly: false,
      minHeight: 600,
      uploader: {
        insertImageAsBase64URI: true,
      },
    }),
    []
  );
  useEffect(() => {
    setTypes(values.type);
  }, [values.type]);
  useEffect(() => {
    getCategoryList(token).then((res) => {
      setCategories(res.data.result);
    });
  }, [token]);

  useEffect(() => {
    const authorsList = async () => {
      const res = await getAdminUserList(token);
      setAuthor(res.data.data);

      const constant_photographer = [103, 74, 160, 164, 1];
      const photographer_list = res.data.data.filter((item) =>
        constant_photographer.includes(item.id)
      );
      console.log(photographer_list, "photographer_list");
      setPhotographer([
        { id: "-1", display_name: "Select Photography By" },
        ...photographer_list,
      ]); //setPhotographer(photographer_list);
    };
    authorsList();
  }, [token]);

  // const handleImageInsert = (image) => {
  //   const imageUrl =
  //     image.image.startsWith("https://autox-gallery") ||
  //     image.image.startsWith("https://autox") ||
  //     image.image.startsWith("https://s3.ap-south-1")
  //       ? image.image
  //       : `https://images.autox.com/${image.image}`;

  //   setFieldValue(
  //     "post_content",
  //     `${values.post_content}<img src="${imageUrl}" alt="${image.title}" />` +
  //       (image.image_caption
  //         ? `<div class="img-caption">${image.image_caption}</div>`
  //         : "")
  //   );
  // };
  var joditEditor;
  const handleImageInsert = (image) => {
    const imageUrl =
      image.image.startsWith("https://autox-gallery") ||
      image.image.startsWith("https://autox") ||
      image.image.startsWith("https://s3.ap-south-1")
        ? image.image
        : `https://images.autox.com/${image.image}`;

    // Construct the HTML for the image
    const imageHtml = `
    <div style="width: 100%; height: auto;" class="post-image">
      <img src="${imageUrl}" alt="${image.title}" style="width: 100%; height: auto;" />
    </div>
  `;
    // ${
    //   image.image_caption
    //     ? `<div class="img-caption">${image.image_caption}</div>`
    //     : ""
    // }

    try {
      joditEditor.selection.insertHTML(imageHtml);
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleGalleryInsert = (selectedGallerySlug) => {
    const galleryHtml = `<br>{{${selectedGallerySlug}}}<br><br>`;

    try {
      joditEditor.selection.insertHTML(galleryHtml);
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleRemoveStory = (index) => {
    const { stories_images } = values;

    // Store the ID of the removed story if in edit mode
    if (type == "edit" && stories_images[index].storie_id) {
      const removedStoryId = stories_images[index].storie_id; // Assuming each story has an 'id' property
      const updatedDeleteStories = [...values.delete_stories, removedStoryId];

      // Update the delete_stories array
      setFieldValue("delete_stories", updatedDeleteStories);
    }

    // Remove the story from stories_images
    const updatedStories = stories_images.filter((_, i) => i !== index);
    setFieldValue("stories_images", updatedStories);
  };
  const handleVideoURLSubmit = (e) => {
    const videoUrl = e.target.value;
    const isValidUrl =
      /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/.test(videoUrl);

    if (isValidUrl) {
      setFieldValue("stories_images", [
        ...values.stories_images,
        {
          image: videoUrl,
          title: "",
          description: "",
          caption: "",
          order: values.stories_images.length + 1,
          type: "video",
        },
      ]);
      e.target.value = ""; // Clear the input after adding
    } else {
      console.error("Invalid YouTube URL");
    }
  };

  const handleAddImage = (media) => {
    setFieldValue("stories_images", [
      ...values.stories_images,
      {
        image: media.image,
        title: "",
        description: "",
        caption: "",
        order: values.stories_images.length + 1,
        type: "image",
      },
    ]);
  };

  const handlSliderImage = (image) => {
    setFieldValue("slider_images", [...values.slider_images, image]);
  };
  const FileSizeConvert = (bytes) => {
    const units = [
      { unit: "TB", value: Math.pow(1024, 4) },
      { unit: "GB", value: Math.pow(1024, 3) },
      { unit: "MB", value: Math.pow(1024, 2) },
      { unit: "KB", value: 1024 },
      { unit: "B", value: 1 },
    ];

    for (let i = 0; i < units.length; i++) {
      if (bytes >= units[i].value) {
        return (bytes / units[i].value).toFixed(1) + " " + units[i].unit;
      }
    }
    return "0 B"; // Fallback if no size found
  };

  const handleFeaturedImage = (image) => {
    const imageUrl =
      image.image.startsWith("https://autox-gallery") ||
      image.image.startsWith("https://autox") ||
      image.image.startsWith("https://s3.ap-south-1")
        ? image.image.startsWith("https://autox")
          ? image.image.replace("https://autox", "https://images.autoxmag")
          : image.image
        : `https://images.autox.com/${image.image}`;

    const imgElement = new Image();
    imgElement.src = imageUrl;

    return new Promise((resolve) => {
      imgElement.onload = () => {
        try {
          // Check if the image is from Amazon S3 (or other valid URL)
          if (imageUrl.includes("amazonaws")) {
            const [width, height, img_size] = imageUrl
              .substring(
                imageUrl.lastIndexOf("-") + 1,
                imageUrl.lastIndexOf(".")
              )
              .split("x");

            const parsedWidth = parseInt(width, 10);
            const parsedHeight = parseInt(height, 10);

            // Validate dimensions: must be 1280x720
            if (parsedWidth === 1280 && parsedHeight === 720) {
              let size = !isNaN(img_size) && `${img_size} KB`;
              resolve({ width: parsedWidth, height: parsedHeight, size });
            } else {
              // Set image to null if the dimensions do not match
              resolve(null);
            }
          } else {
            // Fetch image and check dimensions for non-Amazon URLs
            fetch(imageUrl)
              .then((response) => response.blob())
              .then((blob) => {
                const size = FileSizeConvert(blob.size);
                const dimensions = `${imgElement.width} x ${imgElement.height}`;

                // Validate dimensions: must be 1280x720
                if (imgElement.width === 1280 && imgElement.height === 720) {
                  resolve({
                    width: imgElement.width,
                    height: imgElement.height,
                    size,
                    dimensions,
                  });
                } else {
                  // Set image to null if the dimensions do not match
                  resolve(null);
                }
              })
              .catch((error) => {
                console.error("Error fetching image:", error);
                resolve(null); // Return null if the fetch fails
              });
          }
        } catch (error) {
          console.error("Error processing image:", error);
          resolve(null); // Return null if any error occurs
        }
      };

      imgElement.onerror = () => {
        console.error("Error loading image:", imageUrl);
        resolve(null); // Return null if the image fails to load
      };
    }).then((imageDetails) => {
      // Set the field value with image details if valid, otherwise null
      if (imageDetails) {
        setFieldValue("featured_image", imageUrl);
      } else {
        notifyError("Image dimensions must be 1280x720");
        // setFieldValue("featured_image", null);
      }
    });
  };

  const handleRemoveImage = (index) => {
    const updatedImages = values.slider_images.filter((_, i) => i !== index);
    setFieldValue("slider_images", updatedImages);
  };

  const handleSubmitDraft = () => {
    setIsDraft(true);
    document.getElementById("submit-btn").click();
  };
  const handleSubmitButton = () => {
    setIsDraft(false);
    document.getElementById("submit-btn").click();
  };
  const handleDateChange = (date) => {
    // Convert selected date to IST
    const istDate = moment(date).tz("Asia/Kolkata", true).toDate();
    setFieldValue("publish_date", istDate);
  };

  const selectedDate = values.publish_date
    ? moment(values.publish_date).tz("Asia/Kolkata", true).toDate()
    : new Date();
  console.log(values, errors);
  console.log(isSubmitting, "isSubmitting");

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="post-wrapper">
          <div className="post-card-1">
            <Accordion defaultActiveKey="0" className="mb-4">
              <Accordion.Item
                eventKey="0"
                className="card-height header_padding_top"
              >
                {" "}
                <Accordion.Header className="p-3 pb-2 ps-2 pt-4">
                  Post Details
                </Accordion.Header>{" "}
                <Accordion.Body>
                  {" "}
                  <div className="p-3">
                    <FormControl
                      controlId={"post_title"}
                      label={
                        "Post Title (Min 35 Characters & Max characters 110)"
                      }
                      type={"text"}
                      name={"post_title"}
                      value={values.post_title}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      isRequired={true}
                    />
                  </div>
                  {type == "edit" && (
                    <div className="p-3">
                      <FormControl
                        controlId={"slug"}
                        label={
                          "Post URL (Min 35 Characters & Max characters 110)"
                        }
                        type={"text"}
                        name={"slug"}
                        value={values.slug}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        isRequired={true}
                        disabled={true}
                      />
                    </div>
                  )}
                  <div className="p-3">
                    <FormControl
                      controlId={"short_description"}
                      label={
                        "Post Summary (Min 120 Characters & Max characters 166)"
                      }
                      as={"textarea"}
                      name={"short_description"}
                      value={values.short_description}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      rows={3}
                      isRequired={true}
                    />
                  </div>
                  {/* <div className="px-3">
                    <button
                      type="button"
                      onClick={() => {
                        setPropSelectedImage(null);
                        setOnSelectImageFn(() => handleImageInsert);
                        setMediaTabType("post");
                        setShowMediaTabModal(true);
                      }}
                      className="export-button d-flex align-items-center text-nowrap justify-content-between me-2 mb-2 text-white px-3"
                    >
                      <ReactSVG
                        height="18px"
                        width="18px"
                        src={UploadIcon}
                        className="mr-2"
                      />{" "}
                      Add Image
                    </button>

                    <FormControl
                      controlId={"short_description"}
                      label={
                        "Post Summary (Min 120 Characters & Max characters 166)"
                      }
                      as={"select"}
                      name={"short_description"}
                      value={"values.short_description"}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      rows={3}
                      isRequired={true}
                    />
                  </div> */}
                  <div className="d-flex align-items-center px-3">
                    <button
                      type="button"
                      onClick={() => {
                        setPropSelectedImage(null);
                        setOnSelectImageFn(() => handleImageInsert);
                        setMediaTabType("post");
                        setShowMediaTabModal(true);
                      }}
                      className="export-button d-flex align-items-center text-nowrap me-2 text-white px-3 h-100"
                      style={{ minHeight: "38px" }}
                    >
                      <ReactSVG
                        height="18px"
                        width="18px"
                        src={UploadIcon}
                        className="mr-2"
                      />
                      Add Image
                    </button>

                    <Form.Control
                      as="select"
                      name="post_gallery"
                      value={values.post_gallery}
                      onChange={(e) =>
                        setFieldValue("post_gallery", e.target.value)
                      }
                      className="me-2 form-select"
                      style={{ width: "180px", minHeight: "38px" }}
                    >
                      <option value="">Select Gallery</option>
                      {galleries.map((item) => (
                        <option
                          key={item.gallery_slug}
                          value={item.gallery_slug}
                        >
                          {item.gallery_name}
                        </option>
                      ))}
                    </Form.Control>

                    <button
                      type="button"
                      onClick={() => {
                        if (!values.post_gallery) return;
                        handleGalleryInsert(values.post_gallery); // Insert into Jodit Editor
                        setFieldValue("post_gallery", ""); // Reset select field
                      }}
                      className="export-button d-flex align-items-center text-nowrap text-white px-3 h-100"
                      style={{ minHeight: "38px" }}
                    >
                      Add Gallery
                    </button>
                  </div>
                  {values.thumb_type == "stories" ? (
                    <></>
                  ) : (
                    <div className="p-3" style={{ minHeight: "600px" }}>
                      <label className="form-label">Content</label>
                      {/* <CKEditorComponent/> */}

                      {/* { editorContent !== null && (
      <CKEditor
        initData={editorContent} // This will only be set initially
        value={editorContent} // Ensure that the editor displays the correct content
        onChange={(e) => {
          // Update form value whenever content changes
          setFieldValue('post_content', e.editor.getData());
        }}
      />
    )} */}

                      <JoditEditor
                        // ref={editor}
                        ref={(editor) => (joditEditor = editor)}
                        value={values.post_content}
                        onBlur={(newContent) =>
                          setFieldValue("post_content", newContent)
                        }
                        config={config}
                      />
                      <Form.Label className="fw-light d-flex justify-content-between">
                        <button
                          type="button"
                          className="mat-0 export-button mt-2"
                          onClick={(e) => {
                            setFieldValue(
                              "post_content",
                              `${values.post_content}
                         <div style="weight:100%;height:auto;">${PostProsCons[0]}</div> <br><br>`
                            );
                          }}
                        >
                          <span>Default</span>
                        </button>
                      </Form.Label>
                      <FormFieldErrorMsg
                        touched={touched}
                        errors={errors}
                        name={"post_content"}
                      />
                    </div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Card className="p-3">
              <div className="star-rating-container">
                <label>Post Rating (0 to 5)</label>
                <StarRating
                  postRating={values.post_rating}
                  setFieldValue={setFieldValue}
                />
              </div>
            </Card>
            <Card className="p-3">
              <div>
                <FormSelect
                  controlId={"thumb_type"}
                  label={"Thumbnail Type"}
                  placeholder={"Select Thumbnail Type"}
                  touched={touched}
                  errors={errors}
                  itemName={"value"}
                  name={"thumb_type"}
                  value={values["thumb_type"]}
                  setFieldValue={setFieldValue}
                  items={[
                    { id: "featured_image", value: "Featured Image" },
                    { id: "slider", value: "Slider" },
                    { id: "video", value: "Video" },
                    { id: "gallery", value: "Gallery" },
                    { id: "stories", value: "Stories" },
                  ]}
                  isRequired={true}
                />
              </div>
            </Card>

            {values.thumb_type == "stories" && (
              <Card className="p-3">
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setPropSelectedImage(null);
                      setOnSelectImageFn(() => handleAddImage);
                      setMediaTabType("stories");
                      setShowMediaTabModal(true);
                    }}
                    className="export-button d-flex align-items-center text-nowrap justify-content-between me-2 mb-2 text-white px-3"
                  >
                    <ReactSVG
                      height="18px"
                      width="18px"
                      src={UploadIcon}
                      className="mr-2"
                    />{" "}
                    Add Image
                  </button>
                </div>

                {/* Input for adding YouTube video URLs */}
                {/* <div className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="YouTube Video URL"
                    onBlur={(e) => {
                      const videoUrl = e.target.value.trim();
                      const isValidUrl =
                        /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/.test(
                          videoUrl
                        );
                      if (isValidUrl) {
                        setFieldValue("stories_images", [
                          ...values.stories_images,
                          {
                            image: videoUrl,
                            title: "",
                            description: "",
                            caption: "",
                            order: values.stories_images.length + 1,
                            type: "video", // Set type to video
                          },
                        ]);
                        e.target.value = ""; // Clear the input after adding
                      } else {
                        alert("Please enter a valid YouTube URL."); // Alert for invalid URL
                      }
                    }}
                  />
                </div> */}

                {values.stories_images?.length > 0 && (
                  <>
                    <div className="stories-image-header d-flex align-items-center mb-2 gap-2 p-2">
                      <div style={{ width: "100px" }}>Media</div>
                      <div className="post-stories-input">Title</div>
                      <div className="post-stories-input">Description</div>
                      <div className="post-stories-input">Caption</div>
                      <div className="post-stories-input">Order</div>
                      <div style={{ width: "75px" }}>Action</div>
                    </div>
                    <div
                      style={{
                        height: "1px",
                        width: "100%",
                        backgroundColor: "#ccc",
                        marginBottom: "10px",
                      }}
                    />
                  </>
                )}

                {values.stories_images?.map((media, index) => (
                  <div
                    key={`${media.image}-${index}`}
                    className="d-flex align-items-center mb-3 gap-2"
                  >
                    {media.type == "video" ? (
                      <iframe
                        src={`https://www.youtube.com/embed/${new URL(
                          media.image
                        ).searchParams.get("v")}`}
                        style={{ width: "100px", height: "100px" }}
                        title={media.title}
                        allowFullScreen
                      />
                    ) : (
                      <img
                        src={`${
                          media.image.startsWith("https://autox-gallery") ||
                          media.image.startsWith("https://autox") ||
                          media.image.startsWith("https://s3.ap-south-1")
                            ? media.image
                            : `https://images.autox.com/${media.image}`
                        }`}
                        style={{ width: "100px", height: "100px" }}
                        alt={media.alt || "story"}
                      />
                    )}
                    <Form.Control
                      type="text"
                      placeholder="Title"
                      value={media.title}
                      onChange={(e) =>
                        setFieldValue(
                          `stories_images.${index}.title`,
                          e.target.value
                        )
                      }
                      className="post-stories-input"
                    />
                    <Form.Control
                      as="textarea"
                      placeholder="Description"
                      value={media.description}
                      onChange={(e) =>
                        setFieldValue(
                          `stories_images.${index}.description`,
                          e.target.value
                        )
                      }
                      className="post-stories-input"
                    />
                    <Form.Control
                      as="textarea"
                      placeholder="Caption"
                      value={media.caption}
                      onChange={(e) =>
                        setFieldValue(
                          `stories_images.${index}.caption`,
                          e.target.value
                        )
                      }
                      className="post-stories-input"
                    />
                    <Form.Control
                      type="number"
                      placeholder="Order"
                      value={media.order}
                      onChange={(e) => {
                        const newOrder = e.target.value;
                        setFieldValue(
                          `stories_images.${index}.order`,
                          newOrder
                        );
                      }}
                      className="post-stories-input"
                    />
                    <Button
                      variant="danger"
                      onClick={() => handleRemoveStory(index)}
                      className="ms-2"
                    >
                      Remove
                    </Button>
                  </div>
                ))}

                {values.stories_images.length < 4 && (
                  <div className="text-danger">
                    At least 4 media items (images or videos) are required for
                    stories.
                  </div>
                )}
              </Card>
            )}

            {values.thumb_type == "slider" && (
              <Card className="p-3">
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setPropSelectedImage(null);
                      setOnSelectImageFn(() => handlSliderImage);
                      setMediaTabType("slider");
                      setShowMediaTabModal(true);
                    }}
                    className="export-button d-flex align-items-center text-nowrap justify-content-between me-2 mb-2 text-white px-3"
                  >
                    <ReactSVG
                      height="18px"
                      width="18px"
                      src={UploadIcon}
                      className="mr-2"
                    />{" "}
                    Add Image
                  </button>
                </div>

                {values.slider_images?.length > 0 && (
                  <>
                    <div className="slider-image-header d-flex align-items-center mb-2 gap-2 p-2">
                      <div style={{ width: "100px" }}>Image</div>
                      <div className="post-slider-input">Caption</div>
                      <div className="post-slider-input">Alt Text</div>
                      <div className="post-slider-input">Order</div>
                      <div style={{ width: "75px" }}>Action</div>
                    </div>
                    <div
                      style={{
                        height: "1px",
                        width: "100%",
                        backgroundColor: "#ccc",
                        marginBottom: "10px",
                      }}
                    />
                  </>
                )}

                {values.slider_images?.map((image, index) => {
                  const imageId = image?.id; // Extracting the image ID
                  const order = index + 1; // The order is based on the index + 1
                  const caption = image?.caption || ""; // The caption, defaulting to an empty string if not present

                  // Create the desired format: [id, order, caption]
                  const imageFormat = [imageId, order, caption];

                  // You can use imageFormat for any other logic you need (like submitting, saving, etc.)

                  return (
                    <div
                      key={`${image}-${index}`}
                      className="d-flex align-items-center mb-3 gap-2"
                    >
                      <img
                        src={`${
                          image?.image.startsWith("https://autox-gallery") ||
                          image?.image.startsWith("https://autox") ||
                          image?.image.startsWith("https://s3.ap-south-1")
                            ? image?.image
                            : `https://images.autox.com/${image?.image}`
                        }`}
                        style={{ width: "100px", height: "100px" }}
                        alt={image?.alt || "slider"}
                      />
                      <Form.Control
                        as="textarea"
                        placeholder="Caption"
                        defaultValue={image?.caption}
                        onChange={(e) =>
                          setFieldValue(
                            `slider_images.${index}.caption`,
                            e.target.value
                          )
                        }
                        className="post-slider-input"
                      />
                      <Form.Control
                        as="textarea"
                        placeholder="Alt Text"
                        value={image?.title}
                        onChange={(e) =>
                          setFieldValue(
                            `slider_images.${index}.alt`,
                            e.target.value
                          )
                        }
                        className="post-slider-input"
                      />
                      <Form.Control
                        type="number"
                        placeholder="Order"
                        value={index + 1}
                        onChange={(e) =>
                          setFieldValue(
                            `slider_images.${index}.order`,
                            e.target.value
                          )
                        }
                        className="post-slider-input"
                      />
                      <Button
                        variant="danger"
                        onClick={() => handleRemoveImage(index)}
                        className="ms-2"
                      >
                        Remove
                      </Button>
                    </div>
                  );
                })}
              </Card>
            )}

            {values.thumb_type == "video" && (
              <Card className="p-3">
                <div className="mb-3">
                  <Form.Control
                    type="text"
                    value={values.thumb_value}
                    placeholder="YouTube Video URL"
                    onChange={(e) => {
                      const videoUrl = e.target.value.trim();

                      setFieldValue("thumb_value", videoUrl);
                    }}
                  />
                </div>
              </Card>
            )}

            {values.thumb_type == "gallery" && (
              <Card className="p-3">
                <div>
                  <Form.Control
                    type="text"
                    placeholder="Add Gallery shortcode"
                    value={values.thumb_value}
                    onChange={(e) => {
                      const shortcode = e.target.trim();
                      setFieldValue("thumb_value", shortcode);
                    }}
                  />
                </div>
              </Card>
            )}
            <Accordion defaultActiveKey={null} className="mb-4">
              <Accordion.Item
                eventKey="0"
                className="card-height header_padding_top pb-4"
              >
                <Accordion.Header className="p-3 pb-2 ps-2 pt-4">
                  SEO Content
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <FormControl
                      controlId={"seo_title"}
                      label={"SEO Title"}
                      type={"text"}
                      name={"seo_title"}
                      value={values.seo_title}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      errors={errors}
                    />

                    <FormControl
                      controlId={"seo_description"}
                      label={"SEO Description"}
                      as={"textarea"}
                      name={"seo_description"}
                      value={values.seo_description}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      rows={3}
                    />

                    <FormControl
                      controlId={"meta_keywords"}
                      label={"Meta Keywords"}
                      type={"text"}
                      name={"meta_keywords"}
                      value={values.meta_keywords}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      errors={errors}
                    />

                    <FormControl
                      controlId={"news_keyword"}
                      label={"News Keywords"}
                      type={"text"}
                      name={"news_keyword"}
                      value={values.news_keyword}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      errors={errors}
                    />

                    <FormControl
                      controlId={"cannonical"}
                      label={"Canonical URL"}
                      type={"text"}
                      name={"cannonical"}
                      value={values.cannonical}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      errors={errors}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {values.revisions && values.revisions.length > 0 ? (
              <Card className="p-3">
                <HeadingTwo
                  title={"Revisions"}
                  type="dashboard"
                  showHeader={true}
                  pointerOnHover={true}
                />
                <div>
                  {values.revisions.map((revision, index) => (
                    <div
                      key={`${revision?.id}-${index}`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <img
                        src={
                          revision?.author_image?.startsWith("https://")
                            ? revision?.author_image
                            : `https://images.autox.com/${revision?.author_image}`
                        }
                        width={40}
                        height={40}
                        alt="author"
                        style={{ borderRadius: "10%", marginRight: "10px" }}
                      />
                      <span style={{ fontWeight: "500" }}>
                        {revision?.author_display_name},&nbsp;
                      </span>{" "}
                      <span>
                        {convertTime(revision?.time_elapsed)} (
                        {new Date(revision?.revision_date)
                          .toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })
                          .slice(0, 19)
                          .replace("T", " ")}
                        )
                      </span>
                    </div>
                  ))}
                </div>
              </Card>
            ) : null}
          </div>
          <div className="post-card-2">
            <Card className="p-3">
              <HeadingTwo
                title={"Publish"}
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />

              {type == "edit" && (
                <>
                  <Button
                    className={`mb-1 ${
                      values.status == 1
                        ? "btn-success"
                        : values.status == 2
                        ? "btn-danger"
                        : "btn-warning"
                    }`}
                    // href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/${
                    //   values.parent_category_slug ? `${values.parent_category_slug}/` : ''
                    // }${values.category_slug}/${values.slug}`}
                    target="_blank"
                    disabled={true}
                  >
                    {values.status == 1
                      ? "Live"
                      : values.status == 2
                      ? "Scheduled"
                      : values.status == 0
                      ? "Draft"
                      : ""}
                  </Button>
                  <Button
                    className="btn-secondary"
                    href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/${
                      values.parent_category_slug
                        ? `${values.parent_category_slug}/`
                        : ""
                    }${values.category_slug}/${values.slug}`}
                    target="_blank"
                    // onClick={() => handleSubmitDraft()}
                  >
                    Preview
                  </Button>
                </>
              )}
              <div className="row p-3">
                <div className="row checkbox-container mb-2">
                  <Form.Check
                    type="checkbox"
                    id="publish"
                    label={
                      values.status == 1 && type == "edit"
                        ? `Published on ${
                            values.publish_date
                              ? new Date(values.publish_date).toLocaleString()
                              : "N/A"
                          }`
                        : "Publish Immediately"
                    }
                    checked={values.status == 1}
                    onChange={() => setFieldValue("status", 1)}
                  />
                </div>
                <div className="row checkbox-container mb-2">
                  <Form.Check
                    type="checkbox"
                    id="schedule"
                    label="Schedule Publish"
                    checked={values.status == 2}
                    onChange={() => setFieldValue("status", 2)}
                  />
                </div>
                {values.status == 2 && (
                  <div className="w-100">
                    <ReactDatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMMM dd yyyy h:mm aa"
                      className="w-100"
                    />
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-between flex-wrap">
                <Button
                  variant="btnBlue"
                  className="d-flex align-items-center justify-content-between"
                  type="button"
                  disabled={
                    !(values.status === 1 || values.status === 2) ||
                    isSubmitting
                  } // Disable if submitting
                  onClick={() => handleSubmitButton()}
                >
                  {type == "add" ? "Publish" : "Update"}
                </Button>
                <Button
                  type="submit"
                  id="submit-btn"
                  className="btn-secondary d-none"
                  disabled={isSubmitting} // Disable when submitting
                >
                  submit
                </Button>
                <Button
                  type=""
                  className="btn-secondary"
                  disabled={isSubmitting} // Disable when submitting
                  onClick={() => {
                    setIsDraft(true); // Mark as draft
                    setFieldValue("status", 0);
                    handleSubmit(); // Trigger the form submission
                  }}
                >
                  Save Draft
                </Button>
              </div>
            </Card>
            <Accordion defaultActiveKey={null} className="mb-4">
              <Accordion.Item
                eventKey="0"
                className="card-height header_padding_top pb-4"
              >
                <Accordion.Header className="p-3 pb-2 ps-2 pt-4">
                  Category List
                </Accordion.Header>
                <Accordion.Body>
                  <NestedCategoryList
                    categories={categories}
                    values={values}
                    setFieldValue={setFieldValue}
                    fieldName="category_id" // Specify the field name
                  />
                  <FormFieldErrorMsg
                    touched={touched}
                    errors={errors}
                    name={"category_id"}
                  />
                  {/* <FormSelect
                controlId={"post_status"}
                // label={"Post Status"}
                placeholder={"Select Post Status"}
                touched={touched}
                errors={errors}
                itemName={"value"}
                name={"post_status"}
                value={values.post_status}
                setFieldValue={setFieldValue}
                items={[
                  { id: "draft", value: "Draft" },
                  { id: "publish", value: "Publish" },
                ]}
                // isRequired={true}
              /> */}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Card className="p-3">
              <HeadingTwo
                title={"Item Reviewed"}
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />
              <div>
                <FormControl
                  controlId={"item_reviewed"}
                  placeholder={"e.g Honda Amaze"}
                  type={"text"}
                  name={"item_reviewed"}
                  value={values.item_reviewed}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  errors={errors}
                />
                <div
                  style={{
                    fontSize: "12px",
                    color: "gray",
                    marginTop: "10px",
                  }}
                >
                  Review Section Only
                </div>
              </div>
            </Card>
            <Card className="p-3">
              <HeadingTwo
                title={"Post On LinkedIn"}
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />
              <Form.Check
                type="checkbox"
                label="Post On LinkedIn"
                name="post_on_linkedin"
                checked={values.post_on_linkedin}
                onChange={(e) =>
                  setFieldValue("post_on_linkedin", e.target.checked)
                }
                className="custom-checkbox"
              />
            </Card>

            <Card className="p-3">
              <HeadingTwo
                title={"Push Notification"}
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />
              <Form.Check
                type="checkbox"
                label="Push Notification"
                name="push_notification"
                checked={values.push_notification}
                onChange={(e) =>
                  setFieldValue("push_notification", e.target.checked)
                }
                className="custom-checkbox"
              />
            </Card>
            <CarBikeLink
              errors={errors}
              touched={touched}
              values={values}
              setFieldValue={setFieldValue}
              handleSubmit={handleSubmit}
              For
              type={types}
            />
            <Card className="p-3">
              <HeadingTwo
                title={"Tags"}
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />
              <TagInput
                name="tags"
                values={values.tags || []}
                setFieldValue={setFieldValue}
              />
              <FormFieldErrorMsg
                touched={touched}
                errors={errors}
                name="tags"
              />
            </Card>
            <Card className="p-3">
              <HeadingTwo
                title={" Display Category"}
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />
              <DisplayNestedCategoryList
                categories={categories}
                values={values.display_category}
                setFieldValue={setFieldValue}
                fieldName="display_category" // Specify the field name
              />
            </Card>
            {(adminType === "editor admin" || adminType === "super admin") && (
              <Card className="p-3">
                <HeadingTwo
                  title={"Author"}
                  type="dashboard"
                  showHeader={true}
                  pointerOnHover={true}
                />
                <FormSelect
                  controlId={"author"}
                  label={"Author"}
                  placeholder={"Select Author"}
                  touched={touched}
                  errors={errors}
                  itemName={"display_name"}
                  name={"author_id"}
                  setFieldValue={setFieldValue}
                  value={values.author_id}
                  items={author}
                  showDefault={true}
                  defaultName={"Select Author"}
                />
              </Card>
            )}

            <Card className="p-3">
              <HeadingTwo
                title={"Photography By"}
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />
              <FormSelect
                controlId={"photography_id"}
                label={"Photography By"}
                placeholder={"Select Photography By"}
                touched={touched}
                errors={errors}
                itemName={"display_name"}
                name={"photography_id"}
                setFieldValue={setFieldValue}
                value={values.photography_id}
                items={photographer}
                // defaultName={"Select Photography By"}
              />
            </Card>
            <Card className="p-3">
              <HeadingTwo
                title={"Featured Image"}
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />

              {values.featured_image.length > 0 && (
                <img
                  src={
                    values.featured_image.startsWith("https://autox") ||
                    values.featured_image.startsWith("https://autox-gallery") ||
                    values.featured_image.startsWith("https://s3.ap-south-1") ||
                    values.featured_image.startsWith(
                      "https://static.autox.com"
                    ) ||
                    values.featured_image.startsWith(
                      "https://images.autox.com/"
                    )
                      ? values.featured_image.startsWith("https://autox.com")
                        ? values.featured_image.replace(
                            "https://autox.com",
                            "https://images.autox.com"
                          )
                        : values.featured_image
                      : `https://images.autox.com/${values.featured_image}`
                  }
                  alt="featured image"
                  className="w-100"
                  onClick={() => {
                    setMediaTabType("featured");
                    setPropSelectedImage(
                      values.featured_image.startsWith("https://autox") ||
                        values.featured_image.startsWith(
                          "https://autox-gallery"
                        ) ||
                        values.featured_image.startsWith(
                          "https://s3.ap-south-1"
                        )
                        ? values.featured_image
                        : `https://images.autox.com/${values.featured_image}`
                    );
                    setShowMediaTabModal(true);
                  }}
                />
              )}

              {values.featured_image.length > 0 && (
                <span
                  className="mt-1 text-primary text-decoration-none hover:text-decoration-underline"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setFieldValue("featured_image", "");
                  }}
                >
                  Remove Featured Image
                </span>
              )}
              <div className="mt-3 px-3">
                <button
                  type="button"
                  onClick={() => {
                    setPropSelectedImage(null);
                    setOnSelectImageFn(() => handleFeaturedImage);
                    setMediaTabType("featured");
                    setShowMediaTabModal(true);
                  }}
                  className="export-button d-flex align-items-center text-nowrap justify-content-between me-2 mb-2 text-white px-3"
                >
                  <ReactSVG
                    height="18px"
                    width="18px"
                    src={UploadIcon}
                    className="mr-2"
                  />{" "}
                  Add Image
                </button>
              </div>
              <span>Size Max 500 Kb & Dimension: 1280 x 720</span>
              <FormFieldErrorMsg
                touched={touched}
                errors={errors}
                name={"featured_image"}
              />
            </Card>
            <Card className="p-3">
              <HeadingTwo
                title={"Slideshow"}
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />
              <Form.Check
                type="checkbox"
                label="Slideshow"
                name="slideshow"
                checked={values.slider}
                onChange={(e) => setFieldValue("slider", e.target.checked)}
                className="custom-checkbox-lg"
              />
            </Card>
          </div>
        </div>
        <MediaTab
          propShow={showMediaTabModal}
          propSetShow={setShowMediaTabModal}
          propSelectedImage={propSelectedImage}
          onSelectImage={onSelectImageFn}
          type={mediaTabType}
          defaultActiveKey="library"
        />
      </form>
    </>
  );
}
