import axios from "axios";
import {
  ADD_CAR_DEALER,
  BASE_URL_AUTOX,
  DELETE_CAR_DEALER_BY_ID,
  EDIT_CAR_DEALER,
  GET_CAR_DEALER_BY_ID,
  GET_CAR_DEALER_LIST,
  getConfig,
} from "../../../request";
import { notifyError } from "../../../utility/common";

/**
 * Get List of Car Dealers
 * getCardDealerList is used to get List of Car Dealers
 * @param token
 * @param payload
 */
export function getCardDealerList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_CAR_DEALER_LIST}`,
    payload,
    getConfig(token)
  );
}

export function addCarDealer(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${ADD_CAR_DEALER}`,
    payload,
    getConfig(token)
  );
}

export function getCarDealerById(token, id) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_CAR_DEALER_BY_ID}${id}`,
    getConfig(token)
  );
}

export function editCarDealer(token, payload, id) {
  return axios.put(
    `${BASE_URL_AUTOX}${EDIT_CAR_DEALER}${id}`,
    payload,
    getConfig(token)
  );
}

export function deleteCarDealerById(token, id) {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_CAR_DEALER_BY_ID}${id}`,
    getConfig(token)
  );
}

export function addCarDealerImport(data) {
  return axios.post(`${BASE_URL_AUTOX}car/dealer/importDealer`, {
    data: data,
  });
}

export function addBikeDealerImport(data) {
  return axios.post(`${BASE_URL_AUTOX}new-bike/dealer/importDealer`, {
    data: data,
  });
}
export async function downloadDealerSample() {
  try {
    const res = await axios.post(
      `${BASE_URL_AUTOX}car/dealer/downloadSample`,
      {}
    );
    console.log(res.data.url);
    if (res.data.url) {
      window.open(res.data.url, "_blank");
    }
  } catch (error) {
    notifyError(error.message);
  }
}

export async function downloadDealer(token, payload) {
  return await axios.post(
    `${BASE_URL_AUTOX}car/dealer/export-dealer`,
    payload,
    getConfig(token)
  );
}

export default {
  getCardDealerList,
  addCarDealer,
  getCarDealerById,
  editCarDealer,
  deleteCarDealerById,
  addCarDealerImport,
  downloadDealerSample,
};
