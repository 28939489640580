import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../utility/constants";
import AdPageAPI from "../adPage/adPageAPI";
import HeadingTwo from "../../../components/HeadingTwo";
import { Card, Form } from "react-bootstrap";

export default function CategoryAdsSelect({
  categoryList,
  setSelectedCategorySlug,
  selectedCategorySlug
}) {
  return (
    <div className="row px-3">
      <Card className="p-3 mb-3">
        <HeadingTwo
          title="Category Ads"
          type="dashboard"
          showHeader={true}
          pointerOnHover={true}
        />

        <Form.Group className="mb-3" controlId="filterType">
          <div className="d-flex justify-content-between w-100 gap-4 align-items-end">
            <div className="AdPage-select">
              <Form.Label className="mb-2">Select Ad Category</Form.Label>
              <Form.Select
                aria-label="Default select example"
                // onChange={handleAdPageChange}
                onChange={(e) => setSelectedCategorySlug(e.target.value)}
                value={selectedCategorySlug}
              >
                {categoryList.map((item, index) => (
                  <option key={index} value={item.template_slug}>
                    {item.template_slug}
                  </option>
                ))}
              </Form.Select>
            </div>
            {/* <button
            className="version-go-button m-0 p-0"
            onClick={getAdPageWidgets}
          >
            Go
          </button> */}
          </div>
        </Form.Group>
      </Card>
    </div>
  );
}
