import DownloadIcon from "../assets/icons/bootstrap-icons/button-icons/download white.svg";
import { ReactSVG } from "react-svg";
import { getToken } from "../utility/constants";
import { notifyError } from "../utility/common";

export default function DownloadCSVversion({
  api,
  filename = "export-data.csv",
}) {
  const token = getToken();

  const handleCSVDownload = async () => {
    try {
      const response = await api(token);
      console.log(response, "response");
      if (response.data.url) {
        window.open(response.data.url, "_blank");
      }
      // // Create a Blob from the CSV string and download it
      // const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
      // const link = document.createElement("a");
      // const url = URL.createObjectURL(blob);

      // link.setAttribute("href", url);
      // link.setAttribute("download", filename);
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);

      // // Cleanup the object URL
      // URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
      notifyError(error.response?.data?.message || "Error downloading file");
    }
  };

  return (
    <button
      className="download-variants-button d-flex align-items-center justify-content-between me-2  text-white px-3"
      onClick={handleCSVDownload}
    >
      <ReactSVG
        src={DownloadIcon}
        height="18px"
        width="18px"
        className="mr-2"
      />
      Download Varients
    </button>
  );
}
