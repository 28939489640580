import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import JoditEditor from "jodit-react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import {
  getCarBrandList,
  getModelListingById,
  addCarUserReview,
} from "../CarUserController";
import CarReviewSchema from "./CarReviewValidation";
import DynamicSelect from "./DynamicSelect";
import FormControl from "../../../components/FormControl";
import HeadingTwo from "../../../components/HeadingTwo";
import { getToken, renderDateNumerical } from "../../../utility/constants";
import FormFieldErrorMsg from "../../../components/FormFieldErrorMsg";
import ReactDatePicker from "react-datepicker";
import { getVersionsByModelId } from "../../../request";
import FormControlNumber from "../../../components/FormControlNumber";

const AddForm = () => {
  const [brandList, setBrandList] = useState([]);
  const [carModelList, setCarModelList] = useState([]);
  const [ipAddress, setIpAddress] = useState(null);
  const [brandId, setBrandId] = useState(null);
  const [versionList, setVersionList] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const token = getToken();
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const editor = useRef(null);
  console.log(id, "id");

  useEffect(() => {
    setBrandId(searchParams.get("brand_id"));
    const getUserIpAddress = async () => {
      const res = await axios.get("https://api.ipify.org/?format=text");
      setIpAddress(res.data.ip);
    };
    const fetchBrands = async () => {
      try {
        const brandRes = await getCarBrandList({ sortBy: "", sortOrder: "" });
        setBrandList(brandRes.result);
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    fetchBrands();
    getUserIpAddress();
  }, []);

  useEffect(() => {
    const fetchAllCarModels = async () => {
      try {
        console.log("i am called");

        const carModelRes = await getModelListingById(
          { brand_id: brandId, sortBy: "", sortOrder: "" },
          token
        );
        console.log(carModelRes, "carModelRes");
        setCarModelList(carModelRes.result);
      } catch (error) {
        console.error("Error fetching car models:", error);
      }
    };

    fetchAllCarModels();
  }, [brandId]);

  const { handleSubmit, values, errors, touched, setFieldValue } = useFormik({
    initialValues: {
      name: "",
      emailid: "",
      model_id: parseInt(id),
      version_id: "",
      design_styling: "-1",
      comfort_space: "-1",
      performance: "-1",
      fuel_economy: "-1",
      value_for_money: "-1",
      features: "-1",
      safety: "-1",
      maintenance_cost: "-1",
      title: "",
      review: "",
      rating: "",
      kms_driven: "0",
      mileage: "",
      pincode: "",
      status: "",
      ip_address: "",
      brandId: parseInt(brandId),
      created_date: new Date().toISOString().slice(0, 10),
    },

    validationSchema: CarReviewSchema,
    onSubmit: async (values, action) => {
      try {
        const payload = {
          ...values,
          ip_address: ipAddress || "121.121.121.121",
        };
        const keysToRemove = ["day", "month", "year"];
        keysToRemove.forEach((key) => delete payload[key]);

        if (payload.review === "<p><br></p>") {
          toast.error("Please add review");
          return;
        }

        const res = await addCarUserReview(payload, token);
        if (res.status === 200) {
          toast.success("Review submitted successfully");
          setTimeout(() => {
            navigate("/car-user-review");
          }, 1500);
        }
      } catch (error) {
        console.error("Error submitting review:", error);
        action.setErrors(error.response.data);
        toast.error(error.response.data.message);
      }
    },
  });

  useEffect(() => {
    const versionByModel = async () => {
      console.log("I am called");
      try {
        const versionList = await getVersionsByModelId(values.model_id, token);
        console.log(versionList, "versionList");
        setVersionList(versionList.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    versionByModel();
  }, [values.model_id]);

  const handleBrandChange = (e) => {
    const selectedBrandId = e.target.value;
    setBrandId(selectedBrandId);
    // setFieldValue("model_id", "");
  };
  const handleModelChange = (e) => {
    const selectedModel = e.target.value;
    setFieldValue("model_id", selectedModel);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="d-flex card py-4">
          <div className="card-headers w-100 d-flex p-3 align-items-center justify-content-between">
            <div>
              <HeadingTwo
                title="Car Review Form"
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />
            </div>
          </div>
          <div className="col-12 mt-3 d-flex flex-wrap">
            <div className="col-lg-6 p-3">
              <label htmlFor={id} className="form-label">
                Car Brand
              </label>
              <select
                className={`form-select border border-2 shadow-none ${
                  touched[id] && errors[id] ? "is-invalid" : ""
                }`}
                id={id}
                onChange={handleBrandChange}
                value={brandId}
              >
                <option>Select Brand</option>
                {brandList.map((brand) => (
                  <option key={brand.id} value={brand.id}>
                    {brand.brand_name}
                  </option>
                ))}
              </select>
              {touched[id] && errors[id] && (
                <div className="invalid-feedback">{errors[id]}</div>
              )}
            </div>
            <DynamicSelect
              label="Car Model"
              options={carModelList}
              id="model_id"
              name="model_name"
              values={values}
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
              checkedValue={id}
            />
            <DynamicSelect
              label="Model Version"
              options={versionList}
              id={"version_id"}
              name="version_name"
              values={values}
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
            />
            <DynamicSelect
              label="Design & Styling"
              options={[
                { id: 1, design_styling: 5 },
                { id: 2, design_styling: 4 },
                { id: 3, design_styling: 3 },
                { id: 4, design_styling: 2 },
                { id: 5, design_styling: 1 },
              ]}
              id={"design_styling"}
              name="design_styling"
              values={values}
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
            />
            <DynamicSelect
              label="Comfort & Space"
              options={[
                { id: 1, comfort_space: 5 },
                { id: 2, comfort_space: 4 },
                { id: 3, comfort_space: 3 },
                { id: 4, comfort_space: 2 },
                { id: 5, comfort_space: 1 },
              ]}
              id={"comfort_space"}
              name="comfort_space"
              values={values}
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
            />
            <DynamicSelect
              label="Performance"
              options={[
                { id: 1, performance: 5 },
                { id: 2, performance: 4 },
                { id: 3, performance: 3 },
                { id: 4, performance: 2 },
                { id: 5, performance: 1 },
              ]}
              id={"performance"}
              name="performance"
              values={values}
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
            />
            <DynamicSelect
              label="Fuel Economy"
              options={[
                { id: 1, fuel_economy: 5 },
                { id: 2, fuel_economy: 4 },
                { id: 3, fuel_economy: 3 },
                { id: 4, fuel_economy: 2 },
                { id: 5, fuel_economy: 1 },
              ]}
              id={"fuel_economy"}
              name="fuel_economy"
              values={values}
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
            />
            <DynamicSelect
              label="Value for Money"
              options={[
                { id: 1, value_for_money: 5 },
                { id: 2, value_for_money: 4 },
                { id: 3, value_for_money: 3 },
                { id: 4, value_for_money: 2 },
                { id: 5, value_for_money: 1 },
              ]}
              id={"value_for_money"}
              name="value_for_money"
              values={values}
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
            />
            <DynamicSelect
              label="Features"
              options={[
                { id: 1, features: 5 },
                { id: 2, features: 4 },
                { id: 3, features: 3 },
                { id: 4, features: 2 },
                { id: 5, features: 1 },
              ]}
              id={"features"}
              name="features"
              values={values}
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
            />
            <DynamicSelect
              label="Safety"
              options={[
                { id: 1, safety: 5 },
                { id: 2, safety: 4 },
                { id: 3, safety: 3 },
                { id: 4, safety: 2 },
                { id: 5, safety: 1 },
              ]}
              id={"safety"}
              name="safety"
              values={values}
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
            />{" "}
            <DynamicSelect
              label="Rating"
              options={[
                { id: 1, rating: 5 },
                { id: 2, rating: 4 },
                { id: 3, rating: 3 },
                { id: 4, rating: 2 },
                { id: 5, rating: 1 },
              ]}
              id="rating"
              values={values.rating}
              name="rating"
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
            />
            <DynamicSelect
              label="Maintenance Cost"
              options={[
                { id: 1, maintenance_cost: 5 },
                { id: 2, maintenance_cost: 4 },
                { id: 3, maintenance_cost: 3 },
                { id: 4, maintenance_cost: 2 },
                { id: 5, maintenance_cost: 1 },
              ]}
              id="maintenance_cost"
              values={values.maintenance_cost}
              name="maintenance_cost"
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
            />
            <div className="col-lg-6 p-3">
              <FormControl
                controlId="KmsDriven"
                label="KMs Driven"
                type="text"
                touched={touched}
                errors={errors}
                name="kms_driven"
                value={values.kms_driven}
                className={"form-control border border-2 shadow-none"}
                setFieldValue={setFieldValue}
              />
            </div>
            <div className="col-lg-6 p-3">
              <FormControl
                controlId="Mileage"
                label="Mileage"
                type="text"
                touched={touched}
                errors={errors}
                name="mileage"
                value={values.mileage}
                className={"form-control border border-2 shadow-none"}
                setFieldValue={setFieldValue}
              />
            </div>
            <div className="col-lg-6 p-3">
              <FormControlNumber
                controlId="PinCode"
                label="Pin Code"
                touched={touched}
                errors={errors}
                name={"pincode"}
                value={values.pincode}
                className={"form-control border border-2 shadow-none"}
                setFieldValue={setFieldValue}
              />
            </div>
            <div className="col-lg-6 p-3">
              <FormControl
                controlId="userName"
                label="User Name"
                type="text"
                touched={touched}
                errors={errors}
                name="name"
                className={"form-control border border-2 shadow-none"}
                value={values.name}
                setFieldValue={setFieldValue}
              />
            </div>
            <div className="col-lg-6 p-3">
              <FormControl
                controlId="emailId"
                label="Email ID"
                type="text"
                touched={touched}
                errors={errors}
                name="emailid"
                value={values.emailid}
                className={"form-control border border-2 shadow-none"}
                setFieldValue={setFieldValue}
              />
            </div>
            <div className="col-lg-6 p-3">
              <FormControl
                controlId="title"
                label="Title"
                type="text"
                touched={touched}
                errors={errors}
                name="title"
                value={values.title}
                className={"form-control border border-2 shadow-none"}
                setFieldValue={setFieldValue}
              />
            </div>
            <FormFieldErrorMsg touched={touched} errors={errors} name="title" />
            <div className="w-100 p-3">
              <label htmlFor="review" className="form-label">
                Review<sup className="required-superscript">*</sup>
              </label>
              <JoditEditor
                ref={editor}
                value={values.review}
                onChange={(newContent) => setFieldValue("review", newContent)}
              />
              <FormFieldErrorMsg
                touched={touched}
                errors={errors}
                name="review"
              />
            </div>
            <div className="w-100">
              <div className="col-lg-6 p-3">
                <label htmlFor="title" className="form-label">
                  Status
                </label>
                <select
                  className="form-select border border-2 shadow-none"
                  onChange={(e) => setFieldValue("status", e.target.value)}
                >
                  <option value="" disabled>
                    Select Status
                  </option>
                  <option value="1">Approve</option>
                  <option value="0">Pending</option>
                  <option value="2">Rejected</option>
                </select>
                <FormFieldErrorMsg
                  touched={touched}
                  errors={errors}
                  name="status"
                />
              </div>
              <div className="col-lg-6 p-3">
                <Form.Label>
                  Date <sup className="text-danger">*</sup> (dd/mm/yyyy)
                </Form.Label>
                <ReactDatePicker
                  selected={values.created_date}
                  onChange={(date) =>
                    setFieldValue(
                      "created_date",
                      `${renderDateNumerical(date)}T00:00:00.000Z`
                    )
                  }
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"
                  className="form-control border border-2 shadow-none"
                />
              </div>
              <button type="submit" className="green-filled-button mx-3">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </>
  );
};

export default AddForm;
