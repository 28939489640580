import { Card, Form } from "react-bootstrap";
import Breadcrumbs from "../../Breadcrumbs";
import HeadingTwo from "../../components/HeadingTwo";
import apiHandler from "./apiHandler";
import { useEffect, useState } from "react";
import { getToken } from "../../utility/constants";
import ReactDatePicker from "react-datepicker";
import { useParams } from "react-router-dom";

export default function ViewNewsCampaign() {
  const [campaign, setCampaign] = useState({
    name: "",
    subject: "",
    feed_url: "",
    template_id: "",
    utm_source: "",
    newsletter_id: "",
    status: 1,
    publish_date: new Date(),
    isRecurring: false,
    end_date: null,
    recurrence: "daily",
    recurrenceTime: new Date(),
    publish_type: "",
  });
  const [templateList, setTemplateList] = useState([]);
  const [newsletter, setNewsletter] = useState([]);
  const [templateRender, setTemplateRender] = useState("");
  const [loading, setLoading] = useState(false);
  const token = getToken();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // Fetch campaign details by ID
        const campaignResponse = await apiHandler.getNewsCampaignById(
          id,
          token
        );
        console.log("campaignResponse", campaignResponse);

        // Destructure response data
        const { campaign, newsletterIds, schedule } = campaignResponse.data;

        // Fetch template and newsletter lists
        const templateResponse = await apiHandler.getTemplateList(token);
        const newsletterResponse = await apiHandler.getNewsletterList(token);

        // Fetch template preview if template_id exists
        if (campaign.template_id) {
          const templatePreviewResponse = await apiHandler.getTemplateBodyById(
            campaign.template_id,
            token
          );
          setTemplateRender(templatePreviewResponse.data[0].html_body);
        }

        // Process dates from the schedule
        const startDate = schedule
          ? new Date(`${schedule.start_date}T${schedule.schedule_time}`)
          : new Date();
        const endDate =
          schedule && schedule.end_date ? new Date(schedule.end_date) : null;
        const recurrenceTime = schedule
          ? new Date(`1970-01-01T${schedule.schedule_time}`)
          : new Date();

        // Combine campaign data with schedule information
        const processedCampaign = {
          ...campaign,
          newsletter_id: newsletterIds[0], // Use the first newsletter ID
          publish_date: startDate,
          end_date: endDate,
          recurrenceTime: recurrenceTime,
          recurrence: schedule ? schedule.schedule_type : "daily",
          isRecurring: schedule ? schedule.is_recurring === 1 : false,
          status: campaign.status, // Use status from campaign
        };

        // Update state
        setCampaign(processedCampaign);
        setTemplateList(templateResponse.data);
        setNewsletter(newsletterResponse.data);
      } catch (error) {
        console.error("Error fetching campaign details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, token]);

  return (
    <>
      <Breadcrumbs />
      {loading ? (
        <div className="text-center p-5">Loading campaign details...</div>
      ) : (
        <div className="d-flex gap-3" style={{ height: "100%" }}>
          <div className="d-flex flex-column gap-4" style={{ width: "30%" }}>
            <Card className="p-3">
              <HeadingTwo title={"Primary Details"} />
              <div>
                <Form.Group className="mb-3">
                  <Form.Label>NewsCampaign Name</Form.Label>
                  <Form.Control type="text" value={campaign.name} disabled />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control type="text" value={campaign.subject} disabled />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Feed Url</Form.Label>
                  <Form.Control
                    type="text"
                    value={campaign.feed_url}
                    disabled
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Template</Form.Label>
                  <Form.Control
                    as="select"
                    value={campaign.template_id}
                    disabled
                  >
                    <option value="">Select Template</option>
                    {templateList.map((template) => (
                      <option
                        key={template.template_id}
                        value={template.template_id}
                      >
                        {template.template_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Utm Source</Form.Label>
                  <Form.Control
                    type="text"
                    value={campaign.utm_source}
                    disabled
                  />
                </Form.Group>
              </div>
            </Card>
            <Card className="p-3">
              <HeadingTwo title={"Contact List"} />
              <div className="p-3">
                {newsletter.map((item) => (
                  <div
                    className="checkbox-container mb-2"
                    key={item.newsletter_id}
                  >
                    <Form.Check
                      type="checkbox"
                      id={`newsletter-${item.newsletter_id}`}
                      label={`${item.name} (${item.subscription_count})`}
                      checked={campaign.newsletter_id === item.newsletter_id}
                      disabled
                    />
                  </div>
                ))}
              </div>
            </Card>
            <Card className="p-3">
              <HeadingTwo title={"Timing"} />
              <div className="row p-3">
                <div className="row checkbox-container mb-2">
                  <Form.Check
                    type="radio"
                    id="send-immediately"
                    label="Send Immediately"
                    checked={campaign.status === 1}
                    disabled
                  />
                </div>
                <div className="row checkbox-container mb-2">
                  <Form.Check
                    type="radio"
                    id="schedule"
                    label="Schedule Publish"
                    checked={campaign.status === 2}
                    disabled
                  />
                </div>
                {campaign.status === 2 && (
                  <div>
                    <Form.Check
                      type="radio"
                      id="one-time"
                      label="One Time"
                      checked={!campaign.isRecurring}
                      disabled
                    />
                    <ReactDatePicker
                      selected={!campaign.isRecurring && campaign.publish_date}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMMM dd yyyy h:mm aa"
                      className="w-100 mb-2"
                      disabled
                    />
                    <Form.Check
                      type="radio"
                      id="recurring"
                      label="Recurring"
                      checked={campaign.isRecurring}
                      disabled
                    />
                    {campaign.isRecurring && (
                      <div>
                        <ReactDatePicker
                          selected={campaign.publish_date}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          dateFormat="MMMM dd yyyy h:mm aa"
                          className="w-100 mb-2"
                          disabled
                        />
                        <ReactDatePicker
                          selected={campaign.end_date}
                          placeholderText="End Date"
                          className="w-100 mb-2"
                          disabled
                        />
                        <Form.Check
                          type="checkbox"
                          id="never-ends"
                          label="It never ends"
                          checked={!campaign.end_date}
                          disabled
                        />
                        <Form.Group>
                          <Form.Label>Recurrence</Form.Label>
                          <Form.Control
                            as="select"
                            value={campaign.recurrence}
                            disabled
                          >
                            <option value="daily">Daily</option>
                            <option value="weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                          </Form.Control>
                        </Form.Group>
                        <ReactDatePicker
                          selected={campaign.recurrenceTime}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          dateFormat="h:mm aa"
                          className="w-100"
                          disabled
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Card>
          </div>
          <div className="flex-fill" style={{ width: "70%" }}>
            <Card className="p-3">
              <HeadingTwo title={"Template Preview"} />
              <div
                className="template-preview"
                dangerouslySetInnerHTML={{ __html: templateRender }}
              />
            </Card>
          </div>
        </div>
      )}
    </>
  );
}
