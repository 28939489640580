import { version } from "react";
import * as Yup from "yup";

const deleteCarCityValidation = Yup.object({
  brand_id: Yup.number()
    .min(1, "Brand is required")
    .required("Brand is required"),
  model_id: Yup.number()
    .min(1, "Model is required")
    .required("Model is required"),
  version_id: Yup.string()
    .test(
      "valid-version-id",
      'Version is required and must be a valid number or "all_version"',
      (value) => {
        // Check if the value is "all_version"
        if (value === "all_version") return true;

        // Check if the value can be parsed into a number and is >= 1
        const parsedValue = Number(value);
        return !isNaN(parsedValue) && parsedValue >= 1;
      }
    )
    .required("Version is required"),
  city_id: Yup.string()
    .trim() // Trims any leading or trailing spaces
    // .required("City is required") // Ensures the field is not empty
    // .min(1, "City is required") // Ensures the string has at least one character (after trimming)
    .matches(
      /^(\d+,)*\d+$/,
      "City IDs must be a comma-separated list of numbers."
    ), // Ensures it matches the comma-separated numbers pattern
});

export default deleteCarCityValidation;
