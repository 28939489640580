import React, { useState } from "react";
import CarImage from "../assets/icons/bootstrap-icons/car-image.png";
import EditViewDelete from "../components/EditViewDeleteReviewPassword";
import DeleteConfirmationModalComponent from "../components/DeleteConfirmationModalComponent";
import { getToken, renderDate } from "../utility/constants";
import { carType } from "../utility/constants";
import { useNavigate } from "react-router-dom";
import { deleteBikeModelById, deleteCarModelById } from "../request";
import { toast } from "react-toastify";

export const BikeColumns = (reloadDataTable, currentPage, itemsPerPage) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const token = getToken();

  const getBikeType = (row) => {
    const type = carType.find((item) => item.id === row);
    return type ? type.value : "";
  };

  const deleteBikeModel = async (id) => {
    if (deleting) return;

    try {
      setDeleting(true);

      const res = await deleteBikeModelById(id, token);
      if (res) {
        toast.success("Bike model deleted successfully");
        reloadDataTable();
      } else {
        throw new Error("Failed to delete bike model");
      }
    } catch (error) {
      console.error("Error deleting bike model:", error);
      toast.error("Failed to delete bike model");
    } finally {
      // Reset deleting state after deletion operation
      setDeleting(false);
      setModal(false);
    }
  };

  const onDelete = (row) => {
    if (deleting) return;
    setDeleteId(row);
    setModal(true);
  };

  const onReview = (row) => {
    navigate(`/add-bike-user-review?id=${row.id}&brand_id=${row.brand_id}`);
  };

  const OnEdit = (id) => {
    navigate(`/bike-models/edit-bike-model/${id}`);
  };

  const OnView = (model) => {
    window.open(
      `${process.env.REACT_APP_FRONTEND_BASE_URL}/new-bikes/${model.brand_url}/${model.model_url}`,
      "_blank"
    );
  };
  return [
    {
      name: "ID",
      sortable: false,
      selector: (row) => row.id,
      width: "55px",
    },
    {
      name: "Model Name",
      sortable: true,
      selector: (row) => row.model_name,
      width: "140px",
    },
    {
      name: "Brand Name",
      sortable: true,
      selector: (row) => <div className="text-wrap">{row.brand_name}</div>,
      width: "140px",
    },
    {
      name: "Image",
      sortable: true,
      selector: (row) => (
        <img
          src={
            row.model_image.startsWith("https://")
              ? row.model_image
              : `https://static.autox.com/uploads/bikes/${row.model_image}`
          }
          style={{
            height: "30px",
            width: "60px",
            marginTop: "10px",
            marginLeft: "5px",
            marginBottom: "10px",
          }}
          alt="Car"
        />
      ),
      width: "125px",
    },
    {
      name: "Body Type",
      sortable: true,
      selector: (row) => row.type_name,
      width: "140px",
    },
    {
      name: "Bike Type",
      sortable: true,
      selector: (row) => {
        const carTypeClass = carType
          .find((item) => item.id == row.bike_type)
          ?.value.toLowerCase();
        return (
          <div
            className={`car-type-style px-2 pt-1 ${
              carTypeClass ? `car-type-style-${carTypeClass}` : ""
            }`}
          >
            {getBikeType(row.bike_type)}
          </div>
        );
      },
      width: "190px",
    },
    {
      name: "Price City Count",
      sortable: true,
      selector: (row) => row.starting_price,
      width: "140px",
    },
    {
      name: "Launch Date",
      sortable: true,
      selector: (row) => renderDate(row.model_date),
      width: "140px",
    },
    {
      name: "Action",
      sortable: false,
      width: "auto",
      cell: (row) => (
        <>
          <EditViewDelete
            id={row.id}
            onEdit={() => OnEdit(row.id)}
            onView={() => OnView(row)}
            onDelete={() => onDelete(row.id)}
            onReview={() => onReview(row)}
            onReviewLink={row.model_url}
          />
          <DeleteConfirmationModalComponent
            heading="Delete Bike Model"
            message="Are you sure you want to delete this bike review?"
            show={modal && deleteId === row.id}
            handleClose={() => setModal(false)}
            setDeleteConfirm={() => deleteBikeModel(row.id)}
            type="delete"
          />
        </>
      ),
    },
  ];
};
