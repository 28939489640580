import { Button, Card, ListGroup } from "react-bootstrap";
import Breadcrumbs from "../../Breadcrumbs";
import HeadingTwo from "../../components/HeadingTwo";
import { ReactSVG } from "react-svg";
import PlusLargeIcon from "../../assets/icons/bootstrap-icons/plus-lg.svg";
import Search from "../../components/SearchField";
import FilterDropdown from "../../components/FilterDropDown";
import { useCallback, useEffect, useRef, useState } from "react";
import FilterIcon from "../../assets/icons/filter2.svg";
import Datatable from "../../components/Table";
import { useNavigate } from "react-router-dom";
import apiHandler, { getPosts } from "./apiHandler";
import DeleteConfirmationModalComponent from "../../components/DeleteConfirmationModalComponent";
import { getToken, getUserId, renderDateTime } from "../../utility/constants";
import EditViewDelete from "../../components/EditViewDeleteReviewPassword";
import { notifyError, notifySuccess } from "../../utility/common";
import PostButtonGroup from "../../components/PostButtonGroup";
// import PostListingComponent from "../../components/PostListingComponent";
// import socket from "../../socket";
export default function PostManagement() {
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);
  const [search, setSearch] = useState("");
  const [postIdToDelete, setPostIdToDelete] = useState();
  const [totalRecords, setTotalRecords] = useState(0);
  const [formatedDate, setFormatedDate] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [authorList, setAuthorList] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [customSlider, setCustomSlider] = useState([]);
  const [statusList, setStatusList] = useState([
    {
      id: 0,
      name: "Draft",
    },
    {
      id: 1,
      name: "Published",
    },
    {
      id: 2,
      name: "Scheduled",
    },
    {
      id: 3,
      name: "Custom Slider",
    },
  ]);
  const admin = localStorage.getItem("admin_type");
  const [filter, setFilter] = useState({
    category_id: "",
    start_date: "",
    author_id: "",
    status: "",
    post_title: "",
    user_type: admin,
  });
  const navigate = useNavigate();
  const token = getToken();
  const handleFilterClick = () => {
    setFilterDropdown(!filterDropdown);
  };

  const onDeletePost = async () => {
    // Check the filter status
    if (filter.status == 3) {
      // If filter.status is 3, call a different API
      apiHandler
        .deleteCustomSliderById(postIdToDelete, token) // Call the different API
        .then((result) => {
          console.log(result);
          setShowDeleteModal(false);
          setReloadTable(true);
          notifySuccess("Custom Slider deleted successfully"); // Update success message accordingly
        })
        .catch((error) => {
          setShowDeleteModal(false);
          notifyError(error.message);
        });
    } else {
      // Default API call for deletion
      apiHandler
        .deletePostById(postIdToDelete, token)
        .then((result) => {
          setShowDeleteModal(false);
          setReloadTable(true);
          notifySuccess("Post deleted successfully");
        })
        .catch((error) => {
          setShowDeleteModal(false);
          notifyError(error.message);
        });
    }
  };
  const onEditPost = (id) => {
    navigate(`/post-management/edit-post?id=${id}`);
  };
  const onReview = (post) => {
    apiHandler
      .addCustomSlider(post.id, token)
      .then((result) => {
        console.log(result);
        setReloadTable(true);
        notifySuccess("Post added to custom slider successfully");
      })
      .catch((error) => {
        notifyError(error.message);
      });
  };
  const onViewPost = (values) => {
    const baseUrl = "https://www.autox.com";
    const urlPath = values.parent_category_slug
      ? `/${values.parent_category_slug}/${values.category_slug}/${values.slug}`
      : `/${values.category_slug}/${values.slug}`;
    window.open(`${baseUrl}${urlPath}`);
  };
  const columns = [
    {
      name: "ID",
      selector: (post) => post.id,
      sortable: true,
      width: "100px",
    },
    {
      name: "Title",
      selector: (post) => (
        <div className="d-flex flex-column m-4 gap-2">
          {post.display_name && (
            <span
              style={{
                fontSize: "0.8rem", // Small font size for the "editing" message
                color: "gray", // Light color for the editing message
              }}
            >
              {post.image && (
                <img
                  src={
                    post.image.startsWith("https://")
                      ? post.image
                      : `https://static.autox.com/uploads/${post.image}`
                  } //${row.display_image}
                  alt="image"
                  style={{
                    width: "40px",
                    height: "30px",
                  }}
                />
              )}{" "}
              {post.display_name} is currently editing....
            </span>
          )}
          <a
            onClick={() => onEditPost(post.id)}
            style={{
              cursor: "pointer",
              color: "black", // Default color
              textDecoration: "none", // Remove underline if needed
              fontWeight: "bold", // You can make the title bold if needed
            }}
            onMouseEnter={(e) => (e.target.style.color = "blue")} // Change color on hover
            onMouseLeave={(e) => (e.target.style.color = "black")} // Revert back on mouse leave
          >
            {post.post_title}
          </a>
        </div>
      ),
      sortable: true,
      width: "350px",
      height: "100px",
    },

    {
      name: "Post Type",
      sortable: true,
      selector: (row) =>
        row.status == 1 ? (
          <div className="green-box text-center p-1">Live</div>
        ) : row.status == 0 ? (
          <div className="yellow-box">Draft</div>
        ) : row.status == 2 ? (
          <div className="red-box">Scheduled</div>
        ) : (
          <div className="red-box">Custom Slider</div>
        ),

      width: "120px",
    },
    {
      name: "Category",
      selector: (post) =>
        post.parent_category
          ? `${post.parent_category} >> ${post.category_name}`
          : post.category_name,
      sortable: true,
    },
    // {
    //   name: "Status",
    //   selector: (post) => getPageStatusBadge(post.status),
    //   sortable: false,
    // },
    {
      name: "Date",
      selector: (post) => renderDateTime(post.publish_date),
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      width: "auto",
      cell: (post) => {
        if (filter.status == 3) {
          // If the status is 3, only show the Delete button
          return (
            <EditViewDelete
              id={post.id}
              onDelete={() => {
                setPostIdToDelete(post.id);
                setShowDeleteModal(true);
              }}
            />
          );
        } else {
          // If status is not 3, show Edit, View, and Delete buttons
          return (
            <EditViewDelete
              id={post.id}
              onEdit={onEditPost}
              onDelete={() => {
                setPostIdToDelete(post.id);
                setShowDeleteModal(true);
              }}
              onView={() => onViewPost(post)}
              {...(post.status == 1 ? { onReview: () => onReview(post) } : {})}
            />
          );
        }
      },
    },
  ];
  const filterOptions = [
    {
      key: "startDate",
      label: "Start Date",
      type: "date",
      searchKey: "formatted_date",
      values: formatedDate,
    },
    {
      key: "exlcusiveStories",
      label: "Exclusive Stories",
      type: "Category",
      searchKey: "category_name",
      values: categoryList,
    },
    {
      key: "author",
      label: "Author",
      type: "author",
      searchKey: "display_name",
      values: authorList,
    },
    {
      key: "status",
      label: "Status",
      type: "status",
      searchKey: "name",
      values: statusList,
    },
  ];

  const filterDropdownRef = useRef();
  const handleClickOutside = (event) => {
    if (
      filterDropdownRef.current &&
      !filterDropdownRef.current.contains(event.target)
    ) {
      setFilterDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const selectedTypes = (type, selected) => {
    console.log(type, selected);
    if (type === "Category") {
      setFilter({ ...filter, category_id: selected.id });
    }
    if (type === "author") {
      setFilter({ ...filter, author_id: selected.id });
    }
    if (type === "date") {
      setFilter({ ...filter, start_date: selected.id });
    }
    if (type === "status") {
      setFilter({ ...filter, status: selected.id });
    }
  };

  useEffect(() => {
    apiHandler
      .postFilterOptions(token)
      .then((result) => {
        setCategoryList(result.data.category_list);
        setAuthorList(result.data.author_list);
        setFormatedDate(result.data.date_list);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  }, []);
  const handleSearchClicked = () => {
    // setReloadTable(true);

    console.log("filter", filter);
  };
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);

    // Clear the previous timeout if it exists
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const id = setTimeout(() => {
      setFilter((prevFilter) => ({
        ...prevFilter,
        post_title: value,
      }));
    }, 2000); // 2 seconds debounce

    // Save the timeout id to clear it if needed
    setTimeoutId(id);
  };

  useEffect(() => {
    // Cleanup function to clear timeout if component unmounts
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);
  const fetchSliderPosts = useCallback(async () => {
    if (filter.status == 3) {
      const response = await getPosts(token, filter);
      setCustomSlider(response.data.result);
    }
  }, [filter]);
  useEffect(() => {
    console.log("deleted");
    fetchSliderPosts();
  }, [filter, reloadTable]);

  const clearAll = () => {
    setFilter({
      category_id: "",
      start_date: "",
      author_id: "",
      status: "",
      post_title: "",
      user_type: admin,
    });
    setReloadTable(true);
  };
  // useEffect(() => {
  //   // Listen for the 'postsUpdated' event
  //   socket.on('postsUpdated', (updatedPosts) => {
  //     console.log(updatedPosts,"updatedPosts")
  //   });

  //   // Clean up the socket listener when the component unmounts
  //   return () => {
  //     socket.off('postsUpdated');
  //   };
  // }, []);
  console.log(filter, "filter");
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const updateSort = (carouselItems) => {
    apiHandler
      .sortSlider(token, carouselItems)
      .then((result) => {
        console.log(result);
        setCustomSlider(result.data.result);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  };
  useEffect(() => {
    fetchSliderPosts();
  }, [fetchSliderPosts]);
  const handleSort = () => {
    let __carouselItems = [...customSlider];
    const draggedItemContent = __carouselItems.splice(dragItem.current, 1)[0];
    __carouselItems.splice(dragOverItem.current, 0, draggedItemContent);
    dragItem.current = null;
    dragOverItem.current = null;

    const sortItems = __carouselItems.map((items) => items.id);
    updateSort(sortItems);
  };
  return (
    <>
      <Breadcrumbs />
      <div>
        <Card className="p-3">
          <div className="d-flex justify-content-between">
            <HeadingTwo
              title={`Post list (${totalRecords})`}
              type="dashboard"
              showHeader={true}
              pointerOnHover={true}
            />{" "}
            <button
              className="add-post-button d-flex align-items-center justify-content-between me-2 mb-2 px-3"
              onClick={() => navigate("/post-management/add-post")}
            >
              <ReactSVG
                src={PlusLargeIcon}
                height="18px"
                width="18px"
                className="mr-2"
              />{" "}
              Add Post
            </button>{" "}
          </div>
          {admin == "super admin" && <PostButtonGroup />}
          <div>
            <div className="d-flex w-100">
              <Search
                onChange={(e) => handleSearchChange(e)}
                value={search}
                placeholder={"Search"}
              />
              <button
                className="black-filled-button ms-2"
                onClick={() => console.log("Apply")}
              >
                Apply
              </button>
              <div className="position-relative" ref={filterDropdownRef}>
                <button
                  className="px-2 border-1 rounded filter-option p-1  ms-2 position-relative"
                  onClick={handleFilterClick}
                >
                  <ReactSVG src={FilterIcon} />{" "}
                </button>
                <FilterDropdown
                  isVisible={filterDropdown}
                  options={filterOptions}
                  selectedValues={filter}
                  selectedTypes={selectedTypes}
                  apply={() => handleSearchClicked()}
                  clearAll={() => clearAll()}
                  buttonVisible={false}
                />
              </div>
            </div>
            <div>
              <div className="row dashboard">
                {filter.status == 3 ? (
                  <div>
                    <ListGroup as="ol" className="customGridList">
                      {/* Header Row with Column Names */}
                      <ListGroup.Item
                        as="li"
                        className="d-grid customGrid-header"
                      >
                        <div className="customGrid-col-id">
                          <strong>ID</strong>
                        </div>
                        <div className="customGrid-col-title">
                          <strong>Title</strong>
                        </div>
                        <div className="customGrid-col-category">
                          <strong>Category</strong>
                        </div>
                        <div className="customGrid-col-date">
                          <strong>Date</strong>
                        </div>
                        <div className="customGrid-col-action">
                          <strong>Action</strong>
                        </div>
                      </ListGroup.Item>

                      {/* List Items */}
                      {customSlider.map((item, index) => {
                        return (
                          <ListGroup.Item
                            as="li"
                            className="d-grid customGrid-item cursor-move"
                            draggable
                            onDragStart={(e) => (dragItem.current = index)}
                            onDragEnter={(e) => (dragOverItem.current = index)}
                            onDragEnd={handleSort}
                            onDragOver={(e) => e.preventDefault()}
                            key={index}
                          >
                            <div className="customGrid-col-id ms-2">
                              <div>{item.id}</div>
                            </div>
                            <div className="customGrid-col-title ms-2">
                              <div>{`${item.post_title.slice(0, 45)}...`}</div>
                            </div>
                            <div className="customGrid-col-category ms-2">
                              <div>
                                {item.parent_category
                                  ? `${item.parent_category} >> ${item.category_name}`
                                  : item.category_name}
                              </div>
                            </div>
                            <div className="customGrid-col-date">
                              {renderDateTime(item.publish_date)}
                            </div>
                            <div className="customGrid-col-action ms-2">
                              <EditViewDelete
                                id={item.id}
                                onDelete={() => {
                                  setPostIdToDelete(item.id);
                                  setShowDeleteModal(true);
                                }}
                              />
                            </div>
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </div>
                ) : (
                  <Datatable
                    columns={columns}
                    api={getPosts}
                    reload={reloadTable}
                    setReload={setReloadTable}
                    totalRecords={totalRecords}
                    setTotalRecords={setTotalRecords}
                    filter={filter}
                  />
                )}
                {/* 
              <PostListingComponent
                  columns={columns}
                  api={getPosts}
                  reload={reloadTable}
                  setReload={setReloadTable}
                  totalRecords={totalRecords}
                  setTotalRecords={setTotalRecords}
                  /> */}
                <DeleteConfirmationModalComponent
                  show={showDeleteModal}
                  type={"delete"}
                  handleClose={() => setShowDeleteModal(false)}
                  setDeleteConfirm={onDeletePost}
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}
