import React from "react";
import { Accordion, Card, Form, Button } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import DynamicSelect from "../../CarUserReview/EditCarUserReview/DynamicSelect";
import { getGalleryPreviewLink } from "../apiHandler";

const CommonForm = ({
  handleSubmit,
  values,
  setFieldValue,
  touched,
  errors,
  brands,
  models,
  handleFileChange,
  handleRemoveImage,
  handleSubmitDraft,
  type,
}) => {
  console.log(values);
  console.log(errors);
  const getPreviewLink = async (brandId, modelId, type) => {
    if (brandId && modelId) {
      const response = await getGalleryPreviewLink(brandId, modelId, type);
      if (response.status === 200) {
        const url = `${process.env.REACT_APP_FRONTEND_BASE_URL}/new-cars/${response.data.brand_url}/${response.data.model_url}/photos`;
        // Use window.open correctly
        window.open(url, "_blank");
      }
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex gap-3" style={{ width: "100%" }}>
        <div className="d-flex flex-column" style={{ width: "70%" }}>
          <div className="mb-3">
            <Card className="w-100">
              <div className="d-flex flex-row justify-content-between align-items-center align-content-center">
                <DynamicSelect
                  label={"Brand"}
                  options={brands}
                  id="brand_id"
                  name="brand_name"
                  values={values}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  checkedValue={values.brand_id}
                />
                <DynamicSelect
                  label={"Model"}
                  name="model_name"
                  options={models}
                  id="model_id"
                  values={values}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  checkedValue={values.model_id}
                />
              </div>
            </Card>
          </div>
          <div>
            <Card className="w-100">
              <Accordion defaultActiveKey="0">
                <Accordion.Item
                  eventKey="0"
                  className="card-height header_padding_top"
                >
                  <Accordion.Header className="p-3 pb-1 ps-2 pt-4">
                    Upload Files
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="mb-3 d-flex gap-3">
                      <div className="mb-3">
                        <Form.Label>All</Form.Label>
                        <Form.Control
                          type="file"
                          multiple
                          onChange={(event) => handleFileChange(event, "All")}
                          className="form-control"
                        />
                      </div>
                      <div className="mb-3">
                        <Form.Label>Exterior</Form.Label>
                        <Form.Control
                          type="file"
                          multiple
                          onChange={(event) =>
                            handleFileChange(event, "exterior")
                          }
                        />
                      </div>
                      <div className="mb-3">
                        <Form.Label>Interior</Form.Label>
                        <Form.Control
                          type="file"
                          multiple
                          onChange={(event) =>
                            handleFileChange(event, "interior")
                          }
                        />
                      </div>
                      <div className="mb-3">
                        <Form.Label>Color</Form.Label>
                        <Form.Control
                          type="file"
                          multiple
                          onChange={(event) => handleFileChange(event, "color")}
                        />
                      </div>
                    </div>
                    {values.images.map((image, index) => (
                      <div key={index} className="mb-3 d-flex">
                        {image.blob ? (
                          image.blob instanceof File ||
                          image.blob instanceof Blob ? (
                            <img
                              src={URL.createObjectURL(image.blob)}
                              alt={image.alt}
                              style={{ width: "50px", height: "50px" }}
                            />
                          ) : (
                            <img
                              src={
                                image.blob.startsWith("https://")
                                  ? image.blob
                                  : image.blob.startsWith("blob:")
                                  ? image.blob
                                  : `https://static.autox.com//${image.blob}`
                              }
                              alt={image.alt}
                              style={{ width: "50px", height: "50px" }}
                            />
                          )
                        ) : null}
                        <Form.Control
                          type="text"
                          placeholder="Caption"
                          value={image.caption}
                          onChange={(e) =>
                            setFieldValue(
                              `images.${index}.caption`,
                              e.target.value
                            )
                          }
                          className="ms-2"
                        />
                        <Form.Control
                          type="text"
                          placeholder="Alt Text"
                          value={image.alt}
                          onChange={(e) =>
                            setFieldValue(`images.${index}.alt`, e.target.value)
                          }
                          className="ms-2"
                        />
                        <Form.Select
                          value={image.position}
                          onChange={(e) =>
                            setFieldValue(
                              `images.${index}.position`,
                              e.target.value
                            )
                          }
                          className="ms-2"
                        >
                          <option value="All">All</option>
                          <option value="exterior">Exterior</option>
                          <option value="interior">Interior</option>
                          <option value="color">Color</option>
                        </Form.Select>
                        <Form.Control
                          type="number"
                          placeholder="Order"
                          value={image.order}
                          onChange={(e) =>
                            setFieldValue(
                              `images.${index}.order`,
                              e.target.value
                            )
                          }
                          className="ms-2"
                        />
                        <Button
                          variant="danger"
                          onClick={() => handleRemoveImage(index)}
                          className="ms-2"
                        >
                          Remove
                        </Button>
                      </div>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card>
          </div>
        </div>
        <div className="d-flex flex-column gap-3" style={{ width: "30%" }}>
          <div>
            <Card className="w-100">
              <Accordion defaultActiveKey="0">
                <Accordion.Item
                  eventKey="0"
                  className="card-height header_padding_top"
                >
                  <Accordion.Header className="p-3 pb-1 ps-2 pt-4">
                    <div className=" d-flex justify-content-between w-100 align-items-center">
                      Publish
                      {type == "edit" && (
                        <Button
                          className="btn-secondary"
                          target="_blank"
                          onClick={() =>
                            getPreviewLink(
                              values.brand_id,
                              values.model_id,
                              "car"
                            )
                          }
                        >
                          Preview
                        </Button>
                      )}
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row p-3">
                      <div className="row checkbox-container mb-2">
                        <Form.Check
                          type="checkbox"
                          id="publish"
                          label="Publish Immediately"
                          checked={values.status === 1}
                          onChange={() => setFieldValue("status", 1)}
                        />
                      </div>
                      <div className="row checkbox-container mb-2">
                        <Form.Check
                          type="checkbox"
                          id="schedule"
                          label="Schedule Publish"
                          checked={values.status === 2}
                          onChange={() => setFieldValue("status", 2)}
                        />
                      </div>
                      {values.status === 2 && (
                        <div className="w-100">
                          <ReactDatePicker
                            selected={values.publish_date}
                            onChange={(date) =>
                              setFieldValue("publish_date", date)
                            }
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="MMMM dd yyyy h:mm aa"
                            className="w-100"
                          />
                        </div>
                      )}
                    </div>
                    <div className="d-flex justify-content-between flex-wrap">
                      <Button
                        variant="btnBlue"
                        className="d-flex align-items-center justify-content-between"
                        type="submit"
                      >
                        Publish
                      </Button>
                      <Button
                        className="btn-secondary"
                        onClick={() => handleSubmitDraft()}
                      >
                        Save Draft
                      </Button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card>
          </div>
          <div>
            <Card className="w-100">
              <Accordion defaultActiveKey="0" className="">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="p-3 pb-2 ps-2 pb-0">
                    Home Gallery
                  </Accordion.Header>
                  <Accordion.Body className="pt-0 checkbox-body">
                    <div className="checkbox-container">
                      <Form.Check
                        type="checkbox"
                        id="home_gallery"
                        name="home_gallery"
                        label="Make Home Gallery"
                        checked={values.home_gallery}
                        onChange={(e) =>
                          setFieldValue("home_gallery", e.target.checked)
                        }
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CommonForm;
