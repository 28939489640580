import { Card } from "react-bootstrap";
import HeadingTwo from "../../components/HeadingTwo";
import Datatable from "../../components/Table";
import { useEffect, useState } from "react";
import apiHandler from "./apiHandler";
import { ReactSVG } from "react-svg";
import PlusLargeIcon from "../../assets/icons/bootstrap-icons/plus-lg.svg";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs";
import { notifyError, notifySuccess } from "../../utility/common";
import DeleteConfirmationModalComponent from "../../components/DeleteConfirmationModalComponent";
import { getToken, renderDate } from "../../utility/constants";
import EditViewDelete from "../../components/EditViewDeleteReviewPassword";

export default function NewsLetter() {
  const [totalRecords, setTotalRecords] = useState(0);
  const [reloadTable, setReloadTable] = useState(false);
  const [newsToDelete, setNewsToDelete] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();

  const token = getToken();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onEditNews = (newsletter_id) =>
    navigate(`/edit-news-letter/${newsletter_id}`);
  const onDeleteNews = async () => {
    try {
      if (newsToDelete == 25) {
        notifyError("Daily Newsletter cannot be deleted");
        setShowDeleteModal(false);
        setReloadTable(true);
        return;
      }
      await apiHandler.deleteNewsletter(newsToDelete, token);
      setShowDeleteModal(false);
      setReloadTable(true);
      notifySuccess("Newsletter deleted successfully");
    } catch (error) {
      setShowDeleteModal(false);
      notifyError(error.message);
    }
  };
  const column = [
    {
      name: "No .",
      selector: (row) => row.newsletter_id,
      sortable: true,
    },
    {
      name: "name",
      selector: (row) => row.name,
      sortable: true,
      width: "250px",
    },
    {
      name: "Subscriber Count",
      selector: (row) => row?.subscription_count || 0,
    },
    { name: "Description", selector: (row) => row.description, sortable: true },

    {
      name: "Status",
      selector: (row) =>
        row.status == 1 ? (
          <div className="green-box">Active</div>
        ) : (
          <div className="red-box">Inactive</div>
        ),
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row) => renderDate(row.created_date),
    },
    {
      name: "Action",
      sortable: false,
      width: "auto",

      cell: (row) => (
        <EditViewDelete
          id={row.newsletter_id}
          onEdit={() => onEditNews(row.newsletter_id)}
          onDelete={() => {
            setNewsToDelete(row.newsletter_id);

            setShowDeleteModal(true);
          }}
        />
      ),
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between mb-2">
        <Breadcrumbs />
        <button
          className="add-service-centre-button d-flex align-items-center justify-content-between mb-2 px-3"
          onClick={() => navigate("/add-news-letter")}
        >
          <ReactSVG
            src={PlusLargeIcon}
            height="18px"
            width="18px"
            className="mr-2"
          />{" "}
          Add Contact List
        </button>
      </div>
      <Card className="p-3">
        <HeadingTwo
          title="Contact List"
          type="dashboard"
          showHeader={true}
          pointerOnHover={true}
        />
        <Datatable
          api={apiHandler.getNewsLetterList}
          columns={column}
          setTotalRecords={setTotalRecords}
          reload={reloadTable}
          setReload={setReloadTable}
        />
        <DeleteConfirmationModalComponent
          show={showDeleteModal}
          type="delete"
          handleClose={() => setShowDeleteModal(false)}
          setDeleteConfirm={onDeleteNews}
        />
      </Card>
    </div>
  );
}
