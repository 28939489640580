import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../components/Layout";

import Breadcrumbs from "../../../Breadcrumbs";
import BikeComparisonList from "./BikeComparisonList";
import AddBikeComparison from "./AddBikeComparison";

export default function BikeComparisonMain({}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [reloadData, setReloadData] = useState(false);

  return (
    <Layout>
      <div className="row dashboard">
        <Breadcrumbs />
        <div className="my-0 d-flex">
          <div className="col-8 pe-4 align-self-start">
            <BikeComparisonList reloadData={reloadData} />
          </div>
          <div className="col-4 align-self-start">
            <AddBikeComparison setReloadData={setReloadData} />
          </div>
        </div>
      </div>
    </Layout>
  );
}
