import axios from "axios";
import { getConfig, BASE_URL_AUTOX } from "../../../request";
export const GET_BRAND_LIST = "car/brand/filtered/list";
export const GET_ALL_BRAND_LIST = "car/brand/all/list";
export const GET_BRAND_URL = "car/brand/view/";
export const ADD_BRAND = "car/brand/add";
export const DELETE_BRAND = "car/brand/delete/";
export const GET_BRAND_BY_ID = "car/brand/";
export const UPDATE_CAR_BRAND = "car/brand/update/";
export const BULK_UPDATE_BRAND_ORDERING = "car/brand/ordering/update";

/**
 * Get List of Brands
 * getAllBrands is used to get subset data of all brands
 * @param token
 * @param payload
 */
export function getAllBrands(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_ALL_BRAND_LIST}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get List of Car Brands
 * getBrandList is used to get all the data of car brands
 * @param token
 * @param payload
 */
export function getBrandList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_BRAND_LIST}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get Car Brand By Id
 * getBrandById is used to get car brand data by id
 * @param payload
 */
export function getBrandById(token, brand_id) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_BRAND_BY_ID}${brand_id}`,
    getConfig(token)
  );
}

/**
 * Get View link of Car Brand
 * getBrandViewLink is used to view link of car brand
 * @param token
 * @param payload
 */
export function getBrandViewLink(token, id) {
  return axios.get(`${BASE_URL_AUTOX}${GET_BRAND_URL}${id}`, getConfig(token));
}

/**
 * Add New Car Brand Data
 * addBrand is used to create new car brand data
 * @param token
 * @param payload
 */
export function addBrand(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${ADD_BRAND}`,
    payload,
    getConfig(token, { "Content-Type": "multipart/form-data" })
  );
}

/**
 * Update Car Brand By Id
 * updateBrand is used to update data of car brand by id
 * @param token
 * @param id
 * @param payload
 */
export function updateBrand(token, brand_id, payload) {
  return axios.put(
    `${BASE_URL_AUTOX}${UPDATE_CAR_BRAND}${brand_id}`,
    payload,
    getConfig(token, { "Content-Type": "multipart/form-data" })
  );
}

/**
 * Update Car Brand By Id
 * bulkUpdateBrandOrdering is used to update ordering of car brand
 * @param token
 * @param payload
 */
export function bulkUpdateBrandOrdering(token, payload) {
  // console.log("test 4567")
  return axios.put(
    `${BASE_URL_AUTOX}${BULK_UPDATE_BRAND_ORDERING}`,
    payload,
    getConfig(token)
  );
}

/**
 * Delete Car Brand By Id
 * deleteBrand is used to delete single record of car brand by id
 * @param token
 * @param id
 */
export function deleteBrand(token, brand_id) {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_BRAND}${brand_id}`,
    getConfig(token)
  );
}

/**
 * Get Export of Car Brands
 * getExportBrandList is used to get all the data of car brands
 * @param token
 * @param payload
 */
export function getExportBrandList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_BRAND_LIST}`,
    payload,
    getConfig(token)
  );
}

//eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllBrands,
  getBrandList,
  getBrandById,
  getBrandViewLink,
  addBrand,
  updateBrand,
  deleteBrand,
  bulkUpdateBrandOrdering,
};
