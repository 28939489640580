import Layout from "../../components/Layout";

export default function CategoryMain({ children }) {
  return (
    <Layout>
      <div className="row dashboard">
        <div className="my-4">{children}</div>
      </div>
    </Layout>
  );
}
