import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import EditViewDeleteReviewPassword from "../../../components/EditViewDeleteReviewPassword";
import { ReactSVG } from "react-svg";
import FilterIcon from "../../../assets/icons/filter2.svg";
import DeleteIcon from "../../../assets/icons/bootstrap-icons/Trash.svg";
import PlusLargeIcon from "../../../assets/icons/bootstrap-icons/plus-lg.svg";
import DownloadIcon from "../../../assets/icons/bootstrap-icons/download.svg";
import Dropdown from "react-bootstrap/Dropdown";
import Breadcrumbs from "../../../Breadcrumbs";
import Card from "react-bootstrap/Card";
import Search from "../../../components/SearchField";
import apiHandler, { downloadVarient } from "./apiHandler";
import Datatable from "../../../components/Table";
import { notifyError, notifySuccess } from "../../../utility/common";
import { ToastContainer } from "react-toastify";
import DeleteConfirmationModalComponent from "../../../components/DeleteConfirmationModalComponent";
import { versionCarType } from "../../../utility/constants";
import { getToken } from "../../../utility/constants";
import Form from "react-bootstrap/Form";
import CarModelSearchBar from "../CarModels/CarModelListing/CarModelSearchBar";
import BulkImportVersion from "../../../components/BulkImportVersion";
import dustbin from "../../../assets/icons/dustbin.svg";
import axios from "axios";
import ExportCustomButton from "../../../components/ExportCustomButton";
import DownloadCSVversion from "../../../components/DownLoadCSVversion";
import { BASE_URL_AUTOX } from "../../../request";
export default function CarVersions({ children }) {
  const token = getToken();
  const navigate = useNavigate();
  const [totalRecords, setTotalRecords] = useState(0);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [reload, setReload] = useState(false);
  const [show, setShow] = useState(false);
  const [bulkShow, setBulkShow] = useState(false);
  const [filter, setFilter] = useState({
    brand_id: "",
    model_id: "",
    car_status: "",
    version_name: "",
  });
  const [search, setSearch] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const onViewInner = (row) => {
    // apiHandler
    //   .getCarVersionViewLink(token, id)
    //   .then((result) => {
    console.log("row", row);

    window.open(
      `${process.env.REACT_APP_FRONTEND_BASE_URL}/new-cars/${row.brand_url}/${row.model_url}/${row.version_url}`,
      "_blank"
    );
    // window.open(result.data.data, "_blank");
    // })
    // .catch((error) => {
    //   notifyError(error.message);
    // });
  };

  const onEditInner = (id) => {
    console.log("id", id);

    navigate(
      `/edit-car-version?brandId=${id.brand_id}&modelId=${id.model_id}&type=1`
    );
  };

  const onDeleteInner = (id) => {
    apiHandler
      .deleteCarVersionById(token, deleteId)
      .then((result) => {
        setShow(false);
        setReload(true);
        notifySuccess("Car Version deleted successfully");
      })
      .catch((error) => {
        notifyError(error.message);
      });
  };

  const onReviewInner = (id) => {};

  const toggleCheckAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const checkboxes = document.getElementsByName("checkedStatusArray");
      checkboxes.forEach((ele) => {
        console.log("ele", ele);
        ele.checked = true;
      });
    } else {
      const checkboxes = document.getElementsByName("checkedStatusArray");
      checkboxes.forEach((ele) => {
        console.log("ele", ele);
        ele.checked = false;
      });
    }
  };

  const onBulkDeleteHandler = (id) => {
    const checkedStatusArrayInner = Array.from(
      document.getElementsByName("checkedStatusArray")
    )
      .filter((ele) => ele.checked)
      .map((ele) => ele.value);
    console.log("checked array", checkedStatusArrayInner);
    apiHandler
      .bulkDeleteCarVersionById(token, { delete_ids: checkedStatusArrayInner })
      .then((result) => {
        setBulkShow(false);
        setReload(true);
        notifySuccess("Car Versions deleted successfully");
      })
      .catch((error) => {
        notifyError(error.message);
      });
  };

  const getBrands = async () => {
    apiHandler
      .getAllBrands(token)
      .then((res) => {
        setBrands(res.data.result);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  };

  useEffect(() => {
    getBrands();
  }, []);

  useEffect(() => {
    if (filter.brand_id === "") {
      apiHandler
        .getAllModelsList(token)
        .then((result) => {
          setModels(result.data.result);
          setFilter({ ...filter, model_id: "" });
        })
        .catch((error) => {
          notifyError(error.message);
        });
    } else {
      apiHandler
        .getAllModelsList(token, { brand_id: filter.brand_id })
        .then((result) => {
          setModels(result.data.result);
          setFilter({ ...filter, model_id: "" });
        })
        .catch((error) => {
          notifyError(error.message);
        });
    }
  }, [filter.brand_id]);

  console.log("filter", filter);
  const filterOptions = [
    {
      key: "brand",
      label: "By Brands",
      type: "brand",
      searchKey: "brand_name",
      values: brands,
    },
    {
      key: "model",
      label: "By Models",
      type: "model",
      searchKey: "model_name",
      values: models,
    },
    {
      key: "carType",
      label: "By Car Type",
      type: "carType",
      searchKey: "value",
      values: versionCarType,
    },
  ];
  const columns = [
    {
      name: (
        <div className="text-center">
          <input type="checkbox" checked={selectAll} onClick={toggleCheckAll} />
        </div>
      ),
      sortable: false,
      selector: (row) => row.id,
      width: "60px",
      cell: (row) => (
        <div className="text-center">
          <input
            type="checkbox"
            value={row.id}
            onChange={(e) =>
              console.log(e, document.getElementsByName("checkedStatusArray"))
            }
            name={"checkedStatusArray"}
          />
        </div>
      ),
    },
    {
      name: "ID",
      sortable: true,
      selector: (row) => row.id,
      width: "80px",
    },
    {
      name: "Variants Name",
      sortable: true,
      selector: (row) => row.version_name,
      width: "200px",
    },
    {
      name: "Url",
      sortable: true,
      selector: (row) => row.version_url,
      width: "180px",
    },
    {
      name: "Brand Name",
      sortable: true,
      selector: (row) => row.brand_name,
      width: "200px",
    },
    {
      name: "Model Name",
      sortable: true,
      selector: (row) => row.model_name,
      width: "200",
    },
    {
      name: "Car Type",
      sortable: true,
      selector: (row) =>
        row.car_status === 0 ? (
          <div className="green-box">Live</div>
        ) : row.car_status === 2 ? (
          <div className="red-box">Discontinued</div>
        ) : (
          <div className="red-box">Not Live</div>
        ),
      width: "160px",
    },
    {
      name: "Action",
      sortable: false,
      width: "auto",
      cell: (row) => (
        <EditViewDeleteReviewPassword
          id={row.id}
          onView={() => onViewInner(row)}
          onEdit={() => onEditInner(row)}
          onDelete={(id) => {
            setDeleteId(id);
            setShow(true);
          }}
        />
      ),
    },
  ];

  const sortFieldNames = {
    ID: "id",
    "Variants Name": "version_name",
    Url: "version_url",
    "Brand Name": "brand_name",
    "Model Name": "model_name",
    "Car Type": "car_status",
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      className="user-name"
      href="javascript:void(0)"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  const subHeaderComponentMemo = useMemo(() => {
    function handleSearchClick() {
      setReload(true);
    }

    const clearFilter = () => {
      setFilter({
        brand_id: "",
        model_id: "",
        car_status: "",
        version_name: "",
      });
      setReload(!reload);
    };
    const selectedTypes = (type, selected) => {
      console.log("typee", type, selected);
      if (type === "brand") {
        setFilter({ ...filter, brand_id: selected.id });
      }
      if (type === "model") {
        setFilter({ ...filter, model_id: selected.id });
      }
      if (type === "carType") {
        setFilter({ ...filter, car_status: selected.id });
      }
    };
    const downloadSample = async (data) => {
      try {
        // const res = await apiHandler.downloadSampleInsertionFile(data);
        // console.log(res.data.url);
        const url = `${BASE_URL_AUTOX}downloads/car-import-version-sample.xlsx`;

        window.open(url, "_blank");

        // if (res.data.url) {
        //   window.open(res.data.url, "_blank");
        // }
      } catch (error) {
        notifyError(error.message);
      }
    };
    return (
      <>
        <div className="col-md-12 text-right">
          <div className="btngroup d-flex justify-content-end flex-row flex-wrap">
            <button
              className="bulk-update-button d-flex align-items-center justify-content-between me-2 mb-2 px-3"
              onClick={() => downloadSample("update")}
            >
              <ReactSVG
                src={DownloadIcon}
                height="18px"
                width="18px"
                className="mr-2"
              />{" "}
              Sample For Bulk Update
            </button>
            <button
              className="bulk-insert-button d-flex align-items-center justify-content-between me-2 mb-2 px-3"
              onClick={() => downloadSample("insert")}
            >
              <ReactSVG
                src={DownloadIcon}
                height="18px"
                width="18px"
                className="mr-2"
              />{" "}
              Sample For Bulk Insert
            </button>

            <DownloadCSVversion
              api={() =>
                downloadVarient(token, {
                  brand_id: filter.brand_id,
                  model_id: filter.model_id,
                  car_status: filter.car_status,
                })
              }
            />

            <button
              className="add-variant-button d-flex align-items-center justify-content-between me-2 mb-2 px-3"
              onClick={() => navigate("/car-version/create-car-version")}
            >
              <ReactSVG
                src={PlusLargeIcon}
                height="18px"
                width="18px"
                className="mr-2"
              />{" "}
              Add Variants
            </button>
          </div>
        </div>
        <div className="d-flex w-100 justify-content-between align-items-center gap-2">
          <CarModelSearchBar
            onChange={(e) => {
              setSearch(e);
            }}
            value={search}
            options={filterOptions}
            selectedTypes={selectedTypes}
            apply={handleSearchClick}
            tableHeading={`Car Variants (${totalRecords})`}
            searchPlaceholder={"Type Variant Name..."}
            selectedValues={{
              brand_id: filter.brand_id,
              model_id: filter.model_id,
              car_status: filter.car_status,
            }}
            clearAll={clearFilter}
          />
          <div>
            <ReactSVG
              src={dustbin}
              onClick={() => setBulkShow(true)}
              className="dustbin-button"
            />
          </div>
        </div>
      </>
    );
  }, [search, totalRecords, filter, reload]);
  console.log("search", filter);
  return (
    <div>
      <ToastContainer />
      <DeleteConfirmationModalComponent
        show={show}
        type={"delete"}
        handleClose={() => setShow(false)}
        setDeleteConfirm={onDeleteInner}
      />
      <DeleteConfirmationModalComponent
        show={bulkShow}
        type={"delete"}
        handleClose={() => setBulkShow(false)}
        setDeleteConfirm={onBulkDeleteHandler}
      />

      <div className="d-flex justify-content-between">
        <Breadcrumbs />
        <BulkImportVersion setReload={setReload} type={"car"} />

        {/* </div> */}
      </div>

      <Card>
        <Card.Body>
          <Card.Text>
            <div className="row dashboard">
              <Datatable
                api={apiHandler.getCarVersions}
                columns={columns}
                header={subHeaderComponentMemo}
                showHeader={true}
                sortFieldNames={sortFieldNames}
                search={search}
                reload={reload}
                defaultSortColumn={""}
                sortByDir={"desc"}
                setReload={setReload}
                setTotalRecords={setTotalRecords}
                filter={filter}
              />
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
