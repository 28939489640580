import * as Yup from "yup";

const phoneRegex = /^\+\d{1,3}\s?\d{10}$/;

export const carDrivingSchoolValidation = Yup.object({
  city_id: Yup.number().min(1, "City is required").required("City is required"),
  school_name: Yup.string().required("School Name is required"),
  school_url: Yup.string().required("School Url is required"),
  driving_school_address: Yup.string().required("School Address is required"),
  driving_school_area: Yup.string(),
  driving_school_map: Yup.string(),
  latitude: Yup.number().required("Latitude is required"),
  longitude: Yup.number().required("Longitude is required"),
  phone_number: Yup.string().matches(phoneRegex, "Phone number is not valid"),
  email_id: Yup.string().email(),
});
