import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../../../components/Table";
import Card from "react-bootstrap/Card";
import {
  getBrandNameList,
  getCarBodyType,
  getModelList,
  getSimpleModelList,
} from "../../../../request";

import PlusLargeIcon from "../../../../assets/icons/bootstrap-icons/plus-lg.svg";
import UploadIcon from "../../../../assets/icons/bootstrap-icons/upload.svg";

import Breadcrumbs from "../../../../Breadcrumbs";
import CarModelSearchBar from "./CarModelSearchBar";
import CarModelBtnGroup from "./CarModelBtnGroup";
import { CarColumns } from "../../../../components/CarColumns";
import { getToken } from "../../../../utility/constants";
import CarModelBtn from "../../../../components/CarModelBtn";
import OldTable from "../../../../components/OldListingTable";
import { carType } from "../../../../utility/constants";
import Datatable from "../../../../components/Table";
import ExportButton from "../../../../components/Export";

export default function CarBrands({ children }) {
  const navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [originalData, setOriginalData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchVal, setSearchVal] = useState("");
  const [brands, setBrands] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState(0);
  const [bodyType, setBodyType] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedBodyType, setSelectedBodyType] = useState("");
  const [selectedCarType, setSelectedCarType] = useState("");
  const token = getToken();
  const debounceTimeout = useRef(null);
  useEffect(() => {
    // fetchData();
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      fetchData();
    }, 500);

    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, [currentPage, searchVal]);

  useEffect(() => {
    fetchData();
    getBrands();
    getBodyType();
  }, []);
  // useEffect(() => {
  //   fetchData();
  // }, [selectedBrand, selectedBodyType, selectedCarType]);

  const getBrands = async () => {
    const brandParams = {
      sortBy: "",
      sortOrder: "",
    };
    const res = await getBrandNameList(token, brandParams);
    setBrands(res.data.result);
  };

  const getBodyType = async () => {
    const bodyTypeParams = {
      page: 0,
      limit: 0,
      sortBy: "",
      sortOrder: "",
      search: "",
    };
    const res = await getCarBodyType(token, bodyTypeParams);
    setBodyType(res.data.result);
  };

  const fetchData = async () => {
    try {
      const params = {
        page: currentPage,
        limit: itemsPerPage,
        sortBy: "id",
        sortOrder: "desc",
        search: searchVal,
        brand_id: [selectedBrand],
        body_type_id: [selectedBodyType],
        car_id: [selectedCarType],
      };
      const res = await getModelList(token, params);
      const response = res.data.result;
      console.log("response.........", response);
      setOriginalData(response);

      setFilteredRecords(res.data.filteredRecords);

      setTotalRecords(res.data.totalRecord);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearchValue = useCallback((value) => {
    setSearchVal(value);
  }, []);

  const selectedTypes = (type, selected) => {
    if (type === "brand") {
      setSelectedBrand(selected.id);
    }
    if (type === "bodyType") {
      setSelectedBodyType(selected.id);
    }
    if (type === "carType") {
      setSelectedCarType(selected.id);
    }
  };

  const clearAll = async () => {
    setSelectedBrand("");
    setSelectedBodyType("");
    setSelectedCarType("");
    await fetchData();
  };

  const reloadDataTable = () => {
    Promise.resolve(fetchData()).then(() => {
      setCurrentPage(1);
      // setTotalRecords(filteredRecords);
    });
  };
  console.log("filteredRecords", totalRecords);

  const filterOptions = [
    {
      key: "brand",
      label: "By Brands",
      type: "brand",
      searchKey: "brand_name",
      values: brands,
    },
    {
      key: "bodyType",
      label: "By Body Type",
      type: "bodyType",
      searchKey: "type_name",
      values: bodyType,
    },
    {
      key: "carType",
      label: "By Car Type",
      type: "carType",
      searchKey: "value",
      values: carType,
    },
  ];
  const keys = [
    "id",
    "brand_name",
    "brand_url",
    "model_name",
    "model_url",
    "final_url",
    "update_date",
    "launch_date",
    "starting_price",
    "ending_price",
    "car_type",
    "body_type",
    "popular_position",
    "min_price",
    "max_price",
    "model_image",
    "model_brochure",
    "model_content",
    "gallery_short_code",
    "colors",
    "expert_review",
    "robots_index",
    "robots_follow",
  ];

  const showKeys = [
    "id",
    "brand_name",
    "brand_url",
    "model_name",
    "model_url",
    "final_url",
    "update_date",
    "launch_date",
    "starting_price",
    "ending_price",
    "car_type",
    "body_type",
    "popular_position",
    "min_price",
    "max_price",
    "model_image",
    "model_brochure",
    "model_content",
    "gallery_short_code",
    "colors",
    "expert_review",
    "no  index",
    "no follow",
  ];

  const exportFilter = {
    search: "",
    sortBy: "",
    sortOrder: "",
    page: 1,
    limit: totalRecords,
  };
  if (selectedBrand) {
    exportFilter.brand_id = [selectedBrand];
  }
  if (selectedBodyType) {
    exportFilter.body_type_id = [selectedBodyType];
  }
  if (selectedCarType) {
    exportFilter.car_id = [selectedCarType];
  }

  return (
    <div>
      <Breadcrumbs />
      <Card>
        <Card.Body>
          <Card.Text>
            <div className="w-100">
              <CarModelBtnGroup navigate={navigate} />
              <div className="w-100">
                <div className="d-flex align-items-center justify-content-between gap-2">
                  <CarModelSearchBar
                    onChange={handleSearchValue}
                    value={searchVal}
                    options={filterOptions}
                    selectedTypes={selectedTypes}
                    selectedValues={{
                      brand: selectedBrand,
                      bodyType: selectedBodyType,
                      carType: selectedCarType,
                    }}
                    apply={reloadDataTable}
                    clearAll={clearAll}
                    tableHeading={`Car Models (${totalRecords}) `}
                    searchPlaceholder={"Search"}
                  />
                  <div className="d-flex">
                    <ExportButton
                      api={getModelList}
                      totalRecords={totalRecords}
                      keys={keys}
                      showKeys={showKeys}
                      payload={exportFilter}
                    />
                    <CarModelBtn
                      label={"Add New Car Model"}
                      icon={PlusLargeIcon}
                      className={
                        "add-button d-flex align-items-center justify-content-between mb-2 px-3"
                      }
                      onClick={() => navigate("/car-models/add-car-model")}
                    />
                  </div>
                </div>
              </div>

              <OldTable
                columns={CarColumns(reloadDataTable, currentPage, itemsPerPage)}
                data={originalData}
                currentPage={currentPage}
                onChangePage={(pageNumber) => {
                  setCurrentPage(pageNumber);
                }}
                totalPages={Math.ceil(totalRecords / itemsPerPage)}
                setTotalRecords={setTotalRecords}
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
                showPagination={true}
              ></OldTable>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
