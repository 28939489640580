import axios from "axios";
import { getConfig, BASE_URL_AUTOX } from "../../../request";

export const GET_ALL_BODY_TYPE_LIST = "new-bike/body-type/list";
export const GET_ALL_BRAND_LIST = "new-bike/brand/all/list";
export const GET_MODEL_BY_BODY_TYPE_ID_BRAND_ID =
  "new-bike/model/body-type-brand-specific/";
export const ADD_BIKE_COMPARISON = "new-bike/comparison/add";
export const GET_BIKE_COMPARISONS = "new-bike/comparison/filtered/list";
export const GET_BIKE_COMPARISON_URL = "new-bike/comparison/view/";
export const DELETE_BIKE_COMPARISON = "new-bike/comparison/delete/";
export const GET_BIKE_COMPARISON_BODY_TYPES = "new-bike/comparison/body-type";
export const GET_BIKE_COMAPRISONS_BY_BODY_TYPES =
  "new-bike/comparison/body-type-specific/filtered/list/";
export const BULK_UPDATE_BIKE_COMPARISON_ORDERING =
  "new-bike/comparison/update";

/**
 * Get List of all body types
 * getBodyTypeList is used to get all BIKE body types
 * @param token
 * @param payload
 */
export function getBikeBodyTypeList(token) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_ALL_BODY_TYPE_LIST}`,

    getConfig(token)
  );
}

/**
 * Get List of Brands
 * getAllBrands is used to get data of all brands
 * @param token
 * @param payload
 */
export function getAllBikeBrands(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_ALL_BRAND_LIST}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get List of Models
 * getModelsByBodyTypeBrand is used to get data of models on behalf of body type and brand
 * @param token
 * @param payload
 */
export function getBikeModelsByBodyTypeBrand(token, body_type_id, brand_id) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_MODEL_BY_BODY_TYPE_ID_BRAND_ID}${body_type_id}/${brand_id}`,
    getConfig(token)
  );
}

/**
 * Get List of BIKE Comparisons
 * getBIKEComparison is used to get all BIKE comparisons
 * @param token
 * @param payload
 */
export function getBikeComparison(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_BIKE_COMPARISONS}`,
    payload,
    getConfig(token)
  );
}

/**
 * Add BIKE comparison
 * addBIKEComparison is used to add BIKE comparison
 * @param token
 * @param payload
 */
export function addBikeComparison(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${ADD_BIKE_COMPARISON}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get View link of BIKE comparison
 * getBIKEComparisonViewLink is used to get view link of BIKE comparison
 * @param token
 * @param payload
 */
export function getBikeComparisonViewLink(token, id) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_BIKE_COMPARISON_URL}${id}`,
    getConfig(token)
  );
}

/**
 * Get delete BIKE comparison
 * deleteBIKEComparisonById is used to delete BIKE comparison
 * @param token
 * @param payload
 */
export function deleteBikeComparisonById(token, id) {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_BIKE_COMPARISON}${id}`,
    getConfig(token)
  );
}

/**
 * Get BIKE comparison body types
 * getBIKEComparisonBodyTypes is used to get BIKE comparison body types
 * @param token
 * @param payload
 */
export function getBikeComparisonBodyTypes(token) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_BIKE_COMPARISON_BODY_TYPES}`,
    getConfig(token)
  );
}

/**
 * Get BIKE comparison by body types
 * getBIKEComparisonsByBodyTypes is used to get BIKE comparison by body type
 * @param token
 * @param payload
 */
export function getBikeComparisonsByBodyTypes(token, payload, id) {
  console.log("BIKE body typeid", id);
  return axios.post(
    `${BASE_URL_AUTOX}${GET_BIKE_COMAPRISONS_BY_BODY_TYPES}${id}`,
    payload,
    getConfig(token)
  );
}

/**
 * Update BIKE comparison ordering
 * bulkUpdateBIKEComparisonOrdering is used to update BIKE comparison ordering
 * @param token
 * @param payload
 */
export function bulkUpdateBikeComparisonOrdering(token, payload, id) {
  console.log("test 4567");
  return axios.put(
    `${BASE_URL_AUTOX}${BULK_UPDATE_BIKE_COMPARISON_ORDERING}/${id}`,
    payload,
    getConfig(token)
  );
}

export default {
  getBikeBodyTypeList,
  getAllBikeBrands,
  getBikeModelsByBodyTypeBrand,
  getBikeComparison,
  addBikeComparison,
  getBikeComparisonViewLink,
  deleteBikeComparisonById,
  getBikeComparisonBodyTypes,
  getBikeComparisonsByBodyTypes,
  bulkUpdateBikeComparisonOrdering,
};
