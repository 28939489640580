import { useNavigate } from "react-router-dom";
import HeadingTwo from "../../components/HeadingTwo";
import Table from "../../components/Table";
import EditViewDeleteReviewPassword from "../../components/EditViewDeleteReviewPassword";
import { ReactSVG } from "react-svg";
import SearchIcon from "../../assets/icons/bootstrap-icons/search.svg";
import React, { useState } from "react";
import { getToken } from "../../utility/constants";
import { notifyError, notifySuccess } from "../../utility/common";
import Breadcrumbs from "../../Breadcrumbs";
import { Card } from "react-bootstrap";
import Datatable from "../../components/Table";
import DeleteConfirmationModalComponent from "../../components/DeleteConfirmationModalComponent";
import apiHandler, { deleteEditor } from "./apiHandler.js";
import PlusLargeIcon from "../../assets/icons/bootstrap-icons/plus-lg.svg";
export default function Editors() {
  const [reloadTable, setReloadTable] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editorIdToDelete, setEditorIdToDelete] = useState();
  const navigate = useNavigate();
  const token = getToken();
  const onChangePasswordInner = (id) => {
    navigate("/editor/edit-password/" + id);
  };

  const onEditInner = (id) => {
    navigate("/editor/edit-editor?id=" + id);
  };

  const onDeleteInner = async () => {
    try {
      await deleteEditor(editorIdToDelete, token);
      setShowDeleteModal(false);
      setReloadTable(true);
      notifySuccess("Editor deleted successfully");
    } catch (error) {
      setShowDeleteModal(false);
      notifyError(error.message);
    }
  };

  const columns = [
    {
      name: "Display Name",
      sortable: true,
      selector: (row) => row.display_name,
      width: "auto",
    },
    {
      name: "User Name",
      sortable: true,
      selector: (row) => row.user_name,
      width: "auto",
    },
    {
      name: "Email",
      sortable: true,
      selector: (row) => row.email,
      width: "auto",
    },
    {
      name: "Alternate Email",
      sortable: true,
      selector: (row) => row.alternate_email,
      width: "auto",
    },
    {
      name: "Action",
      sortable: false,
      width: "auto",
      cell: (row) => (
        <EditViewDeleteReviewPassword
          id={row.id}
          onEdit={onEditInner}
          onPasswordChange={onChangePasswordInner}
          onDelete={() => {
            if (row.status !== 2) {
              setShowDeleteModal(true);
              setEditorIdToDelete(row.id);
            }
          }}
        />
      ),
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <Breadcrumbs />
        <button
          className="add-service-centre-button d-flex align-items-center justify-content-between mb-2 px-3"
          onClick={() => navigate("/editor/add-editor")}
        >
          <ReactSVG
            src={PlusLargeIcon}
            height="18px"
            width="18px"
            className="mr-2"
          />{" "}
          Add Editor
        </button>
      </div>
      <Card className="p-3">
        <div>
          <HeadingTwo
            title={"Editors"}
            type={"dashboard"}
            showHeader
            pointerOnHover
          />
        </div>
        <div>
          <Datatable
            columns={columns}
            api={apiHandler.getEditorList}
            reload={reloadTable}
            setReload={setReloadTable}
            setTotalRecords={setTotalPages}
            totalRecords={totalPages}
          />
          <DeleteConfirmationModalComponent
            show={showDeleteModal}
            type={"delete"}
            handleClose={() => setShowDeleteModal(false)}
            setDeleteConfirm={onDeleteInner}
          />
        </div>
      </Card>
    </>
  );
}
