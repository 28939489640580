import { useEffect, useState } from "react";
import Breadcrumbs from "../../../Breadcrumbs";
import adPageAPI, { getAllPopupAds } from "./adPageAPI";
import HeadingTwo from "../../../components/HeadingTwo";
import { useNavigate } from "react-router-dom";
import PopupAdPageForm from "./adPageForm";
import { getToken } from "../../../utility/constants";
import { notifyError } from "../../../utility/common";
export default function AdPage() {
  const [initialData, setInitialData] = useState({});
  const navigate = useNavigate();
  const token = getToken();

  useEffect(() => {
    getAllPopupAds(token)
      .then((res) => {
        // console.log("res", res?.data?.data);
        setInitialData(res?.data?.data);
      })
      .catch((error) => {
        // console.log(error);
        notifyError(error.response.data.message);
      });
  }, []);

  const initialValues = {
    desktop_header_script: initialData[0]?.body_script,
    desktop_top: initialData[1]?.body_script,
    desktop_bottom: initialData[2]?.body_script,
    mobile_header_script: initialData[3]?.body_script,
    mobile_top: initialData[4]?.body_script,
    mobile_bottom: initialData[5]?.body_script
  };

  return (
    <>
      <Breadcrumbs />
      <div className="card p-3">
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-4 col-6 d-flex justify-content-between  sub-heading py-1">
          <HeadingTwo
            title={`Popup ads`}
            type="dashboard"
            showHeader={true}
            pointerOnHover={true}
          />
        </div>
        <PopupAdPageForm initialValues={initialValues} />
      </div>
    </>
  );
}
