import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Accordion, Card, Form, Button } from "react-bootstrap";
import { getPostGalleryById, updatePostGallery } from "./apiHandler";
import { correctImagePath, getToken } from "../../utility/constants";
import { notifyError, notifySuccess } from "../../utility/common";

const EditPostGallery = () => {
  const { id } = useParams(); // Get gallery ID from URL params
  const [loading, setLoading] = useState(true);
  console.log("id", id);
  useEffect(() => {
    fetchGalleryData();
  }, []);

  const fetchGalleryData = async () => {
    try {
      const response = await getPostGalleryById(id, getToken());
      if (response.status === 200) {
        console.log("galleryData", response);
        const galleryData = response.data.data.result[0];
        setFieldValue("galleryName", galleryData.gallery_name);
        setFieldValue("gallerySlug", galleryData.gallery_slug);

        const images = galleryData.images.map((img, index) => ({
          id: img.id,
          blob: correctImagePath(img.file_path),
          caption: img.caption,
          alt: img.alt_text,
          order: img.order_by,
        }));

        setFieldValue("images", images);
      } else {
        notifyError("Failed to fetch gallery data");
      }
    } catch (error) {
      console.log("error", error);
      notifyError("Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  const { handleSubmit, values, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      images: [],
      removedImages: [],
      galleryName: "",
      gallerySlug: "",
    },
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("galleryName", values.galleryName);
      formData.append("gallerySlug", values.gallerySlug);
      formData.append("removedImages", JSON.stringify(values.removedImages));

      values.images.forEach((image, index) => {
        if (image.file) {
          formData.append(`images[${index}][file]`, image.file);
        }
        formData.append(`images[${index}][caption]`, image.caption);
        formData.append(`images[${index}][alt]`, image.alt);
        formData.append(`images[${index}][order]`, image.order);
      });

      try {
        const response = await updatePostGallery(id, formData, getToken());
        if (response.status === 200) {
          notifySuccess("Gallery updated successfully");
        } else {
          notifyError("Failed to update gallery");
        }
      } catch (error) {
        notifyError("Error updating gallery");
      }
    },
  });

  const handleFileChange = (event) => {
    const files = event.currentTarget.files;
    const newImages = Array.from(files).map((file, index) => ({
      file,
      blob: URL.createObjectURL(file),
      caption: "",
      alt: file.name.replace(/\.[^/.]+$/, ""),
      order: values.images.length + index + 1,
    }));

    setFieldValue("images", [...values.images, ...newImages]);
  };

  const handleRemoveImage = (index) => {
    const removedImageId = values.images[index]?.id;
    if (removedImageId) {
      setFieldValue("removedImages", [...values.removedImages, removedImageId]);
    }
    setFieldValue(
      "images",
      values.images.filter((_, i) => i !== index)
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex gap-3" style={{ width: "100%" }}>
        <div className="d-flex flex-column" style={{ width: "70%" }}>
          <Card className="w-100">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Update Gallery</Accordion.Header>
                <Accordion.Body>
                  <Form.Group className="mb-3">
                    <Form.Label>Gallery Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={values.galleryName}
                      onChange={(e) => {
                        setFieldValue("galleryName", e.target.value);
                        setFieldValue(
                          "gallerySlug",
                          e.target.value.toLowerCase().replace(/\s+/g, "-")
                        );
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Gallery Slug</Form.Label>
                    <Form.Control
                      type="text"
                      value={values.gallerySlug}
                      readOnly
                    />
                  </Form.Group>

                  <div className="mb-3">
                    <Form.Label>Post Gallery</Form.Label>
                    <Form.Control
                      type="file"
                      multiple
                      onChange={handleFileChange}
                    />
                  </div>

                  {values.images.map((image, index) => (
                    <div key={index} className="mb-3 d-flex align-items-center">
                      <img
                        src={image.blob}
                        alt={image.alt}
                        style={{ width: "50px", height: "50px" }}
                      />
                      <Form.Control
                        type="text"
                        placeholder="Caption"
                        value={image.caption}
                        onChange={(e) =>
                          setFieldValue(
                            `images.${index}.caption`,
                            e.target.value
                          )
                        }
                        className="ms-2"
                      />
                      <Form.Control
                        type="text"
                        placeholder="Alt Text"
                        value={image.alt}
                        onChange={(e) =>
                          setFieldValue(`images.${index}.alt`, e.target.value)
                        }
                        className="ms-2"
                      />
                      <Form.Control
                        type="number"
                        placeholder="Order"
                        value={image.order}
                        onChange={(e) =>
                          setFieldValue(`images.${index}.order`, e.target.value)
                        }
                        className="ms-2"
                      />
                      <Button
                        variant="danger"
                        onClick={() => handleRemoveImage(index)}
                        className="ms-2"
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Card>
        </div>
      </div>
      <Button type="submit" className="mt-3">
        Update Gallery
      </Button>
    </form>
  );
};

export default EditPostGallery;
