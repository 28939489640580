import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../../request";

const LIST = "new-bike/faq/list";
const ADD_CAR_FAQ = "new-bike/faq/add";
const GET_FAQ = "new-bike/faq/";
const UPDATE_CAR_FAQ = "new-bike/faq/update/";
const DELETE_CAR_FAQ = "new-bike/faq/delete/";

/**
 * Get List of BikeFAQ
 * getAllBikeFAQ is used to get subset data of all brands
 * @param token
 * @param payload
 */
export function getAllBikeFAQs(token, payload) {
  return axios.post(`${BASE_URL_AUTOX}${LIST}`, payload, getConfig(token));
}

/**
 *
 * @param {*} payload
 * @returns
 */

export const addBikeFAQ = (payload) => {
  return axios.post(`${BASE_URL_AUTOX}${ADD_CAR_FAQ}`, payload);
};

export const getBikeFAQbyId = async (token, id) => {
  return axios.get(`${BASE_URL_AUTOX}${GET_FAQ}/${id}`, getConfig(token));
};

export const updateBikeFAQ = (token, id, payload) => {
  return axios.put(
    `${BASE_URL_AUTOX}${UPDATE_CAR_FAQ}${id}`,
    payload,
    getConfig(token)
  );
};

export const deleteBikeFAQ = (token, id) => {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_CAR_FAQ}${id}`,
    getConfig(token)
  );
};
export default {
  getAllBikeFAQs,
  addBikeFAQ,
  getBikeFAQbyId,
  updateBikeFAQ,
  deleteBikeFAQ,
};
