import { Form } from "react-bootstrap";
import Breadcrumbs from "../../Breadcrumbs";
import HeadingTwo from "../../components/HeadingTwo";
import FormControl from "../../components/FormControl";
import { getToken } from "../../utility/constants";
import { useEffect, useState, useRef } from "react";
import { getAdminDetails, updateAdminDetails } from "./profileAPI";
import { useFormik } from "formik";
import { notifySuccess, notifyError } from "../../utility/common";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const [adminDetails, setAdminDetails] = useState({});
  const token = getToken();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchAdminDetails = async () => {
      try {
        const response = await getAdminDetails(token);
        setAdminDetails(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAdminDetails();
  }, []);

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    console.log(selectedFile);
    setAdminDetails({
      ...adminDetails,
      image: selectedFile,
    });
  };

  const handleRemoveImage = () => {
    setAdminDetails({
      ...adminDetails,
      image: null,
    });
  };
  console.log(adminDetails);

  const { handleSubmit, errors, values, touched, setFieldValue } = useFormik({
    initialValues: adminDetails,
    enableReinitialize: true,
    onSubmit: async (values, action) => {
      try {
        const formData = new FormData();
        formData.append("user_image", values.image);
        formData.append("first_name", values.first_name);
        formData.append("last_name", values.last_name);
        formData.append("user_name", values.user_name);

        const res = await updateAdminDetails(token, formData);
        if (res.status === 200) {
          notifySuccess("Profile updated successfully");
          navigate("/dashboard");
        } else {
          notifyError("Something went wrong");
        }
      } catch (error) {
        console.log(error);
        notifyError("Something went wrong");
      }
    },
  });

  return (
    <>
      <Breadcrumbs />
      <div className="card p-3">
        <Form onSubmit={handleSubmit}>
          <HeadingTwo
            title="Account Settings"
            type="dashboard"
            showHeader={true}
            pointerOnHover={true}
          />
          <div className="row">
            <div className="col-lg-6">
              <div className="d-flex align-items-start gap-3">
                <img
                  src={
                    values.image instanceof File
                      ? URL.createObjectURL(values.image)
                      : values.image
                      ? values.image
                      : "https://via.placeholder.com/150"
                  }
                  alt="profile"
                  className="profile-image"
                />
                <div className="">
                  <HeadingTwo
                    title="Profile Picture"
                    type="dashboard"
                    showHeader={true}
                    pointerOnHover={true}
                  />
                  <div className="d-flex gap-3">
                    <button
                      type="button"
                      className="change-photos px-4"
                      onClick={() => fileInputRef.current.click()}
                    >
                      Change Photo
                    </button>
                    <button
                      type="button"
                      className="filter-clear-all px-4"
                      onClick={handleRemoveImage}
                    >
                      Remove
                    </button>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={(e) => {
                        handleImageChange(e);
                        setFieldValue("image", e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="row mb-3">
              <div className="col-lg-5">
                <FormControl
                  controlId={"firstName"}
                  name="first_name"
                  label={"First Name"}
                  placeholder="Enter your first name"
                  touched={touched}
                  errors={errors}
                  isRequired={true}
                  value={values.first_name}
                  setFieldValue={setFieldValue}
                />
              </div>
              <div className="col-lg-5">
                <FormControl
                  controlId={"lastName"}
                  name="last_name"
                  label={"Last Name"}
                  placeholder="Enter your last name"
                  touched={touched}
                  errors={errors}
                  isRequired={true}
                  value={values.last_name}
                  setFieldValue={setFieldValue}
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-lg-5">
                <FormControl
                  title="Email"
                  type="email"
                  label="Email"
                  placeholder="Enter your email"
                  controlId="email"
                  name="email"
                  disabled={true}
                  value={values.email}
                  setFieldValue={setFieldValue}
                  touched={false}
                  errors={false}
                  isRequired={false}
                />
              </div>
              <div class="col-lg-5">
                <FormControl
                  title="Username"
                  type="text"
                  label={"Username"}
                  placeholder="Enter your username"
                  controlId="username"
                  name="username"
                  value={values.user_name}
                  setFieldValue={setFieldValue}
                  touched={false}
                  errors={false}
                  isRequired={false}
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-lg-5">
                <FormControl
                  title="Password"
                  type="password"
                  label={"Password"}
                  placeholder="Enter your password"
                  controlId="password"
                  name="password"
                  value="*********"
                  touched={false}
                  disabled={true}
                  errors={false}
                  isRequired={false}
                />
              </div>
            </div>
          </div>

          <div className="d-flex gap-3 mt-4">
            <button className="change-photos px-4" type="submit">
              Save Changes
            </button>
            <button
              type="button"
              className="filter-clear-all px-4"
              onClick={() => navigate("/dashboard")}
            >
              Cancel
            </button>
          </div>
        </Form>
      </div>
    </>
  );
}
