import { ReactSVG } from "react-svg";
import ViewIcon from "../assets/icons/bootstrap-icons/eye.svg";
import EditIcon from "../assets/icons/bootstrap-icons/pencil.svg";
import DeleteIcon from "../assets/icons/bootstrap-icons/trash3.svg";
import ReviewIcon from "../assets/icons/bootstrap-icons/pencil-square.svg";
import LockIcon from "../assets/icons/bootstrap-icons/lock.svg";

export default function EditViewDelete({
  id,
  onView,
  onEdit,
  onDelete,
  onReview,
  onPasswordChange,
}) {
  return (
    <div className="d-flex gap-2">
      {onEdit ? (
        <div className="action-button blue-btn-action">
          <ReactSVG
            src={EditIcon}
            onClick={() => onEdit(id)}
            style={{ color: "#42B2F2" }}
          />
        </div>
      ) : null}
      {onDelete ? (
        <div className="action-button red-btn-action">
          <ReactSVG
            src={DeleteIcon}
            onClick={() => onDelete(id)}
            style={{ color: "rgb(230, 83, 60)" }}
          />
        </div>
      ) : null}
      {onView ? (
        <div
          className="action-button green-btn-action"
          // href={onReviewLink ? onReviewLink : "#"}
          // target="_blank"
        >
          <ReactSVG
            src={ViewIcon}
            onClick={() => onView(id)}
            style={{ color: "rgb(38, 191, 148)" }}
          />
        </div>
      ) : null}

      {onReview ? (
        <div className="action-button purple-btn-action">
          <ReactSVG
            src={ReviewIcon}
            onClick={() => onReview(id)}
            style={{ color: "rgb(139, 99, 224)" }}
          />
        </div>
      ) : null}

      {onPasswordChange ? (
        <div className="action-button orange-btn-action">
          <ReactSVG
            src={LockIcon}
            onClick={() => onPasswordChange(id)}
            style={{ color: "rgb(255, 172, 28)" }}
          />
        </div>
      ) : null}
    </div>
  );
}
