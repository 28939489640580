import clsx from "clsx";
import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import open_eye from "../assets/icons/bootstrap-icons/auth-images/open-eye.svg";
import close_eye from "../assets/icons/bootstrap-icons/auth-images/hide.svg";
import FormFieldErrorMsg from "./FormFieldErrorMsg";

export default function InputGroup({
  position,
  icon,
  placeholder,
  type,
  touched,
  errors,
  name,
  value,
  setFieldValue,
  auth,
  otp,
}) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div>
      <div className="input-group">
        {position === "left" && (
          <div className="input-group-prepend">
            <ReactSVG className="input-group-icon" src={icon} />
          </div>
        )}
        <input
          type={
            type === "text" || type === "email"
              ? type
              : type === "password"
              ? showPassword
                ? "text"
                : "password"
              : "text"
          }
          placeholder={placeholder}
          value={value}
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
          className={clsx(
            "form-control form-control-lg",
            {
              "is-invalid": touched[name] && errors[name],
            },
            {
              "is-valid": touched[name] && !errors[name],
            },
            {
              "auth-field": auth,
            },
            {
              "otp-input": otp,
            }
          )}
          maxLength={otp ? 1 : undefined}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
          }}
        />

        {position === "right" && (
          <div className="input-group-append">
            <ReactSVG className="input-group-icon" src={icon} />
          </div>
        )}
        {type === "password" && (
          <div className="input-group-append">
            <ReactSVG
              className="input-group-icon cursor-pointer password-eye"
              onClick={() => {
                !showPassword ? setShowPassword(true) : setShowPassword(false);
              }}
              src={showPassword ? open_eye : close_eye}
            />
          </div>
        )}
      </div>

      <FormFieldErrorMsg touched={touched} errors={errors} name={name} />
    </div>
  );
}
