import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../utility/constants";
import SidebarAPI from "./SidebarAPI";
import { notifyError, notifySuccess } from "../../../utility/common";
import DeleteConfirmationModalComponent from "../../../components/DeleteConfirmationModalComponent";
import EditViewDeleteReviewPassword from "../../../components/EditViewDeleteReviewPassword";
export const SideBarColumn = (reloadTable, currentPage, itemsPerPage) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const token = getToken();

  const deleteSideBar = async () => {
    if (deleting) return;

    try {
      setDeleting(true);
      const res = await SidebarAPI.deleteSideBar(token, deleteId);
      notifySuccess("Sidebar deleted successfully");

      reloadTable();
      setModal(false);
      return res;
    } catch (error) {
      console.error("Error deleting Sidebar:", error);
      notifyError("Failed to delete Sidebar");
    } finally {
      setDeleting(false);
      setModal(false);
    }
  };

  const onDelete = (id) => {
    if (deleting) return;
    setDeleteId(id);
    setModal(true);
  };

  const onEdit = (id) => {
    navigate(`/sidebar-edit/${id}`);
  };

  const onViewInner = (id) => {
    navigate(`/widget-edit/${id}`);
  };

  return [
    {
      name: "Sr No.",
      sortable: true,
      selector: (_, index) =>
        index + 1 + currentPage * itemsPerPage - itemsPerPage,
      width: "80px",
    },
    {
      name: "Sidebar Name",
      sortable: true,
      selector: (row) => row.sidebar_name,
      width: "200px",
    },
    {
      name: "Router",
      sortable: true,
      selector: (row) => row.router_location,
      width: "180px",
    },
    {
      name: "Remark",
      sortable: true,
      selector: (row) => row.remark,
      width: "170px",
    },

    {
      name: "Action",
      sortable: false,
      width: "auto",
      id: "Action",
      style: {
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },

      cell: (row) => (
        <div className="d-flex align-items-center justify-content-center">
          <EditViewDeleteReviewPassword
            id={row.id}
            onView={() => onViewInner(row.id)}
            onEdit={() => onEdit(row.id)}
            onDelete={() => onDelete(row.id)}
          />
          <DeleteConfirmationModalComponent
            heading="Delete Sidebar"
            message="Are you sure you want to delete this Sidebar?"
            show={modal && deleteId === row.id}
            handleClose={() => setModal(false)}
            setDeleteConfirm={deleteSideBar}
            type="delete"
          />
        </div>
      ),
    },
  ];
};
