import React from "react";
import Breadcrumbs from "../../Breadcrumbs.js";
import Layout from "../../components/Layout.js";
import EditBikeReviewForm from "./EditBikeUserReview/EditBikeReviewForm.js";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../assets/icons/bootstrap-icons/Back_Arrow.svg";
import { ReactSVG } from "react-svg";

export default function EditBikeUserReview() {
  const navigate = useNavigate();
  return (
    <Layout>
      <div className="row dashboard">
        <div className="d-flex justify-content-between">
          <Breadcrumbs />

          <button
            className="d-flex back-button py-2 px-3 mb-4 btn btn-primary"
            onClick={() => navigate("/bike-user-review")}
          >
            {" "}
            <ReactSVG src={BackIcon} className="me-1" />
            Back
          </button>
        </div>
        <EditBikeReviewForm />
      </div>
    </Layout>
  );
}
