import React from "react";
import { useNavigate } from "react-router-dom";

import { getToken } from "../../utility/constants";
import { ToastContainer, toast } from "react-toastify";
import FAQapiHandler from "./FAQapiHandler";

import CarQuestionForm from "./CarQuestionForm";

export default function AddCarQuestion() {
  const navigate = useNavigate();

  const token = getToken();

  const addCarSubmit = async (data) => {
    try {
      console.log(data);
      const response = await FAQapiHandler.addCarFAQquestion(token, data);
      console.log(response);
      if (response.status === 200) {
        toast.success(response.data.message);
        setTimeout(() => {
          navigate("/car-questions");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };
  const initialValues = {
    question_name: "",
    question_status: -1,
    brand_id: -1,
    model_id: -1,
  };

  return (
    <>
      <CarQuestionForm initialValues={initialValues} onSubmit={addCarSubmit} />
      <ToastContainer />
    </>
  );
}
