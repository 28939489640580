import { Button, Image } from "react-bootstrap";
import BikeBrandForm from "./BikeBrandForm";
import BackIcon from "../../../assets/icons/Back_Arrow.png";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getToken } from "../../../utility/constants";
import { useCallback, useEffect, useState } from "react";
import apiHandler from "./apiHandler";
import { notifyError, notifySuccess } from "../../../utility/common";
import { bikeBrandValidation } from "./schemaValidation";
import { ToastContainer } from "react-toastify";
import Breadcrumbs from "../../../Breadcrumbs";
import { useFormik } from "formik";

export default function EditBikeBrand({ routes }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const token = getToken();
  const [queryParams, setQueryParams] = useSearchParams();
  //eslint-disable-next-line
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    if (queryParams.get("added") && queryParams.get("added") === "true") {
      //eslint-disable-next-line
      setQueryParams({ ["added"]: "false" });
      // notifySuccess("Car Service Centre added successfully")
    } else {
      return;
    }
  }, [queryParams, setQueryParams]);

  const [initialValues, setInitialValues] = useState({
    brand_name: "",
    brand_url: "",
    brand_image: "",
    vehicle_supports: -1,
    brand_content: "",
    brand_ordering: 0,
    canonical_url: "",
    meta_description: "",
    meta_keyword: "",
    meta_title: "",
    robots_follow: -1,
    robots_index: -1,
    short_description: "",
    status: -1,
  });
  const onViewInner = async (id) => {
    apiHandler
      .getBikeBrandViewLink(token, id)
      .then((result) => {
        console.log("link", result);
        window.open(result.data.data, "_blank");
      })
      .catch((error) => {
        notifyError(error.message);
      });
  };
  const preprocessedModelDetails = Object.fromEntries(
    Object.entries(initialValues).map(([key, value]) => [
      key,
      value === null ? "" : value,
    ])
  );

  const { handleSubmit, values, errors, setFieldValue, touched } = useFormik({
    enableReinitialize: true,
    initialValues: preprocessedModelDetails,
    validationSchema: bikeBrandValidation,
    onSubmit: async (values, action) => {
      const payload = values;
      delete payload.id;
      delete payload.created_date;
      delete payload.updated_date;
      const formData = new FormData();
      for (let e of Object.entries(payload)) {
        formData.append(e[0], e[1]);
      }

      apiHandler
        .updateBikeBrand(token, id, payload)
        .then((result) => {
          if (result.status === 200) {
            action.resetForm();
            notifySuccess("Car brand updated successfully");
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          } else {
            const responseData = result.response
              ? result.response.data
              : result.data;
            const errorMessage = responseData.message || "An error occurred";
            notifyError(errorMessage);
          }
        })
        .catch((error) => {
          notifyError(error.message);
        });
    },
  });

  const fetchData = useCallback(async () => {
    apiHandler
      .getBikeBrandById(token, id)
      .then((res) => {
        const response = res.data.data;
        setInitialValues(response);
        // setValue((response.brand_content, "html"));
        setFileName(response.brand_image);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  }, [id, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="row dashboard">
      <ToastContainer />
      <div className="row">
        <div className="col-xl-11 col-sm-11">
          <Breadcrumbs removeId={true} />
        </div>
        <div className="col-xl-1 col-sm-1 ">
          <Button
            varient="primary"
            className="d-flex back-button py-2 px-3 mb-2"
            onClick={() => navigate("/car-brand")}
          >
            <Image
              src={BackIcon}
              width="16px"
              height="16px"
              className="me-1 mt-1"
            />{" "}
            Back
          </Button>
        </div>
      </div>
      <BikeBrandForm
        values={values}
        errors={errors}
        touched={touched}
        handleSubmit={handleSubmit}
        setFieldValue={setFieldValue}
        type={"Update"}
        onView={onViewInner}
      />
    </div>
  );
}
