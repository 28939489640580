import { Accordion, Form, Overlay, Tooltip } from "react-bootstrap";
import {
  metaRobotsIndex,
  renderDateNumerical,
} from "../../../../utility/constants";
import { ReactSVG } from "react-svg";
import PlusLargeIcon from "../../../../assets/icons/bootstrap-icons/plus-lg.svg";
import RemoveIcon from "../../../../assets/icons/bootstrap-icons/x-lg.svg";
import { carType } from "../../../../utility/constants";
import FormControl from "../../../../components/FormControl";
import FormFieldErrorMsg from "../../../../components/FormFieldErrorMsg";
import FormSelect from "../../../../components/FormSelect";
import { useRef } from "react";
import JoditEditor from "jodit-react";
import DefaultProsAndConsTemplateContent from "../../../Template/ProsAndConsTemplate";
const AccordianGroup = ({
  showTooltip1,
  setShowTooltip1,
  showTooltipTwo,
  setShowTooltipTwo,
  target,
  touched,
  errors,
  values,
  setFieldValue,

  modelFAQ,
  customFAQs,
  setCustomFAQs,
  handleUpdateFAQ,
  handleAddFAQInput,

  custom_faq_removed,
  setCustom_faq_removed,
}) => {
  const htmlString = "Editorial Supports <img> and <br> tags only";
  const editor = useRef(null);
  const upcomingPreview = useRef(null);
  const proscons = useRef(null);

  const handleRemoveFAQ = (index) => {
    const updatedFAQs = [...customFAQs];
    const removedFAQ = updatedFAQs.splice(index, 1)[0];
    setCustomFAQs(updatedFAQs);
    setCustom_faq_removed([...custom_faq_removed, removedFAQ.id]);
  };

  console.log(values, "shambles");

  return (
    <>
      <div className="col-xl-9 col-sm-8">
        <Accordion defaultActiveKey="0" className="mb-4">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="p-3 pb-1 ps-2">
              Primary Details
            </Accordion.Header>

            <Accordion.Body>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="mb-4" controlId="modelName">
                    <FormControl
                      controlId={"modelName"}
                      label={"Model Name"}
                      placeholder={"Model Name"}
                      touched={touched}
                      errors={errors}
                      name={"model_name"}
                      value={values.model_name}
                      setFieldValue={setFieldValue}
                      className="mb-4"
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="modelUrl">
                    <FormControl
                      controlId={"modelUrl"}
                      label={"Model Url"}
                      placeholder={"Model Url"}
                      touched={touched}
                      errors={errors}
                      name={"model_url"}
                      value={values.model_url}
                      setFieldValue={setFieldValue}
                      className="mb-4"
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="StatusControlSelect">
                    <Form.Label>
                      Select Url Status <sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) =>
                        setFieldValue("bike_type", e.target.value)
                      }
                      value={values.bike_type}
                      className="mb-4"
                      name="bike_type"
                    >
                      {carType.map((type, index) => (
                        <option key={index} value={type.id}>
                          {type.value}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="StatusControlSelect">
                    <Form.Label>
                      Date <sup className="text-danger">*</sup> (dd-mm-yyyy)
                    </Form.Label>
                    {console.log(values.model_date, "room")}
                    <Form.Control
                      type="date"
                      name="model_date"
                      pattern="\d{4}-\d{2}-\d{2}"
                      placeholder="YYYY-MM-DD"
                      value={renderDateNumerical(values.model_date)}
                      // value={renderDateNumerical(values.updated_launch_date)}
                      onChange={(e) =>
                        setFieldValue("model_date", e.target.value)
                      }
                    />
                  </Form.Group>
                </div>
                {values.bike_type == 1 && (
                  <>
                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="modelUrl">
                        <Form.Label>
                          Expected Price <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder={"Expected Price"}
                          value={values.starting_price}
                          onChange={(e) =>
                            setFieldValue("starting_price", e.target.value)
                          }
                          name="starting_price"
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="modelUrl">
                        <Form.Label>
                          Ending Price <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder={"Ending Price"}
                          value={values.ending_price}
                          onChange={(e) =>
                            setFieldValue("ending_price", e.target.value)
                          }
                          name="ending_price"
                        />
                      </Form.Group>
                    </div>
                  </>
                )}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion defaultActiveKey="0" className="mb-4">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="p-3 pb-1 ps-2">
              Secondary Details
            </Accordion.Header>

            <Accordion.Body>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="colorCode">
                    <FormControl
                      controlId={"colorCode"}
                      label={"Color name and color code"}
                      placeholder={"Color name and color code"}
                      touched={touched}
                      errors={errors}
                      name={"colors"}
                      value={values.colors}
                      setFieldValue={setFieldValue}
                      className="mb-4"
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="popularity">
                    <FormControl
                      controlId={"popularity"}
                      label={"Popularity"}
                      placeholder={"Popularity"}
                      touched={touched}
                      errors={errors}
                      name={"popular_position"}
                      value={values.popular_position}
                      setFieldValue={setFieldValue}
                      className="mb-4"
                    />
                  </Form.Group>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {values.bike_type == 1 && (
          <Accordion defaultActiveKey="0" className="mb-4">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="p-3 pb-1 ps-2 acrodian-btn">
                Model Preview <sup className="required-superscript">*</sup>
              </Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <JoditEditor
                        ref={upcomingPreview}
                        value={values.model_preview}
                        onBlur={(value) =>
                          setFieldValue("model_preview", value)
                        }
                      />
                    </Form.Group>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}

        <Accordion defaultActiveKey="0" className="mb-4">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="p-3 pb-1 ps-2">
              Model Content
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#2a99ee"
                class="bi bi-question-circle-fill"
                viewBox="0 0 16 16"
                className="ms-2"
                onClick={(event) => {
                  setShowTooltip1(!showTooltip1);
                  event.stopPropagation();
                }}
                ref={target}
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247m2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
              </svg>
              <div className="bg-danger">
                <Overlay
                  target={target.current}
                  show={showTooltip1}
                  placement="right"
                >
                  {(props) => (
                    <Tooltip
                      id="overlay-example"
                      {...props}
                      className="text-danger"
                    >
                      My Tooltip
                    </Tooltip>
                  )}
                </Overlay>
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <div className="row">
                <div className="col-md-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <JoditEditor
                      ref={editor}
                      value={values["model_content"]}
                      onBlur={(value) => {
                        setFieldValue("model_content", value);
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion defaultActiveKey="0" className="mb-4">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="p-3 pb-1 ps-2">
              Model Editorial Review
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#2a99ee"
                class="bi bi-question-circle-fill"
                viewBox="0 0 16 16"
                className="ms-2"
                onClick={(event) => {
                  setShowTooltipTwo(!showTooltipTwo);
                  event.stopPropagation();
                }}
                ref={target}
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247m2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
              </svg>
              <div className="bg-danger">
                <Overlay
                  target={target.current}
                  show={showTooltipTwo}
                  placement="right"
                >
                  {(props1) => (
                    <Tooltip
                      id="overlay-example2"
                      {...props1}
                      className="text-danger "
                    >
                      My Tooltip
                    </Tooltip>
                  )}
                </Overlay>
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <div className="row">
                <div className="col-md-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <JoditEditor
                      ref={editor}
                      value={values["model_editorial_review"]}
                      onBlur={(newContent) => {
                        setFieldValue("model_editorial_review", newContent);
                      }}
                    />
                  </Form.Group>
                </div>
              </div>

              <Form.Label className="fw-light">
                Editorial Supports / {htmlString}
              </Form.Label>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion defaultActiveKey="0" className="mb-4">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="p-3 pb-1 ps-2 acrodian-btn">
              Model Pros and Cons<sup className="required-superscript"></sup>
            </Accordion.Header>
            <Accordion.Body>
              <div className="row">
                <div className="col-md-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <JoditEditor
                      ref={proscons}
                      value={values["model_prosncons"]}
                      onBlur={(value) => {
                        setFieldValue("model_prosncons", value);
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
              <Form.Label className="fw-light d-flex justify-content-between">
                Pros / Cons {htmlString}
                <button
                  type="button"
                  className="add-button mat-0"
                  onClick={(e) => {
                    setFieldValue(
                      "model_prosncons",
                      DefaultProsAndConsTemplateContent[0]
                    );
                  }}
                >
                  <span>Default</span>
                </button>
              </Form.Label>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion defaultActiveKey="0" className="mb-4">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="p-3 pb-1 ps-2">
              Model Seo Tags
            </Accordion.Header>

            <Accordion.Body>
              <div className="row">
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="seoTitleInput">
                    <FormControl
                      controlId={"seoTitle"}
                      label={"Seo Title"}
                      placeholder={"Seo Title"}
                      touched={touched}
                      errors={errors}
                      name={"meta_title"}
                      value={values.meta_title}
                      setFieldValue={setFieldValue}
                      className="mb-4"
                    />
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="metaDescriptionInput">
                    <Form.Label>Meta Description </Form.Label>
                    <Form.Control
                      as="textarea"
                      aria-label="With textarea"
                      onChange={(e) =>
                        setFieldValue("meta_description", e.target.value)
                      }
                      value={values.meta_description}
                      name="meta_description"
                      placeholder="Meta Description"
                    />
                    <FormFieldErrorMsg
                      touched={touched}
                      name="meta_description"
                      errors={errors}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="StatusControlSelect">
                    <Form.Label>Meta Keywords </Form.Label>
                    <Form.Control
                      as="textarea"
                      aria-label="With textarea"
                      onChange={(e) =>
                        setFieldValue("meta_keyword", e.target.value)
                      }
                      value={values.meta_keyword}
                      name="meta_keyword"
                      placeholder="Meta Keywords"
                    />
                    <FormFieldErrorMsg
                      touched={touched}
                      name="meta_keyword"
                      errors={errors}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="StatusControlSelect">
                    <Form.Label>Canonical URL</Form.Label>
                    <Form.Control
                      as="textarea"
                      aria-label="With textarea"
                      onChange={(e) =>
                        setFieldValue("canonical_url", e.target.value)
                      }
                      value={values.canonical_url}
                      name="canonical_url"
                      placeholder="Canonical URL"
                    />
                    <FormFieldErrorMsg
                      touched={touched}
                      name="canonical_url"
                      errors={errors}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="StatusControlSelect">
                    <Form.Label>Meta Robots Index</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="robots_index"
                      value={values.robots_index}
                      onChange={(e) =>
                        setFieldValue("robots_index", e.target.value)
                      }
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="index">Index</option>
                      <option value="noindex">No Index</option>
                    </Form.Select>
                    <FormFieldErrorMsg
                      touched={touched}
                      name="robots_index"
                      errors={errors}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="StatusControlSelect">
                    <Form.Label>Meta Robots Follow</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="robots_follow"
                      value={values.robots_follow}
                      onChange={(e) =>
                        setFieldValue("robots_follow", e.target.value)
                      }
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="Follow">Follow</option>
                      <option value="nofollow">No Follow</option>
                    </Form.Select>
                    <FormFieldErrorMsg
                      touched={touched}
                      name="robots_index"
                      errors={errors}
                    />
                  </Form.Group>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion defaultActiveKey="0" className="mb-4">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="p-3 pb-1 ps-2 acrodian-btn">
              Model FAQ Tags
            </Accordion.Header>
            <Accordion.Body>
              {modelFAQ.map((item, index) => (
                <Accordion key={index} className="mb-3">
                  <Accordion.Item eventKey={index.toString()}>
                    <Accordion.Header>
                      <Form.Check
                        label={item.question}
                        className="checklist"
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          if (isChecked) {
                            setFieldValue("faq", [...values.faq, item.id]);
                          } else {
                            const faqIndex = values.faq.indexOf(item.id);
                            if (faqIndex !== -1) {
                              const filteredFaq = [...values.faq];
                              filteredFaq.splice(faqIndex, 1);
                              setFieldValue("faq", filteredFaq);
                            }
                          }
                        }}
                        checked={values.faq.includes(item.id)}
                        type="checkbox"
                      />
                    </Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion defaultActiveKey="0" className="mb-4">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="p-3 pb-1 ps-2 acrodian-btn">
              Model Custom FAQs{" "}
              <button
                type="button"
                className="add-button mat-0 rightsidebtn"
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddFAQInput();
                }}
              >
                <span>Add FAQ</span>
              </button>
            </Accordion.Header>
            <Accordion.Body className="pe-5">
              {customFAQs.map((faq, index) => (
                <div className="row" key={index}>
                  <div className="col-lg-10 col-sm-9 col-8">
                    <Form.Group
                      className="mb-3"
                      controlId={`questionInput-${index}`}
                    >
                      <Form.Control
                        type="text"
                        placeholder="Enter Question"
                        value={faq.question}
                        onChange={(e) =>
                          handleUpdateFAQ(index, "question", e.target.value)
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId={`answerInput-${index}`}
                    >
                      <Form.Control
                        type="text"
                        placeholder="Enter Answer"
                        value={faq.answer}
                        onChange={(e) =>
                          handleUpdateFAQ(index, "answer", e.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-2 col-sm-3 col-4 d-flex align-items-center">
                    <button
                      type="button"
                      className="remove-button d-flex align-items-center px-2"
                      onClick={() => handleRemoveFAQ(index)}
                    >
                      <span className="remove-text">Remove</span>
                    </button>
                  </div>
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>{" "}
    </>
  );
};

export default AccordianGroup;
