import { useNavigate } from "react-router-dom";
import HeadingTwo from "../../../components/HeadingTwo";
import Table from "../../../components/Table";
import EditViewDeleteReviewPassword from "../../../components/EditViewDeleteReviewPassword";
import { ReactSVG } from "react-svg";
import SearchIcon from "../../../assets/icons/bootstrap-icons/search.svg";
import FilterIcon from "../../../assets/icons/bootstrap-icons/funnel.svg";
import React, { useState } from "react";
import Datatable from "../../../components/Table";
import apiHandler from "./apiHandler";
import { BikeFAQColumn } from "./BikeFAQColumn";
import { FAQcustomStyles } from "./FAQtableStyle";
import CarModelBtn from "../../../components/CarModelBtn";
import PlusLargeIcon from "../../../assets/icons/bootstrap-icons/plus-lg.svg";

export function BikeFAQs({ children }) {
  const [bikeFAQs, setBikeFAQs] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();

  const reloadTable = () => {
    setReload(!reload);
  };

  return (
    <div className="row dashboard">
      <div className="card p-3">
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-4 col-6 d-flex justify-content-between mb-2 sub-heading">
          <HeadingTwo
            title="Bike FAQ List"
            type="dashboard"
            showHeader={true}
            pointerOnHover={true}
          />
          <CarModelBtn
            label={"Add Bike FAQ"}
            icon={PlusLargeIcon}
            onClick={() => navigate("/add-bike-faq")}
            className={
              "add-button d-flex align-items-center justify-content-between mb-2 px-3"
            }
          />
        </div>
        <Datatable
          api={apiHandler.getAllBikeFAQs}
          columns={BikeFAQColumn(reloadTable, currentPage, itemsPerPage)}
          search={""}
          defaultSortColumn={""}
          reload={reload}
          setReload={setReload}
          setTotalRecords={setTotalRecords}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
          isDraggable={true}
          column_ordering={""}
          styling={FAQcustomStyles}
        ></Datatable>
      </div>
    </div>
  );
}
