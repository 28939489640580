import React, { useState, useEffect, useCallback } from "react";
import DataTable from "react-data-table-component";
import Loader from "./Loader";
import { getToken } from "../utility/constants";
import CustomPagination from "./CustomPagination";

import $ from "jquery";
import "jquery-ui-dist/jquery-ui";

import { notifyError } from "../utility/common";

function DatatableLoader() {
  return (
    <>
      <div style={{ padding: "100px" }}>
        <Loader size="100px" color="danger" />
      </div>
    </>
  );
}

export default function Datatable(props) {
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortByColumn, setSortByColumn] = useState(props.defaultSortColumn);
  const [sortByDir, setSortByDir] = useState("asc");
  const [isInitialize, setIsInitialize] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [dropId, setDropId] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const token = getToken();
  const { sortFieldNames, search, filter } = props;
  const [diff, setDiff] = useState([]);
  const [hasReordered, setHasReordered] = useState(false); // New flag

  const {
    api,
    car_body_type_id,
    columns,
    filterText,
    reload,
    key,
    setReload,
    setTotalRecords,
    updateOrderingHandler,
    isDraggable,
  } = props;

  const fetchApiData = useCallback(
    async (
      page,
      size = perPage,
      sortBy = sortByColumn,
      sortOrder = sortByDir,
      searchKw = search
    ) => {
      let response = [];

      response = await api(
        token,
        { search: searchKw, limit: size, sortBy, sortOrder, page, filter },
        car_body_type_id
      );

      setData(response.data.data.result);
      setTotalRows(response.data.data.totalRecord);

      setIsInitialize(true);
    },
    [
      token,
      perPage,
      sortByColumn,
      sortByDir,
      search,
      filter,
      api,
      car_body_type_id,
    ]
  );
  useEffect(() => {
    setTotalRecords();
    const totalPages = Math.ceil(totalRows / perPage);
    setTotalPages(totalPages);
  }, [totalRows, setTotalRecords, perPage]);

  useEffect(() => {
    fetchApiData(1).catch((err) => {});
  }, [sortByColumn, sortByDir, fetchApiData]);

  useEffect(() => {
    setCurrentPage(1);
    fetchApiData(1, perPage, sortByColumn, sortByDir, filterText).catch(
      (err) => {}
    );
  }, [filterText, perPage, sortByColumn, sortByDir, fetchApiData]);

  useEffect(() => {
    if (reload) {
      fetchApiData(1).catch((err) => {
        console.log("klkl", err);
      });
      setCurrentPage(1);
      setReload(false);
    }
  }, [fetchApiData, reload, setReload]);
  const handlePageChange = (page) => {
    if (isInitialize !== false) {
      fetchApiData(page).catch((err) => {});
      setCurrentPage(page);
    }
  };

  const handlePerRowsChange = useCallback(
    (newPerPage, page) => {
      if (isInitialize !== false) {
        fetchApiData(page, newPerPage).catch((err) => {});
        setPerPage(newPerPage);
      }
    },
    [isInitialize, fetchApiData]
  );

  const handleSort = (column, sortDirection) => {
    setSortByDir(sortDirection);
    setSortByColumn(sortFieldNames[column.name]);
  };

  const reorderRowInner = useCallback(() => {
    if (hasReordered) return; // If already reordered, exit early
    setHasReordered(true); // Set the flag to prevent further calls
    const updateOrderingArray = diff.map((obj) => ({
      [data[obj.oldPosition].id]: {
        [props.column_ordering]: obj.newPosition + 1,
      },
    }));

    if (updateOrderingArray.length) {
      updateOrderingHandler(token, updateOrderingArray, car_body_type_id)
        .then(() => {
          fetchApiData(currentPage);
          setDiff([]); // Clear diff after processing
        })
        .catch((error) => notifyError(error.message))
        .finally(() => {
          setHasReordered(false); // Reset flag
          setDiff([]);
        });
    } else {
      setHasReordered(false); // Reset flag if no update needed
    }
  }, [
    diff,
    updateOrderingHandler,
    token,
    fetchApiData,
    currentPage,
    perPage,
    data,
    props.column_ordering,
    hasReordered,
  ]);

  useEffect(() => {
    $(".rdt_TableRow").each(function () {
      $(this).addClass(`dragging-div-${car_body_type_id}`);
    });
    if (isDraggable) {
      const handleMouseDown = function () {
        $(this).prop("selected", true);
      };

      const handleDragStart = function () {
        const start_row_index = $(this).index();
        setActiveId(start_row_index);
      };

      const handleDrop = function () {
        const drop = $(this).index();
        setDropId(drop);
      };

      const handleDragEnd = function () {
        if (activeId !== dropId) {
          const array = [];
          if (activeId < dropId) {
            array.push({ oldPosition: activeId, newPosition: dropId });
            for (let i = activeId + 1; i <= dropId; i++) {
              array.push({ oldPosition: i, newPosition: i - 1 });
            }
          } else if (activeId > dropId) {
            array.push({ oldPosition: activeId, newPosition: dropId });
            for (let i = activeId - 1; i >= dropId; i--) {
              array.push({ oldPosition: i, newPosition: i + 1 });
            }
          }
          setDiff(array);
        }
        setActiveId(null);
        setDropId(null);
      };

      $(`.dragging-div-${car_body_type_id}`).on("mousedown", handleMouseDown);
      $(`.dragging-div-${car_body_type_id}`).on("dragstart", handleDragStart);

      $(`.dragging-div-${car_body_type_id}`).on("drop", handleDrop);
      $(`.dragging-div-${car_body_type_id}`).on("dragend", handleDragEnd);

      return () => {
        $(`.dragging-div-${car_body_type_id}`).off(
          "mousedown",
          handleMouseDown
        );
        $(`.dragging-div-${car_body_type_id}`).off(
          "dragstart",
          handleDragStart
        );
        $(`.dragging-div-${car_body_type_id}`).off("drop", handleDrop);
        $(`.dragging-div-${car_body_type_id}`).off("dragend", handleDragEnd);
      };
    }
  }, [data, isDraggable, dropId]);
  useEffect(() => {
    if (diff.length > 0) {
      console.log("Reordering rows with diff:", diff); // Debugging statement
      reorderRowInner();
    }
  }, [diff, reorderRowInner]);
  console.log(car_body_type_id, "bpdy_type");
  return (
    <div className="mt-4">
      {" "}
      <DataTable
        columns={columns}
        data={data}
        progressPending={null}
        progressComponent={<DatatableLoader />}
        pagination
        paginationServer
        // paginationTotalRows={totalRows}
        paginationDefaultPage={currentPage}
        paginationComponent={(props) => (
          <CustomPagination
            totalPages={totalPages}
            currentPage={currentPage}
            onChangePage={(page) => setCurrentPage(page)}
            {...props}
          />
        )}
        subHeader={props.showHeader}
        subHeaderComponent={props.header(props.type_name, totalRows)}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        sortByDir={"asc"}
        // sortIcon={<ReactSVG src={DropUpIcon} className="dropup-icon" />}
        dense
        className="table-styles grab"
        draggable={true}

        // striped
      >
        {(props) => {
          console.log("data table props", props);
        }}
      </DataTable>
    </div>
  );
}
