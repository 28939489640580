import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button, Form } from "react-bootstrap";
import { deleteImage, imagesListing, updateImageDetails } from "../apiHandler";
import { getToken } from "../../../utility/constants";

const FileSizeConvert = (bytes) => {
  const units = [
    { unit: "TB", value: Math.pow(1024, 4) },
    { unit: "GB", value: Math.pow(1024, 3) },
    { unit: "MB", value: Math.pow(1024, 2) },
    { unit: "KB", value: 1024 },
    { unit: "B", value: 1 },
  ];

  for (let i = 0; i < units.length; i++) {
    if (bytes >= units[i].value) {
      return (bytes / units[i].value).toFixed(1) + " " + units[i].unit;
    }
  }
  return "0 B"; // Fallback if no size found
};
const ImageListing = forwardRef(
  ({ onSelectImage, setShow, type, propSelectedImage }, ref) => {
    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const token = getToken();

    // Reset the images and pagination if search term changes
    useEffect(() => {
      if (searchTerm === "" || propSelectedImage === null) {
        setImages([]); // Clear images if the search term is cleared

        setHasMore(true); // Reset pagination
      } else {
        setPage(1); // Reset to the first page when search term changes
      }
    }, [searchTerm, propSelectedImage]);

    const fetchImages = useCallback(
      async (pageNum) => {
        if (!token) {
          console.log("No token available. Aborting fetch.");
          return;
        }

        try {
          const response = await imagesListing(token, {
            pageNum,
            search: propSelectedImage ? "" : searchTerm, // Use searchTerm directly
            selectedImageUrl: propSelectedImage
              ? propSelectedImage.replace("https://images.autox.com/", "")
              : "",
          });

          if (!response || !response.data || !response.data.result) {
            console.error("Invalid response structure or empty data.");
            return;
          }

          const fetchedImages = response.data.result;

          if (fetchedImages.length === 0) {
            setHasMore(false);
          }

          // Fetch dimensions and sizes for each image
          const imagesWithDetails = await Promise.all(
            fetchedImages.map(async (img) => {
              const imageUrl =
                img.image.startsWith("https://autox-gallery") ||
                img.image.startsWith("https://s3.ap-south-1")
                  ? img.image.startsWith("https://autox.com")
                    ? img.image.replace(`autox`, `images.autoxmag`)
                    : img.image
                  : `https://images.autox.com/${img.image}`;

              const imgElement = new Image();
              imgElement.src = imageUrl;

              return new Promise((resolve) => {
                imgElement.onload = async () => {
                  try {
                    if (imageUrl.includes("amazonaws")) {
                      const [width, height, img_size] = imageUrl
                        .substring(
                          imageUrl.lastIndexOf("-") + 1,
                          imageUrl.lastIndexOf(".")
                        )
                        .split("x");
                      let dimensions =
                        !isNaN(width) && !isNaN(height)
                          ? `${width} x ${height}`
                          : "";
                      let size = !isNaN(img_size) && `${img_size} KB`;

                      const imageDetails = { ...img, size, dimensions };

                      if (imageUrl === propSelectedImage) {
                        setSelectedImage(imageDetails); // Set selected image if URLs match
                      }

                      resolve({
                        ...img,
                        size,
                        dimensions,
                      });
                    } else {
                      const response = await fetch(imageUrl);
                      console.log("blob......", response);
                      const blob = await response.blob();
                      let size = FileSizeConvert(blob.size);
                      let dimensions = `${imgElement.width} x ${imgElement.height}`;

                      const imageDetails = { ...img, size, dimensions };
                      console.log("propSelectedImage.....1", imageUrl);
                      console.log("propSelectedImage.....2", propSelectedImage);
                      if (imageUrl === propSelectedImage) {
                        setSelectedImage(imageDetails); // Set selected image if URLs match
                      }

                      resolve({
                        ...img,
                        size,
                        dimensions,
                      });
                    }
                  } catch (error) {
                    console.error(
                      "Error fetching image details for",
                      imageUrl,
                      error
                    );
                    resolve({
                      ...img,
                      size: null,
                      dimensions: null,
                    });
                  }
                };

                imgElement.onerror = () => {
                  console.error("Error loading image", imageUrl);
                  resolve({
                    ...img,
                    size: null,
                    dimensions: null,
                  });
                };
              });
            })
          );

          setImages((prevImages) => {
            // const imageIds = new Set(prevImages.map((img) => img.id));
            // console.log(imagesWithDetails, "prevImages");
            // console.log(imageIds, "imageIds");
            // const newImages = imagesWithDetails.filter(
            //   (img) => !imageIds.has(img.id)
            // );

            // if (searchTerm) {
            //   return newImages; // Keep the previous images and add new ones.
            //   // If a new search term is performed, reset the images
            // }

            // return [...prevImages, ...newImages]; // Append new images for pagination

            return [...imagesWithDetails]; // Append new images for pagination
          });
        } catch (error) {
          console.error("Error fetching images", error);
        }
      },
      [searchTerm, token, propSelectedImage]
    );

    useImperativeHandle(ref, () => ({
      fetchImages,
    }));

    useEffect(() => {
      if (token) {
        fetchImages(page); // Fetch images every time page or searchTerm changes
      }
    }, [page, token, searchTerm, propSelectedImage]); // Fetch images when searchTerm changes

    const handleSelect = (image) => {
      setSelectedImage({
        ...image,
        originalTitle: image.title, // Store the original title
        originalCaption: image.image_caption || "",
      });
    };

    const handleInsert = async () => {
      if (selectedImage) {
        // Check if title or caption has changed
        const isTitleChanged =
          selectedImage.title !== selectedImage.originalTitle;
        const isCaptionChanged =
          selectedImage.image_caption !== selectedImage.originalCaption;

        if (!isTitleChanged && !isCaptionChanged) {
          onSelectImage(selectedImage); // Just pass the selected image as is
          setShow(false);
          return;
        }

        try {
          if (isTitleChanged || isCaptionChanged) {
            await updateImageDetails(
              selectedImage.id,
              selectedImage.title,
              selectedImage.image_caption,
              token
            );
          }

          onSelectImage(selectedImage); // Pass updated image
          setShow(false); // Close modal or panel after insertion
        } catch (error) {
          console.error("Failed to update image details:", error);
        }
      }
    };

    const onDelete = async (imageId) => {
      const confirmed = window.confirm(
        "Are you sure you want to delete this image?"
      );
      if (!confirmed) {
        return; // Exit if the user clicks "Cancel"
      }

      try {
        await deleteImage(imageId, token);
        const updatedImages = images.filter((image) => image.id !== imageId);
        setImages(updatedImages);
        setSelectedImage(null);
      } catch (error) {
        console.error(error);
      }
    };

    const handleScroll = (e) => {
      const bottom =
        e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
      if (bottom && hasMore) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    return (
      <div className="image-listing">
        <div className="d-flex flex-column align-items-end gap-3">
          <div className="search-bar">
            <Form.Control
              type="text"
              placeholder="Search images..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
          </div>
          <div
            className="image-grid"
            onScroll={handleScroll}
            style={{ height: "400px", overflowY: "auto" }}
          >
            {images.map((image) => (
              <div
                key={image.id}
                className={`image-item ${
                  selectedImage?.id === image.id ? "selected" : ""
                }`}
                onClick={() => handleSelect(image)}
              >
                <img
                  src={
                    image.image.startsWith("https://autox-gallery") ||
                    image.image.startsWith("https://autox") ||
                    image.image.startsWith("https://s3.ap-south-1")
                      ? image.image
                      : `https://images.autox.com/${image.image}`
                  }
                  alt={image.title}
                  className="image-box"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="image-info-container">
          {selectedImage && (
            <div className="image-info">
              <h5>Image Info</h5>
              <p>Dimensions: {selectedImage.dimensions}</p>
              <p>Size: {selectedImage.size}</p>
              <span
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => onDelete(selectedImage.id)}
              >
                Delete Image
              </span>
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedImage.title}
                  onChange={(e) =>
                    setSelectedImage({
                      ...selectedImage,
                      title: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group aria-disabled>
                <Form.Label>URL</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedImage.image}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Caption</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedImage.image_caption || ""}
                  onChange={(e) =>
                    setSelectedImage({
                      ...selectedImage,
                      image_caption: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Button className="mt-3" onClick={handleInsert}>
                Insert into {type}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default ImageListing;
