import axios from "axios";
import React, { useState } from "react";
import * as XLSX from "xlsx"; // For reading Excel files
import Papa from "papaparse"; // For reading CSV files
import { BASE_URL_AUTOX } from "../request";
import { notifyError, notifySuccess } from "../utility/common";

export default function BulkImportVersion({ setReload, type }) {
  const [file, setFile] = useState(null);

  const addVersion = async (formData) => {
    try {
      const response = await axios.post(
        type === "car"
          ? `${BASE_URL_AUTOX}car/version/importCSV`
          : `${BASE_URL_AUTOX}new-bike/version/importCSV`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        notifySuccess(
          type === "car"
            ? "Car Version imported successfully"
            : "Bike Version imported successfully"
        );
        setReload(true);
      } else {
        notifyError("Failed to import data");
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Import failed";
      notifyError(errorMessage);
    }
  };

  const handleImport = async () => {
    if (!file) {
      return;
    }

    const formData = new FormData();
    const importName =
      type === "car" ? "car_version_import" : "bike_version_import";
    formData.append(`${importName}`, file);

    addVersion(formData);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <div className="d-flex">
      <input
        type="file"
        onChange={handleFileChange}
        className="form-control shadow-none file-input m-0 me-3"
      />
      <button className="import-btn align-items-center" onClick={handleImport}>
        Import
      </button>
    </div>
  );
}

// // {
//   import axios from "axios";
//   import React, { useState } from "react";
//   import * as XLSX from "xlsx"; // For reading Excel files
//   import Papa from "papaparse"; // For reading CSV files
//   import { BASE_URL_AUTOX } from "../request";
//   import { notifyError, notifySuccess } from "../utility/common";
//   import specificationColumns from "../../src/utility/specification.json";
//   import featureColumns from "../../src/utility/features.json";

//   export default function BulkImportVersion({ setReload }) {
//     const [file, setFile] = useState(null);

//     const addVersion = async (data) => {
//       try {
//         console.log(data);
//         const response = await axios.post(
//           ${BASE_URL_AUTOX}car/version/importCSV,
//           data,
//           {
//             headers: {
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (response.status === 200) {
//           notifySuccess("Car Version imported successfully");
//           setReload(true);
//         } else {
//           notifyError("Failed to import data");
//         }
//       } catch (error) {
//         const errorMessage = error.response?.data?.message || "Import failed";
//         notifyError(errorMessage);
//       }
//     };

//     const handleImport = async () => {
//       if (!file) {
//         return;
//       }

//       const fileExtension = file.name.split(".").pop().toLowerCase();
//       console.log(fileExtension);
//       if (fileExtension === "xls" || fileExtension === "xlsx") {
//         readExcelFile(file);
//       } else if (fileExtension === "csv") {
//         readCSVFile(file);
//       } else {
//         notifyError("Unsupported file type");
//       }
//     };

//     const readExcelFile = (file) => {
//       const reader = new FileReader();
//       reader.onload = (e) => {
//         const data = e.target.result;
//         const workbook = XLSX.read(data, { type: "binary" });
//         const sheetName = workbook.SheetNames[0];
//         const worksheet = workbook.Sheets[sheetName];
//         const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

//         processData(json);
//       };

//       reader.readAsBinaryString(file);
//     };

//     const readCSVFile = (file) => {
//       const reader = new FileReader();
//       reader.onload = (e) => {
//         const csvData = e.target.result;
//         Papa.parse(csvData, {
//           complete: (result) => {
//             const json = result.data;
//             processData(json);
//           },
//           error: (error) => {
//             notifyError("Error parsing CSV file: " + error.message);
//           },
//         });
//       };

//       reader.readAsText(file);
//     };

//     const processData = (json) => {
//       const finalData = [];
//       let bulkTypeState = "";

//       if (json[0][0] === "version-id") {
//         bulkTypeState = "update";
//       } else if (json[0][0] === "models-id") {
//         bulkTypeState = "insert";
//       } else if (json[0][0] === "version_id") {
//         bulkTypeState = "update-export";
//       }

//       const startIndex =
//         bulkTypeState === "update" ? 1 : bulkTypeState === "insert" ? 5 : 6;

//       json.slice(1).forEach((row) => {
//         if (!row[0]) {
//           return;
//         }

//         const version_specification = {};
//         const version_feature = {};
//         const dataEntry = {
//           version_specification,
//           version_feature,
//         };

//         if (bulkTypeState === "update-export") {
//           dataEntry.version_id = row[0];
//           dataEntry.modelId = row[1];
//           dataEntry.version_name = row[2];
//           dataEntry.version_url = row[3];
//           dataEntry.show_on_launch = parseInt(row[4]);
//           dataEntry.launch_date = row[5];
//         } else if (bulkTypeState === "update") {
//           dataEntry.version_id = row[0];
//         } else if (bulkTypeState === "insert") {
//           dataEntry.modelId = row[0];
//           dataEntry.version_name = row[1];
//           dataEntry.version_url = row[2];
//           dataEntry.show_on_launch = parseInt(row[3]);
//           dataEntry.launch_date = row[4];
//         }

//         const specStartIndex = 53; // Number of specification columns
//         const featureStartIndex = 135; // Number of feature columns

//         // const specStartIndex = startIndex;
//         // const featureStartIndex = Object.keys(specificationColumns).length;
//         console.log(
//           "output",
//           specStartIndex,
//           featureStartIndex,
//           Object.keys(specificationColumns).length +
//             Object.keys(featureColumns).length
//         );
//         for (let i = 0; i < specStartIndex; i++) {
//           const specKey = Object.keys(specificationColumns)[i].trim(); // Trim the key
//           // console.log("specKey", specKey);
//           version_specification[specKey] = row[startIndex + i]; // Adjust the index
//           // console.lof(row[startIndex + i]);
//         }

//         // Process features
//         for (let i = 0; i < featureStartIndex; i++) {
//           const featureKey = Object.keys(featureColumns)[i].trim(); // Trim the key
//           version_feature[featureKey] = row[specStartIndex + startIndex + i]; // Adjust the index
//         }

//         finalData.push(dataEntry);
//       });

//       const payload = {
//         type: bulkTypeState,
//         data: finalData,
//       };

//       addVersion(payload);
//     };

//     const handleFileChange = (e) => {
//       setFile(e.target.files[0]);
//     };

//     return (
//       <div className="d-flex">
//         <input
//           type="file"
//           onChange={handleFileChange}
//           className="form-control shadow-none file-input m-0 me-3"
//         />
//         <button className="import-btn align-items-center" onClick={handleImport}>
//           Import
//         </button>
//       </div>
//     );
//   }
// // }
