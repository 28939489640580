import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../../../components/Table";
import Card from "react-bootstrap/Card";
import {
  getBrandNameList,
  getBikeBodyType,
  getModelList,
  getSimpleModelList,
  getBikeBrandNameList,
  getBikeModelList,
  getBikeModelExport,
} from "../../../../request";

import PlusLargeIcon from "../../../../assets/icons/bootstrap-icons/plus-lg.svg";
import UploadIcon from "../../../../assets/icons/bootstrap-icons/upload.svg";

import Breadcrumbs from "../../../../Breadcrumbs";
import BikeModelSearchBar from "./BikeModelSearchBar";
import BikeModelBtnGroup from "./BikeModelBtnGroup";
import { BikeColumns } from "../../../../components/BikeColumns.js";
import { getToken } from "../../../../utility/constants";
import CarModelBtn from "../../../../components/CarModelBtn";
import OldTable from "../../../../components/OldListingTable";
import { carType } from "../../../../utility/constants";
import Datatable from "../../../../components/Table";
import ExportButton from "../../../../components/Export";

export default function BikeModels({ children }) {
  const navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [originalData, setOriginalData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchVal, setSearchVal] = useState("");
  const [brands, setBrands] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState(0);
  const [bodyType, setBodyType] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedBodyType, setSelectedBodyType] = useState("");
  const [selectedcarType, setSelectedcarType] = useState("");
  const token = getToken();
  const debounceTimeout = useRef(null);
  useEffect(() => {
    // fetchData();
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      fetchData();
    }, 500);

    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, [currentPage, searchVal]);

  useEffect(() => {
    fetchData();
    getBrands();
    getBodyType();
  }, []);
  // useEffect(() => {
  //   fetchData();
  // }, [selectedBrand, selectedBodyType, selectedcarType]);

  const getBrands = async () => {
    const brandParams = {
      sortBy: "",
      sortOrder: "",
    };
    const res = await getBikeBrandNameList(brandParams);
    setBrands(res.data.result);
  };

  const getBodyType = async () => {
    const bodyTypeParams = {
      page: 0,
      limit: 0,
      sortBy: "",
      sortOrder: "",
      search: "",
    };
    const res = await getBikeBodyType(token, bodyTypeParams);
    setBodyType(res.data.result);
  };

  const fetchData = async () => {
    try {
      const params = {
        page: currentPage,
        limit: itemsPerPage,
        sortBy: "id",
        sortOrder: "desc",
        search: searchVal,
        brand_id: [selectedBrand],
        body_type_id: [selectedBodyType],
        bike_id: [selectedcarType],
      };
      const res = await getBikeModelList(token, params);
      const response = res.data.result;
      setOriginalData(response);

      setFilteredRecords(res.data.filteredRecords);

      setTotalRecords(res.data.totalRecord);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearchValue = useCallback((value) => {
    setSearchVal(value);
  }, []);

  const selectedTypes = (type, selected) => {
    if (type === "brand") {
      setSelectedBrand(selected.id);
    }
    if (type === "bodyType") {
      setSelectedBodyType(selected.id);
    }
    if (type === "carType") {
      setSelectedcarType(selected.id);
    }
  };

  const clearAll = async () => {
    setSelectedBrand("");
    setSelectedBodyType("");
    setSelectedcarType("");
    await fetchData();
  };

  const reloadDataTable = () => {
    Promise.resolve(fetchData()).then(() => {
      setCurrentPage(1);
      // setTotalRecords(filteredRecords);
    });
  };
  console.log("filteredRecords", totalRecords);

  const filterOptions = [
    {
      key: "brand",
      label: "By Brands",
      type: "brand",
      searchKey: "brand_name",
      values: brands,
    },
    {
      key: "bodyType",
      label: "By Body Type",
      type: "bodyType",
      searchKey: "type_name",
      values: bodyType,
    },
    {
      key: "carType",
      label: "By Bike Type",
      type: "carType",
      searchKey: "value",
      values: carType,
    },
  ];
  const keys = [
    "id",
    "brand_name",
    "brand_url",
    "model_name",
    "model_url",
    "updated_launch_date",
    "bike_type",
    "body_type",
    "popular_position",
    "starting_price",
    "ending_price",
    "model_image",
    "model_brochure",
    "model_content",
    "gallery_short_code",
    "colors",
    "exper_review",
    "pricecount",
    "robots_index",
    "robots_follow",
    "min_price",
    "max_price",
    "trending_position",
  ];

  const exportFilter = {
    search: "",
    sortBy: "",
    sortOrder: "",
    page: 1,
    limit: totalRecords,
  };
  if (selectedBrand) {
    exportFilter.brand_id = [selectedBrand];
  }
  if (selectedBodyType) {
    exportFilter.body_type_id = [selectedBodyType];
  }
  if (selectedcarType) {
    exportFilter.bike_id = [selectedcarType];
  }

  return (
    <div>
      <Breadcrumbs />
      <Card>
        <Card.Body>
          <Card.Text>
            <div className="w-100">
              <BikeModelBtnGroup navigate={navigate} />
              <div className="w-100">
                <div className="d-flex align-items-center justify-content-between gap-2">
                  <BikeModelSearchBar
                    onChange={handleSearchValue}
                    value={searchVal}
                    options={filterOptions}
                    selectedTypes={selectedTypes}
                    selectedValues={{
                      brand: selectedBrand,
                      bodyType: selectedBodyType,
                      carType: selectedcarType,
                    }}
                    apply={reloadDataTable}
                    clearAll={clearAll}
                    tableHeading={`Bike Models (${totalRecords}) `}
                    searchPlaceholder={"Search"}
                  />
                  <div className="d-flex">
                    <ExportButton
                      api={getBikeModelList}
                      totalRecords={filteredRecords}
                      keys={keys}
                      showKeys={keys}
                      payload={exportFilter}
                    />
                    <CarModelBtn
                      label={"Add New Bike Model"}
                      icon={PlusLargeIcon}
                      className={
                        "add-button d-flex align-items-center justify-content-between mb-2 px-3"
                      }
                      onClick={() => navigate("/bike-models/add-bike-model")}
                    />
                  </div>
                </div>
              </div>

              <OldTable
                columns={BikeColumns(
                  reloadDataTable,
                  currentPage,
                  itemsPerPage
                )}
                data={originalData}
                currentPage={currentPage}
                onChangePage={(pageNumber) => {
                  setCurrentPage(pageNumber);
                }}
                totalPages={Math.ceil(totalRecords / itemsPerPage)}
                setTotalRecords={setTotalRecords}
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
                showPagination={true}
              ></OldTable>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
