import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import EmailIcon from "../../assets/icons/bootstrap-icons/auth-images/Email.svg";
import AuthLayout from "./AuthLayout";
import InputGroup from "../../components/InputGroup";
import SubmitButton from "../../components/SubmitButton";
import { forgotPassword } from "../../request";
import { setResetPasswordToken, setUserId } from "../../utility/constants";
import { notifyError } from "../../utility/common";

export default function ForgotPassword() {
  const navigate = useNavigate();

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });
  return (
    <AuthLayout>
      <main className="d-flex w-100 h-100">
        <div className="container">
          <div className="row h-100">
            <div className="col-sm-10 col-md-10 col-lg-10 col-xl-10 col-lg-10 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center mt-3">
                  <div className="auth-heading">Forgot Password</div>
                  <p className="p-3 auth-message">
                    Please enter your registered Email to reset your password
                  </p>
                </div>

                <div className="">
                  <div className="">
                    <div className="m-sm-3">
                      <div className="mb-3">
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          onSubmit={(values, { setSubmitting }) => {
                            forgotPassword(values)
                              .then((res) => {
                                const { token, user_id } = res.data.data;
                                setResetPasswordToken(token);
                                setUserId(user_id);
                                navigate("/verify-otp");
                              })
                              .catch((err) => {
                                notifyError(err.message);
                              });
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleSubmit,
                            setFieldValue,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <div className="mb-3">
                                <InputGroup
                                  type="email"
                                  position="left"
                                  icon={EmailIcon}
                                  placeholder={"Email"}
                                  name={"email"}
                                  touched={touched}
                                  errors={errors}
                                  value={values["email"]}
                                  setFieldValue={setFieldValue}
                                  auth={true}
                                />
                              </div>

                              <div className="d-grid gap-2 mt-5 mb-3">
                                <SubmitButton title="Submit" />
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </AuthLayout>
  );
}
