import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../request";

export function deleteCategoryById(id, token) {
  return axios.delete(
    `${BASE_URL_AUTOX}category/delete/${id}`,
    getConfig(token)
  );
}

export function getCategoryList(token, params) {
  return axios.post(
    `${BASE_URL_AUTOX}category/filtered/list`,
    params,
    getConfig(token)
  );
}

export function getCategoryById(id, token) {
  return axios.get(`${BASE_URL_AUTOX}category/details/${id}`, getConfig(token));
}

export function addNewCategory(payload, token) {
  return axios.post(`${BASE_URL_AUTOX}category/add`, payload, getConfig(token));
}

export function updateCategoryById(id, payload, token) {
  return axios.put(
    `${BASE_URL_AUTOX}category/update/${id}`,
    payload,
    getConfig(token)
  );
}

export function getRootCategoryList(token) {
  return axios.get(`${BASE_URL_AUTOX}category/root/list`, getConfig(token));
}
export function downloadCategoryContent(token) {
  return axios.post(`${BASE_URL_AUTOX}category/download`, {}, getConfig(token));
}

export default {
  deleteCategoryById,
  getCategoryList,
  getCategoryById,
  addNewCategory,
  updateCategoryById,
  getRootCategoryList,
  downloadCategoryContent,
};
