import * as Yup from "yup";

export const carBodyTypeValidation = Yup.object({
  car_body_type_id: Yup.number().required("Car body type is required"),
  car1_brand_id: Yup.number()
    .required("Brand 1 is required")
    .min(0, "Brand 1 is required"),
  car2_brand_id: Yup.number()
    .required("Brand 2 is required")
    .min(0, "Brand 2 is required"),
  car1_model_id: Yup.number()
    .required("Model 1 is required")
    .min(0, "Model 1 is required"),
  car2_model_id: Yup.number()
    .required("Model 2 is required")
    .min(0, "Model 2 is required"),
});
