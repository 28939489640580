import clsx from "clsx";
import React from "react";

export default function Heading({ title, component, type }) {
  console.log("🚀 ~ file: Heading.js:5 ~ Heading ~ component:", component);
  return (
    <div
      className={clsx("d-flex align-items-center my-2", {
        "justify-content-between":
          type === "dashboard" || type === "transaction",
      })}
    >
      <div className="heading">{title}</div>
      {component && <div className="mx-4">{component}</div>}
    </div>
  );
}
