import { useNavigate } from "react-router-dom";
import { getToken, renderDate } from "../../utility/constants";
import { useState } from "react";
import EditViewDelete from "../../components/EditViewDeleteReviewPassword";
import { notifyError, notifySuccess } from "../../utility/common";
import apiHandler from "./apiHandler";
import PlusLargeIcon from "../../assets/icons/bootstrap-icons/plus-lg.svg";
import { Card } from "react-bootstrap";
import HeadingTwo from "../../components/HeadingTwo";
import DeleteConfirmationModalComponent from "../../components/DeleteConfirmationModalComponent";
import Table from "../../components/Table";
import { ReactSVG } from "react-svg";
import Breadcrumbs from "../../Breadcrumbs";
import CarModelBtn from "../../components/CarModelBtn";
import DownloadIcon from "../../assets/icons/bootstrap-icons/download.svg";
export default function CategoryList() {
  const token = getToken();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);
  const [categoryIdToDelete, setCategoryIdToDelete] = useState();
  const [totalCategorys, setTotalCategorys] = useState(0);

  const onEditPage = (id) => navigate(`/edit-category/${id}`);

  const onViewPage = (page) => {
    window.open(
      `${process.env.REACT_APP_FRONTEND_BASE_URL}/${page.slug}`,
      "_blank"
    );
  };

  const onDeleteCategory = async () => {
    try {
      await apiHandler.deleteCategoryById(categoryIdToDelete, token);
      setShowDeleteModal(false);
      setReloadTable(true);
      notifySuccess("Category deleted successfully");
    } catch (error) {
      setShowDeleteModal(false);
      notifyError(error.message);
    }
  };
  const columns = [
    {
      name: "Title",
      selector: (page) => (
        <span style={{ fontWeight: page.parent > 0 ? "normal" : "bold" }}>
          {page.parent > 0 ? `— ${page.category_name} ` : page.category_name}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Date",
      selector: (page) => renderDate(page.created_date),
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      width: "auto",
      cell: (page) => (
        <EditViewDelete
          id={page.id}
          onEdit={onEditPage}
          onDelete={() => {
            setCategoryIdToDelete(page.id);
            setShowDeleteModal(true);
          }}
          onView={() => onViewPage(page)}
        />
      ),
    },
  ];
  const downloadCategoryContent = async () => {
    const response = await apiHandler.downloadCategoryContent(token);
    if (response.data.url) {
      window.open(response.data.url, "_blank");
    }
  };
  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <Breadcrumbs />
        <CarModelBtn
          label={"Download Each Category Content in Text Files"}
          icon={DownloadIcon}
          onClick={() => downloadCategoryContent()}
          className={
            "preview-button d-flex align-items-center justify-content-between  mb-2 text-white px-3"
          }
        />{" "}
        <button
          className="add-service-centre-button d-flex align-items-center justify-content-between mb-2 px-3"
          onClick={() => navigate("/add-category")}
        >
          <ReactSVG
            src={PlusLargeIcon}
            height="18px"
            width="18px"
            className="mr-2"
          />{" "}
          Add Category
        </button>
      </div>
      <Card className="p-3">
        <div>
          <HeadingTwo
            title={`Category List (${totalCategorys})`}
            type="dashboard"
            showHeader
            pointerOnHover
          />
        </div>
        <div>
          <Table
            columns={columns}
            api={apiHandler.getCategoryList}
            reload={reloadTable}
            setReload={setReloadTable}
            setTotalRecords={setTotalCategorys}
          />
          <DeleteConfirmationModalComponent
            show={showDeleteModal}
            type="delete"
            handleClose={() => setShowDeleteModal(false)}
            setDeleteConfirm={onDeleteCategory}
          />
        </div>
      </Card>
    </>
  );
}
