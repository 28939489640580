// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
  --heading-color : #15171a;
  --primary-color: #6754c6;
  --secondary-color: #4c3d99;
  --accent-color: #90faf6;
  --primary-light: #8770ff;
  --gray-color: #15171a;
  --opacity-50-gray: #a3a3a3;
  --opacity-90-gray: #f4f4fc;
  --primary-yellow: #f6bf5b;
  --primary-Blue: #5a7ddf;
}

.bg-color-main{
  background-color: #131f39 !important;
}

.bg-color-header{
  background-color: #1d325e;
  color: white;

}
`, "",{"version":3,"sources":["webpack://./src/styles/globals.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,wBAAwB;EACxB,0BAA0B;EAC1B,uBAAuB;EACvB,wBAAwB;EACxB,qBAAqB;EACrB,0BAA0B;EAC1B,0BAA0B;EAC1B,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,yBAAyB;EACzB,YAAY;;AAEd","sourcesContent":[":root{\r\n  --heading-color : #15171a;\r\n  --primary-color: #6754c6;\r\n  --secondary-color: #4c3d99;\r\n  --accent-color: #90faf6;\r\n  --primary-light: #8770ff;\r\n  --gray-color: #15171a;\r\n  --opacity-50-gray: #a3a3a3;\r\n  --opacity-90-gray: #f4f4fc;\r\n  --primary-yellow: #f6bf5b;\r\n  --primary-Blue: #5a7ddf;\r\n}\r\n\r\n.bg-color-main{\r\n  background-color: #131f39 !important;\r\n}\r\n\r\n.bg-color-header{\r\n  background-color: #1d325e;\r\n  color: white;\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
