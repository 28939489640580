import { Card } from "react-bootstrap";
import Breadcrumbs from "../../Breadcrumbs";
import Layout from "../../components/Layout";
import HeadingTwo from "../../components/HeadingTwo";
import Datatable from "../../components/Table";
import { useState } from "react";
import { getToken, renderDate } from "../../utility/constants";
import { notifyError, notifySuccess } from "../../utility/common";
import apiHandler from "./apiHandler";
import DeleteConfirmationModalComponent from "../../components/DeleteConfirmationModalComponent";
import EditViewDelete from "../../components/EditViewDeleteReviewPassword";

export default function Notification() {
  const [reloadTable, setReloadTable] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [notificationToDelete, setNotificationToDelete] = useState();
  const [filter, setFilter] = useState({ alert_type: "upcoming_car" });

  const token = getToken();

  const onDeleteInner = async () => {
    try {
      await apiHandler.deleteNotification(token, notificationToDelete);
      setShowDeleteModal(false);
      setReloadTable(true);
      notifySuccess("Notification deleted successfully");
    } catch (error) {
      setShowDeleteModal(false);
      notifyError(error.message);
    }
  };
  const columns = [
    {
      name: "User Name",
      selector: (row) => row.name,
      sortable: true,
      width: "300px",
    },
    {
      name: "Subscribed Model",
      selector: (row) => row.model_name,
      sortable: true,
      width: "300px",
    },
    {
      name: "Email Status",
      selector: (row) => (row.status ? "Valid" : "Not Valid"),
      sortable: true,
      width: "150px",
    },
    {
      name: "Subscribed Date",
      selector: (row) => renderDate(row.created_date),
      sortable: true,
      width: "150px",
    },
    {
      name: "IP Address",
      selector: (row) => row.ip_address,
      sortable: true,
      width: "200px",
    },
    {
      name: "Action",
      sortable: false,
      width: "auto",
      cell: (row) => (
        <EditViewDelete
          id={row.id}
          onDelete={() => {
            setNotificationToDelete(row.id);
            setShowDeleteModal(true);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Layout>
        <div className="row dashboard" style={{ minHeight: "100%" }}>
          <div className="d-flex">
            <Breadcrumbs />
          </div>
          <div className="my-4" style={{ minHeight: "100%" }}>
            <Card className="p-3">
              <HeadingTwo title="Notification" />
              <div>
                <div className="d-flex gap-3">
                  <button
                    onClick={() => setFilter({ alert_type: "upcoming_car" })}
                    className={`active feedback-button-grp${
                      filter.alert_type === "upcoming_car" ? "-active" : ""
                    }`}
                  >
                    Upcoming Car{" "}
                  </button>
                  <button
                    onClick={() => setFilter({ alert_type: "upcoming_bike" })}
                    className={`feedback-button-grp${
                      filter.alert_type === "upcoming_bike" ? "-active" : ""
                    }`}
                  >
                    Upcoming Bike
                  </button>
                </div>
                <Datatable
                  columns={columns}
                  api={apiHandler.getNotificationList}
                  reload={reloadTable}
                  setReload={setReloadTable}
                  setTotalRecords={setTotalPages}
                  totalRecords={totalPages}
                  filter={filter}
                />
                <DeleteConfirmationModalComponent
                  show={showDeleteModal}
                  type={"delete"}
                  handleClose={() => setShowDeleteModal(false)}
                  setDeleteConfirm={onDeleteInner}
                />
              </div>
            </Card>
          </div>
        </div>
      </Layout>
    </>
  );
}
