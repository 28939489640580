import React from "react";
import { Navigate } from "react-router-dom";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token ? JSON.parse(token) : null;
};

const getRole = () => {
  const role = localStorage.getItem("user_role");
  return role ? JSON.parse(role) : [];
};

const RoleBasedRoute = ({ children, allowedRoles }) => {
  const token = getToken();
  const userRoles = getRole();

  const hasAccess = allowedRoles.some(role => userRoles.includes(role));

  return token && hasAccess ? children : <Navigate to="/dashboard" />;
};

export default RoleBasedRoute;
