import { Card } from "react-bootstrap";

import {
  getCarBrandList,
  getCarModelList,
  getCarUserReviews,
  getVersion,
} from "./CarUserController";
import { useCallback, useEffect, useRef, useState } from "react";
import { getToken } from "../../utility/constants";

import OldTable from "../../components/OldListingTable";
import CarModelSearchBar from "../NewCar/CarModels/CarModelListing/CarModelSearchBar";
import { CarReviewColumn } from "./CarUserReviewListing/CarReviewColumn";

const CarUserReview = () => {
  const [reviewList, setReviewList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  //eslint-disable-next-line
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [allBrands, setAllBrands] = useState([]);
  const [carModelList, setCarModelList] = useState([]);
  const [versionsList, setVersionsList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedModel, setSelectedModel] = useState();
  const [selectedVersion, setSelectedVersion] = useState();
  const [selectedStatus, setSelectedStatus] = useState();

  const token = getToken();

  const debounceTimeout = useRef(null);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const fetchBrandsParams = {
          sortBy: "",
          sortOrder: "",
        };
        const brandRes = await getCarBrandList(fetchBrandsParams);
        setAllBrands(brandRes.result);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchModelList = async () => {
      try {
        const fetchCarModelParams = {
          brand_id: selectedBrand ? selectedBrand : "",
          sortBy: "",
          sortOrder: "",
        };
        const carModelRes = await getCarModelList(fetchCarModelParams);
        setCarModelList(carModelRes.result);
      } catch (error) {
        console.log(error);
      }
    };
    const getVersionsList = async () => {
      try {
        const versionParams = {
          model_id: selectedModel ? selectedModel : "",
          sortBy: "",
          sortOrder: "",
        };
        const versionRes = await getVersion(versionParams);
        setVersionsList(versionRes.result);
      } catch (error) {
        console.log(error);
      }
    };

    fetchBrands();
    fetchModelList();
    getVersionsList();
  }, [selectedBrand, selectedModel]);
  const getReviews = useCallback(async () => {
    const params = {
      page: currentPage,
      limit: 10,
      sortBy: "",
      sortOrder: "",
      name: searchValue,
      brand_id: selectedBrand ? selectedBrand : "",
      model_id: selectedModel ? selectedModel : "",
      version_id: selectedVersion ? selectedVersion : "",
      status: selectedStatus ? selectedStatus : "",
    };
    const res = await getCarUserReviews(token, params);
    console.log(res);
    setReviewList(res.data.result);
    setTotalRecords(res.data.totalRecord);
  }, [
    token,
    currentPage,
    searchValue,
    selectedBrand,
    selectedModel,
    selectedVersion,
    selectedStatus,
  ]);
  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      getReviews();
    }, 500);

    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, [
    currentPage,
    searchValue,
    selectedBrand,
    selectedModel,
    selectedVersion,
    selectedStatus,
    getReviews,
  ]);

  const handleSearchClick = (value) => {
    setSearchValue(value);
  };

  const applySearch = () => {
    setCurrentPage(1);
  };

  const clearAll = () => {
    setSearchValue("");
    setSelectedBrand(null);
    setSelectedModel(null);
    setSelectedVersion(null);
    setSelectedStatus(null);
  };

  const selectedTypes = (type, selected) => {
    if (type === "brand") {
      setSelectedBrand(selected.id);
    }
    if (type === "model") {
      setSelectedModel(selected.id);
    }
    if (type === "version") {
      setSelectedVersion(selected.id);
    }
  };

  const selectedValues = {
    brand: selectedBrand,
    model: selectedModel,
    version: selectedVersion,
    status: selectedStatus,
  };

  const filterOptions = [
    {
      key: "brand",
      label: "By Brands",
      type: "brand",
      searchKey: "brand_name",
      values: allBrands,
    },
    {
      key: "model",
      label: "By Models",
      type: "model",
      searchKey: "model_name",
      values: carModelList,
    },
    {
      key: "version",
      label: "By Versions",
      type: "version",
      searchKey: "version_name",
      values: versionsList,
    },
  ];
  const reloadTable = () => {
    getReviews();
  };

  return (
    <Card className="pb-2 mb-3">
      <Card.Body>
        <Card.Text>
          <div className="w-100">
            <CarModelSearchBar
              onChange={handleSearchClick}
              value={searchValue}
              selectedTypes={selectedTypes}
              selectedValues={selectedValues}
              options={filterOptions}
              apply={applySearch}
              clearAll={clearAll}
              tableHeading={"Car Reviews"}
              searchPlaceholder={"Search"}
            />
            <OldTable
              columns={CarReviewColumn(reloadTable, currentPage, itemsPerPage)}
              data={reviewList}
              currentPage={currentPage}
              onChangePage={(pagenNumber) => setCurrentPage(pagenNumber)}
              totalPages={Math.ceil(totalRecords / itemsPerPage)}
              setTotalRecords={setTotalRecords}
              setCurrentPage={setCurrentPage}
              itemsPerPage={itemsPerPage}
              showPagination={true}
            />
          </div>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default CarUserReview;
