import axios from "axios";
import { getConfig, BASE_URL_AUTOX } from "../../../request";
import { getToken } from "../../../utility/constants";
export const GET_VERSION = "car/version/filtered/list";
export const GET_ALL_MODEL_LIST = "car/model/all/list";
export const GET_ALL_BRAND_LIST = "car/brand/all/list";
export const GET_MODEL_BY_BRAND_ID = "car/model/brand-specific/";
export const ADD_CAR_VERSION = "car/version/add";
export const BULK_DELETE_CAR_VERSION = "car/version/bulk/delete";
export const DELETE_CAR_VERSION = "car/version/delete/";
export const GET_CAR_VERSION_VIEW_LINK = "car/version/view/";
export const GET_CAR_VERSION_FIELDS = "car/version/manageversion/";

/**
 * Get List of Car Versions
 * getCarVersions is used to get all the data of car service centres
 * @param token
 * @param payload
 */
export function getCarVersions(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_VERSION}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get Car Version view lik
 * getCarVersionViewLink is used to get view link of car version
 * @param token
 * @param id
 */
export function getCarVersionViewLink(token, id) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_CAR_VERSION_VIEW_LINK}${id}`,
    getConfig(token)
  );
}

/**
 * Get List of Brands
 * getAllBrands is used to get data of all brands
 * @param token
 * @param payload
 */
export function getAllBrands(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_ALL_BRAND_LIST}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get List of Models by brand id
 * getAllModelsByBrandId is used to get data of all models by brand id
 * @param token
 * @param payload
 */
export function getAllModelsByBrandId(token, brand_id) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_MODEL_BY_BRAND_ID}${brand_id}`,
    getConfig(token)
  );
}

/**
 * Add New Car Version
 * addCarVersion is used to create new car version data
 * @param token
 * @param payload
 */
export function addCarVersion(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${ADD_CAR_VERSION}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get List of Models
 * getAllModels is used to get data of all models
 * @param token
 * @param payload
 */
export function getAllModelsList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_ALL_MODEL_LIST}`,
    payload,
    getConfig(token)
  );
}

/**
 * Delete Car Version By Id
 * deleteCarVersionById is used to delete record of car version by id
 * @param token
 * @param id
 */
export function deleteCarVersionById(token, id) {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_CAR_VERSION}${id}`,
    getConfig(token)
  );
}

/**
 * Bulk Delete Car Version By Id
 * bulkDeleteCarVersionById is used to delete bulk record of car version by id
 * @param token
 * @param payload
 */
export function bulkDeleteCarVersionById(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${BULK_DELETE_CAR_VERSION}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get List car version fields
 * getCarVersionFields is used to get car version fields
 * @param token
 */
export function getCarVersionFields(token, filter_type, model_id) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_CAR_VERSION_FIELDS}${filter_type}/${model_id}`,
    getConfig(token)
  );
}

export function updateAllVersion(token, payload) {
  console.log("payload", payload);
  return axios.post(
    `${BASE_URL_AUTOX}${GET_CAR_VERSION_FIELDS}`,
    payload,
    getConfig(token)
  );
}

export function downloadSampleInsertionFile(type) {
  return axios.post(`${BASE_URL_AUTOX}car/version/download/sampleInsert`, {
    type: type,
  });
}

export async function downloadVarient(token, payload) {
  const response = await axios.post(
    `${BASE_URL_AUTOX}car/version/export-csv`,
    payload,
    getConfig(token)
  );
  return response;
}

export function addCarCityImport(formData) {
  return axios.post(
    `${BASE_URL_AUTOX}car/city-price/importCityPrice`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
}

export function addBikeCityImport(formData) {
  return axios.post(
    `${BASE_URL_AUTOX}new-bike/city-price/importCityPrice`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
}

export default {
  getCarVersions,
  getAllBrands,
  getAllModelsByBrandId,
  getAllModelsList,
  getCarVersionViewLink,
  deleteCarVersionById,
  bulkDeleteCarVersionById,
  getCarVersionFields,
  updateAllVersion,
  addCarVersion,
  downloadSampleInsertionFile,
  downloadVarient,
  addCarCityImport,
  addBikeCityImport,
};
