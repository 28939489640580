import UploadIcon from "../assets/icons/bootstrap-icons/upload.svg";
import { ReactSVG } from "react-svg";
import { getToken } from "../utility/constants";
import { notifyError } from "../utility/common";
import DownloadIcon from "../assets/icons/bootstrap-icons/button-icons/download white.svg";
import specificationColumns from "../../src/utility/specification.json";
import featureColumns from "../../src/utility/features.json";
export default function ExportCustomButton({ api }) {
  const token = getToken();
  let keys = [];
  let showKeys = [];

  //   const showKeys = ["id", "Brand Name", "URL"];

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    result = "";

    result += showKeys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key, index) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv === null) return;
    const filename = "export-variants.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const handleCSVExport = async () => {
    try {
      const response = await api(token);
      const data = response.data.data;

      // Get keys until 'launch_date'
      const dataKeys = Object.keys(data[0]).filter((key) => key !== "id");
      const dataUntilLaunchDate = dataKeys.slice(0, 6);
      // Extract specification and feature keys
      const specKeys = Object.keys(specificationColumns);
      const featureKeys = Object.keys(featureColumns);
      const specValues = Object.values(specificationColumns);
      const featureValues = Object.values(featureColumns);

      // Construct the final list of keys
      keys = [...dataUntilLaunchDate, ...specKeys, ...featureKeys];

      // Set the showKeys to the first five of 'keys' and then add spec and feature keys
      showKeys = [...keys.slice(0, 6), ...specValues, ...featureValues];
      const exportData = data;
      downloadCSV(exportData);
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };

  return (
    <button
      className="download-variants-button d-flex align-items-center justify-content-between me-2  text-white px-3"
      onClick={() => handleCSVExport()}
    >
      <ReactSVG
        src={DownloadIcon}
        height="18px"
        width="18px"
        className="mr-2"
      />{" "}
      Download Variants
    </button>
  );
}
