import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../../utility/constants";
import apiHandler from "./apiHandler";
import { notifyError, notifySuccess } from "../../utility/common";
import TemplateForm from "./TemplateForm";

export default function EditTemplate() {
  const navigate = useNavigate();
  const { id } = useParams();
  const token = getToken();
  const [initialValues, setInitialValues] = useState({
    template_name: "",
    subject: "",
    html_body: "",
    text_body: "",
    status: 1,
    created_by: 0,
  });

  useEffect(() => {
    const getTemplate = async () => {
      try {
        const response = await apiHandler.getTemplateDetails(id, token);
        console.log(response.data.data);
        if (response.status === 200) {
          setInitialValues(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getTemplate();
  }, []);
  const EditTemplate = async (data) => {
    try {
      delete data.id;
      delete data.created_date;
      delete data.updated_date;
      const response = await apiHandler.editTemplate(id, data, token);
      if (response.status === 200) {
        notifySuccess(response.data.message);
        setTimeout(() => {
          navigate("/template");
        }, 1500);
      }
    } catch (error) {
      console.log(error);
      notifyError(error.response.data.message);
    }
  };
  return (
    <>
      <TemplateForm
        initialValues={initialValues}
        onSubmit={EditTemplate}
        title={"Edit Template"}
      />
    </>
  );
}
