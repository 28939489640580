import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/Table";
import EditViewDeleteReviewPassword from "../../../components/EditViewDeleteReviewPassword";
import Breadcrumbs from "../../../Breadcrumbs";
import Search from "../../../components/SearchField";
import { ReactSVG } from "react-svg";
import { Card } from "react-bootstrap";
import FilterIcon from "../../../assets/icons/bootstrap-icons/filter.svg";
import PlusLogo from "../../../assets/icons/bootstrap-icons/plus-lg.svg";
import UploadIcon from "../../../assets/icons/bootstrap-icons/upload.svg";
import DownloadIcon from "../../../assets/icons/bootstrap-icons/download.svg";
import apiHandler, {
  downloadBikeDealer,
  downloadDealerSample,
} from "./apiHandler";
import DeleteConfirmationModalComponent from "../../../components/DeleteConfirmationModalComponent";
import { notifyError, notifySuccess } from "../../../utility/common";
import { getToken } from "../../../utility/constants";
import CarModelSearchBar from "../../NewCar/CarModels/CarModelListing/CarModelSearchBar";
import { getAllStates, getBikeBrandNameList } from "../../../request";
import ExportButton from "../../../components/Export";
import BulkImportCarDealer from "../../../components/BulkImportCarDealer";
import DownloadCSVExport from "../../../components/DownloadCSVExport";

export default function BikeDealers({ children }) {
  const token = getToken();
  const navigate = useNavigate();
  const [searchVal, setSearchVal] = useState("");
  const onEditInner = (id) => {
    navigate(`/bike-dealer/edit-bike-dealer/${id}`);
  };
  const [dealer, setDealer] = useState([]);
  const [slicedDealer, setSlicedDealer] = useState([]);
  const [filteredDealer, setFilteredDealer] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [deleteId, setDeleteId] = useState(null);
  const [show, setShow] = useState(false);
  const [reload, setReload] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [brands, setBrands] = useState([]);
  const [states, setStates] = useState([]);
  const [filter, setFilter] = useState({});

  const onDeleteInner = (id) => {
    apiHandler
      .deletebikeDealerById(token, deleteId)
      .then(() => {
        setShow(false);
        setReload(true);
        notifySuccess("Bike dealer deleted successfully");
      })
      .catch((error) => {
        setShow(false);
        notifyError(error.message);
      });
  };
  const getDealerData = () => {
    setDealer(data);
  };
  useEffect(() => {
    setFilteredDealer(dealer);
  }, [dealer]);

  useEffect(() => {
    getDealerData();
  }, []);

  useEffect(() => {
    handleDealerSlicing();
  }, [currentPage, filteredDealer, itemsPerPage]);

  const columns = [
    {
      name: "S. No.",
      sortable: false,

      selector: (_, index) =>
        index + 1 + currentPage * itemsPerPage - itemsPerPage,
      width: "70px",
    },
    {
      name: "State",
      sortable: true,
      selector: (row) => row.state_name,
      width: "auto",
    },
    {
      name: "Brand Name",
      sortable: true,
      selector: (row) => row.brand_name,
      width: "auto",
    },
    {
      name: "Bike Dealer Name",
      sortable: true,
      selector: (row) => row.dealer_name,
      width: "200px",
    },
    {
      name: "City",
      sortable: true,
      selector: (row) => row.city_name,
      width: "auto",
    },
    {
      name: "Action",
      sortable: false,
      width: "auto",
      cell: (row) => (
        <EditViewDeleteReviewPassword
          id={row.id}
          onEdit={onEditInner}
          onDelete={(id) => {
            setDeleteId(id);
            setShow(true);
          }}
        />
      ),
    },
  ];

  const data = [
    {
      index: 1,
      state: "abc",
      brandName: "abc",
      carDrivingSchoolName: "test",
      city: "test",
    },
    {
      index: 2,
      state: "abc",
      brandName: "abc",
      carDrivingSchoolName: "test",
      city: "test",
    },
  ];

  useEffect(() => {
    handleSearchClick();
  }, [searchVal]);

  function handleSearchClick() {
    if (searchVal === "") {
      setFilteredDealer(dealer);
      return;
    }
    const filterBySearch = dealer.filter((item) => {
      if (
        item.state?.toLowerCase().includes(searchVal.toLowerCase()) ||
        item.brandName?.toLowerCase().includes(searchVal.toLowerCase())
      ) {
        return item;
      }
    });
    setFilteredDealer(filterBySearch);
  }

  function handleDealerSlicing() {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const slicedDealerInner = filteredDealer.slice(startIndex, endIndex);
    setSlicedDealer(slicedDealerInner);
  }

  const getBrands = async () => {
    const brandParams = {
      sortBy: "",
      sortOrder: "",
    };
    const res = await getBikeBrandNameList(token, brandParams);
    setBrands(res.data.result);
  };

  const getStates = async () => {
    const brandParams = {
      sortBy: "",
      sortOrder: "",
    };
    const res = await getAllStates(token, brandParams);
    setStates(res.data.result);
  };

  useEffect(() => {
    getBrands();
    getStates();
  }, []);

  const handleSearchValue = useCallback((value) => {
    setSearchVal(value);
    setReload(true);
  }, []);
  const Keys = [
    "id",
    "city_id",
    "city_name",
    "brand_id",
    "brand_name",
    "dealer_name",
    "dealer_url",
    "dealer_address",
    "dealer_area",
    "dealer_map",
    "phone_number",
    "email_id",
    "dealer_content",
    "created_date",
    "updated_date",
  ];

  const filterOptions = [
    {
      key: "brand",
      label: "By Brands",
      type: "brand",
      searchKey: "brand_name",
      values: brands,
    },
    {
      key: "state_name",
      label: "By State",
      type: "state",
      searchKey: "state_name",
      values: states,
    },
  ];

  const selectedTypes = (type, selected) => {
    if (type === "brand") {
      setSelectedBrand(selected);
      setFilter({ ...filter, brand_id: selected.id });
    }
    if (type === "state") {
      setSelectedState(selected);
      setFilter({ ...filter, state_id: selected.id });
    }
  };

  const applyFilter = useCallback((value) => {
    setReload(true);
  }, []);

  const clearAll = () => {
    setSelectedBrand("");
    setSelectedState("");
    setFilter({});
    setReload(true);
  };

  return (
    <div className="row dashboard">
      <div className="d-flex justify-content-between">
        <Breadcrumbs />
        <div className="d-flex">
          <BulkImportCarDealer setReload={setReload} type="bike" />

          <DownloadCSVExport
            api={() =>
              downloadBikeDealer(token, {
                brand_id: filter.brand_id,
                state_id: filter.state_id,
              })
            }
          />

          <button
            className="sample-import-file-button d-flex align-items-center text-nowrap justify-content-between me-2 mb-2 text-white px-3"
            onClick={() => downloadDealerSample()}
          >
            <ReactSVG
              height="18px"
              width="18px"
              src={DownloadIcon}
              className="mr-2"
            />{" "}
            Sample File for Import
          </button>
        </div>
      </div>
      <Card className="ms-2">
        <Card.Body>
          <Card.Text>
            <div className="row dashboard">
              <div className="col-md-7">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <CarModelSearchBar
                      onChange={handleSearchValue}
                      value={searchVal}
                      options={filterOptions}
                      selectedTypes={selectedTypes}
                      selectedValues={{
                        brand: selectedBrand.id,
                        state: selectedState,
                      }}
                      apply={applyFilter}
                      clearAll={clearAll}
                      tableHeading={"City Dealer List"}
                      searchPlaceholder={"Search"}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-5 text-right">
                <div className="btngroup d-flex justify-content-end flex-row flex-wrap">
                  <button
                    onClick={() => navigate("/bike-dealer/add-bike-dealer")}
                    className="bulk-update-button d-flex align-items-center justify-content-between mt-2  mb-2 px-3"
                  >
                    <ReactSVG
                      src={PlusLogo}
                      height="18px"
                      width="18px"
                      className="mr-2"
                    />{" "}
                    Add Bike Dealer
                  </button>
                </div>
              </div>
              <Table
                api={apiHandler.getbikeDealerList}
                columns={columns}
                data={slicedDealer}
                setReload={setReload}
                reload={reload}
                search={searchVal}
                currentPage={currentPage}
                showPagination={true}
                onChangePage={(pageNumber) => setCurrentPage(pageNumber)}
                totalPages={Math.ceil(filteredDealer.length / itemsPerPage)}
                setTotalRecords={setTotalRecords}
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
                filter={filter}
              ></Table>
            </div>
            <DeleteConfirmationModalComponent
              show={show}
              type={"delete"}
              handleClose={() => setShow(false)}
              setDeleteConfirm={onDeleteInner}
            />
          </Card.Text>
        </Card.Body>
      </Card>
      {/* <div className="card">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-4 col-6">
                    <HeadingTwo
                        title="Car Dealer List"
                        type="dashboard"
                        showHeader={true}
                        pointerOnHover={true}
                    />
                </div>

                <div className="d-flex justify-content-end">
                    <div className="d-flex">
                        <div className="my-2 d-flex border border-2 align-items-center rounded-2 mx-1" onClick={() => {
                        }}>
                            <select className="form-select border-0 w-100 shadow-none" id="selectBrand">
                                <option className="">Brand</option>
                                <option>Brand 1</option>
                                <option>Brand 2</option>
                                <option>Brand 3</option>
                            </select>
                        </div>

                        <div class="my-2 d-flex border border-2 align-items-center rounded-2 mx-1" onClick={() => {
                        }}>
                            <select className="form-select border-0 w-100 shadow-none" id="selectBodyType">
                                <option className="">State</option>
                                <option>State 1</option>
                                <option>State 2</option>
                                <option>State 3</option>
                            </select>
                        </div>
                    </div>
                    <div className="py-2 me-1">
                        <button className="grey-btn h-100">
                            <ReactSVG className="filter-icon" src={FilterIcon} />
                        </button>
                    </div>

                    <div className="py-2 me-5">
                        <button className="grey-btn h-100">
                            <ReactSVG className="filter-icon" src={ResetIcon} />
                        </button>
                    </div>


                    <div class="my-2 d-flex border border-2 align-items-center rounded-2 px-2 space-between" onClick={() => { }} >
                        <input type="text" className="form-control border-0 shadow-none text-dark px-0" id="searchEditor" placeholder="Search by dealer name" />
                        <ReactSVG src={SearchIcon} />
                    </div>
                </div>
                <Table
                    columns={columns}
                    data={data}
                >
                </Table>
            </div> */}
    </div>
  );
}
