import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import EditViewDeleteReviewPassword from "../../../components/EditViewDeleteReviewPassword";
import Card from "react-bootstrap/Card";
import { ReactSVG } from "react-svg";
import PlusLargeIcon from "../../../assets/icons/bootstrap-icons/plus-lg.svg";
import UploadIcon from "../../../assets/icons/bootstrap-icons/upload.svg";
import Search from "../../../components/SearchField";
import Breadcrumbs from "../../../Breadcrumbs";
import { getToken } from "../../../utility/constants";
import apiHandler from "./apiHandler";
import Datatable from "../../../components/Table";
import { notifyError, notifySuccess } from "../../../utility/common";
import { ToastContainer } from "react-toastify";
import DeleteConfirmationModalComponent from "../../../components/DeleteConfirmationModalComponent";
import ExportButton from "../../../components/Export";

export default function CarDrivingSchools({ children }) {
  const token = getToken();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [reload, setReload] = useState(false);
  const [show, setShow] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const onEditInner = (id) => {
    navigate(`/car-driving-school/edit-car-driving-school/${id}`);
  };

  const onDeleteInner = async (id) => {
    apiHandler
      .deleteCarDrivingSchoolById(token, deleteId)
      .then((result) => {
        setShow(false);
        setReload(true);
        notifySuccess("Car Driving School deleted successfully");
        setDeleteConfirm(false);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  };

  const columns = [
    {
      name: "Sr. No.",
      selector: (row) => row.row_number,
      sortable: true,
      width: "200px",
    },
    {
      name: "Car Driving School Name",
      sortable: true,
      selector: (row) => row.school_name,
      width: "auto",
    },
    {
      name: "City",
      sortable: true,
      selector: (row) => row.city_name,
      width: "auto",
    },
    {
      name: "Action",
      sortable: false,
      width: "auto",
      cell: (row) => (
        <>
          <EditViewDeleteReviewPassword
            id={row.id}
            onEdit={onEditInner}
            onDelete={(id) => {
              setDeleteId(id);
              setShow(true);
            }}
          />
        </>
      ),
    },
  ];

  const sortFieldNames = {
    "Sr. No.": "id",
    "Car Driving School Name": "school_name",
    City: "city_name",
  };

  const Keys = [
    "id",
    "city_id",
    "school_name",
    "school_url",
    "driving_school_address",
    "driving_school_area",
    "driving_school_map",
    "latitude",
    "longitude",
    "phone_number",
    "email_id",
    "created_date",
    "updated_date",
    "city_name",
  ];

  const subHeaderComponentMemo = useMemo(() => {
    function handleSearchClick() {
      setReload(true);
    }
    return (
      <>
        <div className="col-md-6 p-0 m-0">
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center p-0 m-0">
              <div className="table-heading fw-bold d-flex align-items-center m-0 p-0">
                Car Driving School list ({totalRecords})
              </div>
              <div className="py-0 ms-2">
                <Search
                  onChange={(event) => {
                    console.log("ev", event);
                    setSearch(event.target.value);
                  }}
                  value={search}
                  placeholder={"Search by Driving School Name"}
                />
              </div>
              <button
                className="d-flex align-items-center justify-content-center search-btn ms-2"
                onClick={handleSearchClick}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="col-md-6 text-right">
          <div className="btngroup d-flex  justify-content-end flex-row flex-wrap">
            <ExportButton
              api={apiHandler.getCarDrivingSchools}
              totalRecords={totalRecords}
              keys={Keys}
              showKeys={Keys}
              payload={{
                search: "",
                sortBy: "",
                sortOrder: "",
                page: 1,
                limit: totalRecords,
              }}
            />
            <button
              className="add-button d-flex align-items-center justify-content-between mb-2 px-3"
              onClick={() =>
                navigate("/car-driving-school/add-car-driving-school")
              }
            >
              <ReactSVG
                src={PlusLargeIcon}
                height="18px"
                width="18px"
                className="mr-2"
              />{" "}
              Add Car Driving School
            </button>
          </div>
        </div>
      </>
    );
  }, [search, totalRecords, reload]);

  return (
    <div>
      <ToastContainer />
      <DeleteConfirmationModalComponent
        show={show}
        type={"delete"}
        handleClose={() => setShow(false)}
        setDeleteConfirm={onDeleteInner}
      />
      <Breadcrumbs />
      <Card>
        <Card.Body>
          <Card.Text>
            <div className="row dashboard">
              <Datatable
                api={apiHandler.getCarDrivingSchools}
                columns={columns}
                header={subHeaderComponentMemo}
                showHeader={true}
                sortFieldNames={sortFieldNames}
                search={search}
                reload={reload}
                defaultSortColumn={""}
                setReload={setReload}
                setTotalRecords={setTotalRecords}
              />
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
