import { useNavigate } from "react-router-dom";
import EditViewDelete from "../../../components/EditViewDeleteReviewPassword";
import { getToken, renderDate } from "../../../utility/constants";
import { useState } from "react";
import DeleteConfirmationModalComponent from "../../../components/DeleteConfirmationModalComponent";
import { deleteBikeUserReviewById } from "../BikeUserController";
import { toast } from "react-toastify";

export const BikeReviewColumn = (reloadTable, currentPage, itemsPerPage) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const token = getToken();

  const deleteBikeReview = async (id) => {
    try {
      const res = await deleteBikeUserReviewById(id, token);
      if (res) {
        toast.success("Bike Review deleted successfully");
        setModal(false);
        reloadTable();
      } else {
        throw new Error("Failed to delete bike review");
      }
    } catch (error) {
      console.error("Error deleting bike review:", error);
      toast.error("Failed to delete bike review");
    }
  };

  const onDelete = (id) => {
    setDeleteId(id);
    setModal(true);
  };

  const OnEdit = (id) => {
    navigate(`/edit-bike-user-review/${id}`);
  };

  const OnView = (id) => {
    navigate(`https://www.google.com/`);
  };

  return [
    {
      name: "Sr No.",
      sortable: true,
      selector: (_, index) =>
        index + 1 + currentPage * itemsPerPage - itemsPerPage,
      width: "35px",
    },
    // {
    //   name: "ID",
    //   sortable: true,
    //   selector: (row) => row.id,
    //   width: "35px"
    // },
    {
      name: "User Name",
      sortable: true,
      selector: (row) => row.name,
      width: "130px",
    },
    {
      name: "Email Id",
      sortable: false,
      selector: (row) => row.email,
      width: "130px",
    },
    {
      name: "Brand Name",
      sortable: false,
      selector: (row) => row.brand_name,
      width: "140px",
    },
    {
      name: "Model Name",
      sortable: false,
      selector: (row) => row.model_name,
      width: "140px",
    },
    {
      name: "Version Name",
      sortable: false,
      selector: (row) => row.version_name,
      width: "130px",
    },
    {
      name: "status",
      sortable: false,
      selector: (row) => {
        switch (row.status) {
          case 1:
            return <div className="badge bg-success">Approved</div>;
          case 2:
            return <div className="badge bg-warning">Pending</div>;
          case 3:
            return <div className="badge bg-danger">Rejected</div>;
          default:
            return "Unknown";
        }
      },
      width: "100px",
    },
    {
      name: "Date",
      sortable: false,
      selector: (row) => renderDate(row.created_date),
      width: "130px",
    },
    {
      name: "Action",
      sortable: false,
      width: "150px",
      cell: (row) => (
        <>
          <EditViewDelete
            id={row.id}
            onEdit={() => OnEdit(row.id)}
            // onView={() => OnView(row.id)}
            onDelete={() => onDelete(row.id)}
          />
          <DeleteConfirmationModalComponent
            heading="Delete Bike Review"
            message="Are you sure you want to delete this bike review?"
            show={modal && deleteId === row.id}
            handleClose={() => setModal(false)}
            setDeleteConfirm={() => deleteBikeReview(row.id)}
            type="delete"
          />
        </>
      ),
    },
  ];
};
