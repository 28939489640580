import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FAQapiHandler from "./FAQapiHandler";
import Breadcrumbs from "../../Breadcrumbs";
import { useFormik } from "formik";
import FAQValidation from "./FAQValidation";
import { Accordion, Card } from "react-bootstrap";
import HeadingTwo from "../../components/HeadingTwo";
import FormControl from "../../components/FormControl";
import FormSelect from "../../components/FormSelect";
import { getToken } from "../../utility/constants";
import BackIcon from "../../assets/icons/bootstrap-icons/Back_Arrow.svg";
import { ReactSVG } from "react-svg";
const BikeAnswerForm = ({ initialValues, onSubmit }) => {
  const navigate = useNavigate();
  const [questionList, setQuestionList] = useState([]);
  const token = getToken();
  console.log(initialValues);
  useEffect(() => {
    const getBikeQuestionAll = async () => {
      try {
        const payload = {
          sortBy: "",
          sortOrder: "",
        };
        const response = await FAQapiHandler.getBikeQuestionListAll(
          payload,
          token
        );
        console.log(response);
        setQuestionList(response.data.result);
      } catch (error) {
        console.log(error);
      }
    };
    getBikeQuestionAll();
  }, [token]);
  console.log(initialValues);
  const { handleSubmit, errors, values, touched, setFieldValue } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: FAQValidation.FAQAnswerSchema,
    onSubmit: async (values, action) => {
      try {
        const payload = {
          ...values,
        };
        const response = await onSubmit(payload);
        console.log(response, "form");
      } catch (error) {
        console.log(error, "form");
      }
    },
  });

  console.log(questionList, "questionList");
  console.log(values, "values");
  return (
    <>
      <div>
        <div className="d-flex justify-content-between">
          <Breadcrumbs />

          <button
            className="d-flex back-button py-2 px-3 mb-4 btn btn-primary"
            onClick={() => navigate("/bike-answers")}
          >
            {" "}
            <ReactSVG src={BackIcon} className="me-1" />
            Back
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="d-flex gap-4 justify-content-between">
            <Card className="p-3" style={{ width: "80%" }}>
              <HeadingTwo
                title="Add Bike Question"
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />
              <div className="row d-flex gap-4">
                <FormSelect
                  controlId={"question"}
                  label={"Question"}
                  placeholder={"Question"}
                  touched={touched}
                  errors={errors}
                  itemName={"question_name"}
                  showDefault={true}
                  defaultName={"Select Question"}
                  name={"question_id"}
                  value={values.question_id}
                  items={questionList}
                  setFieldValue={setFieldValue}
                />
                <FormControl
                  controlId={"answerInput"}
                  label={"Answer"}
                  placeholder={"Answer"}
                  touched={touched}
                  errors={errors}
                  name="answer_name"
                  itemName={"answer"}
                  value={values["answer_name"]}
                  setFieldValue={setFieldValue}
                />
                <FormSelect
                  controlId={"answerStatusInput"}
                  label={"Answer Status"}
                  placeholder={"Answer Status"}
                  touched={touched}
                  errors={errors}
                  itemName={"name"}
                  showDefault={true}
                  defaultName={"Select Status"}
                  name={"answer_status"}
                  value={values.answer_status}
                  items={[
                    { id: 1, name: "Approved" },
                    { id: 2, name: "Not Approved" },
                    { id: 3, name: "Pending Review" },
                  ]}
                  setFieldValue={setFieldValue}
                />
              </div>
            </Card>
            <div className="col-xl-3 col-sm-4 pe-2">
              <Accordion defaultActiveKey="0" className="mb-4">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="p-3 pb-1 ps-2 ">
                    Save
                  </Accordion.Header>
                  <Accordion.Body className="pt-1 d-flex">
                    <div className="w-100 d-flex justify-content-between">
                      <button
                        type="submit"
                        className="d-flex save-button align-items-center rounded mb-2"
                      >
                        Add
                      </button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default BikeAnswerForm;
