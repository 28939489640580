import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../request";

export function getUserList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}user-manage/filtered/list`,
    payload,
    getConfig(token)
  );
}
export function getUserDeatils(token, id) {
  return axios.get(
    `${BASE_URL_AUTOX}user-manage/user-details/${id}`,
    getConfig(token)
  );
}

export default {
  getUserList,
  getUserDeatils,
};
