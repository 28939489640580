import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs";
import { ReactSVG } from "react-svg";
import BackIcon from "../../assets/icons/bootstrap-icons/Back_Arrow.svg";
import { Card, Form } from "react-bootstrap";
import FormControl from "../../components/FormControl";
import HeadingTwo from "../../components/HeadingTwo";
import FormSelect from "../../components/FormSelect";
import SubscriptionValidationSchema from "./validationSchema";

import { getToken, renderDateNumerical } from "../../utility/constants";

export default function SubscriptionForm({ initialValues, onSubmit, title }) {
  const navigate = useNavigate();
  const { handleSubmit, errors, values, touched, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: SubscriptionValidationSchema,
    onSubmit: async (values, action) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.error(error);
      }
    },
  });
  console.log(values, errors);

  return (
    <>
      <div className="d-flex justify-content-between">
        <Breadcrumbs />
        <button
          className="d-flex back-button py-2 px-3 mb-4 btn btn-primary"
          onClick={() => navigate("/subscription")}
        >
          <ReactSVG src={BackIcon} className="me-1" />
          Back
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between gap-3">
          <div className="w-75 d-flex gap-3 flex-column">
            {/* Basic Information */}
            <Card className="p-3">
              <h4>Basic Information</h4>
              <div className="row">
                <div className="col-md-6">
                  <FormControl
                    controlId={"firstName"}
                    label={"First Name"}
                    placeholder={"Enter First Name"}
                    touched={touched}
                    errors={errors}
                    name={"first_name"}
                    value={values.first_name}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                    isRequired={true}
                  />
                </div>
                <div className="col-md-6">
                  <FormControl
                    controlId={"lastName"}
                    label={"Last Name"}
                    placeholder={"Enter Last Name"}
                    touched={touched}
                    errors={errors}
                    name={"last_name"}
                    value={values.last_name}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                    isRequired={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FormControl
                    controlId={"company"}
                    label={"Company"}
                    placeholder={"Enter Company Name"}
                    touched={touched}
                    errors={errors}
                    name={"company"}
                    value={values.company}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                  />
                </div>
                <div className="col-md-6">
                  <FormControl
                    controlId={"designation"}
                    label={"Designation"}
                    placeholder={"Enter Designation"}
                    touched={touched}
                    errors={errors}
                    name={"designation"}
                    value={values.designation}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FormControl
                    controlId={"state"}
                    label={"State"}
                    placeholder={"Enter State"}
                    touched={touched}
                    errors={errors}
                    name={"state"}
                    value={values.state}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                  />
                </div>
                <div className="col-md-6">
                  <FormControl
                    controlId={"city"}
                    label={"City"}
                    placeholder={"Enter City"}
                    touched={touched}
                    errors={errors}
                    name={"city"}
                    value={values.city}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FormControl
                    controlId={"pincode"}
                    label={"Pincode"}
                    placeholder={"Enter Pincode"}
                    touched={touched}
                    errors={errors}
                    name={"pincode"}
                    value={values.pincode}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                  />
                </div>
                <div className="col-md-6">
                  <FormControl
                    controlId={"address"}
                    label={"Address"}
                    placeholder={"Enter Address"}
                    touched={touched}
                    errors={errors}
                    name={"address"}
                    value={values.address}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                    as="textarea"
                    rows={3}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FormControl
                    controlId={"phone"}
                    label={"Phone"}
                    placeholder={"Enter Phone Number"}
                    touched={touched}
                    errors={errors}
                    name={"phone"}
                    value={values.phone}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                    isRequired={true}
                  />
                </div>
                <div className="col-md-6">
                  <FormControl
                    controlId={"email"}
                    label={"Email"}
                    placeholder={"Enter Email"}
                    touched={touched}
                    errors={errors}
                    name={"email"}
                    value={values.email}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                    isRequired={true}
                  />
                </div>
              </div>
            </Card>

            {/* Subscription Details */}
            <Card className="p-3">
              <h4>Subscription Details</h4>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="StatusControlSelect">
                    <Form.Label>
                      Start Date <sup className="text-danger">*</sup>{" "}
                      (dd-mm-yyyy)
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="start_date"
                      pattern="\d{4}-\d{2}-\d{2}"
                      placeholder="YYYY-MM-DD"
                      value={renderDateNumerical(values.start_date)}
                      onChange={(e) => {
                        const normalizedDate = e.target.value.replace(
                          /\//g,
                          "-"
                        );
                        setFieldValue("start_date", normalizedDate);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="StatusControlSelect">
                    <Form.Label>
                      End Date <sup className="text-danger">*</sup> (dd-mm-yyyy)
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="end_date"
                      pattern="\d{4}-\d{2}-\d{2}"
                      placeholder="YYYY-MM-DD"
                      value={renderDateNumerical(values.end_date)}
                      onChange={(e) => {
                        const normalizedDate = e.target.value.replace(
                          /\//g,
                          "-"
                        );
                        setFieldValue("end_date", normalizedDate);
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FormSelect
                    controlId={"plan"}
                    label={"Select Plan"}
                    placeholder={"Select Plan"}
                    touched={touched}
                    errors={errors}
                    name={"plan"}
                    itemName={"label"}
                    value={values.plan}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                    defaultName={"Select Plan"}
                    items={[
                      { value: "1", label: "1 Year" },
                      { value: "2", label: "2 Year" },
                      { value: "3", label: "3 Year" },
                      { value: "4", label: "4 Year" },
                      { value: "5", label: "5 Year" },
                      { value: "1 month", label: "1 Month" },
                    ]}
                    isRequired={true}
                  />
                </div>
                <div className="col-md-6">
                  <FormSelect
                    controlId={"source"}
                    label={"Select Source"}
                    placeholder={"Select Source"}
                    touched={touched}
                    errors={errors}
                    itemName={"label"}
                    name={"source"}
                    value={values.source}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                    isRequired={true}
                    defaultName={"Select Source"}
                    items={[
                      { value: "1", label: "Suscriber" },
                      { value: "2", label: "Auto Expo" },
                      { value: "3", label: "Krackerjack" },
                      { value: "4", label: "Others" },
                    ]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FormControl
                    controlId={"subscription_id"}
                    label={"Subscription ID"}
                    touched={touched}
                    disabled={true}
                    errors={errors}
                    name={"subscriber_id"}
                    value={values.subscriber_id}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                    readOnly={true}
                  />
                </div>
                <div className="col-md-6">
                  <FormControl
                    controlId={"remarks"}
                    label={"Remarks"}
                    placeholder={"Remarks.."}
                    touched={touched}
                    errors={errors}
                    name={"remarks"}
                    value={values.remarks}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                  />
                </div>
              </div>
            </Card>

            {/* Payment Details */}
            <Card className="p-3">
              <h4>Payment Details</h4>
              <div className="row">
                <div className="col-md-6">
                  <FormControl
                    controlId={"amount"}
                    label={"Amount"}
                    placeholder={"Amount Rs"}
                    touched={touched}
                    errors={errors}
                    name={"amount"}
                    value={values.amount}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                    isRequired={true}
                  />
                </div>
                <div className="col-md-6">
                  <FormSelect
                    controlId={"mode_of_payment"}
                    label={"Mode Of Payment"}
                    placeholder={"Select Mode"}
                    touched={touched}
                    errors={errors}
                    name={"mode_of_payment"}
                    value={values.mode_of_payment}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                    itemName={"label"}
                    defaultName={"Select Source"}
                    items={[
                      { value: "1", label: "Cash" },
                      { value: "2", label: "Cheque" },
                      { value: "3", label: "Online" },
                      { value: "4", label: "Complimentary" },
                    ]}
                    isRequired={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FormControl
                    controlId={"txn_id"}
                    label={"Txn / Cheque / Draft No."}
                    placeholder={"Enter Transaction ID"}
                    touched={touched}
                    errors={errors}
                    name={"txn_id"}
                    value={values.txn_id}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                  />
                </div>
                <div className="col-md-6">
                  <FormControl
                    controlId={"bank"}
                    label={"Bank"}
                    placeholder={"Enter Bank Name"}
                    touched={touched}
                    errors={errors}
                    name={"bank"}
                    value={values.bank}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                  />
                </div>
              </div>
            </Card>
          </div>
          <div className="w-25">
            <Card className="p-3">
              <HeadingTwo
                title={"Action"}
                showHeader={true}
                pointerOnHover={true}
              />
              <button type="submit" className="green-filled-button w-50">
                Save
              </button>
            </Card>
          </div>
        </div>
      </form>
    </>
  );
}
