export const FAQcustomStyles = {
  rows: {
    style: {
      "&:nth-child(2n+1)": {
        backgroundColor: "#F6F6F6",
        height: "150px",
      },

      paddingLeft: "16px",
      paddingRight: "16px",

      border: "0px !important",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#FFFFFF !important",
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingTop: "2px",
      paddingBottom: "2px",
      textAlign: "center",
    },
  },
  headCells: {
    style: {
      fontWeight: "600",
      fontSize: "13px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "center",
    },
  },
  cells: {
    style: {
      fontWeight: "400",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "center",
    },
  },
};
