import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from "../../utility/common";
import SubscriptionForm from "./SubscriptionForm";
import apiHandler from "./apiHandler";
import { getToken } from "../../utility/constants";

export default function EditSubscription() {
  const navigate = useNavigate();
  const { id } = useParams();
  const token = getToken();
  const [initialValues, setInitialValues] = useState({
    title: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    telephone: "",
    designation: "",
    subscription_id: "",
    subscriber_id: "",
    company: "",
    state: "",
    city: "",
    address: "",
    pincode: "",
    ip_address: "",
    status: 0,
    payment_status: "Paid",
    start_date: "",
    end_date: "",
    plan: -1,
    source: -1,
    remarks: "",
    amount: 0,
    mode_of_payment: -1,
    txn_id: "",
    bank: "",
  });
  useEffect(() => {
    const getSubscription = async () => {
      try {
        const response = await apiHandler.getSubscriptionDetails(id, token);
        console.log(response.data[0]);
        if (response.status === 200) {
          setInitialValues(response.data[0]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getSubscription();
  }, []);
  const EditSubscription = async (data) => {
    try {
      const response = await apiHandler.editSubscription(id, data, token);
      if (response.status === 200) {
        notifySuccess(response.data.message);
        setTimeout(() => {
          navigate("/subscription");
        }, 1500);
      }
    } catch (error) {
      console.log(error);
      notifyError(error.response.data.message);
    }
  };
  return (
    <>
      <SubscriptionForm
        initialValues={initialValues}
        onSubmit={EditSubscription}
        title={"Edit Subscription"}
      />
    </>
  );
}
