import React, { useEffect, useState } from "react";

import Layout from "../../../components/Layout";

import Breadcrumbs from "../../../Breadcrumbs";

export default function CategoryAds({ children }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div className="row dashboard" style={{ minHeight: "100%" }}>
        <div className="d-flex">
          <Breadcrumbs />
        </div>
        <div className="my-4" style={{ minHeight: "100%" }}>
          {children}
        </div>
      </div>
    </Layout>
  );
}
