import React from "react";
import { Form } from "react-bootstrap";

const NestedCategoryList = ({
  categories,
  values,
  setFieldValue,
  fieldName = "category_id",
  parentFieldName = "parent_category",
}) => {
  const renderCategory = (
    category,
    level = 0,
    visitedCategories = new Set(),
    parentId = null
  ) => {
    if (visitedCategories.has(category.id)) {
      return null; // Prevent infinite loop
    }
    visitedCategories.add(category.id);

    const isParent = category.children && category.children.length > 0;

    return (
      <div key={category.id} style={{ marginLeft: level * 20 }}>
        <div className="checkbox-container mb-2">
          <Form.Check
            type="checkbox"
            id={`${fieldName}-${category.id}`}
            onChange={(e) => {
              const isChecked = e.target.checked;
              if (isChecked) {
                setFieldValue(fieldName, category.id);
                setFieldValue("category_name", category.category_name);
                setFieldValue(parentFieldName, parentId);
              } else {
                setFieldValue(fieldName, null);
                setFieldValue("category_name", null);
                setFieldValue(parentFieldName, null);
              }
            }}
            name={fieldName}
            checked={values[fieldName] == category.id}
            disabled={isParent} // Disable checkbox if it's a parent
          />
          <label htmlFor={`${fieldName}-${category.id}`}>
            {category.category_name}
          </label>
        </div>
        {category.children && category.children.length > 0 && (
          <div style={{ marginLeft: 20 }}>
            {category.children.map((child) =>
              renderCategory(child, level + 1, visitedCategories, category.id)
            )}
          </div>
        )}
      </div>
    );
  };

  const buildCategoryTree = (categories) => {
    const categoryMap = {};
    categories.forEach((category) => {
      categoryMap[category.id] = { ...category, children: [] };
    });

    const categoryTree = [];
    categories.forEach((category) => {
      if (category.parent == 0) {
        categoryTree.push(categoryMap[category.id]);
      } else if (categoryMap[category.parent]) {
        categoryMap[category.parent].children.push(categoryMap[category.id]);
      }
    });

    return categoryTree;
  };

  const categoryTree = buildCategoryTree(categories);

  return (
    <div
      className="mb-3"
      style={{ overflowY: "auto", maxHeight: "300px", padding: "10px" }}
    >
      {categoryTree.map((category) => renderCategory(category))}
    </div>
  );
};

export default NestedCategoryList;
