import { useNavigate } from "react-router-dom";
import { getToken } from "../../../utility/constants";
import { useMemo, useState } from "react";
import apiHandler, { bulkUpdateBikeBrandOrdering } from "./apiHandler";
import { notifyError, notifySuccess } from "../../../utility/common";
import { downloadBikeBrandContent } from "../../../request";
import EditViewDeleteReviewPassword from "../../../components/EditViewDeleteReviewPassword";
import Search from "../../../components/SearchField";
import ExportButton from "../../../components/Export";
import { ReactSVG } from "react-svg";
import PlusLargeIcon from "../../../assets/icons/bootstrap-icons/button-icons/add.svg";
import DownloadIcon from "../../../assets/icons/bootstrap-icons/button-icons/download white.svg";
import { ToastContainer } from "react-toastify";
import Breadcrumbs from "../../../Breadcrumbs";
import { Card } from "react-bootstrap";
import DeleteConfirmationModalComponent from "../../../components/DeleteConfirmationModalComponent";
import Datatable from "../../../components/Table";

export default function BikeBrands() {
  const token = getToken();
  //eslint-disable-next-line
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [reload, setReload] = useState(false);

  const navigate = useNavigate();
  const onViewInner = async (slug) => {
    console.log("slug", slug);
    window.open(
      `${process.env.REACT_APP_FRONTEND_BASE_URL}/new-bikes/${slug}`,
      "_blank"
    );
    // apiHandler
    //   .getBikeBrandViewLink(token, id)
    //   .then((result) => {
    //     console.log("link", result);
    //     window.open(result.data.data, "_blank");
    //   })
    //   .catch((error) => {
    //     notifyError(error.message);
    //   });
  };
  const onEditInner = (id) => {
    navigate(`/bike-brand/edit-bike-brand/${id}`);
  };
  const onDeleteInner = () => {
    apiHandler
      .deleteBikeBrand(token, deleteId)
      .then(() => {
        setShow(false);
        setReload(true);
        notifySuccess("Bike Brand deleted successfully");
        setDeleteConfirm(false);
      })
      .catch((error) => {
        console.log(error);
        setShow(false);
        notifyError(error.message);
      });
  };

  const downloadBikeBrandContentWrapper = async () => {
    const result = await downloadBikeBrandContent(token);
    if (result.data.url) {
      window.open(result.data.url, "_blank");
    }
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      width: "90px",
    },
    {
      name: "Bike Brand Name",
      selector: (row) => row.brand_name,
      sortable: true,
      width: "auto",
    },
    {
      name: "Slug",
      sortable: true,
      selector: (row) => row.brand_url,
      width: "auto",
    },
    {
      name: "Ordering",
      sortable: true,
      selector: (row) => row.brand_ordering,
      width: "auto",
    },
    {
      name: "Action",
      orderable: false,
      width: "auto",
      cell: (row) => (
        <EditViewDeleteReviewPassword
          id={row.id}
          onView={() => onViewInner(row.brand_url)}
          onEdit={onEditInner}
          onDelete={(id) => {
            setDeleteId(id);
            setShow(true);
          }}
        />
      ),
    },
  ];

  const sortFieldNames = {
    "Bike Brand Name": "brand_name",
    Url: "brand_url",
    Ordering: "brand_ordering",
  };

  const keys = ["id", "brand_name", "brand_url"];
  const showKeys = ["id", "brand_name", "brand_url"];

  const subHeaderComponentMemo = useMemo(() => {
    function handleSearchClick() {
      setReload(true);
    }
    return (
      <>
        <div className="col-md-6">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div className="table-heading">
                Bike Brand List ({totalRecords})
              </div>
              <div className="py-0 ms-3">
                <Search
                  onChange={(event) => {
                    console.log("ev", event);
                    setSearch(event.target.value);
                  }}
                  value={search}
                  placeholder={"Search by brand name"}
                />
              </div>
              <button
                className="d-flex align-items-center justify-content-center search-btn ms-2 p-2"
                onClick={handleSearchClick}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6 text-right">
          <div className="btngroup d-flex  justify-content-end flex-row flex-wrap">
            <ExportButton
              totalRecords={totalRecords}
              api={apiHandler.getBikeBrandList}
              keys={keys}
              showKeys={showKeys}
              payload={{
                search: "",
                sortBy: "",
                sortOrder: "",
                page: 1,
                limit: totalRecords,
              }}
            />

            <button
              className="download-button d-flex align-items-center justify-content-between me-2 mb-2 px-3"
              onClick={downloadBikeBrandContentWrapper}
            >
              <ReactSVG
                src={DownloadIcon}
                className="mr-2"
                height="18px"
                width="18px"
              />{" "}
              Download
            </button>

            <button
              className="add-button d-flex align-items-center justify-content-between me-2 mb-2 px-3"
              onClick={() => navigate("/bike-brand/add-bike-brand")}
            >
              <ReactSVG
                src={PlusLargeIcon}
                className="mr-2"
                height="18px"
                width="18px"
              />{" "}
              Add New Brand
            </button>
          </div>
        </div>
      </>
    );
  }, [search, totalRecords, keys, showKeys, token, navigate]);

  return (
    <div>
      <ToastContainer />
      <Breadcrumbs />
      <Card>
        <Card.Body>
          <DeleteConfirmationModalComponent
            show={show}
            type={"delete"}
            handleClose={() => setShow(false)}
            setDeleteConfirm={onDeleteInner}
          />
          <Card.Text>
            <div className="row dashboard">
              <div className="">
                <Datatable
                  api={apiHandler.getBikeBrandList}
                  columns={columns}
                  header={subHeaderComponentMemo}
                  showHeader={true}
                  sortFieldNames={sortFieldNames}
                  search={search}
                  reload={reload}
                  defaultSortColumn={""}
                  setReload={setReload}
                  setTotalRecords={setTotalRecords}
                  isDraggable={true}
                  sortByDir={"asc"}
                  column_ordering={"brand_ordering"}
                  updateOrderingHandler={bulkUpdateBikeBrandOrdering}
                />
              </div>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
