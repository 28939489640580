import React, { useEffect, useState } from "react";
import EditorForm from "./EditorForm";
import apiHandler, { editEditor, getEditorDetails } from "./apiHandler";
import { getToken } from "../../utility/constants";
import { useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from "../../utility/common";
import useQuery from "../../components/useQuery";

export default function EditEditor() {
  const [initialValues, setInitialValues] = useState({});
  const token = getToken();
  const query = useQuery();
  const id = query.get("id");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEditorData = async () => {
      const response = await getEditorDetails(id, token);
      const data = response.data;

      // Convert module_id string to array
      if (data.module_id) {
        data.module_id = data.module_id.split(",").map(Number);
      }

      setInitialValues(data);
    };
    fetchEditorData();
  }, [id, token]);

  const handleSubmit = async (values) => {
    try {
      // Creating FormData instance
      const formData = new FormData();

      // Append fields to FormData
      formData.append("first_name", values.first_name);
      formData.append("last_name", values.last_name);
      formData.append("display_name", values.display_name);
      formData.append("twitter", values.twitter);
      formData.append("email", values.email);
      formData.append("alternate_email", values.alternate_email);
      // formData.append("user_name", values.user_name);
      // formData.append("password", values.password);
      formData.append("description", values.description);
      formData.append("module_id", values.module_id);
      formData.append("status", values.status);

      // If profile_image exists, append it
      if (values.profile_image) {
        formData.append("profile_image", values.profile_image);
      }

      // Handle module_id as an array
      // if (Array.isArray(values.module_id)) {
      //   values.module_id.forEach((moduleId, index) => {
      //     formData.append(`module_id[${index}]`, moduleId);
      //   });
      // }

      // Send the form data to the API
      const response = await editEditor(id, formData, token);

      if (response.status === 200) {
        notifySuccess("Editor Edited successfully");
        setTimeout(() => {
          navigate("/editor");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      notifyError(error.response?.data?.message || "Something went wrong");
    }
  };

  return (
    <EditorForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      formType="Edit"
    />
  );
}
