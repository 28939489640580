import { Card, Image } from "react-bootstrap";
import HeadingTwo from "../../components/HeadingTwo";
import DynamicSelect from "../CarUserReview/EditCarUserReview/DynamicSelect";
import FormControl from "../../components/FormControl";
import JoditEditor from "jodit-react";
import UpdateIcon from "../../assets/icons/bootstrap-icons/update white 1.svg";
import { getRootPagesList } from "./apiHandler";
import { useEffect, useRef, useState } from "react";
import FormFieldErrorMsg from "../../components/FormFieldErrorMsg";
import { getToken } from "../../utility/constants";

export default function PageForm({
  values,
  touched,
  errors,
  setFieldValue,
  handleSubmit,
  type,
}) {
  const [rootList, setRootList] = useState([]);
  const token = getToken();
  const getRootPages = async () => {
    const response = await getRootPagesList(token);
    console.log(response);
    setRootList(response.data.page);
  };

  useEffect(() => {
    getRootPages();
  }, []);

  const editor = useRef(null);
  console.log(values, errors);

  return (
    <div>
      <Card className="p-3">
        <HeadingTwo
          title="Page Form"
          type="dashboard"
          showHeader={true}
          pointerOnHover={true}
        />

        <div>
          <form>
            <div>
              <DynamicSelect
                label={"Page"}
                options={rootList}
                id={"parent"}
                values={values.parent}
                touched={touched}
                setFieldValue={setFieldValue}
                errors={errors}
                show
                name={"page_name"}
                checkedValue={values.parent}
              />
            </div>
            <div className="p-3 w-50">
              <FormControl
                controlId={"page_name"}
                label={"Page Name"}
                type={"text"}
                name={"page_name"}
                value={values.page_name}
                touched={touched}
                setFieldValue={setFieldValue}
                errors={errors}
              />
            </div>
            <div className="p-3 w-50">
              <FormControl
                controlId={"slug"}
                label={"Slug"}
                type={"text"}
                name={"slug"}
                value={values.slug}
                touched={touched}
                setFieldValue={setFieldValue}
                errors={errors}
              />
            </div>
            <div className="p-3 w-50">
              <FormControl
                controlId={"heading"}
                label={"Heading (H1)"}
                type={"text"}
                name={"heading"}
                value={values.heading}
                touched={touched}
                setFieldValue={setFieldValue}
                errors={errors}
              />
            </div>
            <div className="p-3 w-50">
              <FormControl
                controlId={"breadcrumb"}
                label={"Breadcrumb"}
                type={"text"}
                name={"breadcrumb"}
                value={values.breadcrumb}
                touched={touched}
                setFieldValue={setFieldValue}
                errors={errors}
              />
            </div>
            <div className="p-3 w-50">
              <FormControl
                controlId={"meta_title"}
                label={"Meta Title"}
                type={"text"}
                name={"meta_title"}
                value={values.meta_title}
                touched={touched}
                setFieldValue={setFieldValue}
                errors={errors}
              />
            </div>
            <div className="p-3 ">
              <FormControl
                controlId={"meta_description"}
                label={"Meta Description"}
                type={"text"}
                name={"meta_description"}
                value={values.meta_description}
                touched={touched}
                setFieldValue={setFieldValue}
                errors={errors}
                rows={5}
                as={"textarea"}
              />
            </div>
            <div className="p-3 ">
              <FormControl
                controlId={"meta_keywords"}
                label={"Meta Keywords"}
                type={"text"}
                name={"meta_keywords"}
                value={values.meta_keywords}
                touched={touched}
                setFieldValue={setFieldValue}
                errors={errors}
                rows={5}
                as={"textarea"}
              />
            </div>
            <div className="p-3 ">
              <label className="form-label">Content</label>
              <JoditEditor
                ref={editor}
                // config={{ height: 300 }}
                value={values.content}
                onBlur={(newContent) => setFieldValue("content", newContent)}
              />
              <FormFieldErrorMsg
                touched={touched}
                errors={errors}
                name={"content"}
              />
            </div>
            <div className="p-3 w-50">
              <FormControl
                controlId={"meta_canonical"}
                label={"Meta Canonical"}
                type={"text"}
                name={"meta_canonical"}
                value={values.meta_canonical}
                touched={touched}
                setFieldValue={setFieldValue}
                errors={errors}
              />
            </div>
            <div className="p-3 w-50">
              <label className="form-label">Status</label>
              <select
                className="form-select"
                aria-label="Default select example"
                value={values.status}
                onChange={(e) => setFieldValue("status", e.target.value)}
                name="status"
              >
                <option selected disabled>
                  Select Status
                </option>
                <option value="0">Unpublic</option>
                <option value="1">Public</option>
              </select>
            </div>
            <div className="p-3">
              <button
                type="button"
                className="d-flex save-button align-items-center rounded mb-2 "
                onClick={handleSubmit}
              >
                {" "}
                {type}
              </button>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
}
