import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import AuthLayout from "./AuthLayout";
import { verify_otp } from "../../request";
import SubmitButton from "../../components/SubmitButton";
import { getResetPasswordToken, getUserId } from "../../utility/constants";
import OtpInput from "react-otp-input";
import { notifyError } from "../../utility/common";

export default function VerifyOTP() {
  const navigate = useNavigate();

  const initialValues = {
    otpCode: "",
  };

  const validationSchema = Yup.object({
    otpCode: Yup.string()
      .length(4, "OTP must be exactly 4 digits")
      .required("Otp is required"),
  });

  return (
    <AuthLayout>
      <main className="d-flex w-100 h-100">
        <div className="container">
          <div className="row h-100">
            <div className="col-sm-10 col-md-10 col-lg-10 col-xl-10 col-lg-10 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center mt-3">
                  <div className="auth-heading">Forgot Password</div>
                  <p className="p-3 auth-message">
                    Enter the 4 digit code that you have received on your email
                  </p>
                </div>

                <div className="">
                  <div className="">
                    <div className="m-sm-3">
                      <div className="mb-3 text-center">
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          onSubmit={(values, { setSubmitting }) => {
                            const input_otp = values.otpCode;
                            const token = getResetPasswordToken();
                            const user_id = getUserId();
                            verify_otp({ token, otp: input_otp, user_id })
                              .then((res) => {
                                navigate("/reset-password");
                              })
                              .catch((err) => {
                                notifyError(
                                  err?.response?.data?.error ||
                                    err?.response?.data?.message
                                );
                              });
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <div className="d-flex justify-content-center">
                                <div className="row col-9">
                                  <div className="otp-container">
                                    <OtpInput
                                      onChange={(num) => {
                                        setFieldValue("otpCode", num);
                                      }}
                                      numInputs={4}
                                      isInputNum={true}
                                      value={values.otpCode}
                                      shouldAutoFocus={true}
                                      renderInput={(props) => (
                                        <input {...props} />
                                      )}
                                    />
                                  </div>

                                  <div
                                    className="text-danger mt-2"
                                    style={{ textAlign: "center" }}
                                  >
                                    {touched["otpCode"] && errors["otpCode"]}
                                  </div>
                                </div>
                              </div>

                              <div className="d-grid gap-2 mt-3 mb-3">
                                <SubmitButton title="Submit" />
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </AuthLayout>
  );
}
