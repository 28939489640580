import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../request";

/**
 * Deletes a page by its ID using the provided token.
 *
 * @param {string} id - The ID of the page to delete.
 * @param {string} token - The authentication token.
 * @return {Promise} A promise that resolves to the result of the deletion.
 */
export function deletePageById(id, token) {
  // Send a DELETE request to the API to delete the page with the specified ID
  return axios.delete(`${BASE_URL_AUTOX}page/delete/${id}`, getConfig(token));
}

/**
 * Retrieves a list of pages filtered by the provided parameters, using the provided token.
 *
 * @param {string} token - The authentication token.
 * @param {object} params - The parameters to filter the page list.
 * @return {Promise} A promise that resolves to the result of the request.
 */
export function getPageList(token, params) {
  // Send a POST request to the API to retrieve the filtered page list
  return axios.post(
    `${BASE_URL_AUTOX}page/filtered/list`,
    params,
    getConfig(token)
  );
}

/**
 * Retrieves a page by its ID using the provided token.
 *
 * @param {string} id - The ID of the page to retrieve.
 * @param {string} token - The authentication token.
 * @return {Promise} A promise that resolves to the result of the request.
 */
export function getPageById(id, token) {
  // Send a GET request to the API to retrieve the page with the specified ID
  return axios.get(`${BASE_URL_AUTOX}page/details/${id}`, getConfig(token));
}

/**
 * Adds a new page using the provided payload and token.
 *
 * @param {object} payload - The data for the new page.
 * @param {string} token - The authentication token.
 * @return {Promise} A promise that resolves to the result of the request.
 */
export function addNewPage(payload, token) {
  // Send a POST request to the API to add a new page
  return axios.post(`${BASE_URL_AUTOX}page/add`, payload, getConfig(token));
}

/**
 * Updates a page by its ID using the provided payload and token.
 *
 * @param {string} id - The ID of the page to update.
 * @param {object} payload - The data for the updated page.
 * @param {string} token - The authentication token.
 * @return {Promise} A promise that resolves to the result of the request.
 */
export function updatePageById(id, payload, token) {
  // Send a PUT request to the API to update the page with the specified ID
  return axios.put(
    `${BASE_URL_AUTOX}page/update/${id}`,
    payload,
    getConfig(token)
  );
}

/**
 * Retrieves a list of root pages using the provided token.
 *
 * @param {string} token - The authentication token.
 * @return {Promise} A promise that resolves to the result of the request.
 */
export function getRootPagesList(token) {
  // Send a GET request to the API to retrieve the list of root pages
  return axios.get(`${BASE_URL_AUTOX}page/root/list`, getConfig(token));
}

/**
 * The default export of the module. Contains the functions for deleting and retrieving pages.
 *
 * @module pages/apiHandler
 * @exports {Object}
 */
export default {
  deletePageById,
  getPageList,
  getPageById,
  addNewPage,
  updatePageById,
  getRootPagesList,
};
