import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Breadcrumbs from "../../../Breadcrumbs";
import Image from "react-bootstrap/Image";
import BackIcon from "../../../assets/icons/Back_Arrow.png";
import { getToken } from "../../../utility/constants";
import { notifyError, notifySuccess } from "../../../utility/common";
import apiHandler from "./apiHandler";
import { carDrivingSchoolValidation } from "./schemaValidation";
import CarDrivingSchoolForm from "./CarDrivingSchoolForm";

export default function AddCarDrivingSchool() {
  const token = getToken();
  const navigate = useNavigate();

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    values,
    errors,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: {
      city_id: -1,
      state_id: -1,
      school_name: "",
      school_url: "",
      driving_school_address: "",
      driving_school_area: "",
      driving_school_map: "",
      latitude: "",
      longitude: "",
      phone_number: "",
      email_id: "",
      brand_id: -1,
    },

    validationSchema: carDrivingSchoolValidation,

    onSubmit: async (values, action) => {
      const payload = { ...values };
      delete payload.state_id;
      apiHandler
        .addCarDrivingSchool(token, payload)
        .then((result) => {
          action.resetForm();
          notifySuccess("Car Driving School added successfully");
          navigate(
            `/car-driving-school/edit-car-driving-school/${result.data.result.id}?added=true`
          );
        })
        .catch((error) => {
          notifyError(error.message);
        });
    },
  });

  return (
    <div className="row dashboard">
      <div className="row">
        <div className="col-xl-11 col-sm-11">
          <Breadcrumbs />
        </div>
        <div className="col-xl-1 col-sm-1">
          <button
            className="d-flex back-button py-2 px-3 mb-2 btn btn-primary"
            onClick={() => navigate("/car-driving-school")}
          >
            <Image
              src={BackIcon}
              width="16px"
              height="16px"
              className="mt-1 me-2"
            />{" "}
            Back
          </button>
        </div>
      </div>
      <Card>
        <Card.Body>
          <Card.Text>
            <div className="row dashboard">
              <div className="table-heading fw-bold">
                Car Driving School Form
              </div>
              <CarDrivingSchoolForm
                values={values}
                errors={errors}
                touched={touched}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                type={"Add"}
              />
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
