import React from "react";

import Layout from "../../../components/Layout";

export default function CarCityPriceMain({ children }) {
  return (
    <Layout>
      <div>
        <div className="my-4">{children}</div>
      </div>
    </Layout>
  );
}
