import axios from "axios";
import { getConfig, BASE_URL_AUTOX } from "../../../request";
export const GET_CAR_SERVICE_CENTRE = "car/service-centre/filtered/list";
export const GET_CAR_SERVICE_CENTRE_BY_ID = "car/service-centre/";
export const ADD_CAR_SERVICE_CENTRE = "car/service-centre/add";
export const UPDATE_CAR_SERVICE_CENTRE = "car/service-centre/update/";
export const DELETE_CAR_SERVICE_CENTRE = "car/service-centre/delete/";
export const GET_ALL_CAR_CITIES = "car/city/all/list";
export const GET_ALL_BRAND_LIST = "car/brand/all/list";
export const GET_ALL_STATES = "state/all/list";

/**
 * Add New Car Service Centre Data
 * addCarServiceCentre is used to create new car service centre data
 * @param token
 * @param payload
 */
export function addCarServiceCentre(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${ADD_CAR_SERVICE_CENTRE}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get List of Car Service Centres
 * getCarServiceCentres is used to get all the data of car service centres
 * @param token
 * @param payload
 */
export function getCarServiceCentres(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_CAR_SERVICE_CENTRE}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get Car Service Centre By Id
 * getCarServiceCentreById is used to get data of car service centre by id
 * @param token
 * @param id
 */
export function getCarServiceCentreById(token, id) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_CAR_SERVICE_CENTRE_BY_ID}${id}`,
    getConfig(token)
  );
}

/**
 * Update Car Service Centre By Id
 * updateCarServiceCentreById is used to update data of car service centre by id
 * @param token
 * @param id
 * @param payload
 */
export function updateCarServiceCentreById(token, id, payload) {
  return axios.put(
    `${BASE_URL_AUTOX}${UPDATE_CAR_SERVICE_CENTRE}${id}`,
    payload,
    getConfig(token)
  );
}

/**
 * Delete Car Service Centre By Id
 * deleteCarServiceCentreById is used to delete single record of car service centre by id
 * @param token
 * @param id
 */
export function deleteCarServiceCentreById(token, id) {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_CAR_SERVICE_CENTRE}${id}`,
    getConfig(token)
  );
}

/**
 * Get List of States
 * getAllStates is used to get subset data of all cities
 * @param token
 * @param payload
 */
export function getAllCarCities(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_ALL_CAR_CITIES}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get List of Cities
 * getAllCarCities is used to get subset data of all cities
 * @param token
 * @param payload
 */
export function getCityBystate(token, payload) {
  return axios.get(
    `${BASE_URL_AUTOX}car/city/city-by-state/${payload}`,
    getConfig(token)
  );
}

/**
 * Get List of Brands
 * getAllBrands is used to get data of all brands
 * @param token
 * @param payload
 */
export function getAllBrands(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_ALL_BRAND_LIST}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get List of States
 * getAllStates is used to get data of states
 * @param token
 * @param payload
 */
export function getAllStates(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_ALL_STATES}`,
    payload,
    getConfig(token)
  );
}

export default {
  addCarServiceCentre,
  getCarServiceCentres,
  getCarServiceCentreById,
  updateCarServiceCentreById,
  deleteCarServiceCentreById,
  getAllBrands,
  getAllCarCities,
  getAllStates,
  getCityBystate,
};
