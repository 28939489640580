import * as Yup from "yup";

const categoryValidationSchema = Yup.object().shape({
  parent: Yup.number().nullable().required("Parent is required"),
  category_name: Yup.string().max(255).required("Category name is required"),
  slug: Yup.string().max(255).nullable(),
  heading: Yup.string().max(500).nullable(),

  breadcrumb: Yup.string().max(255).nullable(),
  image: Yup.string().nullable(),
  description: Yup.string().nullable(),

  meta_title: Yup.string().max(255).nullable(),
  meta_description: Yup.string().nullable(),
  meta_keywords: Yup.string().nullable(),
  meta_canonical: Yup.string().max(255).nullable(),
});

export default categoryValidationSchema;
