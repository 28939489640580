import Breadcrumbs from "../../Breadcrumbs";
import Layout from "../../components/Layout";
import BikeUserReview from "./BikeUserReviews";

const BikeUserReviewMain = () => {
  return (
    <Layout>
      <div className="row dashboard">
        <Breadcrumbs />
        <BikeUserReview />
      </div>
    </Layout>
  );
};

export default BikeUserReviewMain;
