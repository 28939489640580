import * as Yup from "yup";

const popupAdSchema = Yup.object().shape({
  desktop_top: Yup.string(),
  desktop_bottom: Yup.string(),
  mobile_top: Yup.string(),
  mobile_bottom: Yup.string()
});

export default popupAdSchema;
