import React, { useState, useRef } from "react";
import Card from "react-bootstrap/Card";
import { ReactSVG } from "react-svg";
import ListGroup from "react-bootstrap/ListGroup";
import ViewIcon from "../../../assets/icons/bootstrap-icons/eye.svg";
import DeleteIcon from "../../../assets/icons/bootstrap-icons/trash3.svg";
import { Link, useNavigate } from "react-router-dom";
import DeleteConfirmationModalComponent from "../../../components/DeleteConfirmationModalComponent";
import EditIcon from "../../../assets/icons/bootstrap-icons/pencil-square.svg";
export default function PopularCars({ updateSort, carData, handleDelete }) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  const showPopup = (carId) => {
    setShow(true);
    setDeleteId(carId);
    //  handleDelete(carid)
  };
  const onDeleteInner = () => {
    setShow(false);
    handleDelete(deleteId);
  };

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  //Handle Sort
  const handleSort = () => {
    //duplicate Items
    let __carDataItems = [...carData];

    //remove and save the dragged item content
    const draggedItemContent = __carDataItems.splice(dragItem.current, 1)[0];

    //switch the position
    __carDataItems.splice(dragOverItem.current, 0, draggedItemContent);

    dragItem.current = null;
    dragOverItem.current = null;

    const sortItems = __carDataItems.map((items) => items.id);
    updateSort(sortItems);
  };

  return (
    <Card className="pb-3 mb-4">
      <Card.Body className="popularCars_table">
        <Card.Text>
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="table-heading mb-3">Popular Cars List</div>
                  <div className="py-0 ms-5"></div>
                </div>
              </div>
            </div>
          </div>
        </Card.Text>

        <div className="top-container">
          <div className="container">
            <div className="row">
              <div className="col-6 me-auto carousel-labels">Popular Cars</div>
              <div className="col me-auto carousel-labels">Popularity</div>
              <div className="col me-auto carousel-labels">Action</div>
            </div>
          </div>
          <ListGroup as="ol" className="carCarouselList">
            {carData.map((item, index) => {
              return (
                <>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-center li-container cursor-move"
                    draggable
                    onDragStart={(e) => (dragItem.current = index)}
                    onDragEnter={(e) => (dragOverItem.current = index)}
                    onDragEnd={handleSort}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-6">
                          <div className="">{item.model_name} </div>
                        </div>
                        <div className="col me-auto">
                          <div className="">{item.popular_position}</div>
                        </div>
                        <div className="col action-div">
                          <span className="action-button blue-btn-action">
                            <ReactSVG
                              src={EditIcon}
                              onClick={() =>
                                navigate(
                                  `/car-models/edit-car-model/${item.id}`
                                )
                              }
                              style={{ color: "rgb(38, 191, 148)" }}
                            />
                          </span>{" "}
                          <span className="action-button red-btn-action">
                            <ReactSVG
                              src={DeleteIcon}
                              onClick={() => showPopup(item.id)}
                              style={{ color: "rgb(230, 83, 60)" }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </ListGroup.Item>
                </>
              );
            })}
            <DeleteConfirmationModalComponent
              show={show}
              type={"delete"}
              handleClose={() => setShow(false)}
              setDeleteConfirm={onDeleteInner}
            />
          </ListGroup>
        </div>
      </Card.Body>
    </Card>
  );
}
