import { Card } from "react-bootstrap";
import HeadingTwo from "../../components/HeadingTwo";
import DeleteConfirmationModalComponent from "../../components/DeleteConfirmationModalComponent";
import { useNavigate } from "react-router-dom";
import apiHandler from "./apiHandler";
import { useState } from "react";
import EditViewDelete from "../../components/EditViewDeleteReviewPassword";
import Datatable from "../../components/Table";
import { getToken, renderDate } from "../../utility/constants";
import Breadcrumbs from "../../Breadcrumbs";
import { ReactSVG } from "react-svg";
import PlusLargeIcon from "../../assets/icons/bootstrap-icons/plus-lg.svg";
import { notifyError, notifySuccess } from "../../utility/common";

// Helper function to determine status
const getStatus = (endDate) => {
  const currentDate = new Date();
  const end = new Date(endDate);

  if (end < currentDate) {
    return <button className="subscription-status-red">Expired</button>;
  } else if (
    end.toDateString() === currentDate.toDateString() ||
    end >= currentDate
  ) {
    return <button className="subscription-status-green">Current</button>;
  }
};

export default function Subscription() {
  const [reloadTable, setReloadTable] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [subscriptionIdToDelete, setSubscriptionIdToDelete] = useState();
  const navigate = useNavigate();
  const token = getToken();
  const onEditInner = (id) => {
    navigate("/subscription/edit-subscription/" + id);
  };

  const onDeleteInner = async () => {
    try {
      await apiHandler.deleteSubScription(subscriptionIdToDelete, token);
      setShowDeleteModal(false);
      setReloadTable(true);
      notifySuccess("Subscription deleted successfully");
    } catch (error) {
      setShowDeleteModal(false);
      notifyError(error.message);
    }
  };

  const columns = [
    {
      name: "Subscriber ID",
      sortable: false,
      selector: (row) => row.id,
      width: "150px",
    },
    {
      name: "Name",
      sortable: true,
      selector: (row) => row.first_name + " " + row.last_name,
      width: "200px",
    },
    {
      name: "Phone no",
      sortable: true,
      selector: (row) => (row.phone == "nan" ? "N/A" : row.phone),
      width: "180px",
    },
    {
      name: "Amount",
      sortable: true,
      selector: (row) => row.amount,
      width: "100px",
    },
    {
      name: "Start Date",
      sortable: true,
      selector: (row) => renderDate(row.start_date),
      width: "120px",
    },
    {
      name: "End Date",
      sortable: true,
      selector: (row) => renderDate(row.end_date),
      width: "120px",
    },
    {
      name: "Created Date",
      sortable: true,
      selector: (row) => renderDate(row.created_date),
      width: "120px",
    },
    {
      name: "Status",
      sortable: true,
      selector: (row) => getStatus(row.end_date),
      width: "auto",
    },
    {
      name: "Action",
      sortable: false,
      width: "auto",
      cell: (row) => (
        <EditViewDelete
          id={row.id}
          onEdit={onEditInner}
          onDelete={() => {
            setSubscriptionIdToDelete(row.id);
            setShowDeleteModal(true);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <Breadcrumbs />
        <button
          className="add-service-centre-button d-flex align-items-center justify-content-between mb-2 px-3"
          onClick={() => navigate("/subscription/add-subscription")}
        >
          <ReactSVG
            src={PlusLargeIcon}
            height="18px"
            width="18px"
            className="mr-2"
          />{" "}
          Add Subscription
        </button>
      </div>
      <Card className="p-3">
        <div>
          <HeadingTwo
            title={"Subscription"}
            type={"dashboard"}
            showHeader
            pointerOnHover
          />
        </div>
        <div>
          <Datatable
            columns={columns}
            api={apiHandler.getSubscriptionList}
            reload={reloadTable}
            setReload={setReloadTable}
            setTotalRecords={setTotalPages}
          />
          <DeleteConfirmationModalComponent
            show={showDeleteModal}
            type={"delete"}
            handleClose={() => setShowDeleteModal(false)}
            setDeleteConfirm={onDeleteInner}
          />
        </div>
      </Card>
    </>
  );
}
