import React from "react";
import Layout from "../../../components/Layout";

export default function BikeBrandsMain({ children }) {
  return (
    <Layout>
      <div className="row dashboard">
        <div className="d-flex">
          <div className="margin-heading col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-4 col-6"></div>
        </div>
        <div>{children}</div>
      </div>
    </Layout>
  );
}
