import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../../assets/icons/bootstrap-icons/Back_Arrow.svg";
import { ReactSVG } from "react-svg";
import CarFaqForm from "./CarFaqForm";
import { useFormik } from "formik";
import { getToken } from "../../../utility/constants";
import { addCarFAQ } from "./CarFAQapiHandler";
import { ToastContainer, toast } from "react-toastify";
import Breadcrumbs from "../../../Breadcrumbs";
import FAQCarShortCode from "./FAQCarShortCode";
import { CarFAQValidation } from "./CarFAQValidation";
import apiHandler from "./apiHandler";

export default function AddCarFAQ() {
  const navigate = useNavigate();
  const token = getToken();

  const { handleSubmit, values, touched, errors, setFieldValue } = useFormik({
    initialValues: {
      question: "",
      answer: "",
      faq_ordering: 1,
      is_default: 0,
      faq_condition: "",
    },
    validationSchema: CarFAQValidation,
    onSubmit: async (values, action) => {
      try {
        const payload = {
          ...values,
        };
        console.log(values);
        const response = await apiHandler.addCarFAQ(payload);
        console.log(response);
        if (response.status === 200) {
          toast.success("Car FAQ added successfully");
          setTimeout(() => {
            navigate("/car-faq");
          }, 1500);
        }
      } catch (error) {
        console.log(error);
      }
    },
  });
  return (
    <div className="row dashboard">
      <ToastContainer />

      <div className="d-flex gap-3 justify-content-between">
        <CarFaqForm
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          handleSubmit={handleSubmit}
          type={"Save"}
        />
        <FAQCarShortCode />
      </div>
    </div>
  );
}
