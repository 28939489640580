import React, { useState } from "react";
import {
  BrowserRouter,
  Navigate,
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ReactSVG } from "react-svg";
import Layout from "../../../components/Layout";
import Heading from "../../../components/Heading";
import HeadingTwo from "../../../components/HeadingTwo";
import PlusLargeIcon from "../../../assets/icons/bootstrap-icons/plus-lg.svg";
import DownloadIcon from "../../../assets/icons/bootstrap-icons/download.svg";

export default function BikeVehicleTypeMain({ children }) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="row dashboard">
        {/* <div className="d-flex">
                    <div className="margin-heading col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-4 col-6">
                        <Heading
                            title="New Bike"
                            type="dashboard"
                            showHeader={true}
                            pointerOnHover={true}
                        />
                        <div className="d-flex w-100 align-items-center justify-content-between mb-2 sub-heading">
                            <HeadingTwo
                                title={<span className="">Bike Vehicle Type Management</span>}
                                type="dashboard"
                                showHeader={true}
                                pointerOnHover={true}>
                            </HeadingTwo>
                            <div className="d-flex justify-content-end">
                                {location.pathname==="/car-vehicle-type" ?
                                    (<>
                                        <button className="purple-btn" onClick={()=>navigate("/add-car-vehicle-type")}><div className="d-flex"><ReactSVG src={PlusLargeIcon} />&nbsp;Add Bike Vehicle Type</div></button>
                                    </>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div> */}
        <div>{children}</div>
      </div>
    </Layout>
  );
}
