import { useState } from "react";
import { ReactSVG } from "react-svg";
import * as XLSX from "xlsx";
import DownloadIcon from "../assets/icons/bootstrap-icons/button-icons/download white.svg";
import { notifyError, notifySuccess } from "../utility/common";
import { addCarDealerImport } from "../pages/NewCar/CarDealer/apiHandler";
import { addbikeDealerImport } from "../pages/NewBike/BikeDealer/apiHandler";

export default function BulkImportCarDealer({ setReload, type }) {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleFileUpload = async () => {
    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const options = {
        header: 1,
        defval: "",
      };

      const json = XLSX.utils.sheet_to_json(worksheet, options);
      const finalData = json.slice(1).map((row) => ({
        city_id: row[0],
        brand_id: row[1],
        dealer_name: row[2],
        dealer_url: row[3] || "",
        dealer_address: row[4] || "",
        dealer_area: row[5] || "",
        dealer_map: row[6] || "",
        phone_number: row[7] || "",
        email_id: row[8] || "",
        dealer_content: row[9] || "",
      }));

      try {
        let response;
        if (type == "car") {
          response = await addCarDealerImport(finalData);
        } else if (type == "bike") {
          response = await addbikeDealerImport(finalData);
        }
        if (response.status === 200) {
          notifySuccess("Car Dealer imported successfully");
          setFile(null);
          setReload(true);
        } else {
          notifyError(response.response.data.message);
        }
      } catch (error) {
        console.error("Error uploading data:", error);
      }
    };

    reader.readAsBinaryString(file);
  };

  return (
    <>
      <input
        type="file"
        className="form-control shadow-none file-input m-0 me-3"
        onChange={handleFileChange}
      />
      <button
        className="add-button d-flex align-items-center justify-content-between me-2 mb-2 px-3"
        onClick={handleFileUpload}
      >
        <ReactSVG
          src={DownloadIcon}
          className="mr-2"
          height="18px"
          width="18px"
        />
        Import Data
      </button>
    </>
  );
}
