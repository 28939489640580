import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../request";

export function getGalleryList(token, params) {
  return axios.post(
    `${BASE_URL_AUTOX}gallery/regular/filtered/list`,
    params,
    getConfig(token)
  );
}

export default {
  getGalleryList,
};
