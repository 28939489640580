import { Accordion, Form } from "react-bootstrap";
import FormFieldErrorMsg from "../../../../components/FormFieldErrorMsg";
import pdfSvg from "../../../../assets/icons/pdf-svg.svg";
import { renderText } from "../../../../utility/constants";
import { useState } from "react";
import { notifyError } from "../../../../utility/common";
const AddBikeRight = ({
  touched,

  errors,
  values,
  setFieldValue,
  brandDetails,
  bodyTypeDetails,
  handleFileChange,
  fileName,
  selectedFile,

  handleBrochureFileChange,
  brochureFileName,
}) => {
  const [fileNames, setFileNames] = useState("No file choosen");
  return (
    <>
      <Accordion defaultActiveKey="0" className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="p-3 pb-2 ps-2 pb-0">
            Electric Bike
          </Accordion.Header>
          <Accordion.Body className="pt-0 checkbox-body">
            <div className="">
              <div class="checkbox-container">
                <input
                  type="checkbox"
                  id="myCheckbox"
                  checked={values.electric_bike}
                  onChange={(e) =>
                    setFieldValue("electric_bike", e.target.checked)
                  }
                  name="electric_bike"
                />
                <label htmlFor="myCheckbox">Electric Bike</label>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion defaultActiveKey="0" className="mb-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="p-3 pb-1 ps-2">
            Bike Brand <sup className="text-danger">*</sup>
          </Accordion.Header>
          <Accordion.Body className="scrollable-body">
            <div>
              <div className="mb-3">
                {brandDetails.map((item, index) => (
                  <div class="checkbox-container mb-2">
                    <Form.Check
                      type="checkbox"
                      id={index}
                      onChange={(e) => {
                        setFieldValue("brand_name", item.brand_name);
                        setFieldValue("brand_id", item.id);
                      }}
                      name="brand"
                      checked={values.brand_id == item.id}
                    />
                    <label htmlFor="myCheckbox">{item.brand_name}</label>
                  </div>
                ))}
              </div>
            </div>
          </Accordion.Body>
          <div className="p-2">
            <FormFieldErrorMsg
              touched={touched}
              errors={errors}
              name={"brand_id"}
            />
          </div>
        </Accordion.Item>
      </Accordion>

      <Accordion defaultActiveKey="0" className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="p-3 pb-1 ps-2">
            Vehicle Type <sup className="text-danger">*</sup>
          </Accordion.Header>
          <Accordion.Body className="scrollable-body">
            <div>
              <div className="mb-3">
                {bodyTypeDetails.map((item, index) => (
                  <div class="checkbox-container mb-2">
                    <Form.Check
                      type="checkbox"
                      id={index}
                      onChange={(e) => setFieldValue("body_type", item.id)}
                      name="vehicle_type"
                      checked={values.body_type == item.id}
                    />
                    <label htmlFor="myCheckbox">{item.type_name}</label>
                  </div>
                ))}{" "}
              </div>
            </div>
          </Accordion.Body>
          <div className="p-2">
            <FormFieldErrorMsg
              touched={touched}
              errors={errors}
              name={"body_type"}
            />
          </div>
        </Accordion.Item>
      </Accordion>

      <Accordion defaultActiveKey="0" className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="p-3 ps-2 pb-0 brand_logo_btn">
            Model Image <sup className="text-danger">*</sup>
            <small className="small-text">(Format : JPG, JPEG, PNG)</small>
          </Accordion.Header>
          <Accordion.Body className="pt-0 ">
            <div className="mt-4">
              {(values.model_image && typeof values.model_image === "string") ||
              (values.model_image && values.model_image instanceof File) ? (
                <img
                  src={
                    values.model_image instanceof File
                      ? URL.createObjectURL(values.model_image)
                      : values.model_image
                  }
                  className="w-75 h-75"
                  alt="Brand Logo"
                />
              ) : null}

              <Form.Group controlId="formImageFile" className="mb-1">
                {" "}
                <div className="file-container">
                  <label htmlFor="imageFileInput" className="custom-file-input">
                    {" "}
                    Choose File
                  </label>
                  <input
                    type="file"
                    id="imageFileInput"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      const selectedFile = e.target.files[0];
                      const maxSizeKB = 200;
                      if (selectedFile) {
                        if (selectedFile.size > maxSizeKB * 1024) {
                          notifyError(
                            "Image size exceeds 200Kb. Please choose a smaller image."
                          );
                          e.target.value = "";
                          return;
                        }
                        const img = new Image();
                        const objectUrl = URL.createObjectURL(selectedFile);

                        img.onload = () => {
                          // Check image dimensions
                          if (img.width !== 500 || img.height !== 261) {
                            notifyError("Image dimensions must be 500x261.");
                            e.target.value = "";
                            return;
                          }

                          setFieldValue("model_image", selectedFile);
                          setFileNames(selectedFile.name);
                          URL.revokeObjectURL(objectUrl);
                        };

                        img.onerror = () => {
                          notifyError("Invalid image file.");
                          e.target.value = "";
                          URL.revokeObjectURL(objectUrl);
                        };

                        img.src = objectUrl;
                      } else {
                        setFieldValue("model_image", null);
                        setFileNames("No file chosen");
                      }
                    }}
                  />
                  <span className="file-name ps-1">
                    {renderText(fileNames, 15)}
                  </span>
                </div>
                <Form.Label className="logo-dimension">
                  Max 200Kb & Dimension:500X261
                </Form.Label>
                <FormFieldErrorMsg
                  touched={touched}
                  errors={errors}
                  name={"model_image"}
                />
              </Form.Group>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion defaultActiveKey="0" className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="p-3 ps-2 pb-0 brand_logo_btn">
            Model Brochure
            <small className="small-text">(Format : PDF)</small>
          </Accordion.Header>
          <Accordion.Body className="pt-0 ">
            <div className="mt-4">
              {brochureFileName !== "No file chosen" ? (
                <img src={pdfSvg} alt="Logo" className="img-fluid p-4" />
              ) : null}
              <Form.Group controlId="formBrochureFile" className="mb-1">
                {" "}
                <div className="file-container">
                  <label
                    htmlFor="brochureFileInput"
                    className="custom-file-input"
                  >
                    {" "}
                    Choose File
                  </label>
                  <input
                    type="file"
                    id="brochureFileInput"
                    style={{ display: "none" }}
                    onChange={handleBrochureFileChange}
                  />
                  <span className="file-name ps-1">
                    {renderText(brochureFileName, 15)}
                  </span>
                </div>
                <small className="mt-2">Size: 10MB</small>
              </Form.Group>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default AddBikeRight;
