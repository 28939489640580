import DownloadIcon from "../assets/icons/bootstrap-icons/button-icons/download white.svg";
import { ReactSVG } from "react-svg";
import { getToken } from "../utility/constants";
import { notifyError, notifySuccess } from "../utility/common";
import UploadIcon from "../assets/icons/bootstrap-icons/upload.svg";

export default function DownloadCSVExport({
  api,
  filename = "export-data.csv",
}) {
  const token = getToken();

  const handleCSVDownload = async () => {
    try {
      const response = await api();
      if (response.data.url) {
        window.open(response.data.url, "_blank");
        notifySuccess("File downloaded successfully");
      }
    } catch (error) {
      notifyError(error.response?.data?.message || "Error downloading file");
    }
  };

  return (
    <button
      className="export-button d-flex align-items-center text-nowrap justify-content-between me-2 mb-2 text-white px-3"
      onClick={handleCSVDownload}
    >
      <ReactSVG height="18px" width="18px" src={UploadIcon} className="mr-2" />{" "}
      Export
    </button>
  );
}
