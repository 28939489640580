import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Breadcrumbs from "../../../Breadcrumbs";
import Image from "react-bootstrap/Image";
import BackIcon from "../../../assets/icons/Back_Arrow.png";
import {
  getToken,
  metaRobotsFollow,
  metaRobotsIndex,
} from "../../../utility/constants";
import { ToastContainer } from "react-toastify";
import { notifyError, notifySuccess } from "../../../utility/common";
import APIHandler from "./apiHandler";
import { useTranslation } from "react-i18next";
import { serviceCentreValidation } from "./schemaValidation";
import BikeServiceCentreForm from "./BikeServiceCentreForm";

export default function AddBikeServiceCentre() {
  const { t } = useTranslation();
  const token = getToken();
  const navigate = useNavigate();
  const target = useRef(null);
  const [cities, setCities] = useState([]);
  const [brands, setBrands] = useState([]);

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    values,
    errors,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: {
      city_id: -1,
      brand_id: -1,
      state_id: -1,
      service_centre_name: "",
      service_centre_url: "",
      service_centre_address: "",
      phone_number: "",
      email_id: "",
      service_centre_content: "",
      meta_title: "",
      meta_description: "",
      meta_keyword: "",
      canonical_url: "",
      breadcrumbs: "",
      robots_follow: -1,
      robots_index: -1,
    },
    validationSchema: serviceCentreValidation,
    onSubmit: async (values, action) => {
      delete values.state_id;
      APIHandler.addBikeServiceCentre(token, values)
        .then((result) => {
          console.log("add service centre result", result);
          // notifySuccess("Bike Service Centre added successfully");
          action.resetForm();
          navigate(
            `/bike-service-centre/edit-bike-service-centre/${result.data.result.id}?added=true`
          );
        })
        .catch((error) => {
          notifyError(error.message);
        });
    },
  });

  return (
    <div className="row dashboard">
      <ToastContainer />
      <div className="row">
        <div className="col-xl-11 col-sm-11">
          <Breadcrumbs />
        </div>
        <div className="col-xl-1 col-sm-1">
          <button
            className="d-flex back-button py-2 px-3 mb-2 btn btn-primary"
            onClick={() => navigate("/bike-service-centre")}
          >
            <Image
              src={BackIcon}
              width="16px"
              height="16px"
              className="mt-1 me-2"
            />{" "}
            Back
          </button>
        </div>
      </div>

      <Card>
        <Card.Body>
          <Card.Text>
            <div className="row dashboard">
              <div className="table-heading fw-bold">
                Add Bike Service Centre
              </div>
              <BikeServiceCentreForm
                type={"Add"}
                values={values}
                errors={errors}
                touched={touched}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
              />
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
