import { Card } from "react-bootstrap";
import HeadingTwo from "../../components/HeadingTwo";
import Datatable from "../../components/Table";
import { useEffect, useState } from "react";
import apiHandler from "./apiHandler";
import { ReactSVG } from "react-svg";
import PlusLargeIcon from "../../assets/icons/bootstrap-icons/plus-lg.svg";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs";
import { notifyError, notifySuccess } from "../../utility/common";
import DeleteConfirmationModalComponent from "../../components/DeleteConfirmationModalComponent";
import { getToken, renderDate } from "../../utility/constants";
import EditViewDelete from "../../components/EditViewDeleteReviewPassword";

export default function NewsCampaignListing() {
  const [totalRecords, setTotalRecords] = useState(0);
  const [reloadTable, setReloadTable] = useState(false);
  const [newsToDelete, setNewsToDelete] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();

  const token = getToken();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onView = (id) => navigate(`/view-campaign/${id}`);
  const onDeleteNews = async () => {
    try {
      await apiHandler.deleteNewsCampaign(newsToDelete, token);
      setShowDeleteModal(false);
      setReloadTable(true);
      notifySuccess("News  Campaign deleted successfully");
    } catch (error) {
      setShowDeleteModal(false);
      notifyError(error.message);
    }
  };
  const column = [
    {
      name: "No .",
      selector: (row) => row.campaign_id,
      sortable: true,
      width: "80px",
    },
    {
      name: "name",
      selector: (row) => row.name,
      sortable: false,
      width: "360px",
    },
    {
      name: "Template Name",
      selector: (row) => row.template_name,
      sortable: false,
    },

    {
      name: "Status",
      selector: (row) =>
        row.is_active ? (
          <div className="red-box">Inactive</div>
        ) : (
          <div className="green-box">Active</div>
        ),
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row) => renderDate(row.created_date),
    },
    {
      name: "Action",
      sortable: false,
      width: "auto",

      cell: (row) => (
        <EditViewDelete
          id={row.campaign_id}
          onView={() => onView(row.campaign_id)}
          onDelete={() => {
            setNewsToDelete(row.campaign_id);

            setShowDeleteModal(true);
          }}
        />
      ),
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between mb-2">
        <Breadcrumbs />
        <button
          className="add-service-centre-button d-flex align-items-center justify-content-between mb-2 px-3"
          onClick={() => navigate("/add-news-campaign")}
        >
          <ReactSVG
            src={PlusLargeIcon}
            height="18px"
            width="18px"
            className="mr-2"
          />{" "}
          Add News Campaign
        </button>
      </div>
      <Card className="p-3">
        <HeadingTwo
          title="Campaign List"
          type="dashboard"
          showHeader={true}
          pointerOnHover={true}
        />
        <Datatable
          api={apiHandler.getNewsCampaignList}
          columns={column}
          setTotalRecords={setTotalRecords}
          reload={reloadTable}
          setReload={setReloadTable}
        />
        <DeleteConfirmationModalComponent
          show={showDeleteModal}
          type="delete"
          handleClose={() => setShowDeleteModal(false)}
          setDeleteConfirm={onDeleteNews}
        />
      </Card>
    </div>
  );
}
