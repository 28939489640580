import { Card } from "react-bootstrap";
import Breadcrumbs from "../../Breadcrumbs";
import Layout from "../../components/Layout";
import HeadingTwo from "../../components/HeadingTwo";
import Datatable from "../../components/Table";
import { useEffect, useState } from "react";
import { getToken, renderDate } from "../../utility/constants";
import { notifyError, notifySuccess } from "../../utility/common";
import apiHandler from "./apiHandler";
import DeleteConfirmationModalComponent from "../../components/DeleteConfirmationModalComponent";
import EditViewDelete from "../../components/EditViewDeleteReviewPassword";

export default function EnquiryAdmin({ type }) {
  const [reloadTable, setReloadTable] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState({ type: type });
  // const [filter, setFilter] = useState({ type: "car" });

  const token = getToken();
  useEffect(() => {
    window.scrollTo(0, 0);
    setFilter({ type: type });
  }, [type]);

  const columns = [
    {
      name: "Brand",
      selector: (row) => row.brand_name,
      sortable: true,
      // width: "300px",
    },
    {
      name: "Model",
      selector: (row) => row.model_name,
      sortable: true,
      width: "200px",
    },
    {
      name: "Varient",
      selector: (row) => row.version_name,
      sortable: true,
      // width: "300px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "200px",
    },
    {
      name: "Mobile Number",
      selector: (row) => row.phone,
      sortable: true,
      width: "150px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "300px",
    },
    {
      name: "Selected City",
      selector: (row) => row.selected_city,
      sortable: true,
      // width: "300px",
    },
    {
      name: "Page City",
      selector: (row) => row.city_name,
      sortable: true,
      // width: "300px",
    },
    {
      name: "Date",
      selector: (row) => row.created_date && renderDate(row.created_date),
      sortable: true,
      // width: "300px",
    },
  ];

  return (
    <>
      <Layout>
        <div className="row dashboard" style={{ minHeight: "100%" }}>
          <div className="d-flex">
            <Breadcrumbs />
          </div>
          <div className="my-4" style={{ minHeight: "100%" }}>
            <Card className="p-3">
              <HeadingTwo title={`${type.toUpperCase()} Enquiry Admin`} />
              <div>
                {/* <div className="d-flex gap-3">
                  <button
                    onClick={() => setFilter({ type: "car" })}
                    className={`active feedback-button-grp${
                      filter.alert_type === "car" ? "-active" : ""
                    }`}
                  >
                    Car Enquiry{" "}
                  </button>
                  <button
                    onClick={() => setFilter({ type: "bike" })}
                    className={`feedback-button-grp${
                      filter.alert_type === "bike" ? "-active" : ""
                    }`}
                  >
                    Bike Enquiry
                  </button>
                </div> */}
                <Datatable
                  columns={columns}
                  api={apiHandler.getEnquiryList}
                  reload={reloadTable}
                  setReload={setReloadTable}
                  setTotalRecords={setTotalPages}
                  totalRecords={totalPages}
                  filter={filter}
                />
              </div>
            </Card>
          </div>
        </div>
      </Layout>
    </>
  );
}
