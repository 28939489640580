import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { addCarCity, getStates } from "../../../request";
import Card from "react-bootstrap/Card";
import Breadcrumbs from "../../../Breadcrumbs";
import Image from "react-bootstrap/Image";
import BackIcon from "../../../assets/icons/Back_Arrow.png";
import { getToken } from "../../../utility/constants";

import FormSelect from "../../../components/FormSelect";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import FormControl from "../../../components/FormControl";
import { notifyError, notifySuccess } from "../../../utility/common";

export default function AddCarCity() {
  const { t } = useTranslation();

  const token = getToken();
  const navigate = useNavigate();
  //eslint-disable-next-line
  const [cityDetails, setCityDetails] = useState({
    state_id: "",
    // state_name:"",
    city_name: "",
    city_url: "",
    latitude: "",
    longitude: "",
  });

  const [states, setStates] = useState([]);

  const initialValues = {
    state_id: -1,
    city_name: "",
    city_url: "",
    latitude: "",
    longitude: "",
  };

  const validationSchema = Yup.object({
    state_id: Yup.number()
      .min(1, t("State is required"))
      .required(t("State is required")),
    city_name: Yup.string().required(t("City Name is required")),
    city_url: Yup.string(),
    latitude: Yup.number().typeError(t("Longitude must be a number")),
    longitude: Yup.number().typeError(t("Longitude must be a number")),
  });

  const getStateList = useCallback(async () => {
    await getStates(token, { limit: 50 }).then((res) => {
      setStates(res.data.result);
    });
  }, [token]);

  const handleClick = async () => {
    try {
      const res = await addCarCity(cityDetails, token);
      console.log("myres", res);
      // navigate("/car-city");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStateList();
  }, [getStateList]);

  return (
    <div className="row dashboard">
      <div className="row">
        <div className="col-xl-11 col-sm-11">
          <Breadcrumbs />
        </div>
        <div className="col-xl-1 col-sm-1">
          <button
            className="d-flex back-button py-2 px-3 mb-2 btn btn-primary"
            onClick={() => navigate("/car-city")}
          >
            <Image
              src={BackIcon}
              width="16px"
              height="16px"
              className="mt-1 me-2"
            />{" "}
            Back
          </button>
        </div>
      </div>

      <Card>
        <Card.Body>
          <Card.Text>
            <div className="row dashboard">
              <div className="table-heading fw-bold">
                Add Car Service Centre
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  const payload = values;

                  addCarCity(payload, token)
                    .then((result) => {
                      resetForm();
                      notifySuccess("Car City added successfully");
                      setTimeout(() => {
                        navigate(`/car-city`);
                      }, 1500);
                    })
                    .catch((error) => {
                      notifyError(error.message);
                    });
                }}
              >
                {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="row col-12 mt-1 p-3">
                      <div className="col-6 px-0 pe-3 my-3">
                        <div className="">
                          <FormSelect
                            controlId={"StateSelect"}
                            label={"Select State"}
                            name={"state_id"}
                            defaultName={"Select State"}
                            setFieldValue={setFieldValue}
                            items={states || []}
                            itemName={"state_name"}
                            touched={touched}
                            errors={errors}
                            value={values["state_id"]}
                          />
                        </div>
                      </div>

                      <div className="col-6 px-0 ps-3 my-3">
                        <div className="">
                          <FormControl
                            controlId={"CityNameInput"}
                            label={"City Name"}
                            placeholder={"City Name"}
                            touched={touched}
                            errors={errors}
                            name={"city_name"}
                            value={values["city_name"]}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                      </div>

                      <div className="col-6 px-0 pe-3 my-3">
                        <div className="">
                          <FormControl
                            controlId={"CityUrlInput"}
                            label={"Slug"}
                            placeholder={"Slug"}
                            touched={touched}
                            errors={errors}
                            name={"city_url"}
                            value={values["city_url"]}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                      </div>

                      <div className="col-6 px-0 ps-3 my-3">
                        <div className="">
                          <FormControl
                            controlId={"LatitudeInput"}
                            label={"Latitude"}
                            placeholder={"Latitude"}
                            touched={touched}
                            errors={errors}
                            name={"latitude"}
                            value={values["latitude"]}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                      </div>

                      <div className="col-6 px-0 pe-3 my-3">
                        <div className="">
                          <FormControl
                            controlId={"LongitudeInput"}
                            label={"Longitude"}
                            placeholder={"Longitude"}
                            touched={touched}
                            errors={errors}
                            name={"longitude"}
                            value={values["longitude"]}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                      </div>
                    </div>
                    <Button
                      type="submit"
                      variant="btnBlue"
                      className="d-flex align-items-center justify-content-between my-3"
                    >
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
