import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../utility/common";
import { useFormik } from "formik";
import popupAdSchema from "./adPageValidation";
import Breadcrumbs from "../../../Breadcrumbs";
import { Card } from "react-bootstrap";
import HeadingTwo from "../../../components/HeadingTwo";
import FormControl from "../../../components/FormControl";
import { ReactSVG } from "react-svg";
import BackIcon from "../../../assets/icons/bootstrap-icons/Back_Arrow.svg";
import { updatePopupAds } from "./adPageAPI";
import { getToken } from "../../../utility/constants";

export default function PopupAdPageForm({ initialValues }) {
  const navigate = useNavigate();
  const token = getToken();
  const { handleSubmit, errors, values, touched, setFieldValue } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: popupAdSchema,
    onSubmit: async (values, action) => {
      try {
        const payload = { ...values };
        const response = await updatePopupAds(token, payload);
        console.log("response", response);
        if (response.status === 200) {
          notifySuccess("Popup ads updated successfully");
        }
      } catch (error) {
        console.log(error);
        notifyError(error.response.data.message);
      }
    }
  });

  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex gap-4 justify-content-between">
        <Card className="px-3" style={{ width: "80%" }}>
          <div className="row d-flex gap-4">
            <FormControl
              controlId="desktop_header_script"
              label="Desktop header script"
              placeholder="Header script for Desktop top popup ad"
              touched={touched}
              errors={errors}
              type="text"
              rows={3}
              as={"textarea"}
              name="desktop_header_script"
              value={values.desktop_header_script}
              setFieldValue={setFieldValue}
            />
            <FormControl
              controlId="desktop_top"
              label="Desktop top popup ad"
              placeholder="Body script for Desktop top popup ad"
              touched={touched}
              errors={errors}
              type="text"
              rows={3}
              as={"textarea"}
              name="desktop_top"
              value={values.desktop_top}
              setFieldValue={setFieldValue}
            />
            <FormControl
              controlId="desktop_bottom"
              label="Desktop bottom popup ad"
              placeholder="Body script for Desktop bottom popup ad"
              touched={touched}
              errors={errors}
              type="text"
              rows={3}
              as={"textarea"}
              name="desktop_bottom"
              value={values.desktop_bottom}
              setFieldValue={setFieldValue}
            />
            <FormControl
              controlId="mobile_header_script"
              label="Mobile header script"
              placeholder="Header script for Mobile top popup ad"
              touched={touched}
              errors={errors}
              type="text"
              rows={3}
              as={"textarea"}
              name="mobile_header_script"
              value={values.mobile_header_script}
              setFieldValue={setFieldValue}
            />

            <FormControl
              controlId="mobile_top"
              label="Mobile top popup ad"
              placeholder="Body script for Mobile top popup ad"
              touched={touched}
              errors={errors}
              type="text"
              rows={3}
              as={"textarea"}
              name="mobile_top"
              value={values.mobile_top}
              setFieldValue={setFieldValue}
            />

            <FormControl
              controlId="mobile_bottom"
              label="Mobile bottom popup ad"
              placeholder="Body script for Mobile bottom popup ad"
              touched={touched}
              errors={errors}
              type="text"
              rows={3}
              as={"textarea"}
              name="mobile_bottom"
              value={values.mobile_bottom}
              setFieldValue={setFieldValue}
            />
          </div>
          <div className="py-3">
            <button type="submit" className="green-filled-button">
              Save
            </button>
          </div>
        </Card>
      </div>
    </form>
  );
}
