import { useEffect, useState } from "react";
import CategoryAdsSelect from "./categoryAdsSelect";
import { getAdByCategorySlug, getAllAdCategory } from "./adCategoryApi";
import { getToken } from "../../../utility/constants";
import CategoryAdsForm from "./CategoryAdsForm";

export default function CategoryAdsPage() {
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategorySlug, setSelectedCategorySlug] = useState("home");
  const [selectedCategoryAdData, setSelectedCategoryAdData] = useState([]);
  const [selectedCategoryHeaderData, setSelectedCategoryHeaderData] = useState({});
  const token = getToken();
  useEffect(() => {
    getAllAdCategory(token).then((res) => {
      setCategoryList(res?.data?.data);
    });
  }, []);

  useEffect(() => {
    getAdByCategorySlug(token, selectedCategorySlug).then((res) => {
      setSelectedCategoryAdData(res?.data?.ads);
      setSelectedCategoryHeaderData(res?.data?.header);
      console.log("Res", res);
    });
  }, [selectedCategorySlug]);

  console.log("selectedCategoryAdData", selectedCategoryAdData);
  return (
    <div>
      <CategoryAdsSelect
        categoryList={categoryList}
        setSelectedCategorySlug={setSelectedCategorySlug}
        selectedCategorySlug={selectedCategorySlug}
      />
      <CategoryAdsForm
        initialHeaderData={selectedCategoryHeaderData}
        initialAdData={selectedCategoryAdData}
        template_slug={selectedCategorySlug}
        setSelectedCategoryAdData={setSelectedCategoryAdData}
        setSelectedCategoryHeaderData={setSelectedCategoryHeaderData}
        
      />
    </div>
  );
}
