import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../../assets/icons/bootstrap-icons/Back_Arrow.svg";
import { ReactSVG } from "react-svg";
import Breadcrumbs from "../../../Breadcrumbs";
import { Card, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { getToken } from "../../../utility/constants";
import { addCityPrice, getAllBrands, getAllCities } from "../../../request";
import FormControl from "../../../components/FormControl";
import { ToastContainer, toast } from "react-toastify";
import apiHandler from "./apiHandler";
import { notifyError, notifySuccess } from "../../../utility/common";

export default function AddCarCityPrice() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [brands, setBrands] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [model, setModels] = useState([]);
  const [versions, setVersions] = useState([]);

  const token = getToken();

  useEffect(() => {
    getAllBrands()
      .then((res) => {
        setBrands(res.data.result);
      })
      .catch((error) => {
        notifyError(error.message);
      });

    getAllCities(token)
      .then((res) => {
        setCityList(res.data.result);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  }, [token]);
  //eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    city_id: "",
    version_id: "",
    brand_id: "",
    brand_url: "",
    model_id: "",
    ex_showroom_price: undefined,
    rto: undefined,
    version_name: "",
    insurance: undefined,
    registration: undefined,
    handling_logistic: undefined,
    tcs: undefined,
    mcd: undefined,
    hypothecation: undefined,
    ecc: undefined,
    incidental: undefined,
    number_plate: undefined,
    fast_tag: undefined,
    road_safety_tax: undefined
  });

  const validationSchema = Yup.object({
    city_id: Yup.number()
      .min(1, t("City is required"))
      .required(t("City is required")),
    brand_id: Yup.number()
      .min(1, t("Brand is required"))
      .required(t("Brand is required")),
    brand_url: Yup.string().required(t("Brand url is required")),
    version_id: Yup.number()
      .min(1, t("Version is required"))
      .required(t("Version is required")),
    model_id: Yup.number()
      .min(1, t("Model is required"))
      .required(t("Model is required")),
    ex_showroom_price: Yup.number()
      .typeError(t("Showroom Price must be a number"))
      .required(t("Showroom Price is required")),
    rto: Yup.number()
      .typeError(t("RTO must be a number"))
      .required(t("RTO is required")),
    insurance: Yup.number()
      .typeError(t("Insurance must be a number"))
      .required(t("Insurance is required"))
  });

  const getModels = (brand_id) => {
    if (!brand_id) {
      setModels([]);
      return;
    }
    const payload = {
      brand_id,
      type: "all"
    };
    apiHandler
      .getCarModelByBrandId(token, payload)
      .then((response) => {
        setModels(response.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getVersions = (model_id) => {
    if (!model_id) {
      setModels([]);
      return;
    }
    const payload = {
      model_id
    };
    apiHandler
      .getCarVersionByModelId(token, payload)
      .then((response) => {
        setVersions(response.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //hello
  return (
    <div className="row dashboard">
      <div className="row">
        {console.log("Filtered Models", model)}
        <div className="col-xl-11 col-sm-11">
          <Breadcrumbs />
        </div>

        <div className="col-xl-1 col-sm-1">
          <button
            className="d-flex back-button py-2 px-3 mb-4 btn btn-primary"
            onClick={() => navigate("/car-city-price")}
          >
            <ReactSVG src={BackIcon} className="me-1" />
            Back
          </button>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-xl-12 col-sm-12">
          <Card className="pb-2 px-4">
            <Card.Body>
              <Card.Text>
                <div className="row">
                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <div className="d-flex align-items-center">
                        <div className="table-heading">City Price Form</div>
                        <div className="py-0 ms-5"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  enableReinitialize={false}
                  onSubmit={(values) => {
                    const payload = {
                      brand_url: values.brand_url,
                      city_id: values.city_id,
                      version_id: values.version_id,
                      ex_showroom_price: values.ex_showroom_price,
                      rto: values.rto,
                      insurance: values.insurance,
                      registration: values.registration
                    };

                    if (values.registration) {
                      payload.registration = values.registration;
                    }
                    if (values.handling_logistic) {
                      payload.handling_logistic = values.handling_logistic;
                    }
                    if (values.tcs) {
                      payload.tcs = values.tcs;
                    }
                    if (values.mcd) {
                      payload.mcd = values.mcd;
                    }
                    if (values.hypothecation) {
                      payload.hypothecation = values.hypothecation;
                    }
                    if (values.ecc) {
                      payload.ecc = values.ecc;
                    }
                    if (values.incidental) {
                      payload.incidental = values.incidental;
                    }
                    if (values.number_plate) {
                      payload.number_plate = values.number_plate;
                    }
                    if (values.fast_tag) {
                      payload.fast_tag = values.fast_tag;
                    }
                    if (values.road_safety_tax) {
                      payload.road_safety_tax = values.road_safety_tax;
                    }

                    addCityPrice(payload, token)
                      .then((resp) => {
                        notifySuccess("Car city price added successfully.");
                        setTimeout(() => {
                          navigate("/car-city-price");
                        }, 0);
                      })
                      .catch((error) => {
                        notifyError(error.message);
                        console.log(error);
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-4"
                            controlId="brandNameInput"
                          >
                            <Form.Label className="mb-2">
                              Select City<sup className="text-danger">*</sup>
                            </Form.Label>
                            <Form.Select
                              controlId={"CityControlSelect"}
                              label={"Select City"}
                              touched={touched}
                              errors={errors["city_id"]}
                              defaultChecked={""}
                              defaultValue={""}
                              name="city_id"
                              value={values.city_id}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.city_id && errors.city_id}
                            >
                              <option selected disabled value="">
                                Select City
                              </option>
                              {cityList.map((city, i) => {
                                return (
                                  <option value={city.id} key={i}>
                                    {city.city_name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {touched["city_id"] && errors["city_id"]}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-4"
                            controlId="brandNameInput"
                          >
                            <Form.Label className="mb-2">
                              Select Brand <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Form.Select
                              controlId={"CityControlSelect"}
                              touched={touched}
                              errors={errors["brand_id"]}
                              name="brand_id"
                              value={values.brand_id}
                              onChange={(e) => {
                                const brand_id = e.target.value;

                                const selectedBrand = brands.find(
                                  (brand) => brand.id == parseInt(brand_id)
                                );
                                console.log(selectedBrand);
                                const brand_url = selectedBrand
                                  ? selectedBrand.brand_url
                                  : "";
                                setFieldValue("brand_id", brand_id);
                                setFieldValue("brand_url", brand_url);
                                setFieldValue("model_id", "");
                                setFieldValue("version_id", "");
                                getModels(brand_id);
                              }}
                              onBlur={handleBlur}
                              isInvalid={touched.brand_id && errors.brand_id}
                            >
                              <option selected disabled value="">
                                Select Brand
                              </option>
                              {brands.map((brand, i) => {
                                return (
                                  <option value={brand.id} key={i}>
                                    {brand.brand_name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {touched["brand_id"] && errors["brand_id"]}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-4"
                            controlId="brandNameInput"
                          >
                            <Form.Label className="mb-2">
                              Select Model <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Form.Select
                              controlId={"CityControlSelect"}
                              touched={touched}
                              errors={errors["model_id"]}
                              name="model_id"
                              value={values.model_id}
                              onChange={(e) => {
                                const model_id = e.target.value;
                                setFieldValue("model_id", model_id);
                                getVersions(model_id);
                              }}
                              onBlur={handleBlur}
                              isInvalid={touched.model_id && errors.model_id}
                            >
                              <option selected disabled value={""}>
                                Select Model
                              </option>
                              {model.map((mod, i) => {
                                return (
                                  <option value={mod.id} key={i}>
                                    {mod.model_name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {touched["model_id"] && errors["model_id"]}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-md-6">
                          <Form.Group
                            className="mb-4"
                            controlId="brandNameInput"
                          >
                            <Form.Label className="mb-2">
                              Select Version
                              <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Form.Select
                              controlId={"CityControlSelect"}
                              touched={touched}
                              errors={errors["version_id"]}
                              name="version_id"
                              value={values.version_id}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.version_id && errors.version_id
                              }
                            >
                              <option selected disabled value={""}>
                                Select Version
                              </option>
                              {versions.map((version, i) => {
                                return (
                                  <option value={version.id} key={i}>
                                    {version.version_name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {touched["version_id"] && errors["version_id"]}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group className="mb-4" controlId="priceInput">
                            <FormControl
                              controlId={"showroomPriceInput"}
                              label={
                                <span>
                                  Ex Showroom Price
                                  <sup className="text-danger">*</sup>
                                </span>
                              }
                              touched={touched}
                              errors={errors}
                              name={"ex_showroom_price"}
                              value={values["ex_showroom_price"]}
                              setFieldValue={setFieldValue}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group className="mb-4" controlId="rtoInput">
                            <FormControl
                              controlId={"rtoInput"}
                              label={
                                <span>
                                  RTO
                                  <sup className="text-danger">*</sup>
                                </span>
                              }
                              touched={touched}
                              errors={errors}
                              name={"rto"}
                              value={values["rto"]}
                              setFieldValue={setFieldValue}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-4"
                            controlId="insurenceInput"
                          >
                            {console.log("er", errors, values)}
                            <FormControl
                              controlId={"insuranceInput"}
                              label={
                                <span>
                                  Insurance
                                  <sup className="text-danger">*</sup>
                                </span>
                              }
                              touched={touched}
                              errors={errors}
                              name={"insurance"}
                              value={values["insurance"]}
                              setFieldValue={setFieldValue}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group className="mb-4" controlId="chargesInput">
                            <FormControl
                              controlId={"registrationInput"}
                              label={"Registration Charges"}
                              name={"registration"}
                              touched={touched}
                              errors={errors}
                              value={values["registration"]}
                              setFieldValue={setFieldValue}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-4"
                            controlId="logisticChargeInput"
                          >
                            <FormControl
                              controlId={"logisticsInput"}
                              label={"Handling/Logistic Charges"}
                              name={"handling_logistic"}
                              touched={touched}
                              errors={errors}
                              value={values["handling_logistic"]}
                              setFieldValue={setFieldValue}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group className="mb-4" controlId="taxInput">
                            <FormControl
                              controlId={"tcsInput"}
                              label={"Tax Collected at Source (TCS)"}
                              name={"tcs"}
                              touched={touched}
                              errors={errors}
                              value={values["tcs"]}
                              setFieldValue={setFieldValue}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group className="mb-4" controlId="mcdInput">
                            <FormControl
                              controlId={"mcdInput"}
                              label={"MCD"}
                              name={"mcd"}
                              touched={touched}
                              errors={errors}
                              value={values["mcd"]}
                              setFieldValue={setFieldValue}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-4"
                            controlId="hypothecationInput"
                          >
                            <FormControl
                              controlId={"hypothecationInput"}
                              label={"Hypothecation Charges"}
                              name={"hypothecation"}
                              touched={touched}
                              errors={errors}
                              value={values["hypothecation"]}
                              setFieldValue={setFieldValue}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-4"
                            controlId="compChargeInput"
                          >
                            <FormControl
                              controlId={"compensationInput"}
                              label={"Environment Compensation Charge (ECC)"}
                              name={"ecc"}
                              touched={touched}
                              errors={errors}
                              value={values["ecc"]}
                              setFieldValue={setFieldValue}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-4"
                            controlId="incidentalChargesInput"
                          >
                            <FormControl
                              controlId={"incidentialChargesInput"}
                              label={"Incidental Charges"}
                              name={"incidental"}
                              touched={touched}
                              errors={errors}
                              value={values["incidental"]}
                              setFieldValue={setFieldValue}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-4"
                            controlId="numberPlateInput"
                          >
                            <FormControl
                              controlId={"plateInput"}
                              label={"Number Plate"}
                              name={"number_plate"}
                              touched={touched}
                              errors={errors}
                              value={values["number_plate"]}
                              setFieldValue={setFieldValue}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group className="mb-4" controlId="fastTagInput">
                            <FormControl
                              controlId={"compensationInput"}
                              label={"Fast Tag"}
                              name={"fast_tag"}
                              touched={touched}
                              errors={errors}
                              value={values["fast_tag"]}
                              setFieldValue={setFieldValue}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-4"
                            controlId="safetyTaxInput"
                          >
                            <FormControl
                              controlId={"safetyTaxInput"}
                              label={"Road Safety Tax"}
                              name={"road_safety_tax"}
                              touched={touched}
                              errors={errors}
                              value={values["road_safety_tax"]}
                              setFieldValue={setFieldValue}
                            />
                          </Form.Group>
                        </div>
                        {/* <div className="col-md-6">
                          <Form.Group
                            className="mb-4"
                            controlId="onRoadPriceInput"
                          >
                            <Form.Label className="mb-2">
                              On Road Price
                            </Form.Label>
                            <Form.Control type="text" name="onRoadPrice" />
                          </Form.Group>
                        </div> */}
                      </div>
                      <Button
                        type="submit"
                        variant="btnBlue"
                        className="d-flex align-items-center justify-content-between my-3"
                        // onClick={handleClick}
                      >
                        Add Price
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}
