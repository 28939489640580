import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeadingTwo from "../../components/HeadingTwo";
import Breadcrumbs from "../../Breadcrumbs";
import { Accordion, Card } from "react-bootstrap";
import { useFormik } from "formik";
import FormControl from "../../components/FormControl";
import FormSelect from "../../components/FormSelect";

import { getToken } from "../../utility/constants";
import { ToastContainer, toast } from "react-toastify";
import FAQValidation from "./FAQValidation";
import {
  getBikeBrandNameList,
  getBikeModelById,
  getModelsByBrandId,
} from "../../request";
import { getAllBikeModelsByBrandId } from "../NewBike/BikeVersion/apiHandler";
import { getBikeModelByBrandId } from "../NewBike/BikeCityPrice/apiHandler";
const BikeQuestionForm = ({ initialValues, onSubmit }) => {
  const navigate = useNavigate();
  const [brandList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState("");
  const token = getToken();
  const status = [
    { id: 1, name: "Approved" },
    { id: 2, name: "Not Approved" },
    { id: 3, name: "Pending Review" },
  ];
  useEffect(() => {
    const getBrandList = async () => {
      try {
        const payload = {
          sortBy: "",
          sortOrder: "",
        };
        const response = await getBikeBrandNameList(payload, token);
        console.log(response);
        setBrandList(response.data.result);
      } catch (error) {
        console.log(error);
      }
    };

    getBrandList();
  }, [token]);

  const getModelList = useCallback(async () => {
    try {
      if (selectedBrandId < 0) {
        return;
      }

      const response = await getBikeModelByBrandId(token, {
        brand_id: selectedBrandId,
      });
      console.log(response);
      setModelList(response.data.result);
    } catch (error) {
      console.log(error);
    }
  }, [selectedBrandId, token]);
  useEffect(() => {
    if (selectedBrandId) {
      getModelList();
    }
  }, [selectedBrandId, getModelList]);

  const { handleSubmit, errors, values, touched, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: FAQValidation.FAQQuestionSchema,

    onSubmit: async (values, action) => {
      try {
        const payload = {
          ...values,
        };
        const response = await onSubmit(payload);
        if (response.status === 200) {
          toast.success("Bike Question added successfully");
          setTimeout(() => {
            navigate("/bike-questions");
          }, 2000);
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  useEffect(() => {
    setSelectedBrandId(values.brand_id);
  }, [values.brand_id]);

  return (
    <>
      <div>
        <Breadcrumbs />

        <form onSubmit={handleSubmit}>
          <div className="d-flex gap-4 justify-content-between">
            <Card className="p-3" style={{ width: "80%" }}>
              <HeadingTwo
                title="Add Bike Question"
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />
              <div className="row d-flex gap-4">
                <FormControl
                  controlId="questionNameInput"
                  label="Question Name"
                  placeholder="Question Name"
                  touched={touched}
                  errors={errors}
                  name="question_name"
                  value={values.question_name}
                  setFieldValue={setFieldValue}
                  defaultName={"Enter Question Name"}
                  showDefault={true}
                />
                <FormSelect
                  controlId={"questionStatusInput"}
                  label={"Question Status"}
                  placeholder={"Question Status"}
                  touched={touched}
                  errors={errors}
                  items={status}
                  itemName={"name"}
                  showDefault={true}
                  defaultName={"Select Status"}
                  name={"question_status"}
                  value={values.question_status}
                  setFieldValue={setFieldValue}
                />
                <FormSelect
                  controlId={"brandInput"}
                  label={"Brand"}
                  placeholder={"Brand"}
                  touched={touched}
                  errors={errors}
                  itemName={"brand_name"}
                  items={brandList}
                  showDefault={true}
                  defaultName={"Select Brand"}
                  name={"brand_id"}
                  value={values.brand_id}
                  setFieldValue={setFieldValue}
                />
                <FormSelect
                  controlId={"modelInput"}
                  label={"model"}
                  placeholder={"model"}
                  touched={touched}
                  errors={errors}
                  itemName={"model_name"}
                  items={modelList}
                  showDefault={true}
                  defaultName={"Select Model"}
                  name={"model_id"}
                  value={values.model_id}
                  setFieldValue={setFieldValue}
                />
              </div>
            </Card>
            <div className="col-xl-3 col-sm-4 pe-2">
              <Accordion defaultActiveKey="0" className="mb-4">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="p-3 pb-1 ps-2 ">
                    Save
                  </Accordion.Header>
                  <Accordion.Body className="pt-1 d-flex">
                    <div className="w-100 d-flex justify-content-between">
                      <button
                        type="submit"
                        className="d-flex save-button align-items-center rounded mb-2"
                      >
                        Add
                      </button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default BikeQuestionForm;
