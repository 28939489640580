import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../request";

const addBikeFAQquestion = (token, payload) => {
  return axios.post(
    `${BASE_URL_AUTOX}bike-faq/question/add`,
    payload,
    getConfig(token)
  );
};

const getBikeQuestionList = (token, payload) => {
  return axios.post(
    `${BASE_URL_AUTOX}bike-faq/question/list`,
    payload,
    getConfig(token)
  );
};

const deleteBikeQuestionById = (token, id) => {
  return axios.delete(
    `${BASE_URL_AUTOX}bike-faq/question/delete/${id}`,
    getConfig(token)
  );
};

const getBikeQuestionValues = (token, id) => {
  return axios.get(
    `${BASE_URL_AUTOX}bike-faq/question/${id}`,
    getConfig(token)
  );
};

const updateBikeFAQquestion = (token, id, payload) => {
  return axios.put(
    `${BASE_URL_AUTOX}bike-faq/question/update/${id}`,
    payload,
    getConfig(token)
  );
};

const getAnswerList = (token, payload) => {
  return axios.post(
    `${BASE_URL_AUTOX}bike-faq/answer/list`,
    payload,
    getConfig(token)
  );
};

const addBikeAnswer = (token, payload) => {
  return axios.post(
    `${BASE_URL_AUTOX}bike-faq/answer/add`,
    payload,
    getConfig(token)
  );
};

const updateBikeAnswer = (token, id, payload) => {
  return axios.put(
    `${BASE_URL_AUTOX}bike-faq/answer/update/${id}`,
    payload,
    getConfig(token)
  );
};

const deleteBikeAnswerById = (token, id) => {
  return axios.delete(
    `${BASE_URL_AUTOX}bike-faq/answer/delete/${id}`,
    getConfig(token)
  );
};

const getBikeAnswerValues = (token, id) => {
  return axios.get(`${BASE_URL_AUTOX}bike-faq/answer/${id}`, getConfig(token));
};

const listUserNewsletterSubscriptions = (token, payload) => {
  return axios.post(
    `${BASE_URL_AUTOX}user-newsletter-subscription/list`,
    payload,
    getConfig(token)
  );
};

const addUserNewsletterSubscriptions = (token, payload) => {
  return axios.post(
    `${BASE_URL_AUTOX}user-newsletter-subscription/add`,
    payload,
    getConfig(token)
  );
};
const deleteSubscriberById = (token, id) => {
  return axios.delete(
    `${BASE_URL_AUTOX}user-newsletter-subscription/delete/${id}`,
    getConfig(token)
  );
};
const getUserNewsletterSubscription = (token, id) => {
  return axios.get(
    `${BASE_URL_AUTOX}user-newsletter-subscription/get/${id}`,
    getConfig(token)
  );
};

const updateSubscriber = (token, id, payload) => {
  return axios.put(
    `${BASE_URL_AUTOX}user-newsletter-subscription/update/${id}`,
    payload,
    getConfig(token)
  );
};
//eslint-disable-next-line
export default {
  listUserNewsletterSubscriptions,
  addUserNewsletterSubscriptions,
  deleteSubscriberById,
  getUserNewsletterSubscription,
  updateSubscriber,
};
