export default function DashboardWidgetOne({
  bgColor,
  count,
  message,
  picture,
  redirectLink,
  imageBgColor,
  growth,
}) {
  return (
    <div className="d-flex card card-rounded-dashboard widget-one">
      <div className="d-flex">
        <div className="">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              height: "60px",
              width: "60px",
              backgroundColor: imageBgColor,
              borderRadius: "5px",
            }}
          >
            <div className="zoom d-flex justify-content-center align-items-center">
              <img
                src={picture}
                className="dashboard-picture-logo"
                alt="dashboard-logo"
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center ms-3">
          <div className="" style={{ color: "#AAAAAA" }}>
            {message}
          </div>
          <div className="" style={{ color: "#2B2A2A", fontSize: "25px" }}>
            {count}
          </div>
          <div className="d-flex">
            <div
              className="mt-2"
              style={{
                fontSize: "10px",
                color: "#26BF94",
                fontWeight: "500",
                maxWidth: "70%",
              }}
            >
              {`${growth ? growth : "0"}%`} &nbsp; {"^"}
            </div>
            <div
              className="mt-2"
              style={{ fontSize: "10px", color: "#AAAAAA", fontWeight: "500" }}
            >
              &nbsp;{"This Month"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
