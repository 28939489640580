import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";

import {
  updateModel,
  getModelById,
  getCarBodyType,
  getBrandNameList,
  getFAQlist,
} from "../../../request";
import Breadcrumbs from "../../../Breadcrumbs";
import BackIcon from "../../../assets/icons/bootstrap-icons/Back_Arrow.svg";
import { getBrandList } from "../../../request";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccordianGroup from "./EditCarModels/AccordianGroup";
import AccordianRight from "./EditCarModels/AccordianRight";
import {
  getToken,
  renderDate,
  renderDateNumerical,
} from "../../../utility/constants";
import { Formik, isInteger, useFormik } from "formik";
import { Form } from "react-bootstrap";
import { notifyError } from "../../../utility/common";
export default function EditCarModel() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [value, setValue] = useState("");
  const [editorialValue, setEditorialValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showTooltip1, setShowTooltip1] = useState(false);
  const [showTooltipTwo, setShowTooltipTwo] = useState(false);
  const [fileName, setFileName] = useState("No file chosen");
  const [brochureFileName, setBrochureFileName] = useState("No file chosen");
  const [custom_faq_removed, setCustom_faq_removed] = useState([]);
  const [modelDetails, setModelDetails] = useState({
    model_name: "",
    model_url: "",
    car_type: 0,
    keep_discontinued: 0,
    body_type: 0,
    popular_position: 0,
    model_date: new Date().toISOString().slice(0, 19) + ".000Z",
    updated_launch_date: "",
    model_content: "",
    colors: "",
    model_preview: "",
    model_prosncons: "",
    starting_price: 0,
    ending_price: 0,
    gallery_short_code: "",
    brand_id: 0,
    meta_title: "",
    meta_description: "",
    meta_keyword: "",
    canonical_url: "",
    breadcrumbs: "",
    robots_follow: "",
    robots_index: "",
    model_editorial_review: "",
    electric_car: false,
    brand_name: "",
    model_image: "test",

    model_brochure: { fileName },
    multiple_vehicle: [],
    custom_faq: [
      {
        id: "",
        question: "",
        answer: "",
      },
    ],
    faq: [],
    custom_faq_removed: [],
  });
  const [brandDetails, setBrandDetails] = useState([]);
  const [bodyTypeDetails, setBodyTypeDetails] = useState([]);
  const [bodyType, setBodyType] = useState();
  const [checkboxState, setCheckboxState] = useState({});
  const [electricCarChecked, setElectricCarChecked] = useState(
    modelDetails.electric_car == 1 ? true : false
  );
  const [modelFAQ, setModelFAQ] = useState([]);
  const [checkedFAQ, setCheckedFAQ] = useState([]);
  const [customFAQs, setCustomFAQs] = useState([]);
  const [multiple_vehicle, setMultipleVehicle] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  const [selectedOption, setSelectedOption] = useState(null);
  const token = getToken();

  const handleUpdateFAQ = (index, field, value) => {
    const updatedFAQs = [...customFAQs];
    updatedFAQs[index][field] = value;
    setCustomFAQs(updatedFAQs);
  };
  console.log(customFAQs, modelFAQ, "something");
  const handleAddFAQInput = () => {
    setCustomFAQs([...customFAQs, { question: "", answer: "" }]);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getModelById(id, token);
        console.log(res);
        const response = res.data.data;
        setBodyType(response.modelData.body_type);
        console.log(response.modelData, "hi");
        setModelDetails(response.modelData);
        // setValue(response.modelData.model_content);

        setModelDetails({
          ...response.modelData,
          faq: [],
          custom_faq: [{ question: "", answer: "" }],
          multiple_vehicle: response.multipleVehicleData.map(
            (obj) => obj.vehicle_type_id
          ),
        });
        // setEditorialValue(response.modelData.expert_review);
        setCheckedFAQ(response.faqData);
        setCustomFAQs(response.customFaqData);
        setMultipleVehicle(response.multipleVehicleData);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchBrands = async () => {
      try {
        const fetchBrandsParams = {
          page: 1,
          limit: 10,
          sortBy: "brand_ordering",
          sortOrder: "asc",
          search: "",
        };
        const res = await getBrandNameList(token, fetchBrandsParams);
        const response = res.data.result;
        setBrandDetails(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCarBodyType = async () => {
      try {
        const CarBodyTypeParams = {
          sortBy: "",
          sortOrder: "",
        };
        const res = await getCarBodyType(token, CarBodyTypeParams);
        const response = res.data.result;

        setBodyTypeDetails(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchModelFAQ = async () => {
      try {
        const payload = {
          page: 1,
          limit: "",
          sortBy: "",
          sortOrder: "",
          search: "",
          searchFields: "",
          offset: "",
        };
        const modelFaq = await getFAQlist(token, payload);
        setModelFAQ(modelFaq.data.result);
      } catch (error) {
        setError(error);
      }
    };
    fetchModelFAQ();
    fetchData();
    fetchBrands();
    fetchCarBodyType();
    const formattedDate = renderDateNumerical(modelDetails.updated_launch_date);
    setModelDetails({
      ...modelDetails,
      updated_launch_date: formattedDate,
    });
  }, [id]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size <= 50 * 1024) {
        setModelDetails({
          ...modelDetails,
          model_image: selectedFile,
        });
        setFileName(selectedFile ? selectedFile.name : "No file chosen");
      } else {
        setModelDetails({
          ...modelDetails,
          model_image: null,
        });
        toast.error("File size should be less than 50 KB");
      }
    }
  };
  const handleBrochureFileChange = (e) => {
    const selectedBrochure = e.target.files[0];
    const allowedTypes = ["application/pdf"];
    if (selectedBrochure && allowedTypes.includes(selectedBrochure.type)) {
      if (selectedBrochure.size <= 1024 * 10 * 1024) {
        alert("PDF file selected for brochure");
        setModelDetails({
          ...modelDetails,
          model_brochure: selectedBrochure,
        });
        setBrochureFileName(
          selectedBrochure ? selectedBrochure.name : "No file chosen"
        );
      } else {
        notifyError("File size should be less than 150 KB");
        setModelDetails({
          ...modelDetails,
          model_brochure: null,
        });
        setBrochureFileName("No file chosen");
      }
    } else {
      setModelDetails({
        ...modelDetails,
        model_brochure: null,
      });
      setBrochureFileName("No file chosen");
    }
  };

  const handleRadioClick = (value) => {
    setSelectedOption(value);
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    const { electricCarChecked } = event.target;
    setElectricCarChecked(electricCarChecked);
  };

  const handleCarBrandTypeRadioChange = (value, id) => {
    setModelDetails({
      ...modelDetails,
      brand_name: value,
      brand_id: id,
    });
  };
  const handleBodyTypeCheckboxChange = (checkboxId) => {
    setModelDetails({
      ...modelDetails,
      body_type: checkboxId,
    });
  };
  const onDescriptionChange = (value) => {
    setValue(value);
  };
  const onEditorialChange = (value) => {
    setEditorialValue(value);
  };
  const target = useRef(null);

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setModelDetails({
      ...modelDetails,
      [name]: value,
    });
  };
  console.log(customFAQs, "customfaq");
  console.log(checkedFAQ, "checkedFAQ");
  const preprocessedModelDetails = Object.fromEntries(
    Object.entries(modelDetails).map(([key, value]) => [
      key,
      value === null ? "" : value,
    ])
  );
  console.log(preprocessedModelDetails, "preprocessedModelDetails");
  const { handleSubmit, values, errors, setFieldValue, touched } = useFormik({
    initialValues: {
      ...preprocessedModelDetails,
      // multiple_vehicle: multiple_vehicle.map((obj) => obj.vehicle_type_id),

      faq: checkedFAQ.map((obj) => +obj.question_id),
    },
    enableReinitialize: true,
    onSubmit: async (values, action) => {
      try {
        const formData = new FormData();
        delete values.trending_position;
        const updateModelDetails = {
          ...values,
          electric_car: electricCarChecked ? 1 : 0,

          faq: JSON.stringify(values.faq),
          custom_faq: JSON.stringify(
            customFAQs.filter(
              (item) => item.question.trim() !== "" && item.answer.trim() !== ""
            )
          ),
          custom_faq_removed: JSON.stringify(custom_faq_removed),
          multiple_vehicle: JSON.stringify(values.multiple_vehicle),
          model_image: values.model_image ? values.model_image : null,
        };
        console.log("values......", values.model_image);
        // console.log(updateModelDetails, "updated");
        const keysToRemove = [
          "model_id",
          "id",
          "created_date",
          "updated_date",
          "min_price",
          "max_price",
          "json_agg",
          "brand_url",
        ];
        const updatedObject = Object.fromEntries(
          Object.entries(updateModelDetails).filter(
            ([key]) => !keysToRemove.includes(key)
          )
        );
        Object.entries(updatedObject).forEach(([key, value]) => {
          if (value instanceof File) {
            formData.append(key, value);
          } else {
            formData.append(key, value);
          }
        });

        const response = await updateModel(id, formData);
        if (response.status === 200) {
          toast.success("Model updated successfully");
          setTimeout(() => {
            // navigate("/car-models");
            // window.location.reload();
          }, 2000);
        } else {
          const responseData = response.response
            ? response.response.data
            : response.data;
          const errorMessage = responseData.message || "An error occurred";
          notifyError(errorMessage);
        }
      } catch (error) {
        toast.error(`${error.response.data.message}`);
      }
    },
  });
  console.log(values, "values");

  return (
    <div className="row dashboard">
      <div className="row">
        <div className="col-xl-11 col-sm-11">
          <Breadcrumbs removeId={true} />
        </div>

        <div className="col-xl-1 col-sm-1">
          <button
            className="d-flex back-button py-2 px-3 mb-4 btn btn-primary"
            onClick={() => navigate("/car-models")}
          >
            <ReactSVG src={BackIcon} className="me-1" />
            Back
          </button>
        </div>
      </div>
      {console.log(values, "value")}
      <Form onSubmit={handleSubmit} className="row">
        <AccordianGroup
          handleOnChange={handleOnChange}
          onDescriptionChange={onDescriptionChange}
          value={value}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          values={values}
          handleSelectChange={handleSelectChange}
          selectedValue={selectedValue}
          showTooltip1={showTooltip1}
          setShowTooltip1={setShowTooltip1}
          showTooltipTwo={showTooltipTwo}
          setShowTooltipTwo={setShowTooltipTwo}
          target={target}
          onEditorialChange={onEditorialChange}
          editorialValue={editorialValue}
          modelFAQ={modelFAQ}
          customFAQs={customFAQs}
          setCustomFAQs={setCustomFAQs}
          handleUpdateFAQ={handleUpdateFAQ}
          handleAddFAQInput={handleAddFAQInput}
          checkedFAQ={checkedFAQ}
          custom_faq_removed={custom_faq_removed}
          setCustom_faq_removed={setCustom_faq_removed}
        />
        <AccordianRight
          bodyType={bodyType}
          values={values}
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
          selectedOption={selectedOption}
          brandDetails={brandDetails}
          bodyTypeDetails={bodyTypeDetails}
          electricCarChecked={electricCarChecked}
          handleCheckboxChange={handleCheckboxChange}
          handleRadioClick={handleRadioClick}
          handleFileChange={handleFileChange}
          handleBrochureFileChange={handleBrochureFileChange}
          brochureFileName={brochureFileName}
          fileName={fileName}
          multipleVehicleData={multiple_vehicle}
          setMultipleVehicleData={setMultipleVehicle}
          handleCarBrandTypeRadioChange={handleCarBrandTypeRadioChange}
          handleBodyTypeRadioChange={handleBodyTypeCheckboxChange}
          setModelDetails={setModelDetails}
        />
      </Form>

      <ToastContainer />
    </div>
  );
}
