import React from "react";
import { ReactSVG } from "react-svg";

function CarModelBtn({ label, icon, onClick, className }) {
  return (
    <button className={className} onClick={onClick}>
      <ReactSVG src={icon} height="18px" width="18px" className="mr-2" />{" "}
      {label}
    </button>
  );
}

export default CarModelBtn;
