import axios from "axios";
import {
  BASE_URL_AUTOX,
  GET_BIKE_CITY_PRICE_LIST,
  GET_BIKE_MODEL_LIST_BY_BRAND,
  GET_BIKE_VERSION_LIST_BY_MODEL,
  getConfig,
} from "../../../request";

/**
 * Get List of Car city price
 * getCarCityPriceList is used to get List of Car city price
 * @param token
 * @param payload
 */
export function getBikeCityPriceList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_BIKE_CITY_PRICE_LIST}`,
    payload,
    getConfig(token)
  );
}

export function getBikeModelByBrandId(token, payload) {
  console.log(payload, "shalom");
  return axios.post(
    `${BASE_URL_AUTOX}${GET_BIKE_MODEL_LIST_BY_BRAND}`,
    payload,
    getConfig(token)
  );
}

export function getBikeVersionByModelId(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_BIKE_VERSION_LIST_BY_MODEL}`,
    payload,
    getConfig(token)
  );
}

export function downloadSampleCityPrice() {
  return axios.post(
    `${BASE_URL_AUTOX}new-bike/city-price/downloadCityPriceSample`,
    {}
  );
}

export function deleteBulkBikeCityPrice(token, payload) {
  console.log(payload);
  return axios.delete(
    `${BASE_URL_AUTOX}new-bike/city-price/delete-citybulk`,
    { data: payload },
    getConfig(token)
  );
}
export async function downloadBikePrice(token, payload) {
  return await axios.post(
    `${BASE_URL_AUTOX}new-bike/city-price/export-city-price`,
    payload,
    getConfig(token)
  );
}
//eslint-disable-next-line import/no-anonymous-default-export
export default {
  getBikeCityPriceList,
  getBikeModelByBrandId,
  getBikeVersionByModelId,
  downloadSampleCityPrice,
  deleteBulkBikeCityPrice,
};
