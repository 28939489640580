
import React from "react";
import {
    useLocation,
    useNavigate
} from "react-router-dom";
import Layout from "../../../components/Layout";


export default function CarServiceCentreMain({ children }) {
    const location = useLocation();
    const navigate = useNavigate();


    return (
        <Layout>
            <div className="row dashboard">
                <div>
                    {children}
                </div>
            </div>
        </Layout>
    )
}