import axios from "axios";
import {
  ADD_BIKE_BODY_TYPE,
  BASE_URL_AUTOX,
  DELETE_BIKE_BODY_TYPE,
  GET_BIKE_BODY_TYPES,
  GET_BIKE_BODY_TYPE_BY_ID,
  UPDATE_BIKE_BODY_TYPE,
  getConfig,
} from "../../../request";

/**
 * Get List of Bike body type
 * getBikeBodyType is used to get List of Bike body type
 * @param token
 * @param payload
 */
export function getBikeBodyType(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_BIKE_BODY_TYPES}`,
    payload,
    getConfig(token)
  );
}

export function addBikeBodyType(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${ADD_BIKE_BODY_TYPE}`,
    payload,
    getConfig(token)
  );
}
export function updateBikeBodyType(token, payload, id) {
  return axios.put(
    `${BASE_URL_AUTOX}${UPDATE_BIKE_BODY_TYPE}${id}`,
    payload,
    getConfig(token)
  );
}

export function getBikeBodyTypeById(token, id) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_BIKE_BODY_TYPE_BY_ID}${id}`,
    getConfig(token)
  );
}

export function deleteBikeBodyTypeById(token, id) {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_BIKE_BODY_TYPE}${id}`,
    getConfig(token)
  );
}

export default {
  getBikeBodyType,
  addBikeBodyType,
  getBikeBodyTypeById,
  updateBikeBodyType,
  deleteBikeBodyTypeById,
};
