import { useNavigate } from "react-router-dom";
import { getToken } from "../../utility/constants";
import { notifyError, notifySuccess } from "../../utility/common";
import apiHandler from "./apiHandler";
import SubscriptionForm from "./SubscriptionForm";
import { useEffect, useState } from "react";

export default function AddSubscription() {
  const navigate = useNavigate();
  const token = getToken();
  const [sus_id, setSus_id] = useState("");
  const initialValues = {
    title: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    telephone: "",
    designation: "",
    subscriber_id: sus_id,
    company: "",
    state: "",
    city: "",
    address: "",
    pincode: "",
    ip_address: "",
    status: 0,
    payment_status: "Paid",
    start_date: "",
    end_date: "",
    plan: -1,
    source: -1,
    remarks: "",
    amount: 0,
    mode_of_payment: -1,
    txn_id: "",
    bank: "",
  };
  useEffect(() => {
    const fetchCurrentId = async () => {
      if (token) {
        try {
          const value = await apiHandler.getCurrentId(token);
          console.log(value.data.data.id);
          setSus_id(value.data.data.id);
        } catch (error) {
          console.error("Failed to fetch current ID:", error);
        }
      }
    };

    fetchCurrentId();
  }, [token]);

  const AddSubscription = async (data) => {
    try {
      delete data.subscriber_id;

      const response = await apiHandler.addSubscription(data, token);
      console.log(response);
      if (response.status === 200) {
        notifySuccess(response.data.message);
        setTimeout(() => {
          navigate("/subscription");
        }, 1500);
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };
  return (
    <>
      <SubscriptionForm
        initialValues={initialValues}
        onSubmit={AddSubscription}
        title="Add Subscription"
      />
    </>
  );
}
