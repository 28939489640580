import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layout";
import Breadcrumbs from "../../../Breadcrumbs";
import AddPopularBike from "./AddPopularScooter";
import { getToken } from "../../../utility/constants";
import APIHandler from "./apiHandler";
import { notifyError, notifySuccess } from "../../../utility/common";
import PopularBikesList from "./PopularScooterList";

export default function PopularBikes({ children }) {
  const token = getToken();
  const [popularBike, setPopularBike] = useState([]);

  useEffect(() => {
    getPopularBikeList();
  }, []);

  //Get list of Popular Car
  const getPopularBikeList = () => {
    APIHandler.listPopularScooter(token)
      .then((result) => {
        setPopularBike(result.data.result);
      })
      .catch((error) => {
        notifyError(error.response.data.message);
      });
  };

  //Handle Delete Event
  const handleDelete = (id) => {
    APIHandler.deletePopularBike(token, id)
      .then((result) => {
        getPopularBikeList();
        notifySuccess("Removed Successfully !!");
      })
      .catch((error) => {
        notifyError(
          error.response
            ? error.response.data.message
            : "There is problem in error"
        );
      });
  };

  //Update Sort
  const updateSort = (popularItems) => {
    APIHandler.sortPopularBike(token, { popularItems })
      .then((result) => {
        // console.log(result.data.result);
        setPopularBike(result.data.result);
      })
      .catch((error) => {
        notifyError(
          error.response
            ? error.response.data.message
            : "There is problem in error"
        );
      });
  };

  return (
    <Layout>
      <div className="row dashboard">
        <div className="d-flex justify-content-between align-items-center multipleCityPriceMain mb-3 flex-wrap">
          <Breadcrumbs />
        </div>
        <div className="row">
          <div className="col-xxl-8 col-md-7">
            <PopularBikesList
              updateSort={updateSort}
              bikeData={popularBike}
              handleDelete={handleDelete}
            />
          </div>
          <div className="col-xxl-4 col-md-5">
            <AddPopularBike onSuccess={getPopularBikeList} />
          </div>
        </div>
      </div>
    </Layout>
  );
}
