import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../request";

export function getNewsCampaignList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}news-campaign/list`,
    payload,
    getConfig(token)
  );
}

export function addNewNewsCampaign(values, token) {
  return axios.post(
    `${BASE_URL_AUTOX}news-campaign/create-campaign`,
    values,
    getConfig(token)
  );
}

export function getNewsCampaignDetails(id, token) {
  return axios.get(
    `${BASE_URL_AUTOX}news-campaign/news-campaign-details/${id}`,
    getConfig(token)
  );
}

export function deleteNewsCampaign(id, token) {
  return axios.delete(
    `${BASE_URL_AUTOX}news-campaign/delete/${id}`,
    getConfig(token)
  );
}

export function getTemplateList(token) {
  return axios.get(
    `${BASE_URL_AUTOX}news-campaign/template-list`,
    getConfig(token)
  );
}

export function getNewsletterList(token) {
  return axios.get(
    `${BASE_URL_AUTOX}news-campaign/news-letter-type`,
    getConfig(token)
  );
}

export function getTemplateBodyById(id, token) {
  return axios.get(
    `${BASE_URL_AUTOX}news-campaign/template-body/${id}`,
    getConfig(token)
  );
}
export function getNewsCampaignById(id, token) {
  return axios.get(
    `${BASE_URL_AUTOX}news-campaign/campaign/${id}`,
    getConfig(token)
  );
}

export default {
  getNewsCampaignList,
  addNewNewsCampaign,
  getNewsCampaignDetails,
  deleteNewsCampaign,
  getTemplateList,
  getNewsletterList,
  getTemplateBodyById,
  getNewsCampaignById,
};
