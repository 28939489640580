import axios from "axios";
import { getConfig, BASE_URL_AUTOX } from "../../../request";
export const GET_MARUTI_SUZUKI_CAR_DRIVING_SCHOOL = "car/maruti-suzuki-driving-school/filtered/list";
export const GET_MARUTI_SUZUKI_CAR_DRIVING_SCHOOL_BY_ID = "car/maruti-suzuki-driving-school/";
export const ADD_MARUTI_SUZUKI_CAR_DRIVING_SCHOOL = "car/maruti-suzuki-driving-school/add";
export const UPDATE_MARUTI_SUZUKI_CAR_DRIVING_SCHOOL = "car/maruti-suzuki-driving-school/update/";
export const DELETE_MARUTI_SUZUKI_CAR_DRIVING_SCHOOL = "car/maruti-suzuki-driving-school/delete/";
export const GET_ALL_CAR_CITIES = "car/city/all/list";

/**
 * Get List of Maruti Car Driving School
 * getMarutiSuzukiCarDrivingSchools is used to get all the data of maruti car driving schools
 * @param token
 * @param payload
 */
export function getMarutiSuzukiCarDrivingSchools(token, payload) {
    return axios.post(`${BASE_URL_AUTOX}${GET_MARUTI_SUZUKI_CAR_DRIVING_SCHOOL}`, payload,
        getConfig(token)
    )
}

/**
 * Get Maruti Car Driving School By Id
 * getMarutiSuzukiCarDrivingSchoolById is used to get data of maruti car driving school by id
 * @param token
 * @param id
 */
export function getMarutiSuzukiCarDrivingSchoolById(token, id) {
    return axios.get(`${BASE_URL_AUTOX}${GET_MARUTI_SUZUKI_CAR_DRIVING_SCHOOL_BY_ID}${id}`,
        getConfig(token)
    )
}

/**
 * Add New Maruti Car Driving School
 * addMarutiSuzukiDrivingSchool is used to create new maruti car driving school data
 * @param token
 * @param payload
 */
export function addMarutiSuzukiDrivingSchool(token, payload) {
    return axios.post(`${BASE_URL_AUTOX}${ADD_MARUTI_SUZUKI_CAR_DRIVING_SCHOOL}`, payload, getConfig(token))
}


/**
 * Update Maruti Car Driving School By Id
 * updateMarutiSuzukiDrivingSchoolById is used to update data of maruti car driving school by id
 * @param token
 * @param id
 * @param payload
 */
export function updateMarutiSuzukiDrivingSchoolById(token, id, payload) {
    return axios.put(`${BASE_URL_AUTOX}${UPDATE_MARUTI_SUZUKI_CAR_DRIVING_SCHOOL}${id}`, payload, getConfig(token))
}

/**
 * Delete Maruti Car Driving School By Id
 * deleteMarutiSuzukiDrivingSchoolById is used to delete single record of maruti car driving school by id
 * @param token
 * @param id
 */
export function deleteMarutiSuzukiDrivingSchoolById(token, id) {
    return axios.delete(`${BASE_URL_AUTOX}${DELETE_MARUTI_SUZUKI_CAR_DRIVING_SCHOOL}${id}`, getConfig(token))
}

/**
 * Get List of Cities
 * getAllCarCities is used to get subset data of all cities
 * @param token
 * @param payload
 */
export function getAllCarCities(token, payload) {
    return axios.post(`${BASE_URL_AUTOX}${GET_ALL_CAR_CITIES}`, payload,
        getConfig(token)
    )
}

export default {
    addMarutiSuzukiDrivingSchool,
    getMarutiSuzukiCarDrivingSchoolById,
    getMarutiSuzukiCarDrivingSchools,
    updateMarutiSuzukiDrivingSchoolById,
    deleteMarutiSuzukiDrivingSchoolById,
    getAllCarCities
}