import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeadingTwo from "../../../components/HeadingTwo";
import BackArrow from "../../../assets/icons/bootstrap-icons/arrow-left.svg";
import HondaLogo from "../../../assets/icons/bootstrap-icons/honda-logo-2000-full-640.webp";
import PlusLogo from "../../../assets/icons/bootstrap-icons/plus.svg";
import { ReactSVG } from "react-svg";
import Breadcrumbs from "../../../Breadcrumbs";
import Image from "react-bootstrap/Image";
import { Card, Form } from "react-bootstrap";
import BackIcon from "../../../assets/icons/Back_Arrow.png";
import apiHandler from "./apiHandler";
import { getToken } from "../../../utility/constants";
import { carBodyTypeValidation } from "./schemaValidation";
import { useFormik } from "formik";
import { notifyError, notifySuccess } from "../../../utility/common";

export default function EditBikeVehicleType() {
  const navigate = useNavigate();
  const { id } = useParams();
  const token = getToken();
  const [carBodyType, setBikeBodyType] = useState({});

  useEffect(() => {
    apiHandler.getBikeBodyTypeById(token, id).then((res) => {
      console.log("ERsss", res.data.data);
      setBikeBodyType(res.data.data);
    });
  }, []);

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    values,
    errors,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: {
      type_name: carBodyType.type_name || "",
      type_url: carBodyType.type_url || "",
    },
    validationSchema: carBodyTypeValidation,
    enableReinitialize: true,
    onSubmit: async (values, action) => {
      const payload = values;
      payload.body_type_status = carBodyType.body_type_status;

      apiHandler
        .updateBikeBodyType(token, payload, id)
        .then((result) => {
          notifySuccess("Bike body type updated successfully");
          navigate(`/car-vehicle-type`);
        })
        .catch((error) => {
          notifyError(error.message);
        });
    },
  });

  return (
    <div className="row dashboard">
      <div className="row">
        <div className="col-xl-11 col-sm-11">
          <Breadcrumbs />
        </div>
        {/* <div className="col-xl-4 col-sm-4">

        </div> */}
        <div className="col-xl-1 col-sm-1">
          <button
            className="d-flex back-button py-2 px-3 mb-2 btn btn-primary"
            onClick={() => navigate("/car-vehicle-type")}
          >
            <Image
              src={BackIcon}
              width="16px"
              height="16px"
              className="mt-1 me-2"
            />{" "}
            Back
          </button>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-xl-12 col-sm-12">
          <Card className="pb-2 px-2">
            <Card.Body>
              <Card.Text>
                <div className="row">
                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <div className="d-flex align-items-center">
                        <div className="table-heading">Edit Bike Body Type</div>
                        <div className="py-0 ms-5"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <Form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="mb-4" controlId="bodyTypeInput">
                        <Form.Label className="mb-2">
                          Bike Body Type Name
                          <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Brand Name"
                          name="type_name"
                          touched={touched}
                          errors={errors["type_name"]}
                          value={values.type_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.type_name && errors.type_name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {touched["type_name"] && errors["type_name"]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-4" controlId="slugInput">
                        <Form.Label className="mb-2">
                          Slug<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="type_url"
                          placeholder="Slug"
                          touched={touched}
                          errors={errors["type_url"]}
                          value={values.type_url}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.type_url && errors.type_url}
                        />
                        <Form.Control.Feedback type="invalid">
                          {touched["type_url"] && errors["type_url"]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                  <button
                    className="export-button d-flex align-items-center justify-content-between me-2 mb-2 text-white px-3"
                    type="submit"
                  >
                    Submit
                  </button>
                </Form>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}
