import { useNavigate } from "react-router-dom";
import EditViewDelete from "../../../components/EditViewDeleteReviewPassword";
import { useState } from "react";
import DeleteConfirmationModalComponent from "../../../components/DeleteConfirmationModalComponent";
import apiHandler from "./apiHandler";
import { getToken } from "../../../utility/constants";
import { toast } from "react-toastify";

export const BikeFAQColumn = (reloadTable, currentPage, itemsPerPage) => {
  const navigate = useNavigate();
  const token = getToken();
  const [modal, setModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const onViewInner = (id) => {};
  const onEditInner = (id) => {
    navigate(`/edit-bike-faq/${id}`);
  };

  const onDeleteInner = (id) => {
    if (deleting) return;
    setModal(true);
    setDeleteId(id);
  };

  const deleteBikeFAQ = async (id) => {
    if (deleting) return;
    try {
      setDeleting(true);
      const res = await apiHandler.deleteBikeFAQ(token, id);
      if (res) {
        toast.success("Bike FAQ deleted successfully");
        setModal(false);
        reloadTable();
      } else {
        throw new Error("Failed to delete bike FAQ");
      }
    } catch (error) {
      console.error("Error deleting bike FAQ:", error);
      toast.error("Failed to delete bike FAQ");
    } finally {
      // Reset deleting state after deletion operation
      setDeleting(false);
      setModal(false);
    }
  };

  const onReviewInner = (id) => {};
  return [
    {
      name: "Sr No.",
      sortable: true,
      selector: (_, index) =>
        index + 1 + currentPage * itemsPerPage - itemsPerPage,
      width: "80px",
    },
    {
      name: "Question",
      sortable: true,
      selector: (row) => row.question,
      width: "250px",
      paddingBottom: "20px",
      paddingTop: "20px",
      wrap: true,
    },
    {
      name: "Answer",
      sortable: true,
      // selector: (row) => row.answer,
      width: "400px",
      wrap: true,
      cell: (row) => <div className="text-justify-answer">{row.answer}</div>,
    },
    {
      name: "Ordering",
      sortable: true,
      selector: (row) => row.faq_ordering,
      width: "100px",
    },
    {
      name: "Is Default",
      sortable: true,
      selector: (row) => (row.is_default === 1 ? "True" : "False"),
      width: "100px",
    },
    {
      name: "Condition",
      sortable: true,
      selector: (row) => row.faq_condition,
      width: "150px",
    },
    {
      name: "Action",
      sortable: false,
      width: "100px",
      cell: (row) => (
        <>
          <EditViewDelete
            id={row.id}
            onEdit={onEditInner}
            onDelete={onDeleteInner}
          />
          <DeleteConfirmationModalComponent
            heading="Delete FAQ"
            message="Are you sure you want to delete this FAQ?"
            show={modal && deleteId === row.id}
            handleClose={() => setModal(false)}
            setDeleteConfirm={() => deleteBikeFAQ(row.id)}
            type="delete"
          />
        </>
      ),
    },
  ];
};
