import { Card } from "react-bootstrap";
import HeadingTwo from "../../components/HeadingTwo";
import FormControl from "../../components/FormControl";

export default function NewsLetterForm({
  values,
  touched,
  errors,
  setFieldValue,
  handleSubmit,
  type,
}) {
  return (
    <>
      <div>
        <Card className="p-3">
          <HeadingTwo title={"Contact List"} />
          <div>
            <form>
              <div className="p-3 w-50">
                <FormControl
                  controlId={"name"}
                  label={"Contact List Name"}
                  type={"text"}
                  name={"name"}
                  value={values.name}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  errors={errors}
                />
              </div>
              <div className="p-3 ">
                <FormControl
                  controlId={"description"}
                  label={"Description (Optional)"}
                  type={"text"}
                  name={"description"}
                  value={values.description}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  rows={5}
                  as={"textarea"}
                />
              </div>

              <div className="p-3">
                <div className="">
                  <FormControl
                    controlId={"templateBodyInput"}
                    label={
                      type === "UPDATE"
                        ? "Update subscribers"
                        : "Add Subscribers"
                    }
                    type={"text"}
                    placeholder={"Enter comma-separated emails"}
                    name={"emails"}
                    value={values.emails}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    as={"textarea"}
                    rows={3}
                  />
                </div>
              </div>
              <div className="p-3 w-50">
                <label className="form-label">Subscribable</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={values.is_subscribable}
                  onChange={(e) =>
                    setFieldValue("is_subscribable", e.target.value)
                  }
                  name="is_subscribable"
                >
                  <option selected disabled>
                    Select Status
                  </option>
                  <option value="0">False</option>
                  <option value="1">True</option>
                </select>
              </div>
              <div className="p-3 w-50">
                <label className="form-label">Status</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={values.status}
                  onChange={(e) => setFieldValue("status", e.target.value)}
                  name="status"
                >
                  <option selected disabled>
                    Select Status
                  </option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
              <div className="p-3">
                <button
                  type="button"
                  className="d-flex save-button align-items-center rounded mb-2 "
                  onClick={handleSubmit}
                >
                  {" "}
                  {type}
                </button>
              </div>
            </form>
          </div>
        </Card>
      </div>
    </>
  );
}
