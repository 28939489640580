import Layout from "../../components/Layout";

export default function StatePriceMain({ children }) {
    return (
        <Layout>
            <div className="row dashboard">
                <div className="my-4">{children}</div>
            </div>
        </Layout>
    );
}