export const passwordRegex =
  /^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])(?=\S*[!@#$%^&*? ])\S*$/;
export const postalCodeRegex = /^[A-Za-z0-9]+$/;
export const nameRegex = /^[a-zA-Z '.-]*$/;
export const numberRegex = /^\d+$/;
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const phoneRegex = /^\d{10}$/;
export const multiple_city_price_cities = [2, 9];
export const multiple_bike_city_price_cities = [65];

export const setToken = (token, token_expiration_time) => {
  localStorage.setItem("token", JSON.stringify(token));
  localStorage.setItem("token_expiration_time", token_expiration_time);
};
export const setRole = (role) => {
  // If role is a string like "1,3,40,76,27", convert it to an array of numbers
  const roleArray = Array.isArray(role) ? role : role.split(",").map(Number);

  console.log("roleArray", roleArray); // Log the array of roles
  localStorage.setItem("user_role", JSON.stringify(roleArray)); // Store as JSON string
};

export const adminType = (type, role) => {
  let admin = "";
  const roleArray = Array.isArray(role) ? role : role.split(",").map(Number);
  if (type == 2) {
    admin = "super admin";
  } else if (type == 1) {
    // Check if the role array includes 40
    if (roleArray.includes(40)) {
      admin = "editor admin";
    } else {
      admin = "admin";
    }
  }

  localStorage.setItem("admin_type", admin);
};

export const getAdminType = () => {
  if (typeof window !== "undefined") {
    if (localStorage.getItem("admin_type") === undefined) {
      return null;
    } else {
      return localStorage.getItem("admin_type");
    }
  }
};

export const setUserName = (user_name) => {
  localStorage.setItem("userName", user_name);
};

export const setUserImage = (image) => {
  localStorage.setItem("userImage", image);
};
export const getToken = () => {
  if (typeof window !== "undefined") {
    if (localStorage.getItem("token") === undefined) {
      return null;
    } else {
      return JSON.parse(localStorage.getItem("token"));
    }
  }
};

export const getUserRole = () => {
  if (typeof window !== "undefined") {
    if (localStorage.getItem("user_role") === undefined) {
      return null;
    } else {
      return JSON.parse(localStorage.getItem("user_role"));
    }
  }
};

export const setUserId = (user_id) => {
  localStorage.setItem("user_id", JSON.stringify(user_id));
};

export const getUserId = () => {
  if (typeof window !== "undefined") {
    if (localStorage.getItem("user_id") === undefined) {
      return null;
    } else {
      return JSON.parse(localStorage.getItem("user_id"));
    }
  }
};

export const setResetPasswordToken = (token) => {
  localStorage.setItem("reset_password_token", JSON.stringify(token));
};

export const getResetPasswordToken = () => {
  if (typeof window !== "undefined") {
    if (localStorage.getItem("reset_password_token") === undefined) {
      return null;
    } else {
      return JSON.parse(localStorage.getItem("reset_password_token"));
    }
  }
};

export const renderDate = (inputDate) => {
  const date = new Date(inputDate);
  const options = { day: "numeric", month: "short", year: "numeric" };
  return date.toLocaleDateString("en-GB", options);
};

export const renderText = (input, limit) => {
  if (input.length > limit) {
    return input.substring(0, limit) + "...";
  } else {
    return input;
  }
};

export const renderDateTime = (inputDate) => {
  const date = new Date(inputDate);
  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  };
  return date.toLocaleDateString("en-US", options);
};
export function convertTimestampToDate(timestamp) {
  const datePart = timestamp.substring(0, 10);
  return datePart;
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  return `${day}/${month}/${year}`;
}

export const renderDateNumerical = (inputDate) => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
// export const durationList = [
//   { label: "Yearly", value: "365" },
//   { label: "Monthly", value: "30" },
//   { label: "Weekly", value: "7" },
// ];

// export const sortOptions = [
//   { label: "Name", value: "365" },
//   { label: "Date", value: "30" },
// ];

// export const filterOptions = [
//   { label: "Yearly", value: "365" },
//   { label: "Monthly", value: "30" },
//   { label: "Weekly", value: "7" },
//   { label: "Daily", value: "1" },
//   { label: "Custom", value: "custom" },
// ];

// export const limit = [
//   { label: "1", value: "1" },
//   { label: "2", value: "2" },
//   { label: "3", value: "3" },
//   { label: "4", value: "4" },
//   { label: "5", value: "5" },
// ];

// export const sendNotificationsFieldNames = {
//   TITLE: "title",
//   DESCRIPTION: "description",
//   USERNAME: "userName",
//   EMAIL: "email",
// };
// export const profileFieldNames = {
//   NAME: "name",
//   EMAIL: "email",
//   LANGUAGE: "language",
//   PROFILEPICTURE: "profilePicture",
// };

// export const endUserFieldNames = {
//   NAME: "name",
//   DATEOFBIRTH: "dateOfBirth",
//   EMAIL: "email",
//   PHONE: "phone",
//   COUNTRY: "country",
//   GENDER: "gendet",
//   HEIGHT: "height",
//   WEIGHT: "weight",
//   EDUCATION: "education",
//   ETHINICITY: "ethinicity",
//   RELATIONSHIPSTATUS: "relatioshipStatus",
//   INTEREESTEDIN: "interestedIn",
//   OCCUPATION: "occupation",
//   SEXUALORIENTATION: "sexualOrientation",
//   LOCATION: "location",
//   USERID: "userId"
// };
// export const faqFieldNames = {
//   TITLE: "title",
//   DESCRIPTION: "description",
// };

// export const membershipFieldNames = {
//   NAME: "name",
//   PRICE: "email",
//   DURATION: "language",
//   METHOD: "profilePicture",
//   SENDTEXT: "sendText",
//   PROFILEBOOSTER: "profileBooster",
//   SENDFIL: "sendFil",
//   SEEFAVOURITE: "seeFavourite",
//   SEEPROFILEVIEW: "seeProfileView",
//   PROFILEBOOSTERDURATION: "profileBoosterDuration",
//   SENDFILDURATION: "SENDFILDuration",
// };

// export const passwordFormFields = {
//   CONFIRMPASSWORD: "confirmPassword",
//   CURRENTPASSWORD: "currentpassword",
//   PASSWORD: "password",
//   NEWPASSWORD: "newPassword",
// };

// export const fieldNames = {
//   FIRSTNAME: "first_name",
//   LASTNAME: "last_name",
//   EMAIL: "email",
//   PHONE: "phone_no",
//   PASSWORD: "password",
//   CONFIRMPASSWORD: "confirmPassword",
//   CURRENTPASSWORD: "currentpassword",
//   COMPANYNAME: "company_name",
//   STREETNAME: "street_name",
//   HOUSENUMBER: "house_number",
//   CITY: "city",
//   STATE: "state",
//   POSTALCODE: "postal_code",
//   TAXNUMBER: "taxNumber",
//   CONTACTFIRSTNAME: "contactFirstName",
//   CONTACTLASTNAME: "contactLastName",
//   CONTACTPHONENUMBER: "contactPhoneNumber",
//   CONTACTPICTURE: "contactPicture",
//   CONTACTEMAIL: "contactEmail",
//   BUSINESSLOCATION: "businessLocation",
//   TERRACECAPACITY: "terraceCapacity",
//   LIQUORLICENSE: "liquorLicense",
//   BUSINESSTYPE: "businessType",
//   USERNAME: "userName",
//   JOBCATEGORY: "jobCategory",
//   JOBSUBCATEGORY: "jobSubCategory",
//   NUMBEROFSTUDENTS: "numberOfStudents",
//   REQUIREDCERTIFICATION: "requiredCertification",
//   JOBDESCRIPTION: "jobDescription",
//   JOBADDITIONALINFO: "additionJobInfo",
//   JOBSTARTDATE: "jobStartDate",
//   JOBSTARTHOUR: "jobStartHour",
//   JOBENDHOUR: "jobStartHour",
//   JOBBREAK: "jobBreak",
//   MULTIPLEDAYS: "multipleDays",
//   MINIMALAGE: "minimalAge",
//   PAYROLLERNAME: "payrollerName",
//   QUESTION: "name",
//   ANSWER: "description",
//   CERTIFICATIONNAME: "name",
//   CERTIFICATIONCATEGORY: "category",
//   CERTIFICATIONDESCRIPTION: "description",
//   CERTIFICATIONLINK: "website_link",
//   QUESTIONLINK: "website_link",
//   NOOFHISTORICALHOURS: "noOfHistoricalHours",
//   NOOFFUTUREJOBS: "noOfFutureJobs",
//   CANCELLATIONCOUNTER: "cancellationCounter",
// };

// export const interestedInList = [
//   {
//     label: "Male",
//     value: "Male",
//   },
//   {
//     label: "Female",
//     value: "Female",
//   },
//   {
//     label: "Other",
//     value: "Other",
//   },
// ];

// export const genderList = [
//   {
//     label: "Male",
//     value: "M",
//   },
//   {
//     label: "Female",
//     value: "F",
//   },
//   {
//     label: "Others",
//     value: "Others",
//   },
// ];

// export const occupationList = [
//   {
//     label: "Teacher",
//     value: "Teacher",
//   },
//   {
//     label: "Doctor",
//     value: "Doctor",
//   },
//   {
//     label: "Engineer",
//     value: "Engineer",
//   },
// ];

// export const educationtionList = [
//   {
//     label: "BE",
//     value: "BE",
//   },
//   {
//     label: "CA",
//     value: "CA",
//   },
//   {
//     label: "BA",
//     value: "BA",
//   },
// ];

// export const relationshipStatusList = [
//   {
//     label: "Single",
//     value: "Single",
//   },
//   {
//     label: "Married",
//     value: "Married",
//   },
//   {
//     label: "Divorcee",
//     value: "Divorcee",
//   },
// ];

// export const dummyDropDown = [
//   {
//     label: "test",
//     value: "test",
//   },

// ];

export const versionCarType = [
  { id: 0, value: "Live" },
  { id: 1, value: "Not Live" },
  { id: 2, value: "Discontinued" }
  // { id: 1, value: "Live" },
];
export function generateMonths(startYear) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Adding 1 to make it 1-indexed

  const months = [];
  for (let year = startYear; year <= currentYear; year++) {
    const endMonth = year === currentYear ? currentMonth : 12; // Stop at current month if it's the current year
    for (let month = 1; month <= endMonth; month++) {
      const monthString = String(month).padStart(2, "0");
      months.push({
        id: `${monthString}-${year}`,
        value: `${getMonthName(month)} ${year}`
      });
    }
  }
  return months;
}

function getMonthName(month) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  return monthNames[month - 1];
}

export const carType = [
  { id: 0, value: "Live" },
  { id: 1, value: "Upcoming" },
  { id: 2, value: "Discontinued" },
  { id: 3, value: "Discontinued With Year" }
];

export const commentsType = [
  { id: 0, value: "Pending" },
  { id: 1, value: "Approved" },
  { id: 2, value: "Rejected" }
];

export const metaRobotsIndex = [
  { id: "index", value: "Index" },
  { id: "noindex", value: "No Index" }
];

export const metaRobotsFollow = [
  { id: "Follow", value: "Follow" },
  { id: "nofollow", value: "No Follow" }
];

export const brandStatus = [
  { id: 0, value: "Live" },
  { id: 1, value: "Discontinued" }
];
export const vehicleSupports = [
  { id: 0, value: "Bike/Scooter" },
  { id: 1, value: "Bike Only" },
  { id: 2, value: "Scooter Only" }
];
export const carVersionShowOnLaunch = [
  { id: 0, value: "No" },
  { id: 1, value: "Yes" }
];

export const correctImagePath = (url, type) => {
  const prefix = "/var/www/mediafiles/";
  if (url.startsWith(prefix)) {
    return `${process.env.REACT_APP_IMAGE_BASE_URL}${url.substring(
      prefix.length
    )}`;
  } else if (!url.startsWith(prefix) && !url.startsWith("https://")) {
    if (type == "car") {
      return `${process.env.REACT_APP_IMAGE_BASE_URL}uploads/cars/b/${url}`;
    }
    if (type == "bike") {
      return `${process.env.REACT_APP_IMAGE_BASE_URL}uploads/bikes/b/${url}`;
    }
  }
  return url;
};
