import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../request";

export const getAdminDetails = (token) => {
  return axios.get(`${BASE_URL_AUTOX}admin/userDetails`, getConfig(token));
};

export const updateAdminDetails = (token, payload) => {
  return axios.put(
    `${BASE_URL_AUTOX}admin/updateDetails`,
    payload,
    getConfig(token, { "Content-Type": "multipart/form-data" })
  );
};
