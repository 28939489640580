import React, { useState, useEffect, useMemo, useCallback } from "react";
import DataTable from "react-data-table-component";
import Loader from "./Loader";
import { getToken } from "../utility/constants";
import CustomPagination from "./CustomPagination";
import $ from "jquery";
import { notifyError } from "../utility/common";

function DatatableLoader() {
  return (
    <div style={{ padding: "100px" }}>
      <Loader size="100px" color="danger" />
    </div>
  );
}

export default function Datatable({
  api,
  car_body_type_id,
  columns,
  defaultSortColumn,
  filterText,
  itemsPerPage = 25,
  setReload,
  setTotalRecords,
  updateOrderingHandler,
  isDraggable,
  showHeader,
  header,
  reload,
  customStyles,
  search,
  filter,
  column_ordering,
  sortByDir = "desc",
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortByColumn, setSortByColumn] = useState(defaultSortColumn);
  const [isInitialize, setIsInitialize] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [dropId, setDropId] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const token = getToken();
  const [diff, setDiff] = useState([]);

  const [hasReordered, setHasReordered] = useState(false); // New flag

  const fetchApiData = useCallback(
    async (
      page,
      size = perPage,
      sortBy = sortByColumn,
      sortOrder = sortByDir,
      searchKw = search
    ) => {
      if (token === null) return;
      setLoading(true);
      try {
        const response = await api(
          token,
          { search: searchKw, limit: size, sortBy, sortOrder, page, ...filter },
          car_body_type_id || null
        );
        console.log(response);
        setData(response.data.result);
        setTotalRows(response.data.totalRecord);
        setIsInitialize(true);
      } catch (error) {
        notifyError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [
      api,
      car_body_type_id,
      filter,
      perPage,
      sortByColumn,
      sortByDir,
      token,
      search,
    ]
  );

  useEffect(() => {
    setTotalRecords(totalRows);
    setTotalPages(Math.ceil(totalRows / perPage));
  }, [totalRows, perPage, setTotalRecords]);

  useEffect(() => {
    fetchApiData(1).catch(console.error);
  }, [fetchApiData, sortByColumn, sortByDir]);

  useEffect(() => {
    setCurrentPage(1);
    fetchApiData(1, perPage, sortByColumn, sortByDir, filterText).catch(
      console.error
    );
  }, [fetchApiData, filterText, perPage, sortByColumn, sortByDir]);

  useEffect(() => {
    if (reload) {
      fetchApiData(1).catch(console.error);
      setCurrentPage(1);
      setReload(false);
    }
  }, [fetchApiData, reload, setReload]);

  const handlePageChange = useCallback(
    (page) => {
      if (isInitialize) {
        fetchApiData(page).catch(console.error);
        setCurrentPage(page);
      }
    },
    [fetchApiData, isInitialize]
  );

  const handlePerRowsChange = useCallback(
    (newPerPage, page) => {
      if (isInitialize) {
        fetchApiData(page, newPerPage).catch(console.error);
        setPerPage(newPerPage);
      }
    },
    [fetchApiData, isInitialize]
  );
  const reorderRowInner = useCallback(() => {
    if (hasReordered) return; // If already reordered, exit early
    setHasReordered(true); // Set the flag to prevent further calls
    console.log("diff", diff, data);

    const updateOrderingArray = diff.map((obj) => ({
      [data[obj.oldPosition].id]: {
        [column_ordering]: (currentPage - 1) * perPage + obj.newPosition + 1,
      },
    }));

    if (updateOrderingArray.length) {
      updateOrderingHandler(token, updateOrderingArray)
        .then(() => {
          fetchApiData(currentPage);
          setDiff([]); // Clear diff after processing
        })
        .catch((error) => notifyError(error.message))
        .finally(() => {
          setHasReordered(false); // Reset flag
        });
    } else {
      setHasReordered(false); // Reset flag if no update needed
    }
  }, [
    currentPage,
    diff,
    fetchApiData,
    perPage,
    column_ordering,
    token,
    updateOrderingHandler,
    data,
    hasReordered, // Include the flag
  ]);

  useEffect(() => {
    if (diff.length > 0) {
      console.log("Reordering rows with diff:", diff); // Debugging statement
      reorderRowInner();
    }
  }, [diff, reorderRowInner]);

  useEffect(() => {
    if (isDraggable) {
      const handleMouseDown = function () {
        $(this).prop("selected", true);
      };

      const handleDragStart = function () {
        const start_row_index = $(this).index();
        setActiveId(start_row_index);
      };

      const handleDrop = function () {
        const drop = $(this).index();
        setDropId(drop);
      };

      const handleDragEnd = function () {
        if (activeId !== dropId) {
          const array = [];
          if (activeId < dropId) {
            array.push({ oldPosition: activeId, newPosition: dropId });
            for (let i = activeId + 1; i <= dropId; i++) {
              array.push({ oldPosition: i, newPosition: i - 1 });
            }
          } else if (activeId > dropId) {
            array.push({ oldPosition: activeId, newPosition: dropId });
            for (let i = activeId - 1; i >= dropId; i--) {
              array.push({ oldPosition: i, newPosition: i + 1 });
            }
          }
          setDiff(array);
        }
        setActiveId(null);
        setDropId(null);
      };

      $(".rdt_TableRow").on("mousedown", handleMouseDown);
      $(".rdt_TableRow").on("dragstart", handleDragStart);
      $(".rdt_TableRow").on("drop", handleDrop);
      $(".rdt_TableRow").on("dragend", handleDragEnd);

      return () => {
        $(".rdt_TableRow").off("mousedown", handleMouseDown);
        $(".rdt_TableRow").off("dragstart", handleDragStart);
        $(".rdt_TableRow").off("drop", handleDrop);
        $(".rdt_TableRow").off("dragend", handleDragEnd);
      };
    }
  }, [data, isDraggable, dropId]);

  const memoizedColumns = useMemo(() => columns, [columns]);
  console.log(data, "data");
  return (
    <div className="mt-4">
      <DataTable
        columns={memoizedColumns}
        data={data}
        progressPending={loading}
        progressComponent={<DatatableLoader />}
        pagination
        paginationServer
        paginationDefaultPage={currentPage}
        paginationComponent={(props) => (
          <CustomPagination
            totalPages={totalPages}
            currentPage={currentPage}
            onChangePage={(page) => setCurrentPage(page)}
            {...props}
          />
        )}
        subHeader={showHeader}
        subHeaderComponent={header}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        draggable={isDraggable}
        customStyles={customStyles}
      />
    </div>
  );
}
