import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from "../../utility/common";

import HeadingTwo from "../../components/HeadingTwo";
import FormControl from "../../components/FormControl";
import { Card } from "react-bootstrap";
import FormSelect from "../../components/FormSelect";
import { getToken } from "../../utility/constants";
import { useCallback, useEffect, useState } from "react";
import {
  getCarBrandList,
  getModelListingById,
} from "../CarUserReview/CarUserController";
import { getAllBrands } from "../../request";
import addSafetyRatingSchema from "./validationSchema";
import FormControlNumber from "../../components/FormControlNumber";
export default function SafetyRatingForm({ initialValues, onSubmit, title }) {
  const navigate = useNavigate();
  const [brandList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState("");
  const token = getToken();
  console.log(initialValues, "initialValues");
  const { id } = useParams();
  useEffect(() => {
    const getBrandList = async () => {
      try {
        const response = await getAllBrands();
        setBrandList(response.data.result);
      } catch (err) {
        console.log(err);
      }
    };
    getBrandList();
  }, [token]);

  const getModelList = async (id) => {
    console.log(id, "valuesss");
    try {
      const payload = {
        brand_id: parseInt(id),
        sortBy: "",
        SortOrder: "",
      };
      const response = await getModelListingById(payload, token);
      setModelList(response.result);
    } catch (err) {
      console.log(err);
    }
  };

  const { handleSubmit, errors, values, touched, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: addSafetyRatingSchema,
    onSubmit: async (values, action) => {
      try {
        const payload = {
          ...values,
        };
        if (title == "Edit Safety Rating") {
          payload.id = id;
        }

        console.log(payload, "i am stuck");
        const repsonse = await onSubmit(payload);
        console.log(repsonse, "repsonse");
        if (repsonse.status === 200) {
          notifySuccess("Safety Rating added successfully");
          setTimeout(() => {
            navigate("/safety-rating");
          }, 2000);
        }
      } catch (err) {
        console.log(err);
        notifyError(err.repsonse.data.message);
      }
    },
  });

  useEffect(() => {
    console.log(values.brand_id, "valuesss.brand_id");
    if (values.brand_id > 0) getModelList(values.brand_id);
  }, [values.brand_id]);

  return (
    <>
      <div>
        <form onSubmit={handleSubmit}>
          <div className="d-flex gap-4 justify-content-between">
            <Card className="p-3" style={{ width: "80%" }}>
              <HeadingTwo
                title={title}
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />
              <div className="row d-flex gap-4">
                <FormSelect
                  controlId={"brandName"}
                  label={"Select Brand"}
                  name={"brand_id"}
                  defaultName={"Select Brand"}
                  items={brandList}
                  value={values.brand_id}
                  itemName={"brand_name"}
                  touched={touched}
                  showDefault={true}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  isRequired={true}
                />
                <FormSelect
                  controlId="modelId"
                  label="Model"
                  placeholder="Select Model"
                  touched={touched}
                  errors={errors}
                  itemName={"model_name"}
                  showDefault={true}
                  setDefaultName={"Select Model"}
                  name="model_id"
                  value={values.model_id}
                  items={modelList}
                  setFieldValue={setFieldValue}
                />
                <FormControlNumber
                  controlId="yearOfTesting"
                  label="Year Of Testing"
                  placeholder="Year Of Testing"
                  touched={touched}
                  errors={errors}
                  name="yearoftesting"
                  value={values.yearoftesting}
                  setFieldValue={setFieldValue}
                />
                <FormControl
                  controlId="crashTestWeight"
                  label="Crash Test Weight"
                  placeholder="Crash Test Weight"
                  touched={touched}
                  errors={errors}
                  name="crashtestweight"
                  value={values.crashtestweight}
                  setFieldValue={setFieldValue}
                />
                <FormControl
                  controlId="madeIn"
                  label="Made In"
                  placeholder="Made In"
                  touched={touched}
                  errors={errors}
                  name="madein"
                  value={values.madein}
                  setFieldValue={setFieldValue}
                />
                <FormControlNumber
                  controlId="globalNcapAdultRating"
                  label="Global Ncap Adult Rating"
                  placeholder="Global Ncap Adult Rating"
                  touched={touched}
                  errors={errors}
                  name="globalncapadultrating"
                  value={values.globalncapadultrating}
                  setFieldValue={setFieldValue}
                />
                <FormControlNumber
                  controlId="globalNcapAdultMaxScore"
                  label="Global Ncap Adult Max Score"
                  placeholder="Global Ncap Adult Max Score"
                  touched={touched}
                  errors={errors}
                  name="globalncapadultmaxscore"
                  value={values.globalncapadultmaxscore}
                  setFieldValue={setFieldValue}
                />
                <FormControlNumber
                  controlId="globalNcapAdultAchievedScore"
                  label="Global Ncap Adult Achieved Score"
                  placeholder="Global Ncap Adult Achieved Score"
                  touched={touched}
                  errors={errors}
                  name="globalncapadultachievedscore"
                  value={values.globalncapadultachievedscore}
                  setFieldValue={setFieldValue}
                />
                <FormControlNumber
                  controlId="globalNcapChildRating"
                  label="Global Ncap Child Rating"
                  placeholder="Global Ncap Child Rating"
                  touched={touched}
                  errors={errors}
                  name="globalncapchildrating"
                  value={values.globalncapchildrating}
                  setFieldValue={setFieldValue}
                />
                <FormControlNumber
                  controlId="globalNcapChildMaxScore"
                  label="Global Ncap Child Max Score"
                  placeholder="Global Ncap Child Max Score"
                  touched={touched}
                  errors={errors}
                  name="globalncapchildmaxscore"
                  value={values.globalncapchildmaxscore}
                  setFieldValue={setFieldValue}
                />
                <FormControlNumber
                  controlId="globalNcapChildAchievedScore"
                  label="Global Ncap Child Achieved Score"
                  placeholder="Global Ncap Child Achieved Score"
                  touched={touched}
                  errors={errors}
                  name="globalncapchildachievedscore"
                  value={values.globalncapchildachievedscore}
                  setFieldValue={setFieldValue}
                />

                <FormControlNumber
                  controlId="bharatNcapAdultRating"
                  label="bharat Ncap Adult Rating"
                  placeholder="bharat Ncap Adult Rating"
                  touched={touched}
                  errors={errors}
                  name="bharatncapadultrating"
                  value={values.bharatncapadultrating}
                  setFieldValue={setFieldValue}
                />
                <FormControlNumber
                  controlId="bharatNcapAdultMaxScore"
                  label="bharat Ncap Adult Max Score"
                  placeholder="bharat Ncap Adult Max Score"
                  touched={touched}
                  errors={errors}
                  name="bharatncapadultmaxscore"
                  value={values.bharatncapadultmaxscore}
                  setFieldValue={setFieldValue}
                />
                <FormControlNumber
                  controlId="bharatNcapAdultAchievedScore"
                  label="bharat Ncap Adult Achieved Score"
                  placeholder="bharat Ncap Adult Achieved Score"
                  touched={touched}
                  errors={errors}
                  name="bharatncapadultachievedscore"
                  value={values.bharatncapadultachievedscore}
                  setFieldValue={setFieldValue}
                />
                <FormControlNumber
                  controlId="bharatNcapChildRating"
                  label="bharat Ncap Child Rating"
                  placeholder="bharat Ncap Child Rating"
                  touched={touched}
                  errors={errors}
                  name="bharatncapchildrating"
                  value={values.bharatncapchildrating}
                  setFieldValue={setFieldValue}
                />
                <FormControlNumber
                  controlId="bharatNcapChildMaxScore"
                  label="bharat Ncap Child Max Score"
                  placeholder="bharat Ncap Child Max Score"
                  touched={touched}
                  errors={errors}
                  name="bharatncapchildmaxscore"
                  value={values.bharatncapchildmaxscore}
                  setFieldValue={setFieldValue}
                />
                <FormControlNumber
                  controlId="bharatNcapChildAchievedScore"
                  label="bharat Ncap Child Achieved Score"
                  placeholder="bharat Ncap Child Achieved Score"
                  touched={touched}
                  errors={errors}
                  name="bharatncapchildachievedscore"
                  value={values.bharatncapchildachievedscore}
                  setFieldValue={setFieldValue}
                />

                <FormControl
                  controlId="globalNcapBrochure"
                  label="Global Ncap Brochure"
                  placeholder="Global Ncap Brochure"
                  touched={touched}
                  errors={errors}
                  name="globalncapbrochure"
                  value={values.globalncapbrochure}
                  setFieldValue={setFieldValue}
                />
                <FormControl
                  controlId="bharatNcapBrochure"
                  label="bharat Ncap Brochure"
                  placeholder="bharat Ncap Brochure"
                  touched={touched}
                  errors={errors}
                  name="bharatncapbrochure"
                  value={values.bharatncapbrochure}
                  setFieldValue={setFieldValue}
                />
                <FormControl
                  controlId="GlobalNcapRatingVideourl"
                  label="Global Ncap Rating Videourl"
                  placeholder="Global Ncap Rating Videourl"
                  touched={touched}
                  errors={errors}
                  name="globalncapratingvideourl"
                  value={values.globalncapratingvideourl}
                  setFieldValue={setFieldValue}
                />
                <FormControl
                  controlId="bharatNcapRatingVideourl"
                  label="bharat Ncap Rating Videourl"
                  placeholder="bharat Ncap Rating Videourl"
                  touched={touched}
                  errors={errors}
                  name="bharatncapratingvideourl"
                  value={values.bharatncapratingvideourl}
                  setFieldValue={setFieldValue}
                />
              </div>
              <div className="py-3">
                <button type="submit" className="green-filled-button">
                  Submit
                </button>
              </div>
            </Card>
          </div>
        </form>
      </div>
    </>
  );
}
