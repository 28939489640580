import { useState } from "react";
import { getToken, renderDate } from "../../utility/constants";
import apiHandler from "./apiHandler";
import { notifyError, notifySuccess } from "../../utility/common";
import EditViewDelete from "../../components/EditViewDeleteReviewPassword";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs";
import { Card } from "react-bootstrap";
import Datatable from "../../components/Table";

import HeadingTwo from "../../components/HeadingTwo";
import DeleteConfirmationModalComponent from "../../components/DeleteConfirmationModalComponent";
import Layout from "../../components/Layout";
import { ReactSVG } from "react-svg";
import AlertSVG from "../../assets/icons/alert-feedback.svg";
export default function Feedback() {
  const [reloadTable, setReloadTable] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [feedbackToDelete, setFeedbackToDelete] = useState();

  const [filter, setFilter] = useState({ search: "Driving School" });
  const token = getToken();
  const navigate = useNavigate();
  const onDeleteInner = async () => {
    try {
      await apiHandler.deleteFeedback(feedbackToDelete, token);
      setShowDeleteModal(false);
      setReloadTable(true);
      notifySuccess("Feedback deleted successfully");
    } catch (error) {
      console.error("Error deleting feedback:", error);
      setShowDeleteModal(false);
      notifyError("Failed to delete feedback");
    }
  };
  const handleStatusChange = async (id, status) => {
    console.log(id, status);
    const payload = { status: parseInt(status) };
    await apiHandler.editFeedbackStatus(token, id, payload);
    setReloadTable(true);
  };

  const handleFilterChange = (value) => {
    console.log(value);
    setFilter({ search: value });
  };
  console.log(filter);

  const columns = [
    {
      name: "Modules",
      sortable: true,
      selector: (row) => row.module, // Corrected to 'selector'
      width: "160px",
    },
    {
      name: "Bussiness Name",
      sortable: false,
      selector: (row) => row.module_name, // Corrected to 'selector'
      width: "260px",
    },

    {
      name: "Issues",
      sortable: false,
      selector: (row) => {
        // Create an array of fields that need to be checked
        const issueFields = [
          { value: row.contact_name, label: "Contact Name" },
          { value: row.phone_number, label: "Phone Number" },
          { value: row.email, label: "Email" },
          { value: row.address, label: "Address" },
          { value: row.other, label: "Other" },
        ];

        // Filter fields that have a truthy value (not null, undefined, 0, or empty)
        const validFields = issueFields.filter((field) => field.value == 1);

        if (validFields.length > 0) {
          return (
            <>
              {validFields.map((field, index) => (
                <div key={index}>
                  {field.label}
                  <img
                    width="30"
                    height="30"
                    src="https://img.icons8.com/ios-glyphs/30/error--v1.png"
                    alt="error icon"
                  />
                </div>
              ))}
            </>
          );
        }

        return null; // If none or more than one, show nothing
      },
      width: "180px",
    },

    {
      name: "Suggestions",
      sortable: false,
      selector: (row) => row.summary, // Corrected to 'selector'
      width: "360px",
    },
    {
      name: "Owner",
      sortable: false,
      selector: (row) => (row.owner ? "Yes" : "No"),
      width: "160px",
    },

    {
      name: "Status",
      sortable: false,
      selector: (row) => (
        <>
          <select
            value={row.status}
            onChange={(e) => handleStatusChange(row.id, e.target.value)}
          >
            <option value="0">Pending</option>
            <option value="1">Solved</option>
            <option value="2">Rejected</option>
          </select>
          <div>{renderDate(row.created_date)}</div>
        </>
      ),
      width: "160px",
    },
    {
      name: "Action",
      sortable: false,
      width: "auto",
      cell: (row) => (
        <EditViewDelete
          id={row.id}
          onDelete={() => {
            setFeedbackToDelete(row.id);
            setShowDeleteModal(true);
          }}
          onView={() => navigate("/")}
        />
      ),
    },
  ];

  return (
    <>
      <Layout>
        <div className="row dashboard">
          <div className="my-4">
            <div className="d-flex justify-content-between mb-2">
              <Breadcrumbs />
            </div>
            <Card className="p-3">
              <div>
                <HeadingTwo
                  title={"Feedback"}
                  type={"dashboard"}
                  showHeader
                  pointerOnHover
                />
                {/* <CommonSearchBar
                  onChange={handleSearchValue}
                  value={searchVal}
                  options={filterOptions}
                  selectedTypes={selectedTypes}
                  selectedValues={{
                    : selectedBrand.id,
                    state: selectedState,
                  }}
                  apply={applyFilter}
                  clearAll={clearAll}
                  tableHeading={"City Dealer List"}
                  searchPlaceholder={"Search"}
                /> */}
              </div>
              <div className="d-flex gap-3">
                <button
                  onClick={() => handleFilterChange("Driving School")}
                  className={`active feedback-button-grp${
                    filter.search === "Driving School" ? "-active" : ""
                  }`}
                >
                  Driving School
                </button>
                <button
                  onClick={() => handleFilterChange("Service Center")}
                  className={`feedback-button-grp${
                    filter.search === "Service Center" ? "-active" : ""
                  }`}
                >
                  Service Centre
                </button>
                <button
                  onClick={() => handleFilterChange("Car Dealer")}
                  className={`feedback-button-grp${
                    filter.search === "Car Dealer" ? "-active" : ""
                  }`}
                >
                  Car Dealer
                </button>
                <button
                  onClick={() => handleFilterChange("Bike Dealer")}
                  className={`feedback-button-grp${
                    filter.search === "Bike Dealer" ? "-active" : ""
                  }
                  `}
                >
                  Bike Dealer
                </button>
                <button
                  onClick={() => handleFilterChange("Maruti School")}
                  className={`feedback-button-grp${
                    filter.search === "Maruti School" ? "-active" : ""
                  }`}
                >
                  Maruti School
                </button>
              </div>

              <div>
                <Datatable
                  columns={columns}
                  api={apiHandler.getFeedbackList}
                  reload={reloadTable}
                  setReload={setReloadTable}
                  setTotalRecords={setTotalPages}
                  totalRecords={totalPages}
                  filter={filter}
                />
                <DeleteConfirmationModalComponent
                  show={showDeleteModal}
                  type={"delete"}
                  handleClose={() => setShowDeleteModal(false)}
                  setDeleteConfirm={onDeleteInner}
                />
              </div>
            </Card>
          </div>
        </div>
      </Layout>
    </>
  );
}
