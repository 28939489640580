import { useNavigate } from "react-router-dom";
import HeadingTwo from "../../../components/HeadingTwo";

import FAQapiHandler from "../FAQapiHandler";
import { FAQcustomStyles } from "../../NewCar/CarFAQs/FAQtableStyle";
import { useEffect, useState } from "react";
import { generateMonths, getToken } from "../../../utility/constants";
import CarModelBtn from "../../../components/CarModelBtn";
import Search from "../../../components/SearchField";
import Datatable from "../../../components/Table";
import { CarAnswerColumn } from "./CarAnswerColumn";

export default function CarAnswers() {
  const [reload, setReload] = useState(false);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  //eslint-disable-next-line
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [filter, setFilter] = useState({
    date_filter: "",
    question_id: "",
  });
  const [questionList, setQuestionList] = useState([]);
  const navigate = useNavigate();
  const startYear = 2022;

  const reloadTable = () => {
    setReload(!reload);
  };
  const ClearFilter = () => {
    setFilter({
      date_filter: 0,
      question_id: 0,
    });
    setSearch("");
    setReload(!reload);
  };
  const months = generateMonths(startYear);
  useEffect(() => {
    FAQapiHandler.getCarQuestionListAll(
      {
        sortBy: "",
        sortOrder: "",
      },
      getToken()
    )
      .then((response) => {
        console.log(response.data.result);

        setQuestionList(response.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [reload]);

  return (
    <div className="row dashboard">
      <div className="card">
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-4 col-6 d-flex justify-content-between mb-2 sub-heading py-3">
          <HeadingTwo
            title={`Car Answer List (${totalRecords})`}
            type="dashboard"
            showHeader={true}
            pointerOnHover={true}
          />
          <CarModelBtn
            label={"Add Car Answer"}
            className="add-button d-flex align-items-center justify-content-between mb-2 px-3"
            onClick={() => navigate("/add-car-answer")}
          />
        </div>
        <div className="d-flex justify-content-start">
          <Search
            onChange={(event) => {
              console.log("ev", event);
              setSearch(event.target.value);
            }}
            value={search}
            placeholder={"Search by Question"}
          />
          <select
            className="question-select border border-2 shadow-none ms-2"
            id="searchQuestion"
            placeholder="Search"
            value={filter.date_filter}
            onChange={(e) =>
              setFilter({ ...filter, date_filter: e.target.value })
            }
          >
            <option value="">Select Month</option>
            {months.map((month, index) => (
              <option key={index} value={month.id}>
                {month.value}
              </option>
            ))}
          </select>

          <select
            className="month-select border border-2 shadow-none ms-2"
            id="searchBrand"
            placeholder="Search"
            value={filter.question_id}
            onChange={(e) =>
              setFilter({ ...filter, question_id: e.target.value })
            }
          >
            <option value="">Select Question</option>
            {questionList.map((question, index) => (
              <option key={index} value={question.id}>
                {question.question_name}
              </option>
            ))}
          </select>
          <button className="black-filled-button ms-2" onClick={reloadTable}>
            Apply
          </button>
          <button className="black-filled-button ms-2" onClick={ClearFilter}>
            Clear
          </button>
        </div>
        <Datatable
          api={FAQapiHandler.getAnswerList}
          columns={CarAnswerColumn(reloadTable, currentPage, itemsPerPage)}
          search={search}
          deafaultSortColumn={""}
          reload={reload}
          setReload={setReload}
          setTotalRecords={setTotalRecords}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
          isDraggable={true}
          column_ordering={""}
          sortByDir={"asc"}
          styling={FAQcustomStyles}
          filter={filter}
        />
      </div>
    </div>
  );
}
