// /car/city-price/multipleprice

import axios from "axios";
import {
  BASE_URL_AUTOX,
  GET_BIKE_MULTIPLE_CITY_PRICE,
  getConfig,
} from "../../../request";

/**
 * Get List of multiple car price for city
 * getCityMultiplePrice is used to get List of multiple car price for city
 * @param token
 * @param payload
 */
export function getBikeCityMultiplePrice(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_BIKE_MULTIPLE_CITY_PRICE}`,
    payload,
    getConfig(token)
  );
}

export function updateBikeCityMultiplePrice(token, payload) {
  return axios.put(
    `${BASE_URL_AUTOX}${GET_BIKE_MULTIPLE_CITY_PRICE}`,
    payload,
    getConfig(token)
  );
}

export default {
  getBikeCityMultiplePrice,
  updateBikeCityMultiplePrice,
};
