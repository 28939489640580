import * as Yup from "yup";

const sidebarSchema = Yup.object().shape({
  sidebar_name: Yup.string().max(255).required("Please enter sidebar name"),
  router_location: Yup.string()
    .max(255)
    .required("Please enter router location"),
  remark: Yup.string().max(255).required(),
});

export default sidebarSchema;
