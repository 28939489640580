import React from 'react'
import AuthLayout from './AuthLayout'
import Login from '../../components/Login'

export default function LoginPage() {
  return (
   <AuthLayout>
    <Login />
   </AuthLayout>
  )
}
