import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../../utility/constants";
import { useEffect, useState } from "react";
import apiHandler from "./apiHandler";
import { notifySuccess } from "../../utility/common";
import NewsLetterForm from "./NewsLetterForm";
import { useFormik } from "formik";
import Breadcrumbs from "../../Breadcrumbs";

export default function EditNewsLetter() {
  const navigate = useNavigate();
  const token = getToken();
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    is_subscribable: "",
    status: "",
    emails: "",
  });

  useEffect(() => {
    const fetchNewsLetterData = async () => {
      const response = await apiHandler.getNewsletterDetails(id, token);
      console.log(response.data.data[0]);
      setInitialValues(response.data.data[0]);
    };
    fetchNewsLetterData();
  }, [id, token]);

  const { handleSubmit, values, setFieldValue, touched, errors } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    //   validationSchema: PageValidationSchema,
    onSubmit: (values) => {
      delete values.newsletter_id;
      delete values.created_date;
      delete values.updated_date;
      apiHandler.updateNewsletterDetails(id, values, token).then((res) => {
        console.log(res);
        if (res.status === 200) {
          notifySuccess("NewsLetter updated successfully");
          setTimeout(() => {
            navigate("/news-letter");
          }, 1500);
        }
      });
    },
  });
  return (
    <>
      <Breadcrumbs />
      <div>
        <NewsLetterForm
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          handleSubmit={handleSubmit}
          type={"UPDATE"}
        />
      </div>
    </>
  );
}
