import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../request";

export function getMagazineList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}magazine/filtered/list`,
    payload,
    getConfig(token)
  );
}
export function addMagazine(payload, token) {
  return axios.post(
    `${BASE_URL_AUTOX}magazine/add`,
    payload,
    getConfig(token, { "Content-Type": "multipart/form-data" })
  );
}

export function getCurrentId(token) {
  return axios.get(
    `${BASE_URL_AUTOX}subscription/current-id`,
    getConfig(token)
  );
}

export function editMagazine(id, data, token) {
  return axios.put(
    `${BASE_URL_AUTOX}magazine/edit/${id}`,
    data,
    getConfig(token, { "Content-Type": "multipart/form-data" })
  );
}

export function getMagazineDetails(id, token) {
  return axios.get(
    `${BASE_URL_AUTOX}magazine/magazine-details/${id}`,
    getConfig(token)
  );
}
export function deleteMagazine(id, token) {
  return axios.delete(
    `${BASE_URL_AUTOX}magazine/delete/${id}`,
    getConfig(token)
  );
}

export default {
  getMagazineList,
  addMagazine,
  editMagazine,
  getMagazineDetails,
  deleteMagazine,
};
