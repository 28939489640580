// /car/city-price/multipleprice

import axios from "axios";
import {
  BASE_URL_AUTOX,
  GET_CAR_MULTIPLE_CITY_PRICE,
  getConfig
} from "../../../request";

/**
 * Get List of multiple car price for city
 * getCityMultiplePrice is used to get List of multiple car price for city
 * @param token
 * @param payload
 */
export function getCityMultiplePrice(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_CAR_MULTIPLE_CITY_PRICE}`,
    payload,
    getConfig(token)
  );
}

export function updateCityMultiplePrice(token, payload) {
  return axios.put(
    `${BASE_URL_AUTOX}${GET_CAR_MULTIPLE_CITY_PRICE}`,
    payload,
    getConfig(token)
  );
}

export default {
  getCityMultiplePrice,
  updateCityMultiplePrice
};
