import { useFormik } from "formik";
import React from "react";
import { Accordion, Card, Form, Button } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { addPostGallery } from "./apiHandler";
import { getToken } from "../../utility/constants";
import { notifyError, notifySuccess } from "../../utility/common";

const AddPostGallery = ({}) => {
  const { handleSubmit, values, setFieldValue, touched, errors } = useFormik({
    enableReinitialize: true,
    initialValues: { images: [], galleryName: "", gallerySlug: "" },
    onSubmit: async (values, action) => {
      const formData = new FormData();
      formData.append("galleryName", values.galleryName);
      formData.append("gallerySlug", values.gallerySlug);

      values.images.forEach((image, index) => {
        formData.append(`images[${index}][file]`, image.file);
        formData.append(`images[${index}][caption]`, image.caption);
        formData.append(`images[${index}][alt]`, image.alt);
        formData.append(`images[${index}][order]`, image.order);
      });

      try {
        const data = await addPostGallery(formData, getToken());
        if (data?.status === 201) {
          action.resetForm();
          notifySuccess("Gallery added successfully");
        }
      } catch (error) {
        console.log("Error:", error);
        notifyError(error.message);
      }
    },
  });

  const handleFileChange = (event) => {
    const files = event.currentTarget.files;
    const newImages = Array.from(files).map((file, index) => {
      const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, ""); // Remove file extension

      return {
        blob: URL.createObjectURL(file),
        file: file,
        caption: "",
        alt: fileNameWithoutExtension, // Use the file name without extension
        order: values.images.length + index + 1,
      };
    });

    setFieldValue("images", [...values.images, ...newImages]);
  };

  const handleRemoveImage = (index) => {
    const updatedImages = values.images.filter((_, i) => i !== index);

    // Reassign order starting from 1
    const reorderedImages = updatedImages.map((image, i) => ({
      ...image,
      order: i + 1,
    }));

    setFieldValue("images", reorderedImages);
  };

  const handleGalleryNameChange = (event) => {
    const name = event.target.value;
    setFieldValue("galleryName", name);
    setFieldValue("gallerySlug", name.toLowerCase().replace(/\s+/g, "-"));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex gap-3" style={{ width: "100%" }}>
        <div className="d-flex flex-column" style={{ width: "70%" }}>
          <div>
            <Card className="w-100">
              <Accordion defaultActiveKey="0">
                <Accordion.Item
                  eventKey="0"
                  className="card-height header_padding_top"
                >
                  <Accordion.Header className="p-3 pb-1 ps-2 pt-4">
                    Upload Files
                  </Accordion.Header>
                  <Accordion.Body>
                    <Form.Group className="mb-3">
                      <Form.Label>Post Gallery Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.galleryName}
                        onChange={handleGalleryNameChange}
                        placeholder="Enter gallery name"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Post Gallery Slug</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.gallerySlug}
                        readOnly
                      />
                    </Form.Group>

                    <div className="mb-3 d-flex gap-3">
                      <div className="mb-3">
                        <Form.Label>Post Gallery</Form.Label>
                        <Form.Control
                          type="file"
                          multiple
                          onChange={(event) => handleFileChange(event)}
                          className="form-control"
                        />
                      </div>
                    </div>

                    {values.images.map((image, index) => (
                      <div key={index} className="mb-3 d-flex">
                        {image.blob ? (
                          image.blob instanceof File ||
                          image.blob instanceof Blob ? (
                            <img
                              src={URL.createObjectURL(image.blob)}
                              alt={image.alt}
                              style={{ width: "50px", height: "50px" }}
                            />
                          ) : (
                            <img
                              src={
                                image.blob.startsWith("https://")
                                  ? image.blob
                                  : image.blob.startsWith("blob:")
                                  ? image.blob
                                  : `https://static.autox.com//${image.blob}`
                              }
                              alt={image.alt}
                              style={{ width: "50px", height: "50px" }}
                            />
                          )
                        ) : null}
                        <Form.Control
                          type="text"
                          placeholder="Caption"
                          value={image.caption}
                          onChange={(e) =>
                            setFieldValue(
                              `images.${index}.caption`,
                              e.target.value
                            )
                          }
                          className="ms-2"
                        />
                        <Form.Control
                          type="text"
                          placeholder="Alt Text"
                          value={image.alt}
                          onChange={(e) =>
                            setFieldValue(`images.${index}.alt`, e.target.value)
                          }
                          className="ms-2"
                        />

                        <Form.Control
                          type="number"
                          placeholder="Order"
                          value={image.order}
                          onChange={(e) =>
                            setFieldValue(
                              `images.${index}.order`,
                              e.target.value
                            )
                          }
                          className="ms-2"
                        />
                        <Button
                          variant="danger"
                          onClick={() => handleRemoveImage(index)}
                          className="ms-2"
                        >
                          Remove
                        </Button>
                      </div>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card>
          </div>
        </div>
      </div>
      <Button type="submit" className="mt-3">
        Submit
      </Button>
    </form>
  );
};

export default AddPostGallery;
