import * as Yup from "yup";

const URL = /^(ftp|http|https):\/\/[^\s/$.?#].[^\s]*$/;

export const carouselValidation = Yup.object({
  title: Yup.string().required("Please enter title"),
  slug: Yup.string()
    .matches(URL, "url is not valid")
    .required("Please enter url"),
  custom_slider_image: Yup.mixed().required("Please upload slider image"),
});
