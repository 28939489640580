import axios from "axios";
import {
  BASE_URL_AUTOX,
  GET_BIKE_CITY_LIST,
  getConfig,
} from "../../../request";

/**
 * Get List of Bike City
 * getBikeCityList is used to get List of Bike cities
 * @param token
 * @param payload
 */
export function getBikeCityList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_BIKE_CITY_LIST}`,
    payload,
    getConfig(token)
  );
}
//eslint-disable-next-line
export default {
  getBikeCityList,
};
