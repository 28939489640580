import Breadcrumbs from "../../Breadcrumbs";
import Layout from "../../components/Layout";
import CarUserReview from "./CarUserReviews";

const CarUserReviewMain = () => {
  return (
    <Layout>
      <div className="row dashboard">
        <Breadcrumbs />
        <CarUserReview />
      </div>
    </Layout>
  );
};

export default CarUserReviewMain;
