import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs";
import apiHandler from "./apiHandler";
import { getToken } from "../../utility/constants";
import NewsLetterForm from "./NewsLetterForm";
import { useFormik } from "formik";
import { notifySuccess } from "../../utility/common";

export default function AddNewsLetter() {
  const navigate = useNavigate();
  const token = getToken();

  const { handleSubmit, values, setFieldValue, touched, errors } = useFormik({
    initialValues: {
      name: "",
      description: "",
      is_subscribable: 0,
      status: 0,
      emails: "",
    },
    enableReinitialize: true,
    //   validationSchema: PageValidationSchema,
    onSubmit: (values) => {
      console.log("values", values);
      // return;
      apiHandler.addNewNewsLetter(values, token).then((res) => {
        console.log(res);
        if (res.status === 200) {
          notifySuccess("NewsLetter added successfully");
          setTimeout(() => {
            navigate("/news-letter");
          }, 1500);
        }
      });
    },
  });

  return (
    <>
      <Breadcrumbs />
      <div>
        <NewsLetterForm
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          handleSubmit={handleSubmit}
          type={"Submit"}
        />
      </div>
    </>
  );
}
