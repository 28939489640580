import React from "react";
import Breadcrumbs from "../../Breadcrumbs";
import Layout from "../../components/Layout";
import AddForm from "./EditBikeUserReview/AddForm";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../assets/icons/bootstrap-icons/Back_Arrow.svg";
import { ReactSVG } from "react-svg";
export default function AddBikeUserReview() {
  const navigate = useNavigate();
  return (
    <Layout>
      <div className="row dashboard">
        <div className="row">
          <div className="col-xl-11 col-sm-11">
            <Breadcrumbs />
          </div>
          <div className="col-xl-1 col-sm-1">
            <button
              className="d-flex back-button py-2 px-3 mb-4 btn btn-primary"
              onClick={() => navigate("/bike-models")}
            >
              <ReactSVG src={BackIcon} className="me-1" />
              Back
            </button>
          </div>
        </div>
        <AddForm />
      </div>
    </Layout>
  );
}
