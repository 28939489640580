import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../request";

export function getEnquiryList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}enquiry/filtered/list`,
    payload,
    getConfig(token)
  );
}

export default {
  getEnquiryList,
};
