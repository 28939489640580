import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/Table";
import EditViewDeleteReviewPassword from "../../../components/EditViewDeleteReviewPassword";
import { ReactSVG } from "react-svg";
import PlusLargeIcon from "../../../assets/icons/bootstrap-icons/plus-lg.svg";
import { getBikeCities, deleteBikeCityById } from "../../../request";
import Search from "../../../components/SearchField";
import Breadcrumbs from "../../../Breadcrumbs";
import Card from "react-bootstrap/Card";
import { getToken } from "../../../utility/constants";
import apiHandler from "./apiHandler";
import DeleteConfirmationModalComponent from "../../../components/DeleteConfirmationModalComponent";
import { notifyError, notifySuccess } from "../../../utility/common";
import ExportButton from "../../../components/Export";

export default function BikeCities({ children }) {
  const token = getToken();
  const navigate = useNavigate();

  const [filteredCities, setFilteredCities] = useState([]);
  const [cities, setCities] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [slicedCities, setSlicedCities] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [show, setShow] = useState(false);
  const [reload, setReload] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  //eslint-disable-next-line
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const onEditInner = (id) => {
    navigate(`/bike-city/edit-bike-city/${id}`);
  };

  const onDeleteInner = async (id) => {
    deleteBikeCityById(deleteId, token)
      .then(() => {
        setShow(false);
        setReload(true);
        notifySuccess("Bike city deleted successfully");
      })
      .catch((error) => {
        setShow(false);
        notifyError(error.message);
      });
  };

  const columns = [
    {
      name: "City ID",
      sortable: true,
      selector: (row) => row.id,
      width: "auto",
    },
    {
      name: "State Name",
      sortable: true,
      selector: (row) => row.state_name,
      width: "auto",
    },
    {
      name: "City Name",
      sortable: true,
      selector: (row) => row.city_name,
      width: "auto",
    },
    {
      name: "Url",
      sortable: true,
      selector: (row) => row.city_url,
      width: "auto",
    },
    {
      name: "Action",
      sortable: false,
      width: "auto",
      cell: (row) => (
        <EditViewDeleteReviewPassword
          id={row.id}
          onEdit={onEditInner}
          onDelete={(id) => {
            setDeleteId(id);
            setShow(true);
          }}
        />
      ),
    },
  ];
  const Keys = ["id", "city_name", "state_id", "state_name"];
  function handleBrandSlicing() {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const slicedCitiesInner = filteredCities.slice(startIndex, endIndex);
    setSlicedCities(slicedCitiesInner);
  }

  function handleSearchClick() {
    setReload(true);
  }

  const fetchData = async () => {
    try {
      console.log("test");
      const res = await getBikeCities(token);
      const response = res.data.data;
      // const response = data;
      console.log("response", response);

      const modifiedData = await Promise.all(
        response.map(async (item) => {
          if (item.updated_launch_date) {
            const dateObject = new Date(item.updated_launch_date);
            const formattedDate = `${dateObject.getUTCDate()}-${(
              dateObject.getUTCMonth() + 1
            )
              .toString()
              .padStart(2, "0")}-${dateObject.getUTCFullYear()}`;
            item.updated_launch_date = formattedDate;
          }
          return {
            ...item,
          };
        })
      );
      setCities(modifiedData);
    } catch (error) {
      console.log(error.message);
    } finally {
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    handleBrandSlicing();
  }, [currentPage, filteredCities, itemsPerPage]);

  useEffect(() => {
    setFilteredCities(cities);
  }, [cities]);

  return (
    <div className="row dashboard">
      <div className="d-flex justify-content-between">
        <Breadcrumbs />

        <ExportButton
          api={apiHandler.getBikeCityList}
          totalRecords={totalRecords}
          keys={Keys}
          showKeys={Keys}
          payload={{
            search: "",
            sortBy: "",
            sortOrder: "",
            page: 1,
            limit: totalRecords,
          }}
        />
      </div>
      <Card>
        <Card.Body>
          <Card.Text>
            <div className="row dashboard">
              <div className="col-md-12 p-0 m-0">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="col-md-6 d-flex align-items-center p-0 m-0">
                    <div className="table-heading fw-bold d-flex align-items-center m-0 p-0">
                      City list ({totalRecords})
                    </div>
                    <div className="py-0 ms-2">
                      <Search
                        onChange={(event) => {
                          console.log("ev", event);
                          setSearchVal(event.target.value);
                        }}
                        value={searchVal}
                        placeholder={"Search by City Name"}
                      />
                    </div>
                    <button
                      className="d-flex align-items-center justify-content-center search-btn ms-2"
                      onClick={handleSearchClick}
                    >
                      Search
                    </button>
                    {/* <button className="px-2 border-1 rounded filter-option p-2">
                                            <ReactSVG src={FilterIcon} />
                                        </button> */}
                  </div>

                  <div className="col-md-6 text-right">
                    <div className="btngroup d-flex justify-content-end flex-row flex-wrap">
                      <button
                        className="add-service-centre-button d-flex align-items-center justify-content-between mb-2 px-3"
                        onClick={() => navigate("/bike-city/add-bike-city")}
                      >
                        <ReactSVG
                          src={PlusLargeIcon}
                          height="18px"
                          width="18px"
                          className="mr-2"
                        />{" "}
                        Add City
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <Table
                api={apiHandler.getBikeCityList}
                columns={columns}
                data={slicedCities}
                currentPage={currentPage}
                onChangePage={(pageNumber) => setCurrentPage(pageNumber)}
                totalPages={Math.ceil(slicedCities.length / itemsPerPage)}
                showPagination={true}
                setTotalRecords={setTotalRecords}
                setReload={setReload}
                reload={reload}
                search={searchVal}
              ></Table>
            </div>
            <DeleteConfirmationModalComponent
              show={show}
              type={"delete"}
              handleClose={() => setShow(false)}
              setDeleteConfirm={onDeleteInner}
            />
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
