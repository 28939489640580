import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layout";
import Breadcrumbs from "../../../Breadcrumbs";
import PopularCarList from "./PopularCarList";
import AddPopularCar from "./AddPopularCar";
import { getToken } from "../../../utility/constants";
import APIHandler from "./apiHandler";
import { notifyError, notifySuccess } from "../../../utility/common";

export default function PopularCar({ children }) {
  const token = getToken();
  const [popularCar, setPopularCar] = useState([]);

  useEffect(() => {
    getPopularCarList();
  }, []);

  //Get list of Popular Car
  const getPopularCarList = () => {
    APIHandler.listPopularCar(token)
      .then((result) => {
        setPopularCar(result.data.result);
      })
      .catch((error) => {
        notifyError(error.response.data.message);
      });
  };

  //Handle Delete Event
  const handleDelete = (id) => {
    APIHandler.deletePopularCar(token, id)
      .then((result) => {
        getPopularCarList();
        notifySuccess("Removed Successfully !!");
      })
      .catch((error) => {
        notifyError(
          error.response
            ? error.response.data.message
            : "There is problem in error"
        );
      });
  };

  //Update Sort
  const updateSort = (popularItems) => {
    APIHandler.sortPopular(token, { popularItems })
      .then((result) => {
        // console.log(result.data.result);
        setPopularCar(result.data.result);
      })
      .catch((error) => {
        notifyError(
          error.response
            ? error.response.data.message
            : "There is problem in error"
        );
      });
  };

  return (
    <Layout>
      <div className="row dashboard">
        <div className="d-flex justify-content-between align-items-center multipleCityPriceMain mb-3 flex-wrap">
          <Breadcrumbs />
        </div>
        <div className="row">
          <div className="col-xxl-8 col-md-7">
            <PopularCarList
              updateSort={updateSort}
              carData={popularCar}
              handleDelete={handleDelete}
            />
          </div>
          <div className="col-xxl-4 col-md-5">
            <AddPopularCar onSuccess={getPopularCarList} />
          </div>
        </div>
      </div>
    </Layout>
  );
}
