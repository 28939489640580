import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../request";

export function getFeedbackList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}feedback/filtered/list`,
    payload,
    getConfig(token)
  );
}

export function deleteFeedback(token, id) {
  return axios.delete(
    `${BASE_URL_AUTOX}feedback/delete/${id}`,
    getConfig(token)
  );
}

export function editFeedbackStatus(token, id, payload) {
  return axios.put(
    `${BASE_URL_AUTOX}feedback/update/${id}`,
    payload,
    getConfig(token)
  );
}

export default {
  getFeedbackList,
  deleteFeedback,
  editFeedbackStatus,
};
