import React, { useEffect, useState } from "react";
import BikeGalleryFormHandler from "./BikeGalleryFormHandler";
import {
  editBikeGallery,
  editCarGallery,
  getBikeGalleryById,
  getCarGalleryById,
} from "../apiHandler";
import { getToken, renderDateTime } from "../../../utility/constants";
import { notifyError, notifySuccess } from "../../../utility/common";
import { useParams } from "react-router-dom";
import BikeGalleryValidation from "../GalleryValidation";

const BikeEditGallery = () => {
  const [initialValues, setInitialValues] = useState(null);
  const [removedImages, setRemovedImages] = useState([]);
  const { id } = useParams();
  const token = getToken();

  useEffect(() => {
    console.log(id, "id");
    const fetchGallery = async () => {
      try {
        const data = await getBikeGalleryById(id, token);
        console.log(data);
        if (data.status === 200) {
          const gallery = data.data.result[0];
          console.log(gallery, "gallery");
          console.log(gallery["images"]);
          setInitialValues({
            brand_id: gallery.model_brand_id,
            model_id: gallery.model_id,
            publish_date: new Date(gallery.publish_date),
            status: gallery.status,
            home_gallery: 0,
            images: gallery.images.map((image, index) => ({
              image_id: image.id,
              blob: image.image,
              file: image.image,
              caption: image.caption,
              alt: image.alt,
              position: image.gallery_type,
              order: image.order_by,
            })),
          });
        } else {
          notifyError("Failed to load gallery");
        }
      } catch (error) {
        console.log("Error fetching gallery:", error);
        notifyError("Error fetching gallery");
      }
    };

    fetchGallery();
  }, [id]);

  const handleSubmit = async (values, action) => {
    console.log(renderDateTime(values.publish_date));
    const formData = new FormData();
    formData.append("brand_id", values.brand_id);
    formData.append("model_id", values.model_id);
    formData.append("publish_date", renderDateTime(values.publish_date));
    formData.append("status", values.status);
    formData.append("home_gallery", values.home_gallery);

    values.images.forEach((image, index) => {
      formData.append(`images[${index}][id]`, image.image_id || "");
      if (image.file) {
        formData.append(`images[${index}][file]`, image.file);
      }
      formData.append(`images[${index}][caption]`, image.caption);
      formData.append(`images[${index}][alt]`, image.alt);
      formData.append(`images[${index}][position]`, image.position);
      formData.append(`images[${index}][order]`, image.order);
    });
    formData.append("removedImages", JSON.stringify(removedImages));

    try {
      const data = await editBikeGallery(id, formData, getToken());
      if (data.status === 200) {
        notifySuccess("Gallery updated successfully");
      } else {
        const responseData = data.response ? data.response.data : data.data;
        const errorMessage = responseData.message || "An error occurred";
        notifyError(errorMessage);
      }
    } catch (error) {
      console.log("Error:", error);
      notifyError(error.message);
    }
  };

  if (!initialValues) {
    return <div>Loading...</div>;
  }

  const handleRemoveImage = (index) => {
    setInitialValues((prevValues) => {
      const updatedImages = [...prevValues.images];
      const [removedImage] = updatedImages.splice(index, 1);
      if (removedImage.image_id) {
        setRemovedImages((prevRemoved) => [
          ...prevRemoved,
          removedImage.image_id,
        ]);
      }

      return { ...prevValues, images: updatedImages };
    });
  };
  return (
    <BikeGalleryFormHandler
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validation={BikeGalleryValidation.BikeGalleryEditValidationSchema}
      onRemoveImage={handleRemoveImage}
      type="edit"
    />
  );
};

export default BikeEditGallery;
