import { Breadcrumb, Card, Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import FormSelect from "../../components/FormSelect";
import apiHandler, { updateStatePrice } from "./apiHandler";
import { getToken } from "../../utility/constants";
import {
  getAllBikeBrands,
  getBikeVersionsByModelId,
  getAllStates,
} from "../../request";
import Breadcrumbs from "../../Breadcrumbs";
import { notifyError, notifySuccess } from "../../utility/common";
import {
  getAllBikeModelsByBrandId,
  getAllBikeModelsList,
} from "../NewBike/BikeVersion/apiHandler";
import {
  getBikeModelByBrandId,
  getBikeVersionByModelId,
} from "../NewBike/BikeCityPrice/apiHandler";

export default function BikeStatePrice() {
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [versions, setVersions] = useState([]);
  const [states, setStates] = useState([]);
  const token = getToken();

  const formik = useFormik({
    initialValues: {
      brand: "-1",
      model: "-1",
      version: "-1",
      state: "",
      ex_showroom_price: "",
      rto: "",
      rtoexpense: "",
      insurance: "",
      standardaccessories: "",
      depotcharges: "",
      rsa: "",
      handlingcharges: "",
      type: "bike", // Always 'bike' for this form
    },
    onSubmit: async (values) => {
      console.log("Form Submitted:", values);
      await updateStatePrice(token, values).then((response) => {
        if (response.status === 200) {
          notifySuccess(response.data.message);
        } else {
          notifyError(response.data.message);
        }
      });
    },
  });

  useEffect(() => {
    const fetchStates = async () => {
      const response = await getAllStates(token);
      setStates(response.data.result);
    };

    fetchStates();
  }, [token]);

  useEffect(() => {
    const fetchBrands = async () => {
      const response = await getAllBikeBrands(token);
      setBrands(response.data.result);
    };

    fetchBrands();
  }, [token]);

  const handleBrandChange = async (e) => {
    const selectedBrand = e;
    formik.setFieldValue("brand", selectedBrand);
    formik.setFieldValue("model", "-1");
    formik.setFieldValue("version", "-1");
    console.log(selectedBrand);

    const response = await getBikeModelByBrandId(token, {
      brand_id: selectedBrand,
    });
    setModels(response.data.result);
  };

  const handleModelChange = async (e) => {
    const selectedModel = e;
    formik.setFieldValue("model", selectedModel);
    formik.setFieldValue("version", "-1");

    const response = await getBikeVersionByModelId(token, {
      model_id: selectedModel,
    });

    setVersions(response.data.result);
  };

  return (
    <div>
      <Breadcrumbs />
      {brands.length > 0 && states.length > 0 && (
        <Card className="p-3">
          <form onSubmit={formik.handleSubmit}>
            <FormSelect
              controlId="brand"
              label="Brand"
              items={brands}
              name="brand"
              value={formik.values.brand}
              setFieldValue={formik.setFieldValue}
              touched={formik.touched}
              errors={formik.errors}
              getValues={handleBrandChange}
              defaultName="Select Brand"
              itemName="brand_name"
            />
            <FormSelect
              controlId="model"
              label="Model"
              items={models}
              name="model"
              value={formik.values.model}
              setFieldValue={formik.setFieldValue}
              touched={formik.touched}
              errors={formik.errors}
              getValues={handleModelChange}
              defaultName="Select Model"
              itemName="model_name"
            />
            <FormSelect
              controlId="version"
              label="Version"
              items={versions}
              name="version"
              value={formik.values.version}
              setFieldValue={formik.setFieldValue}
              touched={formik.touched}
              errors={formik.errors}
              getValues={formik.handleChange}
              defaultName="Select Version"
              itemName="version_name"
            />
            <FormSelect
              controlId="state"
              label="State"
              items={states}
              name="state"
              value={formik.values.state}
              setFieldValue={formik.setFieldValue}
              touched={formik.touched}
              errors={formik.errors}
              onChange={formik.handleChange}
              defaultName="Select State"
              itemName="state_name"
            />
            <Form.Group controlId="number">
              <Form.Label>Ex Showroom Price</Form.Label>
              <Form.Control
                type="number"
                name="ex_showroom_price"
                value={formik.values.number}
                onChange={formik.handleChange}
                placeholder="Enter number"
              />
            </Form.Group>
            <Form.Group controlId="rto">
              <Form.Label>RTO</Form.Label>
              <Form.Control
                type="number"
                name="rto"
                value={formik.values.rto}
                onChange={formik.handleChange}
                placeholder="Enter number"
              />
            </Form.Group>
            <Form.Group controlId="rtoexpense">
              <Form.Label>RTO Expense</Form.Label>
              <Form.Control
                type="number"
                name="rtoexpense"
                value={formik.values.rtoexpense}
                onChange={formik.handleChange}
                placeholder="Enter number"
              />
            </Form.Group>
            <Form.Group controlId="insurance">
              <Form.Label>Insurance</Form.Label>
              <Form.Control
                type="number"
                name="insurance"
                value={formik.values.insurance}
                onChange={formik.handleChange}
                placeholder="Enter number"
              />
            </Form.Group>
            <Form.Group controlId="standardaccessories">
              <Form.Label>Standard Accessories</Form.Label>
              <Form.Control
                type="number"
                name="standardaccessories"
                value={formik.values.standardaccessories}
                onChange={formik.handleChange}
                placeholder="Enter number"
              />
            </Form.Group>
            <Form.Group controlId="depotcharges">
              <Form.Label>Depot Charges</Form.Label>
              <Form.Control
                type="number"
                name="depotcharges"
                value={formik.values.depotcharges}
                onChange={formik.handleChange}
                placeholder="Enter number"
              />
            </Form.Group>
            <Form.Group controlId="rsa">
              <Form.Label>RSA</Form.Label>
              <Form.Control
                type="number"
                name="rsa"
                value={formik.values.rsa}
                onChange={formik.handleChange}
                placeholder="Enter number"
              />
            </Form.Group>
            <Form.Group controlId="handlingcharges">
              <Form.Label>Handling Charges</Form.Label>
              <Form.Control
                type="number"
                name="handlingcharges"
                value={formik.values.handlingcharges}
                onChange={formik.handleChange}
                placeholder="Enter number"
              />
            </Form.Group>

            <Button type="submit" className="mt-3" variant="primary">
              Submit
            </Button>
          </form>
        </Card>
      )}
    </div>
  );
}
