import * as Yup from "yup";
import { emailRegex, phoneRegex } from "../../../utility/constants";

const URL = /^(ftp|http|https):\/\/[^\s/$.?#].[^\s]*$/;

export const carDealerValidation = Yup.object({
  city_id: Yup.number().required("City name is required"),
  brand_id: Yup.number().required("Brand name is required"),
  dealer_name: Yup.string().required("Dealer name is required"),
  dealer_url: Yup.string().required("Dealer url is required"),
  dealer_address: Yup.string().required("Dealer address is required"),
  dealer_area: Yup.string(),
  dealer_map: Yup.string(),
  phone_number: Yup.string().matches(phoneRegex, "Phone number is not valid"),
  email_id: Yup.string().matches(emailRegex, "Email is not valid"),
  dealer_content: Yup.string(),
  dealer_location_url: Yup.string().url(),
});
