import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../request";

export function getTemplateList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}template/filtered/list`,
    payload,
    getConfig(token)
  );
}
export function addTemplate(payload, token) {
  return axios.post(`${BASE_URL_AUTOX}template/add`, payload, getConfig(token));
}

export function editTemplate(id, data, token) {
  return axios.put(
    `${BASE_URL_AUTOX}template/edit/${id}`,
    data,
    getConfig(token)
  );
}

export function getTemplateDetails(id, token) {
  return axios.get(
    `${BASE_URL_AUTOX}template/template-details/${id}`,
    getConfig(token)
  );
}

export function deleteTemplate(id, token) {
  return axios.delete(
    `${BASE_URL_AUTOX}template/delete/${id}`,
    getConfig(token)
  );
}

export default {
  getTemplateList,
  addTemplate,
  editTemplate,
  getTemplateDetails,
  deleteTemplate,
};
