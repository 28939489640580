import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import AuthLayout from "./AuthLayout";
import InputGroup from "../../components/InputGroup";
import { resetPassword } from "../../request";
import { useTranslation } from "react-i18next";
import { getUserId, passwordRegex } from "../../utility/constants";
import SubmitButton from "../../components/SubmitButton";
import LockIcon from "../../assets/icons/bootstrap-icons/auth-images/Lock.svg";

export default function ResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required(t("Password is required"))
      .min(8, t("Password must be at least 8 characters long"))
      .matches(passwordRegex, t("Password is not valid")),
    confirmPassword: Yup.string()
      .matches(passwordRegex, t("Confirm password is not valid"))
      .required(t("Confirm password is required"))
      .oneOf([Yup.ref("password")], t("Your passwords do not match")),
  });
  return (
    <AuthLayout>
      <main className="d-flex w-100 h-100">
        <div className="container">
          <div className="row h-100">
            <div className="col-sm-10 col-md-10 col-lg-10 col-xl-10 col-lg-10 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center mt-0">
                  <div className="auth-heading">Password Reset</div>
                  <p className="p-3 auth-message">
                    Please enter your new password
                  </p>
                </div>

                <div className="">
                  <div className="">
                    <div className="m-sm-3">
                      <div className="mb-3">
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          onSubmit={(values, { setSubmitting }) => {
                            const user_id = getUserId();
                            const payload = {
                              user_id,
                              user_password: values["password"],
                            };

                            resetPassword(payload)
                              .then((res) => {
                                navigate("/reset-password-success");
                              })
                              .catch((err) => console.log(err));
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleSubmit,
                            setFieldValue,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <div className="mb-3">
                                <InputGroup
                                  type="password"
                                  position="left"
                                  icon={LockIcon}
                                  placeholder={"New Password"}
                                  name={"password"}
                                  touched={touched}
                                  errors={errors}
                                  value={values["password"]}
                                  setFieldValue={setFieldValue}
                                  auth={true}
                                />
                              </div>
                              <div className="mb-3">
                                <InputGroup
                                  type="password"
                                  position="left"
                                  icon={LockIcon}
                                  placeholder={"Confirm Password"}
                                  name={"confirmPassword"}
                                  touched={touched}
                                  errors={errors}
                                  value={values["confirmPassword"]}
                                  setFieldValue={setFieldValue}
                                  auth={true}
                                />
                              </div>

                              <div className="d-grid gap-2 mt-5 mb-3">
                                <SubmitButton title="Submit" />
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </AuthLayout>
  );
}
