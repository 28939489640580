import { Accordion, Button, Card, Form } from "react-bootstrap";
import FormControl from "../../components/FormControl";
import JoditEditor, { Jodit } from "jodit-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import FormFieldErrorMsg from "../../components/FormFieldErrorMsg";
import DynamicSelect from "../CarUserReview/EditCarUserReview/DynamicSelect";
import FormSelect from "../../components/FormSelect";
import HeadingTwo from "../../components/HeadingTwo";
import {
  getAdminUserList,
  getCategoryList,
} from "../../pages/PostManagement/apiHandler";
import { getAdminType, getToken } from "../../utility/constants";
import ReactDatePicker from "react-datepicker";
import CarBikeLink from "../../pages/PostManagement/CarBikeLink";
import TagInput from "../../pages/PostManagement/TagInput";
import NestedCategoryList from "../../pages/PostManagement/CategoryList";
import MediaTab from "../../pages/PostManagement/Media/MediaTab";
import { convertTime } from "../../request";
import { ReactSVG } from "react-svg";
import UploadIcon from "../../assets/icons/bootstrap-icons/upload.svg";
import DisplayNestedCategoryList from "../../pages/PostManagement/DisplayCategoryList";
import { notifyError } from "../../utility/common";
import moment from "moment-timezone";
import StarRating from "../../pages/PostManagement/StarRating";
import DefaultProsAndConsTemplateContent from "../Template/ProsAndConsTemplate";
export default function RegularGalleryForm({
  values,
  touched,
  setFieldValue,
  errors,
  handleSubmit,
  type,
  adminType,
  setIsDraft,
  isSubmitting,
}) {
  const [categories, setCategories] = React.useState([]);
  const [propSelectedImage, setPropSelectedImage] = React.useState(null);
  const [author, setAuthor] = React.useState([]);
  const [photographer, setPhotographer] = React.useState([]);
  const [types, setTypes] = React.useState(values.type);
  const [showMediaTabModal, setShowMediaTabModal] = useState(false);
  const [mediaTabType, setMediaTabType] = useState(null);
  const [onSelectImageFn, setOnSelectImageFn] = useState(null);
  const token = getToken();
  const editor = useRef(null);
  const config = useMemo(
    () => ({
      readonly: false,
      minHeight: 600,
    }),
    []
  );
  useEffect(() => {
    setTypes(values.type);
  }, [values.type]);
  useEffect(() => {
    getCategoryList(token).then((res) => {
      setCategories(res.data.result);
    });
  }, [token]);

  useEffect(() => {
    const authorsList = async () => {
      const res = await getAdminUserList(token);
      setAuthor(res.data.data);

      const constant_photographer = [103, 74, 160, 164, 1];
      const photographer_list = res.data.data.filter((item) =>
        constant_photographer.includes(item.id)
      );
      console.log(photographer_list, "photographer_list");
      setPhotographer([
        { id: "-1", display_name: "Select Photography By" },
        ...photographer_list,
      ]); //setPhotographer(photographer_list);
    };
    authorsList();
  }, [token]);

  const handleImageInsert = (image) => {
    const imageUrl =
      image.image.startsWith("https://autox-gallery") ||
      image.image.startsWith("https://autox") ||
      image.image.startsWith("https://s3.ap-south-1")
        ? image.image
        : `https://autox.com/${image.image}`;

    setFieldValue(
      "post_content",
      `${values.post_content}<img src="${imageUrl}" alt="${image.title}" />` +
        (image.image_caption
          ? `<div class="img-caption">${image.image_caption}</div>`
          : "")
    );
  };
  const handleRemoveStory = (index) => {
    const { stories_images } = values;

    // Store the ID of the removed story if in edit mode
    if (type == "edit" && stories_images[index].storie_id) {
      const removedStoryId = stories_images[index].storie_id; // Assuming each story has an 'id' property
      const updatedDeleteStories = [...values.delete_stories, removedStoryId];

      // Update the delete_stories array
      setFieldValue("delete_stories", updatedDeleteStories);
    }

    // Remove the story from stories_images
    const updatedStories = stories_images.filter((_, i) => i !== index);
    setFieldValue("stories_images", updatedStories);
  };
  const handleVideoURLSubmit = (e) => {
    const videoUrl = e.target.value;
    const isValidUrl =
      /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/.test(videoUrl);

    if (isValidUrl) {
      setFieldValue("stories_images", [
        ...values.stories_images,
        {
          image: videoUrl,
          title: "",
          description: "",
          caption: "",
          order: values.stories_images.length + 1,
          type: "video",
        },
      ]);
      e.target.value = ""; // Clear the input after adding
    } else {
      console.error("Invalid YouTube URL");
    }
  };

  const handleAddImage = (media) => {
    setFieldValue("stories_images", [
      ...values.stories_images,
      {
        image: media.image,
        title: "",
        description: "",
        caption: "",
        order: values.stories_images.length + 1,
        type: "image",
      },
    ]);
  };

  const handlSliderImage = (image) => {
    setFieldValue("slider_images", [...values.slider_images, image]);
  };
  const FileSizeConvert = (bytes) => {
    const units = [
      { unit: "TB", value: Math.pow(1024, 4) },
      { unit: "GB", value: Math.pow(1024, 3) },
      { unit: "MB", value: Math.pow(1024, 2) },
      { unit: "KB", value: 1024 },
      { unit: "B", value: 1 },
    ];

    for (let i = 0; i < units.length; i++) {
      if (bytes >= units[i].value) {
        return (bytes / units[i].value).toFixed(1) + " " + units[i].unit;
      }
    }
    return "0 B"; // Fallback if no size found
  };

  const handleFeaturedImage = (image) => {
    const imageUrl =
      image.image.startsWith("https://autox-gallery") ||
      image.image.startsWith("https://autox") ||
      image.image.startsWith("https://s3.ap-south-1")
        ? image.image.startsWith("https://autox")
          ? image.image.replace("https://autox", "https://static.autox")
          : image.image
        : `https://static.autox.com/${image.image}`;

    const imgElement = new Image();
    imgElement.src = imageUrl;

    return new Promise((resolve) => {
      imgElement.onload = () => {
        try {
          // Check if the image is from Amazon S3 (or other valid URL)
          if (imageUrl.includes("amazonaws")) {
            const [width, height, img_size] = imageUrl
              .substring(
                imageUrl.lastIndexOf("-") + 1,
                imageUrl.lastIndexOf(".")
              )
              .split("x");

            const parsedWidth = parseInt(width, 10);
            const parsedHeight = parseInt(height, 10);

            // Validate dimensions: must be 1280x720
            if (parsedWidth === 1280 && parsedHeight === 720) {
              let size = !isNaN(img_size) && `${img_size} KB`;
              resolve({ width: parsedWidth, height: parsedHeight, size });
            } else {
              // Set image to null if the dimensions do not match
              resolve(null);
            }
          } else {
            // Fetch image and check dimensions for non-Amazon URLs
            fetch(imageUrl)
              .then((response) => response.blob())
              .then((blob) => {
                const size = FileSizeConvert(blob.size);
                const dimensions = `${imgElement.width} x ${imgElement.height}`;

                // Validate dimensions: must be 1280x720
                if (imgElement.width === 1280 && imgElement.height === 720) {
                  resolve({
                    width: imgElement.width,
                    height: imgElement.height,
                    size,
                    dimensions,
                  });
                } else {
                  // Set image to null if the dimensions do not match
                  resolve(null);
                }
              })
              .catch((error) => {
                console.error("Error fetching image:", error);
                resolve(null); // Return null if the fetch fails
              });
          }
        } catch (error) {
          console.error("Error processing image:", error);
          resolve(null); // Return null if any error occurs
        }
      };

      imgElement.onerror = () => {
        console.error("Error loading image:", imageUrl);
        resolve(null); // Return null if the image fails to load
      };
    }).then((imageDetails) => {
      // Set the field value with image details if valid, otherwise null
      if (imageDetails) {
        setFieldValue("featured_image", imageUrl);
      } else {
        notifyError("Image dimensions must be 1280x720");
        // setFieldValue("featured_image", null);
      }
    });
  };

  const handleRemoveImage = (index) => {
    const updatedImages = values.slider_images.filter((_, i) => i !== index);
    setFieldValue("slider_images", updatedImages);
  };

  const handleSubmitDraft = () => {
    setIsDraft(true);
    document.getElementById("submit-btn").click();
  };
  const handleSubmitButton = () => {
    setIsDraft(false);
    document.getElementById("submit-btn").click();
  };
  const handleDateChange = (date) => {
    // Convert selected date to IST
    const istDate = moment(date).tz("Asia/Kolkata", true).toDate();
    setFieldValue("publish_date", istDate);
  };

  const selectedDate = values.publish_date
    ? moment(values.publish_date).tz("Asia/Kolkata", true).toDate()
    : new Date();
  console.log(values, errors);
  const handleFileChange = (event, position) => {
    const files = event.currentTarget.files;
    const newImages = Array.from(files).map((file, index) => ({
      blob: URL.createObjectURL(file),
      file: file,
      caption: "",
      alt: file.name,
      position: position,
      order: values.images.length + index + 1,
    }));

    setFieldValue("images", [...values.images, ...newImages]);
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="post-wrapper">
          <div className="post-card-1">
            <Card className="p-3">
              <div className="p-3">
                <FormControl
                  controlId={"post_title"}
                  label={"Post Title (Min 35 Characters & Max characters 110)"}
                  type={"text"}
                  name={"post_title"}
                  value={values.post_title}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  isRequired={true}
                />
              </div>

              <div className="p-3" style={{ minHeight: "600px" }}>
                <label className="form-label">Content</label>
                <JoditEditor
                  ref={editor}
                  value={values.post_content}
                  onBlur={(newContent) =>
                    setFieldValue("post_content", newContent)
                  }
                  config={config}
                />
                <Form.Label className="fw-light d-flex justify-content-between">
                  <button
                    type="button"
                    className="add-button mat-0"
                    onClick={(e) => {
                      setFieldValue(
                        "post_content",
                        `${values.post_content}
                          ${DefaultProsAndConsTemplateContent[0]}`
                      );
                    }}
                  >
                    <span>Default</span>
                  </button>
                </Form.Label>
                <FormFieldErrorMsg
                  touched={touched}
                  errors={errors}
                  name={"post_content"}
                />
              </div>
            </Card>

            <Card className="p-3">
              <HeadingTwo title={"SEO Content"} className="mb-3" />
              <div>
                <FormControl
                  controlId={"seo_title"}
                  label={"SEO Title"}
                  type={"text"}
                  name={"seo_title"}
                  value={values.seo_title}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  errors={errors}
                />

                <FormControl
                  controlId={"seo_description"}
                  label={"SEO Description"}
                  as={"textarea"}
                  name={"seo_description"}
                  value={values.seo_description}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  rows={3}
                />

                <FormControl
                  controlId={"meta_keywords"}
                  label={"Meta Keywords"}
                  type={"text"}
                  name={"meta_keywords"}
                  value={values.meta_keywords}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  errors={errors}
                />

                <FormControl
                  controlId={"news_keyword"}
                  label={"News Keywords"}
                  type={"text"}
                  name={"news_keyword"}
                  value={values.news_keyword}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  errors={errors}
                />

                <FormControl
                  controlId={"cannonical"}
                  label={"Canonical URL"}
                  type={"text"}
                  name={"cannonical"}
                  value={values.cannonical}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  errors={errors}
                />
              </div>
            </Card>
            <Card className="p-3">
              <Card className="w-100">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item
                    eventKey="0"
                    className="card-height header_padding_top"
                  >
                    <Accordion.Header className="p-3 pb-1 ps-2 pt-4">
                      Upload Files
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="mb-3 d-flex gap-3">
                        <div className="mb-3">
                          <Form.Label>All</Form.Label>
                          <Form.Control
                            type="file"
                            multiple
                            onChange={(event) => handleFileChange(event, "All")}
                            className="form-control"
                          />
                        </div>
                        <div className="mb-3">
                          <Form.Label>Exterior</Form.Label>
                          <Form.Control
                            type="file"
                            multiple
                            onChange={(event) =>
                              handleFileChange(event, "exterior")
                            }
                          />
                        </div>
                        <div className="mb-3">
                          <Form.Label>Interior</Form.Label>
                          <Form.Control
                            type="file"
                            multiple
                            onChange={(event) =>
                              handleFileChange(event, "interior")
                            }
                          />
                        </div>
                        <div className="mb-3">
                          <Form.Label>Color</Form.Label>
                          <Form.Control
                            type="file"
                            multiple
                            onChange={(event) =>
                              handleFileChange(event, "color")
                            }
                          />
                        </div>
                      </div>
                      {values.images.map((image, index) => (
                        <div key={index} className="mb-3 d-flex">
                          <img
                            src={image.blob}
                            alt={image.alt}
                            style={{ width: "50px", height: "50px" }}
                          />
                          <Form.Control
                            type="text"
                            placeholder="Caption"
                            value={image.caption}
                            onChange={(e) =>
                              setFieldValue(
                                `images.${index}.caption`,
                                e.target.value
                              )
                            }
                            className="ms-2"
                          />
                          <Form.Control
                            type="text"
                            placeholder="Alt Text"
                            value={image.alt}
                            onChange={(e) =>
                              setFieldValue(
                                `images.${index}.alt`,
                                e.target.value
                              )
                            }
                            className="ms-2"
                          />
                          <Form.Select
                            value={image.position}
                            onChange={(e) =>
                              setFieldValue(
                                `images.${index}.position`,
                                e.target.value
                              )
                            }
                            className="ms-2"
                          >
                            <option value="All">All</option>
                            <option value="exterior">Exterior</option>
                            <option value="interior">Interior</option>
                            <option value="color">Color</option>
                          </Form.Select>
                          <Form.Control
                            type="number"
                            placeholder="Order"
                            value={image.order}
                            onChange={(e) =>
                              setFieldValue(
                                `images.${index}.order`,
                                e.target.value
                              )
                            }
                            className="ms-2"
                          />
                          <Button
                            variant="danger"
                            onClick={() => handleRemoveImage(index)}
                            className="ms-2"
                          >
                            Remove
                          </Button>
                        </div>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Card>
            </Card>
          </div>
          <div className="post-card-2">
            <Card className="p-3">
              <HeadingTwo
                title={"Publish"}
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />

              {type == "edit" && (
                <>
                  <Button
                    className={`mb-1 ${
                      values.status == 1
                        ? "btn-success"
                        : values.status == 2
                        ? "btn-danger"
                        : "btn-warning"
                    }`}
                    // href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/${
                    //   values.parent_category_slug ? `${values.parent_category_slug}/` : ''
                    // }${values.category_slug}/${values.slug}`}
                    target="_blank"
                    disabled={true}
                  >
                    {values.status == 1
                      ? "Live"
                      : values.status == 2
                      ? "Scheduled"
                      : values.status == 0
                      ? "Draft"
                      : ""}
                  </Button>
                  <Button
                    className="btn-secondary"
                    href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/${
                      values.parent_category_slug
                        ? `${values.parent_category_slug}/`
                        : ""
                    }${values.category_slug}/${values.slug}`}
                    target="_blank"
                    // onClick={() => handleSubmitDraft()}
                  >
                    Preview
                  </Button>
                </>
              )}
              <div className="row p-3">
                <div className="row checkbox-container mb-2">
                  <Form.Check
                    type="checkbox"
                    id="publish"
                    label="Publish Immediately"
                    checked={values.status == 1}
                    onChange={() => setFieldValue("status", 1)}
                  />
                </div>
                <div className="row checkbox-container mb-2">
                  <Form.Check
                    type="checkbox"
                    id="schedule"
                    label="Schedule Publish"
                    checked={values.status == 2}
                    onChange={() => setFieldValue("status", 2)}
                  />
                </div>
                {values.status == 2 && (
                  <div className="w-100">
                    <ReactDatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMMM dd yyyy h:mm aa"
                      className="w-100"
                    />
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-between flex-wrap">
                <Button
                  variant="btnBlue"
                  className="d-flex align-items-center justify-content-between"
                  type="button"
                  disabled={
                    !(values.status === 1 || values.status === 2) ||
                    isSubmitting
                  } // Disable if submitting
                  onClick={() => handleSubmitButton()}
                >
                  Publish
                </Button>
                <Button
                  type="submit"
                  id="submit-btn"
                  className="btn-secondary d-none"
                  disabled={isSubmitting} // Disable when submitting
                >
                  submit
                </Button>
                <Button
                  type="button"
                  className="btn-secondary"
                  disabled={isSubmitting} // Disable when submitting
                  onClick={() => {
                    setIsDraft(true); // Mark as draft
                    handleSubmit(); // Trigger the form submission
                  }}
                >
                  Save Draft
                </Button>
              </div>
            </Card>

            <Card className="p-3">
              <HeadingTwo
                title={"Push Notification"}
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />
              <Form.Check
                type="checkbox"
                label="Push Notification"
                name="push_notification"
                checked={values.push_notification}
                onChange={(e) =>
                  setFieldValue("push_notification", e.target.checked)
                }
                className="custom-checkbox"
              />
            </Card>

            <Card className="p-3">
              <HeadingTwo
                title={"Tags"}
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />
              <TagInput
                name="tags"
                values={values.tags || []}
                setFieldValue={setFieldValue}
              />
              <FormFieldErrorMsg
                touched={touched}
                errors={errors}
                name="tags"
              />
            </Card>

            {(adminType === "editor admin" || adminType === "super admin") && (
              <Card className="p-3">
                <HeadingTwo
                  title={"Author"}
                  type="dashboard"
                  showHeader={true}
                  pointerOnHover={true}
                />
                <FormSelect
                  controlId={"author"}
                  label={"Author"}
                  placeholder={"Select Author"}
                  touched={touched}
                  errors={errors}
                  itemName={"display_name"}
                  name={"author_id"}
                  setFieldValue={setFieldValue}
                  value={values.author_id}
                  items={author}
                  showDefault={true}
                  defaultName={"Select Author"}
                />
              </Card>
            )}

            <Card className="p-3">
              <HeadingTwo
                title={"Featured Image"}
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />

              {values.featured_image.length > 0 && (
                <img
                  src={
                    values.featured_image.startsWith("https://autox") ||
                    values.featured_image.startsWith("https://autox-gallery") ||
                    values.featured_image.startsWith("https://s3.ap-south-1") ||
                    values.featured_image.startsWith("https://static.autox.com")
                      ? values.featured_image.startsWith("https://autox.com")
                        ? values.featured_image.replace(
                            "https://autox.com",
                            "https://static.autox.com"
                          )
                        : values.featured_image
                      : `https://static.autox.com/${values.featured_image}`
                  }
                  alt="featured image"
                  className="w-100"
                  onClick={() => {
                    setMediaTabType("featured");
                    setPropSelectedImage(
                      values.featured_image.startsWith("https://autox") ||
                        values.featured_image.startsWith(
                          "https://autox-gallery"
                        ) ||
                        values.featured_image.startsWith(
                          "https://s3.ap-south-1"
                        )
                        ? values.featured_image
                        : `https://static.autox.com/${values.featured_image}`
                    );
                    setShowMediaTabModal(true);
                  }}
                />
              )}
              <div className="mt-3 px-3">
                <button
                  type="button"
                  onClick={() => {
                    setPropSelectedImage(null);
                    setOnSelectImageFn(() => handleFeaturedImage);
                    setMediaTabType("featured");
                    setShowMediaTabModal(true);
                  }}
                  className="export-button d-flex align-items-center text-nowrap justify-content-between me-2 mb-2 text-white px-3"
                >
                  <ReactSVG
                    height="18px"
                    width="18px"
                    src={UploadIcon}
                    className="mr-2"
                  />{" "}
                  Add Image
                </button>
              </div>
              <span>Size Max 500 Kb & Dimension: 1280 x 720</span>
              <FormFieldErrorMsg
                touched={touched}
                errors={errors}
                name={"featured_image"}
              />
            </Card>
            <Card className="p-3">
              <HeadingTwo
                title={"Slideshow"}
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />
              <Form.Check
                type="checkbox"
                label="Slideshow"
                name="slideshow"
                checked={values.slider}
                onChange={(e) => setFieldValue("slider", e.target.checked)}
                className="custom-checkbox-lg"
              />
            </Card>
          </div>
        </div>
        <MediaTab
          propShow={showMediaTabModal}
          propSetShow={setShowMediaTabModal}
          propSelectedImage={propSelectedImage}
          onSelectImage={onSelectImageFn}
          type={mediaTabType}
          defaultActiveKey="library"
        />
      </form>
    </>
  );
}
