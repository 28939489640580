import { useState } from "react";
import { ReactSVG } from "react-svg";
import * as XLSX from "xlsx";
import DownloadIcon from "../assets/icons/bootstrap-icons/button-icons/download white.svg";
import {
  addBikeCityImport,
  addCarCityImport,
} from "../pages/NewCar/CarVersion/apiHandler";
import { notifySuccess } from "../utility/common";

export default function ImportCarCityPrice({ setReload, type }) {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  // const handleFileUpload = async () => {
  //   if (!file) {
  //     return;
  //   }

  //   const reader = new FileReader();

  //   reader.onload = async (e) => {
  //     const data = e.target.result;
  //     const workbook = XLSX.read(data, { type: "binary" });
  //     const sheetName = workbook.SheetNames[0];
  //     const worksheet = workbook.Sheets[sheetName];

  //     const options = {
  //       header: 1,
  //       defval: "",
  //     };

  //     const json = XLSX.utils.sheet_to_json(worksheet, options);
  //     const finalData = json.slice(1).map((row) => ({
  //       version_id: row[0],
  //       city_id: row[1],
  //       ex_showroom_price: row[2] || 0,
  //       rto: row[3] || 0,
  //       insurance: row[4] || 0,
  //       registration: row[5] || 0,
  //       handling_logistic: row[6] || 0,
  //       tcs: row[7] || 0,
  //       mcd: row[8] || 0,
  //       hypothecation: row[9] || 0,
  //       ecc: row[10] || 0,
  //       incidental: row[11] || 0,
  //       number_plate: row[12] || 0,
  //       fast_tag: row[13] || 0,
  //       road_safety_tax: row[14] || 0,
  //     }));

  //     try {
  //       const response = await addCarCityImport(finalData);
  //       if (response.status === 200) {
  //         notifySuccess("Price imported successfully");
  //         setFile(null);
  //         setReload(true);
  //       } else {
  //         console.error("Error importing data:", response.data);
  //       }
  //     } catch (error) {
  //       console.error("Error uploading data:", error);
  //     }
  //   };

  //   reader.readAsBinaryString(file);
  // };

  const handleFileUpload = async () => {
    if (!file) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append("price_import", file);
      let response;
      if (type == "car") {
        response = await addCarCityImport(formData);
      } else if (type == "bike") {
        response = await addBikeCityImport(formData);
      }
      if (response.status === 200) {
        notifySuccess("Price imported successfully");
        setFile(null);
        setReload(true);
      } else {
        console.error("Error importing data:", response.data);
      }
    } catch (error) {
      console.error("Error uploading data:", error);
    }
  };
  return (
    <>
      <input
        type="file"
        className="form-control shadow-none file-input m-0 me-3"
        onChange={handleFileChange}
      />
      <button
        className="add-button d-flex align-items-center justify-content-between me-2 mb-2 px-3"
        onClick={handleFileUpload}
      >
        <ReactSVG
          src={DownloadIcon}
          className="mr-2"
          height="18px"
          width="18px"
        />
        Import Data
      </button>
    </>
  );
}
