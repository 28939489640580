import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import { carVersionShowOnLaunch } from "../../../utility/constants";
import FormSelect from "../../../components/FormSelect";
import FormControl from "../../../components/FormControl";
import { ErrorMessage } from "../../../components/FormArrayFieldErrorMsg";
import FormDate from "../../../components/FormDate";

export default function CreateCarVersionSubForm({
  key,
  index,
  versionProp,
  touched,
  errors,
  values,
  setFieldValue,
  handleSubmit,
  name,
  arrayHelpers,
}) {
  return (
    <div className="col-12 row">
      <div className="col-3">
        <FormControl
          controlId={"versionName"}
          label={"Version Name"}
          placeholder={"Version Name"}
          touched={touched}
          errors={errors}
          name={`${name}.${index}.version_name`}
          setFieldValue={setFieldValue}
          isRequired={true}
          arrayType={true}
          index={index}
          parentName={"version_data"}
          fieldName={"version_name"}
        />
        <ErrorMessage name={`version_data.${index}.version_name`} />
      </div>

      <div className="col-3">
        <FormControl
          controlId={"versionUrl"}
          label={"Version Url"}
          placeholder={"Enter Version Url"}
          touched={touched}
          errors={errors}
          name={`${name}.${index}.version_url`}
          setFieldValue={setFieldValue}
        />
        <ErrorMessage name={`version_data.${index}.version_url`} />
      </div>
      <div className="col-2">
        <FormSelect
          controlId={"showOnLaunch"}
          label={"Show On Launch"}
          defaultName={"Select"}
          setFieldValue={setFieldValue}
          items={carVersionShowOnLaunch}
          itemName={"value"}
          name={`${name}.${index}.show_on_launch`}
          touched={touched}
          errors={errors}
          className="mb-3"
          showDefault={false}
        />
        <ErrorMessage name={`version_data.${index}.show_on_launch`} />
      </div>

      <div className="col-3">
        <FormDate
          controlId={`launchDate-${index}`}
          label={"Launch Date (yyyy-mm-dd)"}
          value={values[name][index].launch_date}
          name={`${name}.${index}.launch_date`}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
        />
        <ErrorMessage name={`version_data.${index}.launch_date`} />
      </div>

      {index === 0 ? (
        <>
          <div
            className={clsx(
              "mb-3",
              { "mb-4": errors.version_data?.[index] },
              "col-1",
              "d-flex",
              "align-items-end"
            )}
          >
            <button
              className="version-button add-more align-end"
              type="button"
              onClick={() =>
                arrayHelpers.push({
                  version_name: "",
                  version_url: "",
                  show_on_launch: 0,
                  launch_date: "",
                  version_status: 1,
                })
              }
            >
              Add More
            </button>
          </div>
        </>
      ) : (
        <>
          <div
            className={clsx(
              "mb-3",
              { "mb-4": errors.version_data?.[index] },
              "col-1",
              "d-flex",
              "align-items-end"
            )}
          >
            <button
              type="button"
              className="version-button remove-btn align-end"
              onClick={() => arrayHelpers.remove(index)}
            >
              Remove
            </button>
          </div>
        </>
      )}
    </div>
  );
}
