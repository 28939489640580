import { useState } from "react";
import Breadcrumbs from "../../../Breadcrumbs";
import Datatable from "../../../components/Table";
import SidebarAPI from "./SidebarAPI";
import getSidebarList from "./SidebarAPI";
import HeadingTwo from "../../../components/HeadingTwo";
import CarModelBtn from "../../../components/CarModelBtn";
import PlusLargeIcon from "../../../assets/icons/bootstrap-icons/plus-lg.svg";
import { SideBarColumn } from "./SideBarColumn";
import { useNavigate } from "react-router-dom";
export default function SideBar() {
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const reloadTable = () => {
    setReload(!reload);
  };
  return (
    <>
      <Breadcrumbs />
      <div className="card p-3">
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-4 col-6 d-flex justify-content-between mb-2 sub-heading py-3">
          <HeadingTwo
            title={`Sidebar List (${totalRecords})`}
            type="dashboard"
            showHeader={true}
            pointerOnHover={true}
          />
          <CarModelBtn
            label={"Add Sidebar"}
            icon={PlusLargeIcon}
            className="add-button d-flex align-items-center justify-content-between mb-2 px-3"
            onClick={() => navigate("/add-sidebar")}
          />
        </div>
        <Datatable
          api={SidebarAPI.getSidebarList}
          columns={SideBarColumn(reloadTable, currentPage, itemsPerPage)}
          search={""}
          defaultSortColumn={""}
          reload={reload}
          setReload={setReload}
          setTotalRecords={setTotalRecords}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
          isDraggable={true}
          column_ordering={""}
          //   styling={FAQcustomStyles}
        />
      </div>
    </>
  );
}
