import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getToken, renderDate } from "../../utility/constants";
import { notifyError, notifySuccess } from "../../utility/common";
import Breadcrumbs from "../../Breadcrumbs";
import EditViewDelete from "../../components/EditViewDeleteReviewPassword";

import { ReactSVG } from "react-svg";
import HeadingTwo from "../../components/HeadingTwo";
import Datatable from "../../components/Table";
import DeleteConfirmationModalComponent from "../../components/DeleteConfirmationModalComponent";
import apiHandler from "./apiHandler";
import { Card } from "react-bootstrap";
import PlusLargeIcon from "../../assets/icons/bootstrap-icons/plus-lg.svg";
export default function TemplateList() {
  const [reloadTable, setReloadTable] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState();
  const navigate = useNavigate();
  const token = getToken();

  const onEditInner = (id) => {
    navigate("/template/edit-template/" + id);
  };

  const onDeleteInner = async () => {
    try {
      await apiHandler.deleteTemplate(templateToDelete, token);
      setShowDeleteModal(false);
      setReloadTable(true);
      notifySuccess("Template deleted successfully");
    } catch (error) {
      setShowDeleteModal(false);
      notifyError(error.message);
    }
  };

  const columns = [
    {
      name: "ID",
      sortable: false,
      selector: (row) => row.id,
      width: "100px",
    },
    {
      name: "Template Name",
      sortable: true,
      selector: (row) => row.template_name,
      width: "350px",
    },
    {
      name: "Subject",
      sortable: true,
      selector: (row) => row.subject,
      width: "400px",
    },

    {
      name: "Created Date",
      sortable: true,
      selector: (row) => renderDate(row.created_date),
      width: "150px",
    },
    {
      name: "Action",
      sortable: false,
      width: "auto",
      cell: (row) => (
        <EditViewDelete
          id={row.id}
          onEdit={onEditInner}
          onDelete={() => {
            setTemplateToDelete(row.id);
            setShowDeleteModal(true);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <Breadcrumbs />
        <button
          className="add-service-centre-button d-flex align-items-center justify-content-between mb-2 px-3"
          onClick={() => navigate("/template/add-template")}
        >
          <ReactSVG
            src={PlusLargeIcon}
            height="18px"
            width="18px"
            className="mr-2"
          />{" "}
          Add Template
        </button>
      </div>
      <Card className="p-3">
        <div>
          <HeadingTwo
            title={"Template List"}
            type={"dashboard"}
            showHeader
            pointerOnHover
          />
        </div>
        <div>
          <Datatable
            columns={columns}
            api={apiHandler.getTemplateList}
            reload={reloadTable}
            setReload={setReloadTable}
            setTotalRecords={setTotalPages}
          />
          <DeleteConfirmationModalComponent
            show={showDeleteModal}
            type={"delete"}
            handleClose={() => setShowDeleteModal(false)}
            setDeleteConfirm={onDeleteInner}
          />
        </div>
      </Card>
    </>
  );
}
