import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ReactSVG } from "react-svg";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import AddCarRight from "./AddCarModel/AddCarRight";
import AddCarLeft from "./AddCarModel/AddCarLeft";
import {
  getCarBodyType,
  createModel,
  getBrandNameList,
  getFAQlist,
} from "../../../request";
import Breadcrumbs from "../../../Breadcrumbs";
import BackIcon from "../../../assets/icons/bootstrap-icons/Back_Arrow.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import { validationSchemaObject } from "./AddCarModel/AddCarModelValidation";
import { useTranslation } from "react-i18next";
import { convertTimestampToDate, getToken } from "../../../utility/constants";
import { notifyError, notifySuccess } from "../../../utility/common";
export default function AddCarModel() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");

  const [bodyTypeDetails, setBodyTypeDetails] = useState([]);
  const [brandDetails, setBrandDetails] = useState([]);

  const [error, setError] = useState(null);

  const [fileName, setFileName] = useState("No file chosen");
  const [editorValue, setEditorValue] = useState("");
  const [brochureFileName, setBrochureFileName] = useState("No file chosen");

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedBrochure, setSelectedBrochure] = useState(null);
  const [modelFAQ, setModelFAQ] = useState([]);
  const [customFAQs, setCustomFAQs] = useState([]);

  const token = getToken();

  const handleUpdateFAQ = (index, field, value) => {
    const updatedFAQs = [...customFAQs];
    updatedFAQs[index][field] = value;
    setCustomFAQs(updatedFAQs);
  };

  const handleAddFAQInput = () => {
    setCustomFAQs([...customFAQs, { question: "", answer: "" }]);
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Check file size (max 200 KB)
      if (selectedFile.size <= 200 * 1024) {
        const img = new Image();
        const objectUrl = URL.createObjectURL(selectedFile);

        img.onload = () => {
          // Check image dimensions (500x261)
          console.log(img);
          if (img.width === 500 && img.height === 261) {
            setSelectedFile(selectedFile);
            setFileName(selectedFile.name);
          } else {
            setSelectedFile(null);
            toast.error("Image dimensions should be 500x261.");
          }
          URL.revokeObjectURL(objectUrl);
        };

        img.onerror = () => {
          setSelectedFile(null);
          toast.error("Invalid image file.");
          URL.revokeObjectURL(objectUrl);
        };

        img.src = objectUrl;
      } else {
        setSelectedFile(null);
        toast.error("File size should be less than 200 KB.");
      }
    }
  };

  const handleBrochureFileChange = (e) => {
    const selectedBrochure = e.target.files[0];
    const allowedTypes = ["application/pdf"];
    if (selectedBrochure && allowedTypes.includes(selectedBrochure.type)) {
      if (selectedBrochure.size <= 1024 * 10 * 1024) {
        setSelectedBrochure(selectedBrochure);
        setBrochureFileName(
          selectedBrochure ? selectedBrochure.name : "No file chosen"
        );
      } else {
        notifyError("File size should be less than 10 MB");
        setSelectedBrochure(null);
        setBrochureFileName("No file chosen");
      }
    } else {
      setSelectedBrochure(null);
      setBrochureFileName("No file chosen");
    }
  };

  const onDescriptionChange = (value) => {
    setValue(value);
  };
  const onEditorialChange = (value) => {
    setEditorValue(value);
  };

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const fetchBrandsParams = {
          page: 1,
          limit: 10,
          sortBy: "brand_ordering",
          sortOrder: "asc",
          search: "",
        };
        const brandRes = await getBrandNameList(token, fetchBrandsParams);
        const brandResponse = brandRes.data.result;
        setBrandDetails(brandResponse);
      } catch (error) {
        setError(error);
      }
    };

    const fetchCarBodyType = async () => {
      try {
        const carBodyTypeParams = {
          sortBy: "",
          sortOrder: "",
        };
        const bodyTypeRes = await getCarBodyType(token, carBodyTypeParams);
        const bodyTypeResponse = bodyTypeRes.data.result;
        setBodyTypeDetails(bodyTypeResponse);
      } catch (error) {
        setError(error);
      }
    };
    const fetchModelFAQ = async () => {
      try {
        const payload = {
          page: 1,
          limit: "",
          sortBy: "",
          sortOrder: "",
          search: "",
          searchFields: "",
          offset: "",
        };
        const modelFaq = await getFAQlist(token, payload);
        setModelFAQ(modelFaq.data.result);
      } catch (error) {
        setError(error);
      }
    };

    setLoading(true);

    fetchBrands();
    fetchCarBodyType();
    fetchModelFAQ();
  }, []);

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    values,
    errors,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: {
      model_name: "",
      model_url: "",
      car_type: -1,
      keep_discontinued: 0,
      body_type: -1,
      popular_position: 0,
      model_date: new Date().toISOString().slice(0, 10),
      updated_launch_date: new Date().toISOString().slice(0, 10),
      model_content: "",
      colors: "",
      model_preview: "",
      model_prosncons: "",
      starting_price: 0,
      ending_price: 0,
      gallery_short_code: "",
      brand_id: 0,
      brand_name: "",
      meta_title: "",
      meta_description: "",
      meta_keyword: "",
      canonical_url: "",
      breadcrumbs: "",
      robots_follow: "",
      robots_index: "",
      model_editorial_review: "",
      electric_car: 0,
      multiple_vehicle: [],
      custom_faq: [],
      faq: [],
      model_image: "",
    },

    validationSchema: validationSchemaObject,
    onSubmit: async (values, action) => {
      try {
        const formData = new FormData();
        const updateModelDetails = {
          ...values,
          model_content: value.toString("html"),
          model_editorial_review: editorValue.toString("html"),
          model_date: `${values.model_date}`,
          updated_launch_date: `${values.model_date}`,
          // model_image: selectedFile,
          keep_discontinued: 0,
          gallery_short_code: "test",
          breadcrumbs: "test",
          faq: JSON.stringify(values.faq),
          custom_faq: JSON.stringify(
            customFAQs.filter(
              (item) => item.question.trim() !== "" && item.answer.trim() !== ""
            )
          ),
          electric_car: values.electric_car ? 1 : 0,
          multiple_vehicle: JSON.stringify(values.multiple_vehicle),
          model_brochure: selectedBrochure,
        };
        const requiredFields = [
          // { name: "model_name", type: "string" },
          // { name: "model_url", type: "string" },
          // { name: "car_type", type: "number" },
          { name: "model_image", type: "binary" },
          // { name: "car_type", type: "number" },
          // { name: "body_type", type: "number" },
          // { name: "popular_position", type: "number" },
          // { name: "colors", type: "string" },
          // { name: "brand_id", type: "number" },
        ];

        const missingField = requiredFields.find(({ name, type }) => {
          const value = values[name];
          return (
            !value ||
            value === "" ||
            (type === "number" && typeof value !== "number")
          );
        });

        if (missingField) {
          toast.error(`${missingField.name.replace(/_/g, " ")} is required`);
          return;
        }

        const keysToRemove = ["min_price", "max_price"];
        const updatedObject = Object.fromEntries(
          Object.entries(updateModelDetails).filter(
            ([key]) => !keysToRemove.includes(key)
          )
        );

        Object.entries(updatedObject).forEach(([key, value]) => {
          formData.append(key, value);
        });

        setLoading(true);
        const response = await createModel(token, formData);
        if (response.status === 200) {
          setLoading(false);
          notifySuccess(response?.data.message);
          action.resetForm();
          setTimeout(() => {
            navigate(`/car-models/edit-car-model/${response.data.result.id}`);
          }, 2000);
        } else {
          const responseData = response.response
            ? response.response.data
            : response.data;
          const errorMessage = responseData.message || "An error occurred";
          notifyError(errorMessage);
        }
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : "An error occurred";
        notifyError(errorMessage);
      }
    },
  });

  console.log(values, "values");

  return (
    <div className="row dashboard">
      <div className="row">
        <div className="col-xl-11 col-sm-11">
          <Breadcrumbs />
        </div>
        <div className="col-xl-1 col-sm-1">
          <button
            className="d-flex back-button py-2 px-3 mb-4 btn btn-primary"
            onClick={() => navigate("/car-models")}
          >
            <ReactSVG src={BackIcon} className="me-1" />
            Back
          </button>
        </div>
      </div>

      <Form onSubmit={handleSubmit}>
        <div className="row">
          <AddCarLeft
            touched={touched}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            value={value}
            onDescriptionChange={onDescriptionChange}
            editorValue={editorValue}
            onEditorialChange={onEditorialChange}
            modelFAQ={modelFAQ}
            customFAQs={customFAQs}
            setCustomFAQs={setCustomFAQs}
            handleUpdateFAQ={handleUpdateFAQ}
            handleAddFAQInput={handleAddFAQInput}
          />
          {console.log(customFAQs, "customFAQs")}
          <div className="col-xl-3  col-sm-4">
            <Accordion defaultActiveKey="0" className="mb-4">
              <Accordion.Item eventKey="0">
                <Accordion.Header className=" ps-2">Publish</Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex justify-content-between flex-wrap">
                    <Button
                      type="submit"
                      variant="btnBlue"
                      className="d-flex align-items-center justify-content-between my-3"
                    >
                      Save
                    </Button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <AddCarRight
              touched={touched}
              errors={errors}
              values={values}
              setFieldValue={setFieldValue}
              brandDetails={brandDetails}
              bodyTypeDetails={bodyTypeDetails}
              handleFileChange={handleFileChange}
              fileName={fileName}
              selectedFile={selectedFile}
              handleBrochureFileChange={handleBrochureFileChange}
              brochureFileName={brochureFileName}
            />
          </div>
        </div>
      </Form>
      <ToastContainer />
    </div>
  );
}
