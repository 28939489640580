import React from "react";
import { Form } from "react-bootstrap";

function FilterDropdown({
  isVisible,
  options,
  selectedValues,
  selectedTypes,
  apply,
  clearAll,
  buttonVisible = true,
}) {
  if (!isVisible) return null;

  const handleChange = (event, type) => {
    const selectedValue = event.target.value;
    let parsedValue;

    if (type === "brand") {
      parsedValue = JSON.parse(selectedValue);
    } else {
      parsedValue = { id: selectedValue };
    }

    selectedTypes(type, parsedValue);
  };

  return (
    <div className="filter-dropdown">
      <Form>
        <div className="filter-options">
          {options.map((option) => (
            <Form.Select
              key={option.key}
              className="form-select-sm mb-2"
              onChange={(event) => handleChange(event, option.type)}
            >
              <option value="" selected>
                {option.label}
              </option>
              {option.values.map((value) => (
                <option
                  key={value.id}
                  value={
                    option.key === "brand"
                      ? JSON.stringify({
                          id: value.id,
                          brand_url: value.brand_url,
                        })
                      : value.id
                  }
                >
                  {value[option.searchKey]}
                </option>
              ))}
            </Form.Select>
          ))}
        </div>
        <div
          className="filter-buttons"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <button className="filter-clear-all" type="reset" onClick={clearAll}>
            Clear All
          </button>
          {buttonVisible && (
            <button
              className="filter-apply"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                apply();
              }}
            >
              Apply
            </button>
          )}
        </div>
      </Form>
    </div>
  );
}

export default FilterDropdown;
