import { useNavigate } from "react-router-dom";
import EditViewDelete from "../../components/EditViewDeleteReviewPassword";
import { deleteCarGallery } from "./apiHandler";
import { notifyError, notifySuccess } from "../../utility/common";
import DeleteConfirmationModalComponent from "../../components/DeleteConfirmationModalComponent";
import { useState } from "react";

const CarGalleryColumn = (reloadTable, token) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const deleteImage = async () => {
    if (deleting) return;
    try {
      setDeleting(true);
      const response = await deleteCarGallery(deleteId, token);
      if (response.status === 200) {
        notifySuccess("Image deleted successfully");
        reloadTable();
        setModal(false);
        setDeleting(false);
      }

      setDeleting(false);
    } catch (error) {
      notifyError("Failed to delete image");
      setDeleting(false);
      setModal(false);
    }
  };
  const onEdit = (id) => {
    navigate(`/car-gallery-edit/${id}`);
  };
  const onDelete = (id) => {
    if (deleting) return;
    console.log(id);
    setDeleteId(id);
    setModal(true);
  };
  const onView = (row) => {
    window.open(
      `${process.env.REACT_APP_FRONTEND_BASE_URL}/new-cars/${row.brand_url}/${row.model_url}/photos`,
      "_blank"
    );
  };
  return [
    {
      name: "Sr.No",
      selector: (row) => row.gallery_id,
      width: "auto",
    },
    {
      name: "Title",
      selector: (row) => `${row.brand_name}-${row.model_name} Photo Gallery`,
    },
    {
      name: "Action",
      width: "auto",
      cell: (row) => (
        <div className="d-flex align-items-center justify-content-center">
          <EditViewDelete
            id={row.gallery_id}
            onEdit={() => onEdit(row.gallery_id)}
            onView={() => onView(row)}
            onDelete={() => onDelete(row.gallery_id)}
          />
          <DeleteConfirmationModalComponent
            heading="Delete Image"
            message="Are you sure you want to delete this image?"
            show={modal && deleteId === row.gallery_id}
            handleClose={() => setModal(false)}
            setDeleteConfirm={deleteImage}
            type="delete"
          />
        </div>
      ),
    },
  ];
};

export default CarGalleryColumn;
