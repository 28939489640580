import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

const DisplayNestedCategoryList = ({
  categories,
  values,
  setFieldValue,
  fieldName = "display_category",
  parentFieldName = "parent_category",
}) => {
  // Ensure values[fieldName] is always an array (default to empty array if undefined or null)
  const [selectedCategories, setSelectedCategories] = useState(() => {
    // Initialize state from values if it's provided, otherwise default to an empty array
    return Array.isArray(values) ? values : [];
  });
  console.log("selectedCategories", selectedCategories);
  // Effect to sync selectedCategories with the initial values on mount
  useEffect(() => {
    if (Array.isArray(values)) {
      setSelectedCategories(values);
    }
  }, [values, fieldName]);

  // Function to handle adding/removing category from the selection array
  const handleCategoryChange = (categoryId, isChecked, parentId) => {
    let newCategoryIds = [...selectedCategories]; // Make a copy of the current selected categories

    if (isChecked) {
      // Add category ID to the array if it's not already included
      if (!newCategoryIds.includes(categoryId)) {
        newCategoryIds.push(categoryId);
      }
    } else {
      // Remove category ID from the array
      newCategoryIds = newCategoryIds.filter((id) => id !== categoryId);
    }

    // Update selected categories and parent category field
    setFieldValue(fieldName, newCategoryIds);
    setSelectedCategories(newCategoryIds);

    // Only update the parent category field if the category has no children (is a leaf node)
    if (!parentId) {
      setFieldValue(parentFieldName, null); // Reset parent if it's a leaf node
    } else {
      setFieldValue(parentFieldName, parentId); // Keep track of the parent category
    }
  };

  const renderCategory = (
    category,
    level = 0,
    visitedCategories = new Set(),
    parentId = null
  ) => {
    if (visitedCategories.has(category.id)) {
      return null; // Prevent infinite loop in case of circular references
    }
    visitedCategories.add(category.id);

    const isParent = category.children && category.children.length > 0;

    return (
      <div key={category.id} style={{ marginLeft: level * 20 }}>
        <div className="checkbox-container mb-2">
          <Form.Check
            type="checkbox"
            id={`${fieldName}-${category.id}`}
            onChange={(e) => {
              const isChecked = e.target.checked;
              handleCategoryChange(category.id, isChecked, parentId);
            }}
            name={fieldName}
            value={category.id}
            checked={selectedCategories.includes(category.id)} // Check if this category ID is in the array
            disabled={isParent} // Disable checkbox if it's a parent (has children)
          />
          <label htmlFor={`${fieldName}-${category.id}`}>
            {category.category_name}
          </label>
        </div>
        {category.children && category.children.length > 0 && (
          <div style={{ marginLeft: 20 }}>
            {category.children.map((child) =>
              renderCategory(child, level + 1, visitedCategories, category.id)
            )}
          </div>
        )}
      </div>
    );
  };

  // Build the category tree
  const buildCategoryTree = (categories) => {
    const categoryMap = {};
    categories.forEach((category) => {
      categoryMap[category.id] = { ...category, children: [] };
    });

    const categoryTree = [];
    categories.forEach((category) => {
      if (category.parent == 0) {
        categoryTree.push(categoryMap[category.id]);
      } else if (categoryMap[category.parent]) {
        categoryMap[category.parent].children.push(categoryMap[category.id]);
      }
    });

    return categoryTree;
  };

  const categoryTree = buildCategoryTree(categories);

  return (
    <div
      className="mb-3"
      style={{ overflowY: "auto", maxHeight: "300px", padding: "10px" }}
    >
      {categoryTree.map((category) => renderCategory(category))}
    </div>
  );
};

export default DisplayNestedCategoryList;
