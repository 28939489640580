import * as Yup from "yup";

const SubscriptionValidationSchema = Yup.object().shape({
  title: Yup.string().max(255),
  first_name: Yup.string().max(255).required("Please enter first name"),
  last_name: Yup.string().max(255).required("Please enter last name"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Please enter email"),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Please enter a valid 10-digit phone number")
    .required("Please enter phone number"),
  telephone: Yup.string()
    .matches(/^[0-9]{10,15}$/, "Please enter a valid telephone number")
    .nullable(),
  designation: Yup.string().max(255).nullable(),
  company: Yup.string().max(255).nullable(),
  state: Yup.string().max(255).nullable(),
  city: Yup.string().max(255).nullable(),
  address: Yup.string().max(255).nullable(),
  pincode: Yup.string()
    .matches(/^[0-9]{6}$/, "Please enter a valid 6-digit pincode")
    .nullable(),
  ip_address: Yup.string().max(255).nullable(),
  status: Yup.number().integer().oneOf([0, 1]).required("Please select status"),
  start_date: Yup.date().nullable(),
  end_date: Yup.date()
    .min(Yup.ref("start_date"), "End date can't be before start date")
    .nullable(),
  plan: Yup.string().max(255).nullable(),
  source: Yup.string().max(255).nullable(),
  remarks: Yup.string().max(500).nullable(),
  amount: Yup.number().min(0).required("Please enter amount"),
  mode_of_payment: Yup.string().max(255).nullable(),
  txn_id: Yup.string().max(255).nullable(),
  bank: Yup.string().max(255).nullable(),
});

export default SubscriptionValidationSchema;
