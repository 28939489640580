import NonLoadLayout from "../../components/NonLoadLayout";

export default function PostManagementMain({ children }) {
  return (
    <NonLoadLayout>
      <div className="row dashboard">
        <div className="my-4">{children}</div>
      </div>
    </NonLoadLayout>
  );
}
