import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../request";

const addCarFAQquestion = (token, payload) => {
  return axios.post(
    `${BASE_URL_AUTOX}car-faq/question/add`,
    payload,
    getConfig(token)
  );
};

const getCarQuestionList = (token, payload) => {
  return axios.post(
    `${BASE_URL_AUTOX}car-faq/question/list`,
    payload,
    getConfig(token)
  );
};

const deleteCarQuestionById = (token, id) => {
  return axios.delete(
    `${BASE_URL_AUTOX}car-faq/question/delete/${id}`,
    getConfig(token)
  );
};

const getCarQuestionValues = (token, id) => {
  return axios.get(`${BASE_URL_AUTOX}car-faq/question/${id}`, getConfig(token));
};

const updateCarFAQquestion = (token, id, payload) => {
  return axios.put(
    `${BASE_URL_AUTOX}car-faq/question/update/${id}`,
    payload,
    getConfig(token)
  );
};

const getAnswerList = (token, payload) => {
  return axios.post(
    `${BASE_URL_AUTOX}car-faq/answer/list`,
    payload,
    getConfig(token)
  );
};

const addCarAnswer = (token, payload) => {
  return axios.post(
    `${BASE_URL_AUTOX}car-faq/answer/add`,
    payload,
    getConfig(token)
  );
};

const getCarQuestionListAll = (payload, token) => {
  return axios.post(
    `${BASE_URL_AUTOX}car-faq/question/all/list`,
    payload,
    getConfig(token)
  );
};

const updateCarAnswer = (token, id, payload) => {
  return axios.put(
    `${BASE_URL_AUTOX}car-faq/answer/update/${id}`,
    payload,
    getConfig(token)
  );
};

const deleteCarAnswerById = (token, id) => {
  return axios.delete(
    `${BASE_URL_AUTOX}car-faq/answer/delete/${id}`,
    getConfig(token)
  );
};

const getCarAnswerValues = (token, id) => {
  return axios.get(`${BASE_URL_AUTOX}car-faq/answer/${id}`, getConfig(token));
};
//eslint-disable-next-line
export default {
  addCarFAQquestion,
  getCarQuestionList,
  deleteCarQuestionById,
  getCarQuestionValues,
  updateCarFAQquestion,
  getAnswerList,
  addCarAnswer,
  getCarQuestionListAll,
  deleteCarAnswerById,
  getCarAnswerValues,
  updateCarAnswer,
};
