import { useNavigate } from "react-router-dom";
import apiHandler from "./apiHandler";
import { notifyError, notifySuccess } from "../../utility/common";
import TagForm from "./TagForm";
import Breadcrumbs from "../../Breadcrumbs";
import { getToken } from "../../utility/constants";

export default function AddTag() {
  const navigate = useNavigate();
  const token = getToken();

  const initialValues = {
    tag_name: "",
    slug: "",
    description: "",
    tag_type: "",
    verified: 0,
    status: 0,
    heading: "",
    breadcrumb: "",
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    robotsfollow: "Follow",
    robotsindex: "index",
    model_id: "-1",
    brand_id: "-1",
    vehicle_type: "",
  };

  const addTag = async (data) => {
    try {
      const response = await apiHandler.addTag(data, token);
      console.log(response);
      if (response.status === 200) {
        notifySuccess(response.data.message);
        setTimeout(() => {
          navigate("/tag");
        }, 1500);
      }
    } catch (error) {
      console.error(error);
      notifyError(error.response.data.message);
    }
  };

  return (
    <TagForm initialValues={initialValues} onSubmit={addTag} title="Add Tag" />
  );
}
