import React from "react";
import { Modal, Card, Button } from "react-bootstrap";

export default function WidgetShortCodes({ show, handleClose }) {
  return (
    <div>
      <Modal show={show} onHide={handleClose} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Widget Shortcodes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex">
            <div className="p-2">
              <h5 className="text-center">New Car Widgets</h5>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>[NewCarModelNearByCity]</td>
                    <td>[NewBikeModelNearByCity]</td>
                  </tr>
                  <tr>
                    <td>[NewCarModelPopularCity]</td>
                    <td>[NewBikeModelPopularCity]</td>
                  </tr>
                  <tr>
                    <td>[NewCarsOtherVariants]</td>
                    <td>[NewBikesOtherVariants]</td>
                  </tr>
                  <tr>
                    <td>[UpcomingCars]</td>
                    <td>[UpcomingBikes]</td>
                  </tr>
                  <tr>
                    <td>[UpcomingCarsByBrand]</td>
                    <td>[UpcomingBikesByBrand]</td>
                  </tr>
                  <tr>
                    <td>[TopBrandCars]</td>
                    <td>[TopBrandBikes]</td>
                  </tr>
                  <tr>
                    <td>[PopularComparedCars]</td>
                    <td>[PopularComparedBikes]</td>
                  </tr>
                  <tr>
                    <td>[NewCarLaunches]</td>
                    <td>[NewBikeLaunches]</td>
                  </tr>
                  <tr>
                    <td>[CarCompareFilter]</td>
                    <td>[BikeCompareFilter]</td>
                  </tr>
                  <tr>
                    <td>[CarCityPriceFilter]</td>
                    <td>[BikeCityPriceFilter]</td>
                  </tr>
                  <tr>
                    <td>[NewCarFilter]</td>
                    <td>[NewBikeFilter]</td>
                  </tr>
                  <tr>
                    <td>[CarPopularBrands]</td>
                    <td>[BikePopularBrands]</td>
                  </tr>
                  <tr>
                    <td>[NewCarModelVariantOtherCity]</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>[NewCarQuickLinksCategory]</td>
                    <td>
                      [NewBikeQuickLinksCategory] [ScooterQuickLinksCategory]
                    </td>
                  </tr>
                  <tr>
                    <td>[NewCarQuickLinksFuelType]</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>[NewCarQuickLinksBodyType]</td>
                    <td>[NewBikeQuickLinksBodyType]</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <h5 className="text-center">Common Widgets</h5>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>[YouTubeVideos]</td>
                <td>[RecentPosts]</td>
              </tr>
              <tr>
                <td>[NewCarBikeFilter]</td>
                <td>[CarDealerAndServiceCentreFilter]</td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
