import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useFormik } from "formik";
import JoditEditor from "jodit-react";
import ReactDatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import {
  getBikeBrandList,
  getModelListingById,
  getBikeUserReviewById,
  updateBikeUserReview,
} from "../BikeUserController";
import BikeReviewSchema from "./BikeReviewValidation";
import DynamicSelect from "./DynamicSelect";
import FormControl from "../../../components/FormControl";
import FormFieldErrorMsg from "../../../components/FormFieldErrorMsg";
import HeadingTwo from "../../../components/HeadingTwo";
import { getToken, renderDateNumerical } from "../../../utility/constants";
import { getVersionsByModelId } from "../../../request";
import FormControlNumber from "../../../components/FormControlNumber";
export default function EditBikeReviewForm() {
  const { id } = useParams();
  const token = getToken();
  const editor = useRef(null);

  const [reviewDetails, setReviewDetails] = useState({
    email: "",
    name: "",
    model_id: "",
    version_id: "",
    design_styling: "-1",
    comfort_space: "-1",
    performance: "-1",
    fuel_economy: "-1",
    value_for_money: "-1",
    features: "-1",
    safety: "-1",
    maintenance_cost: "-1",
    title: "",
    rating: 0,
    review: "",
    kms_driven: "0",
    mileage: "",
    pincode: "",
    brand_id: "",
    status: 0,
    brand_id: 0,
    ip_address: "",
    created_date: "",
  });
  const [bikeModelList, setBikeModelList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [versionList, setVersionList] = useState([]);

  useEffect(() => {
    const fetchReviewDetails = async () => {
      try {
        const response = await getBikeUserReviewById(id, token);
        const data = response.data.data[0];
        const dateObject = new Date(data.created_date);
        setReviewDetails({
          ...data,
          created_date: dateObject,
        });
      } catch (error) {
        console.error("Error fetching review details:", error);
      }
    };

    const fetchBrands = async () => {
      try {
        const fetchBrandsParams = {
          sortBy: "",
          sortOrder: "",
        };
        const brandRes = await getBikeBrandList(fetchBrandsParams, token);
        setBrandList(brandRes.result);
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    fetchReviewDetails();

    fetchBrands();
  }, [id, token]);

  const removeExtraKeys = (obj) => {
    const allowedKeys = ["status", "title"];
    // const allowedKeys = [
    //   "email",
    //   "name",
    //   "rating",
    //   "review",
    //   "status",
    //   "title",
    //   "model_id",
    //   "ip_address",
    //   "created_date",
    //   "pincode",
    //   "kms_driven",
    //   "mileage",
    //   "version_id",
    //   "design_styling",
    //   "comfort_space",
    //   "performance",
    //   "fuel_economy",
    //   "value_for_money",
    //   "features",
    //   "safety",
    //   "maintenance_cost",
    // ];
    return Object.keys(obj)
      .filter((key) => allowedKeys.includes(key))
      .reduce((acc, key) => {
        acc[key] = obj[key];
        return acc;
      }, {});
  };

  const { handleSubmit, values, errors, touched, setFieldValue } = useFormik({
    initialValues: reviewDetails,
    enableReinitialize: true,
    // validationSchema: BikeReviewSchema,
    onSubmit: async (values, action) => {
      try {
        const payload = {
          ...values,
        };
        const filteredValue = removeExtraKeys(payload);
        const res = await updateBikeUserReview(id, filteredValue, token);
        if (res.status === 200) {
          toast.success("Review updated successfully");
        }
      } catch (error) {
        toast.error(error.response.data.message);
        console.error("Error updating review:", error);
      }
    },
  });
  useEffect(() => {
    const fetchAllBikeModels = async () => {
      try {
        const fetchBikeModelParams = {
          brand_id: values.brand_id,
          sortBy: "",
          sortOrder: "",
        };
        const bikeModelRes = await getModelListingById(fetchBikeModelParams);
        setBikeModelList(bikeModelRes.result);
      } catch (error) {
        console.error("Error fetching bike models:", error);
      }
    };

    fetchAllBikeModels();
  }, [values.brand_id]);

  useEffect(() => {
    const versionByModel = async () => {
      try {
        if (!values.model_id) return;

        const versionList = await getVersionsByModelId(values.model_id, token);
        console.log(versionList, "versionList");
        setVersionList(versionList.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    versionByModel();
  }, [values.model_id, token]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="d-flex card biked py-4 mb-3">
          <div className="biked-headers w-100 d-flex p-3 align-items-center justify-content-between">
            <div>
              <HeadingTwo
                title="Bike Review Form"
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />
            </div>
          </div>
          <div className="col-12 mt-3 d-flex flex-wrap">
            <DynamicSelect
              label="Bike Brand"
              options={brandList}
              id="brand_id"
              name="brand_name"
              values={values}
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
              disabled={true}
            />
            <DynamicSelect
              label="Bike Model"
              options={bikeModelList}
              id="model_id"
              name="model_name"
              values={values}
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
              checkedValue={id}
              disabled={true}
            />
            <DynamicSelect
              label="Model Version"
              options={versionList}
              id={"version_id"}
              name="version_name"
              values={values}
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
              disabled={true}
            />
            <DynamicSelect
              label="Design & Styling"
              options={[
                { id: 1, design_styling: 5 },
                { id: 2, design_styling: 4 },
                { id: 3, design_styling: 3 },
                { id: 4, design_styling: 2 },
                { id: 5, design_styling: 1 },
              ]}
              id={"design_styling"}
              name="design_styling"
              values={values}
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
              disabled={true}
            />
            <DynamicSelect
              label="Comfort & Space"
              options={[
                { id: 1, comfort_space: 5 },
                { id: 2, comfort_space: 4 },
                { id: 3, comfort_space: 3 },
                { id: 4, comfort_space: 2 },
                { id: 5, comfort_space: 1 },
              ]}
              id={"comfort_space"}
              name="comfort_space"
              values={values}
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
              disabled={true}
            />
            <DynamicSelect
              label="Performance"
              options={[
                { id: 1, performance: 5 },
                { id: 2, performance: 4 },
                { id: 3, performance: 3 },
                { id: 4, performance: 2 },
                { id: 5, performance: 1 },
              ]}
              id={"performance"}
              name="performance"
              values={values}
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
              disabled={true}
            />
            <DynamicSelect
              label="Fuel Economy"
              options={[
                { id: 1, fuel_economy: 5 },
                { id: 2, fuel_economy: 4 },
                { id: 3, fuel_economy: 3 },
                { id: 4, fuel_economy: 2 },
                { id: 5, fuel_economy: 1 },
              ]}
              id={"fuel_economy"}
              name="fuel_economy"
              values={values}
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
              disabled={true}
            />
            <DynamicSelect
              label="Value for Money"
              options={[
                { id: 1, value_for_money: 5 },
                { id: 2, value_for_money: 4 },
                { id: 3, value_for_money: 3 },
                { id: 4, value_for_money: 2 },
                { id: 5, value_for_money: 1 },
              ]}
              id={"value_for_money"}
              name="value_for_money"
              values={values}
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
              disabled={true}
            />
            <DynamicSelect
              label="Features"
              options={[
                { id: 1, features: 5 },
                { id: 2, features: 4 },
                { id: 3, features: 3 },
                { id: 4, features: 2 },
                { id: 5, features: 1 },
              ]}
              id={"features"}
              name="features"
              values={values}
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
              disabled={true}
            />
            <DynamicSelect
              label="Safety"
              options={[
                { id: 1, safety: 5 },
                { id: 2, safety: 4 },
                { id: 3, safety: 3 },
                { id: 4, safety: 2 },
                { id: 5, safety: 1 },
              ]}
              id={"safety"}
              name="safety"
              values={values}
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
              disabled={true}
            />{" "}
            <DynamicSelect
              label="Rating"
              options={[
                { id: 1, rating: 5 },
                { id: 2, rating: 4 },
                { id: 3, rating: 3 },
                { id: 4, rating: 2 },
                { id: 5, rating: 1 },
              ]}
              id="rating"
              values={values}
              name="rating"
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
              disabled={true}
            />
            <DynamicSelect
              label="Maintenance Cost"
              options={[
                { id: 1, maintenance_cost: 5 },
                { id: 2, maintenance_cost: 4 },
                { id: 3, maintenance_cost: 3 },
                { id: 4, maintenance_cost: 2 },
                { id: 5, maintenance_cost: 1 },
              ]}
              id="maintenance_cost"
              values={values}
              name="maintenance_cost"
              touched={touched}
              setFieldValue={setFieldValue}
              errors={errors}
              disabled={true}
            />
            <div className="col-lg-6 p-3">
              <FormControl
                controlId="KmsDriven"
                label="KMs Driven"
                type="text"
                touched={touched}
                errors={errors}
                name="kms_driven"
                value={values.kms_driven}
                className={"form-control border border-2 shadow-none"}
                setFieldValue={setFieldValue}
                disabled={true}
              />
            </div>
            <div className="col-lg-6 p-3">
              <FormControl
                controlId="Mileage"
                label="Mileage"
                type="text"
                touched={touched}
                errors={errors}
                name="mileage"
                value={values.mileage}
                className={"form-control border border-2 shadow-none"}
                setFieldValue={setFieldValue}
                disabled={true}
              />
            </div>
            <div className="col-lg-6 p-3">
              <FormControlNumber
                controlId="PinCode"
                label="Pin Code"
                touched={touched}
                errors={errors}
                name={"pincode"}
                value={values.pincode}
                className={"form-control border border-2 shadow-none"}
                setFieldValue={setFieldValue}
                disabled={true}
              />
            </div>
            <div className="col-lg-6 p-3">
              <FormControl
                controlId="userName"
                label="User Name"
                type="text"
                touched={touched}
                errors={errors}
                name="name"
                className={"form-control border border-2 shadow-none"}
                value={values.name}
                setFieldValue={setFieldValue}
                disabled={true}
              />
            </div>
            <div className="col-lg-6 p-3">
              <FormControl
                controlId="emailId"
                label="Email ID"
                type="text"
                touched={touched}
                errors={errors}
                name="email"
                value={values.email}
                className={"form-control border border-2 shadow-none"}
                setFieldValue={setFieldValue}
                disabled={true}
              />
            </div>
            <div className="col-lg-6 p-3">
              <FormControl
                controlId="title"
                label="Title"
                type="text"
                touched={touched}
                errors={errors}
                name="title"
                value={values.title}
                className={"form-control border border-2 shadow-none"}
                setFieldValue={setFieldValue}
                disabled={true}
              />
            </div>
            <FormFieldErrorMsg touched={touched} errors={errors} name="title" />
            <div className="w-100 p-3">
              <label htmlFor="review" className="form-label">
                Review<sup className="required-superscript">*</sup>
              </label>
              <JoditEditor
                ref={editor}
                value={values.review}
                onBlur={(newContent) => setFieldValue("review", newContent)}
                disabled={true}
                config={{ readonly: true }}
              />
              <FormFieldErrorMsg
                touched={touched}
                errors={errors}
                name="review"
              />
            </div>
            <div className="w-100">
              <div className="col-lg-6 p-3">
                <label htmlFor="title" className="form-label">
                  Status
                </label>
                <select
                  className="form-select border border-2 shadow-none"
                  value={values.status}
                  onChange={(e) => setFieldValue("status", e.target.value)}
                >
                  <option value="" disabled>
                    Select Status
                  </option>
                  <option value="1">Approve</option>
                  <option value="2">Pending</option>
                  <option value="3">Rejected</option>
                </select>
                <FormFieldErrorMsg
                  touched={touched}
                  errors={errors}
                  name="status"
                />
              </div>
              <div className="col-lg-6 p-3">
                <Form.Label>
                  Date <sup className="text-danger">*</sup> (dd/mm/yyyy)
                </Form.Label>
                <ReactDatePicker
                  selected={values.created_date}
                  onChange={(date) =>
                    setFieldValue(
                      "created_date",
                      `${renderDateNumerical(date)}T00:00:00.000Z`
                    )
                  }
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"
                  className="form-control border border-2 shadow-none"
                  disabled={true}
                />
              </div>
              <button type="submit" className="green-filled-button mx-3">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </>
  );
}
