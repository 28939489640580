import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackIcon from "../../../assets/icons/Back_Arrow.png";
import BackArrow from "../../../assets/icons/bootstrap-icons/arrow-left.svg";
import HondaLogo from "../../../assets/icons/bootstrap-icons/honda-logo-2000-full-640.webp";
import PlusLogo from "../../../assets/icons/bootstrap-icons/plus.svg";
import { ReactSVG } from "react-svg";
import Breadcrumbs from "../../../Breadcrumbs";
import Image from "react-bootstrap/Image";
import { Card, Form } from "react-bootstrap";
import { notifyError } from "../../../utility/common";
import apiHandler from "./apiHandler";
import { carDealerValidation } from "./schemaValidation";
import { useFormik } from "formik";
import { getToken } from "../../../utility/constants";
import {
  getAllBikeBrands,
  getAllBikeCities,
  getAllBrands,
  getAllCities,
} from "../../../request";

export default function EditBikeDealer() {
  const token = getToken();
  const navigate = useNavigate();
  const { id } = useParams();
  const [brands, setBrands] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    apiHandler
      .getbikeDealerById(token, id)
      .then((res) => {
        setFieldValue("city_id", res.data.data[0].city_id);
        setFieldValue("brand_id", res.data.data[0].brand_id);
        setFieldValue("dealer_name", res.data.data[0].dealer_name);
        setFieldValue("dealer_url", res.data.data[0].dealer_url);
        setFieldValue("dealer_address", res.data.data[0].dealer_address);
        setFieldValue("dealer_map", res.data.data[0].dealer_map);
        setFieldValue("dealer_area", res.data.data[0].dealer_area);
        setFieldValue("phone_number", res.data.data[0].phone_number);
        setFieldValue("email_id", res.data.data[0].email_id);
        if (res.data.data[0].dealer_location_url) {
          setFieldValue(
            "dealer_location_url",
            res.data.data[0].dealer_location_url
          );
        }

        if (res.data.data[0].dealer_content) {
          setFieldValue("dealer_content", res.data.data[0].dealer_content);
        }
      })
      .catch((error) => {
        notifyError(error.message);
      });

    getAllBikeBrands()
      .then((res) => {
        setBrands(res.data.result);
      })
      .catch((error) => {
        notifyError(error.message);
      });

    getAllBikeCities()
      .then((res) => {
        setCityList(res.data.result);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  }, []);

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    values,
    errors,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: {
      city_id: "",
      brand_id: "",
      dealer_name: "",
      dealer_url: "",
      dealer_address: "",
      dealer_area: "",
      dealer_map: "",
      phone_number: "",
      email_id: "",
      dealer_content: "",
      dealer_location_url: "",
    },
    validationSchema: carDealerValidation,
    onSubmit: async (values, action) => {
      const payload = values;

      apiHandler
        .editbikeDealer(token, payload, id)
        .then((result) => {
          navigate(`/bike-dealer`);
        })
        .catch((error) => {
          notifyError(error.message);
        });
    },
  });
  return (
    <div className="row dashboard">
      <div className="row">
        <div className="col-xl-11 col-sm-11">
          <Breadcrumbs />
        </div>
        {/* <div className="col-xl-4 col-sm-4">

        </div> */}
        <div className="col-xl-1 col-sm-1">
          <button
            className="d-flex back-button py-2 px-3 mb-2 btn btn-primary"
            onClick={() => navigate("/bike-dealer")}
          >
            <Image
              src={BackIcon}
              width="16px"
              height="16px"
              className="mt-1 me-2"
            />{" "}
            Back
          </button>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-xl-12 col-sm-12">
          <Card className="pb-2 px-2">
            <Card.Body>
              <Card.Text>
                <div className="row">
                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <div className="d-flex align-items-center">
                        <div className="table-heading">Edit Bike Dealer</div>
                        <div className="py-0 ms-5"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <Form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="mb-4" controlId="brandNameInput">
                        <Form.Label className="mb-2">Select Brand</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          className="custom-select"
                          touched={touched}
                          errors={errors["brand_id"]}
                          name="brand_id"
                          value={values.brand_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.brand_id && errors.brand_id}
                        >
                          <option selected disabled>
                            {" "}
                            Select Brand
                          </option>
                          {brands.map((brand, i) => {
                            return (
                              <option value={brand.id} key={i}>
                                {brand.brand_name}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {touched["brand_id"] && errors["brand_id"]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="SlugControlInput">
                        <Form.Label className="mb-2">City</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          className="custom-select"
                          touched={touched}
                          errors={errors["city_id"]}
                          name="city_id"
                          value={values.city_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.city_id && errors.city_id}
                        >
                          <option selected disabled>
                            {" "}
                            Select City
                          </option>
                          {cityList.map((city, i) => {
                            return (
                              <option value={city.id} key={i}>
                                {city.city_name}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {touched["city_id"] && errors["city_id"]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="mb-4" controlId="dealerNameInput">
                        <Form.Label className="mb-2">Dealer Name</Form.Label>
                        <Form.Control
                          type="text"
                          touched={touched}
                          errors={errors["dealer_name"]}
                          name="dealer_name"
                          value={values.dealer_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.dealer_name && errors.dealer_name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {touched["dealer_name"] && errors["dealer_name"]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-4" controlId="dealerUrlInput">
                        <Form.Label className="mb-2">Dealer Url</Form.Label>
                        <Form.Control
                          type="text"
                          touched={touched}
                          errors={errors["dealer_url"]}
                          name="dealer_url"
                          value={values.dealer_url}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.dealer_url && errors.dealer_url}
                        />
                        <Form.Control.Feedback type="invalid">
                          {touched["dealer_url"] && errors["dealer_url"]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="mb-4" controlId="addressInput">
                        <Form.Label className="mb-2">Address</Form.Label>
                        <Form.Control
                          type="text"
                          touched={touched}
                          errors={errors["dealer_address"]}
                          name="dealer_address"
                          value={values.dealer_address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.dealer_address && errors.dealer_address
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {touched["dealer_address"] &&
                            errors["dealer_address"]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-4" controlId="phoneInput">
                        <Form.Label className="mb-2">Phone No.</Form.Label>
                        <Form.Control
                          type="text"
                          touched={touched}
                          errors={errors["phone_number"]}
                          name="phone_number"
                          value={values.phone_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.phone_number && errors.phone_number
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {touched["phone_number"] && errors["phone_number"]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="mb-4" controlId="addressInput">
                        <Form.Label className="mb-2">Area</Form.Label>
                        <Form.Control
                          type="text"
                          touched={touched}
                          errors={errors["dealer_area"]}
                          name="dealer_area"
                          value={values.dealer_area}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.dealer_area && errors.dealer_area}
                        />
                        <Form.Control.Feedback type="invalid">
                          {touched["dealer_area"] && errors["dealer_area"]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="col-md-6">
                      <Form.Group className="mb-4" controlId="urlInput">
                        <Form.Label className="mb-2">Location URL</Form.Label>
                        <Form.Control
                          type="text"
                          touched={touched}
                          errors={errors["dealer_location_url"]}
                          name="dealer_location_url"
                          value={values.dealer_location_url}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.dealer_location_url &&
                            errors.dealer_location_url
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {touched["dealer_location_url"] &&
                            errors["dealer_location_url"]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="mb-4" controlId="phoneInput">
                        <Form.Label className="mb-2">Map</Form.Label>
                        <Form.Control
                          type="text"
                          touched={touched}
                          errors={errors["dealer_map"]}
                          name="dealer_map"
                          value={values.dealer_map}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.dealer_map && errors.dealer_map}
                        />
                        <Form.Control.Feedback type="invalid">
                          {touched["dealer_map"] && errors["dealer_map"]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-4" controlId="emailInput">
                        <Form.Label className="mb-2">Email</Form.Label>
                        <Form.Control
                          type="email"
                          touched={touched}
                          errors={errors["email_id"]}
                          name="email_id"
                          value={values.email_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.email_id && errors.email_id}
                        />
                        <Form.Control.Feedback type="invalid">
                          {touched["email_id"] && errors["email_id"]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Form.Group
                      className="mb-4"
                      controlId="dealerConetentInput"
                    >
                      <Form.Label className="mb-2">Dealer Content</Form.Label>
                      <Form.Control
                        type="text"
                        touched={touched}
                        errors={errors["dealer_content"]}
                        name="dealer_content"
                        value={values.dealer_content}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          touched.dealer_content && errors.dealer_content
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {touched["dealer_content"] && errors["dealer_content"]}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <button
                    className="export-button d-flex align-items-center justify-content-between me-2 mb-2 text-white px-3"
                    type="submit"
                  >
                    Update Dealer
                  </button>
                </Form>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}
