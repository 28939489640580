import { Link } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";

const routes = [
  { path: "/", breadcrumb: "Dashboard" },
  { path: "/car-brand", breadcrumb: "Car Brand" },
  { path: "/car-brand/add-car-brand", breadcrumb: "Create Car Brand" },
  { path: "/car-brand/edit-car-brand/:id", breadcrumb: "Edit Car Brand" },
  { path: "/car-models/edit-car-model/:id", breadcrumb: "Edit Car Model" },
  { path: "/popular-cars", breadcrumb: "Popular Cars" },
  { path: "/trending-cars", breadcrumb: "Trending Cars" },
  { path: "/car-price", breadcrumb: "Price Management" },
  { path: "/car-models", breadcrumb: "Car Model" },
  { path: "/car-models/add-car-model", breadcrumb: "Create Car Model" },
  { path: "/car-price/multiple-city-price", breadcrumb: "Multiple City Price" },

  { path: "/car-version", breadcrumb: "Car Variants" },
  { path: "/car-version/add-car-version", breadcrumb: "Add Version" },
  { path: "/car-version/create-car-version", breadcrumb: "Create Car Version" },
  {
    path: "/edit-car-version",
    breadcrumb: "Manage Car Version",
  },
  { path: "/car-city-price", breadcrumb: "Car City Price" },
  { path: "/add-car-city-price", breadcrumb: "Add City Price" },
  { path: "/delete-car-city-price", breadcrumb: "Delete Car City Price" },
  { path: "/edit-car-city-price", breadcrumb: "Edit Car Version" },

  { path: "/dealer", breadcrumb: "Car Dealer Management" },
  { path: "/car-dealer", breadcrumb: "Car Dealer Management" },

  { path: "/multiple-city-price", breadcrumb: "Multiple City Price" },
  { path: "/car-comparison", breadcrumb: "Car Comparison Management" },
  { path: "/car-service-centre", breadcrumb: "Car Service Centre Management" },
  {
    path: "/car-service-centre/add-car-service-centre",
    breadcrumb: "Add Car Service Centre",
  },
  {
    path: "/car-service-centre/edit-car-service-centre",
    breadcrumb: "Edit Car Service Centre",
  },
  {
    path: "/bike-model-gallery/bike-gallery-edit",
    breadcrumb: "Edit Bike Gallery",
  },
  {
    path: "/edit-post-gallery",
    breadcrumb: "Edit Post Gallery",
  },
  {
    path: "/edit-user-newsletter-subscriptions",
    breadcrumb: "Edit subscriber",
  },
  {
    path: "/view-campaign",
    breadcrumb: "View Campaign",
  },
  { path: "/bike-model-gallery", breadcrumb: "Bike Gallery" },
  {
    path: "/edit-safety-rating",
    breadcrumb: "Edit Safety Rating",
  },

  { path: "/edit-car-user-review", breadcrumb: "Edit Car Review" },
  {
    path: "/add-car-user-review",
    breadcrumb: "Add Car Review",
  },

  { path: "/vehicle-type", breadcrumb: "Car Body Type Management" },
  {
    path: "/vehicle-type/add-car-vehicle-type",
    breadcrumb: "Add Car Body Type",
  },

  {
    path: "/vehicle-type/edit-car-vehicle-type",
    breadcrumb: "Edit Car Body Type",
  },
  { path: "/sidebar-edit", breadcrumb: "Sidebar Edit" },
  { path: "/edit-ad-page", breadcrumb: "Ad Page Edit" },

  { path: "/car-carousel", breadcrumb: "Car Carousel" },
  { path: "/car-vehicle-type", breadcrumb: "Car Body Type Management" },
  { path: "/add-car-vehicle-type", breadcrumb: "" },
  { path: "/car-city", breadcrumb: "City Management" },
  { path: "/car-city/add-car-city", breadcrumb: "Create City" },
  { path: "/car-driving-school", breadcrumbs: "Car Driving School Management" },
  {
    path: "/dealer/edit-car-dealer",
    breadcrumb: "Edit Car Dealer",
  },
  {
    path: "/car-driving-school/edit-car-driving-school/:id",
    breadcrumb: "Edit Car Driving School",
  },
  {
    path: "/maruti-car-driving-school",
    breadcrumbs: "Maruti Car Driving School Management",
  },
  {
    path: "/maruti-car-driving-school/add-maruti-car-driving-school",
    breadcrumbs: "Create Maruti Car Driving School",
  },
  {
    path: "/maruti-car-driving-school/edit-maruti-car-driving-school",
    breadcrumbs: "Edit Maruti Car Driving School",
  },
  { path: "/car-model-gallery", breadcrumbs: "Car Model Gallery Management" },
  { path: "/car-gallery-edit", breadcrumbs: "Edit Car Gallery" },
  {
    path: "/edit-car-faq",
    breadcrumb: "Edit Car FAQ",
  },
  { path: "/editor", breadcrumb: "Editor Management" },
  {
    path: "/editor/edit-password",
    breadcrumb: "Change password",
  },
  { path: "/edit-news-letter", breadcrumb: "Edit Contact List" },
  { path: "/news-letter", breadcrumb: "Contact List" },

  {
    path: "/widget-edit",
    breadcrumb: "Edit Widget",
  },
  {
    path: "/ad-widget-edit",
    breadcrumb: "Edit Ad Widget",
  },
  {
    path: "/edit-car-question",
    breadcrumb: "Edit Car Question",
  },
  {
    path: "/edit-car-answer",
    breadcrumb: "Edit Car Answer",
  },
  //Bike Routes
  { path: "/bike-brand", breadcrumb: "bike Brand" },
  { path: "/bike-brand/add-bike-brand", breadcrumb: "Create Bike Brand" },
  { path: "/bike-brand/edit-bike-brand/:id", breadcrumb: "Edit Bike Brand" },
  { path: "/bike-models", breadcrumb: "bike Model" },
  { path: "/bike-models/edit-bike-model/:id", breadcrumb: "Edit Bike Model" },
  { path: "/bike-models/add-bike-model", breadcrumb: "Create Bike Model" },
  { path: "/bike-version", breadcrumb: "Bike Variants" },
  { path: "/bike-version/add-bike-version", breadcrumb: "Add Version" },
  {
    path: "/bike-version/create-bike-version",
    breadcrumb: "Create Bike Version",
  },
  {
    path: "/edit-bike-version",
    breadcrumb: "Manage bike Version",
  },
  {
    path: "/bike-dealer",
    breadcrumb: "Bike Dealer Management",
  },
  {
    path: "/bike-dealer/edit-bike-dealer",
    breadcrumb: "Edit Bike Dealer",
  },
  {
    path: "/bike-dealer/add-bike-dealer",
    breadcrumb: "Add Bike Dealer",
  },
  { path: "/bike-city-price", breadcrumb: "Bike City Price" },
  { path: "/add-bike-city-price", breadcrumb: "Add Bike Price" },
  { path: "/delete-bike-city-price", breadcrumb: "Delete Bike City Price" },
  { path: "/edit-bike-city-price", breadcrumb: "Edit Bike Version" },
  { path: "/popular-bikes", breadcrumb: "Popular Bikes" },
  { path: "/trending-bikes", breadcrumb: "Trending Bikes" },
  {
    path: "/bike-city-price/multiple-city-price",
    breadcrumb: "Multiple City Price",
  },
  {
    path: "/bike-service-centre",
    breadcrumb: "Bike Service Centre Management",
  },
  {
    path: "/bike-service-centre/add-bike-service-centre",
    breadcrumb: "Add Bike Service Centre",
  },
  {
    path: "/bike-service-centre/edit-bike-service-centre",
    breadcrumb: "Edit Bike Service Centre",
  },
  { path: "/bike-city", breadcrumb: "City Management" },
  { path: "/bike-city/add-bike-city", breadcrumb: "Create City" },
  {
    path: "/edit-Bike-faq",
    breadcrumb: "Edit Bike FAQ",
  },
  {
    path: "/edit-bike-question",
    breadcrumb: "Edit Bike Question",
  },
  {
    path: "/add-bike-question",
    breadcrumb: "Add Bike Question",
  },
  {
    path: "/edit-bike-answer",
    breadcrumb: "Edit Bike Answer",
  },
  {
    path: "/add-bike-answer",
    breadcrumb: "Add Bike Answer",
  },
  { path: "/edit-bike-user-review", breadcrumb: "Edit Bike Review" },
  {
    path: "/add-bike-user-review",
    breadcrumb: "Add Bike Review",
  },
  {
    path: "/page-edit",
    breadcrumb: "Edit Page",
  },
  {
    path: "/editor",
    breadcrumb: "Editor",
  },
  {
    path: "/edit-editor",
    breadcrumb: "Edit Editor",
  },
  { path: "/edit-tag", breadcrumb: "Edit Tag" },
  { path: "/edit-category", breadcrumb: "Edit Category" },
  { path: "/post-management", breadcrumb: "Post Management" },
  { path: "/post-management/edit-post", breadcrumb: "Edit Post" },
  { path: "/post-management/add-post", breadcrumb: "Add Post" },
  { path: "/regular-gallery", breadcrumb: "Regular Gallery" },
  {
    path: "/regular-gallery/edit-regular-gallery",
    breadcrumb: "Edit Regular Gallery",
  },
  {
    path: "/regular-gallery/add-regular-gallery",
    breadcrumb: "Add Regular Gallery",
  },
  { path: "/subscription", breadcrumb: "Subscription Management" },
  {
    path: "/subscription/edit-subscription",
    breadcrumb: "Edit Subscription",
  },
  { path: "/magazine", breadcrumb: "Magazine Management" },
  {
    path: "/magazine/edit-magazine",
    breadcrumb: "Edit Magazine",
  },
  { path: "/template", breadcrumb: "Template Management" },
  {
    path: "/template/edit-template",
    breadcrumb: "Edit Template",
  },
  {
    path: "/add-news-letter",
    breadcrumb: "Add Contact List",
  },
  {
    path: "/add-user-newsletter-subscriptions",
    breadcrumb: "Add newsletter subscriber",
  },
];

export default function Breadcrumbs({ removeId }) {
  const breadcrumbs = useBreadcrumbs(routes);
  const breadcrumbsUpdated = removeId ? breadcrumbs.slice(0, -1) : breadcrumbs;

  return (
    <nav className="d-flex mb-3">
      {breadcrumbsUpdated.map(({ match, breadcrumb }, index) => {
        return (
          (index < breadcrumbsUpdated.length - 1 && (
            <div
              className="breadcrumb-secondary text-capitalize"
              key={match.url}
            >
              <Link
                key={match.url || ""}
                to={match.route.path}
                className="text-decoration-none"
              >
                {breadcrumb}/&nbsp;
              </Link>
            </div>
          )) || (
            <div className="breadcrumb-primary text-capitalize" key={match.url}>
              {breadcrumb}
            </div>
          )
        );
      })}
    </nav>
  );
}
