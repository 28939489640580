import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs";
import { ReactSVG } from "react-svg";
import BackIcon from "../../assets/icons/bootstrap-icons/arrow-left.svg";
import { Button, Card } from "react-bootstrap";
import { useFormik } from "formik";
import HeadingTwo from "../../components/HeadingTwo";
import FormControl from "../../components/FormControl";
import UserNewsletterApiHandler from "./UserNewsletterApiHandler";
import { getToken } from "../../utility/constants";
import { notifyError, notifySuccess } from "../../utility/common";
import { getNewsLetterList } from "../NewsLetter/apiHandler";
import FormSelect from "../../components/FormSelect";

export default function AddNewsLetterSubscriptionForm({}) {
  const navigate = useNavigate();
  const [isPreview, setIsPreview] = useState(false);
  const [contactList, setContactList] = useState(false);
  console.log("contactList", contactList);
  const token = getToken();

  useEffect(() => {
    const getContactList = async () => {
      const response = await getNewsLetterList(token);
      console.log(response.data);
      setContactList(
        response.data.result.map((item) => {
          return {
            id: item.newsletter_id,
            name: item.name,
          };
        })
      );
    };
    getContactList();
  }, [token]);

  const { handleSubmit, errors, values, touched, setFieldValue, resetForm } =
    useFormik({
      initialValues: {
        emails: "",
        contact_list_id: 25,
      },
      enableReinitialize: true,
      onSubmit: async (values, action) => {
        try {
          const payload = values;
          const res =
            await UserNewsletterApiHandler.addUserNewsletterSubscriptions(
              token,
              payload
            );
          console.log("response", res);
          if (res.status === 200) {
            notifySuccess("User subscriptions added successfully");
            resetForm();
          }
        } catch (error) {
          notifyError(error.response.data.message);
          console.error(error);
        }
      },
    });

  return (
    <>
      <div className="d-flex justify-content-between">
        <Breadcrumbs />
        <button
          className="d-flex back-button py-2 px-3 mb-4 btn btn-primary"
          onClick={() => navigate("/user-newsletter-subscriptions")}
        >
          <ReactSVG src={BackIcon} className="me-1" />
          Back
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <Card className="p-3 col-12 col-md-6">
            <HeadingTwo
              title={"Add Newsletter Subscriber"}
              type="dashboard"
              showHeader={true}
              pointerOnHover={true}
            />
            <div className="row g-4">
              <div className="">
                <FormControl
                  controlId={"templateBodyInput"}
                  label={"Emails"}
                  type={"text"}
                  placeholder={"Enter comma-separated emails"}
                  name={"emails"}
                  value={values.emails}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  as={"textarea"}
                  rows={3}
                />{" "}
                <FormSelect
                  controlId={"contact_list_id"}
                  label={"Contact List"}
                  items={contactList}
                  itemName={"name"}
                  name={"contact_list_id"}
                  value={values.contact_list_id}
                  touched={touched}
                  setFieldValue={(field, value) => {
                    console.log("value", value);
                    setFieldValue(field, Number(value));
                  }}
                  errors={errors}
                />
              </div>
            </div>
            <div>
              <button type="submit" className="green-filled-button mt-4">
                Add
              </button>
            </div>
          </Card>
        </div>
      </form>
    </>
  );
}
