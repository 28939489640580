import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../utility/common";
import { useFormik } from "formik";
import sidebarSchema from "./sidebarValidation";
import Breadcrumbs from "../../../Breadcrumbs";
import { Card } from "react-bootstrap";
import HeadingTwo from "../../../components/HeadingTwo";
import FormControl from "../../../components/FormControl";
import { ReactSVG } from "react-svg";
import BackIcon from "../../../assets/icons/bootstrap-icons/Back_Arrow.svg";

export default function SideBarForm({ initialValues, onSubmit, title }) {
  const navigate = useNavigate();
  const { handleSubmit, errors, values, touched, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: sidebarSchema,
    onSubmit: async (values, action) => {
      try {
        const payload = { ...values };
        const response = await onSubmit(payload);
        if (response.status === 200) {
          notifySuccess("Sidebar added successfully");
          setTimeout(() => {
            navigate("/sidebar");
          }, 2000);
        }
      } catch (error) {
        console.log(error);
        notifyError(error.response.data.message);
      }
    },
  });
  return (
    <div>
      <div className="d-flex justify-content-between">
        <Breadcrumbs />

        <button
          className="d-flex back-button py-2 px-3 mb-4 btn btn-primary"
          onClick={() => navigate("/sidebar")}
        >
          {" "}
          <ReactSVG src={BackIcon} className="me-1" />
          Back
        </button>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="d-flex gap-4 justify-content-between">
          <Card className="p-3" style={{ width: "80%" }}>
            <HeadingTwo
              title={title}
              type="dashboard"
              showHeader={true}
              pointerOnHover={true}
            />
            <div className="row d-flex gap-4">
              <FormControl
                controlId="sidebarNameInput"
                label="Sidebar Name"
                placeholder="Sidebar Name"
                touched={touched}
                errors={errors}
                name="sidebar_name"
                value={values.sidebar_name}
                setFieldValue={setFieldValue}
              />
              <FormControl
                controlId="routerNameInput"
                label="Router Name"
                placeholder="Router Name"
                touched={touched}
                errors={errors}
                name="router_location"
                value={values.router_location}
                setFieldValue={setFieldValue}
              />

              <FormControl
                controlId="remarkInput"
                label="Remark"
                placeholder="Remark"
                touched={touched}
                errors={errors}
                name="remark"
                value={values.remark}
                setFieldValue={setFieldValue}
              />
            </div>
            <div className="py-3">
              <button type="submit" className="green-filled-button">
                Save
              </button>
            </div>
          </Card>
        </div>
      </form>
    </div>
  );
}
