import { useNavigate } from "react-router-dom";
import DeleteConfirmationModalComponent from "../../../components/DeleteConfirmationModalComponent";
import EditViewDeleteReviewPassword from "../../../components/EditViewDeleteReviewPassword"; // Import the component
import { useState } from "react";
import { getToken, renderDate } from "../../../utility/constants";
import FAQapiHandler from "../FAQapiHandler";
import { toast } from "react-toastify"; // Import toast from react-toastify

export const CarAnswerColumn = (reloadTable, currentPage, itemsPerPage) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const token = getToken();

  const deleteCaranswer = async () => {
    if (deleting) return;

    try {
      setDeleting(true);
      const res = await FAQapiHandler.deleteCarAnswerById(token, deleteId);
      toast.success("Car answer deleted successfully");
      setModal(false);
      reloadTable();
      return res;
    } catch (error) {
      console.error("Error deleting car answer:", error);
      toast.error("Failed to delete car answer");
    } finally {
      setDeleting(false);
      setModal(false);
    }
  };

  const onDelete = (id) => {
    if (deleting) return;
    setDeleteId(id);
    setModal(true);
  };

  const onEdit = (id) => {
    navigate(`/edit-car-answer/${id}`);
  };

  const onViewInner = (id) => {
    window.open(`https://www.autox.com/new-cars/`, "_blank");
  };

  return [
    {
      name: "Sr No.",
      sortable: true,
      selector: (_, index) =>
        index + 1 + currentPage * itemsPerPage - itemsPerPage,
      width: "100px",
    },

    {
      name: "Answer",
      sortable: true,
      selector: (row) => row.answer_name,
      width: "200px",
    },
    {
      name: "Question",
      sortable: true,
      selector: (row) => row.question_name,
      width: "180px",
    },
    {
      name: "Helpful",
      sortable: true,
      selector: (row) => row.helpful_count,
      width: "100px",
    },
    {
      name: "Created Date",
      sortable: true,
      selector: (row) => renderDate(row.created_date),
      width: "200px",
    },
    {
      name: "Status",
      sortable: true,

      selector: (row) =>
        row.answer_status === 1 ? (
          <div className="green-box text-center p-1">Approved</div>
        ) : row.answer_status === 2 ? (
          <div className="red-box">Not Approved</div>
        ) : row.answer_status === 3 ? (
          <div className="yellow-box">Pending</div>
        ) : null,
      width: "170px",
    },
    {
      name: "Action",
      sortable: false,
      width: "auto",
      id: "Action",
      style: {
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },

      cell: (row) => (
        <div className="d-flex align-items-center justify-content-center">
          <EditViewDeleteReviewPassword
            id={row.id}
            onView={() => onViewInner(row.id)}
            onEdit={() => onEdit(row.id)}
            onDelete={() => onDelete(row.id)}
          />
          <DeleteConfirmationModalComponent
            heading="Delete Car Answer"
            message="Are you sure you want to delete this car answer?"
            show={modal && deleteId === row.id}
            handleClose={() => setModal(false)}
            setDeleteConfirm={deleteCaranswer}
            type="delete"
          />
        </div>
      ),
    },
  ];
};
