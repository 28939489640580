import { useNavigate } from "react-router-dom";
import DeleteConfirmationModalComponent from "../../components/DeleteConfirmationModalComponent";
import EditViewDeleteReviewPassword from "../../components/EditViewDeleteReviewPassword"; // Import the component
import { useState } from "react";
import { getToken, renderDate } from "../../utility/constants";
import UserNewsletterApiHandler from "./UserNewsletterApiHandler";
import { toast } from "react-toastify"; // Import toast from react-toastify

export const UserNewsletterColumn = (
  reloadTable,
  currentPage,
  itemsPerPage
) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const token = getToken();

  const deleteSubscriber = async () => {
    if (deleting) return;

    try {
      setDeleting(true);
      const res = await UserNewsletterApiHandler.deleteSubscriberById(
        token,
        deleteId
      );
      toast.success("Subscriber deleted successfully");
      setModal(false);
      reloadTable();
      return res;
    } catch (error) {
      console.error("Error deleting Subscriber:", error);
      toast.error("Failed to Subscriber");
    } finally {
      setDeleting(false);
      setModal(false);
    }
  };

  const onDelete = (id) => {
    if (deleting) return;
    setDeleteId(id);
    setModal(true);
  };

  const onEdit = (id) => {
    navigate(`/edit-user-newsletter-subscriptions/${id}`);
  };

  const onViewInner = (id) => {
    window.open(`https://www.autox.com/new-bikes/`, "_blank");
  };

  return [
    {
      name: "Sr No.",
      sortable: true,
      selector: (_, index) =>
        index + 1 + currentPage * itemsPerPage - itemsPerPage,
      width: "100px",
    },
    {
      name: "Email",
      sortable: true,
      selector: (row) => row.email,
      //   width: "200px",
    },
    {
      name: "Contact List",
      sortable: true,
      selector: (row) => row.newsletter_name,
      width: "250px",
    },
    {
      name: "Source",
      sortable: true,
      selector: (row) => row.source,
      //   width: "180px",
    },
    {
      name: "Created Date",
      sortable: true,
      selector: (row) => renderDate(row.created_date),
      //   width: "200px",
    },

    {
      name: "Action",
      sortable: false,
      width: "auto",
      id: "Action",
      style: {
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },

      cell: (row) => (
        <div className="d-flex align-items-center justify-content-center">
          <EditViewDeleteReviewPassword
            id={row.id}
            // onView={() => onViewInner(row.id)}
            onEdit={() => onEdit(row.id)}
            onDelete={() => onDelete(row.id)}
          />
          <DeleteConfirmationModalComponent
            heading="Delete Subscriber"
            message="Are you sure you want to delete this subscriber?"
            show={modal && deleteId === row.id}
            handleClose={() => setModal(false)}
            setDeleteConfirm={deleteSubscriber}
            type="delete"
          />
        </div>
      ),
    },
  ];
};
