import { Breadcrumb, Card, Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useEffect, useState, useCallback } from "react";
import FormSelect from "../../components/FormSelect";
import { updateStatePrice } from "./apiHandler";
import { getToken } from "../../utility/constants";
import {
  getAllBrands,
  getModelsByBrandId,
  getVersionsByModelId,
  getAllStates,
} from "../../request";
import Breadcrumbs from "../../Breadcrumbs";
import { notifyError, notifySuccess } from "../../utility/common";
import {
  getCarModelByBrandId,
  getCarVersionByModelId,
} from "../NewCar/CarCityPrice/apiHandler";

export default function StatePrice() {
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [versions, setVersions] = useState([]);
  const [states, setStates] = useState([]);
  const token = getToken();
  const formik = useFormik({
    initialValues: {
      brand: "-1",
      model: "-1",
      version: "-1",
      state: "",
      ex_showroom_price: "",
      type: "car",
      rto: "",
      insurance: "",
      registration: "",
      handling_logistic: "",
      tcs: "",
      mcd: "",
      hypothecation: "",
      ecc: "",
      incidental: "",
      number_plate: "",
      fast_tag: "",
      road_safety_tax: "",
    },
    onSubmit: async (values) => {
      try {
        const response = await updateStatePrice(token, values);
        console.log(response);
        response.status === 200
          ? notifySuccess(response.data.message)
          : notifyError(response.data.message);
      } catch (error) {
        notifyError("An error occurred");
      }
    },
  });

  const fetchStates = useCallback(async () => {
    const response = await getAllStates(token);
    setStates(response.data.result);
  }, [token]);
  const fetchBrands = useCallback(async () => {
    const response = await getAllBrands(token);
    setBrands(response.data.result);
  }, [token]);

  useEffect(() => {
    fetchStates();
    fetchBrands();
  }, [fetchStates, fetchBrands]);

  const handleBrandChange = async (selectedBrand) => {
    formik.setFieldValue("brand", selectedBrand);
    formik.setFieldValue("model", "-1");

    formik.setFieldValue("version", "-1");
    const response = await getCarModelByBrandId(token, {
      brand_id: selectedBrand,
    });
    setModels(response.data.result);
  };
  const handleModelChange = async (selectedModel) => {
    formik.setFieldValue("model", selectedModel);
    formik.setFieldValue("version", "-1");
    const response = await getCarVersionByModelId(token, {
      model_id: selectedModel,
    });
    setVersions(response.data.result);
  };
  const formFields = [
    {
      id: "brand",
      label: "Brand",
      items: brands,
      handler: handleBrandChange,
      name: "brand_name",
    },
    {
      id: "model",
      label: "Model",
      items: models,
      handler: handleModelChange,
      name: "model_name",
    },
    {
      id: "version",
      label: "Version",
      items: versions,
      handler: formik.handleChange,
      name: "version_name",
    },
    {
      id: "state",
      label: "State",
      items: states,
      handler: formik.handleChange,
      name: "state_name",
    },
  ];
  const inputFields = [
    { id: "ex_showroom_price", label: "Ex Showroom Price" },
    { id: "rto", label: "RTO" },
    { id: "insurance", label: "Insurance" },
    { id: "registration", label: "Registration Charges" },
    { id: "handling_logistic", label: "Handling/Logistic Charges" },
    { id: "tcs", label: "Tax Collected at Source (TCS)" },
    { id: "mcd", label: "MCD" },
    { id: "hypothecation", label: "Hypothecation Charges" },
    { id: "ecc", label: "Environment Compensation Charge (ECC)" },
    { id: "incidental", label: "Incidental Charges" },
    { id: "number_plate", label: "Number Plate" },
    { id: "fast_tag", label: "Fast Tag" },
    { id: "road_safety_tax", label: "Road Safety Tax" },
  ];
  return (
    <div>
      <Breadcrumbs />
      {brands.length > 0 && states.length > 0 && (
        <Card className="p-3">
          <form onSubmit={formik.handleSubmit}>
            {formFields.map(({ id, label, items, handler, name }) => (
              <FormSelect
                key={id}
                controlId={id}
                label={label}
                items={items}
                name={id}
                value={formik.values[id]}
                setFieldValue={formik.setFieldValue}
                touched={formik.touched}
                errors={formik.errors}
                getValues={handler}
                defaultName={`Select ${label}`}
                itemName={name}
              />
            ))}
            {inputFields.map(({ id, label }) => (
              <Form.Group controlId={id} key={id}>
                <Form.Label>{label}</Form.Label>
                <Form.Control
                  type="number"
                  name={id}
                  value={formik.values[id]}
                  onChange={formik.handleChange}
                  placeholder={`Enter ${label}`}
                />
              </Form.Group>
            ))}

            <Button type="submit" className="mt-3" variant="primary">
              Submit
            </Button>
          </form>
        </Card>
      )}
    </div>
  );
}
