import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs";
import { getPageById, updatePageById } from "./apiHandler";
import PageValidationSchema from "./validation";
import { getToken } from "../../utility/constants";
import { useFormik } from "formik";
import { notifySuccess } from "../../utility/common";
import PageForm from "./PageForm";
import { useEffect, useState } from "react";

export default function EditPage() {
  const navigate = useNavigate();
  const token = getToken();
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    parent: 0,
    page_name: "",
    slug: "",
    heading: "",
    breadcrumb: "",
    image: "",
    description: "",
    content: "",
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    meta_canonical: "",
    status: "",
  });

  useEffect(() => {
    const fetchPageData = async () => {
      const response = await getPageById(id, token);
      console.log(response.data.page);
      setInitialValues(response.data.page);
    };

    fetchPageData();
  }, [id, token]);

  const { handleSubmit, values, setFieldValue, touched, errors } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: PageValidationSchema,
    onSubmit: (values) => {
      delete values.id;
      delete values.created_date;
      delete values.updated_date;
      updatePageById(id, values, token).then((res) => {
        console.log(res);
        if (res.status === 200) {
          notifySuccess("Page updated successfully");
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      });
    },
  });
  return (
    <>
      <Breadcrumbs />
      <div>
        <PageForm
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          handleSubmit={handleSubmit}
          type={"UPDATE"}
        />
      </div>
    </>
  );
}
