import { Accordion, Card } from "react-bootstrap";

import FormControl from "../../../components/FormControl";
import { useFormik } from "formik";
import { updateCategoryAds } from "./adCategoryApi";
import { notifyError, notifySuccess } from "../../../utility/common";
import { getToken } from "../../../utility/constants";
import validationSchema from "./categoryAdValidation";


export default function CategoryAdsForm({
  initialAdData,
  initialHeaderData,
  template_slug,
  setSelectedCategoryAdData,
  setSelectedCategoryHeaderData
}) {
  const token = getToken();
  // const adsCount = [1, 2, 3, 4, 5, 6];
  const adsCount = ["One", "Two", "Three", "Four", "Five", "Six"];
  const initialValues = {
    desktop_header_script: initialHeaderData?.desktop_header_script || "",
    mobile_header_script: initialHeaderData?.mobile_header_script || "",
  };
  adsCount.forEach((ad, index) => {
    // initialValues[`desktop_header_script_${ad}`] =
    //   initialAdData[index]?.desktop_header_script || "";
    initialValues[`desktop_body_script_${ad}`] =
      initialAdData[index]?.desktop_body_script || "";
    initialValues[`desktop_position_${ad}`] =
      initialAdData[index]?.desktop_position || index + 1;
    // initialValues[`mobile_header_script_${ad}`] =
    //   initialAdData[index]?.mobile_header_script || "";
    initialValues[`mobile_body_script_${ad}`] =
      initialAdData[index]?.mobile_body_script || "";
    initialValues[`mobile_position_${ad}`] =
      initialAdData[index]?.mobile_position || index + 1;
  });
  console.log("initialValues", initialValues);
  const { handleSubmit, errors, values, touched, setFieldValue } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, action) => {
      try {
        const payload = { template_slug, desktop_header_script: values?.desktop_header_script, mobile_header_script: values?.mobile_header_script, ads: [] };
        console.log("values", values);
        adsCount.forEach((ad_num) => {
          const adObj = {};
          if (
            values[`desktop_body_script_${ad_num}`]
          ) {
            adObj.desktop_body_script = values[`desktop_body_script_${ad_num}`];
            if (Number(values[`desktop_position_${ad_num}`])) {
              adObj.desktop_position = Number(
                values[`desktop_position_${ad_num}`]
              );
            }
          }

          if (
            values[`mobile_body_script_${ad_num}`]) {
            adObj.mobile_body_script = values[`mobile_body_script_${ad_num}`];
            if (Number(values[`mobile_position_${ad_num}`])) {
              adObj.mobile_position = Number(
                values[`mobile_position_${ad_num}`]
              );
            }
          }
          if (
            values[`desktop_body_script_${ad_num}`] || values[`mobile_body_script_${ad_num}`]
          ) {
            payload.ads.push(adObj);
          }
          console.log("payload", payload);
        });
        if (payload.ads.length === 0) {
          notifyError("Please add at least one ad");
          return;
        }
        const response = await updateCategoryAds(token, payload);
        console.log("response", response);
        if (response.status === 200) {
          notifySuccess("Category ads updated successfully");
          setSelectedCategoryAdData(response?.data?.data);
          setSelectedCategoryHeaderData({desktop_header_script : payload?.desktop_header_script, mobile_header_script : payload?.mobile_header_script});
        }
      } catch (error) {
        console.log(error);
        notifyError(error.response.data.message);
      }
    }
  });
  return (
    <div class="row gy-3">
      <div class="col-lg-3 order-lg-2 p-3">
        <Card className="px-3" style={{ width: "100%" }}>
          <div className="py-3" style={{ width: "100%" }}>
            <button
              type="button"
              className="green-filled-button"
              style={{ width: "100%" }}
              onClick={() => {
                document.getElementById("category_ad_submit_btn").click();
              }}
            >
              <span className="p-5"> Save</span>
            </button>
          </div>
        </Card>
      </div>
      <form class="col-lg-9 order-lg-1 text-white p-3" onSubmit={handleSubmit}>
        <Card className="p-3 mb-3" style={{ width: "100%" }}>
          <div className="row d-flex gap-4">
            <FormControl
              controlId={`desktop_header_script`}
              label="Desktop Header Script"
              placeholder="Desktop Header Script"
              touched={touched}
              errors={errors}
              type="text"
              rows={3}
              as={"textarea"}
              name={`desktop_header_script`}
              value={values[`desktop_header_script`]}
              setFieldValue={setFieldValue}
            />
          </div>
        </Card>
        <Card className="p-3 mb-3" style={{ width: "100%" }}>
          <div className="row d-flex gap-4">
            <FormControl
              controlId={`mobile_header_script`}
              label="Mobile Header Script"
              placeholder="Mobile Header Script"
              touched={touched}
              errors={errors}
              type="text"
              rows={3}
              as={"textarea"}
              name={`mobile_header_script`}
              value={values[`mobile_header_script`]}
              setFieldValue={setFieldValue}
            />
          </div>
        </Card>
        {adsCount.map((ad_num, index) => {
          return (
            <Accordion key={index} defaultActiveKey={"One"} className="mb-4">
              <Accordion.Item
                eventKey={ad_num}
                className="card-height header_padding_top pb-2"
              >
                <Accordion.Header className="p-3 pb-2 ps-2 pt-4">
                  <h3 className="fw-bold text-center">Advertise {ad_num}</h3>
                </Accordion.Header>
                <Accordion.Body>
                  <Card className="p-3 mb-3" style={{ width: "100%" }}>
                    <div className="row d-flex gap-4">
                      <h5 className="fw-bold">Desktop Ads</h5>
                      {/* <FormControl
                        controlId={`desktop_header_script_${ad_num}`}
                        label="Desktop Header Script"
                        placeholder="Desktop Header Script"
                        touched={touched}
                        errors={errors}
                        type="text"
                        rows={3}
                        as={"textarea"}
                        name={`desktop_header_script_${ad_num}`}
                        value={values[`desktop_header_script_${ad_num}`]}
                        setFieldValue={setFieldValue}
                      /> */}
                      <FormControl
                        controlId={`desktop_body_script_${ad_num}`}
                        label="Desktop Body Script"
                        placeholder="Desktop Body Script"
                        touched={touched}
                        errors={errors}
                        type="text"
                        rows={3}
                        as={"textarea"}
                        name={`desktop_body_script_${ad_num}`}
                        value={values[`desktop_body_script_${ad_num}`]}
                        setFieldValue={setFieldValue}
                      />
                      <FormControl
                        controlId={`desktop_position_${ad_num}`}
                        label="Desktop Position (Number between 1 to 6)"
                        placeholder="Desktop Position (Number between 1 to 6)"
                        touched={touched}
                        errors={errors}
                        type="text"
                        name={`desktop_position_${ad_num}`}
                        value={values[`desktop_position_${ad_num}`] || index + 1}
                        setFieldValue={setFieldValue}
                        onChange={(value) =>
                          setFieldValue(
                            `desktop_position_${ad_num}`,
                            Math.abs(value)
                          )
                        }
                      />
                      <h5 className="fw-bold">Mobile Ads</h5>
                      {/* <FormControl
                        controlId={`mobile_header_script_${ad_num}`}
                        label="Mobile Header Script"
                        placeholder="Mobile Header Script"
                        touched={touched}
                        errors={errors}
                        type="text"
                        rows={3}
                        as={"textarea"}
                        name={`mobile_header_script_${ad_num}`}
                        value={values[`mobile_header_script_${ad_num}`]}
                        setFieldValue={setFieldValue}
                      /> */}
                      <FormControl
                        controlId={`mobile_body_script_${ad_num}`}
                        label="Mobile Body Script"
                        placeholder="Mobile Body Script"
                        touched={touched}
                        errors={errors}
                        type="text"
                        rows={3}
                        as={"textarea"}
                        name={`mobile_body_script_${ad_num}`}
                        value={values[`mobile_body_script_${ad_num}`]}
                        setFieldValue={setFieldValue}
                      />
                      <FormControl
                        controlId={`mobile_position_${ad_num}`}
                        label="Mobile Position (Number between 1 to 6)"
                        placeholder="Mobile Position (Number between 1 to 6)"
                        touched={touched}
                        errors={errors}
                        type="text"
                        name={`mobile_position_${ad_num}`}
                        value={values[`mobile_position_${ad_num}`] || index + 1}
                        setFieldValue={setFieldValue}
                        onChange={(value) =>
                          setFieldValue(
                            `mobile_position_${ad_num}`,
                            Math.abs(value)
                          )
                        }
                      />
                    </div>
                  </Card>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          );
        })}
        <div className="py-3">
          <button
            id="category_ad_submit_btn"
            type="submit"
            className="green-filled-button"
          >
            <span className="p-5"> Save</span>
          </button>
        </div>
      </form>
    </div>
  );
}
