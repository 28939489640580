import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../../utility/constants";
import FAQapiHandler from "./FAQapiHandler";
import { useCallback, useEffect, useState } from "react";
import BikeAnswerForm from "./BikeAnswerForm";
import { notifyError, notifySuccess } from "../../utility/common";

export default function EditBikeAnswer() {
  const navigate = useNavigate();
  const token = getToken();
  const { answerId } = useParams();
  const [initialValues, setInitialValues] = useState({
    question_id: "",
    answer_name: "",
    answer_status: "",
  });

  useEffect(() => {
    const getBikeAnswers = async () => {
      try {
        const response = await FAQapiHandler.getBikeAnswerValues(
          token,
          answerId
        );
        setInitialValues({
          question_id: response.data.data.question_id,
          answer_name: response.data.data.answer_name,
          answer_status: response.data.data.answer_status,
        });
      } catch (error) {
        console.log(error);
      }
    };
    getBikeAnswers();
  }, [token, answerId]);

  const EditBikeAnswer = useCallback(
    async (data) => {
      try {
        console.log(data);
        const response = await FAQapiHandler.updateBikeAnswer(
          token,
          answerId,
          data
        );
        console.log(response);
        if (response.status === 200) {
          notifySuccess(response.data.message);
          setTimeout(() => {
            navigate("/bike-answers");
          }, 2000);
        }
      } catch (error) {
        notifyError(error.response.data.message);
        console.log(error);
      }
    },
    [token, answerId, navigate]
  );
  console.log(initialValues);
  return (
    <>
      <BikeAnswerForm initialValues={initialValues} onSubmit={EditBikeAnswer} />
    </>
  );
}
