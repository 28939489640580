import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../../request";

// export const getadPageList = (token, payload) => {
//   return axios.post(`${BASE_URL_AUTOX}ad-page/list`, payload, getConfig(token));
// };

// export const getadPagelistName = (token, payload) => {
//   return axios.post(
//     `${BASE_URL_AUTOX}ad-page/lists`,
//     payload,
//     getConfig(token)
//   );
// };
// export const deleteadPage = (token, id) => {
//   return axios.delete(
//     `${BASE_URL_AUTOX}ad-page/delete/${id}`,
//     getConfig(token)
//   );
// };

// export const addadPage = (token, payload) => {
//   return axios.post(`${BASE_URL_AUTOX}ad-page/add`, payload, getConfig(token));
// };

// export const updateadPage = (token, id, payload) => {
//   return axios.put(
//     `${BASE_URL_AUTOX}ad-page/update/${id}`,
//     payload,
//     getConfig(token)
//   );
// };

// export const getadPageDetails = (token, id) => {
//   return axios.get(`${BASE_URL_AUTOX}ad-page/${id}`, getConfig(token));
// };

// export const getWidgetFromadPage = (token, payload) => {
//   return axios.post(
//     `${BASE_URL_AUTOX}ad-widgets/lists`,
//     payload,
//     getConfig(token)
//   );
// };

// export const updateWidgets = (token, payload) => {
//   return axios.post(
//     `${BASE_URL_AUTOX}ad-widgets/update`,
//     payload,
//     getConfig(token)
//   );
// };

export const getAllPopupAds = (token) => {
  return axios.get(`${BASE_URL_AUTOX}ad-page/get-popup-ads`, getConfig(token));
};

export const updatePopupAds = (token, payload) => {
  return axios.put(
    `${BASE_URL_AUTOX}ad-page/update-popup-ads`,
    payload,
    getConfig(token)
  );
};

export default {
  getAllPopupAds,
  updatePopupAds
};
