import { Card, Form } from "react-bootstrap";
import HeadingTwo from "../../components/HeadingTwo";
import FormSelect from "../../components/FormSelect";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utility/constants";
import SafetyRatingForm from "./SafetyRatingForm";
import { notifyError, notifySuccess } from "../../utility/common";
import { addSafetyRatingAPI } from "./apiHandler";

export default function SafetyRatingAdd() {
  const navigation = useNavigate();
  const token = getToken();

  const addSafetyRating = async (data) => {
    try {
      delete data.brand_id;
      console.log(data);
      const response = await addSafetyRatingAPI(data, token);
      console.log(response, "response");
      if (response.status === 200) {
        notifySuccess("Safety Rating added successfully");
        setTimeout(() => {
          navigation("/safety-rating");
        }, 2000);
      } else {
        notifyError("Error while adding Safety Rating");
      }
    } catch (err) {
      console.log(err);
      notifyError(err.repsonse.data.message);
    }
  };
  const initialValues = {
    brand_id: "-1",
    model_id: "-1",
    yearoftesting: "",
    crashtestweight: "",
    madein: "",
    globalncapadultmaxscore: "",
    globalncapadultrating: "",
    globalncapadultachievedscore: "",
    globalncapchildmaxscore: "",
    globalncapchildrating: "",
    globalncapchildachievedscore: "",
    globalncapratingvideourl: "",
    globalncapbrochure: "",
    bharatncapadultmaxscore: "",
    bharatncapadultrating: "",
    bharatncapadultachievedscore: "",
    bharatncapchildmaxscore: "",
    bharatncapchildrating: "",
    bharatncapchildachievedscore: "",
    bharatncapratingvideourl: "",
    bharatncapbrochure: "",
  };

  return (
    <div>
      <SafetyRatingForm
        initialValues={initialValues}
        onSubmit={addSafetyRating}
        title={"Add Safety Rating"}
      />
    </div>
  );
}
