import CarModelBtn from "./CarModelBtn";
import DownloadIcon from "../assets/icons/bootstrap-icons/download.svg";
import {
  downloadPostContent,
  downloadPostMetaData,
} from "../pages/PostManagement/apiHandler";
import { getToken } from "../utility/constants";
import { BASE_URL_AUTOX } from "../request";

const PostButtonGroup = () => {
  const token = getToken();
  const downloadPostContentFunction = async () => {
    try {
      const response = await downloadPostContent(token);

      if (response.data.download_url) {
        // Prepend the API path to the download URL
        const downloadUrl = `${BASE_URL_AUTOX}${response.data.download_url}`;

        // Create a link element dynamically
        const link = document.createElement("a");

        // Set the URL to the full path (API_PATH + relative URL)
        link.href = downloadUrl;

        // Set the download attribute to specify the filename for download
        link.setAttribute("download", "post_contents.zip");

        // Append the link to the document body (required to trigger click in some browsers)
        document.body.appendChild(link);

        // Trigger a click event on the link to start the download
        link.click();

        // Remove the link from the document body
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error downloading post content:", error);
    }
  };
  const downloadPostMeta = async () => {
    try {
      const response = await downloadPostMetaData(token);
      console.log(response);
      if (response.data.download_url) {
        const downloadUrl = `${BASE_URL_AUTOX}${response.data.download_url}`;
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "posts.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error downloading post meta:", error); // Added error handling
    }
  };

  const downloadLink = () => {};
  return (
    <div className="w-100">
      <div className="btngroup d-flex flex-wrap justify-content-end">
        <CarModelBtn
          label={"Download Each Post Content in Text Files"}
          icon={DownloadIcon}
          onClick={downloadPostContentFunction}
          className={
            "preview-button d-flex align-items-center justify-content-between me-1 mb-2 text-white px-3"
          }
        />

        <CarModelBtn
          label={"Download Post Meta Data"}
          icon={DownloadIcon}
          onClick={downloadPostMeta}
          className={
            "download-button d-flex align-items-center justify-content-between me-1 mb-2 text-white px-3 py-2"
          }
        />
        <CarModelBtn
          label={"Download Links For Broken Check"}
          icon={DownloadIcon}
          onClick={downloadLink}
          className={
            "export-magazine-button d-flex align-items-center justify-content-between me-1 mb-2 text-white px-3 py-2"
          }
        />
      </div>
    </div>
  );
};

export default PostButtonGroup;
