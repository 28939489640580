import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import ImageUpload from "./ImageUpload.js";
import ImageListing from "./ImageListing.js";
export default function MediaTab({
  onSelectImage,
  propSelectedImage,
  type,
  defaultActiveKey = "upload",
  propShow,
  propSetShow,
}) {
  const [show, setShow] = useState(false);
  const imageListingRef = useRef();
  const handleClose = () => {
    setShow(false);
    propSetShow();
  };
  const handleUpload = (val) => {
    if (val) setShow(true);
  };
  const refreshImages = () => {
    imageListingRef.current.fetchImages(1);
  };

  useEffect(() => {
    setShow(propShow);
  }, [propShow]);

  useEffect(() => {
    if (!show && typeof propSetShow === "function") {
      propSetShow(false);
    }
  }, [show, propSetShow]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="custom-media-modal"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Media</Modal.Title>
        </Modal.Header>
        <div className="modal-body">
          <Tabs defaultActiveKey={defaultActiveKey} id="media-tab">
            <Tab eventKey="upload" title="Upload from Computer">
              <ImageUpload
                onUploadComplete={handleUpload}
                refreshImages={refreshImages}
              />
            </Tab>
            <Tab eventKey="library" title="Media Library">
              <ImageListing
                propSelectedImage={propSelectedImage}
                ref={imageListingRef}
                onSelectImage={onSelectImage}
                setShow={setShow}
                type={type}
                // Pass function setter to ImageListing
              />
            </Tab>
          </Tabs>
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
