import { useEffect, useState } from "react";
import { getToken } from "../../../utility/constants";
import apiHandler from "./apiHandler";
import { notifyError, notifySuccess } from "../../../utility/common";
import Layout from "../../../components/Layout";
import Breadcrumbs from "../../../Breadcrumbs";
import AddTrendingBike from "./AddTrendingBike";
import TrendingBikesList from "./TrendingBikeList";

export default function TrendingBikes({ children }) {
  const token = getToken();
  const [trendingBike, setTrendingBike] = useState([]);

  useEffect(() => {
    getTrendingBikeList();
  }, []);

  const getTrendingBikeList = () => {
    apiHandler
      .listTrendingBike(token)
      .then((res) => {
        console.log(res.data.result);
        setTrendingBike(res.data.result);
      })
      .catch((error) => {
        notifyError(error.response.data.message);
      });
  };

  const handleDelete = (id) => {
    apiHandler
      .deleteTrendingBike(token, id)
      .then((res) => {
        getTrendingBikeList();
        notifySuccess("Removed Successfully !!");
      })
      .catch((error) => {
        notifyError(
          error.response
            ? error.response.data.message
            : "There is problem in error"
        );
      });
  };

  const updateSort = (trendingItems) => {
    apiHandler
      .sortTrendingBike(token, { trendingItems })
      .then((result) => {
        setTrendingBike(result.data.result);
      })
      .catch((error) => {
        notifyError(
          error.response
            ? error.response.data.message
            : "There is problem in error"
        );
      });
  };

  return (
    <Layout>
      <div className="row dashboard">
        <div className="d-flex justify-content-between align-items-center multipleCityPriceMain mb-3 flex-wrap">
          <Breadcrumbs />
        </div>
        <div className="row">
          <div className="col-xxl-8 col-md-7">
            <TrendingBikesList
              updateSort={updateSort}
              bikeData={trendingBike}
              handleDelete={handleDelete}
            />
          </div>
          <div className="col-xxl-4 col-md-5">
            <AddTrendingBike onSuccess={getTrendingBikeList} />
          </div>
        </div>
      </div>
    </Layout>
  );
}
