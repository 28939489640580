import React, { useState } from "react";
import {
  BrowserRouter,
  Navigate,
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ReactSVG } from "react-svg";
import Layout from "../../../components/Layout";
import Heading from "../../../components/Heading";
import HeadingTwo from "../../../components/HeadingTwo";
import PlusLargeIcon from "../../../assets/icons/bootstrap-icons/plus-lg.svg";
import DownloadIcon from "../../../assets/icons/bootstrap-icons/download.svg";

export default function BikeDealersMain({ children }) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="row dashboard">
        <div>{children}</div>
      </div>
    </Layout>
  );
}
