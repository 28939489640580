import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function DeleteConfirmationModalComponent({
  setDeleteConfirm,
  show,
  handleClose,
  type,
}) {
  const [deleting, setDeleting] = useState(false);

  const handleDeleteClick = async () => {
    setDeleting(true);
    try {
      await setDeleteConfirm();
    } catch (error) {
      console.error("Error deleting:", error);
    } finally {
      setTimeout(() => {
        setDeleting(false);
      }, 2000);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="delete-modal-message">
          {"Delete Confirmation"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <div className="message">
            {" "}
            {"Are you sure you want to delete this record?"}{" "}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant={type ? "danger" : "danger"}
          onClick={handleDeleteClick}
          disabled={deleting}
        >
          {deleting ? "Deleting..." : type === "delete" ? "Delete" : "Block"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
