import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
  getToken,
  metaRobotsFollow,
  metaRobotsIndex,
} from "../../../utility/constants";
import { notifyError, notifySuccess } from "../../../utility/common";
import APIHandler from "./apiHandler";
import FormSelect from "../../../components/FormSelect";
import FormControl from "../../../components/FormControl";

export default function CarServiceCentreForm({
  type,
  touched,
  errors,
  values,
  setFieldValue,
  handleSubmit,
}) {
  const token = getToken();
  const [cities, setCities] = useState([]);
  const [brands, setBrands] = useState([]);
  const [states, setStates] = useState([]);
  console.log(values, "values");

  useEffect(() => {
    getStateList();
    getBrands();
  }, []);
  useEffect(() => {
    if (values["state_id"] > 0) getCityList();
  }, [values["state_id"]]);

  const getCityList = async () => {
    APIHandler.getCityBystate(token, values["state_id"])
      .then((res) => {
        console.log("cities", res);
        setCities(res.data.data.result);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  };
  const getStateList = async () => {
    APIHandler.getAllStates(token)
      .then((res) => {
        // console.log("states", res)
        setStates(res.data.result);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  };

  const getBrands = async () => {
    APIHandler.getAllBrands(token)
      .then((res) => {
        // console.log("brands", res)
        setBrands(res.data.result);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  };
  console.log("values", values, states, cities, brands);

  return (
    <Form onSubmit={handleSubmit}>
      <div className="row col-12 mt-1 p-3">
        <div className="col-6 px-0 pe-3 my-3">
          <div className="">
            <FormSelect
              controlId={"BrandControlSelect"}
              label={"Select Brand"}
              name={"brand_id"}
              defaultName={"Select Brand"}
              setFieldValue={setFieldValue}
              items={brands}
              itemName={"brand_name"}
              touched={touched}
              errors={errors}
              value={values["brand_id"]}
              isRequired={true}
            />
          </div>
        </div>

        <div className="col-6 px-0 ps-3 my-3">
          <div className="">
            <FormSelect
              controlId={"CityControlSelect"}
              label={"Select State"}
              name={"state_id"}
              defaultName={"Select State"}
              setFieldValue={setFieldValue}
              items={states}
              itemName={"state_name"}
              touched={touched}
              errors={errors}
              value={values["state_id"]}
              isRequired={true}
            />
          </div>
        </div>
        <div className="col-6 px-0 pe-3 my-3">
          <div className="">
            <FormSelect
              controlId={"CityControlSelect"}
              label={"Select City"}
              name={"city_id"}
              defaultName={"Select City"}
              setFieldValue={setFieldValue}
              items={cities}
              itemName={"city_name"}
              touched={touched}
              errors={errors}
              value={values["city_id"]}
              isRequired={true}
            />
          </div>
        </div>

        <div className="col-6 px-0 ps-3 my-3">
          <div className="">
            <FormControl
              controlId={"ServiceCentreNameInput"}
              label={"Service Centre Name"}
              placeholder={"Service Centre Name"}
              touched={touched}
              errors={errors}
              name={"service_centre_name"}
              value={values["service_centre_name"]}
              setFieldValue={setFieldValue}
              isRequired={true}
            />
          </div>
        </div>

        <div className="col-6 px-0 pe-3 my-3">
          <div className="">
            <FormControl
              controlId={"ServiceCentreUrlInput"}
              label={"Service Centre Url"}
              placeholder={"Url"}
              touched={touched}
              errors={errors}
              name={"service_centre_url"}
              value={values["service_centre_url"]}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>
        <div className="col-6 px-0 ps-3 my-3">
          <div className="">
            <FormControl
              controlId={"SchoolAddressInput"}
              label={"Service Centre Address"}
              placeholder={"Service Centre Address"}
              touched={touched}
              errors={errors}
              name={"service_centre_address"}
              value={values["service_centre_address"]}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>

        <div className="col-6 px-0 pe-3 my-3">
          <div className="">
            <FormControl
              controlId={"PhoneNumberInput"}
              label={"Phone No."}
              placeholder={"(+CC) Phone No."}
              touched={touched}
              errors={errors}
              name={"phone_number"}
              value={values["phone_number"]}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>

        <div className="col-6 px-0 ps-3 my-3">
          <div className="">
            <FormControl
              controlId={"EmailInput"}
              label={"Email"}
              placeholder={"Email"}
              touched={touched}
              errors={errors}
              name={"email_id"}
              value={values["email_id"]}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>

        <div className="col-6 px-0 pe-3 my-3">
          <div className="">
            <FormControl
              as={"textarea"}
              rows={5}
              controlId={"servcieCentreContentInput"}
              label={"Service Centre Content"}
              placeholder={"Service Centre Content"}
              touched={touched}
              errors={errors}
              name={"service_centre_content"}
              value={values["service_centre_content"]}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>
      </div>

      <div className="table-heading fw-bold">SEO TAGS</div>
      <div className="row col-12 mt-1 p-3">
        <div className="col-6 px-0 pe-3 my-3">
          <div className="">
            <FormControl
              controlId={"SeoTitleInput"}
              label={"SEO Title"}
              placeholder={"SEO Title"}
              touched={touched}
              errors={errors}
              name={"meta_title"}
              value={values["meta_title"]}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>

        <div className="col-6 px-0 ps-3 my-3">
          <div className="">
            <FormControl
              controlId={"MetaDescriptionInput"}
              label={" Meta Description"}
              placeholder={" Meta Description"}
              touched={touched}
              errors={errors}
              name={"meta_description"}
              value={values["meta_description"]}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>

        <div className="col-6 px-0 pe-3 my-3">
          <div className="">
            <FormControl
              controlId={"MetaKeywordsInput"}
              label={"Meta Keywords"}
              placeholder={"Meta Keywords"}
              touched={touched}
              errors={errors}
              name={"meta_keyword"}
              value={values["meta_keyword"]}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>

        <div className="col-6 px-0 ps-3 my-3">
          <div className="">
            <FormControl
              controlId={"BreadcrumbsInput"}
              label={"Breadcrumbs"}
              placeholder={"Breadcrumbs"}
              touched={touched}
              errors={errors}
              name={"breadcrumbs"}
              value={values["breadcrumbs"]}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>

        <div className="col-6 px-0 pe-3 my-3">
          <div className="">
            <FormControl
              controlId={"CanonicalUrlInput"}
              label={"Canonical Url"}
              placeholder={"Canonical Url"}
              touched={touched}
              errors={errors}
              name={"canonical_url"}
              value={values["canonical_url"]}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>

        <div className="col-6 px-0 ps-3 my-3">
          <div className="">
            <FormSelect
              controlId={"MetaRobotsIndexSelect"}
              label={"Meta Robots Index"}
              name={"robots_index"}
              defaultName={"Select"}
              setFieldValue={setFieldValue}
              items={metaRobotsIndex}
              itemName={"value"}
              touched={touched}
              errors={errors}
              value={values["robots_index"]}
            />
          </div>
        </div>

        <div className="col-6 px-0 pe-3 my-3">
          <div className="">
            <FormSelect
              controlId={"MetaRobotsFollowSelect"}
              label={"Meta Robots Follow"}
              name={"robots_follow"}
              defaultName={"Select"}
              setFieldValue={setFieldValue}
              items={metaRobotsFollow}
              itemName={"value"}
              touched={touched}
              errors={errors}
              value={values["robots_follow"]}
            />
          </div>
        </div>
      </div>
      <Button
        type="submit"
        variant="btnBlue"
        className="d-flex align-items-center justify-content-between my-3"
      >
        {type} Car Service Centre
      </Button>
    </Form>
  );
}
