import { Card, Form } from "react-bootstrap";
import Breadcrumbs from "../../Breadcrumbs";
import HeadingTwo from "../../components/HeadingTwo";
import DynamicSelect from "../CarUserReview/EditCarUserReview/DynamicSelect";
import FormControl from "../../components/FormControl";
import apiHandler, { addNewNewsCampaign } from "./apiHandler";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { getToken } from "../../utility/constants";
import ReactDatePicker from "react-datepicker"; // Import your date picker
import FormSelect from "../../components/FormSelect";
import { notifyError, notifySuccess } from "../../utility/common";
import { useNavigate } from "react-router-dom";

export default function NewsCampaignAdd() {
  const [templateList, setTemplateList] = useState([]);
  const [newsletter, setNewsletter] = useState([]);
  const [templateRender, setTemplateRender] = useState("");
  const token = getToken();
  const navigate = useNavigate();

  const handleTemplateRender = async (id) => {
    if (id) {
      const res = await apiHandler.getTemplateBodyById(id, token);
      setTemplateRender(res.data[0].html_body);
    } else {
      setTemplateRender(""); // Clear preview if no template is selected
    }
  };

  useEffect(() => {
    const getTemplateList = async () => {
      const response = await apiHandler.getTemplateList(token);
      setTemplateList(response.data);
    };
    const getNewsletterList = async () => {
      const response = await apiHandler.getNewsletterList(token);
      console.log(response.data);
      setNewsletter(response.data);
    };
    getTemplateList();
    getNewsletterList();
  }, [token]);

  const convertToIST = (utcDate) => {
    const dateUTC = new Date(utcDate); // Convert to Date object
    const istOffset = 5.5 * 60 * 60 * 1000; // Offset in milliseconds (+5:30 hours)
    const istDate = new Date(dateUTC.getTime() + istOffset);
    return istDate.toISOString(); // Return IST-adjusted ISO string
  };

  const { values, touched, errors, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      name: "",
      subject: "",
      feed_url: "",
      template_id: "",
      utm_source: "",
      newsletter_id: "",
      status: 1, // Default to send immediately
      publish_date: new Date(),
      isRecurring: false, // To track if it's recurring
      end_date: null,
      recurrence: "daily", // Default recurrence
      recurrenceTime: new Date(),
    },
    onSubmit: async (values) => {
      const payload = { ...values };
      if (!values.isRecurring && values.status === 1) {
        payload.publish_type = "immediate";
        delete payload.end_date;
        delete payload.isRecurring;
        delete payload.publish_date;
        delete payload.recurrence;
        delete payload.recurrenceTime;
        // delete payload.status
      }
      if (!values.isRecurring && values.status === 2) {
        payload.publish_type = "scheduled_one_time";
        delete payload.end_date;
        delete payload.isRecurring;
        delete payload.recurrence;
        delete payload.recurrenceTime;

        payload.publish_date = convertToIST(payload.publish_date);
        console.log("payload", payload);
        // return;
      }
      if (values.isRecurring && values.status === 2) {
        payload.publish_type = "scheduled_recurring";
        payload.publish_date = convertToIST(payload.publish_date);
        payload.end_date = convertToIST(payload.end_date);
        payload.recurrenceTime = convertToIST(payload.recurrenceTime);
        delete payload.isRecurring;
        // console.log("payload", payload);
        // return;
      }

      try {
        const response = await addNewNewsCampaign(payload, token);
        if (response.status === 200) {
          notifySuccess("User subscriptions added successfully");
          setTimeout(() => {
            navigate("/news-letter-campaign");
          }, 1500);
        }
      } catch (error) {
        notifyError(error.response.data.message);
        console.log("error", error);
      }
      console.log(values);
    },
  });

  return (
    <>
      <Breadcrumbs />
      <div className="d-flex gap-3" style={{ height: "100%" }}>
        <form
          onSubmit={handleSubmit}
          className="d-flex flex-column gap-4"
          style={{ width: "30%" }}
        >
          <Card className="p-3">
            <HeadingTwo title={"Primary Details"} />
            <div>
              <FormControl
                controlId={"name"}
                label={"NewsCampaign Name"}
                type={"text"}
                name={"name"}
                value={values.name}
                touched={touched}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <FormControl
                controlId={"subject"}
                label={"Subject"}
                type={"text"}
                name={"subject"}
                value={values.subject}
                touched={touched}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <FormControl
                controlId={"feed_url"}
                label={"Feed Url"}
                type={"text"}
                name={"feed_url"}
                value={values.feed_url}
                touched={touched}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <FormSelect
                controlId={"template_id"}
                label={"Template"}
                items={templateList}
                itemName={"template_name"}
                name={"template_id"}
                value={values.template_id}
                touched={touched}
                setFieldValue={(field, value) => {
                  setFieldValue(field, Number(value));
                  handleTemplateRender(value); // Call the function on selection
                }}
                errors={errors}
              />
              <FormControl
                controlId={"utm_source"}
                label={"Utm Source"}
                type={"text"}
                name={"utm_source"}
                value={values.utm_source}
                touched={touched}
                setFieldValue={setFieldValue}
                errors={errors}
              />
            </div>
          </Card>
          <Card className="p-3">
            <HeadingTwo title={"Contact List"} />
            <div className="p-3">
              {newsletter.map((item) => (
                <div
                  className="checkbox-container mb-2"
                  key={item.newsletter_id}
                >
                  <Form.Check
                    type="checkbox"
                    id={`newsletter-${item.newsletter_id}`}
                    label={`${item.name} (${item.subscription_count})`}
                    checked={values.newsletter_id === item.newsletter_id}
                    onChange={() => {
                      const newSelection =
                        values.newsletter_id === item.newsletter_id
                          ? null
                          : item.newsletter_id;
                      setFieldValue("newsletter_id", newSelection);
                    }}
                  />
                </div>
              ))}
            </div>
          </Card>
          <Card className="p-3">
            <HeadingTwo title={"Timing"} />
            <div className="row p-3">
              <div className="row checkbox-container mb-2">
                <Form.Check
                  type="radio"
                  id="send-immediately"
                  label="Send Immediately"
                  checked={values.status === 1}
                  onChange={() => {
                    setFieldValue("status", 1);
                    setFieldValue("isRecurring", false);
                  }}
                />
              </div>
              <div className="row checkbox-container mb-2">
                <Form.Check
                  type="radio"
                  id="schedule"
                  label="Schedule Publish"
                  checked={values.status === 2}
                  onChange={() => {
                    setFieldValue("status", 2);
                    setFieldValue("isRecurring", false);
                  }}
                />
              </div>
              {values.status === 2 && (
                <div>
                  <Form.Check
                    type="radio"
                    id="one-time"
                    label="One Time"
                    checked={!values.isRecurring}
                    onChange={() => setFieldValue("isRecurring", false)}
                  />
                  <ReactDatePicker
                    selected={values.publish_date}
                    onChange={(date) => setFieldValue("publish_date", date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMMM dd yyyy h:mm aa"
                    className="w-100 mb-2"
                  />
                  <Form.Check
                    type="radio"
                    id="recurring"
                    label="Recurring"
                    checked={values.isRecurring}
                    onChange={() => setFieldValue("isRecurring", true)}
                  />
                  {values.isRecurring && (
                    <div>
                      <ReactDatePicker
                        selected={values.publish_date}
                        onChange={(date) => setFieldValue("publish_date", date)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="MMMM dd yyyy h:mm aa"
                        className="w-100 mb-2"
                      />
                      <ReactDatePicker
                        selected={values.end_date}
                        onChange={(date) => setFieldValue("end_date", date)}
                        placeholderText="End Date"
                        className="w-100 mb-2"
                      />
                      <Form.Check
                        type="checkbox"
                        id="never-ends"
                        label="It never ends"
                        onChange={() => {
                          setFieldValue(
                            "end_date",
                            values.end_date ? null : new Date()
                          );
                        }}
                      />
                      <Form.Group>
                        <Form.Label>Recurrence</Form.Label>
                        <Form.Control
                          as="select"
                          value={values.recurrence}
                          onChange={(e) =>
                            setFieldValue("recurrence", e.target.value)
                          }
                        >
                          <option value="daily">Daily</option>
                          <option value="weekly">Weekly</option>
                          <option value="monthly">Monthly</option>
                        </Form.Control>
                      </Form.Group>
                      <ReactDatePicker
                        selected={values.recurrenceTime}
                        onChange={(date) =>
                          setFieldValue("recurrenceTime", date)
                        }
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                        className="w-100"
                      />
                    </div>
                  )}
                </div>
              )}

              <button type="submit" className="green-filled-button mt-4">
                Submit
              </button>
            </div>
          </Card>
        </form>
        <div className="flex-fill" style={{ width: "70%" }}>
          <Card className="p-3">
            <HeadingTwo title={"Template Preview"} />
            <div
              className="template-preview"
              dangerouslySetInnerHTML={{ __html: templateRender }}
            />
          </Card>
        </div>
      </div>
    </>
  );
}
