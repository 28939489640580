import React, { useState } from "react";
import {
  BrowserRouter,
  Navigate,
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ReactSVG } from "react-svg";
import Layout from "../../../components/Layout";
import Heading from "../../../components/Heading";
import HeadingTwo from "../../../components/HeadingTwo";
import PlusLargeIcon from "../../../assets/icons/bootstrap-icons/plus-lg.svg";
import DownloadIcon from "../../../assets/icons/bootstrap-icons/download.svg";
import Breadcrumbs from "../../../Breadcrumbs";
import BackIcon from "../../../assets/icons/bootstrap-icons/Back_Arrow.svg";
export function CarFAQMain({ children }) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="row dashboard">
        <Breadcrumbs />

        <div className="my-4">{children}</div>
      </div>
    </Layout>
  );
}
