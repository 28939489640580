import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../../request";

const LIST = "car/faq/list";
const ADD_CAR_FAQ = "car/faq/add";
const GET_FAQ = "car/faq/";
const UPDATE_CAR_FAQ = "car/faq/update/";
const DELETE_CAR_FAQ = "car/faq/delete/";

/**
 * Get List of CarFAQ
 * getAllCarFAQ is used to get subset data of all brands
 * @param token
 * @param payload
 */
export function getAllCarFAQs(token, payload) {
  return axios.post(`${BASE_URL_AUTOX}${LIST}`, payload, getConfig(token));
}

/**
 *
 * @param {*} payload
 * @returns
 */

export const addCarFAQ = (payload) => {
  return axios.post(`${BASE_URL_AUTOX}${ADD_CAR_FAQ}`, payload);
};

export const getCarFAQbyId = async (token, id) => {
  return axios.get(`${BASE_URL_AUTOX}${GET_FAQ}/${id}`, getConfig(token));
};

export const updateCarFAQ = (token, id, payload) => {
  return axios.put(
    `${BASE_URL_AUTOX}${UPDATE_CAR_FAQ}${id}`,
    payload,
    getConfig(token)
  );
};

export const deleteCarFAQ = (token, id) => {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_CAR_FAQ}${id}`,
    getConfig(token)
  );
};
export default {
  getAllCarFAQs,
  addCarFAQ,
  getCarFAQbyId,
  updateCarFAQ,
  deleteCarFAQ,
};
