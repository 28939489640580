import React from "react";
import { Accordion, Form } from "react-bootstrap";

export default function WidgetTile({
  index,
  widget,
  handleUpdateWidgets,
  handleRemoveWidgets,
}) {
  // Function to handle radio button change
  const handleRadioChange = (e) => {
    handleUpdateWidgets(index, "widget_type", e.target.value);
  };

  const radioOptions = [
    { id: `textType-${index}`, value: 1, label: "Text" },
    { id: `shortcodeType-${index}`, value: 2, label: "Shortcode" },
  ];

  return (
    <Accordion
      defaultActiveKey={index.toString()}
      className="mb-3 position-relative"
    >
      <button
        className="x-button-accordion"
        onClick={() => handleRemoveWidgets(index)}
      >
        x
      </button>
      <Accordion.Item eventKey={index.toString()}>
        <Accordion.Header className="px-4 pt-3 "></Accordion.Header>
        <Accordion.Body className="p-0">
          <Form className="px-3 pb-3">
            <div className="form-group">
              <label htmlFor={`title-${index}`} className="form-label">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id={`title-${index}`}
                placeholder="Enter title"
                value={widget.widget_name}
                onChange={(e) =>
                  handleUpdateWidgets(index, "widget_name", e.target.value)
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor={`type-${index}`} className="form-label">
                Type
              </label>
              <div className="radio-buttons">
                {radioOptions.map((option) => (
                  <div className="form-check" key={option.id}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name={`type-${index}`}
                      id={option.id}
                      value={option.value}
                      checked={+widget.widget_type == option.value}
                      onChange={handleRadioChange}
                    />
                    <label className="form-check-label" htmlFor={option.id}>
                      {option.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor={`description-${index}`} className="form-label">
                Description
              </label>
              <textarea
                className="form-control"
                id={`description-${index}`}
                placeholder="Enter description"
                value={widget.description}
                onChange={(e) =>
                  handleUpdateWidgets(index, "description", e.target.value)
                }
              />
            </div>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
