import * as Yup from "yup";

const CarGalleryValidationSchema = Yup.object().shape({
  brand_id: Yup.number().required("Brand is required"),
  model_id: Yup.number().required("Model is required"),
  publish_date: Yup.date().required("Publish date is required"),
  images: Yup.array()
    .of(
      Yup.object().shape({
        file: Yup.mixed().required("File is required"),
        caption: Yup.string().optional(),
        alt: Yup.string().required("Alt text is required"),
        position: Yup.string().required("Position is required"),
        order: Yup.number().required("Order is required"),
      })
    )
    .min(1, "At least one image is required"),
});

const BikeGalleryValidationSchema = Yup.object().shape({
  brand_id: Yup.number().required("Brand is required"),
  model_id: Yup.number().required("Model is required"),
  publish_date: Yup.date().required("Publish date is required"),
  images: Yup.array()
    .of(
      Yup.object().shape({
        file: Yup.mixed().required("File is required"),
        caption: Yup.string().optional(),
        alt: Yup.string().required("Alt text is required"),
        position: Yup.string().required("Position is required"),
        order: Yup.number().required("Order is required"),
      })
    )
    .min(1, "At least one image is required"),
});

const CarGalleryEditValidationSchema = Yup.object().shape({
  brand_id: Yup.number().required("Brand is required"),
  model_id: Yup.number().required("Model is required"),
  publish_date: Yup.date().required("Publish date is required"),
  images: Yup.array()
    .of(
      Yup.object().shape({
        blob: Yup.string(),
        file: Yup.mixed().nullable(),
        caption: Yup.string().optional(),
        alt: Yup.string().required("Alt text is required"),
        position: Yup.string().required("Position is required"),
        order: Yup.number().required("Order is required"),
      })
    )
    .min(1, "At least one image is required"),
});

const BikeGalleryEditValidationSchema = Yup.object().shape({
  brand_id: Yup.number().required("Brand is required"),
  model_id: Yup.number().required("Model is required"),
  publish_date: Yup.date().required("Publish date is required"),
  images: Yup.array()
    .of(
      Yup.object().shape({
        blob: Yup.string(),
        file: Yup.mixed().nullable(),
        caption: Yup.string().optional(),
        alt: Yup.string().required("Alt text is required"),
        position: Yup.string().required("Position is required"),
        order: Yup.number().required("Order is required"),
      })
    )
    .min(1, "At least one image is required"),
});

export default {
  CarGalleryValidationSchema,
  CarGalleryEditValidationSchema,
  BikeGalleryValidationSchema,
  BikeGalleryEditValidationSchema,
};
