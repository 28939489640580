import React, { useState, useEffect, useCallback } from "react";
import Layout from "../../../components/Layout";
import Breadcrumbs from "../../../Breadcrumbs";
import BikeCarousels from "./BikeCarousels";
import AddBikeCarousel from "./AddBikeCarousel";
import APIHandler from "./apiHandler";
import { getToken } from "../../../utility/constants";
import { notifyError, notifySuccess } from "../../../utility/common";

export default function BikeCarouselMain({ children }) {
  const token = getToken();
  const [carousel, setCarousel] = useState([]);

  //Get list of Carousel
  const getcarouselList = useCallback(() => {
    APIHandler.listBikeCarousel(token)
      .then((result) => {
        setCarousel(result.data.data);
      })
      .catch((error) => {
        notifyError(error.response.data.message);
      });
  }, [token]);

  //Handle Delete Event
  const handleDelete = (id) => {
    APIHandler.deleteBikeCarousel(token, id)
      .then((result) => {
        getcarouselList();
        notifySuccess("Removed successfully");
      })
      .catch((error) => {
        notifyError(
          error.response
            ? error.response.data.message
            : "There is problem in error"
        );
      });
  };

  const updateSort = (carouselItems) => {
    APIHandler.sortBikeCarousel(token, { carouselItems })
      .then((result) => {
        setCarousel(result.data.data);
      })
      .catch((error) => {
        notifyError(
          error.response
            ? error.response.data.message
            : "There is problem in error"
        );
      });
  };
  useEffect(() => {
    getcarouselList();
  }, [getcarouselList]);
  return (
    <Layout>
      <div className="row dashboard">
        <div className="d-flex justify-content-between align-items-center multipleCityPriceMain mb-3 flex-wrap">
          <Breadcrumbs />
        </div>
        <div className="row">
          <div className="col-xxl-8 col-md-7">
            <BikeCarousels
              carousel={carousel}
              updateSort={updateSort}
              handleDelete={handleDelete}
              refreshcarouselList={getcarouselList}
            />
          </div>
          <div className="col-xxl-4 col-md-5">
            <AddBikeCarousel
              totalItems={carousel ? carousel.length : 0}
              onSuccess={getcarouselList}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
