import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { getToken } from "../../../utility/constants";
import apiHandler from "./apiHandler";
import { notifyError, notifySuccess } from "../../../utility/common";
import { BikeBodyTypeValidation } from "./schemaValidation";
import FormSelect from "../../../components/FormSelect";

export default function AddBikeComparison({ setReloadData }) {
  const token = getToken();
  const [bodyTypes, setBodyTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [modelsOne, setModelsOne] = useState([]);
  const [modelsTwo, setModelsTwo] = useState([]);

  const getBodyTypes = useCallback(() => {
    apiHandler
      .getBikeBodyTypeList(token)
      .then((result) => {
        console.log("body type", result);
        setBodyTypes(result.data.result);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  }, [token]);

  const getBrands = useCallback(() => {
    apiHandler
      .getAllBikeBrands(token)
      .then((result) => {
        console.log("brands", result);
        setBrands(result.data.result);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  }, [token]);

  useEffect(() => {
    getBodyTypes();
    getBrands();
  }, [getBodyTypes, getBrands]);

  const {
    handleSubmit,

    values,
    errors,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: {
      bike_body_type_id: -1,
      bike1_brand_id: -1,
      bike2_brand_id: -1,
      bike1_model_id: -1,
      bike2_model_id: -1,
    },
    validationSchema: BikeBodyTypeValidation,
    onSubmit: async (values, action) => {
      apiHandler
        .addBikeComparison(token, values)
        .then((result) => {
          setReloadData((reload) => !reload);
          notifySuccess(result?.data?.message);
          action.resetForm();
        })
        .catch((error) => {
          notifyError(error.message);
        });
    },
  });

  return (
    <div className="row dashboard">
      <div className="card card-rounded">
        <div className="col-xxl-12 col-xl-12 col-lg-11 col-md-11 col-sm-11 col-6 p-0">
          <div className="table-heading">Add Bike Compare</div>
        </div>
        <Form onSubmit={handleSubmit}>
          <div className="col-12 row mt-3">
            <div className="col-12">
              <FormSelect
                controlId={"BodyTypeSelect"}
                label={"Bike Body Type"}
                name={"bike_body_type_id"}
                defaultName={"Select Car Body Type"}
                setFieldValue={setFieldValue}
                items={bodyTypes}
                itemName={"type_name"}
                touched={touched}
                errors={errors}
                value={values["bike_body_type_id"]}
                getValues={(value) => {
                  if (values["bike1_brand_id"] !== -1 && value !== -1)
                    apiHandler
                      .getBikeModelsByBodyTypeBrand(
                        token,
                        value,
                        values["bike1_brand_id"]
                      )
                      .then((result) => {
                        console.log("brands one", result.data.result);
                        setModelsOne(result?.data?.data[0]?.json_agg || []);
                      });

                  if (values["bike2_brand_id"] !== -1 && value !== -1)
                    apiHandler
                      .getBikeModelsByBodyTypeBrand(
                        token,
                        value,
                        values["bike2_brand_id"]
                      )
                      .then((result) => {
                        console.log("brands two", result.data.result);
                        setModelsTwo(result?.data?.data[0]?.json_agg || []);
                      });
                }}
              />
            </div>

            <div className="col-6">
              <FormSelect
                controlId={"BrandSelect"}
                label={"Brands"}
                name={"bike1_brand_id"}
                defaultName={"Select Brand"}
                setFieldValue={setFieldValue}
                items={brands}
                itemName={"brand_name"}
                touched={touched}
                errors={errors}
                value={values["bike1_brand_id"]}
                getValues={(value) => {
                  if (values["bike_body_type_id"] !== -1 && value !== -1)
                    apiHandler
                      .getBikeModelsByBodyTypeBrand(
                        token,
                        values["bike_body_type_id"],
                        value
                      )
                      .then((result) => {
                        console.log("brands one", result);
                        setModelsOne(result?.data?.data[0]?.json_agg || []);
                      });
                }}
              />
            </div>

            <div className="col-6">
              <FormSelect
                controlId={"ModelSelect"}
                label={"Model"}
                name={"bike1_model_id"}
                defaultName={"Select Model"}
                setFieldValue={setFieldValue}
                items={modelsOne}
                itemName={"name"}
                touched={touched}
                errors={errors}
                value={values["bike1_model_id"]}
              />
            </div>

            <div className="p-3 text-center">VS</div>

            <div className="col-6">
              <FormSelect
                controlId={"BrandSelect"}
                label={"Brands"}
                name={"bike2_brand_id"}
                defaultName={"Select Brand"}
                setFieldValue={setFieldValue}
                items={brands}
                itemName={"brand_name"}
                touched={touched}
                errors={errors}
                value={values["bike2_brand_id"]}
                getValues={(value) => {
                  if (values["bike_body_type_id"] !== -1 && value !== -1)
                    apiHandler
                      .getBikeModelsByBodyTypeBrand(
                        token,
                        values["bike_body_type_id"],
                        value
                      )
                      .then((result) => {
                        console.log("brands one", result.data.result);
                        setModelsTwo(result?.data?.data[0]?.json_agg || []);
                      });
                }}
              />
            </div>

            <div className="col-6">
              <FormSelect
                controlId={"ModelSelect"}
                label={"Model"}
                name={"bike2_model_id"}
                defaultName={"Select Model"}
                setFieldValue={setFieldValue}
                items={modelsTwo}
                itemName={"name"}
                touched={touched}
                errors={errors}
                value={values["bike2_model_id"]}
              />
            </div>
          </div>
          <button type="submit" className="car-comparison-button my-4">
            Add New
          </button>
        </Form>
      </div>
    </div>
  );
}
