import axios from "axios";
import { getConfig, BASE_URL_AUTOX } from "../../../request";
import { getToken } from "../../../utility/constants";
export const GET_VERSION = "new-bike/version/filtered/list";
export const GET_ALL_MODEL_LIST = "new-bike/model/all/list/";
export const GET_ALL_BRAND_LIST = "new-bike/brand/all/list";
export const GET_MODEL_BY_BRAND_ID = "new-bike/model/brand-specific/";
export const ADD_bike_VERSION = "new-bike/version/add";
export const BULK_DELETE_bike_VERSION = "new-bike/version/bulk/delete";
export const DELETE_bike_VERSION = "new-bike/version/delete/";
export const GET_bike_VERSION_VIEW_LINK = "new-bike/version/view/";
export const GET_bike_VERSION_FIELDS = "new-bike/version/manageversion/";

/**
 * Get List of bike Versions
 * getbikeVersions is used to get all the data of bike service centres
 * @param token
 * @param payload
 */
export function getBikeVersions(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_VERSION}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get bike Version view lik
 * getbikeVersionViewLink is used to get view link of bike version
 * @param token
 * @param id
 */
export function getBikeVersionViewLink(token, id) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_bike_VERSION_VIEW_LINK}${id}`,
    getConfig(token)
  );
}

/**
 * Get List of Brands
 * getAllBikeBrands is used to get data of all brands
 * @param token
 * @param payload
 */
export function getAllBikeBrands(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_ALL_BRAND_LIST}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get List of Models by brand id
 * getAllModelsByBrandId is used to get data of all models by brand id
 * @param token
 * @param payload
 */
export function getAllBikeModelsByBrandId(token, brand_id) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_MODEL_BY_BRAND_ID}${brand_id}`,
    getConfig(token)
  );
}

/**
 * Add New bike Version
 * addbikeVersion is used to create new bike version data
 * @param token
 * @param payload
 */
export function addbikeVersion(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${ADD_bike_VERSION}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get List of Models
 * getAllModels is used to get data of all models
 * @param token
 * @param payload
 */
export function getAllBikeModelsList(token, payload) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_ALL_MODEL_LIST}${payload?.brand_id}`,
    payload,
    getConfig(token)
  );
}

/**
 * Delete bike Version By Id
 * deletebikeVersionById is used to delete record of bike version by id
 * @param token
 * @param id
 */
export function deletebikeVersionById(token, id) {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_bike_VERSION}${id}`,
    getConfig(token)
  );
}

/**
 * Bulk Delete bike Version By Id
 * bulkDeletebikeVersionById is used to delete bulk record of bike version by id
 * @param token
 * @param payload
 */
export function bulkDeletebikeVersionById(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${BULK_DELETE_bike_VERSION}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get List bike version fields
 * getbikeVersionFields is used to get bike version fields
 * @param token
 */
export function getbikeVersionFields(token, filter_type, model_id) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_bike_VERSION_FIELDS}${filter_type}/${model_id}`,
    getConfig(token)
  );
}

export function updateAllVersion(token, payload) {
  console.log("payload", payload);
  return axios.post(
    `${BASE_URL_AUTOX}${GET_bike_VERSION_FIELDS}`,
    payload,
    getConfig(token)
  );
}

export function downloadSampleInsertionFile(type) {
  return axios.post(`${BASE_URL_AUTOX}new-bike/version/download/sampleInsert`, {
    type: type,
  });
}

export async function downloadVarient(token, payload) {
  const response = await axios.post(
    `${BASE_URL_AUTOX}new-bike/version/export-csv-bike`,
    payload,
    getConfig(token)
  );
  return response;
}

export function addbikeCityImport(formData) {
  return axios.post(
    `${BASE_URL_AUTOX}new-bike/city-price/importCityPrice`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
}

export default {
  getBikeVersions,
  getAllBikeBrands,
  getAllBikeModelsByBrandId,
  getAllBikeModelsList,
  getBikeVersionViewLink,
  deletebikeVersionById,
  bulkDeletebikeVersionById,
  getbikeVersionFields,
  updateAllVersion,
  addbikeVersion,
  downloadSampleInsertionFile,
  downloadVarient,
  addbikeCityImport,
};
