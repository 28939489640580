import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import { ToastContainer } from "react-toastify";

import LoginPage from "./pages/Auth/LoginPage";
import ResetPassword from "./pages/Auth/ResetPassword";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import PasswordResetSuccessful from "./pages/Auth/PasswordResetSuccessful";
// import "semantic-ui-css/semantic.min.css";
import "./App.css";
import "./utility.css";
import "./styles/globals.css";
import "./styles/CarModels.css";
import "./styles/CarBrands.css";
import "./styles/PopularCars.css";
import "./styles/CarCarousel.css";
import Dashboard from "./pages/Dashboard/Dashboard";
import CarQuestions from "./pages/CarFAQs/QuestionListing/CarQuestions";
import CarAnswers from "./pages/CarFAQs/AnswerListing/CarAnswers";
import CarFAQMain from "./pages/CarFAQs/CarFAQMain";
import EditCarQuestion from "./pages/CarFAQs/EditCarQuestion";
import EditCarAnswer from "./pages/CarFAQs/EditCarAnswer";
import CarBrandsMain from "./pages/NewCar/CarBrands/CarBrandsMain";
import CarBrands from "./pages/NewCar/CarBrands/CarBrands";
import AddCarBrand from "./pages/NewCar/CarBrands/AddCarBrand";
import EditCarBrand from "./pages/NewCar/CarBrands/EditCarBrand";
import CarModelsMain from "./pages/NewCar/CarModels/CarModelsMain";
import CarModels from "./pages/NewCar/CarModels/CarModelListing/CarModels";
import AddCarModel from "./pages/NewCar/CarModels/AddCarModel";
import EditCarModel from "./pages/NewCar/CarModels/EditCarModel";
import CarVersion from "./pages/NewCar/CarVersion/CarVersions";
import EditCarVariant from "./pages/NewCar/CarVersion/EditCarVersion";
import CarVersionMain from "./pages/NewCar/CarVersion/CarVersionMain";
import ManageCarVersionMain from "./pages/NewCar/ManageCarVersion/ManageCarVersionMain";
// import ManageCarVersion from "./pages/NewCar/ManageCarVersion/ManageCarVersion";
import CarVersionInfo from "./pages/NewCar/ManageCarVersion/CarVersionInfo";
import CreateCarVersion from "./pages/NewCar/CarVersion/CreateCarVersion";
import ManageCarVersion from "./pages/NewCar/CarVersion/CarVersionManagement";
import { getToken } from "./utility/constants";

import AddCarQuestion from "./pages/CarFAQs/AddCarQuestion";
import AddCarAnswer from "./pages/CarFAQs/AddCarAnswer";
import PopularCars from "./pages/NewCar/PopularCars/PopularCars";
import MultipleCityPriceMain from "./pages/NewCar/MultipleCityPrice/MultipleCityPriceMain";
import MultipleCityPriceDetails from "./pages/NewCar/MultipleCityPrice/MultipleCityPriceDetails";
import CarCityPriceMain from "./pages/NewCar/CarCityPrice/CarCityPriceMain";
import CarCityPriceList from "./pages/NewCar/CarCityPrice/CarCityPriceList";
import AddCarCityPrice from "./pages/NewCar/CarCityPrice/AddCarCityPrice";
import { CarFAQMain as NewCarFAQMain } from "./pages/NewCar/CarFAQs/CarFAQMain";
import { CarFAQs as NewCarFAQs } from "./pages/NewCar/CarFAQs/CarFAQs";
import AddCarFAQ from "./pages/NewCar/CarFAQs/AddCarFAQ";

import CarVehicleTypeMain from "./pages/NewCar/CarVehicleType/CarVehicleTypeMain";
import CarCityMain from "./pages/NewCar/CarCity/CarCityMain";
import CarCities from "./pages/NewCar/CarCity/CarCities";
import AddCarCity from "./pages/NewCar/CarCity/AddCarCity";
import CarComparisonMain from "./pages/NewCar/CarComparison/CarComparisonMain";
import CarDrivingSchoolMain from "./pages/NewCar/CarDrivingSchool/CarDrivingSchoolMain";
import CarDrivingSchools from "./pages/NewCar/CarDrivingSchool/CarDrivingSchools";
import AddCarDrivingSchool from "./pages/NewCar/CarDrivingSchool/AddCarDrivingSchool";
import MarutiCarDrivingSchoolMain from "./pages/NewCar/MarutiCarDrivingSchool/MarutiCarDrivingSchoolMain";
import MarutiCarDrivingSchools from "./pages/NewCar/MarutiCarDrivingSchool/MarutiCarDrivingSchools";
import AddMarutiCarDrivingSchool from "./pages/NewCar/MarutiCarDrivingSchool/AddMarutiCarDrivingSchool";
import CarDealers from "./pages/NewCar/CarDealer/CarDealers";
import CarDealersMain from "./pages/NewCar/CarDealer/CarDealerMain";
import AddCarDealer from "./pages/NewCar/CarDealer/AddCarDealer";
import CarServiceCentreMain from "./pages/NewCar/CarServiceCenter/CarServiceCentreMain";
import CarServiceCentres from "./pages/NewCar/CarServiceCenter/CarServiceCentres";
import AddCarServiceCentre from "./pages/NewCar/CarServiceCenter/AddCarServiceCentre";
import CarVehicleTypes from "./pages/NewCar/CarVehicleType/CarVehicleTypes";
import AddCarVehicleType from "./pages/NewCar/CarVehicleType/AddCarVehicleType";
import CarCarouselMain from "./pages/NewCar/CarCarousel/CarCarouselMain";
import CarCarousels from "./pages/NewCar/CarCarousel/CarCarousels";
import DeleteCarCityPrice from "./pages/NewCar/CarCityPrice/DeleteCarCityPrice";
import EditCarCityPrice from "./pages/NewCar/CarCityPrice/EditCarCityPrice";
import EditCarDrivingSchool from "./pages/NewCar/CarDrivingSchool/EditCarDrivingSchool";
import EditMarutiCarDrivingSchool from "./pages/NewCar/MarutiCarDrivingSchool/EditMarutiCarDrivingSchool";
import EditCarDealer from "./pages/NewCar/CarDealer/EditCarDealer";
import EditCarServiceCentre from "./pages/NewCar/CarServiceCenter/EditCarServiceCentre";
import EditCarVehicleType from "./pages/NewCar/CarVehicleType/EditCarVehicleType";
import EditCarCity from "./pages/NewCar/CarCity/EditCarCity";
import EditCarFAQ from "./pages/NewCar/CarFAQs/EditCarFAQ";
import CarUserReviewMain from "./pages/CarUserReview/CarUserReviewMain";

import TagsMain from "./pages/Tag/TagsMain";
import Tags from "./pages/Tag/Tags";

import AddTag from "./pages/Tag/AddTag";
import EditTag from "./pages/Tag/EditTag";
import WidgetMain from "./pages/NewCar/Widget/WidgetMain";
import WidgetDescription from "./pages/NewCar/Widget/WidgetDescription";
import EditorMain from "./pages/Editor/EditorMain";
import AddEditor from "./pages/Editor/AddEditor";
import Editors from "./pages/Editor/Editors";
import EditEditor from "./pages/Editor/EditEditor";
import VerifyOTP from "./pages/Auth/VerifyOTP";
import AddCarUserReview from "./pages/CarUserReview/AddCarUserReview";
import EditCarUserReview from "./pages/CarUserReview/EditCarUserReview";
import { AppSideBarProvider } from "./components/AppSideBarProvider";
import SideBarMain from "./pages/Sidebar/sidebar/SideBarMain";
import SideBar from "./pages/Sidebar/sidebar/SideBar";
import AddSideBar from "./pages/Sidebar/sidebar/AddSideBar";
import EditSideBar from "./pages/Sidebar/sidebar/EditSideBar";
import Widget from "./pages/NewCar/Widget/Widget";
import ProfileMain from "./pages/Profile/ProfileMain";
import Profile from "./pages/Profile/Profile";
import SettingsMain from "./pages/Settings/SettingsMain";
import PostManagementMain from "./pages/PostManagement/PostManagementMain";
import PostManagement from "./pages/PostManagement/PostManagement";
import AddPost from "./pages/PostManagement/AddPost";
import PageListing from "./pages/pages/PageListing";
import AddPage from "./pages/pages/AddPage";
import EditPage from "./pages/pages/EditPage";
import PageManagementMain from "./pages/pages/PageManagementMain";
import GalleryManagement from "./pages/Gallery/GalleryManagement";
import CarGalleryListing from "./pages/Gallery/Car/CarGalleryListing";
import CarAddGallery from "./pages/Gallery/Car/CarAddGallery";
import CarEditGallery from "./pages/Gallery/Car/CarEditGallery";
import AddGallery from "./pages/Gallery/AddGallery";
import SafetyRatingMain from "./pages/SafetyRating/SafetyRatingMain";
import SafetyRatingListing from "./pages/SafetyRating/SafetyRatingListing";
import NewsLetterMain from "./pages/NewsLetter/NewsLetterMain";
import NewsLetter from "./pages/NewsLetter/NewsLetter";
import SafetyRatingAdd from "./pages/SafetyRating/SafetyRatingAdd";
import SafetyRatingEdit from "./pages/SafetyRating/SafetyRatingEdit";
import TrendingCars from "./pages/NewCar/TrendingCars/TrendingCars";
import BikeBrandsMain from "./pages/NewBike/BikeBrands/BikeBrandsMain";
import BikeBrands from "./pages/NewBike/BikeBrands/BikeBrands";
import AddBikeBrand from "./pages/NewBike/BikeBrands/AddBikeBrand";
import EditBikeBrand from "./pages/NewBike/BikeBrands/EditBikeBrand";
import BikeModels from "./pages/NewBike/BikeModels/BikeModelListing/BikeModels";
import BikeModelsMain from "./pages/NewBike/BikeModels/BikeModelsMain";
import AddBikeModel from "./pages/NewBike/BikeModels/AddBikeModel";
import EditBikeModel from "./pages/NewBike/BikeModels/EditBikeModel";
import BikeVariantsMain from "./pages/NewBike/BikeVersion/BikeVariantsMain";
import BikeVersions from "./pages/NewBike/BikeVersion/BikeVersions";
import AddBikeVariant from "./pages/NewBike/BikeVersion/CreateBikeVersion";
import BikeVersionManagement from "./pages/NewBike/BikeVersion/BikeVersionManagement";
import BikeCarouselMain from "./pages/NewBike/BikeCarousel/BikeCarouselMain";
import BikeCarousels from "./pages/NewBike/BikeCarousel/BikeCarousels";
import BikeDealersMain from "./pages/NewBike/BikeDealer/BikeDealerMain";
import BikeDealers from "./pages/NewBike/BikeDealer/BikeDealers";
import AddBikeDealer from "./pages/NewBike/BikeDealer/AddBikeDealer";
import EditBikeDealer from "./pages/NewBike/BikeDealer/EditBikeDealer";
import BikeCityPriceMain from "./pages/NewBike/BikeCityPrice/BikeCityPriceMain";
import BikeCityPriceList from "./pages/NewBike/BikeCityPrice/BikeCityPriceList";
import AddBikeCityPrice from "./pages/NewBike/BikeCityPrice/AddBikeCityPrice";
import EditBikeCityPrice from "./pages/NewBike/BikeCityPrice/EditBikeCityPrice";
import DeleteBikeCityPrice from "./pages/NewBike/BikeCityPrice/DeleteBikeCityPrice";
import PopularBikes from "./pages/NewBike/PopularBikes/PopularBikes";
import PopularScooter from "./pages/NewBike/PopularScooter/PopularScooters";
import TrendingBikes from "./pages/NewBike/TrendingBikes/TrendingBikes";
import MultipleBikeCityPriceMain from "./pages/NewBike/MultipleBikeCityPrice/MultipleBikeCityPriceMain";
import BikeComparisonMain from "./pages/NewBike/BikeComparison/BikeComparisonMain";
import BikeVehicleTypes from "./pages/NewBike/BikeVehicleType/BikeVehicleTypes";
import BikeVehicleTypeMain from "./pages/NewBike/BikeVehicleType/BikeVehicleTypeMain";
import AddBikeVehicleType from "./pages/NewBike/BikeVehicleType/AddBikeVehicleType";
import EditBikeVehicleType from "./pages/NewBike/BikeVehicleType/EditBikeVehicleType";
import BikeServiceCentreMain from "./pages/NewBike/BikeServiceCenter/BikeServiceCentreMain";
import BikeServiceCentres from "./pages/NewBike/BikeServiceCenter/BikeServiceCentres";
import AddBikeServiceCentre from "./pages/NewBike/BikeServiceCenter/AddBikeServiceCentre";
import EditBikeServiceCentre from "./pages/NewBike/BikeServiceCenter/EditBikeServiceCentre";
import BikeCityMain from "./pages/NewBike/BikeCity/BikeCityMain";
import BikeCities from "./pages/NewBike/BikeCity/BikeCities";
import AddBikeCity from "./pages/NewBike/BikeCity/AddBikeCity";
import EditBikeCity from "./pages/NewBike/BikeCity/EditBikeCity";
import ScooterCarouselMain from "./pages/NewBike/ScooterCarousel/ScooterCarouselMain";
import ScooterCarousels from "./pages/NewBike/ScooterCarousel/ScooterCarousels";
import BikeFAQMainSection from "./pages/BikeFAQ/BikeFAQMain";
import { BikeFAQs } from "./pages/NewBike/BikeFAQs/BikeFAQs";
import AddBikeFAQ from "./pages/NewBike/BikeFAQs/AddBikeFAQ";
import { BikeFAQMain } from "./pages/NewBike/BikeFAQs/BikeFAQMain";

import EditBikeFAQ from "./pages/NewBike/BikeFAQs/EditBikeFAQ";
import BikeQuestion from "./pages/BikeFAQ/QuestionListing/BikeQuestions";
import BikeAnswers from "./pages/BikeFAQ/AnswerListing/BikeAnswers";
import AddBikeQuestion from "./pages/BikeFAQ/AddBikeQuestion";
import AddBikeAnswer from "./pages/BikeFAQ/AddBikeAnswer";
import EditBikeQuestion from "./pages/BikeFAQ/EditBikeQuestion";
import EditBikeAnswer from "./pages/BikeFAQ/EditBikeAnswer";
import BikeUserReviewMain from "./pages/BikeUserReview/BikeUserReviewMain";
import AddBikeUserReview from "./pages/BikeUserReview/AddBikeUserReview";
import EditBikeUserReview from "./pages/BikeUserReview/EditBikeUserReview";
import CategoryMain from "./pages/Category/CategoryMain";
import CategoryList from "./pages/Category/CategoryList";
import AddCategory from "./pages/Category/AddCategory";
import EditCategory from "./pages/Category/EditCategory";
import EditPost from "./pages/PostManagement/EditPost";
import AdPageMain from "./pages/AdManagement/adPage/adPageMain";
import AdPage from "./pages/AdManagement/adPage/adPage";
import CategoryAds from "./pages/AdManagement/categoryAds/CategoryAds";
import CategoryAdsPage from "./pages/AdManagement/categoryAds/CategoryAdsPage";
import AdWidgetDescription from "./pages/AdManagement/categoryAds/AdWidgetDescription";
import CommentListing from "./pages/Comments/CommentListing";
import SubscriptionMain from "./pages/Subscription/SubscriptionMain";
import Subscription from "./pages/Subscription/Subscription";
import EditSubscription from "./pages/Subscription/EditSubscription";
import AddSubscription from "./pages/Subscription/AddSubscription";
import MagazineMain from "./pages/Magazine/MagazineMain";
import MagazineList from "./pages/Magazine/MagazineList";
import EditMagazine from "./pages/Magazine/EditMagazine";
import AddMagazine from "./pages/Magazine/AddMagazine";
import TemplateList from "./pages/Template/TemplateList";
import TemplateMain from "./pages/Template/TemplateMain";
import EditTemplate from "./pages/Template/EditTemplate";
import AddTemplate from "./pages/Template/AddTemplate";
import Feedback from "./pages/Feedback/Feedback";
import Notification from "./pages/Notification/Notification";
import EnquiryAdmin from "./pages/EnquiryAdmin/EnquiryAdmin";
import ChangePassword from "./pages/Editor/ChangePassword";
import AddNewsLetter from "./pages/NewsLetter/AddNewsLetter";
import EditNewsLetter from "./pages/NewsLetter/EditNewsLetter";
import NewsCampaignMain from "./pages/NewsCampaign/NewsCampaingMain";
import NewsCampaignListing from "./pages/NewsCampaign/NewsCampaignListing";
import NewsCampaignAdd from "./pages/NewsCampaign/NewsCampaingAdd";
import StatePrice from "./pages/StatePrice/StatePrice";
import StatePriceMain from "./pages/StatePrice/StatePriceMain";
import RoleBasedRoute from "./RoleBasedRoutes";
import UserMain from "./pages/userManage/UserMain";
import UserManage from "./pages/userManage/UserManage";
import UserManageEdit from "./pages/userManage/UserManageEdit";
import BikeGalleryListing from "./pages/Gallery/Bike/BikeGalleryListing";
import BikeAddGallery from "./pages/Gallery/Bike/BikeAddGallery";
import BikeEditGallery from "./pages/Gallery/Bike/BikeEditGallery";
import BikeStatePriceMain from "./pages/BikeStatePrice/BikeStatePriceMain";
import BikeStatePrice from "./pages/BikeStatePrice/BikeStatePrice";
import RegularGalleryMain from "./pages/RegularGallery/RegularGalleryMain";
import RegularGalleryListing from "./pages/RegularGallery/RegularGalleryListing";
import RegularGalleryEdit from "./pages/RegularGallery/RegularGalleryEdit";

import useAuthTimeout from "./components/AuthTimoutHook";
import { useEffect, useState } from "react";
import UserNewsletterSubscritions from "./pages/UserNewsletterSubscriptions/UserNewsletterSubscriptionsList";
import AddNewsLetterSubscriptionForm from "./pages/UserNewsletterSubscriptions/AddUserNewsletterSubscription";
import AddPostGallery from "./pages/PostManagement/AddPostGallery";
import PostGalleryListing from "./pages/PostManagement/ListPostGallery";
import EditPostGallery from "./pages/PostManagement/EditPostGallery";
import ViewNewsCampaign from "./pages/NewsCampaign/ViewNewsCampaign";
import EditUserNewsletterSubscription from "./pages/UserNewsletterSubscriptions/EditUserNewsletterSubscription";
const PrivateRoute = ({ children }) => {
  const token = getToken();
  return token ? children : <Navigate to="/login" />;
};

const PublicRoute = ({ children }) => {
  const token = getToken();
  console.log("abc 2");
  return !token ? children : <Navigate to="/dashboard" />;
};
const router = createBrowserRouter(
  [
    {
      path: "/",
      element: (
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      ),
    },

    {
      path: "/dashboard",
      element: (
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      ),
    },
    {
      path: "/login",
      element: (
        <PublicRoute>
          <LoginPage />
        </PublicRoute>
      ),
    },
    {
      path: "/forgot-password",
      element: (
        <PublicRoute>
          <ForgotPassword />
        </PublicRoute>
      ),
    },
    // {
    //   path: "/reset-password/:id",
    //   element: (
    //     <PublicRoute>
    //       <ResetPassword />
    //     </PublicRoute>
    //   ),
    // },
    {
      path: "/verify-otp",
      element: (
        <PublicRoute>
          <VerifyOTP />
        </PublicRoute>
      ),
    },
    {
      path: "/reset-password",
      element: (
        <PublicRoute>
          <ResetPassword />
        </PublicRoute>
      ),
    },
    {
      path: "/reset-password-success",
      element: (
        <PublicRoute>
          <PasswordResetSuccessful />
        </PublicRoute>
      ),
    },
    // {
    //   path: "/car-faq",
    //   element: (
    //     <PrivateRoute>
    //       <CarFAQMain />
    //     </PrivateRoute>
    //   )
    // },
    {
      path: "/car-questions",
      element: (
        <PrivateRoute>
          <CarFAQMain>
            <CarQuestions />
          </CarFAQMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-answers",
      element: (
        <PrivateRoute>
          <CarFAQMain>
            <CarAnswers />
          </CarFAQMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/add-car-question",
      element: (
        <PrivateRoute>
          <CarFAQMain>
            <AddCarQuestion />
          </CarFAQMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/add-car-answer",
      element: (
        <PrivateRoute>
          <CarFAQMain>
            <AddCarAnswer />
          </CarFAQMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/edit-car-question/:questionId",
      element: (
        <PrivateRoute>
          <CarFAQMain>
            <EditCarQuestion />
          </CarFAQMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/edit-car-answer/:answerId",
      element: (
        <PrivateRoute>
          <CarFAQMain>
            <EditCarAnswer />
          </CarFAQMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-brand",
      element: (
        <PrivateRoute>
          {/* <RoleBasedRoute allowedRoles={["3"]}> */}
          <CarBrandsMain>
            <CarBrands />
          </CarBrandsMain>
          {/* </RoleBasedRoute> */}
        </PrivateRoute>
      ),
      breadcrumb: "",
    },

    {
      path: "/car-brand/add-car-brand",
      element: (
        <PrivateRoute>
          <CarBrandsMain>
            <AddCarBrand />
          </CarBrandsMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-brand/edit-car-brand/:id",
      element: (
        <PrivateRoute>
          <CarBrandsMain>
            <EditCarBrand />
          </CarBrandsMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-models",
      element: (
        <PrivateRoute>
          <CarModelsMain>
            <CarModels />
          </CarModelsMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-models/add-car-model",
      element: (
        <PrivateRoute>
          <CarModelsMain>
            <AddCarModel />
          </CarModelsMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-models/edit-car-model/:id",
      element: (
        <PrivateRoute>
          <CarModelsMain>
            <EditCarModel />
          </CarModelsMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-version",
      element: (
        <PrivateRoute>
          <CarVersionMain>
            <CarVersion />
          </CarVersionMain>
        </PrivateRoute>
      ),
    },

    {
      path: "/car-version/create-car-version",
      element: (
        <PrivateRoute>
          <CarVersionMain>
            <CreateCarVersion />
          </CarVersionMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/edit-car-variant",
      element: (
        <PrivateRoute>
          <CarVersionMain>
            <EditCarVariant />
          </CarVersionMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/edit-car-version",
      element: (
        <PrivateRoute>
          <CarVersionMain>
            <ManageCarVersion />
          </CarVersionMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/manage-car-version",
      element: (
        <PrivateRoute>
          <CarVersionMain>
            <ManageCarVersion />
          </CarVersionMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/add-car-version",
      element: (
        <PrivateRoute>
          <ManageCarVersionMain>
            <ManageCarVersion>
              <CarVersionInfo />
            </ManageCarVersion>
          </ManageCarVersionMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/popular-cars",
      element: (
        <PrivateRoute>
          <PopularCars />
        </PrivateRoute>
      ),
    },
    {
      path: "/trending-cars",
      element: (
        <PrivateRoute>
          <TrendingCars />
        </PrivateRoute>
      ),
    },
    {
      path: "/car-price/multiple-city-price",
      element: (
        <PrivateRoute>
          <MultipleCityPriceMain />
        </PrivateRoute>
      ),
    },
    {
      path: "/multiple-city-price-details",
      element: (
        <PrivateRoute>
          <MultipleCityPriceMain>
            <MultipleCityPriceDetails />
          </MultipleCityPriceMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-city-price",
      element: (
        <PrivateRoute>
          <CarCityPriceMain>
            <CarCityPriceList />
          </CarCityPriceMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/add-car-city-price",
      element: (
        <PrivateRoute>
          <CarCityPriceMain>
            <AddCarCityPrice />
          </CarCityPriceMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/edit-car-city-price/:id/:url",
      element: (
        <PrivateRoute>
          <CarCityPriceMain>
            <EditCarCityPrice />
          </CarCityPriceMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/delete-car-city-price",
      element: (
        <PrivateRoute>
          <CarCityPriceMain>
            <DeleteCarCityPrice />
          </CarCityPriceMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-faq",
      element: (
        <PrivateRoute>
          <NewCarFAQMain>
            <NewCarFAQs />
          </NewCarFAQMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/add-car-faq",
      element: (
        <PrivateRoute>
          <NewCarFAQMain>
            <AddCarFAQ />
          </NewCarFAQMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/edit-car-faq/:id",
      element: (
        <PrivateRoute>
          <NewCarFAQMain>
            <EditCarFAQ />
          </NewCarFAQMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-city",
      element: (
        <PrivateRoute>
          <CarCityMain>
            <CarCities />
          </CarCityMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-city/add-car-city",
      element: (
        <PrivateRoute>
          <CarCityMain>
            <AddCarCity />
          </CarCityMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-city/edit-car-city/:id",
      element: (
        <PrivateRoute>
          <CarCityMain>
            <EditCarCity />
          </CarCityMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-comparison",
      element: (
        <PrivateRoute>
          <CarComparisonMain />
        </PrivateRoute>
      ),
    },
    {
      path: "/car-driving-school",
      element: (
        <PrivateRoute>
          <CarDrivingSchoolMain>
            <CarDrivingSchools />
          </CarDrivingSchoolMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-driving-school/add-car-driving-school",
      element: (
        <PrivateRoute>
          <CarDrivingSchoolMain>
            <AddCarDrivingSchool />
          </CarDrivingSchoolMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-driving-school/edit-car-driving-school/:id",
      element: (
        <PrivateRoute>
          <CarDrivingSchoolMain>
            <EditCarDrivingSchool />
          </CarDrivingSchoolMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/maruti-car-driving-school",
      element: (
        <PrivateRoute>
          <MarutiCarDrivingSchoolMain>
            <MarutiCarDrivingSchools />
          </MarutiCarDrivingSchoolMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/maruti-car-driving-school/add-maruti-car-driving-school",
      element: (
        <PrivateRoute>
          <MarutiCarDrivingSchoolMain>
            <AddMarutiCarDrivingSchool />
          </MarutiCarDrivingSchoolMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/maruti-car-driving-school/edit-maruti-car-driving-school/:id",
      element: (
        <PrivateRoute>
          <MarutiCarDrivingSchoolMain>
            <EditMarutiCarDrivingSchool />
          </MarutiCarDrivingSchoolMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-dealer",
      element: (
        <PrivateRoute>
          <CarDealersMain>
            <CarDealers />
          </CarDealersMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/dealer/add-car-dealer",
      element: (
        <PrivateRoute>
          <CarDealersMain>
            <AddCarDealer />
          </CarDealersMain>
        </PrivateRoute>
      ),
    },

    {
      path: "/dealer/edit-car-dealer/:id",
      element: (
        <PrivateRoute>
          <CarDealersMain>
            <EditCarDealer />
          </CarDealersMain>
        </PrivateRoute>
      ),
    },

    {
      path: "/car-service-centre",
      element: (
        <PrivateRoute>
          <CarServiceCentreMain>
            <CarServiceCentres />
          </CarServiceCentreMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-service-centre/add-car-service-centre",
      element: (
        <PrivateRoute>
          <CarServiceCentreMain>
            <AddCarServiceCentre />
          </CarServiceCentreMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-service-centre/edit-car-service-centre/:id",
      element: (
        <PrivateRoute>
          <CarServiceCentreMain>
            <EditCarServiceCentre />
          </CarServiceCentreMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-vehicle-type",
      element: (
        <PrivateRoute>
          <CarVehicleTypeMain>
            <CarVehicleTypes />
          </CarVehicleTypeMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/vehicle-type/add-car-vehicle-type",
      element: (
        <PrivateRoute>
          <CarVehicleTypeMain>
            <AddCarVehicleType />
          </CarVehicleTypeMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/vehicle-type/edit-car-vehicle-type/:id",
      element: (
        <PrivateRoute>
          <CarVehicleTypeMain>
            <EditCarVehicleType />
          </CarVehicleTypeMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-carousel",
      element: (
        <PrivateRoute>
          <CarCarouselMain>
            <CarCarousels />
          </CarCarouselMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-user-review",
      element: (
        <PrivateRoute>
          <CarUserReviewMain />
        </PrivateRoute>
      ),
    },
    {
      path: "/add-car-user-review",
      element: (
        <PrivateRoute>
          <AddCarUserReview />
        </PrivateRoute>
      ),
    },
    {
      path: `/edit-car-user-review/:id`,
      element: (
        <PrivateRoute>
          <EditCarUserReview />
        </PrivateRoute>
      ),
    },
    {
      path: "/tag",
      element: (
        <PrivateRoute>
          <TagsMain>
            <Tags />
          </TagsMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/add-tag",
      element: (
        <PrivateRoute>
          <TagsMain>
            <AddTag />
          </TagsMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/edit-tag/:id",
      element: (
        <PrivateRoute>
          <TagsMain>
            <EditTag />
          </TagsMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/settings",
      element: (
        <PrivateRoute>
          <SettingsMain />
        </PrivateRoute>
      ),
    },

    {
      path: "/sidebar",
      element: (
        <PrivateRoute>
          <SideBarMain>
            <SideBar />
          </SideBarMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/add-sidebar",
      element: (
        <PrivateRoute>
          <SideBarMain>
            <AddSideBar />
          </SideBarMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/sidebar-edit/:id",
      element: (
        <PrivateRoute>
          <SideBarMain>
            <EditSideBar />
          </SideBarMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/widget",
      element: (
        <PrivateRoute>
          <WidgetMain>
            {/* <WidgetDescription /> */}
            <Widget />
          </WidgetMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/widget-edit/:id",
      element: (
        <PrivateRoute>
          <WidgetMain>
            <WidgetDescription />
          </WidgetMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/profile",
      element: (
        <PrivateRoute>
          <ProfileMain>
            <Profile />
          </ProfileMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/editor",
      element: (
        <PrivateRoute>
          <EditorMain>
            <Editors />
          </EditorMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/editor/edit-password/:id",
      element: (
        <PrivateRoute>
          <EditorMain>
            <ChangePassword />
          </EditorMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/editor/add-editor",
      element: (
        <PrivateRoute>
          <EditorMain>
            <AddEditor />
          </EditorMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/editor/edit-editor",
      element: (
        <PrivateRoute>
          <EditorMain>
            <EditEditor />
          </EditorMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/post-management",
      element: (
        <PrivateRoute>
          <PostManagementMain>
            <PostManagement />
          </PostManagementMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/page",
      element: (
        <PrivateRoute>
          <PageManagementMain>
            <PageListing />
          </PageManagementMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/page-add",
      element: (
        <PrivateRoute>
          <PageManagementMain>
            <AddPage />
          </PageManagementMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/page-edit/:id",
      element: (
        <PrivateRoute>
          <PageManagementMain>
            <EditPage />
          </PageManagementMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/post-management/add-post",
      element: (
        <PrivateRoute>
          <PostManagementMain>
            <AddPost />
          </PostManagementMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/post-management/edit-post",
      element: (
        <PrivateRoute>
          <PostManagementMain>
            <EditPost />
          </PostManagementMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-model-gallery",
      element: (
        <PrivateRoute>
          <GalleryManagement>
            <CarGalleryListing />
          </GalleryManagement>
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-model-gallery",
      element: (
        <PrivateRoute>
          <GalleryManagement>
            <BikeGalleryListing />
          </GalleryManagement>
        </PrivateRoute>
      ),
    },
    {
      path: "/post-gallery",
      element: (
        <PrivateRoute>
          <GalleryManagement>
            <PostGalleryListing />
          </GalleryManagement>
        </PrivateRoute>
      ),
    },
    {
      path: "/add-post-gallery",
      element: (
        <PrivateRoute>
          <GalleryManagement>
            <AddPostGallery />
          </GalleryManagement>
        </PrivateRoute>
      ),
    },
    {
      path: "/edit-post-gallery/:id",
      element: (
        <PrivateRoute>
          <GalleryManagement>
            <EditPostGallery />
          </GalleryManagement>
        </PrivateRoute>
      ),
    },
    {
      path: "/regular-gallery",
      element: (
        <PrivateRoute>
          <RegularGalleryMain>
            <RegularGalleryListing />
          </RegularGalleryMain>
        </PrivateRoute>
      ),
    },

    {
      path: "/regular-gallery/edit-regular-gallery",
      element: (
        <PrivateRoute>
          <RegularGalleryMain>
            <RegularGalleryEdit />
          </RegularGalleryMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/gallery-add",
      element: (
        <PrivateRoute>
          <GalleryManagement>
            <AddGallery />
          </GalleryManagement>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-model-gallery/car-gallery-add",
      element: (
        <PrivateRoute>
          <GalleryManagement>
            <CarAddGallery />
          </GalleryManagement>
        </PrivateRoute>
      ),
    },
    {
      path: "/car-gallery-edit/:id",
      element: (
        <PrivateRoute>
          <GalleryManagement>
            <CarEditGallery />
          </GalleryManagement>
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-model-gallery/bike-gallery-add",
      element: (
        <PrivateRoute>
          <GalleryManagement>
            <BikeAddGallery />
          </GalleryManagement>
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-model-gallery/bike-gallery-edit/:id",
      element: (
        <PrivateRoute>
          <GalleryManagement>
            <BikeEditGallery />
          </GalleryManagement>
        </PrivateRoute>
      ),
    },

    {
      path: "/safety-rating",
      element: (
        <PrivateRoute>
          <SafetyRatingMain>
            <SafetyRatingListing />
          </SafetyRatingMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/add-safety-rating",
      element: (
        <PrivateRoute>
          <SafetyRatingMain>
            <SafetyRatingAdd />
          </SafetyRatingMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/edit-safety-rating/:id",
      element: (
        <PrivateRoute>
          <SafetyRatingMain>
            <SafetyRatingEdit />
          </SafetyRatingMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/news-letter-campaign",
      element: (
        <PrivateRoute>
          <NewsCampaignMain>
            <NewsCampaignListing />
          </NewsCampaignMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/user-newsletter-subscriptions",
      element: (
        <PrivateRoute>
          <NewsCampaignMain>
            <UserNewsletterSubscritions />
          </NewsCampaignMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/add-user-newsletter-subscriptions",
      element: (
        <PrivateRoute>
          <NewsCampaignMain>
            <AddNewsLetterSubscriptionForm />
          </NewsCampaignMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/edit-user-newsletter-subscriptions/:id",
      element: (
        <PrivateRoute>
          <NewsCampaignMain>
            <EditUserNewsletterSubscription />
          </NewsCampaignMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/add-news-campaign",
      element: (
        <PrivateRoute>
          <NewsCampaignMain>
            <NewsCampaignAdd />
          </NewsCampaignMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/view-campaign/:id",
      element: (
        <PrivateRoute>
          <NewsCampaignMain>
            <ViewNewsCampaign />
          </NewsCampaignMain>
        </PrivateRoute>
      ),
    },

    {
      path: "/news-letter",
      element: (
        <PrivateRoute>
          <NewsLetterMain>
            <NewsLetter />
          </NewsLetterMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/add-news-letter",
      element: (
        <PrivateRoute>
          <NewsLetterMain>
            <AddNewsLetter />
          </NewsLetterMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/edit-news-letter/:id",
      element: (
        <PrivateRoute>
          <NewsLetterMain>
            <EditNewsLetter />
          </NewsLetterMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/category",
      element: (
        <PrivateRoute>
          <CategoryMain>
            <CategoryList />
          </CategoryMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/add-category",
      element: (
        <PrivateRoute>
          <CategoryMain>
            <AddCategory />
          </CategoryMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/edit-category/:id",
      element: (
        <PrivateRoute>
          <CategoryMain>
            <EditCategory />
          </CategoryMain>
        </PrivateRoute>
      ),
    },

    // Bike routes
    {
      path: "/bike-brand",
      element: (
        <PrivateRoute>
          <BikeBrandsMain>
            <BikeBrands />
          </BikeBrandsMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-brand/add-bike-brand",
      element: (
        <PrivateRoute>
          <BikeBrandsMain>
            <AddBikeBrand />
          </BikeBrandsMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-brand/edit-bike-brand/:id",
      element: (
        <PrivateRoute>
          <BikeBrandsMain>
            <EditBikeBrand />
          </BikeBrandsMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-models",
      element: (
        <PrivateRoute>
          <BikeModelsMain>
            <BikeModels />
          </BikeModelsMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-models/add-bike-model",
      element: (
        <PrivateRoute>
          <BikeModelsMain>
            <AddBikeModel />
          </BikeModelsMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-models/edit-bike-model/:id",
      element: (
        <PrivateRoute>
          <BikeModelsMain>
            <EditBikeModel />
          </BikeModelsMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-version",
      element: (
        <PrivateRoute>
          <BikeVariantsMain>
            <BikeVersions />
          </BikeVariantsMain>
        </PrivateRoute>
      ),
    },

    {
      path: "/bike-version/create-bike-version",
      element: (
        <PrivateRoute>
          <BikeVariantsMain>
            <AddBikeVariant />
          </BikeVariantsMain>
        </PrivateRoute>
      ),
    },
    // {
    //   path: "/edit-car-variant",
    //   element: (
    //     <PrivateRoute>
    //       <CarVersionMain>
    //         <EditCarVariant />
    //       </CarVersionMain>
    //     </PrivateRoute>
    //   ),
    // },
    {
      path: "/edit-bike-version",
      element: (
        <PrivateRoute>
          <BikeVariantsMain>
            <BikeVersionManagement />
          </BikeVariantsMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/manage-bike-version",
      element: (
        <PrivateRoute>
          <BikeVariantsMain>
            <BikeVersionManagement />
          </BikeVariantsMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/trending-bikes",
      element: (
        <PrivateRoute>
          <TrendingBikes />
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-carousel",
      element: (
        <PrivateRoute>
          <BikeCarouselMain>
            <BikeCarousels />
          </BikeCarouselMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/scooter-carousel",
      element: (
        <PrivateRoute>
          <ScooterCarouselMain>
            <ScooterCarousels />
          </ScooterCarouselMain>
        </PrivateRoute>
      ),
    },

    {
      path: "/bike-dealer",
      element: (
        <PrivateRoute>
          <BikeDealersMain>
            <BikeDealers />
          </BikeDealersMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-dealer/add-bike-dealer",
      element: (
        <PrivateRoute>
          <BikeDealersMain>
            <AddBikeDealer />
          </BikeDealersMain>
        </PrivateRoute>
      ),
    },

    {
      path: "/bike-dealer/edit-bike-dealer/:id",
      element: (
        <PrivateRoute>
          <BikeDealersMain>
            <EditBikeDealer />
          </BikeDealersMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-city-price",
      element: (
        <PrivateRoute>
          <BikeCityPriceMain>
            <BikeCityPriceList />
          </BikeCityPriceMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/add-bike-city-price",
      element: (
        <PrivateRoute>
          <BikeCityPriceMain>
            <AddBikeCityPrice />
          </BikeCityPriceMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/edit-bike-city-price/:id/:url",
      element: (
        <PrivateRoute>
          <BikeCityPriceMain>
            <EditBikeCityPrice />
          </BikeCityPriceMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/delete-bike-city-price",
      element: (
        <PrivateRoute>
          <BikeCityPriceMain>
            <DeleteBikeCityPrice />
          </BikeCityPriceMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-city-price/multiple-city-price",
      element: (
        <PrivateRoute>
          <MultipleBikeCityPriceMain />
        </PrivateRoute>
      ),
    },

    {
      path: "/popular-bikes",
      element: (
        <PrivateRoute>
          <PopularBikes />
        </PrivateRoute>
      ),
    },
    {
      path: "/popular-scooter",
      element: (
        <PrivateRoute>
          <PopularScooter />
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-comparison",
      element: (
        <PrivateRoute>
          <BikeComparisonMain />
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-vehicle-type",
      element: (
        <PrivateRoute>
          <BikeVehicleTypeMain>
            <BikeVehicleTypes />
          </BikeVehicleTypeMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/vehicle-type/add-bike-vehicle-type",
      element: (
        <PrivateRoute>
          <BikeVehicleTypeMain>
            <AddBikeVehicleType />
          </BikeVehicleTypeMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/vehicle-type/edit-bike-vehicle-type/:id",
      element: (
        <PrivateRoute>
          <BikeVehicleTypeMain>
            <EditBikeVehicleType />
          </BikeVehicleTypeMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-service-centre",
      element: (
        <PrivateRoute>
          <BikeServiceCentreMain>
            <BikeServiceCentres />
          </BikeServiceCentreMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-service-centre/add-bike-service-centre",
      element: (
        <PrivateRoute>
          <BikeServiceCentreMain>
            <AddBikeServiceCentre />
          </BikeServiceCentreMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-service-centre/edit-bike-service-centre/:id",
      element: (
        <PrivateRoute>
          <BikeServiceCentreMain>
            <EditBikeServiceCentre />
          </BikeServiceCentreMain>
        </PrivateRoute>
      ),
    },

    {
      path: "/bike-city",
      element: (
        <PrivateRoute>
          <BikeCityMain>
            <BikeCities />
          </BikeCityMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-city/add-bike-city",
      element: (
        <PrivateRoute>
          <BikeCityMain>
            <AddBikeCity />
          </BikeCityMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-city/edit-bike-city/:id",
      element: (
        <PrivateRoute>
          <BikeCityMain>
            <EditBikeCity />
          </BikeCityMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-faq",
      element: (
        <PrivateRoute>
          <BikeFAQMain>
            <BikeFAQs />
          </BikeFAQMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/add-bike-faq",
      element: (
        <PrivateRoute>
          <BikeFAQMain>
            <AddBikeFAQ />
          </BikeFAQMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/edit-bike-faq/:id",
      element: (
        <PrivateRoute>
          <BikeFAQMain>
            <EditBikeFAQ />
          </BikeFAQMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-questions",
      element: (
        <PrivateRoute>
          <BikeFAQMainSection>
            <BikeQuestion />
          </BikeFAQMainSection>
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-answers",
      element: (
        <PrivateRoute>
          <BikeFAQMainSection>
            <BikeAnswers />
          </BikeFAQMainSection>
        </PrivateRoute>
      ),
    },
    {
      path: "/add-bike-question",
      element: (
        <PrivateRoute>
          <BikeFAQMainSection>
            <AddBikeQuestion />
          </BikeFAQMainSection>
        </PrivateRoute>
      ),
    },
    {
      path: "/add-bike-answer",
      element: (
        <PrivateRoute>
          <BikeFAQMainSection>
            <AddBikeAnswer />
          </BikeFAQMainSection>
        </PrivateRoute>
      ),
    },
    {
      path: "/edit-bike-question/:questionId",
      element: (
        <PrivateRoute>
          <BikeFAQMainSection>
            <EditBikeQuestion />
          </BikeFAQMainSection>
        </PrivateRoute>
      ),
    },
    {
      path: "/edit-bike-answer/:answerId",
      element: (
        <PrivateRoute>
          <BikeFAQMainSection>
            <EditBikeAnswer />
          </BikeFAQMainSection>
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-user-review",
      element: (
        <PrivateRoute>
          <BikeUserReviewMain />
        </PrivateRoute>
      ),
    },
    {
      path: "/add-bike-user-review",
      element: (
        <PrivateRoute>
          <AddBikeUserReview />
        </PrivateRoute>
      ),
    },
    {
      path: `/edit-bike-user-review/:id`,
      element: (
        <PrivateRoute>
          <EditBikeUserReview />
        </PrivateRoute>
      ),
    },
    {
      path: "/popup-ads",
      element: (
        <PrivateRoute>
          <AdPageMain>
            <AdPage />
          </AdPageMain>
        </PrivateRoute>
      ),
    },
    // {
    //   path: "/edit-ad-page/:id",
    //   element: (
    //     <PrivateRoute>
    //       <AdPageMain>
    //         <EditadPage />
    //       </AdPageMain>
    //     </PrivateRoute>
    //   )
    // },
    {
      path: "/category-ads",
      element: (
        <PrivateRoute>
          <CategoryAds>
            {/* <WidgetDescription /> */}
            <CategoryAdsPage />
          </CategoryAds>
        </PrivateRoute>
      ),
    },
    // {
    //   path: "/ad-widget-edit/:id",
    //   element: (
    //     <PrivateRoute>
    //       <CategoryAds>
    //         <AdWidgetDescription />
    //       </CategoryAds>
    //     </PrivateRoute>
    //   )
    // },
    {
      path: "/subscription",
      element: (
        <PrivateRoute>
          <SubscriptionMain>
            <Subscription />
          </SubscriptionMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/subscription/edit-subscription/:id",
      element: (
        <PrivateRoute>
          <SubscriptionMain>
            <EditSubscription />
          </SubscriptionMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/subscription/add-subscription",
      element: (
        <PrivateRoute>
          <SubscriptionMain>
            <AddSubscription />
          </SubscriptionMain>
        </PrivateRoute>
      ),
    },

    {
      path: "/magazine",
      element: (
        <PrivateRoute>
          <MagazineMain>
            <MagazineList />
          </MagazineMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/magazine/edit-magazine/:id",
      element: (
        <PrivateRoute>
          <MagazineMain>
            <EditMagazine />
          </MagazineMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/magazine/add-magazine",
      element: (
        <PrivateRoute>
          <MagazineMain>
            <AddMagazine />
          </MagazineMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/template",
      element: (
        <PrivateRoute>
          <TemplateMain>
            <TemplateList />
          </TemplateMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/template/edit-template/:id",
      element: (
        <PrivateRoute>
          <TemplateMain>
            <EditTemplate />
          </TemplateMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/template/add-template",
      element: (
        <PrivateRoute>
          <TemplateMain>
            <AddTemplate />
          </TemplateMain>
        </PrivateRoute>
      ),
    },

    {
      path: "/comments",
      element: (
        <PrivateRoute>
          <CommentListing />
        </PrivateRoute>
      ),
    },
    {
      path: "/feedback",
      element: (
        <PrivateRoute>
          <Feedback />
        </PrivateRoute>
      ),
    },
    {
      path: "/notification",
      element: (
        <PrivateRoute>
          <Notification />
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-enquiry-admin",
      element: (
        <PrivateRoute>
          <EnquiryAdmin type="bike" />
        </PrivateRoute>
      ),
    },
    {
      path: "/car-enquiry-admin",
      element: (
        <PrivateRoute>
          <EnquiryAdmin type="car" />
        </PrivateRoute>
      ),
    },
    {
      path: "/user",
      element: (
        <PrivateRoute>
          <UserMain>
            <UserManage />
          </UserMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/user-edit",
      element: (
        <PrivateRoute>
          <UserMain>
            <UserManageEdit />
          </UserMain>
        </PrivateRoute>
      ),
    },

    {
      path: "/state-price",
      element: (
        <PrivateRoute>
          <StatePriceMain>
            <StatePrice />
          </StatePriceMain>
        </PrivateRoute>
      ),
    },
    {
      path: "/bike-state-price",
      element: (
        <PrivateRoute>
          <BikeStatePriceMain>
            <BikeStatePrice />
          </BikeStatePriceMain>
        </PrivateRoute>
      ),
    },
  ],
  {
    basename: "/",
  }
);
function App() {
  const logoutUser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_role");
    localStorage.removeItem("admin_type");
    sessionStorage.removeItem("menuItems");
    sessionStorage.removeItem("notificationCount");
    localStorage.removeItem("userName");
    localStorage.removeItem("userImage");
    localStorage.removeItem("user_id");
    localStorage.removeItem("token_expiration_time");
    window.location.href = "/login";
  };

  useAuthTimeout(logoutUser);

  return (
    <>
      <AppSideBarProvider>
        <RouterProvider router={router} />
        <ToastContainer />
      </AppSideBarProvider>
    </>
  );
}

export default App;
