import React, { useState } from "react";
import {
  BrowserRouter,
  Navigate,
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ReactSVG } from "react-svg";
import Layout from "../../../components/Layout";
import Heading from "../../../components/Heading";
import HeadingTwo from "../../../components/HeadingTwo";
import PlusLargeIcon from "../../../assets/icons/bootstrap-icons/plus-lg.svg";
import DownloadIcon from "../../../assets/icons/bootstrap-icons/download.svg";

export default function CarVariantsMain({ children }) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="row dashboard">
        <div className="d-flex">
          <div className="margin-heading col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-4 col-6">
            {location.pathname === "/car-version" ? <></> : null}
          </div>
        </div>
        <div>{children}</div>
      </div>
    </Layout>
  );
}
