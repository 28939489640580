import { Accordion, Button, Form } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import RepeatIcon from "../../../../assets/icons/bootstrap-icons/arrow-repeat.svg";
import EyeIcon from "../../../../assets/icons/feather-eye.svg";
import CarImage from "../../../../assets/CarImage.svg";
import { useNavigate } from "react-router-dom";
import FormFieldErrorMsg from "../../../../components/FormFieldErrorMsg";
import { correctImagePath, renderText } from "../../../../utility/constants";
import pdfSvg from "../../../../assets/icons/pdf-svg.svg";
import { notifyError } from "../../../../utility/common";
import { useState } from "react";

const AccordianRight = ({
  modelDetails,
  brandDetails,
  bodyTypeDetails,
  electricCarChecked,
  handleCheckboxChange,
  handleRadioClick,
  handleFileChange,
  fileName,
  values,
  setFieldValue,
  touched,
  errors,
  multipleVehicleData,
  setMultipleVehicleData,

  handleBodyTypeRadioChange,
  handleCarBrandTypeRadioChange,
  handleBrochureFileChange,
  brochureFileName,
  setModelDetails,
}) => {
  const navigate = useNavigate();
  const [fileNames, setFileNames] = useState("No file choosen");
  return (
    <div className="col-xl-3  col-sm-4">
      <Accordion defaultActiveKey="0" className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header className=" ps-2">Publish</Accordion.Header>
          <Accordion.Body>
            <div className="d-flex justify-content-between flex-wrap">
              <Button
                variant="btnBlue"
                className="d-flex align-items-center justify-content-between"
                type="submit"
              >
                <ReactSVG src={RepeatIcon} className="me-1" /> Update
              </Button>
              <a
                className="preview-btn d-flex align-items-center justify-content-between px-2 mb-2"
                href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/new-cars/${values.brand_url}/${values.model_url}`}
                target="_blank"
              >
                <ReactSVG src={EyeIcon} className="me-1" />
                Preview
              </a>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion defaultActiveKey="0" className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="p-3 pb-2 ps-2 pb-0">
            Electric Car
          </Accordion.Header>
          <Accordion.Body className="pt-0 checkbox-body">
            <div class="checkbox-container">
              <input
                type="checkbox"
                id="myCheckbox"
                checked={values.electric_car}
                onChange={(e) =>
                  setFieldValue("electric_car", e.target.checked)
                }
                name="electric_car"
              />
              <label htmlFor="myCheckbox">Electric Car</label>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion defaultActiveKey="0" className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="p-3 pb-1 ps-2">
            Car Brand
          </Accordion.Header>
          <Accordion.Body className="scrollable-body">
            {brandDetails.map((item, index) => (
              <div class="checkbox-container mb-2">
                <Form.Check
                  type="checkbox"
                  id={index}
                  onChange={(e) => {
                    setFieldValue("brand_name", item.brand_name);
                    setFieldValue("brand_id", item.id);
                  }}
                  name="brand"
                  checked={+values.brand_id == item.id}
                />
                <label htmlFor="myCheckbox">{item.brand_name}</label>
              </div>
            ))}
          </Accordion.Body>{" "}
          <div className="p-2">
            <FormFieldErrorMsg
              touched={touched}
              errors={errors}
              name={"brand_id"}
            />
          </div>
        </Accordion.Item>
      </Accordion>

      <Accordion defaultActiveKey="0" className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="p-3 pb-1 ps-2">
            Vehicle Type
          </Accordion.Header>
          <Accordion.Body className="scrollable-body">
            <div>
              <div className="mb-3">
                {bodyTypeDetails.map((item, index) => (
                  <div key={index} class="checkbox-container mb-2">
                    <Form.Check
                      type="checkbox"
                      id={index}
                      onChange={(e) => setFieldValue("body_type", item.id)}
                      name="body_type"
                      checked={values.body_type == item.id}
                    />
                    <label htmlFor="myCheckbox">{item.type_name}</label>
                  </div>
                ))}
              </div>
            </div>
          </Accordion.Body>
          <div className="p-2">
            <FormFieldErrorMsg
              touched={touched}
              errors={errors}
              name={"body_type"}
            />
          </div>
        </Accordion.Item>
      </Accordion>

      <Accordion defaultActiveKey="0" className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="p-3 pb-1 ps-2">
            Multiple Vehicle Type
          </Accordion.Header>
          <Accordion.Body className="scrollable-body">
            <div>
              <div className="mb-3">
                {console.log(values.multiple_vehicle, "hi")}
                {bodyTypeDetails.map((item, index) => (
                  <div key={index} class="checkbox-container mb-2">
                    <Form.Check
                      type="checkbox"
                      label={item.type_name}
                      name={"body_type"}
                      id={item.id}
                      onChange={(e) => {
                        const { checked } = e.target;
                        console.log("checked", checked);
                        if (checked) {
                          setFieldValue("multiple_vehicle", [
                            ...values.multiple_vehicle,
                            +item.id,
                          ]);
                        } else {
                          setFieldValue(
                            "multiple_vehicle",
                            values.multiple_vehicle.filter((i) => i != +item.id)
                          );
                        }
                      }}
                      checked={values.multiple_vehicle.includes(+item.id)}
                    />
                  </div>
                ))}
                <FormFieldErrorMsg
                  touched={touched}
                  errors={errors}
                  name={"body_type"}
                />
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion defaultActiveKey="0" className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="p-3 ps-2 pb-0 brand_logo_btn">
            Model Image
            <small className="small-text">(Format : JPG, JPEG, PNG)</small>
          </Accordion.Header>
          <Accordion.Body className="pt-0 ">
            <div className="mt-4">
              {console.log(values.model_image)}
              {values.model_image ? (
                values.model_image instanceof File ||
                values.model_image instanceof Blob ? (
                  <img
                    src={URL.createObjectURL(values.model_image)}
                    alt="model_image"
                    className="w-100"
                  />
                ) : (
                  <img
                    src={
                      correctImagePath(values.model_image)
                      // values.model_image.startsWith("https://")
                      //   ? values.model_image
                      //   : `https://static.autox.com/uploads/cars/${values.model_image}`
                    }
                    alt="model_image"
                    className="w-100"
                  />
                )
              ) : null}

              <Form.Group controlId="formImageFile" className="mb-1">
                {" "}
                <div className="file-container">
                  <label htmlFor="imageFileInput" className="custom-file-input">
                    {" "}
                    Choose File
                  </label>
                  <input
                    type="file"
                    id="imageFileInput"
                    style={{ display: "none" }}
                    // onChange={handleFileChange}
                    onChange={(e) => {
                      const selectedFile = e.target.files[0];
                      const maxSizeKB = 200;
                      if (selectedFile) {
                        if (selectedFile.size > maxSizeKB * 1024) {
                          notifyError(
                            "Image size exceeds 200Kb. Please choose a smaller image."
                          );
                          e.target.value = "";
                          return;
                        }
                        const img = new Image();
                        const objectUrl = URL.createObjectURL(selectedFile);

                        img.onload = () => {
                          // Check image dimensions
                          if (img.width !== 500 || img.height !== 261) {
                            notifyError("Image dimensions must be 500x261.");
                            e.target.value = "";
                            return;
                          }
                          console.log("selectedFile", selectedFile);
                          setFieldValue("model_image", selectedFile);
                          setModelDetails((prev) => {
                            return { ...prev, model_image: selectedFile };
                          });
                          setFileNames(selectedFile.name);
                          URL.revokeObjectURL(objectUrl);
                        };

                        img.onerror = () => {
                          notifyError("Invalid image file.");
                          e.target.value = "";
                          URL.revokeObjectURL(objectUrl);
                        };

                        img.src = objectUrl;
                      } else {
                        setFieldValue("model_image", null);
                        setFileNames("No file chosen");
                      }
                    }}
                  />
                  <span className="file-name ps-1">
                    {renderText(fileNames, 15)}
                  </span>
                </div>
                <Form.Label className="logo-dimension">
                  Max 200Kb & Dimension: 500X261
                </Form.Label>
              </Form.Group>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion defaultActiveKey="0" className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="p-3 ps-2 pb-0 brand_logo_btn">
            Model Brochure
            <small className="small-text">(Format : PDF)</small>
          </Accordion.Header>
          <Accordion.Body className="pt-0 ">
            <div className="mt-4">
              {brochureFileName !== "No file chosen" ||
              (values.model_brochure !== null &&
                values.model_brochure !== "null") ? (
                <img
                  src={pdfSvg}
                  alt="Logo"
                  className="img-fluid p-4"
                  onClick={() =>
                    window.open(
                      correctImagePath(values.model_brochure),
                      "_blank"
                    )
                  }
                  style={{
                    cursor: "pointer",
                  }}
                />
              ) : null}
              <Form.Group controlId="formBrochureFile" className="mb-1">
                {" "}
                {/* Changed controlId to formBrochureFile */}
                <div className="file-container">
                  <label
                    htmlFor="brochureFileInput"
                    className="custom-file-input"
                  >
                    {" "}
                    {/* Changed htmlFor to brochureFileInput */}
                    Choose File
                  </label>
                  <input
                    type="file"
                    id="brochureFileInput"
                    style={{ display: "none" }}
                    onChange={handleBrochureFileChange}
                  />
                  <span className="file-name ps-1">
                    {renderText(brochureFileName, 15)}
                  </span>
                </div>
                <small className="mt-2">Size: 10MB</small>
              </Form.Group>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default AccordianRight;
