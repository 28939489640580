import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeadingTwo from "../../../components/HeadingTwo";
import Table from "../../../components/Table";
import EditViewDeleteReviewPassword from "../../../components/EditViewDeleteReviewPassword";
import { ReactSVG } from "react-svg";
import Search from "../../../components/SearchField";
import FilterIcon from "../../../assets/icons/bootstrap-icons/funnel.svg";
import Breadcrumbs from "../../../Breadcrumbs";
import { Card } from "react-bootstrap";
import PlusLogo from "../../../assets/icons/bootstrap-icons/plus-lg.svg";
import apiHandler from "./apiHandler";
import DeleteConfirmationModalComponent from "../../../components/DeleteConfirmationModalComponent";
import { notifyError, notifySuccess } from "../../../utility/common";
import { getToken } from "../../../utility/constants";

export default function BikeVehicleTypes({ children }) {
  const navigate = useNavigate();
  const token = getToken();
  const [searchVal, setSearchVal] = useState("");
  const [bodyType, setBodyType] = useState([]);
  const [slicedBodyType, setSlicedBodyType] = useState([]);
  const [filteredBodyType, setFilteredBodyType] = useState([]);

  const [reload, setReload] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [show, setShow] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const onEditInner = (id) => {
    navigate(`/vehicle-type/edit-car-vehicle-type/${id}`);
  };
  const getBodyTypeData = () => {
    setBodyType(data);
  };
  useEffect(() => {
    setFilteredBodyType(bodyType);
  }, [bodyType]);

  useEffect(() => {
    getBodyTypeData();
  }, []);

  useEffect(() => {
    handleBodyTypeSlicing();
  }, [currentPage, filteredBodyType, itemsPerPage]);

  const onDeleteInner = (id) => {
    apiHandler
      .deleteBikeBodyTypeById(token, deleteId)
      .then(() => {
        setShow(false);
        setReload(true);
        notifySuccess("Bike body type deleted successfully");
      })
      .catch((error) => {
        setShow(false);
        notifyError(error.message);
      });
  };

  const columns = [
    {
      name: "Sr No.",
      sortable: true,
      selector: (_, index) =>
        index + 1 + currentPage * itemsPerPage - itemsPerPage,
      width: "100px",
    },

    {
      name: "Bike Body Type",
      sortable: true,
      selector: (row) => row.type_name,

      width: "400px",
    },
    {
      name: "Url",
      sortable: true,
      selector: (row) => row.type_url,

      width: "400px",
    },
    {
      name: "Action",
      sortable: false,
      width: "auto",
      cell: (row) => (
        <EditViewDeleteReviewPassword
          id={row.id}
          onEdit={onEditInner}
          onDelete={(id) => {
            setDeleteId(id);
            setShow(true);
          }}
        />
      ),
    },
  ];

  const data = [
    { index: 1, carVehicleType: "abc", url: "test" },
    { index: 2, carVehicleType: "abc", url: "test" },
  ];

  function handleSearchClick() {
    setReload(true);
  }

  function handleBodyTypeSlicing() {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const slicedBodyTypeInner = filteredBodyType.slice(startIndex, endIndex);
    setSlicedBodyType(slicedBodyTypeInner);
  }

  return (
    <div className="row dashboard">
      <div className="d-flex justify-content-between">
        <Breadcrumbs />
      </div>
      <Card className="ms-2">
        <Card.Body>
          <Card.Text>
            <div className="row dashboard">
              <div className="col-md-7">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="table-heading mb-3">Body Type List</div>
                    <div className="py-0 ms-2">
                      <Search
                        onChange={(event) => {
                          console.log("ev", event);
                          setSearchVal(event.target.value);
                        }}
                        value={searchVal}
                      />
                    </div>
                    <button
                      className="black-filled-button ms-3"
                      onClick={handleSearchClick}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-5 text-right">
                <div className="btngroup d-flex justify-content-end flex-row flex-wrap">
                  <button
                    onClick={() =>
                      navigate("/vehicle-type/add-bike-vehicle-type")
                    }
                    className="bulk-update-button d-flex align-items-center justify-content-between mt-2  mb-2 px-3"
                  >
                    <ReactSVG
                      src={PlusLogo}
                      height="18px"
                      width="18px"
                      className="mr-2"
                    />{" "}
                    Add New Bike Vehicle Type
                  </button>
                </div>
              </div>
              <Table
                api={apiHandler.getBikeBodyType}
                columns={columns}
                data={slicedBodyType}
                currentPage={currentPage}
                showPagination={true}
                onChangePage={(pageNumber) => setCurrentPage(pageNumber)}
                totalPages={Math.ceil(filteredBodyType.length / itemsPerPage)}
                setTotalRecords={setTotalRecords}
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
                setReload={setReload}
                reload={reload}
                search={searchVal}
              ></Table>
            </div>
            <DeleteConfirmationModalComponent
              show={show}
              type={"delete"}
              handleClose={() => setShow(false)}
              setDeleteConfirm={onDeleteInner}
            />
          </Card.Text>
        </Card.Body>
      </Card>
      {/* <div className="card">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-4 col-6">
                    <HeadingTwo
                        title="Bike Vehicle Type List"
                        type="dashboard"
                        showHeader={true}
                        pointerOnHover={true}
                    />
                </div>

                <div className="d-flex justify-content-end">
                <div class="my-2 d-flex border border-2 align-items-center rounded-2 px-2 space-between" onClick={() => { }} >
                        <input type="text" className="form-control border-0 shadow-none text-dark px-0" id="searchEditor" placeholder="Search by vehicle type" />
                        <ReactSVG src={SearchIcon} />
                    </div>
                </div>

                <Table
                    columns={columns}
                    data={data}
                >
                </Table>
            </div> */}
    </div>
  );
}
