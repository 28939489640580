import axios from "axios";
import { getConfig, BASE_URL_AUTOX } from "../../../request";
export const GET_CAR_SERVICE_CENTRE = "new-bike/service-centre/filtered/list";
export const GET_CAR_SERVICE_CENTRE_BY_ID = "new-bike/service-centre/";
export const ADD_CAR_SERVICE_CENTRE = "new-bike/service-centre/add";
export const UPDATE_CAR_SERVICE_CENTRE = "new-bike/service-centre/update/";
export const DELETE_CAR_SERVICE_CENTRE = "new-bike/service-centre/delete/";
export const GET_ALL_CAR_CITIES = "new-bike/city/all/list";
export const GET_ALL_BRAND_LIST = "new-bike/brand/all/list";
export const GET_ALL_STATES = "state/all/list";

/**
 * Add New Bike Service Centre Data
 * addBikeServiceCentre is used to create new bike service centre data
 * @param token
 * @param payload
 */
export function addBikeServiceCentre(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${ADD_CAR_SERVICE_CENTRE}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get List of Bike Service Centres
 * getBikeServiceCentres is used to get all the data of bike service centres
 * @param token
 * @param payload
 */
export function getBikeServiceCentres(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_CAR_SERVICE_CENTRE}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get Bike Service Centre By Id
 * getBikeServiceCentreById is used to get data of bike service centre by id
 * @param token
 * @param id
 */
export function getBikeServiceCentreById(token, id) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_CAR_SERVICE_CENTRE_BY_ID}${id}`,
    getConfig(token)
  );
}

/**
 * Update Bike Service Centre By Id
 * updateBikeServiceCentreById is used to update data of bike service centre by id
 * @param token
 * @param id
 * @param payload
 */
export function updateBikeServiceCentreById(token, id, payload) {
  return axios.put(
    `${BASE_URL_AUTOX}${UPDATE_CAR_SERVICE_CENTRE}${id}`,
    payload,
    getConfig(token)
  );
}

/**
 * Delete Bike Service Centre By Id
 * deleteBikeServiceCentreById is used to delete single record of bike service centre by id
 * @param token
 * @param id
 */
export function deleteBikeServiceCentreById(token, id) {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_CAR_SERVICE_CENTRE}${id}`,
    getConfig(token)
  );
}

/**
 * Get List of States
 * getAllStates is used to get subset data of all cities
 * @param token
 * @param payload
 */
export function getAllBikeCities(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_ALL_CAR_CITIES}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get List of Cities
 * getAllBikeCities is used to get subset data of all cities
 * @param token
 * @param payload
 */
export function getCityBystate(token, payload) {
  return axios.get(
    `${BASE_URL_AUTOX}new-bike/city/city-by-state/${payload}`,
    getConfig(token)
  );
}

/**
 * Get List of Brands
 * getAllBrands is used to get data of all brands
 * @param token
 * @param payload
 */
export function getAllBrands(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_ALL_BRAND_LIST}`,
    payload,
    getConfig(token)
  );
}

/**
 * Get List of States
 * getAllStates is used to get data of states
 * @param token
 * @param payload
 */
export function getAllStates(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_ALL_STATES}`,
    payload,
    getConfig(token)
  );
}

export default {
  addBikeServiceCentre,
  getBikeServiceCentres,
  getBikeServiceCentreById,
  updateBikeServiceCentreById,
  deleteBikeServiceCentreById,
  getAllBrands,
  getAllBikeCities,
  getAllStates,
  getCityBystate,
};
