import React from "react";
import DataTable from "react-data-table-component";
import { useLocation } from "react-router-dom";
import CustomPagination from "./CustomPagination";
import DropUpIcon from "../assets/icons/bootstrap-icons/Icon ionic-md-arrow-dropup.svg";

export default function OldTable({
  columns,
  header,
  showHeader,
  data,
  showPagination,
  pointerOnHover,
  onChangePage,
  currentPage,
  totalPages,
  itemsPerPage,
}) {
  const location = useLocation();
  const onRowClick = (row) => {
    switch (location.pathname) {
      default:
        console.log("row", row);
        break;
    }
  };

  const customStyles = {
    rows: {
      style: {
        "&:nth-child(2n+1)": {
          backgroundColor: "#F6F6F6",
          height: "35px",
        },
        minHeight: "30px",
        paddingLeft: "16px",
        paddingRight: "16px",
        height: "45px",
        border: "0px !important",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#FFFFFF !important",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "2px",
        paddingBottom: "2px",
      },
    },
    headCells: {
      style: {
        fontWeight: "600",
        fontSize: "13px",
        paddingLeft: "0px",
        paddingRight: "0px",
        textAlign: "left",
      },
    },
    cells: {
      style: {
        fontWeight: "400",
        paddingLeft: "0px",
        paddingRight: "0px",
        textAlign: "left",
      },
    },
  };

  return (
    <div className="mt-4 data-table table-bordered">
      <DataTable
        columns={columns}
        data={data}
        subHeaderComponent={header}
        subHeader={showHeader}
        paginationPerPage={itemsPerPage}
        pagination={showPagination}
        paginationComponent={() => (
          <CustomPagination
            onChangePage={onChangePage}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        )}
        responsive={true}
        sortIcon={
          <img src={DropUpIcon} alt="Drop up icon" className="dropup-icon" />
        }
        defaultSortAsc={false}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        onRowClicked={onRowClick}
        pointerOnHover={pointerOnHover}
        customStyles={customStyles}
      />
    </div>
  );
}
