import * as Yup from "yup";

export const carVersionValidation = Yup.object({
  brand_id: Yup.number()
    .min(1, "Brand is required")
    .required("Brand is required"),
  model_id: Yup.number()
    .min(1, "Model is required")
    .required("Model is required"),
  version_data: Yup.array().of(
    Yup.object().shape({
      version_name: Yup.string().required("Version Name is required"),
      version_url: Yup.string(),
      show_on_launch: Yup.boolean(),
      launch_date: Yup.date().optional(),
      version_status: Yup.number(),
    })
  ),
});

export const filterValidationSchema = Yup.object({
  brand_id: Yup.number()
    .min(1, "Brand is required")
    .required("Brand is required"),
  model_id: Yup.number()
    .min(1, "Model is required")
    .required("Model is required"),
  filter_type: Yup.number().required("Filter type is required"),
});
