import axios from "axios";
import { getConfig, BASE_URL_AUTOX } from "../../../request";

/**
 * Add New Popular Car Carousel
 * addPopularCar is used to add car as a popular
 * @param token
 * @param payload
 */
const addPopularBike = (payload, token) => {
  return axios.post(
    `${BASE_URL_AUTOX}new-bike/popular/add`,
    payload,
    getConfig(token)
  );
};

/**
 * Delete Popular Car
 * deletePopularCar is used to remove car from popular car
 * @param token
 * @param id
 */
const deletePopularBike = (token, id) => {
  return axios.delete(
    `${BASE_URL_AUTOX}new-bike/popular/delete/${id}`,
    getConfig(token)
  );
};

/**
 * Get List of Popular Car
 * listPopularCar is used to get all the data of popular car
 * @param token
 */
const listPopularBike = (token) => {
  return axios.post(`${BASE_URL_AUTOX}new-bike/popular/list`, getConfig(token));
};

/**
 * Sort Ordering of Car Popular Data
 * sortPopular is used to ordering of car Popular
 * @param token
 * @param carItems
 */
const sortPopularBike = (token, carItems) => {
  return axios.put(
    `${BASE_URL_AUTOX}new-bike/popular/sort`,
    carItems,
    getConfig(token)
  );
};

export default {
  listPopularBike,
  addPopularBike,
  deletePopularBike,
  sortPopularBike,
};
