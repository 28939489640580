import axios from "axios";
import { getConfig, BASE_URL_AUTOX } from "../../../request";

/**
 * Add New Car Carousel Data
 * addCarousel is used to create new carousel data
 * @param token
 * @param payload
 */
const addBikeCarousel = (payload, token) => {
  return axios.post(
    `${BASE_URL_AUTOX}new-bike/carousel/add`,
    payload,
    getConfig(token, { "Content-Type": "multipart/form-data" })
  );
};

/**
 * Delete Car Carousel Data
 * deleteCarousel is used to delete single record of car carousel by id
 * @param token
 * @param id
 */
const deleteBikeCarousel = (token, id) => {
  return axios.delete(
    `${BASE_URL_AUTOX}new-bike/carousel/delete/${id}`,
    getConfig(token)
  );
};

/**
 * Sort Ordering of Car Carousel Data
 * sortCarousel is used to ordering of car carousel
 * @param token
 * @param carousel
 */
const sortBikeCarousel = (token, carousel) => {
  console.log(carousel);
  return axios.put(
    `${BASE_URL_AUTOX}new-bike/carousel/sort`,
    carousel,
    getConfig(token)
  );
};

/**
 * Get List of Car Carousel
 * listCarousel is used to get all the data of car carousel
 * @param token
 */
const listBikeCarousel = (token) => {
  return axios.get(
    `${BASE_URL_AUTOX}frontend/scooter/home/new-scooter/list-slider`,
    getConfig(token)
  );
};
//eslint-disable-next-line import/no-anonymous-default-export
export default {
  listBikeCarousel,
  addBikeCarousel,
  deleteBikeCarousel,
  sortBikeCarousel,
};
