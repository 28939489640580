import React, { useState } from "react";
import { Form, Badge } from "react-bootstrap";

const TagInput = ({ values, setFieldValue, name }) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      e.preventDefault();
      if (!values.includes(inputValue.trim())) {
        setFieldValue(name, [...values, inputValue.trim()]);
        setInputValue("");
      }
    } else if (e.key === "Backspace" && !inputValue && values.length > 0) {
      e.preventDefault();
      setFieldValue(name, values.slice(0, -1));
    }
  };

  const removeTag = (index) => {
    setFieldValue(
      name,
      values.filter((_, i) => i !== index)
    );
  };

  return (
    <>
      <div className="tag-input-container">
        {values.map((tag, index) => (
          <Badge key={index} pill className="tag-badge">
            {tag}
            <span className="remove-tag" onClick={() => removeTag(index)}>
              &times;
            </span>
          </Badge>
        ))}
      </div>
      <div>
        <Form.Control
          type="text"
          placeholder="Add a tag"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          className="tag-input"
        />
      </div>
    </>
  );
};

export default TagInput;
