import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Breadcrumbs from "../../../Breadcrumbs";
import Image from "react-bootstrap/Image";
import BackIcon from "../../../assets/icons/Back_Arrow.png";
import {
  getToken,
  metaRobotsFollow,
  metaRobotsIndex,
} from "../../../utility/constants";
import APIHandler from "./apiHandler";
import { serviceCentreValidation } from "./schemaValidation";
import { ToastContainer } from "react-toastify";
import { notifyError, notifySuccess } from "../../../utility/common";
import BikeServiceCentreForm from "./BikeServiceCentreForm";

export default function EditBikeServiceCentre() {
  const navigate = useNavigate();
  const { id } = useParams();
  const token = getToken();
  const [queryParams, setQueryParams] = useSearchParams();
  const [cities, setCities] = useState([]);
  const [brands, setBrands] = useState([]);
  const [initialValues, setInitialValues] = useState({
    city_id: -1,
    brand_id: -1,
    state_id: -1,
    service_centre_name: "",
    service_centre_url: "",
    service_centre_address: "",
    phone_number: "",
    email_id: "",
    service_centre_content: "",
    meta_title: "",
    meta_description: "",
    meta_keyword: "",
    canonical_url: "",
    breadcrumbs: "",
    robots_follow: "",
    robots_index: "",
  });

  useEffect(() => {
    if (queryParams.get("added") && queryParams.get("added") === "true") {
      setQueryParams({ ["added"]: "false" });
      notifySuccess("Bike Service Centre added successfully");
    } else {
      return;
    }
  }, []);

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    values,
    errors,
    setFieldValue,
    touched,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: serviceCentreValidation,
    onSubmit: async (values, action) => {
      const payload = values;
      delete payload.id;
      delete payload.state_id;
      delete payload.created_date;
      delete payload.updated_date;
      delete payload.brand_name;

      APIHandler.updateBikeServiceCentreById(token, id, payload)
        .then((result) => {
          fetchData();
          notifySuccess("Bike Service Centre updated successfully");
          setTimeout(() => {
            navigate("/bike-service-centre");
          }, 1500);
        })
        .catch((error) => {
          notifyError(error.message);
        });
    },
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    APIHandler.getBikeServiceCentreById(token, id)
      .then((result) => {
        // console.log("result fetch", result);
        const response = result.data.data;
        setInitialValues(response[0]);
      })
      .catch((error) => {
        notifyError(error.message);
      });
  };
  console.log(errors, "hiiiii");

  return (
    <div className="row dashboard">
      <ToastContainer />
      <div className="row">
        <div className="col-xl-11 col-sm-11">
          <Breadcrumbs removeId={true} />
        </div>
        <div className="col-xl-1 col-sm-1">
          <button
            className="d-flex back-button py-2 px-3 mb-2 btn btn-primary"
            onClick={() => navigate("/bike-service-centre")}
          >
            <Image
              src={BackIcon}
              width="16px"
              height="16px"
              className="mt-1 me-2"
            />{" "}
            Back
          </button>
        </div>
      </div>

      <Card>
        <Card.Body>
          <Card.Text>
            <div className="row dashboard">
              <div className="table-heading fw-bold">
                Edit Bike Service Centre
              </div>
              <BikeServiceCentreForm
                type={"Update"}
                values={values}
                errors={errors}
                touched={touched}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
              />
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
