import React from "react";
import AuthLayout from "./AuthLayout";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import SubmitButton from "../../components/SubmitButton";
import SuccesIcon from "../../assets/icons/bootstrap-icons/auth-images/success.png";

export default function PasswordResetSuccessful() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <AuthLayout>
      <main className="d-flex w-100 h-100">
        <div className="container">
          <div className="row h-100 text-center">
            <div className="col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="">
                  <div className="">
                    <div className="m-sm-3">
                      <div className="mb-5">
                        <div className="circle-check my-1">
                          <img src={SuccesIcon} alt="SuccesIcon" />
                        </div>
                        <div className="my-3 text-center ">
                          <h3 className="line-height-1 fw-600 auth-heading">
                            {t("Password Changed")}
                          </h3>
                          <h3 className="fw-600 auth-heading">
                            {t("Successfully")}
                          </h3>
                          <div className="password-rest-sign-in fw-400 auth-message">
                            {t("Please Login")}
                          </div>
                        </div>
                        <div className="d-grid gap-2 mt-5 mb-3">
                          <SubmitButton
                            title="Login"
                            onClick={() => navigate("/login")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </AuthLayout>
  );
}
