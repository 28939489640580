import UploadIcon from "../assets/icons/bootstrap-icons/upload.svg";
import { ReactSVG } from "react-svg";
import { getToken } from "../utility/constants";
import { notifyError } from "../utility/common";

export default function ExportButton({
  api,
  totalRecords,
  keys,
  showKeys,
  payload,
}) {
  const token = getToken();

  // function convertArrayOfObjectsToCSV(array) {
  //   let result = "";
  //   const lineDelimiter = "\n";

  //   // Add the header row
  //   result += showKeys.join(","); // Using semicolon as the delimiter
  //   result += lineDelimiter;

  //   array.forEach((item) => {
  //     let ctr = 0;
  //     keys.forEach((key) => {
  //       if (ctr > 0) result += ","; // Semicolon delimiter

  //       // Enclose the value in quotes to handle commas
  //       const value = String(item[key]);
  //       result += `"${value}"`; // Enclose in quotes

  //       ctr++;
  //     });
  //     result += lineDelimiter;
  //   });

  //   return result;
  // }

  // function downloadCSV(array) {
  //   const link = document.createElement("a");
  //   let csv = convertArrayOfObjectsToCSV(array);
  //   if (csv === null) return;

  //   const filename = "export.csv";

  //   // Create a data URI
  //   if (!csv.match(/^data:text\/csv/i)) {
  //     csv = `data:text/csv;charset=utf-8,${csv}`;
  //   }

  //   link.setAttribute("href", encodeURI(csv));
  //   link.setAttribute("download", filename);
  //   link.click();
  // }

  // const handleCSVExport = async () => {
  //   try {
  //     const getExportData = async () => {
  //       const response = await api(token, payload);
  //       console.log("response.data.result", response.data.result);
  //       return response.data.result;
  //     };
  //     const exportData = await getExportData();
  //     downloadCSV(exportData);
  //   } catch (error) {
  //     console.log(error);
  //     notifyError(error.response?.data?.message || "Error exporting data");
  //   }
  // };

  function convertArrayOfObjectsToCSV(array) {
    if (!array || array.length === 0) return "";

    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]); // Extract column headers dynamically

    // Add the header row
    let result = keys.join(",") + lineDelimiter; // Comma-separated

    array.forEach((item) => {
      const row = keys
        .map((key) => `"${(item[key] || "").toString().replace(/"/g, '""')}"`) // Escape double quotes
        .join(",");

      result += row + lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const csv = convertArrayOfObjectsToCSV(array);
    if (!csv) return;

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" }); // Use Blob instead of data URI
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", "export.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url); // Clean up memory
  }

  const handleCSVExport = async () => {
    try {
      const getExportData = async () => {
        const response = await api(token, payload);
        console.log("response.data.result", response.data.result);
        return response.data.result;
      };

      const exportData = await getExportData();
      downloadCSV(exportData);
    } catch (error) {
      console.log(error);
      notifyError(error.response?.data?.message || "Error exporting data");
    }
  };

  return (
    <button
      className="export-button d-flex align-items-center text-nowrap justify-content-between me-2 mb-2 text-white px-3"
      onClick={handleCSVExport}
    >
      <ReactSVG height="18px" width="18px" src={UploadIcon} className="mr-2" />{" "}
      Export
    </button>
  );
}
