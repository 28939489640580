import UploadIcon from "../assets/icons/bootstrap-icons/upload.svg";
import { ReactSVG } from "react-svg";
import { getToken } from "../utility/constants";
import { notifyError } from "../utility/common";
export default function ExportMagazine({ api, payload }) {
  const token = getToken();
  const keys = [
    "model_name",
    "updated_launch_date",
    "brand_name",
    "version_name",
    "delhi",
    "mumbai",
    "banglore",
    "min_price",
    "max_price",
    "displacement",
    "cylinders",
    "powerbhp",
    "torquenm",
    "noofgears",
    "fueltype",
    "wheelbase",
    "kerbweight",
  ];
  const showKeys = [
    "Model Name",
    "Launched Date",
    "Brand",
    "Version",
    "Delhi",
    "Mumbai",
    "Banglore",
    "Min Price",
    "Max Price",
    "Engine (cc)",
    "Cylinders",
    "power(bhp)",
    "Torque(Nm)",
    "No of Gears",
    "Fuel Type",
    "wheelbase",
    "weight",
  ];
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    result = "";

    result += showKeys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key, index) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv === null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const handleCSVExport = async () => {
    try {
      const getExportData = async () => {
        const temp = {
          city_id: payload,
        };
        const response = await api(token, temp);
        // console.log(response);
        return response.data.result;
      };
      const exportData = await getExportData();
      downloadCSV(exportData);
    } catch (error) {
      console.log(error);
      notifyError(error.response.data.message);
    }
  };
  return (
    <>
      <button
        className="export-magazine-button d-flex align-items-center justify-content-between me-2 mb-2 px-3"
        onClick={() => handleCSVExport()}
      >
        <ReactSVG
          height="18px"
          width="18px"
          src={UploadIcon}
          className="mr-2"
        />{" "}
        Export Magazine Data
      </button>
    </>
  );
}
