import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../request";

export  function getNewsLetterList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}news-letter/list`,
    payload,
    getConfig(token)
  );
}


export  function addNewNewsLetter(values,token){
  return axios.post(
    `${BASE_URL_AUTOX}news-letter/add-news-letter`,
    values,
    getConfig(token)
  )
}

export function getNewsletterDetails(id, token) {
  return axios.get(
    `${BASE_URL_AUTOX}news-letter/news-letter-details/${id}`,
    getConfig(token)
  );
}

export function updateNewsletterDetails(id, values, token) {
  return axios.put(
    `${BASE_URL_AUTOX}news-letter/edit-news-letter/${id}`,
    values,
    getConfig(token)
  );
}

export function deleteNewsletter(id, token) {
  return axios.delete(
    `${BASE_URL_AUTOX}news-letter/delete-news-letter/${id}`,
    getConfig(token)
  );
}


export default{
  getNewsLetterList,addNewNewsLetter,getNewsletterDetails,updateNewsletterDetails,deleteNewsletter
}
