import { io } from 'socket.io-client';

// Replace with your backend's URL (if you're using local, use localhost)
const socket ="sus"
// = io('http://localhost:1080'); // Or use your production URL

// // Listen for any event from the server
// socket.on('connect', () => {
//   console.log('Connected to Socket.IO server');
// });

// socket.on('postsUpdated', (data) => {
//   console.log('Posts updated:', data); // Handle real-time updates here
// });

export default socket;
