import axios from "axios";
import {
  BASE_URL_AUTOX,
  GET_CAR_CITY_PRICE_LIST,
  GET_CAR_MODEL_LIST_BY_BRAND,
  GET_CAR_VERSION_LIST_BY_MODEL,
  getConfig,
} from "../../../request";

/**
 * Get List of Car city price
 * getCarCityPriceList is used to get List of Car city price
 * @param token
 * @param payload
 */
export function getCarCityPriceList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_CAR_CITY_PRICE_LIST}`,
    payload,
    getConfig(token)
  );
}

export function getCarModelByBrandId(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_CAR_MODEL_LIST_BY_BRAND}`,
    payload,
    getConfig(token)
  );
}

export function getCarVersionByModelId(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_CAR_VERSION_LIST_BY_MODEL}`,
    payload,
    getConfig(token)
  );
}

export function downloadSampleCityPrice() {
  return axios.post(
    `${BASE_URL_AUTOX}car/city-price/downloadCityPriceSample`,
    {}
  );
}

export function deleteBulkCityPrice(token, payload) {
  console.log(payload, "sus");
  return axios.delete(
    `${BASE_URL_AUTOX}car/city-price/delete-citybulk`,
    { data: payload },
    getConfig(token)
  );
}

export async function downloadPrice(token, payload) {
  return await axios.post(
    `${BASE_URL_AUTOX}car/city-price/export-city-price`,
    payload,
    getConfig(token)
  );
}
//eslint-disable-next-line import/no-anonymous-default-export
export default {
  getCarCityPriceList,
  getCarModelByBrandId,
  getCarVersionByModelId,
  downloadSampleCityPrice,
  deleteBulkCityPrice,
  downloadPrice,
};
