import { useNavigate, useParams } from "react-router-dom";
import SidebarAPI from "./SidebarAPI";
import { useEffect, useState } from "react";
import SideBarForm from "./SideBarForm";
import { getToken } from "../../../utility/constants";
import { notifyError, notifySuccess } from "../../../utility/common";

export default function EditSideBar() {
  const navigate = useNavigate();
  const token = getToken();
  const { id } = useParams();
  const [values, setValues] = useState({
    sidebar_name: "",
    router_location: "",
    remark: "",
  });

  useEffect(() => {
    const getSideBarDetails = async () => {
      try {
        const response = await SidebarAPI.getSideBarDetails(token, id);
        console.log(response);
        if (response.status === 200) {
          setValues({
            sidebar_name: response.data.data.sidebar_name,
            router_location: response.data.data.router_location,
            remark: response.data.data.remark,
          });
        }
      } catch (error) {
        console.log(error);
        notifyError(error.response.data.message);
      }
    };

    getSideBarDetails();
  }, []);

  const updateSidebar = async (data) => {
    try {
      const response = await SidebarAPI.updateSidebar(token, id, data);
      console.log(response);
      if (response.status === 200) {
        notifySuccess(response.data.message);
        setTimeout(() => {
          navigate("/sidebar");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      notifyError(error.response.data.message);
    }
  };

  return (
    <>
      <SideBarForm
        onSubmit={updateSidebar}
        initialValues={values}
        title="Edit Sidebar"
      />
    </>
  );
}
