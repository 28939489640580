import React, { useState } from "react";

const StarRating = ({ postRating, setFieldValue }) => {
  const [hoveredRating, setHoveredRating] = useState(0);

  const handleRating = (rating) => {
    setFieldValue("post_rating", rating);
  };

  return (
    <div className="star-rating">
      {[5, 4, 3, 2, 1].map((star) => (
        <span
          key={star}
          onClick={() => handleRating(star)}
          onMouseEnter={() => setHoveredRating(star)}
          onMouseLeave={() => setHoveredRating(0)}
          className={`star ${
            hoveredRating >= star || postRating >= star ? "filled" : ""
          }`}
        >
          ★
        </span>
      ))}
    </div>
  );
};

export default StarRating;
