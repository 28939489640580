import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../request";

export function getNotificationList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}notification/filtered/list`,
    payload,
    getConfig(token)
  );
}

export function deleteNotification(token, payload) {
  return axios.put(
    `${BASE_URL_AUTOX}notification/delete/${payload}`,
    getConfig(token)
  );
}

export default {
  getNotificationList,
  deleteNotification,
};
