import React, { useState, useRef } from "react";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import DeleteConfirmationModalComponent from "../../../components/DeleteConfirmationModalComponent";
import { renderDateTime, renderText } from "../../../utility/constants";
const image_base_url = process.env.REACT_APP_IMAGE_BASE_URL;
export default function BikeCarousels({ carousel, handleDelete, updateSort }) {
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  const showPopup = (carId) => {
    setShow(true);
    setDeleteId(carId);
  };
  const onDeleteInner = () => {
    setShow(false);
    handleDelete(deleteId);
  };

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  //Handle Sort
  const handleSort = () => {
    //duplicate Items
    let __carouselItems = [...carousel];

    //remove and save the dragged item content
    const draggedItemContent = __carouselItems.splice(dragItem.current, 1)[0];

    //switch the position
    __carouselItems.splice(dragOverItem.current, 0, draggedItemContent);

    dragItem.current = null;
    dragOverItem.current = null;

    const sortItems = __carouselItems.map((items) => items.id);
    updateSort(sortItems);
  };

  return (
    <>
      <DeleteConfirmationModalComponent
        show={show}
        type={"delete"}
        handleClose={() => setShow(false)}
        setDeleteConfirm={onDeleteInner}
      />

      <Card className="pb-2 mb-3">
        <Card.Body>
          <Card.Text>
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 ps-58 mb-3">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <div className="d-flex align-items-center">
                    <div className="table-heading">Bike Carousel List</div>
                    <div className="py-0 ms-5"></div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <ListGroup as="ol" className="carCarouselList">
                {carousel.map((item, index) => {
                  return (
                    <ListGroup.Item
                      as="li"
                      key={index}
                      className="d-flex justify-content-between align-items-center cursor-move"
                      draggable
                      onDragStart={(e) => (dragItem.current = index)}
                      onDragEnter={(e) => (dragOverItem.current = index)}
                      onDragEnd={handleSort}
                      onDragOver={(e) => e.preventDefault()}
                    >
                      <div className="ms-2 w-25">
                        <div className="box_de">
                          <img
                            src={`${image_base_url}${
                              item.custom_slider_image.startsWith("/")
                                ? item.custom_slider_image.substring(1)
                                : item.custom_slider_image
                            }`}
                            class="img-fluid"
                            alt="..."
                          />
                        </div>
                      </div>
                      <div className="ms-2 w-25">
                        <div className="carousel-labels">
                          {renderText(item.title, 15)}
                        </div>
                      </div>
                      <div className="ms-2 w-25">
                        <div className="carousel-labels">
                          {renderDateTime(item.created_date)}
                        </div>
                      </div>

                      <div className="ms-2">
                        <button
                          className="carousel-delete-button d-flex align-items-center justify-content-between me-2 mb-2 px-4"
                          onClick={() => showPopup(item.id)}
                        >
                          Delete
                        </button>
                      </div>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}
