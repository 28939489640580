import { useNavigate } from "react-router-dom";
import HeadingTwo from "../../components/HeadingTwo";
import Table from "../../components/Table";
import EditViewDelete from "../../components/EditViewDeleteReviewPassword";
import { ReactSVG } from "react-svg";
import PlusLargeIcon from "../../assets/icons/bootstrap-icons/plus-lg.svg";
import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import DeleteConfirmationModalComponent from "../../components/DeleteConfirmationModalComponent";
import apiHandler from "./apiHandler";
import Breadcrumbs from "../../Breadcrumbs";
import { getToken, renderDate } from "../../utility/constants";
import { notifyError, notifySuccess } from "../../utility/common";
import FilterDropdown from "../../components/FilterDropDown";
import FilterIcon from "../../assets/icons/filter2.svg";
import Search from "../../components/SearchField";
export default function Tags({ children }) {
  const [reloadTable, setReloadTable] = useState(false);
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [tagTypeList, setTagTypeList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tagIdToDelete, setTagIdToDelete] = useState();
  const [search, setSearch] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);

  const [filter, setFilter] = useState({
    verified: "",
    tag_type: "",
  });

  const filterDropdownRef = useRef();

  const navigate = useNavigate();
  const onViewInner = (row) => {
    if (!row.post_count) {
      window.open(`${process.env.REACT_APP_FRONTEND_BASE_URL}/`, "_blank");
    } else {
      window.open(
        `${process.env.REACT_APP_FRONTEND_BASE_URL}/tag/${row.slug}`,
        "_blank"
      );
    }
    // console.log(row);
  };
  const token = getToken();

  const onEditInner = (id) => {
    navigate("/edit-tag/" + id);
  };

  useEffect(() => {
    const fetchTagsType = async () => {
      try {
        apiHandler
          .getTagsType(token)
          .then((res) => {
            console.log(res.data);
            setTagTypeList(res.data);
          })
          .catch((error) => {
            notifyError(error.message);
          });
      } catch (error) {
        notifyError(error.message);
      }
    };

    fetchTagsType();
  }, []);
  const onDeleteInner = () => {
    try {
      apiHandler
        .deleteTagById(tagIdToDelete, token)
        .then((res) => {
          notifySuccess(res.data.message);
          setReloadTable(!reloadTable);
          setShowDeleteModal(false);
        })
        .catch((error) => {
          notifyError(error.message);
        });
    } catch (error) {
      notifyError(error.message);
    }
  };

  const filterOptions = [
    {
      key: "verified",
      label: "Verified Status",
      type: "verified",
      searchKey: "name",
      values: [
        {
          id: 0,
          name: "Unverified",
        },
        {
          id: 1,
          name: "Verified",
        },
      ],
    },
    {
      key: "tag_type",
      label: "Tag Type",
      type: "tag_type",
      searchKey: "tag_type",
      values: tagTypeList,
    },
  ];

  const columns = [
    {
      name: "S. No.",
      sortable: false,
      selector: (row) => row.id,
      width: "auto",
    },
    {
      name: "Title",
      sortable: true,
      selector: (row) => row.tag_name,
      width: "200px",
    },
    {
      name: "Tag Type",
      sortable: true,
      selector: (row) => row.tag_type,
      width: "auto",
    },
    {
      name: "Total Posts",
      sortable: true,
      selector: (row) => row.post_count,
      width: "auto",
    },
    {
      name: "Status",
      sortable: true,
      selector: (row) =>
        row.verified == 1 ? (
          <div className="green-box text-center p-1">Verified</div>
        ) : row.verified == 0 ? (
          <div className="red-box">Unverified</div>
        ) : null,
      width: "auto",
    },
    {
      name: "Date",
      sortable: true,
      selector: (row) => renderDate(row.created_date),
      width: "auto",
    },
    {
      name: "Action",
      sortable: false,
      width: "auto",
      cell: (row) => (
        <EditViewDelete
          id={row.id}
          onEdit={onEditInner}
          onView={() => onViewInner(row)}
          onDelete={() => {
            setTagIdToDelete(row.id);
            setShowDeleteModal(true);
          }}
        />
      ),
    },
  ];
  const handleFilterClick = () => {
    setFilterDropdown(!filterDropdown);
  };
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
    // Clear the previous timeout if it exists
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    // Set a new timeout
    const id = setTimeout(() => {
      setFilter((prevFilter) => ({
        ...prevFilter,
        tag_name: value,
      }));
    }, 2000); // 2 seconds debounce
    // Save the timeout id to clear it if needed
    setTimeoutId(id);
  };
  useEffect(() => {
    // Cleanup function to clear timeout if component unmounts
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);
  const handleSearchClicked = () => {};
  const clearAll = () => {
    setFilter({
      verified: "",
      tag_type: "",
      tag_name: "",
    });
    setSearch("");
    setReloadTable(true);
  };
  const selectedTypes = (type, selected) => {
    console.log(type, selected);
    if (type === "verified") {
      setFilter({ ...filter, verified: selected.id });
    }
    if (type === "tag_type") {
      setFilter({ ...filter, tag_type: selected.id });
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <Breadcrumbs />
        <button
          className="add-service-centre-button d-flex align-items-center justify-content-between mb-2 px-3"
          onClick={() => navigate("/add-tag")}
        >
          <ReactSVG
            src={PlusLargeIcon}
            height="18px"
            width="18px"
            className="mr-2"
          />{" "}
          Add Tag
        </button>
      </div>
      <Card className="p-3">
        <div>
          <HeadingTwo
            title={`Tags List (${totalPages})`}
            type="dashboard"
            showHeader
            pointerOnHover
          />
        </div>
        <div>
          {" "}
          <div className="d-flex w-100">
            <Search
              onChange={(e) => handleSearchChange(e)}
              value={search}
              placeholder={"Search"}
            />
            <button
              className="black-filled-button ms-2"
              onClick={() => console.log("Apply")}
            >
              Apply
            </button>
            <div className="position-relative" ref={filterDropdownRef}>
              <button
                className="px-2 border-1 rounded filter-option p-1  ms-2 position-relative"
                onClick={handleFilterClick}
              >
                <ReactSVG src={FilterIcon} />{" "}
              </button>
              <FilterDropdown
                isVisible={filterDropdown}
                options={filterOptions}
                selectedValues={filter}
                selectedTypes={selectedTypes}
                apply={() => handleSearchClicked()}
                clearAll={() => clearAll()}
                buttonVisible={false}
              />
            </div>
          </div>
          <div>
            <Table
              columns={columns}
              api={apiHandler.getTagList}
              reload={reloadTable}
              setReload={setReloadTable}
              setTotalRecords={setTotalPages}
              filter={filter}
            />
            <DeleteConfirmationModalComponent
              show={showDeleteModal}
              type="delete"
              handleClose={() => setShowDeleteModal(false)}
              setDeleteConfirm={onDeleteInner}
            />
          </div>
        </div>
      </Card>
    </>
  );
}
