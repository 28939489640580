import { ReactSVG } from "react-svg";
import FilterIcon from "../../../../assets/icons/filter2.svg";
import SearchIcon from "../../../../assets/icons/search.svg";
import { useNavigate } from "react-router-dom";
import FilterDropdown from "../../../../components/FilterDropDown";
import { useState, useEffect, useRef } from "react";
import CarModelBtn from "../../../../components/CarModelBtn";
import { carType } from "../../../../utility/constants";

export default function CarModelSearchBar({
  onChange,
  value,
  options,
  selectedTypes,
  apply,
  tableHeading,
  searchPlaceholder,
  selectedValues,
  clearAll,
  hideSearch
}) {
  const navigate = useNavigate();
  const [isFilterDropdownVisible, setIsFilterDropdownVisible] = useState(false);
  const filterDropdownRef = useRef(null);

  const handleFilterClick = () => {
    setIsFilterDropdownVisible(!isFilterDropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (
      filterDropdownRef.current &&
      !filterDropdownRef.current.contains(event.target)
    ) {
      setIsFilterDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="d-flex align-items-center ">
        <div className="table-heading fw-bold white-space-nowrap mr-2">
          {tableHeading}
        </div>
        {!hideSearch && (
          <>
            <div class="my-1 d-flex align-items-center rounded px-1 py-0 search-container">
              <div className="search-icon px-2">
                <ReactSVG src={SearchIcon} />
              </div>
              <input
                type="text"
                className="form-control form-control-sm border-0 shadow-none px-0 search-input"
                id="searchBrand"
                placeholder={searchPlaceholder}
                value={value}
                onChange={(e) => onChange(e.target.value)}
              />
            </div>
            <button className="black-filled-button ms-2" onClick={apply}>
              Apply
            </button>
          </>
        )}
        <div className="position-relative" ref={filterDropdownRef}>
          <button
            className="px-2 border-1 rounded filter-option p-1  ms-2 position-relative"
            onClick={handleFilterClick}
          >
            <ReactSVG src={FilterIcon} />{" "}
          </button>
          <FilterDropdown
            isVisible={isFilterDropdownVisible}
            options={options}
            selectedValues={selectedValues}
            selectedTypes={selectedTypes}
            apply={apply}
            clearAll={clearAll}
          />
        </div>
      </div>
    </>
  );
}
