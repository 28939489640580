import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../../request";

export const getSidebarList = (token, payload) => {
  return axios.post(`${BASE_URL_AUTOX}sidebar/list`, payload, getConfig(token));
};

export const getSideBarlistName = (token, payload) => {
  return axios.post(
    `${BASE_URL_AUTOX}sidebar/lists`,
    payload,
    getConfig(token)
  );
};
export const deleteSideBar = (token, id) => {
  return axios.delete(
    `${BASE_URL_AUTOX}sidebar/delete/${id}`,
    getConfig(token)
  );
};

export const addSideBar = (token, payload) => {
  return axios.post(`${BASE_URL_AUTOX}sidebar/add`, payload, getConfig(token));
};

export const updateSidebar = (token, id, payload) => {
  return axios.put(
    `${BASE_URL_AUTOX}sidebar/update/${id}`,
    payload,
    getConfig(token)
  );
};

export const getSideBarDetails = (token, id) => {
  return axios.get(`${BASE_URL_AUTOX}sidebar/${id}`, getConfig(token));
};

export const getWidgetFromSidebar = (token, payload) => {
  return axios.post(
    `${BASE_URL_AUTOX}widgets/lists`,
    payload,
    getConfig(token)
  );
};

export const updateWidgets = (token, payload) => {
  return axios.post(
    `${BASE_URL_AUTOX}widgets/update`,
    payload,
    getConfig(token)
  );
};

export default {
  getSidebarList,
  deleteSideBar,
  getSideBarlistName,
  addSideBar,
  updateSidebar,
  getSideBarDetails,
  getWidgetFromSidebar,
  updateWidgets,
};
