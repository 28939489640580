import { useEffect } from "react";

const useAuthTimeout = (logout) => {
  useEffect(() => {
    const expirationTime = localStorage.getItem("token_expiration_time");
    if (!expirationTime) return;

    const currentTime = Math.floor(Date.now() / 1000); // Current time in Unix format
    const bufferTime = 4; // Buffer before actual expiration
    const remainingTime = expirationTime - currentTime - bufferTime; // Time left in seconds

    if (remainingTime <= 0) {
      logout(); // Logout immediately if already expired
    } else {
      const timeout = setTimeout(logout, remainingTime * 1000); // Convert to milliseconds
      return () => clearTimeout(timeout); // Cleanup on unmount
    }
  }, [logout]);
};

export default useAuthTimeout;
