import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { useFormik } from "formik";
import { popularValidation } from "./schemaValidation";
import APIHandler from "./apiHandler";
import { getToken } from "../../../utility/constants";
import { notifyError, notifySuccess } from "../../../utility/common";
import FormFieldErrorMsg from "../../../components/FormFieldErrorMsg";
import { getAllBrands, getModelByBrandId } from "../../../request";
import FormSelect from "../../../components/FormSelect";
import { ReactSVG } from "react-svg";
import eye from "../../../assets/icons/bootstrap-icons/eye.svg";
import { useNavigate } from "react-router-dom";
export default function AddPopularCar({ onSuccess }) {
  const token = getToken();

  //set initial value
  const [brandList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [brandId, setBrandId] = useState();
  const navigate = useNavigate();
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    values,
    errors,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: {
      model_id: -1,
      brand_id: -1,
    },
    validationSchema: popularValidation,
    onSubmit: (values, action) => {
      APIHandler.addPopularCar(values, token)
        .then((result) => {
          notifySuccess("Added Successfully !!");
          action.resetForm();
          onSuccess();
        })
        .catch((error) => {
          console.log(error);
          notifyError(error?.response?.data?.message);
        })
        .finally(() => {
          action.resetForm();
          action.setSubmitting(false);
        });
    },
  });

  //load inital Data
  useEffect(() => {
    loadBrand();
  }, []);

  //Load Brands
  const loadBrand = async () => {
    try {
      const res = await getAllBrands();
      const response = res.data.result;
      setBrandList(response);
    } catch (error) {
      console.log(error);
    }
  };

  //handle brand dropdown change event
  const handleBrandChange = (brand_id) => {
    // setBrandId(e.target.value);
    getModels(brand_id);
  };

  //Get all the models of a brand
  const getModels = async (brand_id) => {
    try {
      await getModelByBrandId({ brand_id }, token)
        .then((result) => {
          setModelList(result.data.result);
        })
        .catch((error) => {
          notifyError(error.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const redirectPopularCars = () => {
    window.open("https://www.autox.com/top-selling-cars-in-india/", "_blank");
  };

  //Render the Add Popular Form
  return (
    <Card className="ps-2 pe-2">
      <Form onSubmit={handleSubmit}>
        <Card.Body>
          <Card.Text>
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="d-flex align-items-center mb-3">
                  <div className="d-flex align-items-center justify-content-between w-100 ">
                    <div className="table-heading">Add Popular Cars </div>
                    <Form.Group className="d-flex gap-3">
                      <div>
                        <ReactSVG
                          src={eye}
                          onClick={redirectPopularCars}
                          className="eye-bg"
                        />
                      </div>
                      <button
                        type="submit"
                        className="add-button d-flex align-items-center px-3 "
                      >
                        Add New
                      </button>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </Card.Text>
          <Card.Text>
            <FormSelect
              controlId={"brandName"}
              label={"Select Brand"}
              name={"brand_id"}
              defaultName={"Select Brand"}
              setFieldValue={setFieldValue}
              items={brandList}
              value={values.brand_id}
              itemName={"brand_name"}
              touched={touched}
              showDefault={true}
              errors={errors}
              getValues={handleBrandChange}
              isRequired={true}
            />
            <FormSelect
              controlId={"modelName"}
              label={"Select Model"}
              name={"model_id"}
              defaultName={"Select Model"}
              setFieldValue={setFieldValue}
              value={values.model_id}
              showDefault={true}
              items={modelList}
              itemName={"model_name"}
              touched={touched}
              errors={errors}
              isRequired={true}
            />
          </Card.Text>
        </Card.Body>
      </Form>
    </Card>
  );
}
