import axios from "axios";
// export const API_URL = process.env.REACT_APP_STAGING_API_URL;
// export const BASE_URL = process.env.REACT_APP_STAGING_API_URL;
// import {io} from "socket.io-client"
// export const LOGIN = "login";
// export const REGISTER = "register";
// export const UPDATE_PROFILE = "edit";
// export const FORGOT_PASSWORD = "password/forgot-password";
// export const VERIFY_OTP = "password/verify-token";
// export const CHANGE_PASSWORD = "change-passord";
// export const SET_PASSWORD = "password/reset-password";
// export const CREATE_JOB_CATEGORY = "add-job-category";
// export const ADD_PAYROLLER = "add-payroller";
// export const EDIT_JOB_CATEGORY = "edit-job-category";
// export const GET_JOB_CATEGORY = `${API_URL}list_job_category`;
// export const DELETE_JOB_CATEGORY = "delete-job-category";
// export const CREATE_FAQ = "create-faq";
// export const CREATE_STUDENT_FAQ = "add-faq";
// export const CREATE_EMPLOYER_FAQ = "create-faq";
// export const EDIT_STUDENT_FAQ = "create-faq";
// export const EDIT_EMPLOYER_FAQ = "create-faq";
// export const DELETE_EMPLOYER_FAQ = "delete-faq";
// export const DELETE_STUDENT_FAQ = "remove-faq";
// export const UPDATE_STUDENT_FAQ = "update-faq";
// export const GET_EMPLOYER_LIST = `${API_URL}list-employers`;
// export const GET_EMPLOYER_HELP = `${API_URL}list-help-question`;
// export const ADD_EMPLOYER_HELP = "add-help-question";
// export const UPDATE_EMPLOYER_HELP = "update-help-question";
// export const GET_CERTIFICATION_LIST = `${API_URL}list-certification`;
// export const GET_STUDENT_FAQ = `${API_URL}list-faqs`;
// export const CREATE_CERTIFICATE = "create-certification";
// export const UPDATE_CERTIFICATE = "update-certification";
// export const DELETE_CERTIFICATE = "remove-certification";
// export const DELETE_HELP_QUESTION = "remove-help-question";
// export const ADMIN_JOB_DETAILS = "get-job";
// // export const GET_ADMIN_JOBS = `${API_URL}list-admin-job`;
// export const GET_RATES = "get-rates";
// export const UPDATE_RATES = "update-rates";
// export const GET_LOYALTY = "get-loyalty";
// export const UPDATE_LOYALTY = "update-loyalty";
// export const DASHBOARD_CHART = "linechart";

// export const GET_DASHBOARD_COUNT = "dashboard";

// export const GET_CANCELLATION = "get-cancellation-fee";
// export const UPDATE_CANCELLATION = "update-cancellation-fee";

// export const GET_PAYROLLER = "get-payroller";
// export const UPDATE_PAYROLLER = "update-payroller";

// export const GET_WEEKLY_REPORT = `${API_URL}list-weekly-report`;

// export const GET_STUDENT_LIST = `${API_URL}list-students`;

// export const UPDATE_EMPLOYER = "employer-info-update";
// export const UPDATE_STUDENT = "update-profile";
// export const GET_NOTIFICATIONS = "notifications";

// export const ADD_BUSINESS_TYPE = "add-business-type";

// export const BLOCK_EMPLOYER = "block-unblock-employer";
// export const BLOCK_STUDENT = "block-unblock-student";

// export const GET_EMPLOYER_DETAILS = "get-employer";
// export const GET_STUDENT_DETAILS = "get-student";

// export const GET_BUSINESS_TYPES = "get-business-type";
// export const UPDATE_BUSINESS_TYPES = "update-business-type";
// export const DELETE_BUSINESS_TYPES = "delete-business-type";
// export const UNASSIGN_CERTIFICATE = "unassign-certification";
// export const ASSIGN_CERTIFICATE = "assign-certification";

// export const LIST = "unknown";

// export const GET_STATE_URL = "state-list";
// export const GET_CITY_URL = "city-list";
// export const JOB_CREATION_VALUES = "job-creation-values";
// export const CREATE_JOB = "job-creation";

// export function login(payload) {
//   return axios.post(`${API_URL}${LOGIN}`, payload);
// }

// export function signUp(payload) {
//   return axios.post(`${API_URL}${REGISTER}`, payload);
// }

// export function updateProfile(payload) {
//   return axios.post(`${API_URL}${UPDATE_PROFILE}`, payload);
// }

// export function forgotPassword(payload) {
//   return axios.post(`${BASE_URL}${FORGOT_PASSWORD}`, payload);
// }

// export function verifyOtp(payload) {
//   return axios.post(`${BASE_URL}${VERIFY_OTP}`, payload);
// }

// export function changePassword(payload) {
//   return axios.post(`${API_URL}${CHANGE_PASSWORD}`, payload);
// }

// export function setPassword(payload) {
//   return axios.post(`${BASE_URL}${SET_PASSWORD}`, payload);
// }

// export function getUserList() {
//   return axios.get(`${API_URL}${LIST}`);
// }

// export function getCities(state) {
//   return axios.get(`${API_URL}${GET_CITY_URL}?state=${state}`);
// }

// export function getStates() {
//   return axios.get(`${API_URL}${GET_STATE_URL}`);
// }

// export function createJobCategory(payload, token) {
//   return axios.post(`${API_URL}${CREATE_JOB_CATEGORY}`, payload, {
//     headers: { Authorization: token },
//   });
// }

// export function editJobCategoryApi(payload, token) {
//   return axios.put(`${API_URL}${EDIT_JOB_CATEGORY}`, payload, {
//     headers: { Authorization: token },
//   });
// }

// export function addPayroller(payload) {
//   return axios.post(`${API_URL}${ADD_PAYROLLER}`, payload);
// }

// export function createStudentFaq(payload, token) {
//   return axios.post(`${API_URL}${CREATE_STUDENT_FAQ}`, payload, {
//     headers: { Authorization: token, "Content-Type": "multipart/form-data" },
//   });
// }

// export function deleteFaq(id, token) {
//   return axios.delete(`${API_URL}${DELETE_STUDENT_FAQ}/${id}`, {
//     headers: { Authorization: token },
//   });
// }

// export function getJobCategoryList(token) {
//   return axios.get(`${API_URL}${GET_JOB_CATEGORY}`, {
//     headers: { Authorization: token },
//   });
// }

// export function getDashboardChartData(token, days) {
//   return axios.get(`${API_URL}${DASHBOARD_CHART}/${days}`, {
//     headers: { Authorization: token },
//   });
// }

// export function deleteJobCategory(id, token) {
//   let payload = {
//     id: id,
//   };
//   return axios.delete(`${API_URL}${DELETE_JOB_CATEGORY}/${id}`, {
//     data: payload,
//     headers: { Authorization: token },
//   });
// }

// export function deleteHelpQuestion(id, token) {
//   let payload = {
//     // id: id,./
//   };
//   return axios.delete(`${API_URL}${DELETE_HELP_QUESTION}/${id}`, {
//     data: payload,
//     headers: { Authorization: token },
//   });
// }

// export function getEmployerList(payload, token) {
//   return axios.post(`${GET_EMPLOYER_LIST}`, payload, {
//     headers: { Authorization: token },
//   });
// }

// export function updateEmployerHelp(payload, token) {
//   return axios.put(`${API_URL}${UPDATE_EMPLOYER_HELP}`, payload, {
//     headers: { Authorization: token, "Content-Type": "multipart/form-data" },
//   });
// }

// export function addEmployerHelp(payload, token) {
//   return axios.post(`${API_URL}${ADD_EMPLOYER_HELP}`, payload, {
//     headers: { Authorization: token, "Content-Type": "multipart/form-data" },
//   });
// }

// export function addCertificate(payload, token) {
//   return axios.post(`${API_URL}${CREATE_CERTIFICATE}`, payload, {
//     headers: { Authorization: token, "Content-Type": "multipart/form-data" },
//   });
// }

// export function updateCertificate(payload, token) {
//   return axios.put(`${API_URL}${UPDATE_CERTIFICATE}`, payload, {
//     headers: { Authorization: token, "Content-Type": "multipart/form-data" },
//   });
// }

// export function updateStudentFaq(payload, token) {
//   return axios.put(`${API_URL}${UPDATE_STUDENT_FAQ}`, payload, {
//     headers: { Authorization: token, "Content-Type": "multipart/form-data" },
//   });
// }

// export function deleteCertification(id, token) {
//   return axios.delete(`${API_URL}${DELETE_CERTIFICATE}/${id}`, {
//     headers: { Authorization: token },
//   });
// }

// export function addBusinessTypeApi(payload, token) {
//   return axios.post(`${API_URL}${ADD_BUSINESS_TYPE}`, payload, {
//     headers: { Authorization: token },
//   });
// }

// export function getAdminJobDetails(token, id) {
//   return axios.get(`${API_URL}${ADMIN_JOB_DETAILS}/${id}`, {
//     headers: { Authorization: token },
//   });
// }

// export function getAdminNotifications(token) {
//   return axios.get(`${API_URL}${GET_NOTIFICATIONS}`, {
//     headers: { Authorization: token },
//   });
// }

// export function getRates(token) {
//   return axios.get(`${API_URL}${GET_RATES}`, {
//     headers: { Authorization: token },
//   });
// }

// export function updateRates(payload, token) {
//   return axios.put(`${API_URL}${UPDATE_RATES}`, payload, {
//     headers: { Authorization: token },
//   });
// }

// export function getLoyalty(token) {
//   return axios.get(`${API_URL}${GET_LOYALTY}`, {
//     headers: { Authorization: token },
//   });
// }

// export function updateLoyalty(payload, token) {
//   return axios.put(`${API_URL}${UPDATE_LOYALTY}`, payload, {
//     headers: { Authorization: token },
//   });
// }

// export function getCancellation(token) {
//   return axios.get(`${API_URL}${GET_CANCELLATION}`, {
//     headers: { Authorization: token },
//   });
// }

// export function updateCancellation(payload, token) {
//   return axios.put(`${API_URL}${UPDATE_CANCELLATION}`, payload, {
//     headers: { Authorization: token },
//   });
// }

// export function getPayroller(token) {
//   return axios.get(`${API_URL}${GET_PAYROLLER}`, {
//     headers: { Authorization: token },
//   });
// }

// export function updatePayroller(payload, token) {
//   return axios.put(`${API_URL}${UPDATE_PAYROLLER}`, payload, {
//     headers: { Authorization: token },
//   });
// }

// export function getDashboardCount(token) {
//   return axios.get(`${API_URL}${GET_DASHBOARD_COUNT}`, {
//     headers: { Authorization: token },
//   });
// }

// export function blockEmployer(payload, token) {
//   return axios.post(`${API_URL}${BLOCK_EMPLOYER}`, payload, {
//     headers: { Authorization: token },
//   });
// }

// export function blockStudent(payload, token) {
//   return axios.post(`${API_URL}${BLOCK_STUDENT}`, payload, {
//     headers: { Authorization: token },
//   });
// }

// export function getEmployerDetails(token, id) {
//   return axios.get(`${API_URL}${GET_EMPLOYER_DETAILS}/${id}`, {
//     headers: { Authorization: token },
//   });
// }

// export function getStudentDetails(token, id) {
//   return axios.get(`${API_URL}${GET_STUDENT_DETAILS}/${id}`, {
//     headers: { Authorization: token },
//   });
// }

// export function getBusinessTypes(token) {
//   return axios.get(`${API_URL}${GET_BUSINESS_TYPES}`, {
//     headers: { Authorization: token },
//   });
// }

// export function updateBusinessTypes(token, payload) {
//   return axios.put(`${API_URL}${UPDATE_BUSINESS_TYPES}`, payload, {
//     headers: { Authorization: token },
//   });
// }

// export function deleteBusinessTypes(id, token) {
//   let payload = {
//     // id: id,
//   };
//   return axios.delete(`${API_URL}${DELETE_BUSINESS_TYPES}/${id}`, {
//     data: payload,
//     headers: { Authorization: token },
//   });
// }

// export function assignCertificate(payload, token) {
//   return axios.patch(`${API_URL}${ASSIGN_CERTIFICATE}`, payload, {
//     headers: { Authorization: token },
//   });
// }

// export function unassignCertificate(payload, token) {
//   return axios.patch(`${API_URL}${UNASSIGN_CERTIFICATE}`, payload, {
//     headers: { Authorization: token },
//   });
// }
export const API_URL = process.env.REACT_APP_STAGING_API_URL;
export const BASE_URL = process.env.REACT_APP_STAGING_API_URL;

// export const LOGIN = "login";
export const REGISTER = "register";
export const UPDATE_PROFILE = "edit";
// export const FORGOT_PASSWORD = "password/forgot-password";
// export const VERIFY_OTP = "password/verify-token";
export const CHANGE_PASSWORD = "change-passord";

export const GET_FAQ_LIST = "car/faq/list";
export const GET_BIKE_FAQ_LIST = "new-bike/faq/list";
export const SET_PASSWORD = "password/reset-password";
export const CREATE_JOB_CATEGORY = "add-job-category";
export const ADD_PAYROLLER = "add-payroller";
export const EDIT_JOB_CATEGORY = "edit-job-category";
export const GET_JOB_CATEGORY = `${API_URL}list_job_category`;
export const DELETE_JOB_CATEGORY = "delete-job-category";
export const CREATE_FAQ = "create-faq";
export const CREATE_STUDENT_FAQ = "add-faq";
export const CREATE_EMPLOYER_FAQ = "create-faq";
export const EDIT_STUDENT_FAQ = "create-faq";
export const EDIT_EMPLOYER_FAQ = "create-faq";
export const DELETE_EMPLOYER_FAQ = "delete-faq";
export const DELETE_STUDENT_FAQ = "remove-faq";
export const UPDATE_STUDENT_FAQ = "update-faq";
export const GET_EMPLOYER_LIST = `${API_URL}list-employers`;
export const GET_EMPLOYER_HELP = `${API_URL}list-help-question`;
export const ADD_EMPLOYER_HELP = "add-help-question";
export const UPDATE_EMPLOYER_HELP = "update-help-question";
export const GET_CERTIFICATION_LIST = `${API_URL}list-certification`;
export const GET_STUDENT_FAQ = `${API_URL}list-faqs`;
export const CREATE_CERTIFICATE = "create-certification";
export const UPDATE_CERTIFICATE = "update-certification";
export const DELETE_CERTIFICATE = "remove-certification";
export const DELETE_HELP_QUESTION = "remove-help-question";
export const ADMIN_JOB_DETAILS = "get-job";
// export const GET_ADMIN_JOBS = `${API_URL}list-admin-job`;
export const GET_RATES = "get-rates";
export const UPDATE_RATES = "update-rates";
export const GET_LOYALTY = "get-loyalty";
export const UPDATE_LOYALTY = "update-loyalty";
export const DASHBOARD_CHART = "linechart";

export const GET_DASHBOARD_COUNT = "dashboard";

export const GET_CANCELLATION = "get-cancellation-fee";
export const UPDATE_CANCELLATION = "update-cancellation-fee";

export const GET_PAYROLLER = "get-payroller";
export const UPDATE_PAYROLLER = "update-payroller";

export const GET_WEEKLY_REPORT = `${API_URL}list-weekly-report`;

export const GET_STUDENT_LIST = `${API_URL}list-students`;

export const UPDATE_EMPLOYER = "employer-info-update";
export const UPDATE_STUDENT = "update-profile";
export const GET_NOTIFICATIONS = "notifications";

export const ADD_BUSINESS_TYPE = "add-business-type";

export const BLOCK_EMPLOYER = "block-unblock-employer";
export const BLOCK_STUDENT = "block-unblock-student";

export const GET_EMPLOYER_DETAILS = "get-employer";
export const GET_STUDENT_DETAILS = "get-student";

export const GET_BUSINESS_TYPES = "get-business-type";
export const UPDATE_BUSINESS_TYPES = "update-business-type";
export const DELETE_BUSINESS_TYPES = "delete-business-type";
export const UNASSIGN_CERTIFICATE = "unassign-certification";
export const ASSIGN_CERTIFICATE = "assign-certification";

export const LIST = "unknown";

export const GET_STATE_URL = "state-list";
export const GET_CITY_URL = "city-list";
export const JOB_CREATION_VALUES = "job-creation-values";
export const CREATE_JOB = "job-creation";

// export function login(payload) {
//   return axios.post(`${API_URL}${LOGIN}`, payload);
// }

export function signUp(payload) {
  return axios.post(`${API_URL}${REGISTER}`, payload);
}

export function updateProfile(payload) {
  return axios.post(`${API_URL}${UPDATE_PROFILE}`, payload);
}

// export function forgotPassword(payload) {
//   return axios.post(`${BASE_URL}${FORGOT_PASSWORD}`, payload);
// }

export function verifyOtp(payload) {
  return axios.post(`${BASE_URL}${VERIFY_OTP}`, payload);
}

export function changePassword(payload) {
  return axios.post(`${API_URL}${CHANGE_PASSWORD}`, payload);
}

export function setPassword(payload) {
  return axios.post(`${BASE_URL}${SET_PASSWORD}`, payload);
}

export function getUserList() {
  return axios.get(`${API_URL}${LIST}`);
}

export function getCities(state) {
  return axios.get(`${API_URL}${GET_CITY_URL}?state=${state}`);
}

// export function getStates() {
//   return axios.get(`${API_URL}${GET_STATE_URL}`);
// }

export function createJobCategory(payload, token) {
  return axios.post(`${API_URL}${CREATE_JOB_CATEGORY}`, payload, {
    headers: { Authorization: token },
  });
}

export function editJobCategoryApi(payload, token) {
  return axios.put(`${API_URL}${EDIT_JOB_CATEGORY}`, payload, {
    headers: { Authorization: token },
  });
}

export function addPayroller(payload) {
  return axios.post(`${API_URL}${ADD_PAYROLLER}`, payload);
}

export function createStudentFaq(payload, token) {
  return axios.post(`${API_URL}${CREATE_STUDENT_FAQ}`, payload, {
    headers: { Authorization: token, "Content-Type": "multipart/form-data" },
  });
}

export function deleteFaq(id, token) {
  return axios.delete(`${API_URL}${DELETE_STUDENT_FAQ}/${id}`, {
    headers: { Authorization: token },
  });
}

export function getJobCategoryList(token) {
  return axios.get(`${API_URL}${GET_JOB_CATEGORY}`, {
    headers: { Authorization: token },
  });
}

export function getDashboardChartData(token, days) {
  return axios.get(`${API_URL}${DASHBOARD_CHART}/${days}`, {
    headers: { Authorization: token },
  });
}

export function deleteJobCategory(id, token) {
  let payload = {
    id: id,
  };
  return axios.delete(`${API_URL}${DELETE_JOB_CATEGORY}/${id}`, {
    data: payload,
    headers: { Authorization: token },
  });
}

export function deleteHelpQuestion(id, token) {
  let payload = {
    // id: id,./
  };
  return axios.delete(`${API_URL}${DELETE_HELP_QUESTION}/${id}`, {
    data: payload,
    headers: { Authorization: token },
  });
}

export function getEmployerList(payload, token) {
  return axios.post(`${GET_EMPLOYER_LIST}`, payload, {
    headers: { Authorization: token },
  });
}

export function updateEmployerHelp(payload, token) {
  return axios.put(`${API_URL}${UPDATE_EMPLOYER_HELP}`, payload, {
    headers: { Authorization: token, "Content-Type": "multipart/form-data" },
  });
}

export function addEmployerHelp(payload, token) {
  return axios.post(`${API_URL}${ADD_EMPLOYER_HELP}`, payload, {
    headers: { Authorization: token, "Content-Type": "multipart/form-data" },
  });
}

export function addCertificate(payload, token) {
  return axios.post(`${API_URL}${CREATE_CERTIFICATE}`, payload, {
    headers: { Authorization: token, "Content-Type": "multipart/form-data" },
  });
}

export function updateCertificate(payload, token) {
  return axios.put(`${API_URL}${UPDATE_CERTIFICATE}`, payload, {
    headers: { Authorization: token, "Content-Type": "multipart/form-data" },
  });
}

export function updateStudentFaq(payload, token) {
  return axios.put(`${API_URL}${UPDATE_STUDENT_FAQ}`, payload, {
    headers: { Authorization: token, "Content-Type": "multipart/form-data" },
  });
}

export function deleteCertification(id, token) {
  return axios.delete(`${API_URL}${DELETE_CERTIFICATE}/${id}`, {
    headers: { Authorization: token },
  });
}

export function addBusinessTypeApi(payload, token) {
  return axios.post(`${API_URL}${ADD_BUSINESS_TYPE}`, payload, {
    headers: { Authorization: token },
  });
}

export function getAdminJobDetails(token, id) {
  return axios.get(`${API_URL}${ADMIN_JOB_DETAILS}/${id}`, {
    headers: { Authorization: token },
  });
}

export function getAdminNotifications(token) {
  return axios.get(`${API_URL}${GET_NOTIFICATIONS}`, {
    headers: { Authorization: token },
  });
}

export function getRates(token) {
  return axios.get(`${API_URL}${GET_RATES}`, {
    headers: { Authorization: token },
  });
}

export function updateRates(payload, token) {
  return axios.put(`${API_URL}${UPDATE_RATES}`, payload, {
    headers: { Authorization: token },
  });
}

export function getLoyalty(token) {
  return axios.get(`${API_URL}${GET_LOYALTY}`, {
    headers: { Authorization: token },
  });
}

export function updateLoyalty(payload, token) {
  return axios.put(`${API_URL}${UPDATE_LOYALTY}`, payload, {
    headers: { Authorization: token },
  });
}

export function getCancellation(token) {
  return axios.get(`${API_URL}${GET_CANCELLATION}`, {
    headers: { Authorization: token },
  });
}
export function getISTDate() {
  const now = new Date();
  const utcOffset = 5.5 * 60; // IST is UTC +5:30
  const localDate = new Date(now.getTime() + utcOffset * 60 * 1000); // Adjust UTC time to IST
  return localDate.toISOString().slice(0, 19); // Format as 'YYYY-MM-DDTHH:mm:ss'
}

export function convertUSTtoIST(date) {
  const utcOffset = 5.5 * 60; // IST is UTC +5:30
  const localDate = new Date(date.getTime() + utcOffset * 60 * 1000); // Adjust UTC time to IST
  return localDate.toISOString().slice(0, 19);
}
export function updateCancellation(payload, token) {
  return axios.put(`${API_URL}${UPDATE_CANCELLATION}`, payload, {
    headers: { Authorization: token },
  });
}

export function getPayroller(token) {
  return axios.get(`${API_URL}${GET_PAYROLLER}`, {
    headers: { Authorization: token },
  });
}

export function updatePayroller(payload, token) {
  return axios.put(`${API_URL}${UPDATE_PAYROLLER}`, payload, {
    headers: { Authorization: token },
  });
}

export function getDashboardCount(token) {
  return axios.get(`${API_URL}${GET_DASHBOARD_COUNT}`, {
    headers: { Authorization: token },
  });
}

export function blockEmployer(payload, token) {
  return axios.post(`${API_URL}${BLOCK_EMPLOYER}`, payload, {
    headers: { Authorization: token },
  });
}

export function blockStudent(payload, token) {
  return axios.post(`${API_URL}${BLOCK_STUDENT}`, payload, {
    headers: { Authorization: token },
  });
}

export function getEmployerDetails(token, id) {
  return axios.get(`${API_URL}${GET_EMPLOYER_DETAILS}/${id}`, {
    headers: { Authorization: token },
  });
}

export function getStudentDetails(token, id) {
  return axios.get(`${API_URL}${GET_STUDENT_DETAILS}/${id}`, {
    headers: { Authorization: token },
  });
}

export function getBusinessTypes(token) {
  return axios.get(`${API_URL}${GET_BUSINESS_TYPES}`, {
    headers: { Authorization: token },
  });
}

export function updateBusinessTypes(token, payload) {
  return axios.put(`${API_URL}${UPDATE_BUSINESS_TYPES}`, payload, {
    headers: { Authorization: token },
  });
}

export function deleteBusinessTypes(id, token) {
  let payload = {
    // id: id,
  };
  return axios.delete(`${API_URL}${DELETE_BUSINESS_TYPES}/${id}`, {
    data: payload,
    headers: { Authorization: token },
  });
}

export function assignCertificate(payload, token) {
  return axios.patch(`${API_URL}${ASSIGN_CERTIFICATE}`, payload, {
    headers: { Authorization: token },
  });
}

export function unassignCertificate(payload, token) {
  return axios.patch(`${API_URL}${UNASSIGN_CERTIFICATE}`, payload, {
    headers: { Authorization: token },
  });
}

//--------> AutoX APIs
// export const API_URL = process.env.REACT_APP_STAGING_API_URL;

// export const BASE_URL_AUTOX = "http://localhost:1080/"; //process.env.REACT_APP_STAGING_API_URL;

export const BASE_URL_AUTOX = "https://api.autox.com/";
// export const BASE_URL_AUTOX = "http://64.227.166.183:1080/";
// export const BASE_URL_AUTOX = "https://api.autoxmag.com/";
// const authorization_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwidXNlclR5cGUiOiJhZG1pbiIsImlhdCI6MTcwNzI5ODc0MiwiZXhwIjoxNzA4MTYyNzQyfQ.0IL-ttRrx4K0Ml5cA7ukNefekux13TTAeLgXzKMLaRo';

// export const BASE_URL_AUTOX =
//   "https://puzu5ahtqh.execute-api.ap-south-1.amazonaws.com/production/";

// export const BASE_URL_AUTOX =
//   "https://9lovcbkzag.execute-api.ap-south-1.amazonaws.com/production/";

export const GET_BRAND_LIST = "car/brand/filtered/list";
export const GET_ALL_BRAND_LIST = "car/brand/all/list";
export const GET_ALL_BIKE_BRAND_LIST = "new-bike/brand/all/list";
export const GET_BRAND_URL = "car/brand/view/";
export const ADD_BRAND = "car/brand/add";
export const DELETE_BRAND = "car/brand/delete/";
export const GET_BRAND_BY_ID = "car/brand/";
export const UPDATE_CAR_BRAND = "car/brand/update/";
export const BULK_UPDATE_BRAND_ORDERING = "car/brand/ordering/update";

export const GET_MODEL_LIST = "car/model/filtered/list/";
export const GET_BIKE_MODEL_LIST = "new-bike/model/filtered/list/";
export const GET_BIKE_MODEL_EXPORT = "new-bike/model/export/model";
export const GET_SIMPLE_MODEL_LIST = "car/model/list";
export const CREATE_MODEL = "car/model/add";
export const CREATE_BIKE_MODEL = "new-bike/model/add";
export const UPDATE_CAR_MODEL = "car/model/update/";
export const UPDATE_BIKE_MODEL = "new-bike/model/update/";
export const GET_MODEL_BY_ID = "car/model/";
export const GET_BIKE_MODEL_BY_ID = "new-bike/model/";
export const GET_MODEL_BY_BRAND_ID = "car/model/brand-specific/";
export const GET_MODEL_BY_BODY_TYPE_ID_BRAND_ID =
  "car/model/body-type-brand-specific/";
export const DELETE_BIKE_MODEL = "new-bike/model/delete/";
export const ADD_POPULAR_CAR = "car/model/popular-position/add";
export const DELETE_CAR_MODEL = "car/model/delete/";
export const GET_CAR_DRIVING_SCHOOL = "car/driving-school/list";
export const GET_CAR_DRIVING_SCHOOL_BY_ID = "car/driving-school/";
export const ADD_CAR_DRIVING_SCHOOL = "car/driving-school/add";
export const UPDATE_CAR_DRIVING_SCHOOL = "car/driving-school/update/";
export const DELETE_CAR_DRIVING_SCHOOL = "car/driving-school/delete/";

export const GET_MARUTI_SUZUKI_CAR_DRIVING_SCHOOL =
  "car/maruti-suzuki-driving-school/filtered/list";
export const GET_MARUTI_SUZUKI_CAR_DRIVING_SCHOOL_BY_ID =
  "car/maruti-suzuki-driving-school/";
export const ADD_MARUTI_SUZUKI_CAR_DRIVING_SCHOOL =
  "car/maruti-suzuki-driving-school/add";
export const UPDATE_MARUTI_SUZUKI_CAR_DRIVING_SCHOOL =
  "car/maruti-suzuki-driving-school/update/";
export const DELETE_MARUTI_SUZUKI_CAR_DRIVING_SCHOOL =
  "car/maruti-suzuki-driving-school/delete/";

export const GET_CAR_SERVICE_CENTRE = "car/service-centre/filtered/list";
export const GET_CAR_SERVICE_CENTRE_BY_ID = "car/service-centre/";
export const ADD_CAR_SERVICE_CENTRE = "car/service-centre/add";
export const UPDATE_CAR_SERVICE_CENTRE = "car/service-centre/update/";
export const DELETE_CAR_SERVICE_CENTRE = "car/service-centre/delete/";

export const GET_CAR_CITIES = "car/city/filtered/list";

export const GET_BIKE_CITIES = "new-bike/city/filtered/list";
export const GET_CAR_CITY_BY_ID = "car/city/";
export const GET_BIKE_CITY_BY_ID = "new-bike/city/";
export const ADD_CAR_CITY = "car/city/add";
export const ADD_BIKE_CITY = "new-bike/city/add";
export const UPDATE_CAR_CITY = "car/city/update/";
export const UPDATE_BIKE_CITY = "new-bike/city/update/";
export const DELETE_CAR_CITY = "car/city/delete/";
export const DELETE_BIKE_CITY = "new-bike/city/delete/";

export const GET_ALL_CITIES = "car/city/all/list";
export const GET_ALL_BIKE_CITIES = "new-bike/city/all/list";

export const GET_CAR_CITY_PRICE = "car/city-price/filtered/list";
export const GET_BIKE_CITY_PRICE = "new-bike/city-price/filtered/list";
export const GET_CAR_CITY_PRICE_BY_ID = "car/city-price/";
export const GET_BIKE_CITY_PRICE_BY_ID = "new-bike/city-price/";
export const UPDATE_CAR_CITY_PRICE = "car/city-price/update/";
export const UPDATE_BIKE_CITY_PRICE = "new-bike/city-price/update/";
export const ADD_CAR_CITY_PRICE = "car/city-price/add";
export const ADD_BIKE_CITY_PRICE = "new-bike/city-price/add";

export const DELETE_CITY_PRICE = "car/city-price/delete/";
export const DELETE_BIKE_CITY_PRICE = "new-bike/city-price/delete/";

export const GET_STATES = "state/filtered/list";
export const GET_ALL_STATES = "state/all/list";
export const GET_STATE_BY_ID = "state/";
export const ADD_STATE = "state/add";
export const UPDATE_STATE = "state/update/";
export const DELETE_STATE = "state/delete/";

export const GET_CAR_BODY_TYPE = "car/body-type/filtered/list";
export const GET_CAR_TYPE = "car/body-type/filtered/list";
export const GET_BIKE_TYPE = "new-bike/body-type/list";
export const LOGIN = "admin/login";
export const FORGOT_PASSWORD = "admin/forgot-password";
export const VERIFY_OTP = "admin/verify-otp";
export const RESET_PASSWORD = "admin/reset-password";

export const GET_CAR_COMPARISON_BY_BODY_TYPE =
  "car/comparison/body-type-specific/filtered/list/";
// "car/comparison/list";
export const ADD_CAR_COMPARISON = "car/comparison/add";
export const DELETE_CAR_COMPARISON = "car/comparison/delete/";
export const GET_CAR_COMPARISON_BODY_TYPES = "car/comparison/body-type"; // get body types present in comparison table
export const BULK_UPDATE_CAR_COMPARISON_ORDERING = "car/comparison/ordering";
export const GET_CAR_COMPARISON_URL = "car/comparison/view/";

export const GET_VERSION = "car/version/filtered/list";
export const GET_VERSION_BY_MODEL_ID = "car/version/model-specific/";

export const GET_BIKE_VERSION_BY_MODEL_ID = "new-bike/version/model-specific/";
export const ADD_CAR_DEALER = "car/dealer/add";
export const ADD_BIKE_DEALER = "new-bike/dealer/add";
export const GET_CAR_DEALER_LIST = "car/dealer/filtered/list";
export const GET_BIKE_DEALER_LIST = "new-bike/dealer/filtered/list";
export const GET_CAR_DEALER_BY_ID = "car/dealer/";
export const GET_BIKE_DEALER_BY_ID = "new-bike/dealer/";
export const EDIT_CAR_DEALER = "car/dealer/update/";
export const EDIT_BIKE_DEALER = "new-bike/dealer/update/";
export const DELETE_CAR_DEALER_BY_ID = "car/dealer/delete/";
export const DELETE_BIKE_DEALER_BY_ID = "new-bike/dealer/delete/";
export const GET_CAR_CITY_LIST = "car/city/filtered/list";
export const GET_BIKE_CITY_LIST = "new-bike/city/filtered/list";
export const GET_CAR_BODY_TYPES = "car/body-type/filtered/list";
export const GET_BIKE_BODY_TYPES = "new-bike/body-type/filtered/list";
export const ADD_CAR_BODY_TYPE = "car/body-type/add";
export const ADD_BIKE_BODY_TYPE = "new-bike/body-type/add";
export const GET_CAR_BODY_TYPE_BY_ID = "car/body-type/";
export const GET_BIKE_BODY_TYPE_BY_ID = "new-bike/body-type/";
export const UPDATE_CAR_BODY_TYPE = "car/body-type/update/";
export const UPDATE_BIKE_BODY_TYPE = "new-bike/body-type/update/";
export const DELETE_CAR_BODY_TYPE = "car/body-type/delete/";
export const DELETE_BIKE_BODY_TYPE = "new-bike/body-type/delete/";
export const GET_CAR_CITY_PRICE_LIST = "car/city-price/filtered/list";
export const GET_BIKE_CITY_PRICE_LIST = "new-bike/city-price/filtered/list";

export const GET_CAR_MODEL_LIST_BY_BRAND = "car/model/all/list";
export const GET_BIKE_MODEL_LIST_BY_BRAND = "new-bike/model/all/list";
export const GET_CAR_VERSION_LIST_BY_MODEL = "car/version/list";
export const GET_BIKE_VERSION_LIST_BY_MODEL = "new-bike/version/list-version";
export const GET_CAR_MULTIPLE_CITY_PRICE = "car/city-price/multipleprice";
export const GET_BIKE_MULTIPLE_CITY_PRICE = "new-bike/city-price/multipleprice";

//---> Brands APIs
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export function getConfig(token, content_type) {
  console.log("token", token);
  const config = {
    headers: {
      "Content-Type": content_type
        ? content_type["Content-Type"]
        : "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return config;
}

// Car brands APIs

export function getAllBrands(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_ALL_BRAND_LIST}`,
    payload,
    getConfig(token)
  );
}
export function getAllBikeBrands(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_ALL_BIKE_BRAND_LIST}`,
    payload,
    getConfig(token)
  );
}

export function getBrandList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_BRAND_LIST}`,
    payload,
    getConfig(token)
  );
}

export function getBrandNameList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_ALL_BRAND_LIST}`,
    payload,
    getConfig(token)
  );
}

export function getBikeBrandNameList(payload) {
  return axios.post(`${BASE_URL_AUTOX}${GET_ALL_BIKE_BRAND_LIST}`, payload);
}

// export function getBrandViewLink(token, id) {
//   return axios.get(`${BASE_URL_AUTOX}${GET_BRAND_URL}${id}`, getConfig(token));
// }

// export function deleteBrand(token, brand_id) {
//   return axios.delete(`${BASE_URL_AUTOX}${DELETE_BRAND}${brand_id}`, getConfig(token))
// }

// export function getBrandById(token, brand_id) {
//   return axios.get(`${BASE_URL_AUTOX}${GET_BRAND_BY_ID}${brand_id}`, getConfig(token))
// }

// export function addBrand(payload, token) {
//   return axios.post(`${BASE_URL_AUTOX}${ADD_BRAND}`, payload, getConfig(token, {"Content-Type": "multipart/form-data"}))

// }

// export function getBrandById(token, brand_id) {
//   return axios.get(`${BASE_URL_AUTOX}${GET_BRAND_BY_ID}${brand_id}`, getConfig(token))
// }

// export function updateBrand( brand_id, payload, token) {
//   return axios.put(`${BASE_URL_AUTOX}${UPDATE_CAR_BRAND}${brand_id}`, payload, getConfig(token,  {"Content-Type": "multipart/form-data"}))
// }

// Car Model APIs
export function getModelList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_MODEL_LIST}`,
    payload,
    getConfig(token)
  );
}
export function getBikeModelList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_BIKE_MODEL_LIST}`,
    payload,
    getConfig(token)
  );
}
export function getBikeModelExport(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_BIKE_MODEL_EXPORT}`,
    getConfig(token)
  );
}

export function getMagazinData(token, paylod) {
  console.log("paylod", paylod);
  return axios.post(
    `${BASE_URL_AUTOX}car/model/exportMagazineData`,
    paylod,
    getConfig(token)
  );
}

export function getBikeMagazinData(token, paylod) {
  console.log("paylod", paylod);
  return axios.post(
    `${BASE_URL_AUTOX}bike/model/exportMagazineData`,
    paylod,
    getConfig(token)
  );
}

export const downloadBrandContent = async (token) => {
  const response = await axios.post(
    `${BASE_URL_AUTOX}car/model/downloadBrandContent`,
    {},
    getConfig(token)
  );
  return response;
};

export const downloadBikeBrandContent = async (token) => {
  const response = await axios.post(
    `${BASE_URL_AUTOX}bike/model/downloadBrandContent`,
    {},
    getConfig(token)
  );
};
export function createModel(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${CREATE_MODEL}`,
    payload,
    getConfig(token, { "Content-Type": "multipart/form-data" })
  );
}
export function createBikeModel(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${CREATE_BIKE_MODEL}`,
    payload,
    getConfig(token, { "Content-Type": "multipart/form-data" })
  );
}
export function updateModel(model_id, payload) {
  console.log("payload", payload.get("brand_name"));

  return axios.put(
    `${BASE_URL_AUTOX}${UPDATE_CAR_MODEL}${model_id}`,
    payload,
    config.headers
  );
}

export function updateBikeModel(model_id, payload) {
  return axios.put(
    `${BASE_URL_AUTOX}${UPDATE_BIKE_MODEL}${model_id}`,
    payload,
    config.headers
  );
}

export function getModelById(model_id, token) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_MODEL_BY_ID}${model_id}`,
    getConfig(token)
  );
}

export function getBikeModelById(model_id, token) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_BIKE_MODEL_BY_ID}${model_id}`,
    getConfig(token)
  );
}

export function getModelsByBrandId(brand_id, token) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_MODEL_BY_BRAND_ID}${brand_id}`,
    getConfig(token)
  );
}

export function getModelsByBodyTypeBrand(token, body_type_id, brand_id) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_MODEL_BY_BODY_TYPE_ID_BRAND_ID}${body_type_id}/${brand_id}`,
    getConfig(token)
  );
}
export function getSimpleModelList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_SIMPLE_MODEL_LIST}`,
    payload,
    getConfig(token)
  );
}
//Car Brand APIs
// export function addBrand(payload, token) {
//   return axios.post(`${BASE_URL_AUTOX}${ADD_BRAND}`, payload, getConfig(token))
// }

export function addPopularCar(model_id) {
  return axios.post(`${BASE_URL_AUTOX}${ADD_POPULAR_CAR}`, model_id);
}

// export function bulkUpdateBrandOrdering(token, payload){
//   console.log("test 4567")
// return axios.put(`${BASE_URL_AUTOX}${BULK_UPDATE_BRAND_ORDERING}`, payload, getConfig(token));
// }

// export function bulkUpdateBrandOrdering(token, payload){
//   console.log("test 4567")
// return axios.put(`${BASE_URL_AUTOX}${BULK_UPDATE_BRAND_ORDERING}`, payload, getConfig(token));
// }

// car city api
// export function getCarCities() {
//   return axios.get(`${BASE_URL_AUTOX}${GET_CAR_CITIES}`, config.headers)
// }

// export function addCarCity(payload) {
//   return axios.post(`${BASE_URL_AUTOX}${ADD_CAR_CITY}`, config.headers, payload)
// }

// export function updateCarCity(payload) {
//   return axios.post(`${BASE_URL_AUTOX}${UPDATE_CAR_CITY}`, config.headers, payload)
// }

// export function deleteCarCities(city_id) {
//   return axios.post(`${BASE_URL_AUTOX}${DELETE_CAR_CITY}${city_id}`, config.headers)
// }

//auth apis

export function login(payload) {
  return axios.post(`${BASE_URL_AUTOX}${LOGIN}`, payload, {
    "Content-Type": "application/json",
  });
}

export function forgotPassword(payload) {
  return axios.post(`${BASE_URL_AUTOX}${FORGOT_PASSWORD}`, payload);
}

export function verify_otp(payload) {
  return axios.post(`${BASE_URL_AUTOX}${VERIFY_OTP}`, payload);
}

export function resetPassword(payload) {
  return axios.post(`${BASE_URL_AUTOX}${RESET_PASSWORD}`, payload);
}

//Car Driving School APIs
// export function getCarDrivingSchools(token) {
//   return axios.get(`${BASE_URL_AUTOX}${GET_CAR_DRIVING_SCHOOL}`, getConfig(token)
//   )
// }

// export function getCarDrivingSchoolById(id, token) {
//   return axios.get(`${BASE_URL_AUTOX}${GET_CAR_DRIVING_SCHOOL_BY_ID}${id}`, getConfig(token)
//   )
// }

// export function addCarDrivingSchool(payload, token) {
//   return axios.post(`${BASE_URL_AUTOX}${ADD_CAR_DRIVING_SCHOOL}`, payload, getConfig(token))
// }

// export function updateDrivingSchoolById(id, payload, token) {
//   return axios.put(`${BASE_URL_AUTOX}${UPDATE_CAR_DRIVING_SCHOOL}${id}`, payload, getConfig(token))
// }

// export function deleteCarDrivingSchoolById(id, token) {
//   return axios.delete(`${BASE_URL_AUTOX}${DELETE_CAR_DRIVING_SCHOOL}${id}`, getConfig(token))

// }

// export function deleteCarDrivingSchoolById(id, token) {
//   return axios.delete(`${BASE_URL_AUTOX}${DELETE_CAR_DRIVING_SCHOOL}${id}`, getConfig(token))
// }

//Maruti Suzuki Driving School APIs
// export function getMarutiSuzukiCarDrivingSchools(token, payload) {
//   return axios.post(`${BASE_URL_AUTOX}${GET_MARUTI_SUZUKI_CAR_DRIVING_SCHOOL}`, payload,
//     getConfig(token)
//   )
// }

// export function getMarutiSuzukiCarDrivingSchoolById(id, token) {
//   return axios.get(`${BASE_URL_AUTOX}${GET_MARUTI_SUZUKI_CAR_DRIVING_SCHOOL_BY_ID}${id}`,
//     getConfig(token)
//   )
// }

// export function addMarutiSuzukiDrivingSchool(payload, token) {
//   return axios.post(`${BASE_URL_AUTOX}${ADD_MARUTI_SUZUKI_CAR_DRIVING_SCHOOL}`, payload, getConfig(token))
// }

// export function updateMarutiSuzukiDrivingSchoolById(id, payload, token) {
//   return axios.put(`${BASE_URL_AUTOX}${UPDATE_MARUTI_SUZUKI_CAR_DRIVING_SCHOOL}${id}`, payload, getConfig(token))
// }

// export function updateCarCity(payload) {
//   return axios.post(`${BASE_URL_AUTOX}${UPDATE_CAR_CITY}`, config.headers, payload)
// }

// export function deleteMarutiSuzukiDrivingSchoolById(id, token) {
//   return axios.delete(`${BASE_URL_AUTOX}${DELETE_MARUTI_SUZUKI_CAR_DRIVING_SCHOOL}${id}`, getConfig(token))
// }

//car city APIs

export function getAllCities(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_ALL_CITIES}`,
    payload,
    getConfig(token)
  );
}

export function getAllBikeCities(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_ALL_BIKE_CITIES}`,
    payload,
    getConfig(token)
  );
}

export function getCarCities(token) {
  return axios.post(`${BASE_URL_AUTOX}${GET_CAR_CITIES}`, {}, getConfig(token));
}
export function getBikeCities(token) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_BIKE_CITIES}`,
    {},
    getConfig(token)
  );
}

export function getCarCityById(id, token) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_CAR_CITY_BY_ID}${id}`,
    getConfig(token)
  );
}

export function getBikeCityById(id, token) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_BIKE_CITY_BY_ID}${id}`,
    getConfig(token)
  );
}

export function addCarCity(payload, token) {
  return axios.post(
    `${BASE_URL_AUTOX}${ADD_CAR_CITY}`,
    payload,
    getConfig(token)
  );
}
export function addBikeCity(payload, token) {
  return axios.post(
    `${BASE_URL_AUTOX}${ADD_BIKE_CITY}`,
    payload,
    getConfig(token)
  );
}

export function updateCarCityById(id, payload, token) {
  return axios.put(
    `${BASE_URL_AUTOX}${UPDATE_CAR_CITY}${id}`,
    payload,
    getConfig(token)
  );
}

export function updateBikeCityById(id, payload, token) {
  return axios.put(
    `${BASE_URL_AUTOX}${UPDATE_BIKE_CITY}${id}`,
    payload,
    getConfig(token)
  );
}

export function deleteCarCityById(id, token) {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_CAR_CITY}${id}`,
    getConfig(token)
  );
}
export function deleteBikeCityById(id, token) {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_BIKE_CITY}${id}`,
    getConfig(token)
  );
}

// City Price APIs

export function getCityPrice(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_CAR_CITY_PRICE}`,
    payload,
    getConfig(token)
  );
}

export function getBikeCityPrice(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_BIKE_CITY_PRICE}`,
    payload,
    getConfig(token)
  );
}

export function getCityPriceById(id, url, token) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_CAR_CITY_PRICE_BY_ID}${id}/${url}`,
    getConfig(token)
  );
}
export function getBikeCityPriceById(id, url, token) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_BIKE_CITY_PRICE_BY_ID}${id}/${url}`,
    getConfig(token)
  );
}

export function updateCityPriceById(id, url, payload, token) {
  return axios.put(
    `${BASE_URL_AUTOX}${UPDATE_CAR_CITY_PRICE}${id}/${url}`,
    payload,
    getConfig(token)
  );
}
export function updateBikeCityPriceById(id, url, payload, token) {
  return axios.put(
    `${BASE_URL_AUTOX}${UPDATE_BIKE_CITY_PRICE}${id}/${url}`,
    payload,
    getConfig(token)
  );
}

export function addCityPrice(payload, token) {
  console.log(payload);
  return axios.post(
    `${BASE_URL_AUTOX}${ADD_CAR_CITY_PRICE}`,
    payload,
    getConfig(token)
  );
}

export function addBikeCityPrice(payload, token) {
  return axios.post(
    `${BASE_URL_AUTOX}${ADD_BIKE_CITY_PRICE}`,
    payload,
    getConfig(token)
  );
}

export function convertTime(timeString) {
  // Extract the number of seconds from the string
  const match = timeString.match(/(-?\d+)\sseconds\sago/);
  if (!match) {
    return timeString;
  }

  // Get the number of seconds as an integer
  let seconds = parseInt(match[1], 10);

  // Remove the negative sign if it's there (we'll handle that separately)
  const sign = seconds < 0 ? "ago" : "from now";
  seconds = Math.abs(seconds); // Work with absolute value for calculation

  // Calculate time units
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);

  // Format the result
  if (months > 0) {
    return `${months} month${months > 1 ? "s" : ""} ${sign}`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ${sign}`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ${sign}`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ${sign}`;
  } else {
    return `${seconds} second${seconds > 1 ? "s" : ""} ${sign}`;
  }
}

export function deleteCityPrice(id, brand_url, token) {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_CITY_PRICE}${id}/${brand_url}`,
    getConfig(token)
  );
}
export function deleteBikeCityPrice(id, brand, token) {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_BIKE_CITY_PRICE}${id}/${brand}`,
    getConfig(token)
  );
}

// State APIs

export function getAllStates(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_ALL_STATES}`,
    payload,
    getConfig(token)
  );
}

export function getStates(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_STATES}`,
    payload,
    getConfig(token)
  );
}

export function getStateById(id, token) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_STATE_BY_ID}${id}`,
    getConfig(token)
  );
}

export function addState(payload, token) {
  return axios.post(`${BASE_URL_AUTOX}${ADD_STATE}`, payload, getConfig(token));
}

export function updateStateById(id, payload, token) {
  return axios.post(
    `${BASE_URL_AUTOX}${UPDATE_STATE}${id}`,
    payload,
    getConfig(token)
  );
}

export function deleteStateById(id, token) {
  return axios.post(`${BASE_URL_AUTOX}${DELETE_STATE}${id}`, getConfig(token));
}

//Car Service Centre APIs
export function getCarServiceCentres(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_CAR_SERVICE_CENTRE}`,
    payload,
    getConfig(token)
  );
}

export function getCarServiceCentreById(id, token) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_CAR_SERVICE_CENTRE_BY_ID}${id}`,
    getConfig(token)
  );
}

export function addCarServiceCentre(payload, token) {
  return axios.post(
    `${BASE_URL_AUTOX}${ADD_CAR_SERVICE_CENTRE}`,
    payload,
    getConfig(token)
  );
}

export function updateCarServiceCentreById(id, payload, token) {
  return axios.put(
    `${BASE_URL_AUTOX}${UPDATE_CAR_SERVICE_CENTRE}${id}`,
    payload,
    getConfig(token)
  );
}

export function deleteCarServiceCentreById(token, id) {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_CAR_SERVICE_CENTRE}${id}`,
    getConfig(token)
  );
}

// Car Body Type APIs
export function getCarBodyType(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_CAR_TYPE}`,
    payload,
    getConfig(token)
  );
}

export function getBikeBodyType(token) {
  return axios.get(`${BASE_URL_AUTOX}${GET_BIKE_TYPE}`, getConfig(token));
}

export function getFAQlist(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_FAQ_LIST}`,
    payload,
    getConfig(token)
  );
}
export function getBikeFAQlist(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_BIKE_FAQ_LIST}`,
    payload,
    getConfig(token)
  );
}
//Car Comparison APIs
// export function getCarComparison(token) {
//   return axios.get(`${BASE_URL_AUTOX}${GET_CAR_COMPARISON}`, getConfig(token)
//   )
// }

export function getCarComparisonData(token, car_body_type_id, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_CAR_COMPARISON_BY_BODY_TYPE}${car_body_type_id}`,
    payload,
    getConfig(token)
  );
}

export function getCarComparisonBodyTypes(token) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_CAR_COMPARISON_BODY_TYPES}`,
    getConfig(token)
  );
}

export function getCarComparisonViewLink(token, id) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_CAR_COMPARISON_URL}${id}`,
    getConfig(token)
  );
}

export function addCarComparison(payload, token) {
  return axios.post(
    `${BASE_URL_AUTOX}${ADD_CAR_COMPARISON}`,
    payload,
    getConfig(token)
  );
}

export function bulkUpdateCarComparisonOrdering(token, payload) {
  console.log("test 4567");
  return axios.put(
    `${BASE_URL_AUTOX}${BULK_UPDATE_CAR_COMPARISON_ORDERING}`,
    payload,
    getConfig(token)
  );
}

export function deleteCarComparisonById(id, token) {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_CAR_COMPARISON}${id}`,
    getConfig(token)
  );
}
export function deleteCarModelById(id, token) {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_CAR_MODEL}${id}`,
    getConfig(token)
  );
}
export function deleteBikeModelById(id, token) {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_BIKE_MODEL}${id}`,
    getConfig(token)
  );
}

// Car version APIs
export function getCarVersionList(token) {
  return axios.post(`${BASE_URL_AUTOX}${GET_VERSION}`, getConfig(token));
}

export function getVersionsByModelId(model_id, token) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_VERSION_BY_MODEL_ID}${model_id}`,
    getConfig(token)
  );
}

export function getBikeVersionsByModelId(model_id, token) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_BIKE_VERSION_BY_MODEL_ID}${model_id}`,
    getConfig(token)
  );
}

export function getModelByBrandId(payload, token) {
  return axios.post(
    `${BASE_URL_AUTOX}car/model/all/list`,
    payload,
    getConfig(token)
  );
}

export function getBikeModelByBrandId(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_BIKE_MODEL_LIST_BY_BRAND}`,
    payload,
    getConfig(token)
  );
}
