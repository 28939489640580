import { useNavigate } from "react-router-dom";
import { getToken } from "../../utility/constants";
import { notifyError, notifySuccess } from "../../utility/common";
import apiHandler from "./apiHandler";
import MagazineForm from "./MagazineForm";

export default function AddMagazine() {
  const navigate = useNavigate();
  const token = getToken();
  const initialValues = {
    title: "",
    url: "",
    post_id: "",
    display_date: "",
    display_image: "",
    content: "",
  };

  const AddMagazine = async (data) => {
    try {
      const formData = new FormData();
      for (let e of Object.entries(data)) {
        console.log(e[0], e[1]);
        formData.append(e[0], e[1]);
      }
      console.log(formData, "formData");
      const response = await apiHandler.addMagazine(formData, token);
      console.log(response, "response");
      if (response.status === 200) {
        notifySuccess(response.data.message);
        setTimeout(() => {
          navigate("/magazine");
        }, 1500);
      }
    } catch (error) {
      console.log(error);
      notifyError(error.response.data.message);
    }
  };
  return (
    <>
      <MagazineForm
        initialValues={initialValues}
        onSubmit={AddMagazine}
        title="Add Magazine"
      />
    </>
  );
}
