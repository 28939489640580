import { Accordion, Form } from "react-bootstrap";
import { carType } from "../../../../utility/constants";
import FormFieldErrorMsg from "../../../../components/FormFieldErrorMsg";
import FormControl from "../../../../components/FormControl";
import FormSelect from "../../../../components/FormSelect";
import JoditEditor from "jodit-react";
import { useRef } from "react";
import DefaultProsAndConsTemplateContent from "../../../Template/ProsAndConsTemplate";
const AddCarLeft = ({
  touched,
  values,
  errors,
  setFieldValue,
  value,
  onDescriptionChange,
  onEditorialChange,
  editorValue,
  modelFAQ,
  customFAQs,
  setCustomFAQs,
  handleUpdateFAQ,
  handleAddFAQInput,
}) => {
  const htmlString = "Editorial Supports <img> and <br> tags only";
  const editor = useRef(null);
  const newEditor = useRef(null);
  const upcomingPreview = useRef(null);
  const proscons = useRef(null);
  console.log("valuestrue", values);
  console.log("valuefalse", value);

  return (
    <div className="col-xl-9 col-sm-8">
      <Accordion defaultActiveKey="0" className="mb-4">
        <Accordion.Item
          eventKey="0"
          className="card-height header_padding_top pb-4"
        >
          <Accordion.Header className="p-3 pb-2 ps-2 pt-4">
            Primary Details
          </Accordion.Header>
          <Accordion.Body>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-7" controlId="modelName">
                  <FormControl
                    controlId={"modelName"}
                    label={"Model Name"}
                    placeholder={"Model Name"}
                    touched={touched}
                    errors={errors}
                    name={"model_name"}
                    value={values.model_name}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                    isRequired={true}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-5" controlId="modelUrl">
                  <FormControl
                    controlId={"modelUrl"}
                    label={"Model Url"}
                    placeholder={"Model Url"}
                    touched={touched}
                    errors={errors}
                    name={"model_url"}
                    value={values.model_url}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="" controlId="StatusControlSelect">
                  <Form.Label>
                    Model URL status<sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => setFieldValue("car_type", e.target.value)}
                    value={values.car_type}
                    className="mb-4"
                    name="car_type"
                  >
                    <option value={-1} disabled selected>
                      Select Car Type
                    </option>
                    {carType.map((type, index) => (
                      <option key={index} value={type.id}>
                        {type.value}
                      </option>
                    ))}
                  </Form.Select>
                  <FormFieldErrorMsg
                    touched={touched}
                    errors={errors}
                    name={"car_type"}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="StatusControlSelect">
                  <Form.Label>
                    Date <sup className="text-danger">*</sup> (dd-mm-yyyy)
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="model_date"
                    pattern="\d{4}-\d{2}-\d{2}"
                    placeholder="YYYY-MM-DD"
                    value={values.model_date}
                    onChange={(e) => {
                      const normalizedDate = e.target.value.replace(/\//g, "-");
                      setFieldValue("model_date", normalizedDate);
                    }}
                  />
                </Form.Group>
              </div>
              {values.car_type == 1 && (
                <>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="modelUrl">
                      <Form.Label>
                        Expected Price <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder={"Expected Price"}
                        value={values.starting_price}
                        onChange={(e) =>
                          setFieldValue("starting_price", e.target.value)
                        }
                        name="starting_price"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="modelUrl">
                      <Form.Label>
                        Ending Price <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder={"Ending Price"}
                        value={values.ending_price}
                        onChange={(e) =>
                          setFieldValue("ending_price", e.target.value)
                        }
                        name="ending_price"
                      />
                    </Form.Group>
                  </div>
                </>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion defaultActiveKey="0" className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="p-3 pb-1 ps-2">
            Secondary Details
          </Accordion.Header>
          <Accordion.Body>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="colorCode">
                  <FormControl
                    controlId={"colorCode"}
                    label={"Color name and color code"}
                    placeholder={"Color name and color code"}
                    touched={touched}
                    errors={errors}
                    name={"colors"}
                    value={values.colors}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                    isRequired={true}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="popularity">
                  <FormControl
                    controlId={"popularity"}
                    label={"Popularity"}
                    placeholder={"Popularity"}
                    touched={touched}
                    errors={errors}
                    name={"popular_position"}
                    value={values.popular_position}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                    isRequired={true}
                  />
                </Form.Group>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {values.car_type == 1 && (
        <Accordion defaultActiveKey="0" className="mb-4">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="p-3 pb-1 ps-2 acrodian-btn">
              Model Preview <sup className="required-superscript">*</sup>
            </Accordion.Header>
            <Accordion.Body>
              <div className="row">
                <div className="col-md-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <JoditEditor
                      ref={upcomingPreview}
                      value={values["model_preview"]}
                      onBlur={(value) => setFieldValue("model_preview", value)}
                    />
                  </Form.Group>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
      <Accordion defaultActiveKey="0" className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="p-3 pb-1 ps-2 acrodian-btn">
            Model Content<sup className="required-superscript">*</sup>
          </Accordion.Header>
          <Accordion.Body>
            <div className="row">
              <div className="col-md-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <JoditEditor
                    ref={editor}
                    value={value}
                    onBlur={onDescriptionChange}
                  />
                </Form.Group>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion defaultActiveKey="0" className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="p-3 pb-1 ps-2 acrodian-btn">
            Model Editorial Review<sup className="required-superscript">*</sup>
          </Accordion.Header>
          <Accordion.Body>
            <div className="row">
              <div className="col-md-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <JoditEditor
                    ref={newEditor}
                    value={editorValue}
                    onBlur={onEditorialChange}
                  />
                </Form.Group>
              </div>
            </div>

            <Form.Label className="fw-light">
              Editorial Supports / {htmlString}
            </Form.Label>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion defaultActiveKey="0" className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="p-3 pb-1 ps-2 acrodian-btn">
            Model Pros and Cons<sup className="required-superscript"></sup>
          </Accordion.Header>
          <Accordion.Body>
            <div className="row">
              <div className="col-md-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <JoditEditor
                    ref={proscons}
                    value={values["model_prosncons"]}
                    onBlur={(value) => {
                      setFieldValue("model_prosncons", value);
                    }}
                  />
                </Form.Group>
              </div>
            </div>
            <Form.Label className="fw-light d-flex justify-content-between">
              Pros / Cons {htmlString}
              <button
                type="button"
                className="add-button mat-0"
                onClick={(e) => {
                  setFieldValue(
                    "model_prosncons",
                    DefaultProsAndConsTemplateContent[0]
                  );
                }}
              >
                <span>Default</span>
              </button>
            </Form.Label>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion defaultActiveKey="0" className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="p-3 pb-1 ps-2">
            Model SEO Tags
          </Accordion.Header>
          <Accordion.Body>
            <div className="row">
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="seoTitleInput">
                  <FormControl
                    controlId={"seoTitle"}
                    label={"Seo Title"}
                    placeholder={"Seo Title"}
                    touched={touched}
                    errors={errors}
                    name={"meta_title"}
                    value={values.meta_title}
                    setFieldValue={setFieldValue}
                    className="mb-4"
                  />
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="metaDescriptionInput">
                  <Form.Label>Meta Description </Form.Label>
                  <Form.Control
                    as="textarea"
                    aria-label="With textarea"
                    onChange={(e) =>
                      setFieldValue("meta_description", e.target.value)
                    }
                    value={values.meta_description}
                    name="meta_description"
                    placeholder="Meta Description"
                  />
                  <FormFieldErrorMsg
                    touched={touched}
                    name="meta_description"
                    errors={errors}
                  />
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="StatusControlSelect">
                  <Form.Label>Meta Keywords</Form.Label>
                  <Form.Control
                    as="textarea"
                    aria-label="With textarea"
                    onChange={(e) =>
                      setFieldValue("meta_keyword", e.target.value)
                    }
                    value={values.meta_keyword}
                    name="meta_keyword"
                    placeholder="Meta Keywords"
                  />
                  <FormFieldErrorMsg
                    touched={touched}
                    name="meta_keyword"
                    errors={errors}
                  />
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="StatusControlSelect">
                  <Form.Label>Canonical URL</Form.Label>
                  <Form.Control
                    as="textarea"
                    aria-label="With textarea"
                    onChange={(e) =>
                      setFieldValue("canonical_url", e.target.value)
                    }
                    value={values.canonical_url}
                    name="canonical_url"
                    placeholder="Canonical URL"
                  />
                  <FormFieldErrorMsg
                    touched={touched}
                    name="canonical_url"
                    errors={errors}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="StatusControlSelect">
                  <Form.Label>Meta Robots Index </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="robots_index"
                    value={values.robots_index}
                    onChange={(e) =>
                      setFieldValue("robots_index", e.target.value)
                    }
                    defaultValue={""}
                  >
                    <option value="" disabled selected>
                      Please Select
                    </option>
                    <option value="index">Index</option>
                    <option value="noindex">No Index</option>
                  </Form.Select>
                  <FormFieldErrorMsg
                    touched={touched}
                    name="robots_index"
                    errors={errors}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="StatusControlSelect">
                  <Form.Label>Meta Robots Follow </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="robots_follow"
                    value={values.robots_follow}
                    onChange={(e) =>
                      setFieldValue("robots_follow", e.target.value)
                    }
                    defaultValue={""}
                  >
                    <option value="" disabled selected>
                      Please Select
                    </option>
                    <option value="Follow">Follow</option>
                    <option value="nofollow">No Follow</option>
                  </Form.Select>
                  <FormFieldErrorMsg
                    touched={touched}
                    name="robots_index"
                    errors={errors}
                  />
                </Form.Group>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="0" className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="p-3 pb-1 ps-2 acrodian-btn">
            Model FAQ Questions
          </Accordion.Header>
          <Accordion.Body>
            {modelFAQ.map((item, index) => (
              <Accordion key={index} className="mb-3">
                <Accordion.Item eventKey={index.toString()}>
                  <Accordion.Header>
                    <Form.Check
                      label={item.question}
                      className="checklist"
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        if (isChecked) {
                          setFieldValue("faq", [...values.faq, item.id]);
                        } else {
                          const filteredFaq = values.faq.filter(
                            (faqId) => faqId !== item.id
                          );
                          setFieldValue("faq", filteredFaq);
                        }
                      }}
                      type="checkbox"
                      checked={values.faq.includes(item.id)}
                    />
                  </Accordion.Header>
                  <Accordion.Body>{item.answer}</Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion defaultActiveKey="0" className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="p-3 pb-1 ps-2 acrodian-btn">
            Model Custom FAQs{" "}
            <button
              type="button"
              className="add-button mat-0 rightsidebtn"
              onClick={(e) => {
                e.stopPropagation();
                handleAddFAQInput();
              }}
            >
              <span>Add FAQ</span>
            </button>
          </Accordion.Header>
          <Accordion.Body className="pe-5">
            <Form>
              {customFAQs.map((faq, index) => (
                <div className="row" key={index}>
                  <div className="col-lg-10 col-sm-9 col-8">
                    <Form.Group
                      className="mb-3"
                      controlId={`questionInput-${index}`}
                    >
                      <Form.Control
                        type="text"
                        placeholder="Enter Question"
                        value={faq.question}
                        onChange={(e) =>
                          handleUpdateFAQ(index, "question", e.target.value)
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId={`answerInput-${index}`}
                    >
                      <Form.Control
                        type="text"
                        placeholder="Enter Answer"
                        value={faq.answer}
                        onChange={(e) =>
                          handleUpdateFAQ(index, "answer", e.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-2 col-sm-3 col-4 d-flex align-items-center">
                    <button
                      className="remove-button d-flex align-items-center px-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        const updatedFAQs = [...customFAQs];
                        updatedFAQs.splice(index, 1);
                        setCustomFAQs(updatedFAQs);
                      }}
                    >
                      <span className="remove-text">Remove</span>
                    </button>
                  </div>
                </div>
              ))}
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default AddCarLeft;
