import { ReactSVG } from "react-svg";
import SearchIcon from "../assets/icons/bootstrap-icons/search.svg";
export default function Search({onChange, value, placeholder}) {
    return (
        <div class="d-flex align-items-center rounded px-1 py-0 search-container"  >
            <div className="search-icon px-2" >
                <ReactSVG src={SearchIcon} />
            </div>
            <input type="text" className="form-control form-control-sm border-0 shadow-none px-0 search-input" id="searchBrand" placeholder={placeholder} value={value} onChange={onChange} />
        </div>
    )
}