import * as Yup from "yup";

const PageValidationSchema = Yup.object().shape({
  parent: Yup.number().nullable().required("Parent is required"),
  page_name: Yup.string().max(255).nullable().required("Page name is required"),
  slug: Yup.string().max(255).nullable().required("Slug is required"),
  heading: Yup.string().max(500).nullable().required("Heading is required"),

  breadcrumb: Yup.string().max(255).nullable(),
  image: Yup.string().nullable(),
  description: Yup.string().nullable(),
  content: Yup.string().nullable().required("Content is required"),

  meta_title: Yup.string().max(255).nullable(),
  meta_description: Yup.string().nullable(),
  meta_keywords: Yup.string().nullable(),
  meta_canonical: Yup.string().max(255).nullable(),
  status: Yup.number().oneOf([0, 1]).required("Status is required"),
});

export default PageValidationSchema;
