import { Card } from "react-bootstrap";
import HeadingTwo from "../../../components/HeadingTwo";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import PlusLargeIcon from "../../../assets/icons/bootstrap-icons/plus-lg.svg";
import { useEffect, useState } from "react";
import { getToken } from "../../../utility/constants";
import { getCarGalleryList } from "../apiHandler";
import CarGalleryColumn from "../GalleryColumn";
import Datatable from "../../../components/Table";
export default function CarGalleryListing() {
  const [carGallery, setCarGallery] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [reload, setReload] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const navigator = useNavigate();
  const token = getToken();
  // useEffect(() => {
  //   const fetchCarGallery = async () => {
  //     const data = await getCarGalleryList(token);
  //     console.log(data);
  //     setCarGallery(data.data);
  //     setTotalRecords(data.data.length);
  //   };

  //   fetchCarGallery();
  // }, [reload]);
  const reloadTable = () => {
    setReload(!reload);
  };

  return (
    <div>
      <Card>
        <Card.Body>
          <Card.Text>
            <div className="d-flex justify-content-between align-items-center">
              <HeadingTwo
                title="Car Gallery"
                type="dashboard"
                showHeader={true}
                pointerOnHover={true}
              />
              <button
                className="add-variant-button d-flex align-items-center justify-content-between me-2 mb-2 px-3"
                onClick={() => navigator("/gallery-add")}
              >
                <ReactSVG
                  src={PlusLargeIcon}
                  height="18px"
                  width="18px"
                  className="mr-2"
                />{" "}
                Add Gallery
              </button>
            </div>
          </Card.Text>
          <div className="row dashboard">
            <Datatable
              api={getCarGalleryList}
              columns={CarGalleryColumn(reloadTable, token)}
              setTotalRecords={setTotalRecords}
              reload={reload}
              setReload={setReload}
              setCurrentPage={setCurrentPage}
              itemsPerPage={itemsPerPage}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
