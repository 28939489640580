
import React, { useState } from "react";
import {
    useLocation,
    useNavigate
} from "react-router-dom";
import Layout from "../../../components/Layout";
import AddCarComparison from "./AddCarComparison";
import CarComparisonList from "./CarComparisonList";
import Breadcrumbs from "../../../Breadcrumbs";

export default function CarComparisonMain({ }) {
    const location = useLocation();
    const navigate = useNavigate();
    const [reloadData, setReloadData]=useState(false)

    return (
        <Layout>
            <div className="row dashboard">
                <Breadcrumbs/>
                <div className="my-0 d-flex">
                    <div className="col-8 pe-4 align-self-start">
                        <CarComparisonList reloadData={reloadData}/>
                    </div>
                    <div className="col-4 align-self-start">
                        <AddCarComparison setReloadData={setReloadData}/>

                    </div>
                </div>
            </div>
        </Layout>
    )
}