import axios from "axios"; // Importing axios for making HTTP requests
import { BASE_URL_AUTOX, getConfig } from "../../request"; // Importing the base URL and getConfig function from the request module

/**
 * Fetches a list of tags filtered by parameters.
 *
 * @param {string} token - The authentication token.
 * @param {Object} params - The parameters to filter the tags by.
 * @return {Promise} A promise that resolves to the response data.
 */
export function getTagList(token, params) {
  return axios.post(
    `${BASE_URL_AUTOX}tags/filtered/list`, // The URL for fetching filtered tags
    params,
    getConfig(token) // The configuration object for the request
  );
}

/**
 * Deletes a tag by its ID.
 *
 * @param {string} id - The ID of the tag to delete.
 * @param {string} token - The authentication token.
 * @return {Promise} A promise that resolves to the response data.
 */
export function deleteTagById(id, token) {
  return axios.delete(`${BASE_URL_AUTOX}tags/delete/${id}`, getConfig(token)); // The URL for deleting a tag by its ID
}

/**
 * Fetches a tag by its ID.
 *
 * @param {string} id - The ID of the tag to fetch.
 * @param {string} token - The authentication token.
 * @return {Promise} A promise that resolves to the response data.
 */
export function getTagById(id, token) {
  return axios.get(`${BASE_URL_AUTOX}tags/details/${id}`, getConfig(token)); // The URL for fetching a tag by its ID
}

/**
 * Adds a new tag.
 *
 * @param {Object} payload - The data for the new tag.
 * @param {string} token - The authentication token.
 * @return {Promise} A promise that resolves to the response data.
 */
export function addTag(payload, token) {
  return axios.post(`${BASE_URL_AUTOX}tags/add`, payload, getConfig(token)); // The URL for adding a new tag
}

/**
 * Updates a tag by its ID.
 *
 * @param {string} id - The ID of the tag to update.
 * @param {Object} payload - The data for the updated tag.
 * @param {string} token - The authentication token.
 * @return {Promise} A promise that resolves to the response data.
 */
export function updateTag(id, payload, token) {
  return axios.put(
    `${BASE_URL_AUTOX}tags/update/${id}`,
    payload,
    getConfig(token)
  ); // The URL for updating a tag by its ID
}
export function getTagsType(token) {
  return axios.get(`${BASE_URL_AUTOX}tags/types`, getConfig(token));
}
export default {
  getTagList,
  deleteTagById,
  getTagById,
  addTag,
  updateTag,
  getTagsType,
};
