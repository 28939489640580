import { useNavigate } from "react-router-dom";
import { getAdminType, getToken, getUserId } from "../../utility/constants";
import { useFormik } from "formik";
import PostValidationSchema from "./validationSchema";
import { addNewPost } from "./apiHandler";
import { notifySuccess } from "../../utility/common";
import Breadcrumbs from "../../Breadcrumbs";
import PostForm from "./PostForm";
import { useState } from "react";
import { getISTDate } from "../../request";

export default function AddPost() {
  const navigate = useNavigate();
  const token = getToken();
  const user_id = getUserId();
  const adminType = getAdminType();
  const [isDraft, setIsDraft] = useState(false);

  const { handleSubmit, values, setFieldValue, touched, errors, isSubmitting } =
    useFormik({
      initialValues: {
        category_id: -1,
        author_id: user_id,
        post_title: "",
        slug: "",
        post_content: "",
        short_description: "",
        featured_image: "",
        thumb_type: "featured_image",
        slider_images: [],
        status: 1,
        linkedin_post_status: "0",
        home_page_image: "0",
        verify_duplicate: "0",
        display_category: "0",
        publish_date: "",
        photography_id: "-1",
        brand: "-1",
        model: "-1",
        type: "car",
        tags: [],
        thumb_value: "",
        slider: 0,
        seo_title: "",
        seo_description: "",
        meta_keywords: "",
        meta_description: "",
        cannonical: "",
        stories_images: [],
        display_category: [],
        post_rating: 0,
      },
      enableReinitialize: true,
      validationSchema: PostValidationSchema,
      onSubmit: (values) => {
        const payload = { ...values };
        if (isDraft) {
          payload.status = 0;
        }
        if (payload.publish_date == "") {
          payload.publish_date = getISTDate();
        }

        delete payload?.category_slug;
        delete payload?.parent_category_slug;
        delete payload?.category_name;
        if (payload.status == 1) {
          payload.publish_date = getISTDate();
        }
        if (payload.status == 2) {
          const localDate = new Date(payload.publish_date); // Create the date in local time
          const offset = localDate.getTimezoneOffset(); // Get the timezone offset in minutes
          localDate.setMinutes(localDate.getMinutes() - offset); // Adjust the date to UTC
          payload.publish_date = localDate.toISOString(); // Get the UTC ISO string
          console.log(payload.publish_date); // Logs in UTC, but original time
        }
        console.log(payload);
        addNewPost(payload, token).then((res) => {
          if (res.status === 200) {
            notifySuccess("Post added successfully");
            setTimeout(() => {
              navigate("/post-management");
            }, 1500);
          }
        });
      },
    });
  return (
    <div>
      <Breadcrumbs />
      <div>
        <PostForm
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          handleSubmit={handleSubmit}
          type="add"
          adminType={adminType}
          setIsDraft={setIsDraft}
          isSubmitting={isSubmitting}
        />
      </div>
    </div>
  );
}
