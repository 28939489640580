import * as Yup from "yup";
export const carBrandValidation = Yup.object({
  brand_name: Yup.string().required("Brand Name is required"),
  brand_url: Yup.string().optional(),
  brand_image: Yup.mixed().required("Brand Image is required"),
  brand_ordering: Yup.number()
    .typeError("Brand Ordering must be a number")
    .required("Brand Ordering is required"),
  canonical_url: Yup.string(),
  meta_description: Yup.string().optional(),
  meta_keyword: Yup.string().optional(),
  meta_title: Yup.string().optional(),
  robots_follow: Yup.string().oneOf(["Follow", "nofollow"]).optional(),
  robots_index: Yup.string().oneOf(["index", "noindex"]).optional(),
  short_description: Yup.string().optional(),
  status: Yup.number()
    .oneOf([0, 1], "Please enter valid status")
    .required("Status is required"),
});
