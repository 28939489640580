/**
 * Handles API requests for the editor page
 * @module editorApiHandler
 * @example
 * import { getEditorList, addEditor, editEditor, getEditorDetails, deleteEditor, geteditorModules } from './editorApiHandler';
 */
import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../request";

/**
 * Gets the list of all editors
 * @param {string} token - The authentication token
 * @param {object} payload - The payload to send with the request
 * @returns {Promise} - The promise resolved with the response data
 */
export function getEditorList(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}editor/filtered/list`,
    payload,
    getConfig(token)
  );
}

/**
 * Adds a new editor
 * @param {object} payload - The payload to send with the request
 * @param {string} token - The authentication token
 * @returns {Promise} - The promise resolved with the response data
 */
export function addEditor(payload, token) {
  return axios.post(`${BASE_URL_AUTOX}editor/add`, payload, {
    headers: { Authorization: token, "Content-Type": "multipart/form-data" },
  });
}

/**
 * Edits an existing editor
 * @param {number} id - The ID of the editor to edit
 * @param {object} data - The data to send with the request
 * @param {string} token - The authentication token
 * @returns {Promise} - The promise resolved with the response data
 */
export function editEditor(id, data, token) {
  return axios.put(`${BASE_URL_AUTOX}editor/edit/${id}`, data, {
    headers: { Authorization: token, "Content-Type": "multipart/form-data" },
  });
}

/**
 * Gets the list of all editor modules
 * @param {string} token - The authentication token
 * @returns {Promise} - The promise resolved with the response data
 */
export function geteditorModules(token) {
  return axios.get(`${BASE_URL_AUTOX}editor/editor-modules`, getConfig(token));
}

/**
 * Gets the details of a single editor
 * @param {number} id - The ID of the editor to get details for
 * @param {string} token - The authentication token
 * @returns {Promise} - The promise resolved with the response data
 */
export function getEditorDetails(id, token) {
  return axios.get(
    `${BASE_URL_AUTOX}editor/editor-details/${id}`,
    getConfig(token)
  );
}

/**
 * Deletes an editor
 * @param {number} id - The ID of the editor to delete
 * @param {string} token - The authentication token
 * @returns {Promise} - The promise resolved with the response data
 */
export function deleteEditor(id, token) {
  return axios.put(
    `${BASE_URL_AUTOX}editor/update-status/${id}`,
    getConfig(token)
  );
}
export function changeEditorPassword(token, id, password) {
  return axios.patch(
    `${BASE_URL_AUTOX}editor/change-password/${id}`,
    { password: password },
    getConfig(token)
  );
}

/**
 * Exports all the functions in this module
 * @type {Object}
 */
export default {
  getEditorList,
  addEditor,
  editEditor,
  getEditorDetails,
  deleteEditor,
  geteditorModules,
  changeEditorPassword,
};
