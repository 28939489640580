import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";

export default function CarDrivingSchoolMain({ children }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <div className="row dashboard">
        <div>{children}</div>
      </div>
    </Layout>
  );
}
