import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Accordion, Form } from "react-bootstrap";
import adPageAPI from "../adPage/adPageAPI.js";
import { getToken } from "../../../utility/constants.js";
import CustomAdWidgetForm from "./CustomAdWidgetForm";
import CategoryAdsSelect from "./categoryAdsSelect";
import { notifyError, notifySuccess } from "../../../utility/common.js";
import AdWidgetShortCodes from "./AdWidgetShortCodes.js";

export default function AdWidgetDescription() {
  const navigate = useNavigate();
  const [customWidgets, setCustomWidgets] = useState([]);
  const [removedWidgets, setRemovedWidgets] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const token = getToken();
  const { id } = useParams();

  const handleUpdateWidgets = (index, field, value) => {
    const updatedWidgets = [...customWidgets];
    updatedWidgets[index][field] = value;
    setCustomWidgets(updatedWidgets);
  };

  const handleAddWidgets = () => {
    setCustomWidgets([
      ...customWidgets,
      { widget_name: "", widget_type: -1, description: "", position: "1" }
    ]);
  };

  useEffect(() => {
    const getAdPageWidgets = async () => {
      try {
        const payload = {
          adpage_id: id,
          sortBy: "",
          sortOrder: ""
        };
        const response = await adPageAPI.getWidgetFromadPage(token, payload);
        const preData = response.data.result;

        const extractedData = preData.map((item) => ({
          id: item.id,
          description: item.description,
          widget_name: item.widget_name,
          widget_type: item.widget_type,
          position: item.position
        }));

        setCustomWidgets(extractedData);
      } catch (error) {
        console.log(error);
      }
    };

    getAdPageWidgets();
  }, [id]);

  const updatedWidgets = async () => {
    try {
      const encodedWidgets = customWidgets.map((widget) => ({
        ...widget,
        description: encodeURIComponent(widget.description)
      }));

      const payload = {
        adpage_id: id,
        widgets: JSON.stringify(encodedWidgets),
        widget_removed: JSON.stringify(removedWidgets)
      };

      const response = await adPageAPI.updateWidgets(token, payload);
      if (response.status === 200) {
        notifySuccess("Updated successfully");
        setTimeout(() => {
          navigate(`/ad-widget`);
        }, 1500);
      } else {
        console.log(response);
        notifyError("Something went wrong");
      }
    } catch (error) {
      console.log(error);
      notifyError("Something went wrong");
    }
  };

  return (
    <>
      <CategoryAdsSelect selectedAdPage={id} />
      {id ? (
        <div className="widget-area">
          <div className="custom-widget-form">
            <CustomAdWidgetForm
              customWidgets={customWidgets}
              setCustomWidgets={setCustomWidgets}
              handleAddWidgets={handleAddWidgets}
              handleUpdateWidgets={handleUpdateWidgets}
              setRemovedWidgets={setRemovedWidgets}
              removedWidgets={removedWidgets}
            />
          </div>
          <div className="widget-action-area">
            <div className="h-100" />
            <div className="widget-action">
              <Accordion defaultActiveKey="0" className="mb-4">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="p-3 pb-1 ps-2">
                    Action
                  </Accordion.Header>
                  <Accordion.Body className="pt-1 d-flex flex-column gap-3">
                    <div className="d-flex gap-3">
                      <div className="">
                        <button
                          type="submit"
                          className="add-widget-button"
                          onClick={handleAddWidgets}
                        >
                          Add More Widget
                        </button>
                      </div>

                      <button
                        type="submit"
                        className="green-submit-button"
                        onClick={updatedWidgets}
                      >
                        Submit
                      </button>
                    </div>

                    <div className="w-100 d-flex justify-content-between">
                      <button
                        type="submit"
                        className="shortcodes-btn"
                        onClick={handleShowModal}
                      >
                        Shortcodes
                      </button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      ) : null}
      <AdWidgetShortCodes show={showModal} handleClose={handleCloseModal} />
    </>
  );
}
