import { Card, Button, Nav } from "react-bootstrap";
import Layout from "../../components/Layout";
import Breadcrumbs from "../../Breadcrumbs";
import useQuery from "../../components/useQuery";
import { notifyError } from "../../utility/common";
import { getUserDeatils } from "./apiHandler";
import { getToken } from "../../utility/constants";
import { useEffect, useState } from "react";

export default function UserManageEdit() {
  const query = useQuery();
  const id = query.get("id");
  const token = getToken();
  const [initialValues, setInitialValues] = useState({});
  const getUserInfo = async () => {
    try {
      const response = await getUserDeatils(token, id);
      console.log(response);
      setInitialValues(response.data[0]);
    } catch (error) {
      notifyError(error.message);
    }
  };
  useEffect(() => {
    getUserInfo();
  }, [token]);
  return (
    <Layout>
      <div className="dashboard" style={{ minHeight: "100%" }}>
        <div className="d-flex">
          <Breadcrumbs />
        </div>
        <div className="row my-4" style={{ minHeight: "100%" }}>
          <div className="col-4" style={{ maxWidth: "30%" }}>
            <Card className="p-3 mb-3 text-center">
              <img
                src={
                  initialValues?.image
                    ? initialValues?.image
                    : "https://static.autox.com/admin_auto_x/user/user-profile-dummy.png"
                } // Replace with actual user image URL
                alt="User"
                className="rounded-circle mb-2"
                // style={{ width: "100px", height: "100px" }}
              />
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "black",
                  textTransform: "uppercase",
                  cursor: "pointer",
                }}
              >
                {initialValues?.name}
              </span>
              <div style={{ fontSize: "14px", color: "blue" }}>
                {initialValues?.email}
              </div>
              <div className="mt-3">
                <div
                  style={{
                    borderTop: "1px solid black",
                    paddingTop: "10px 10px 10px 10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Comments: <strong>{initialValues?.comments?.length}</strong>
                </div>
                <div
                  style={{
                    borderTop: "1px solid black",
                    paddingTop: "10px 10px 10px 10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Car Notify:{" "}
                  <strong>{initialValues?.upcoming_car_status}</strong>
                </div>
                <div
                  style={{
                    borderTop: "1px solid black",
                    paddingTop: "10px 10px 10px 10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Bike Notify:{" "}
                  <strong>{initialValues?.upcoming_bike_status}</strong>
                </div>
              </div>
              <Button variant="danger" className="mt-3">
                Deactivate
              </Button>
            </Card>
          </div>
          <div className="col-8">
            <Card className="p-3">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link active>Comments</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link>Notify</Nav.Link>
                </Nav.Item>
              </Nav>
              <div className="mt-3">
                {/* Add content for selected tab here */}
                <div>Content for the selected tab will go here.</div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
}
