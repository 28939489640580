import { useNavigate, useParams } from "react-router-dom";
import BikeQuestionForm from "./BikeQuestionForm";
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";
import FAQapiHandler from "./FAQapiHandler";
import { getToken } from "../../utility/constants";

export default function EditBikeQuestion() {
  const navigate = useNavigate();
  const token = getToken();
  const { questionId } = useParams();
  const [initialValues, setInitialValues] = useState({});
  useEffect(() => {
    const getBikeQuestionValues = async () => {
      try {
        const response = await FAQapiHandler.getBikeQuestionValues(
          token,
          questionId
        );
        console.log(response.data.data);
        setInitialValues(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    getBikeQuestionValues();
  }, [token, questionId]);

  const onSubmit = async (data) => {
    try {
      const keystoRemove = [
        "created_date",
        "id",
        "is_front",
        "likes",
        "slug",
        "updated_date",
        "user_id",
      ];
      keystoRemove.forEach((key) => delete data[key]);

      const response = await FAQapiHandler.updateBikeFAQquestion(
        token,
        questionId,
        data
      );
      if (response.status === 200) {
        toast.success("Bike Question updated successfully");
        setTimeout(() => {
          navigate("/bike-questions");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <BikeQuestionForm initialValues={initialValues} onSubmit={onSubmit} />
      <ToastContainer />
    </>
  );
}
