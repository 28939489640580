import React from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import Breadcrumbs from '../../Breadcrumbs';
import HeadingTwo from '../../components/HeadingTwo';
import apiHandler, { changeEditorPassword } from './apiHandler';
import { notifyError, notifySuccess } from '../../utility/common';
import { useNavigate, useParams } from 'react-router-dom';
import { getToken } from '../../utility/constants';

function ChangePassword() {
    const [password, setPassword] = React.useState('');
    const navigate=useNavigate()
    const {id}=useParams()
    const token=getToken()
const changePasswordFunction=async()=>{
    await changeEditorPassword(token,id,password)
    .then((res)=>{
        if(res.status === 200){
            notifySuccess("password changed")
            setTimeout(()=>{
                navigate("/editor")
            },2000)
        }else{
            notifyError(res.message)
        }
    })
}

    return (
        <div>
            <Breadcrumbs />
            <div>
                <Card className="p-3">
                    <HeadingTwo title="Change Password" />
                    <Form.Control 
                        type="password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        style={{ width: '260px' }} // Set width to 60 pixels
                        className="p-2 b-none" // Additional Bootstrap classes
                    />
                    <button className='version-button' style={{
                        width:"100px",
                        backgroundColor:"green",
                        marginTop:"1rem"
                    }}onClick={changePasswordFunction} width="260px">
                    Save
                  </button>
                </Card>
            </div>
        </div>
    );
}

export default ChangePassword;
