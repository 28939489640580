import React from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Breadcrumbs from "../../../Breadcrumbs";
import Image from "react-bootstrap/Image";
import BackIcon from "../../../assets/icons/Back_Arrow.png";
import { getToken } from "../../../utility/constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notifyError, notifySuccess } from "../../../utility/common";
import apiHandler from "./apiHandler";
import { marutiCarDrivingSchoolValidation } from "./schemaValidation";
import MarutiCarDrivingSchoolForm from "./MarutiCarDrivingSchoolForm";

export default function AddMarutiCarDrivingSchool() {
  const token = getToken();
  const navigate = useNavigate();

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    values,
    errors,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: {
      city_id: -1,
      state_id: -1,
      school_name: "",
      school_url: "",
      maruti_suzuki_driving_school_address: "",
      maruti_suzuki_driving_school_area: "",
      maruti_suzuki_driving_school_map: "",
      latitude: "",
      longitude: "",
      phone_number: "",
      email_id: "",
    },

    validationSchema: marutiCarDrivingSchoolValidation,
    onSubmit: async (values, action) => {
      // if(values.maruti_suzuki_driving_school_map){
      //     var [longitude, latitude] = values.maruti_suzuki_driving_school_map.split(",");

      // }
      const payload = { ...values };
      delete payload.state_id;
      apiHandler
        .addMarutiSuzukiDrivingSchool(token, payload)
        .then((result) => {
          action.resetForm();
          notifySuccess("Maruti Car Driving School added successfully");
          navigate("/maruti-car-driving-school");
          navigate(
            `/maruti-car-driving-school/edit-maruti-car-driving-school/${result.data.result.id}?added=true`
          );
        })
        .catch((error) => {
          notifyError(error.message);
        });
    },
  });

  return (
    <div className="row dashboard">
      <ToastContainer />
      <div className="row">
        <div className="col-xl-11 col-sm-11">
          <Breadcrumbs />
        </div>
        <div className="col-xl-1 col-sm-1">
          <button
            className="d-flex back-button py-2 px-3 mb-2 btn btn-primary"
            onClick={() => navigate("/maruti-car-driving-school")}
          >
            <Image
              src={BackIcon}
              width="16px"
              height="16px"
              className="mt-1 me-2"
            />{" "}
            Back
          </button>
        </div>
      </div>

      <Card>
        <Card.Body>
          <Card.Text>
            <div className="row dashboard">
              <div className="table-heading fw-bold">
                Maruti Car Driving School Form
              </div>
              <div className="d-flex col-12 mt-3 flex-wrap">
                <MarutiCarDrivingSchoolForm
                  type={"Add"}
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleSubmit={handleSubmit}
                  setFieldValue={setFieldValue}
                />
              </div>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
