import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../request";

export function getCommentsList(token, params) {
  return axios.post(
    `${BASE_URL_AUTOX}comments/filtered/list`,
    params,
    getConfig(token)
  );
}

export function addComments(payload, token) {
  return axios.post(
    `${BASE_URL_AUTOX}comments/create-comment`,
    payload,
    getConfig(token)
  );
}

export function updateComments(payload, token) {
  return axios.put(
    `${BASE_URL_AUTOX}comments/update-comment/${payload.id}`,
    payload,
    getConfig(token)
  );
}

export function deleteComments(id, payload, token) {
  return axios.delete(
    `${BASE_URL_AUTOX}comments/delete-comment/${id}`,
    getConfig(token, payload)
  );
}

export function downloadCommentContent(token) {
  return axios.get(
    `${BASE_URL_AUTOX}comments/export-comments`,
    getConfig(token)
  );
}

export function approveComments(id, token) {
  return axios.put(
    `${BASE_URL_AUTOX}comments/approve-comment/${id}`,
    {},
    getConfig(token)
  );
}
export default {
  getCommentsList,
  addComments,
  updateComments,
  deleteComments,
  approveComments,
  downloadCommentContent,
};
