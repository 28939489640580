import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/Table";
import EditViewDeleteReviewPassword from "../../../components/EditViewDeleteReviewPassword";
import { ReactSVG } from "react-svg";
import { Card } from "react-bootstrap";
import PlusLogo from "../../../assets/icons/bootstrap-icons/plus-lg.svg";
import Breadcrumbs from "../../../Breadcrumbs";
import DownloadIcon from "../../../assets/icons/bootstrap-icons/download.svg";
import {
  getCityPrice,
  deleteCityPrice,
  getAllBrands,
  getAllCities,
  deleteBikeCityPrice,
  getBikeCityPrice,
  getAllBikeCities
} from "../../../request";
import { getToken } from "../../../utility/constants";
import apiHandler, { downloadBikePrice } from "./apiHandler";
import moment from "moment";
import DeleteConfirmationModalComponent from "../../../components/DeleteConfirmationModalComponent";
import { notifyError, notifySuccess } from "../../../utility/common";
import CarModelSearchBar from "../../NewCar/CarModels/CarModelListing/CarModelSearchBar";
import ExportButton from "../../../components/Export";
import ImportCarCityPrice from "../../../components/ImportCarCityPrice";
import { getAllBikeBrands } from "../BikeBrands/apiHandler";
import DownloadCSVExport from "../../../components/DownloadCSVExport";

export default function BikeCityPriceList() {
  const navigate = useNavigate();
  const token = getToken();

  const [searchVal, setSearchVal] = useState("");
  /* eslint-disable */
  const [filteredPrice, setFilteredPrice] = useState([]);
  // const [totalPages, setTotalPages] = useState(1);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState();
  const [page, setPage] = useState(1);
  const [price, setPrice] = useState([]);
  const [cityPriceDetails, setCityPriceDetails] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteBrand, setDeleteBrand] = useState(null);
  const [show, setShow] = useState(false);
  const [reload, setReload] = useState(false);
  const [filter, setFilter] = useState({
    brand_url: "hero"
  });
  const [brands, setBrands] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [versions, setVersions] = useState([]);
  const [models, setModels] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedVersion, setSelectedVersion] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const [totalPages, setTotalPages] = useState(0);
  /* eslint-enable */
  const onEditInner = (id, url) => {
    console.log(id, url, "iddddd");
    navigate(`/edit-bike-city-price/${id}/${url}`);
  };

  const onDeleteInner = (id) => {
    deleteBikeCityPrice(deleteId, deleteBrand, token)
      .then(() => {
        setShow(false);
        setReload(true);
        notifySuccess("Bike city price deleted successfully");
      })
      .catch((error) => {
        setShow(false);
        notifyError(error.message);
      });
  };

  const columns = [
    {
      name: "Sr. No.",
      sortable: true,

      // selector: (_, index) =>
      //   index + 1 + currentPage * itemsPerPage - itemsPerPage,
      selector: (row) => row.id,
      width: "80px"
    },
    {
      name: "City Name",
      sortable: true,
      selector: (row) => row.city_name,
      width: "auto"
    },
    {
      name: "Ex-showroom Price",
      sortable: true,
      selector: (row) => `Rs ${row.ex_showroom_price}`,
      width: "200px"
    },
    {
      name: "Version Name",
      sortable: true,
      selector: (row) => row.version_name,
      width: "190px"
    },
    {
      name: "Model Name",
      sortable: true,
      selector: (row) => row.model_name,
      width: "200px"
    },
    {
      name: "Brand Name",
      sortable: true,
      selector: (row) => row.brand_name,
      width: "200px"
    },
    {
      name: "Modified Date",
      sortable: true,
      selector: (row) => moment(row.created_date).format("DD MMM YYYY"),
      width: "200px"
    },
    {
      name: "Action",
      sortable: false,
      width: "100px",
      cell: (row) => (
        <EditViewDeleteReviewPassword
          id={row.id}
          onEdit={() => onEditInner(row.id, row.brand_url)}
          onDelete={() => {
            setDeleteId(row.id);
            setDeleteBrand(row.brand_url);
            setShow(true);
          }}
        />
      )
    }
  ];

  useEffect(() => {
    handleSearchClick();
  }, [searchVal]);

  const getCityPriceList = useCallback(async () => {
    try {
      const res = await getBikeCityPrice(token, {
        search,
        limit,
        sortBy,
        sortOrder,
        page,
        brand_url: selectedBrand.brand_url
      });

      const response = res.data.result;
      const totalRecords = res.data.totalRecord;
      const totalPages = Math.ceil(totalRecords / limit);
      setTotalPages(totalPages);

      setCityPriceDetails(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [token, search, limit, sortBy, sortOrder, page]);

  // useEffect(() => {

  //   apiHandler.getBikeCityPriceList();
  // }, [getCityPriceList]);

  useEffect(() => {
    setFilteredPrice(cityPriceDetails);
  }, [cityPriceDetails]);

  function handleSearchClick() {
    if (searchVal === "") {
      setFilteredPrice(cityPriceDetails);
      return;
    }
    const filterBySearch = price.filter((item) => {
      if (
        item.cityName?.toLowerCase().includes(searchVal.toLowerCase()) ||
        item.versionName?.toLowerCase().includes(searchVal.toLowerCase())
      ) {
        return item;
      }
    });
    setFilteredPrice(filterBySearch);
  }
  const getBrands = async () => {
    try {
      const response = await getAllBikeBrands();
      setBrands(response.data.result);
    } catch (error) {
      notifyError(error.message);
    }
  };
  const getCities = async (token) => {
    try {
      const response = await getAllBikeCities(token);
      setCityList(response.data.result);
    } catch (error) {
      notifyError(error.message);
    }
  };
  const getBikeVersionByModel = async () => {
    try {
      const response = await apiHandler.getBikeVersionByModelId(token, {
        model_id: selectedModel.id
      });

      setVersions(response.data.result);
    } catch (error) {
      notifyError(error.message);
    }
  };
  const getBikeModelByBrand = async () => {
    try {
      const response = await apiHandler.getBikeModelByBrandId(token, {
        brand_id: selectedBrand.id
      });
      setModels(response.data.result);
    } catch (error) {
      notifyError(error.message);
    }
  };
  useEffect(() => {
    getBrands();
  }, [reload, token]);
  useEffect(() => {
    if (selectedBrand) {
      getBikeModelByBrand();
    }
  }, [selectedBrand]);

  useEffect(() => {
    if (selectedModel) {
      getBikeVersionByModel();
    }
  }, [selectedModel]);

  useEffect(() => {
    getCities(token);
  }, [reload, token]);
  const handleSearchValue = useCallback((value) => {
    setSearchVal(value);
    setReload(true);
  }, []);

  const filterOptions = [
    {
      key: "brand",
      label: "By Brands",
      type: "brand",
      searchKey: "brand_name",
      values: brands
    },
    {
      key: "model_name",
      label: "By model",
      type: "model",
      searchKey: "model_name",
      values: models
    },
    {
      key: "version_name",
      label: "By version",
      type: "version",
      searchKey: "version_name",
      values: versions
    },
    {
      key: "city_name",
      label: "By City",
      type: "city",
      searchKey: "city_name",
      values: cityList
    }
  ];

  const selectedTypes = (type, selected) => {
    if (type === "brand") {
      setSelectedBrand(selected);
      setFilter({ ...filter, brand_url: selected.brand_url });
    }
    if (type === "model") {
      setSelectedModel(selected);
      setFilter({ ...filter, model_id: selected.id });
    }
    if (type === "version") {
      setSelectedVersion(selected);
      setFilter({ ...filter, version_id: selected.id });
    }
    if (type === "city") {
      setSelectedCity(selected);
      setFilter({ ...filter, city_id: selected.id });
    }
  };

  const applyFilter = useCallback((value) => {
    setReload(true);
  }, []);

  const clearAll = () => {
    setFilter({});
    setReload(true);
  };

  const Keys = [
    "id",
    "city_name",
    "ex_showroom_price",
    "version_name",
    "model_name",
    "brand_name",
    "created_date"
  ];
  const downloadSample = async () => {
    try {
      // const res = await apiHandler.downloadSampleCityPrice();
      // console.log(res.data.url);
      // if (res.data.url) {
      window.open(
        "https://images.autox.com/uploads/temp-files/bike_city_price_sample.xlsx",
        "_blank"
      );
      // }
    } catch (error) {
      notifyError(error.message);
    }
  };

  console.log("values", filter);
  return (
    <div className="row dashboard">
      <div className="d-flex justify-content-between">
        {console.log("cityPriceDetails", cityPriceDetails)}
        <Breadcrumbs />
        <div className="d-flex ">
          <ImportCarCityPrice setReload={setReload} type="bike" />

          {/* <ExportButton
            api={getBikeCityPrice}
            totalRecords={totalRecords}
            keys={Keys}
            showKeys={Keys}
            payload={{
              search: "",
              sortBy: "",
              sortOrder: "",
              page: 1,
              limit: totalRecords,
              brand: selectedBrand.id,
              city_id: selectedCity.id,
              model_id: selectedModel.id,
              version_id: selectedVersion.id,
              brand_url: selectedBrand.brand_url,
            }}
          /> */}
          <DownloadCSVExport
            api={() =>
              downloadBikePrice(token, {
                brand_url: filter.brand_url,
                model_id: filter.model_id,
                version_id: filter.version_id,
                city_id: filter.city_id
              })
            }
          />
          <button
            className="sample-import-file-button d-flex align-items-center text-nowrap justify-content-between me-2 mb-2 text-white px-3"
            id="dropdownMenuButton1"
            onClick={downloadSample}
          >
            <ReactSVG
              height="18px"
              width="18px"
              src={DownloadIcon}
              className="mr-2"
            />{" "}
            Sample File for Import
          </button>
        </div>
      </div>
      <Card className="ms-2">
        <Card.Body>
          <Card.Text>
            <div className="row dashboard">
              <div className="col-md-5">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <CarModelSearchBar
                      onChange={handleSearchValue}
                      value={searchVal}
                      options={filterOptions}
                      selectedTypes={selectedTypes}
                      selectedValues={{
                        brand: selectedBrand.id,
                        city: selectedCity,
                        model: selectedModel,
                        version: selectedVersion
                      }}
                      apply={applyFilter}
                      clearAll={clearAll}
                      tableHeading={"Bike City Price List"}
                      searchPlaceholder={"Search"}
                      hideSearch={true}
                    />
                    {/* <div className="table-heading mb-3">City Price List</div>

                    <button className="px-2 border-1 rounded filter-option p-2 my-2">
                      <ReactSVG src={FilterIcon} />
                    </button> */}
                  </div>
                </div>
              </div>
              <div className="col-md-7 text-right">
                <div className="btngroup d-flex justify-content-end flex-row flex-wrap">
                  <button
                    className="bulk-insert-button d-flex align-items-center justify-content-between me-2 mb-2 px-3 mt-2"
                    onClick={() => navigate("/delete-bike-city-price")}
                  >
                    <ReactSVG
                      src={DownloadIcon}
                      height="18px"
                      width="18px"
                      className="mr-2"
                    />{" "}
                    Delete Multiple Car City Price
                  </button>
                  <button
                    onClick={() => navigate("/add-bike-city-price")}
                    className="bulk-update-button d-flex align-items-center justify-content-between me-2 mt-2 mb-2 px-3"
                  >
                    <ReactSVG
                      src={PlusLogo}
                      height="18px"
                      width="18px"
                      className="mr-2"
                    />{" "}
                    Add Bike City Price
                  </button>
                </div>
              </div>

              <Table
                api={apiHandler.getBikeCityPriceList}
                columns={columns}
                // data={slicedDealer}
                setReload={setReload}
                reload={reload}
                search={searchVal}
                currentPage={currentPage}
                showPagination={true}
                sortByDir={"desc"}
                onChangePage={(pageNumber) => setCurrentPage(pageNumber)}
                // totalPages={Math.ceil(filteredDealer.length / itemsPerPage)}
                setTotalRecords={setTotalRecords}
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
                filter={filter}
              ></Table>
              <DeleteConfirmationModalComponent
                show={show}
                type={"delete"}
                handleClose={() => setShow(false)}
                setDeleteConfirm={onDeleteInner}
              />
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
