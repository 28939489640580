import { useNavigate, useLocation } from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs";
import { notifySuccess } from "../../utility/common";
import PostForm from "./PostForm";
import PostValidationSchema from "./validationSchema";
import { getAdminType, getToken, getUserId } from "../../utility/constants";
import { useEffect, useState, useRef } from "react";
import {
  getPostById,
  updatePostById,
  updatePostStatus,
  checkPostLockStatus,
} from "./apiHandler";
import { useFormik } from "formik";
import useQuery from "../../components/useQuery";
import { convertUSTtoIST, getISTDate } from "../../request";

export default function EditPost() {
  const navigate = useNavigate();
  const token = getToken();
  const query = useQuery();
  const location = useLocation();

  const user_id = getUserId();
  const id = query.get("id");
  const adminType = getAdminType();
  const [isDraft, setIsDraft] = useState(false);

  const [initialValues, setInitialValues] = useState({
    category_id: -1,
    author_id: "0",
    slug: "",
    post_title: "",
    post_content: "",
    short_description: "",
    featured_image: "",
    thumb_type: "featured_image",
    slider_images: [],
    status: "",
    linkedin_post_status: "0",
    home_page_image: "0",
    verify_duplicate: "0",
    display_category: "0",
    publish_date: "",
    photography_id: "0",
    brand: "-1",
    model: "-1",
    type: "",
    tags: [],
    slider: 0,
    seo_title: "",
    seo_description: "",
    meta_keywords: "",
    meta_description: "",
    cannonical: "",
    thumb_value: "",
    category_name: "",
    stories_images: [],
    delete_stories: [],
    post_rating: 0,
  });

  const [isLocked, setIsLocked] = useState(false);
  const [showLockPopup, setShowLockPopup] = useState(false);
  const [publishDate, setPublishDate] = useState("");
  const intervalRef = useRef(null);

  // Fetch the post data
  const fetchPostData = async () => {
    try {
      const response = await getPostById(id, token);
      const processedResult = Object.fromEntries(
        Object.entries(response.data.result).map(([key, value]) => [
          key,
          value ?? "", // Replace null with an empty string
        ])
      );

      const mergedValues = {
        ...initialValues,
        ...processedResult,
        brand: processedResult.brand ? processedResult.brand : "-1",
        // status: processedResult.status == 0 ? 1 : processedResult.status,
        display_category: processedResult.display_category.map((item) =>
          Number(item)
        ),
      };
      setPublishDate(mergedValues.publish_date);
      setInitialValues(mergedValues);
    } catch (error) {
      console.error("Error fetching post data:", error);
    }
  };
  console.log(publishDate, "publishDate");
  // Check lock status on page load and handle the take control process
  useEffect(() => {
    const checkLockStatus = async () => {
      if (location.pathname !== "/post-management/edit-post") return; // Skip if not editing

      try {
        const response = await checkPostLockStatus(id, token);
        const { status, user_id: postUserId } = response.data.result[0];

        if (status === "editing" && postUserId !== user_id) {
          // Post is locked by another user
          alert(
            "This post is being edited by another user. Please try again later."
          );
          setIsLocked(true);
          setShowLockPopup(true);
        } else if (status === "editing" && postUserId === user_id) {
          // Post is being edited by the current user
          setIsLocked(false);
          // Fetch post data only if it's unlocked for the current user
          fetchPostData();
          updatePostStatus({ id, user_id }, token); // Update status to 'editing' for the current user
        } else {
          // Post is available for editing
          setIsLocked(false);
          fetchPostData();
        }
      } catch (error) {
        console.error("Error checking post lock status:", error);
      }
    };

    // Fetch the post data when component mounts
    fetchPostData(); // Ensure data is fetched even if the lock status is checked later

    // Check the lock status on initial load
    checkLockStatus();

    // Set up the interval only if the user is editing the post
    if (!isLocked && location.pathname === "/post-management/edit-post") {
      intervalRef.current = setInterval(async () => {
        try {
          const response = await checkPostLockStatus(id, token);
          const postUserId = response.data.result[0].user_id;

          // If someone else has taken control, redirect
          if (postUserId > 0 && postUserId !== user_id) {
            alert("Someone else has taken control of the post. Redirecting...");
            navigate("/post-management");
          } else {
            updatePostStatus({ id, user_id }, token);
          }
        } catch (error) {
          console.error("Error checking post status in interval:", error);
        }
      }, 8000); // Check every 8 seconds
    }

    // Cleanup the interval if not needed
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [id, token, user_id, location.pathname, navigate, isLocked]); // Dependencies

  // Handle when the user clicks "Take control"
  const handleTakeControl = async () => {
    try {
      const response = await updatePostStatus({ id, user_id }, token);
      if (response.status === 200) {
        setIsLocked(false);
        setShowLockPopup(false);
        notifySuccess("You are now editing the post.");

        // Fetch post data after taking control
        await fetchPostData();

        // Restart the 8-second interval to keep updating the post status
        updatePostStatus({ id, user_id }, token);
      }
    } catch (error) {
      console.error("Error while taking control:", error);
    }
  };

  // Cancel and navigate back to the post listing
  const handleCancel = () => {
    navigate("/post-management");
  };

  const { handleSubmit, values, setFieldValue, touched, errors, isSubmitting } =
    useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      validationSchema: PostValidationSchema,
      onSubmit: (values) => {
        const payload = { ...values };
        if (isDraft) {
          if (payload.status == 1) {
            payload.publish_date = getISTDate();
          }
          payload.status = 0;
        }
        if (payload.status == 2) {
          payload.publish_date = convertUSTtoIST(
            new Date(payload.publish_date)
          );
        }
        // if (payload.status == 1) {
        //   payload.publish_date = publishDate;
        // }
        // Clean up unwanted values before sending to API
        delete payload.id;
        delete payload.created_date;
        delete payload.updated_date;
        delete payload?.category_slug;
        delete payload?.parent_category_slug;
        delete payload?.category_name;
        delete payload?.brand_name;
        delete payload?.model_name;
        console.log("payload........", payload);
        // Update the post by ID
        updatePostById(id, payload, token).then((res) => {
          if (res.status === 200) {
            notifySuccess("Post updated successfully");
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1500);
          }
        });
      },
    });

  return (
    <div>
      <Breadcrumbs />
      {/* Lock Popup */}
      {showLockPopup && (
        <div className="lock-popup-overlay">
          <div className="lock-popup">
            <p>This post is currently locked by another user.</p>
            <div>
              <button onClick={handleCancel}>Go to Post Listing</button>
              <button onClick={handleTakeControl}>Take Control</button>
            </div>
          </div>
        </div>
      )}

      {/* Main Content */}
      {!isLocked && (
        <div>
          <PostForm
            values={values}
            setFieldValue={setFieldValue}
            touched={touched}
            errors={errors}
            handleSubmit={handleSubmit}
            type="edit"
            adminType={adminType}
            setIsDraft={setIsDraft}
            isSubmitting={isSubmitting}
          />
        </div>
      )}
    </div>
  );
}
