import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../utility/common";
import { deleteSafetyRatingAPI } from "./apiHandler";
import { useState } from "react";
import DeleteConfirmationModalComponent from "../../components/DeleteConfirmationModalComponent";
import EditViewDeleteReviewPassword from "../../components/EditViewDeleteReviewPassword";
const SafetyColumn = (reloadTable, token) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const deleteSafetyRating = async () => {
    if (deleting) return;
    try {
      setDeleting(true);
      const res = await deleteSafetyRatingAPI(deleteId, token);

      notifySuccess("Safety Rating deleted successfully");
      reloadTable();
      setModal(false);
      return res;
    } catch (error) {
      console.error("Error deleting Sidebar:", error);
      notifyError("Failed to delete Sidebar");
    } finally {
      setDeleting(false);
      setModal(false);
    }
  };

  const onDelete = (id) => {
    if (deleting) return;
    setDeleteId(id);
    setModal(true);
  };

  const onEdit = (id) => {
    navigate(`/edit-safety-rating/${id}`);
  };

  const onViewInner = (id) => {
    navigate(`/safety-rating/${id}`);
  };

  return [
    {
      name: "No.",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Model",
      selector: (row) => row.model_name,
      sortable: true,
    },

    {
      name: "Year of Testing",
      selector: (row) => row.yearoftesting,
      sortable: true,
    },
    {
      name: "Global NCAP Rating",
      selector: (row) => row.globalncapadultrating,
      sortable: true,
    },
    {
      name: "Bharat NCAP Rating",
      selector: (row) => row.bharatncapadultrating,
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      width: "auto",
      id: "Action",
      style: {
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },

      cell: (row) => (
        <div className="d-flex align-items-center justify-content-center">
          <EditViewDeleteReviewPassword
            id={row.id}
            onView={() => onViewInner(row.id)}
            onEdit={() => onEdit(row.id)}
            onDelete={() => onDelete(row.id)}
          />
          <DeleteConfirmationModalComponent
            heading="Delete Safety Rating"
            message="Are you sure you want to delete this Safety Rating?"
            show={modal && deleteId === row.id}
            handleClose={() => setModal(false)}
            setDeleteConfirm={deleteSafetyRating}
            type="delete"
          />
        </div>
      ),
    },
  ];
};

export default SafetyColumn;
