import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../../request";

export const getAllAdCategory = (token) => {
  return axios.get(
    `${BASE_URL_AUTOX}ad-page/get-all-templates`,
    getConfig(token)
  );
};
export const getAdByCategorySlug = (token, slug) => {
  return axios.get(`${BASE_URL_AUTOX}ad-page/get-ad/${slug}`, getConfig(token));
};

export const updateCategoryAds = (token, payload) => {
  return axios.put(
    `${BASE_URL_AUTOX}ad-page/update-ads`,
    payload,
    getConfig(token)
  );
};

export default {
  getAllAdCategory,
  getAdByCategorySlug,
  updateCategoryAds
};
