import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Layout from "../../components/Layout";
import Heading from "../../components/Heading";
import HeadingTwo from "../../components/HeadingTwo";
import PlusLargeIcon from "../../assets/icons/bootstrap-icons/plus-lg.svg";

export default function EditorMain({ children }) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="row dashboard">
        <div className="my-4">{children}</div>
      </div>
    </Layout>
  );
}
