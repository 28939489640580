import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Breadcrumbs from "../../../Breadcrumbs";
import Image from "react-bootstrap/Image";
import BackIcon from "../../../assets/icons/Back_Arrow.png";
import { getToken } from "../../../utility/constants";
import APIHandler from "./apiHandler";
import { marutiCarDrivingSchoolValidation } from "./schemaValidation";
import { notifyError, notifySuccess } from "../../../utility/common";
import MarutiCarDrivingSchoolForm from "./MarutiCarDrivingSchoolForm";
import Card from "react-bootstrap/Card";

export default function EditMarutiCarDrivingSchool() {
  const navigate = useNavigate();
  const { id } = useParams();
  const token = getToken();
  const [cities, setCities] = useState([]);
  const [queryParams, setQueryParams] = useSearchParams();
  const target = useRef(null);

  const [initialValues, setInitialValues] = useState({
    city_id: -1,
    state_id: -1,
    school_name: "",
    school_url: "",
    maruti_suzuki_driving_school_address: "",
    maruti_suzuki_driving_school_area: "",
    maruti_suzuki_driving_school_map: "",
    latitude: "",
    longitude: "",
    phone_number: "",
    email_id: "",
  });

  useEffect(() => {
    if (queryParams.get("added") && queryParams.get("added") === "true") {
      // queryParams.delete("added");
      setQueryParams({ ["added"]: "false" });
      // notifySuccess("Maruti Car Driving School added successfully")
    } else {
      return;
    }
  }, []);

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    values,
    errors,
    setFieldValue,
    touched,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: marutiCarDrivingSchoolValidation,
    onSubmit: async (values, action) => {
      // const [longitude, latitude] = values.maruti_suzuki_driving_school_map.split(",");
      console.log("values.......", values);
      const payload = { ...values };

      const filteredPayload = {};

      for (const key in payload) {
        if (
          payload.hasOwnProperty(key) &&
          payload[key] !== "" &&
          payload[key] !== null
        ) {
          filteredPayload[key] = payload[key];
        }
      }

      // delete payload.city_name;
      delete filteredPayload.id;
      delete filteredPayload.created_date;
      delete filteredPayload.updated_date;

      APIHandler.updateMarutiSuzukiDrivingSchoolById(token, id, filteredPayload)
        .then((result) => {
          fetchData();
          notifySuccess("Maruti Car Driving School updated successfully");
          setTimeout(() => {
            navigate("/maruti-car-driving-school");
          }, 1500);
        })
        .catch((error) => {
          notifyError(error.message);
        });
    },
  });

  const fetchData = async () => {
    // const token=getToken();
    APIHandler.getMarutiSuzukiCarDrivingSchoolById(token, id)
      .then((result) => {
        const response = result.data.data;
        const filteredResponse = {};

        for (const key in response) {
          if (response.hasOwnProperty(key) && response[key] !== "") {
            filteredResponse[key] = response[key];
          }
        }
        setInitialValues(filteredResponse[0]);
      })
      .catch((error) => {
        console.log(error.message);
        notifyError(error.message);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="row dashboard">
      <div className="row">
        <div className="col-xl-11 col-sm-11">
          <Breadcrumbs removeId={true} />
        </div>
        <div className="col-xl-1 col-sm-1">
          <button
            className="d-flex back-button py-2 px-3 mb-2 btn btn-primary"
            onClick={() => navigate("/maruti-car-driving-school")}
          >
            <Image
              src={BackIcon}
              width="16px"
              height="16px"
              className="mt-1 me-2"
            />{" "}
            Back
          </button>
        </div>
      </div>

      <Card>
        <Card.Body>
          <Card.Text>
            <div className="row dashboard">
              <div className="table-heading fw-bold">
                Maruti Car Driving School Form
              </div>
              <MarutiCarDrivingSchoolForm
                type={"Update"}
                values={values}
                errors={errors}
                touched={touched}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
              />
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
