import { useNavigate } from "react-router-dom";
import { getToken, getUserId } from "../../utility/constants";
import FAQapiHandler from "./FAQapiHandler";
import { notifyError, notifySuccess } from "../../utility/common";
import CarAnswerForm from "./CarAnswerForm";

export default function AddCarAnswer() {
  const navigate = useNavigate();
  const token = getToken();
  const userId = getUserId();
  const AddCarAnswer = async (data) => {
    try {
      const response = await FAQapiHandler.addCarAnswer(token, data);
      console.log(response);
      if (response.status === 200) {
        notifySuccess(response.data.message);
        setTimeout(() => {
          navigate("/car-answers");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      notifyError(error.response.data.message);
    }
  };

  const initialValues = {
    question_id: -1,
    answer_name: "",
    answer_status: -1,
    user_id: userId,
  };

  return (
    <div>
      <CarAnswerForm initialValues={initialValues} onSubmit={AddCarAnswer} />
    </div>
  );
}
