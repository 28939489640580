import axios from "axios";
import { BASE_URL_AUTOX, getConfig } from "../../request";

export const getStats = async (token) => {
  return axios.get(
    `${BASE_URL_AUTOX}dashboard/dashboard-stats`,
    getConfig(token)
  );
};
export const makeSitemap = async (token) => {
  return axios.get(`${BASE_URL_AUTOX}dashboard/make-sitemap`, getConfig(token));
};

export default {
  getStats,
  makeSitemap,
};
