import axios from "axios";
import {
  ADD_CAR_BODY_TYPE,
  BASE_URL_AUTOX,
  DELETE_CAR_BODY_TYPE,
  GET_CAR_BODY_TYPES,
  GET_CAR_BODY_TYPE_BY_ID,
  UPDATE_CAR_BODY_TYPE,
  getConfig
} from "../../../request";

/**
 * Get List of Car body type
 * getCarBodyType is used to get List of Car body type
 * @param token
 * @param payload
 */
export function getCarBodyType(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${GET_CAR_BODY_TYPES}`,
    payload,
    getConfig(token)
  );
}

export function addCarBodyType(token, payload) {
  return axios.post(
    `${BASE_URL_AUTOX}${ADD_CAR_BODY_TYPE}`,
    payload,
    getConfig(token)
  );
}
export function updateCarBodyType(token, payload, id) {
  return axios.put(
    `${BASE_URL_AUTOX}${UPDATE_CAR_BODY_TYPE}${id}`,
    payload,
    getConfig(token)
  );
}

export function getCarBodyTypeById(token, id) {
  return axios.get(
    `${BASE_URL_AUTOX}${GET_CAR_BODY_TYPE_BY_ID}${id}`,
    getConfig(token)
  );
}

export function deleteCarBodyTypeById(token, id) {
  return axios.delete(
    `${BASE_URL_AUTOX}${DELETE_CAR_BODY_TYPE}${id}`,
    getConfig(token)
  );
}

export default {
  getCarBodyType,
  addCarBodyType,
  getCarBodyTypeById,
  updateCarBodyType,
  deleteCarBodyTypeById
};
